/**
* Custom filters for Vue.
*
* In components: this.$options.filters.foo(this.msg);
* In blade files: @{{ passage.start_date | date }}
*
*/

import Vue from "vue";

const timeZone = 'Europe/London';

/**
* Group Special Services by Category.
*/
Vue.filter('titleToSlug', title => {
    var nt = title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    return '/gift-cards/' + nt;
});

/**
* Group Special Services by Category.
*/
Vue.filter('slugToTitle', title => {
    var words = title.split('-');

    for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
});

/**
* Group Special Services by Category.
*/
Vue.filter('titleToImg', title => {
    var nt = title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    return '/img/gc/' + nt + '.webp';
});

/**
* Helper method to return correct plural for person.
*/
Vue.filter('personSwitch', value => {
    if (parseInt(value) > 1) {
        return value + ' people';
    } else {
        return value + ' person';
    }
});

/**
* Helper method to return correct venue name from slug.
*/
Vue.filter('venue', (venue, type) => {
    if (venue === 'eden-hall') {
        return 'Eden Hall - Day Spa';
    }
    if (venue === 'hoar-cross-hall' && type == 'stay') {
        return 'Hoar Cross Hall';
    } else {
        return 'Hoar Cross Hall - Day Spa';
    }
});

/**
* Helper method to return cnice venue.
*/
Vue.filter('nicevenue', (venue) => {
    if (venue === 'Hoar Cross Hall - Day Spa') {
        return 'Hoar Cross Hall';
    } else if (venue === 'Eden Hall - Day Spa') {
        return 'Eden Hall'
    } else {
        return 'Hoar Cross Hall'
    }
});

/**
* Helper method to return cleaned type from slug.
*/
Vue.filter('nicetype', (type) => {
    if (type == 'stay') {
        return 'Stay';
    } else {
        return 'Spa Day';
    }
});

/**
* Helper method to return correct venue slug from folio property name.
*/
Vue.filter('venueSlug', value => {
    if (value === 'Eden Hall - Day Spa') {
        return 'eden-hall';
    } else if (value === 'Hoar Cross Hall' || 'Hoar Cross Hall - Day Spa') {
        return 'hoar-cross-hall';
    } else {
        return 'hoar-cross-hall';
    }
});

/**
* Helper method to return visit type from folio property name.
*/
Vue.filter('visitType', value => {
    if (value === 'Eden Hall - Day Spa' || value === 'Hoar Cross Hall - Day Spa') {
        return 'spa-day';
    } else if (value === 'Hoar Cross Hall') {
        return 'stay';
    } else {
        return 'stay';
    }
});

/**
* Helper method to return correct spa location from slug.
*/
Vue.filter('spavenue', value => {
    if (value === 'Eden Hall - Day Spa') {
        return 300;
    }
    if (value === 'Hoar Cross Hall') {
        return 100;
    }
    if (value === 'Hoar Cross Hall - Day Spa') {
        return 100;
    }
});

/**
* Helper method to return correct type from venue.
*/
Vue.filter('type', value => {
    if (value === 'hoar-cross-hall') {
        return 'stay';
    } else {
        return 'spa-days'
    }
});

/**
* Helper method to return correct item name in WebFolio.
*/
Vue.filter('catnicename', (value, venue) => {
    if (value === 'Retail') {
        return 'Gift Card';
    }
    if (value === 'Hotel') {
        if (venue == 'hoar-cross-hall') {
            return 'Stay';
        } else {
            return 'Spa Day'
        }
    }
    if (value === 'Spa') {
        return 'Treatments';
    }
});

/**
* Helper method to return correct item name in WebFolio for main Cart, as we have no venue now..
*/
Vue.filter('cartnicename', (value, venue) => {
    if (value === 'Retail') {
        return 'Gift Card';
    }
    if (value === 'Hotel') {
        if (venue == 'hoar-cross-hall') {
            return 'Stay';
        } else {
            return 'Spa Day'
        }
    }
    if (value === 'Spa') {
        return 'Treatments';
    }
});

/**
* Format the given time for treatment timetable.
*/
Vue.filter('treatmentChunks', value => {
    return moment.tz(value, 'YYYY-MM-DDHHmmss').format('HH:mm');
});


/**
* Group Gift Cards by Category.
*/
Vue.filter('categories', giftcards => {

    _.each(giftcards, function (o) {
        o['sortPrice'] = parseFloat(o.Price);
    })
    var sorted = _.orderBy(giftcards, ['sortPrice'], ['asc']);
    var gc = _.groupBy(sorted, 'ItemType');

    return gc;
});

/**
* Clean gift card cat for christmas.
*/
Vue.filter('cleanedCat', cat => {

    if (cat == 'Gift Voucher - Monetary Xmas') {
        return 'Gift Voucher - Monetary'
    }
    return cat
});

/**
* Group Special Services by Category.
*/
Vue.filter('ssbycat', ss => {
    return _.groupBy(ss, 'PMSSpecialServiceCategory')
});

/**
* Group treatments by Category.
*/
Vue.filter('treatmentsbycat', ss => {
    var cats = _.groupBy(ss, 'ItemCategory')

    var filtered = {};

    if (cats['Hoar Cross Recommends']) { filtered['Hoar Cross Recommends'] = cats['Hoar Cross Recommends'] }
    if (cats['Eden Recommends']) { filtered['Eden Recommends'] = cats['Eden Recommends'] }

    filtered['Most Popular'] = cats['Most Popular']

    return filtered;
});

/**
* Return icon.
*/
Vue.filter('icon', type => {
    if (type === 'E') {
        return 'fa-at';
    }
    if (type === 'P') {
        return 'fa-envelope';
    }
});

/**
* Format the given date for forms.
*/
Vue.filter('formdate', value => {
    return moment.tz(value, timeZone).format('MM-DD-YYYY');
});

/**
* Pretty date for humans.
*/
Vue.filter('nicedate', value => {
    if (value) {
        return moment.tz(value, timeZone).format('dddd Do MMMM YYYY');
    } else {
        return '--'
    }
});

/**
* Short date with dots.
*/
Vue.filter('dotsdate', value => {
    if (value) {
        return moment.tz(value, timeZone).format('DD.MM.YYYY');
    } else {
        return '--'
    }
});

/**
* Pretty date for humans.
*/
Vue.filter('niceshortdate', value => {
    if (value) {
        return moment.tz(value, timeZone).format('Do MMM');
    } else {
        return '--'
    }
});

/**
* Just the day.
*/
Vue.filter('day', value => {
    if (value) {
        return moment(value).format('D')
    } else {
        return '--'
    }
});

/**
* Format the given RS API date.
*/
Vue.filter('rsdate', value => {
    var date = value.substr(0, value.length - 6);
    return moment.tz(date, timeZone).format('MM-DD-YYYY');
});

/**
* Format the given RS API date.
*/
Vue.filter('rsdateslash', value => {
    var date = value.substr(0, value.length - 6);
    return moment.tz(date, timeZone).format('DD/MM/YYYY');
});

/**
* Format the given RS API date to a nice date.
*/
Vue.filter('rsnicedate', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        return moment.tz(date, timeZone).format('dddd, Do MMM YYYY');
    }
});

/**
* Format the given RS API date to a nice  shorter date.
*/
Vue.filter('rsnicecompactdate', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        return moment.tz(date, timeZone).format('dddd Do MMM YYYY');
    }
});

/**
* Format the given RS API date to a short nice date.
*/
Vue.filter('rsshortdate', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        return moment.tz(date, timeZone).format('Do MMM');
    }
});

/**
* Format the given RS API date.
*/
Vue.filter('rsdatetime', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        var time = value.substr(10, 6);
        return moment.tz(date + 'T' + time.match(/.{1,2}/g).join(':'), timeZone).format('Do MMM YYYY, h:mma');
    }
});

/**
* Format the given RS API date.
*/
Vue.filter('rsdatetimeshort', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        var time = value.substr(10, 6);
        return moment.tz(date + 'T' + time.match(/.{1,2}/g).join(':'), timeZone).format('Do MMM, h:mma');
    }
});

/**
* Format the given RS API time.
*/
Vue.filter('rstime', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        var time = value.substr(10, 6);
        var cleaned = date + 'T' + time.match(/.{1,2}/g).join(':');
        //return cleaned;
        return moment.tz(cleaned, timeZone).format('h:mma');
    }
});

/**
* Format the given RS API time for classes.
*/
Vue.filter('classtime', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        var time = value.substr(10, 6);
        var cleaned = date + 'T' + time.match(/.{1,2}/g).join(':');
        //return cleaned;
        return moment.tz(cleaned, timeZone).format('HH:mm');
    }
});

/**
* Format the given time.
*/
Vue.filter('time', value => {
    return moment.tz(value, timeZone).format('hh:mm:ss')
});

/**
* Format the given time.
*/
Vue.filter('nicetime', value => {
    return moment.tz(value, timeZone).format('HH:mm')
});

/**
* Format the given time.
*/
Vue.filter('nicetimefromtime', value => {
    return moment.tz(moment().format('YYYY-MM-DD') + ' ' + value, timeZone).format('h:mma')
});

/**
* Format the given priority level.
*/
Vue.filter('priority', value => {
    var priorityList = { 1: 'High', 2: 'Medium', 3: 'Low' };

    return priorityList[value];
});

/**
* Format the given date.
*/
Vue.filter('date', value => {
    return moment.tz(value, timeZone).format('MMMM Do, YYYY')
});

/**
* Format the given date.
*/
Vue.filter('shortdate', value => {
    if (value) {
        var date = value.substr(0, value.length - 6);
        var time = value.substr(10, 6);
        return moment.tz(date + 'T' + time.match(/.{1,2}/g).join(':'), timeZone).format('Do MMM');
    }
});


/**
* Format the given date.
*/
Vue.filter('dateStandard', value => {
    return moment.tz(value, timeZone).format('YYYY-MM-DD')
});

/**
* Format the given date as a timestamp.
*/
Vue.filter('datetime', value => {
    return moment.tz(value, timeZone).format('MMMM Do, YYYY h:mm A');
});


/**
* Format the given date into a relative time.
*/
Vue.filter('relative', value => {
    return moment.tz(value, timeZone).locale('en-short').fromNow();
});

/**
* Format the given RS API price to GBP.
*/
Vue.filter('price', value => {
    if (value != '0.00') {
        if (+value % 1 == 0) {
            return parseInt(value, 10)
        } else {
            return +value
        }
    } else {
        return '--'
    }
});


/**
* Convert the first character to upper case.
*
* Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
*/
Vue.filter('capitalize', value => {
    if (!value && value !== 0) {
        return '';
    }

    return value.toString().charAt(0).toUpperCase() + value.slice(1);
});


/**
* Format the given money value.
*
* Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L70
*/
Vue.filter('currency', value => {
    value = parseFloat(value);

    if (!isFinite(value) || (!value && value !== 0)) {
        return '--';
    }

    if (value % 1 == 0) {
        return value.toFixed(0)
    } else {
        return value.toFixed(2)
    }
});

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.filter('giftcard_title', function (value) {
    if (!value) return '';
    return value.split('-')[0];
});


Vue.filter('treatment_title', function (value) {
    if (!value) return '';
    return value.split(' - ')[0];
});
