var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GmapMap",
    {
      staticStyle: { width: "100%", height: "300px" },
      attrs: { center: _vm.center, zoom: 10, options: _vm.options },
    },
    _vm._l(_vm.markers, function (m, index) {
      return _c("GmapMarker", {
        key: index,
        attrs: {
          position: m.position,
          icon: m.icon,
          clickable: true,
          draggable: true,
        },
        on: {
          click: function ($event) {
            _vm.center = m.position
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }