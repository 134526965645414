var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking-treatments" },
    [
      _c("progress-indicator", { attrs: { step: _vm.step } }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "booking-info" }, [
            _c("h2", [_vm._v("Book a Treatment")]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm._f("nicedate")(_vm.arrive)))]),
            _vm._v(" "),
            _c("div", { staticClass: "message-small mt-2 mb-2" }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.dateSideout.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Change date")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "booking-reference" }, [
              _vm.existingBookingApplied
                ? _c("div", [
                    _vm._v(
                      "\n                        Existing booking #" +
                        _vm._s(_vm.existingBooking) +
                        ".\n                        "
                    ),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.clearExistingBooking.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Change")]
                    ),
                  ])
                : _c("div", [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(
                        "\n                            Already have a booking? Please enter your\n                            booking reference to add treatment to your\n                            itinerary\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "form-inline justify-content-center mb-3",
                      },
                      [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.existingBooking,
                                expression: "existingBooking",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: {
                              readonly: _vm.existingBookingApplied,
                              type: "text",
                              id: "promoCode",
                              placeholder: "E.g. 102363",
                            },
                            domProps: { value: _vm.existingBooking },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.existingBooking = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-xs mb-0",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.applyExistingBooking.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Apply\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-8" }, [
          _c(
            "div",
            {
              staticClass:
                "alert alert-warning mt-2 d-block d-md-none d-lg-none d-xl-none",
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "categoryFilters" } }, [
                  _vm._v("Filter by category"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filteredCategory,
                        expression: "filteredCategory",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "categoryFilters" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.filteredCategory = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { selected: "", value: "All" } }, [
                      _vm._v("All"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Face" } }, [
                      _vm._v("Face"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Body / Massage" } }, [
                      _vm._v("Body / Massage"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Touch" } }, [
                      _vm._v("Touch"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Hair" } }, [
                      _vm._v("Hair"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Parlour" } }, [
                      _vm._v("Parlour"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Serenity" } }, [
                      _vm._v("Serenity"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Holistic" } }, [
                      _vm._v("Holistic"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Express" } }, [
                      _vm._v("Express"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Limited Edition" } }, [
                      _vm._v("Limited Edition"),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.treatments
            ? _c(
                "div",
                _vm._l(_vm.treatments, function (tc, tkk) {
                  return _c("div", { key: tkk, staticClass: "tab-cats" }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _vm.filteredCategories(tc.list).length > 0
                          ? _c("div", { staticClass: "col-md-12" }, [
                              _c("h3", [_vm._v(_vm._s(tc.cat.toLowerCase()))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.filteredCategories(tc.list),
                          function (treatment, tk) {
                            return _c(
                              "div",
                              { key: tk, staticClass: "col-md-12" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion",
                                    attrs: {
                                      id:
                                        "acc-" +
                                        tc.cat.replace(/[^a-zA-Z0-9-_]+/g, "-"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "accordion-item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "accordion-header",
                                            attrs: {
                                              id:
                                                "heading" + treatment.SpaItemId,
                                              "data-toggle": "collapse",
                                              "data-target":
                                                "#collapse" +
                                                treatment.SpaItemId,
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "collapse" +
                                                treatment.SpaItemId,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "clearfix" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "accordion-name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(treatment.ItemName)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "float-right",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "£" +
                                                          _vm._s(
                                                            _vm._f("price")(
                                                              treatment.Price
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass: "arrow",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "collapse",
                                            attrs: {
                                              id:
                                                "collapse" +
                                                treatment.SpaItemId,
                                              "aria-labelledby":
                                                "heading" + treatment.SpaItemId,
                                              "data-parent":
                                                "#acc-" +
                                                tc.cat.replace(
                                                  /[^a-zA-Z0-9-_]+/g,
                                                  "-"
                                                ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "accordion-body" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        treatment.ItemDesc
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                treatment.TreatmentTimes
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "treatment-times",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v("Select time"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          treatment.TreatmentTimes,
                                                          function (time, ttk) {
                                                            return _c(
                                                              "button",
                                                              {
                                                                key: ttk,
                                                                staticClass:
                                                                  "btn btn-tt mr-1",
                                                                class: {
                                                                  btnSelected:
                                                                    time ==
                                                                    _vm.selectedTime,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.selectTime(
                                                                        time
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "rstime"
                                                                      )(
                                                                        time.StartTime
                                                                      )
                                                                    ) +
                                                                    "\n                                                "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "clearfix",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-secondary btn-xs mr-1",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.selectedTime,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.addTreatmentToCart()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        Book\n                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass: "clearfix",
                                                      },
                                                      [
                                                        treatment.noAvailability
                                                          ? _c("div", [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "text-right text-uppercase ffs mt-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        Sorry, no\n                                                        availability\n                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _c("div", [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn ersed btn-xs float-right mt-2 mb-0 mr-2",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.loading,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return _vm.getTreatmentTimesV2(
                                                                          treatment
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        Choose a time\n                                                        "
                                                                  ),
                                                                  _vm.loading
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "spinner-border spinner-border-sm",
                                                                          attrs:
                                                                            {
                                                                              role: "status",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "room-full-description" }, [
                _c("p", [_vm._v("Loading treatments...")]),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "treatment-cart-overlay" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4 treatment-cart" }, [
          _c("div", { staticClass: "treatment-summary" }, [
            _c(
              "div",
              {
                staticClass:
                  "alert alert-warning mb-5 d-none d-md-block d-lg-block d-xl-block",
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "categoryFilters" } }, [
                    _vm._v("Filter by category"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filteredCategory,
                          expression: "filteredCategory",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "categoryFilters" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.filteredCategory = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { selected: "", value: "All" } }, [
                        _vm._v("All"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Face" } }, [
                        _vm._v("Face"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Body / Massage" } }, [
                        _vm._v(
                          "\n                                Body / Massage\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Touch" } }, [
                        _vm._v("Touch"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Hair" } }, [
                        _vm._v("Hair"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Parlour" } }, [
                        _vm._v("Parlour"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Serenity" } }, [
                        _vm._v("Serenity"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Holistic" } }, [
                        _vm._v("Holistic"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Express" } }, [
                        _vm._v("Express"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Limited Edition" } }, [
                        _vm._v(
                          "\n                                Limited Edition\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "h5",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleCart.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v("\n                    My Treatments"),
                _c("span", { staticClass: "badge badge-pill badge-primary" }, [
                  _vm._v(_vm._s(_vm.treatmentCount)),
                ]),
                _vm._v(" "),
                _vm.treatmentCount
                  ? _c("i", { staticClass: "arrow" })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "scroll-container" },
              _vm._l(_vm.treatmentsOnly, function (t) {
                return _c("div", { staticClass: "treatment-summary__item" }, [
                  _c("h6", [_vm._v(_vm._s(t.Details))]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("rsdatetimeshort")(t.StartDate))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "float-right" }, [
                      _vm._v("£" + _vm._s(t.AmountWithSurcharges)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2 text-small" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeTreatment(t)
                          },
                        },
                      },
                      [_vm._v("Remove")]
                    ),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.treatmentsOnly.length
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary ersed",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$router.push("/book/cart")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    I'm Ready to pay\n                "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "booking-app-sideout date-selector" }, [
        _c("div", { staticClass: "sideout-wrapper" }, [
          _c("div", { staticClass: "sideout-container" }, [
            _c("div", { staticClass: "sideout-content" }, [
              _c("div", { staticClass: "package-header" }, [
                _c(
                  "a",
                  {
                    staticClass: "prevNextArrow arrowLeft",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.dateSideout()
                      },
                    },
                  },
                  [_vm._v(" ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "package-content" }, [
                _c("div", { staticClass: "room-package" }, [
                  _c(
                    "div",
                    { staticClass: "room-full-description text-center" },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm._f("nicedate")(_vm.arrive))),
                      ]),
                      _vm._v(" "),
                      _c("rate-calendar", {
                        attrs: {
                          venue: _vm.venueName,
                          type: _vm.type,
                          arrive: _vm.arrive,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }