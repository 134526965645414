var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-summary-component" },
    [
      _c("h2", { staticClass: "d-none d-md-block" }, [_vm._v("My Booking")]),
      _vm._v(" "),
      _vm._l(_vm.folioByCat, function (folioItem, cat) {
        return _vm.folioByCat
          ? _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                cat === "Your Visit"
                  ? _c(
                      "div",
                      _vm._l(folioItem, function (item, rate) {
                        return _c("div", { staticClass: "cart-item" }, [
                          _c("span", { staticClass: "room-count" }, [
                            item.Location == "Hoar Cross Hall"
                              ? _c("span", [_vm._v("Room")])
                              : _c("span", [_vm._v("Guest")]),
                            _vm._v(
                              " " +
                                _vm._s(rate + 1) +
                                " - " +
                                _vm._s(item.Location)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(item.RateDetails) + " "),
                            _c("span", { staticClass: "cart-price" }, [
                              _vm._v("£" + _vm._s(item.RoomRate)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "h6",
                            { staticClass: "card-subtitle mb-2 text-muted" },
                            [
                              _vm._v(
                                _vm._s(_vm._f("rsshortdate")(item.StartDate))
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          item.extras
                            ? _c(
                                "div",
                                { staticClass: "cart-summary__extras" },
                                _vm._l(
                                  item.extras.specialServices
                                    .PMSFolioSpecialService,
                                  function (ss) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "cart-summary__extra-item",
                                      },
                                      [
                                        ss.PMSSpecialService.Price == "0.00"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    ss.PMSSpecialService
                                                      .PMSSpecialServiceName
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ])
                                          : _c("span", [
                                              _c("span", [
                                                _vm._v(_vm._s(ss.Qty) + " x "),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    ss.PMSSpecialService
                                                      .PMSSpecialServiceName
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "float-right" },
                                                [
                                                  _vm._v(
                                                    "£" +
                                                      _vm._s(
                                                        ss.TotalWithSurcharges
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                        _vm._v(" "),
                                        _vm.removing ==
                                        ss.PMSFolioSpecialServiceId
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "spinner-border spinner-border-sm",
                                                attrs: { role: "status" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]
                                            )
                                          : _c("span", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "cartSummaryRemove",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.removeExtras(
                                                        ss.PMSFolioSpecialServiceId,
                                                        item.extras
                                                          .specialServices
                                                          .PMSFolioId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-pill badge-remove",
                                                    },
                                                    [_vm._v("×")]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.Treatments
                            ? _c(
                                "div",
                                { staticClass: "cart-summary__extras" },
                                _vm._l(item.Treatments, function (treatment) {
                                  return _c(
                                    "div",
                                    { staticClass: "cart-summary__extra-item" },
                                    [
                                      _c("span", { staticClass: "mr-3" }, [
                                        _vm._v(_vm._s(treatment.Details)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("rstime")(
                                              treatment.StartDate
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.removing == treatment.FolioItemId
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "spinner-border spinner-border-sm",
                                              attrs: { role: "status" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "sr-only" },
                                                [_vm._v("Loading...")]
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "cartSummaryRemove",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.removeTreatment(
                                                      treatment.FolioId,
                                                      treatment.FolioItemId,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-pill badge-remove",
                                                  },
                                                  [_vm._v("×")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                      _vm._v(" "),
                                      treatment.Amount != "0.00"
                                        ? _c(
                                            "span",
                                            { staticClass: "float-right" },
                                            [
                                              _vm._v(
                                                "£" + _vm._s(treatment.Amount)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }, [
                            _c(
                              "a",
                              {
                                staticClass: "remove-booking",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeBooking(item.FolioId)
                                  },
                                },
                              },
                              [_vm._v("Remove booking")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "sub-total" }, [
                              _vm._v(
                                "£" +
                                  _vm._s(
                                    (
                                      Math.round(
                                        (+item.Amount + +item.TreatmentTotal) *
                                          100
                                      ) / 100
                                    ).toFixed(2)
                                  )
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c(
                      "div",
                      _vm._l(folioItem, function (item) {
                        return _c("div", { staticClass: "cart-item" }, [
                          _c("span", { staticClass: "room-count" }, [
                            _vm._v(_vm._s(cat)),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(item.Details) + " "),
                            _c(
                              "a",
                              {
                                staticClass: "cartSummaryRemove",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeGiftCard(
                                      item.FolioId,
                                      item.FolioItemId
                                    )
                                  },
                                },
                              },
                              [_vm._v("Remove")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "cart-price" }, [
                              _vm._v("£" + _vm._s(item.Amount)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "ffs small mb-0" }, [
                            _vm._v("Recipient: " + _vm._s(item.CustomerName)),
                          ]),
                          _vm._v(" "),
                          item.SubItems
                            ? _c("div", { staticClass: "small" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.SubItems.SubItem.Details) +
                                    " - £" +
                                    _vm._s(item.SubItems.SubItem.Amount) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
              ]),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _c("div", { staticClass: "cart-summary-component__footer" }, [
        _vm.cart.FolioItems > 0
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass: "restart",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearCart.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Restart booking")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cart-summary__balance clearfix" },
                [
                  _c("span", { staticClass: "cb_amount" }, [
                    _vm._v("£" + _vm._s(_vm.cart.FolioBalance)),
                  ]),
                  _vm._v(" "),
                  _vm.next && _vm.next !== "cart"
                    ? _c(
                        "router-link",
                        {
                          staticClass:
                            "btn btn-reversed btn-sm ml-2 float-right",
                          attrs: { to: "/book/" + _vm.next },
                        },
                        [_vm._v("Next")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.next == "cart"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-reversed btn-sm float-right",
                          attrs: { to: "/book/cart" },
                        },
                        [_vm._v("Next")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "arrow",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.mobileToggle.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _c("div", [_vm._v("\n            Your cart is empty\n        ")]),
      ]),
      _vm._v(" "),
      _vm.updatingCart
        ? _c("div", { staticClass: "updating-cart" }, [_vm._m(0)])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("p", { staticClass: "ffs text-uppercase" }, [_vm._v("Updating cart")]),
      _vm._v(" "),
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }