<template>
    <div>
        <a href="/hoar-cross-hall" v-if="currentVenue == 'hoar-cross-hall'">
            <img
                src="/img/hch_name_only.svg"
                alt="Hoar Cross Hall logo"
                loading="lazy"
            />
        </a>
        <a href="/eden-hall" v-else-if="currentVenue == 'eden-hall'">
            <img
                src="/img/eh_name_logo.svg"
                alt="Eden Hall logo"
                loading="lazy"
            />
        </a>
        <a href="/" v-else>
            <img
                src="/img/be_logo_bare.svg"
                alt="Barons Eden logo"
                loading="lazy"
            />
        </a>
    </div>
</template>

<script>
export default {
    props: {
        venue: String,
    },
    /**
     * The components data
     */
    data() {
        return {};
    },

    computed: {
        currentVenue() {
            if (this.venue) {
                return this.venue;
            } else {
                if (this.$route.params.venue) {
                    return this.$route.params.venue;
                } else {
                    return "barons-eden";
                }
            }
        },
    },

    /**
     * Methods
     */
    methods: {
        logout() {
            axios.get("/rs/logout").then((response) => {
                Bus.$emit("RefreshSystem");
                this.$router.push("/book");
            });
        },
    },
};
</script>
