<template>
    <div class="gift-cards">
        <div class="row justify-content-md-center">
            <div class="col-md-8 col-lg-6">
                <div class="form-row mb-3 justify-content-md-center">
                    <div
                        class="btn-group btn-group-sm m-auto"
                        role="group"
                        aria-label="Select Location"
                        v-if="this.$screen.width > 580"
                    >
                        <button
                            type="button"
                            class="btn btn-link"
                            @click="venue = 'All locations'"
                            :class="{ active: venue == 'All locations' }"
                        >
                            All locations
                        </button>
                        <button
                            type="button"
                            class="btn btn-link"
                            @click="venue = 'Hoar Cross Hall'"
                            :class="{ active: venue == 'Hoar Cross Hall' }"
                        >
                            Hoar Cross Hall
                        </button>
                        <button
                            type="button"
                            class="btn btn-link"
                            @click="venue = 'Eden Hall'"
                            :class="{ active: venue == 'Eden Hall' }"
                        >
                            Eden Hall
                        </button>
                    </div>
                    <select
                        v-if="this.$screen.width < 580"
                        class="form-control"
                        id="venue"
                        v-model="venue"
                    >
                        <option value="All locations">All locations</option>
                        <option value="Hoar Cross Hall">Hoar Cross Hall</option>
                        <option value="Eden Hall">Eden Hall</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="filteredGC('stays').length > 0">
            <div class="col-md-12">
                <h3 class="archive-card__categories">Hotel & Spa Stays</h3>
            </div>
        </div>
        <div class="row">
            <div
                v-for="card in filteredGC('stays')"
                class="col-md-6 col-lg-4"
                v-if="filteredGC('stays').length > 0"
            >
                <div class="archive-card mb-0">
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.bestseller"
                    >
                        <img
                            class="img-fluid"
                            src="/img/bestseller.webp"
                            loading="lazy"
                        />
                    </div>
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.most_loved"
                    >
                        <img
                            class="img-fluid"
                            src="/img/most-loved.webp"
                            loading="lazy"
                        />
                    </div>
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.black_friday"
                    >
                        <img
                            class="img-fluid"
                            src="/img/black_friday.webp"
                            loading="lazy"
                        />
                    </div>
                    <a
                        :href="'/gift-cards/' + card.slug"
                        class="archive-card__image"
                    >
                        <img
                            :src="'/storage/' + card.img"
                            :title="card.title"
                            loading="lazy"
                        />
                    </a>
                    <div class="archive-card__body">
                        <h2>
                            <a :href="'/gift-cards/' + card.slug">{{
                                card.title
                            }}</a>
                        </h2>
                        <h5 class="archive-card__location">
                            {{ card.location }}
                        </h5>
                        <div
                            class="archive-card__description"
                            v-html="card.thumbnail_description"
                        ></div>
                        <span class="archive-card__price">{{
                            card.tag_line
                        }}</span>
                        <a
                            :href="'/gift-cards/' + card.slug"
                            alt="Buy Now"
                            class="btn btn-small btn-primary"
                            >Buy Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <hr
            v-if="
                filteredGC('days').length > 0 && filteredGC('stays').length > 0
            "
        />
        <div class="row" v-if="filteredGC('days').length > 0">
            <div class="col-md-12">
                <h3 class="archive-card__categories">Spa Days</h3>
            </div>
        </div>
        <div class="row">
            <div
                v-for="card in filteredGC('days')"
                class="col-md-6 col-lg-4"
                v-if="filteredGC('days').length > 0"
            >
                <div class="archive-card mb-0">
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.bestseller"
                    >
                        <img
                            class="img-fluid"
                            src="/img/bestseller.webp"
                            loading="lazy"
                        />
                    </div>
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.most_loved"
                    >
                        <img
                            class="img-fluid"
                            src="/img/most-loved.webp"
                            loading="lazy"
                        />
                    </div>
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.black_friday"
                    >
                        <img
                            class="img-fluid"
                            src="/img/black_friday.webp"
                            loading="lazy"
                        />
                    </div>
                    <a
                        :href="'/gift-cards/' + card.slug"
                        class="archive-card__image"
                    >
                        <img
                            :src="'/storage/' + card.img"
                            :title="card.title"
                            loading="lazy"
                        />
                    </a>
                    <div class="archive-card__body">
                        <h2>
                            <a :href="'/gift-cards/' + card.slug">{{
                                card.title
                            }}</a>
                        </h2>
                        <h5 class="archive-card__location">
                            {{ card.location }}
                        </h5>
                        <div
                            class="archive-card__description"
                            v-html="card.thumbnail_description"
                        ></div>
                        <span class="archive-card__price">{{
                            card.tag_line
                        }}</span>
                        <a
                            :href="'/gift-cards/' + card.slug"
                            alt="Buy Now"
                            class="btn btn-small btn-primary"
                            >Buy Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <hr v-if="filteredGC('more').length > 0" />
        <div class="row" v-if="filteredGC('more').length > 0">
            <div class="col-md-12">
                <h3 class="archive-card__categories">Treats</h3>
            </div>
        </div>
        <div class="row">
            <div
                v-for="card in filteredGC('more')"
                class="col-md-6 col-lg-4"
                v-if="filteredGC('more').length > 0"
            >
                <div class="archive-card mb-0">
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.bestseller"
                    >
                        <img
                            class="img-fluid"
                            src="/img/bestseller.webp"
                            loading="lazy"
                        />
                    </div>
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.most_loved"
                    >
                        <img
                            class="img-fluid"
                            src="/img/most-loved.webp"
                            loading="lazy"
                        />
                    </div>
                    <div
                        class="archive-card__badge"
                        v-if="card.badges !== null && card.badges.black_friday"
                    >
                        <img
                            class="img-fluid"
                            src="/img/black_friday.webp"
                            loading="lazy"
                        />
                    </div>
                    <a
                        :href="'/gift-cards/' + card.slug"
                        class="archive-card__image"
                    >
                        <img
                            :src="'/storage/' + card.img"
                            :title="card.title"
                            loading="lazy"
                        />
                    </a>
                    <div class="archive-card__body">
                        <h2>
                            <a :href="'/gift-cards/' + card.slug">{{
                                card.title
                            }}</a>
                        </h2>
                        <h5 class="archive-card__location">
                            {{ card.location }}
                        </h5>
                        <div
                            class="archive-card__description"
                            v-html="card.thumbnail_description"
                        ></div>
                        <span class="archive-card__price">{{
                            card.tag_line
                        }}</span>
                        <a
                            :href="'/gift-cards/' + card.slug"
                            alt="Buy Now"
                            class="btn btn-small btn-primary"
                            >Buy Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            venue: "All locations",
            cards: [],
        };
    },

    mounted() {
        this.getFeed();
    },

    methods: {
        filteredGC(category = null) {
            var self = this;

            if (this.cards) {
                return this.cards.filter(function (card) {
                    if (self.venue == "All locations") {
                        return card && card.category == category;
                    } else {
                        return (
                            card.location == self.venue &&
                            card.category == category
                        );
                    }
                });
            }
        },
        getFeed() {
            axios.get("/gift-cards/data/feed").then((response) => {
                this.cards = response.data.gift_cards;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.gift-cards {
    .col-md-6 {
        margin-bottom: 40px;

        .archive-card {
            height: 100%;

            .btn {
                position: absolute;
                bottom: 0px;
                right: 10px;
                font-size: 12px;
                padding: 2px 10px;

                @media (max-width: 767px) {
                    position: relative;
                    bottom: auto;
                    right: auto;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>
