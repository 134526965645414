var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gift-card-component" }, [
    _c(
      "form",
      {
        staticClass: "gift-card-order",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.addToCart.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "voucher-tabs" }, [
          _c("div", { staticClass: "gift-card-order__price" }, [
            _vm._v("Price: £" + _vm._s(_vm.giftCard.Price)),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h5", { staticClass: "mb-4" }, [
            _vm._v("It's time to tailor your gift..."),
          ]),
          _vm._v(" "),
          _c("h5", [_vm._v("Value")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row mb-3" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.giftCard,
                      expression: "giftCard",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { name: "value" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.giftCard = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.packages, function (gc) {
                  return _c("option", { domProps: { value: gc } }, [
                    _vm._v(_vm._s(gc.Name)),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("h5", [_vm._v("Recipient")]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("h5", [_vm._v("Name")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-8" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.emailOrderForm.IssueToName,
                    expression: "emailOrderForm.IssueToName",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "IssueToName",
                  placeholder: "Enter recipients name",
                  required: "",
                },
                domProps: { value: _vm.emailOrderForm.IssueToName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.emailOrderForm,
                      "IssueToName",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-12" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.emailOrderForm.Message,
                    expression: "emailOrderForm.Message",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  cols: "8",
                  rows: "6",
                  id: "Message",
                  placeholder: "Enter your message (max 300 characters)",
                  maxlength: "300",
                },
                domProps: { value: _vm.emailOrderForm.Message },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.emailOrderForm, "Message", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "small" }, [
                _vm._v(
                  "Characters remaining: " +
                    _vm._s(300 - _vm.emailOrderForm.Message.length)
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("h5", { staticClass: "mt-3" }, [_vm._v("Select your type")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "ul",
                {
                  staticClass: "nav nav-tabs",
                  attrs: { id: "voucherTabs", role: "tablist" },
                },
                [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { active: _vm.voucherType == "CERT" },
                        attrs: {
                          id: "cert-tab'",
                          "data-toggle": "tab",
                          role: "tab",
                          "aria-controls": "cert-tab",
                          "aria-selected": { true: _vm.voucherType == "CERT" },
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectVoucherType("CERT")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                E-Voucher\n                            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { active: _vm.voucherType == "CARD" },
                        attrs: {
                          id: "card-tab'",
                          "data-toggle": "tab",
                          role: "tab",
                          "aria-controls": "card-tab",
                          "aria-selected": { true: _vm.voucherType == "CARD" },
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectVoucherType("CARD")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                Gift Card\n                            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-content",
                  attrs: { id: "voucherTabsContent" },
                },
                [
                  _c("p", [
                    _vm._v(
                      "The delivery address is the address of the person you would like us to send the gift card to. If you would like this delivered to yourself, please enter your own address."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.voucherType == "CERT",
                          expression: "voucherType == 'CERT'",
                        },
                      ],
                      attrs: {
                        id: "certPanel",
                        role: "tabpanel",
                        "aria-labelledby": "certPanel-tab",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailOrderForm.EmailAddress,
                                expression: "emailOrderForm.EmailAddress",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "email",
                              id: "EmailAddress",
                              placeholder: "Enter email address",
                              required: _vm.voucherType == "CERT",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.EmailAddress,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm,
                                  "EmailAddress",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-10 mb-0" },
                          [
                            _vm.voucherError
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v(_vm._s(_vm.voucherError)),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.voucherType == "CARD",
                          expression: "voucherType == 'CARD'",
                        },
                      ],
                      attrs: {
                        id: "cardPanel",
                        role: "tabpanel",
                        "aria-labelledby": "cardPanel-tab",
                      },
                    },
                    [
                      _vm.giftCard && _vm.giftCard.shippingRates
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-12" }, [
                              _c("h5", [_vm._v("Select postage")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btn-group",
                                  attrs: {
                                    role: "group",
                                    "aria-label": "Postage Options",
                                  },
                                },
                                _vm._l(
                                  _vm.giftCard.shippingRates,
                                  function (s) {
                                    return s.Price
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-shipping",
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                s.ShippingId ==
                                                _vm.shippingSelected.ShippingId,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.selectShippingType(s)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(s.Name))]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _vm.shippingSelected
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "small d-block text-uppercase fwb",
                                      },
                                      [_vm._v("Shipping selected:")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.shippingSelected.Name) +
                                        " - £" +
                                        _vm._s(
                                          _vm.shippingSelected
                                            .PriceWithSurcharges
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.Address1,
                                expression:
                                  "emailOrderForm.MailToAddress.Address1",
                              },
                            ],
                            ref: "Address1",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "Address1",
                              placeholder: "Address line 1",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.Address1,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "Address1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.Address2,
                                expression:
                                  "emailOrderForm.MailToAddress.Address2",
                              },
                            ],
                            ref: "Address2",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "Address2",
                              placeholder: "Address line 2",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.Address2,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "Address2",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailOrderForm.MailToAddress.City,
                                expression: "emailOrderForm.MailToAddress.City",
                              },
                            ],
                            ref: "City",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "City",
                              placeholder: "City",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.City,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "City",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-4" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.PostCode,
                                expression:
                                  "emailOrderForm.MailToAddress.PostCode",
                              },
                            ],
                            ref: "PostCode",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "PostCode",
                              placeholder: "Post Code",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.PostCode,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "PostCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.emailOrderForm.MailToAddress.Country,
                                  expression:
                                    "emailOrderForm.MailToAddress.Country",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { name: "Country" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.emailOrderForm.MailToAddress,
                                    "Country",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.countryList, function (country) {
                              return _c(
                                "option",
                                { domProps: { value: country } },
                                [_vm._v(_vm._s(country))]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _vm.voucherError
                            ? _c("span", { staticClass: "small" }, [
                                _vm._v(_vm._s(_vm.voucherError)),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-row mt-3" }, [
                _c("div", { staticClass: "form-group col-md-12" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "/gift-cards" },
                    },
                    [_vm._v("Back to all Gift Cards")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-reversed",
                      attrs: { type: "submit", disabled: _vm.isDisabled },
                    },
                    [_vm._v("Add to cart")]
                  ),
                  _vm._v(" "),
                  _vm.addingGc
                    ? _c(
                        "div",
                        {
                          staticClass: "spinner-border spinner-border-md",
                          attrs: { role: "status" },
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Loading..."),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gift-card-cart" },
      [
        _c("gift-card-cart", {
          attrs: { system: _vm.system, cart: _vm.cart, next: "cart" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section-notice text-center mb-4" }, [
      _c("p", [
        _vm._v(
          "Your preferred value not showing? Fear not, our Reservations\n                    Team can create a custom gift card or e-voucher just for you."
        ),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "icons",
          attrs: {
            onclick: "leadContact('Barons Eden');",
            "data-toggle": "modal",
            "data-target": "#contact-modal",
          },
        },
        [_vm._v("Call Reservations")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Please enter your personalised message below"),
      _c("br"),
      _vm._v("(this will be printed on the gift)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", [
      _vm._v("Message "),
      _c("span", { staticClass: "small" }, [_vm._v("(optional)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-9" }, [
        _c("h5", [_vm._v("Delivery address")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("p", { staticClass: "small" }, [
          _vm._v(
            "Please note, we allow a 48 hour processing time for all postal gift cards. Delivery by Royal Mail can take 5 -7 days, and we are unable to take any responsibility for Royal Mail delays."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }