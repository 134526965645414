<template>
    <div class="row">
        <div v-for="article in feed" class="col-md-4">
            <div class="archive-card">
                <a :href="'/' + article.slug">
                    <div class="archive-card__image" :style="'background-image:url(/storage/' +article.img+ ');'">&nbsp;</div>
                    <div class="archive-card__header">
                        <p>{{ article.date_line }}</p>
                        <h2>{{ article.title }}</h2>
                    </div>
                </a>
                <div class="archive-card__body">
                    {{ article.summary }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            limit: Number,
            location: Number
        },

        components: {
            
        },

        data() {
            return {
                feed: null
            }
        },
        
        mounted() {
            console.log('Component mounted')
            this.getFeed()
        },

        watch: {
            
        },

        methods: {
            getFeed() {
                axios.get('/feeds/members/events/' + this.location + '/' + this.limit)
                    .then(response=> {
                        this.feed = response.data
                    });
            }
        },
    }
</script>
