<template>
    <div class="cart-summary-component">
        <h2>My Cart</h2>
        <div v-if="folioByCat" class="card" v-for="(folioItem, cat) in folioByCat">
            <div class="card-body">
                <div v-if="cat === 'Your Visit'">
                    <div v-for="(item, rate) in folioItem" class="cart-item">
                        <span class="room-count">Booking {{ rate+1 }} - {{ item.Location }}</span>
                        <h5 class="card-title mb-2">{{ item.RateDetails }} <span class="cart-price">&pound;{{item.RoomRate}}</span></h5>
                        <h6 class="card-subtitle mb-0 text-muted">{{ item.StartDate | rsshortdate }}</h6>
                        
                        <div v-if="item.extras" class="cart-summary__extras">
                            <div v-for="ss in item.extras.specialServices.PMSFolioSpecialService" class="cart-summary__extra-item">
                                
                                <span>{{ss.Qty}} x </span> {{ss.PMSSpecialService.PMSSpecialServiceName}} 
                                <span v-if="removing == ss.PMSFolioSpecialServiceId" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                                <span v-else>
                                    <a class="cartSummaryRemove" @click.prevent="removeExtras(ss.PMSFolioSpecialServiceId, item.extras.specialServices.PMSFolioId)"><span class="badge badge-pill badge-remove">&times;</span></a> 
                                </span>
                                <span class="float-right">&pound;{{ss.TotalWithSurcharges}}</span>
                            </div>
                        </div>
                        <div v-if="item.Treatments" class="cart-summary__extras">
                            <div v-for="treatment in item.Treatments" class="cart-summary__extra-item">
                                
                                {{ treatment.Details }} 
                                <span v-if="removing == treatment.FolioItemId" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                                <span v-else>
                                    <a class="cartSummaryRemove" @click.prevent="removeTreatment(treatment.FolioId, treatment.FolioItemId, true)"><span class="badge badge-pill badge-remove">&times;</span></a> 
                                </span>
                                <span class="float-right">&pound;{{treatment.Amount}}</span>
                            </div>
                        </div>
                        <div class="clearfix">
                            <a class="remove-booking" @click.prevent="removeBooking(item.FolioId)">Remove booking</a>
                            <span class="sub-total">£{{ (Math.round((+item.Amount + +item.TreatmentTotal) * 100) / 100).toFixed(2) }}</span>
                        </div>
                        
                    </div>
                </div>
                <div v-else> <!-- retail -->
                    <div v-for="item in folioItem" class="cart-item">
                        <span class="room-count">{{ cat }}</span>
                        <h5 class="card-title">{{ item.Details }} <a class="cartSummaryRemove" @click.prevent="removeGiftCard(item.FolioId, item.FolioItemId)">Remove</a> <span class="cart-price">&pound;{{item.AmountWithSurcharges}}</span></h5>
                        <p class="ffs small mb-0">Recipient: {{ item.CustomerName }}</p>
                        <div class="small" v-if="item.SubItems">
                            {{ item.SubItems.SubItem.Details }} - &pound;{{ item.SubItems.SubItem.Amount }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cart-summary-component__footer">
            <div v-if="cart.FolioItems > 0">
                <div class="cart-summary__balance clearfix">
                    <span class="cb_amount">£{{cart.FolioBalance}}</span>
                    <a href="/book/cart" class="btn btn-primary float-right">Pay Now</a>
                </div>
            </div>
            <div v-else>
                Your cart is empty
            </div>
        </div>

        <div v-if="updatingCart" class="updating-cart">
            <div class="text-center">
                <p>Updating cart</p>
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            next: String,
            system: Object,
            cart: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                isActive: true,
                removing: false,
                ssTotal: 0,
                updatingCart: null
            }
        },
        mounted() {
            Bus.$emit('StartGiftCards');
        },
        beforeMount() {
            Bus.$on('clearCartFromSummary', () => {
                this.confirmClearCart();
            });
            Bus.$on('updatingCartComponent', () => {
                this.updatingCart = true;
            });
        },
        computed: {
            folioByCat() {
                var cats = _.groupBy(this.cart.WebFolioItem, 'Category');
                if(cats['Hotel']) {
                    cats['Your Visit'] = cats['Hotel'];
                    delete cats['Hotel']
                }
                if(cats['Spa']) {
                    cats['Treatments'] = cats['Spa']
                    delete cats['Spa']
                }
                if(cats['Retail']) {
                    cats['Gift Cards'] = cats['Retail']
                    delete cats['Retail']
                }
                return cats;
            },
        },
        watch: {
            cart() {
                this.updatingCart = null
            },
        },
        /**
         * Methods
         */
        methods: {
            
            clearCart() {
                var self = this;
                _.each(this.cart.WebFolioItem, function(item) {
                    self.removeBooking(item.FolioId);
                })
                this.$router.push('/book')
            },
            removeBooking(item) {
                var self = this
                this.updatingCart = true;

                var booking = _.find(self.cart.WebFolioItem, {'FolioId': item})

                if(booking) {
                    // Remove any treatments first
                    if(booking.Treatments) {
                        _.each(booking.Treatments, function(treatment) {
                            self.removeTreatment(treatment.FolioId, treatment.FolioItemId, false)
                        })
                    }

                    var request = {
                        method: '',
                        data: {
                            SessionId: this.system.SessionId,
                            WebFolioId: this.system.WebFolioId,
                        }
                    }
                    
                    // Gift card cancel request
                    if(booking.Category == 'Retail') {
                        request.method = 'CancelRetailItem';
                        request.data.RetFolioId = booking.FolioId;
                        request.data.RetFolioItemId = booking.FolioItemId;
                    }

                    // PMScancel request
                    if(booking.Category == 'Hotel') {
                        request.method = 'CancelPMSBooking';
                        request.data.PMSFolioId = booking.FolioId;
                    }
                    
                    axios.post('/rs/request', request)
                        .then(response=> {
                            if(response.data.Result.value === 'SUCCESS') {
                                Bus.$emit('UpdateCart');
                                
                            }
                        });
                }
            },
            
            removeTreatment(SpaFolioId, SpaFolioItemId, updateCart) {
                this.removing = SpaFolioItemId;
                var request = {
                    method: 'CancelSpaService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        SpaFolioId: SpaFolioId,
                        SpaFolioItemId: SpaFolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.removing = null
                            if(updateCart) {
                                Bus.$emit('UpdateCart');
                            }
                        }
                    });
            },
            removeExtras(id, folio) {
                this.removing = id;
                var request = {
                    method: 'RemovePMSSpecialService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        PMSFolioId: folio,
                        PMSFolioSpecialServiceId: id
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.removing = null
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            removeGiftCard(RetFolioId, RetFolioItemId) {
                var request = {
                    method: 'CancelRetailItem',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        RetFolioId: RetFolioId,
                        RetFolioItemId: RetFolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            viewCart() {
                this.$router.push('/book/cart')
            },
            
        }
    }
</script>