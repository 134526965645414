import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies';
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment-timezone';
import VueScreen from 'vue-screen';
import VTooltip from 'v-tooltip'
import Plyr from 'plyr';

require('./bootstrap');

window.sha256 = require('js-sha256');
window.Vue = require("vue").default;
window.Bus = new Vue();
window.URI = require('urijs');
window.Cookies = require('js-cookie')

require('./filters');
require('./analytics');
require('./mixins');


Vue.use(VueScreen);
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(VTooltip);

/**
 * Vue components, around the site.
 */
Vue.component('test-component', require('./components/TestComponent.vue').default);

Vue.component('membership-form-component', require('./components/MembershipFormComponent.vue').default);
Vue.component('new-member-enquiry', require('./components/NewMemberEnquiry.vue').default);
Vue.component('member-get-member-component', require('./components/MemberGetMemberComponent.vue').default);
Vue.component('download-form-component', require('./components/DownloadFormComponent.vue').default);
Vue.component('generic-download-component', require('./components/GenericDownloadComponent.vue').default);
Vue.component('generic-contact-enquiry', require('./components/GenericContactEnquiry.vue').default);
Vue.component('apprenticeship-form', require('./components/ApprenticeshipForm.vue').default);
Vue.component('competition-subscribe-component', require('./components/CompetitionSubscribeComponent.vue').default);
Vue.component('treatment-class-availability', require('./components/TreatmentClassAvailability.vue').default);
Vue.component('treatment-availability', require('./components/TreatmentAvailability.vue').default);
Vue.component('class-availability', require('./components/ClassAvailability.vue').default);
Vue.component('treatments-feed', require('./components/TreatmentsFeed.vue').default);

Vue.component('map-component', require('./components/MapComponent.vue').default);
Vue.component('subscribe-component', require('./components/SubscribeComponent.vue').default);
Vue.component('subscribe-cta', require('./components/SubscribeCta.vue').default);
Vue.component('subscribe-modal', require('./components/SubscribeModal.vue').default);
Vue.component('contact-component', require('./components/ContactComponent.vue').default);
Vue.component('modal-component', require('./components/ModalComponent.vue').default);
Vue.component('gift-cards-feed', require('./components/GiftCardsFeed.vue').default);
Vue.component('drop-hint-component', require('./components/DropHintComponent.vue').default);
Vue.component('collection-component', require('./components/CollectionComponent.vue').default);
Vue.component('marketing-subscribe', require('./components/MarketingSubscribe.vue').default);

Vue.component('monetary-gift-cards', require('./components/MonetaryGiftCards.vue').default);
Vue.component('package-gift-cards', require('./components/PackageGiftCards.vue').default);

Vue.component('stay-book-component', require('./components/StayBookComponent.vue').default);
Vue.component('spa-book-component', require('./components/SpaBookComponent.vue').default);
Vue.component('new-package-calendar', require('./components/NewPackageCalendar.vue').default);

Vue.component('hch-home-feed', require('./components/HchHomeFeed.vue').default);
Vue.component('eden-home-feed', require('./components/EdenHomeFeed.vue').default);

Vue.component('blog-feed', require('./components/BlogFeed.vue').default);
Vue.component('journal-feed', require('./components/JournalFeed.vue').default);
Vue.component('events-feed', require('./components/EventsFeed.vue').default);

Vue.component('pre-arrival-component', require('./components/PreArrivalComponent.vue').default);
Vue.component('pre-arrival-public-component', require('./components/PreArrivalPublicComponent.vue').default);

/**
 * Vue Components (Booking App)
 */
Vue.component('cart-summary-component', require('./book/CartSummaryComponent.vue').default);
Vue.component('login-modal', require('./book/LoginModal.vue').default);
Vue.component('login-register', require('./book/LoginRegister.vue').default);
Vue.component('register-modal', require('./book/RegisterModal.vue').default);
Vue.component('forgot-password-modal', require('./book/ForgotPasswordModal.vue').default);
Vue.component('logout-component', require('./book/LogoutComponent.vue').default);
Vue.component('cart-nav-icon', require('./book/CartNavIcon.vue').default);
Vue.component('cart-dropdown-component', require('./book/CartDropdownComponent.vue').default);
Vue.component('dynamic-logo-component', require('./book/DynamicLogoComponent.vue').default);
Vue.component('rate-calendar', require('./book/RateCalendar.vue').default);

const Home = Vue.component('home-component', require('./book/HomeComponent.vue').default);
const Start = Vue.component('start-component', require('./book/StartComponent.vue').default);
const Search = Vue.component('search-component', require('./book/SearchComponent.vue').default);
const Stay = Vue.component('stay-component', require('./book/StayComponent.vue').default);
const Spa = Vue.component('spa-component', require('./book/SpaComponent.vue').default);
const Enhance = Vue.component('enhance-component', require('./book/EnhanceComponent.vue').default);
const FinishingTouches = Vue.component('finishing-touches-component', require('./book/FinishingTouchesComponent.vue').default);
//const Extras = Vue.component('extras-component', require('./book/ExtrasComponent.vue').default);
const AfternoonTea = Vue.component('afternoon-tea-component', require('./book/AfternoonTeaComponent.vue').default);
const Treatments = Vue.component('treatments-component', require('./book/TreatmentsComponent.vue').default);
const Cart = Vue.component('cart-component', require('./book/CartComponent.vue').default);
const Checkout = Vue.component('checkout-component', require('./book/CheckoutComponent.vue').default);
const Success = Vue.component('checkout-success-component', require('./book/CheckoutSuccessComponent.vue').default);
const Account = Vue.component('account-component', require('./book/AccountComponent.vue').default);
const PasswordReset = Vue.component('password-reset-component', require('./book/PasswordResetComponent.vue').default);
const RequestPasswordReset = Vue.component('request-password-reset', require('./book/RequestPasswordReset.vue').default);


/**
 * Vue Components (Portal)
 */
const PortalHome = Vue.component('portal-home', require('./membership/PortalHome.vue').default);
Vue.component('portal-login', require('./membership/PortalLoginComponent.vue').default);
Vue.component('portal-logout', require('./membership/PortalLogout.vue').default);
Vue.component('member-account', require('./membership/MemberAccount.vue').default);
Vue.component('book-classes', require('./membership/BookClasses.vue').default);
Vue.component('class-bookings', require('./membership/ClassBookings.vue').default);
Vue.component('member-details', require('./membership/MemberDetails.vue').default);
Vue.component('members-cart', require('./membership/MembersCart.vue').default);
Vue.component('member-benefit-feed', require('./membership/MemberBenefitFeed.vue').default);
Vue.component('member-benefit-carousel', require('./membership/MemberBenefitCarousel.vue').default);
Vue.component('portal-password-reset', require('./membership/PortalPasswordReset.vue').default);

Vue.component('password-checker', require('./membership/PasswordChecker.vue').default);

/**
 * Routes (Booking App)
 */
const routes = [
    {
        name: 'book',
        path: '/book',
        component: Home,
        props: true
    },
    {
        name: 'book-start',
        path: '/book/start/:venue',
        component: Start,
        props: true
    },
    {
        name: 'book-afternoon-tea',
        path: '/book/search/:venue/afternoon-tea/:step?',
        component: AfternoonTea,
        props: true
    },
    {
        name: 'book-search',
        path: '/book/search/:venue/:type',
        component: Search,
        props: true
    },
    {
        name: 'book-stay',
        path: '/book/booking/:venue/stay/:arrive?/:nights?/:guests?/:rate?',
        component: Stay,
        props: { venue: 'hoar-cross-hall', type: 'stay' }
    },
    {
        name: 'book-hotel-stay',
        path: '/book/booking/:venue/hotel-stay/:arrive?/:nights?/:guests?/:rate?',
        component: Stay,
        props: { venue: 'hoar-cross-hall', type: 'hotel-stay' }
    },
    {
        name: 'book-spa',
        path: '/book/booking/:venue/spa-day/:arrive?/:guests?/:rate?',
        component: Spa,
        props: { type: 'spa-day' }
    },
    {
        name: 'enhance',
        path: '/book/enhance/:venue/:type/:arrive?/:nights?/:guests?/:rate?',
        component: Enhance,
        props: true
    },
    {
        name: 'finishing-touches',
        path: '/book/finishing-touches/:venue/:type/:arrive?/:nights?/:guests?/:rate?',
        component: FinishingTouches,
        props: true
    },
    {
        name: 'book-eh-spa',
        path: '/book/booking/eden-hall/spa-day/:arrive?/:guests?/:rate?',
        component: Spa,
        props: { type: 'spa-day' }
    },
    /*
    {
        name: 'extras',
        path: '/book/extras/:venue/:type/:arrive?/:guests?/:rate?',
        component: Extras,
        props: true
    },*/
    {
        name: 'treatments',
        path: '/book/treatments/:venue/:type/:arrive?/:guests?/:rate?',
        component: Treatments,
        props: true
    },
    {
        name: 'cart',
        path: '/book/cart',
        component: Cart,
        props: true
    },
    {
        name: 'checkout',
        path: '/book/checkout',
        component: Checkout,
        props: true
    },
    {
        name: 'success',
        path: '/book/success',
        component: Success,
        props: true
    },
    {
        name: 'account',
        path: '/book/account',
        component: Account,
        props: true
    },
    {
        name: 'request-password-reset',
        path: '/book/account/request-password-reset',
        component: RequestPasswordReset,
        props: true
    },
    {
        name: 'password-reset',
        path: '/book/account/password-reset',
        sensitive: false,
        component: PasswordReset,
        props: true
    },
    /*{
        name: 'eden-portal-home',
        path: '/eden-hall/members-portal',
        component: PortalHome,
        props: { venue: 'eden-hall' }
    },
    {
        name: 'hch-portal-home',
        path: '/hoar-cross-hall/members-portal',
        component: PortalHome,
        props: { venue: 'hoar-cross-hall' }
    }*/
]

/**
 * Create Router instance (Booking App)
 */
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

router.afterEach((to, from) => {
    $('html').removeClass('no-scroll');
    // Add class for location to body
    if (to.params.venue) {
        if (to.params.venue == 'hoar-cross-hall') {
            $('body').addClass('hch');
        } else {
            $('body').removeClass('hch');
        }
        if (to.params.venue == 'eden-hall') {
            $('body').addClass('eh');
        } else {
            $('body').removeClass('eh');
        }
    }
    // Track page views in Booking App
    var segment = to.path.split('/')[1];
    if (segment == 'book') {
        axios.post('/api/track/page-view', {
            PagePath: location.protocol + location.hostname + to.fullPath,
            WebFolioId: window.RS.WebFolioId,
            SessionId: window.RS.SessionId,
            CustomerId: window.RS.CustomerId
        })
    }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
var heartbeatTimer = null;

const app = new Vue({
    el: '#app',
    router: router,
    /**
     * The application's data.
     */
    data() {
        return {
            system: window.RS,
            cart: {
                FolioBalance: null,
                FolioItems: null,
                Guaranteed: null,
                WebFolioItem: null,
                inclusivesBooked: false
            },
            inclusives: null,
            booking: {
                date: null,
                type: null,
                location: null,
                guests: null,
                nights: null,
                rooms: null
            },
            venue: null,
            updatingCart: null,
            showModal: null,
            fiveMinModal: true,
            inclusiveRates: [],
            inclusiveTreatmentList: [],
            guestnames: JSON.parse(window.localStorage.getItem('guestnames')),
            upsellOnlyRate: 'DSUMDAZE',
        }
    },

    beforeMount() {
        Bus.$on('UpdateCart', () => {
            this.getWebFolio()
        });

        Bus.$on('RefreshSystem', () => {
            this.refreshSystem()
        });

        Bus.$on('RefreshExtras', () => {
            this.refreshExtras()
        });

        Bus.$on('UpdateSession', () => {
            this.updateSession()
        });

        Bus.$on('StartGiftCards', () => {
            this.systemLoad()
            this.getWebFolio()
        });

        Bus.$on('RestartBooking', (redirect) => {
            window.localStorage.removeItem('webfolio_rooms');
            this.clearCart(redirect)
        });

        Bus.$on('RemoveItem', (item) => {
            this.removeBooking(item.FolioId)
        });
    },

    watch: {
        cart: function (newVal, oldVal) {
            if (newVal.FolioItems == 0) {
                Bus.$emit('priceUpdated')
            }
            // Watch inclusives for ready to proceed
            var ic = 0
            var ib = 0
            _.each(newVal.WebFolioItem, function (booking) {
                if (booking.InclusiveTreatments && !booking.HasUpsells) {
                    _.each(booking.InclusiveTreatments.Inclusives, function (inc) {
                        ic++
                        if (inc) {
                            ib++
                        }
                    })
                }
            })
            if (ic > 0) {
                if (ic == ib) {
                    newVal.inclusivesBooked = true
                } else {
                    newVal.inclusivesBooked = false
                }
            } else {
                newVal.inclusivesBooked = true
            }
        },
        $route: function (to, from) {
            if (document.getElementById('awin')) {
                awin.remove()
            }

            if (to.name !== "checkout") {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.defer = 'defer';
                script.id = 'awin'
                script.src = "https://www.dwin1.com/65940.js";
                document.getElementsByTagName('body')[0].appendChild(script);
            }
        }
    },

    mounted() {
        var self = this

        // Get all the Rates that have inclusive treatments

        _.each(self.system.inclusive_rates, function (ir) {
            // Add the rates
            _.each(ir.package_rate_code, function (r) {
                self.inclusiveRates.push(r)
            })
            // Add the inclusives
            if (ir.inclusives) {
                _.each(ir.inclusives, function (r) {
                    if (r != "") {
                        self.inclusiveTreatmentList.push(r)
                    } else {
                        r = null
                    }
                })
            }
            if (ir.second_inclusives) {
                _.each(ir.second_inclusives, function (r) {
                    if (r != "") {
                        self.inclusiveTreatmentList.push(r)
                    } else {
                        r = null
                    }
                })
            }
            if (ir.upsells) {
                _.each(ir.upsells, function (r) {
                    if (r != "") {
                        self.inclusiveTreatmentList.push(r)
                    } else {
                        r = null
                    }
                })
            }
        })

        if (window.location.href.indexOf('book') > -1 || window.location.href.indexOf('portal') > -1) {
            this.systemLoad();

            if (this.system.WebFolioId) {
                this.getWebFolio();
            }
        }

        self.inclusives = _.filter(self.system.treatments, function (t) { return _.includes(self.inclusiveTreatmentList, t.SpaItemId) })

        if (!window.localStorage.getItem('guestnames')) {
            var guestnames = {}
            guestnames[this.system.WebFolioId] = {}
            window.localStorage.setItem('guestnames', JSON.stringify(guestnames))
        } else {
            // Clear down all the old stuff
            var guestnames = JSON.parse(window.localStorage.getItem('guestnames'))

            if (!guestnames[this.system.WebFolioId]) {
                guestnames = {}
                guestnames[this.system.WebFolioId] = {}
                window.localStorage.setItem('guestnames', JSON.stringify(guestnames))
            }
        }

        if (self.upsellOnlyRate) {
            self.inclusiveRates.push(self.upsellOnlyRate)
        }

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = 'defer';
        script.id = 'awin'
        script.src = "https://www.dwin1.com/65940.js";
        document.getElementsByTagName('body')[0].appendChild(script);
    },

    methods: {
        systemLoad() {
            if (this.system.SystemState == 'ONLINE') {

                /* Start the timout countdown */
                // Set a cookie based on the WebFolioTimeout remaining minutes.This is global for all tabs.
                var remaining = this.system.WebFolioRemainingTimeoutMinutes ? this.system.WebFolioRemainingTimeoutMinutes : 19
                var fiveMin = remaining - 5
                $cookies.set("be_sessionid", this.system.SessionId, 60 * remaining)
                $cookies.set("be_fivemin", this.system.SessionId, 60 * fiveMin)
                this.hartbeat()

            } else {
                this.showModal = { titleOne: '<span class="font-italic">Oh no!</span> Our website is<br/>currently so busy, bookings', titleTwo: 'are temporarily unavailable.', message: 'Please call reservations on 01283 576522', btn: 'Retry', btnUrl: null }
            }
        },

        refreshSystem() {
            axios.get('/rs/system')
                .then(response => {
                    this.system = response.data
                    this.systemLoad()
                })
        },

        updateSession() {
            var request = {
                method: 'UpdateSession',
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.CustomerId,
                    BookingAgentId: null
                }
            }
            axios.post('/rs/request', request)
                .then(response => {
                    this.refreshSystem()
                });
        },

        getWebFolio() {
            if (!window.localStorage.getItem('session_folios')) {
                window.localStorage.removeItem('webfolio_rooms');
                window.localStorage.setItem('session_folios', JSON.stringify({ [this.system.WebFolioId]: {} }))
            } else {
                var checkForWebFolio = JSON.parse(window.localStorage.getItem('session_folios'))
                if (!checkForWebFolio[this.system.WebFolioId]) {
                    window.localStorage.setItem('session_folios', JSON.stringify({ [this.system.WebFolioId]: {} }))
                }
            }

            if (!window.sessionStorage.getItem('booked_classes')) {
                window.sessionStorage.setItem('booked_classes', JSON.stringify([]))
            } else {
                var classList = JSON.parse(window.sessionStorage.getItem('booked_classes'))
            }

            Bus.$emit('updatingCartComponent')

            var self = this;
            var request = {
                method: 'FetchWebFolioBookings',
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId
                }
            }
            axios.post('/rs/request', request)
                .then(response => {
                    if (!$.isArray(response.data.WebFolioItem) && response.data.WebFolioItem) {
                        response.data.WebFolioItem = [response.data.WebFolioItem]
                    }

                    if (response.data.FolioItems) {
                        response.data.FolioItems = +response.data.FolioItems
                    }

                    var folios = response.data.WebFolioItem
                    var fullIncList = self.inclusiveTreatmentList.concat(self.inclusiveRates)

                    // Set the venue class for other pages once stuff in cart
                    if (folios) {
                        var venue = folios[0].Location
                        if (venue == 'Hoar Cross Hall' || venue == 'Hoar Cross Hall - Day Spa') {
                            $('body').addClass('hch');
                            this.venue = 'hoar-cross-hall'
                        }
                        if (venue == 'Eden Hall - Day Spa') {
                            $('body').addClass('eh');
                            this.venue = 'eden-hall'
                        }
                    }

                    _.each(response.data.WebFolioItem, function (o) {
                        if (o.FolioCreationStatus === "P" && o.CustomerId !== '24') {
                            var data = {
                                CustomerName: o.CustomerName,
                                CustomerId: o.CustomerId,
                                WebFolioId: o.WebFolioId
                            }

                            axios.post('/system/errors/pending-webfolio', data)
                                .then(response => {

                                })
                        }

                        if (o.Category == 'Hotel') {
                            Vue.set(o, 'Treatments', [])
                            Vue.set(o, 'Cabanas', [])
                            Vue.set(o, 'TreatmentTotal', null)
                            Vue.set(o, 'HotelOnly', false)

                            // Inclusive treatments
                            Vue.set(o, 'InclusiveTreatments', null)
                            Vue.set(o, 'ArrivalTime', null)
                            Vue.set(o, 'DepartureTime', null)

                            if (o.Location == 'Hoar Cross Hall') {
                                Vue.set(o, 'Nights', moment(o.FinishDate.slice(0, -6)).diff(moment(o.StartDate.slice(0, -6)), 'days'))

                                if (_.includes(self.system.hotelOnlyRates, o.RateType)) {
                                    o.HotelOnly = true
                                }

                                // Insurance
                                var stayInsurance = {
                                    PMSSpecialServiceName: 'Flex Booking Per Room',
                                    PMSSpecialServiceQty: o.Nights,
                                    Price: 50,
                                    PMSItemId: 999109,
                                    added: false
                                }
                                Vue.set(o, 'Type', 'Stay')
                                Vue.set(o, 'Insurance', stayInsurance)
                                o.ArrivalTime = '162900'
                                o.DepartureTime = '170000'
                            } else {
                                var spaInsurance = {
                                    PMSSpecialServiceName: o.RoomType == 'HTWI' || o.RoomType == 'EFEE' ? 'Flex Booking per Twilight Spa Guest' : 'Flex Booking per Day Spa Guest',
                                    PMSSpecialServiceQty: 1,
                                    Price: o.RoomType == 'HTWI' || o.RoomType == 'EFEE' ? 10 : 20,
                                    PMSItemId: o.RoomType == 'HTWI' || o.RoomType == 'EFEE' ? 999112 : 999110,
                                    added: false
                                }

                                Vue.set(o, 'Type', 'Spa Day')
                                Vue.set(o, 'Insurance', spaInsurance)

                                // Property arrival and departure times
                                if (o.Location == 'Hoar Cross Hall - Day Spa') {
                                    if (o.RoomType == 'HTWI') {
                                        o.ArrivalTime = '172900'
                                        o.DepartureTime = '213000'
                                    } else {
                                        o.ArrivalTime = '083000'
                                        o.DepartureTime = '161500'
                                    }
                                }

                                if (o.Location == 'Eden Hall - Day Spa') {
                                    if (o.RoomType == 'EFEE') {
                                        o.ArrivalTime = '172900'
                                        o.DepartureTime = '220000'
                                    } else {
                                        o.ArrivalTime = '090000'
                                        o.DepartureTime = '161500'
                                    }
                                }
                            }

                            // Remove insurance if a promo
                            var promo_rates = ['EHAUTUMNGLOW', 'RXMAS2N', 'RXMAS3N', 'RNYEPARTY', 'RNYESHEBANG'];

                            if (_.includes(promo_rates, o.RateType)) {
                                o.Insurance = null
                            }

                            // No of days (Stay only)
                            var dates = []
                            var start = moment.tz(o.StartDate.slice(0, -6), 'Europe/London');
                            var end = moment.tz(o.FinishDate.slice(0, -6), 'Europe/London');

                            if (!_.includes(['RINDULGENT', 'RUNDULGENT2'], o.RateType)) {
                                start.add(1, 'days')
                                o.ArrivalTime = '100000'
                                o.DepartureTime = '170000'
                            }

                            if (o.Type == 'Stay') {
                                while (start <= end) {
                                    dates.push(start.format('YYYY-MM-DD'));
                                    start.add(1, 'days');
                                }
                                //this.treatmentDate = moment(o.StartDate.slice(0, -6)).add(1, 'days').format('YYYY-MM-DD')
                            } else {
                                while (start < end) {
                                    dates.push(start.format('YYYY-MM-DD'));
                                    start.add(1, 'days');
                                }
                                //this.treatmentDate = o.StartDate.slice(0, -6)
                            }
                            Vue.set(o, 'TreatmentDates', dates)
                        }
                        if (o.Category == 'Retail') {
                            if (_.isEmpty(o.SubItems)) {
                                o.SubItems = null
                            }
                        }

                        if (o.FolioId) {
                            var sessionStorage = JSON.parse(window.localStorage.getItem('session_folios'))
                            var rsfolio = sessionStorage[self.system.WebFolioId][o.FolioId];

                            if (o.Type == 'Stay') {
                                var roomConfiguration = JSON.parse(window.localStorage.getItem('webfolio_rooms'));

                                if (roomConfiguration !== null && roomConfiguration[self.system.WebFolioId] !== undefined) {
                                    var room = roomConfiguration[self.system.WebFolioId].find(item => item.folioId == o.FolioId);
                                } else {
                                    var room = {
                                        guests: rsfolio ? rsfolio.Adults : self.$route.params.guests
                                    }
                                }
                            } else {
                                var room = {
                                    guests: rsfolio ? rsfolio.Adults : self.$route.params.guests
                                }
                            }

                            if (rsfolio) {
                                Vue.set(o, 'Adults', room.guests)
                                Vue.set(o, 'Nights', rsfolio.Nights)
                                Vue.set(o, 'SelectedInclusives', null)
                                Vue.set(o, 'ShowFullTreatmentList', true)
                                Vue.set(o, 'HasUpsells', false)
                                Vue.set(o, 'HasOffers', false)
                                Vue.set(o, 'promotionApplied', null)

                                // Now setup inclusive treatments
                                var inclusive = null
                                var secondInclusive = null
                                var itemName = null
                                var upsell = null

                                if (_.some(fullIncList, function (ir) { return _.includes(o.RateType, ir) })) {

                                    if (_.includes(o.RateType, self.upsellOnlyRate)) {
                                        inclusive = null
                                        itemName = 'Frangipani Melt'
                                        upsell = [100811372, 100811373, 100811374, 100811375]
                                    }

                                    // Create inclusive treatment slot for each guest, each night
                                    var inclusives = []
                                    var n = 0
                                    if (!o.HasOffers) { // unlimited options
                                        while (n <= o.Nights - 1) {
                                            var g = 1
                                            while (g <= room.guests) {
                                                inclusives.push(null)
                                                if (_.includes(o.RateDetails, 'Infinity')) {
                                                    inclusives.push(null)
                                                }
                                                g++
                                            }
                                            n++
                                        }
                                    }

                                    // Remove second treatment for this rate
                                    if (_.includes(o.RateType, 'RSUMMER2NT') || _.includes(o.RateType, 'RFESTIVE2')) {
                                        inclusives.splice(inclusives.length - 2, 2)
                                    }

                                    // Add second treatment for this rate
                                    if (secondInclusive) {
                                        inclusives.push(null)
                                    }

                                    var packageInclusives = _.find(self.system.inclusive_rates, function (pkg) { return _.includes(pkg.package_rate_code, o.RateType) })

                                    if (packageInclusives) {
                                        inclusive = packageInclusives.inclusives;
                                        itemName = packageInclusives.inclusive_treatment_name ? packageInclusives.inclusive_treatment_name : 'Select your treatment';
                                        secondInclusive = packageInclusives.second_inclusives;
                                        upsell = packageInclusives.upsells
                                    }

                                    o.InclusiveTreatments = {
                                        'Guests': room.guests,
                                        'Day': n,
                                        'Date': o.Location == 'Hoar Cross Hall' ? moment(o.StartDate.slice(0, -6)).add(1, 'days').format('YYYY-MM-DD') : o.StartDate.slice(0, -6),
                                        'Inclusives': inclusives,
                                        'SpaItemId': inclusive,
                                        'SpaItemIdTwo': secondInclusive,
                                        'ItemName': itemName,
                                        'SpaItem': null,
                                        'OptionList': null,
                                        'Upsells': upsell,
                                        'UpsellList': null
                                    }
                                    // Remove second treatment for this rate
                                    if (_.includes(o.RateType, self.upsellOnlyRate)) {
                                        o.HasUpsells = true
                                    }
                                    o.ShowFullTreatmentList = false
                                }
                            }
                        }

                    })


                    // Move treatment to each folio
                    _.each(response.data.WebFolioItem, function (o) {
                        if (o.Category == 'Spa') {
                            if (o.ItemClass == 'C') { // Gym classes
                                o.Category = 'Classes'
                                o.GymRoom = null
                                var classRoom = _.find(classList, { 'SpaItemId': +o.ItemId, 'StartTime': o.StartDate })
                                if (classRoom) {
                                    o.GymRoom = classRoom.SpaRoomId
                                }
                                o.start = moment(o.StartDate, 'YYYY-MM-DDhhmmss').format('hh:mm')
                                o.end = moment(o.FinishDate, 'YYYY-MM-DDhhmmss').format('hh:mm')
                            }
                            Vue.set(o, 'Date', o.StartDate.slice(0, -6))
                            Vue.set(o, 'Type', null)
                            // Vue.set(o, 'InclusiveDay', 0)

                            if (_.includes(fullIncList, +o.ItemId)) {
                                o.Type = 'Inclusive'
                            } else {
                                o.Type = 'Optional'
                            }

                            // if(_.includes(['100208020'], o.ItemId)) {
                            //     o.InclusiveDay = 1
                            // }

                            var folioId = o.GuestRequest
                            var folio = _.find(response.data.WebFolioItem, { 'FolioId': folioId })

                            if (folio) {
                                if(o.Details.includes('Cabana')) {
                                    folio.Cabanas.push(o)
                                } else {
                                    folio.TreatmentTotal = +folio.TreatmentTotal + +o.Amount
                                    folio.Treatments.push(o)
                                }
                            }
                        }
                    })

                    // Loop through inclusives and add to booked
                    _.each(response.data.WebFolioItem, function (o) {
                        var bookedInclusives = _.filter(o.Treatments, { 'Type': 'Inclusive' })

                        if (bookedInclusives.length) {
                            // Map booked to each inclusive allowance
                            _.each(bookedInclusives, function (tmt, k) {
                                o.InclusiveTreatments.Inclusives[k] = tmt
                            })
                        }
                        //Vue.set(o, 'BookedInclusives', bookedInclusives)
                        o.Treatments = _.filter(o.Treatments, { 'Type': 'Optional' })
                    })


                    // Remove Spa/Dining folios
                    _.remove(response.data.WebFolioItem, function (o) {
                        if (o.Category == 'Dining') {
                            return true;
                        }

                        if (o.Category == 'Spa') {
                            if (o.GuestRequest) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return false
                        }
                    })

                    // Now get the extras
                    _.each(response.data.WebFolioItem, function (o, k) {
                        if (o.Category == 'Hotel') {
                            var guestsForFolio = {}
                            var i = 0
                            while (i + 1 <= o.Adults) {
                                guestsForFolio[i] = null
                                i++
                            }

                            Vue.set(o, 'GuestNames', {
                                'folio_id': o.FolioId,
                                'type': o.Type == 'Stay' ? 'Room' : 'Guest',
                                'date': o.StartDate.slice(0, -6),
                                'guests': guestsForFolio
                            })

                            self.getExtras(o.FolioId);
                        }
                    })

                    this.cart = response.data
                    this.updatingCart = null
                    this.refreshSystem()

                    Bus.$emit('StopUpdatingCartComponent')
                });
        },
        getExtras(folio) {
            var self = this
            var guestnames = JSON.parse(window.localStorage.getItem('guestnames'))
            if (!guestnames[self.system.WebFolioId][folio]) {
                guestnames[self.system.WebFolioId][folio] = {}
            }
            var request = {
                method: 'FetchPMSFolioSpecialServices',
                data: {
                    PMSFolioId: folio,
                    WebFolioId: this.system.WebFolioId,
                    Language: null
                }
            }
            axios.post('/rs/request', request)
                .then(response => {
                    if (response.data.Result.value === 'SUCCESS') {
                        if (response.data.PMSBookingDetails.PMSFolioSpecialService && !$.isArray(response.data.PMSBookingDetails.PMSFolioSpecialService)) {
                            response.data.PMSBookingDetails.PMSFolioSpecialService = [response.data.PMSBookingDetails.PMSFolioSpecialService]
                        }
                        if (!$.isArray(response.data.PMSBookingDetails)) {
                            response.data.PMSBookingDetails = [response.data.PMSBookingDetails]
                        }
                        var self = this;

                        var ssPromotionList = []
                        _.each(this.system.discount_settings, function (ss) {
                            ssPromotionList.push(ss.name)
                            if (ss.twi_name) {
                                ssPromotionList.push(ss.twi_name)
                            }
                            if (ss.stay_name) {
                                ssPromotionList.push(ss.stay_name)
                            }
                        })

                        _.each(self.cart.WebFolioItem, function (o, k) {
                            var extra = _.find(response.data.PMSBookingDetails, function (ex) { return ex.PMSFolioId == o.FolioId })
                            if (extra != null) {
                                var price = _.sumBy(extra.PMSFolioSpecialService, function (ssp) { return +ssp.TotalWithSurcharges });
                                var guestNameSS = []
                                _.each(extra.PMSFolioSpecialService, function (ts) {
                                    if (ts.PMSSpecialService.PMSItemId == 101) {
                                        Vue.set(o, 'twinSelected', true)
                                    }
                                    if (ts.PMSSpecialService.PMSItemId == 102) {
                                        Vue.set(o, 'doubleSelected', true)
                                    }
                                    if (_.includes(ssPromotionList, ts.PMSSpecialService.PMSSpecialServiceName)) {
                                        o.promotionApplied = ts.PMSSpecialService.PMSSpecialServiceName
                                    }
                                    if (ts.PMSSpecialService.PMSSpecialServiceName.includes('Offer')) {
                                        Vue.set(o, 'advancedBooking', 10)
                                    }
                                    if (ts.PMSSpecialService.PMSSpecialServiceName.includes('Loyalty')) {
                                        Vue.set(o, 'advancedBooking', 20)
                                    }
                                    if (ts.PMSSpecialService.PMSItemId == 2000) { // grab the guset names
                                        guestNameSS.push(_.clone(ts))
                                    }
                                    if (ts.PMSSpecialService.PMSSpecialServiceName == 'Spa Access') {
                                        Vue.set(o, 'spaAccess', ts)
                                    }
                                })

                                _.each(guestNameSS, function (gn, k) {
                                    gn['name'] = guestnames[self.system.WebFolioId][folio][gn.PMSFolioSpecialServiceId]
                                    o.GuestNames.guests[k] = gn.name
                                })


                                _.remove(extra.PMSFolioSpecialService, function (s) {
                                    if (s.PMSSpecialService.PMSItemId == 101 || s.PMSSpecialService.PMSItemId == 102 || _.includes(ssPromotionList, s.PMSSpecialService.PMSSpecialServiceName)) {
                                        return true
                                    } else if (s.PMSSpecialService.PMSSpecialServiceName.includes('Loyalty') || s.PMSSpecialService.PMSItemId == 2000) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })

                                if (extra.PMSFolioSpecialService.length) {
                                    Vue.set(o, 'extras', {
                                        total: price,
                                        specialServices: extra
                                    })
                                    if(o.Cabanas.length > 0) {
                                        o.extras.total += _.sumBy(o.Cabanas, function (c) { return +c.AmountWithSurcharges })
                                    }
                                }
                                    
                            }
                            extra = null;
                        })
                    }
                });
        },
        refreshExtras() {
            var self = this
            _.each(this.cart.WebFolioItem, function (o) {
                self.getExtras(o.FolioId)
            })
        },

        removeBooking(item) {
            var self = this
            this.removing = item;
            Bus.$emit('updatingPrice');
            Bus.$emit('showLoading');

            var booking = _.find(self.cart.WebFolioItem, { 'FolioId': item })

            if (booking) {
                // Remove any treatments first
                if (booking.Treatments) {
                    _.each(booking.Treatments, function (treatment) {
                        self.removeTreatment(treatment.FolioId, treatment.FolioItemId, true)
                    })
                }

                // Remove any inclusive treatments next
                if (booking.InclusiveTreatments) {
                    _.each(booking.InclusiveTreatments.Inclusives, function (it) {
                        if (it) {
                            self.removeTreatment(it.FolioId, it.FolioItemId, true)
                        }
                    })
                }

                var request = {
                    method: '',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                    }
                }

                // Gift card cancel request
                if (booking.Category == 'Retail') {
                    request.method = 'CancelRetailItem';
                    request.data.RetFolioId = booking.FolioId;
                    request.data.RetFolioItemId = booking.FolioItemId;
                }

                // PMScancel request
                if (booking.Category == 'Hotel') {
                    request.method = 'CancelPMSBooking';
                    request.data.PMSFolioId = booking.FolioId;
                }

                axios.post('/rs/request', request)
                    .then(response => {
                        if (response.data.Result.value === 'SUCCESS') {
                            this.getWebFolio();
                        }
                        this.removing = false
                        Bus.$emit('priceUpdated');
                        Bus.$emit('hideLoading');

                    })
            }
        },

        removeTreatment(SpaFolioId, SpaFolioItemId, updateCart) {
            this.removing = SpaFolioItemId;
            var request = {
                method: 'CancelSpaService',
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    SpaFolioId: SpaFolioId,
                    SpaFolioItemId: SpaFolioItemId
                }
            }
            axios.post('/rs/request', request)
                .then(response => {
                    if (response.data.Result.value === 'SUCCESS') {
                        this.removing = null
                        if (updateCart) {
                            this.getWebFolio()
                        }
                    }
                });
        },
        removeExtras(id, folio) {
            this.removing = id;
            var request = {
                method: 'RemovePMSSpecialService',
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    PMSFolioId: folio,
                    PMSFolioSpecialServiceId: id
                }
            }
            axios.post('/rs/request', request)
                .then(response => {
                    if (response.data.Result.value === 'SUCCESS') {
                        this.removing = null
                        this.getWebFolio()
                    }
                });
        },
        removeGiftCard(RetFolioId, RetFolioItemId) {
            var request = {
                method: 'CancelRetailItem',
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    RetFolioId: RetFolioId,
                    RetFolioItemId: RetFolioItemId
                }
            }
            axios.post('/rs/request', request)
                .then(response => {
                    if (response.data.Result.value === 'SUCCESS') {

                        this.getWebFolio()
                    }
                });
        },

        clearCart(redirect) {
            var self = this;


            _.each(this.cart.WebFolioItem, function (item) {
                self.removeBooking(item.FolioId);
            });

            sessionStorage.removeItem("guestsAdded");

            if (redirect) {
                this.venue = null
                this.$router.push('/book')
            }
        },

        hartbeat() {
            if (!window.heartbeatTimer) {
                window.heartbeatTimer = setInterval(this.cleanupWebfolio, 1000)
            }
            if (!window.fiveMinuteTimer) {
                window.fiveMinuteTimer = setInterval(this.fiveMinuteWarning, 1000)
            }
        },

        cleanupWebfolio() {
            if (!$cookies.get('be_sessionid')) {
                // Reset timer
                clearInterval(window.heartbeatTimer)

                console.log('WebFolio expired at: ' + moment().format('YYYY-MM-DD hh:mm'))

                // Set return path (booking/GC/Membership)
                var uri = new URI(window.location.href)

                var path = '/book'
                if (uri.segment(1) == 'members-portal') {
                    path = '/' + uri.segment(0) + '/members-portal'
                }

                // Remove Guests Added flag
                sessionStorage.removeItem("guestsAdded")

                // Show session expired modal
                this.showModal = null
                this.showModal = { titleOne: '<span>Oh no!</span><br> Unfortunately your session', titleTwo: 'has expired', message: 'Please reload the page to start again', btn: 'Reload', btnUrl: path }

                // Clear session
                axios.get('/rs/clearSession')
                    .then(response => {
                        console.log('session cleared...')
                    });
            }
        },

        fiveMinuteWarning() {
            if (!$cookies.get('be_fivemin')) {
                // Reset timer
                clearInterval(window.fiveMinuteTimer)
                if (this.cart.FolioItems) {
                    $('#five-min-modal').modal('show')
                }
            }
        }
    }

});

document.addEventListener('DOMContentLoaded', () => {
    const players = Plyr.setup('video', {
        autoplay: true,
        muted: true,
        playsinline: true,
        loop: { active: true }
    });
});

/**
 * Import scripts
 */
require('./fslightbox');
require('./scripts');
