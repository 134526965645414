var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-app" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-6 text-center" }, [
          _c("h1", { staticClass: "brandHeading mb-4" }, [
            _vm._v("Reset your Password"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-5" }, [
            _vm._v(
              "To reset your password, enter the email address for your account. An email will be sent to you with the details."
            ),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.resetPassword.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-sm-7 mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      id: "email",
                      placeholder: "Enter email address",
                      required: "",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.resetStatus
            ? _c("div", { staticClass: "message" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.resetStatus) +
                    "\n                "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Reset password")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }