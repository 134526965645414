<template>
    <div class="row">
        <div v-for="article in feed" class="col-md-6">
            <div class="card">
                <div class="card-image" :style="'background-image:url(/storage/' +article.img+ ');'">&nbsp;</div>
                <div class="card-body">
                    <div class="card-body__inner">
                        <h3>{{ article.title }}</h3>
                        <div v-html="article.copy"></div>
                        <a v-if="article.btn_1_url" :href="article.btn_1_url" class="btn btn-primary mr-1">{{ article.btn_1_text }}</a>
                        <a v-if="article.btn_2_url" :href="article.btn_2_url" class="btn btn-primary mr-1">{{ article.btn_2_text }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            location: Number
        }, 

        components: {
            
        },

        data() {
            return {
                feed: null
            }
        },
        
        mounted() {
            this.getFeed()
        },

        computed: {
            
        },

        methods: {
            getFeed() {
                axios.get('/feeds/members/benefits/' + this.location)
                    .then(response=> {
                        this.feed = response.data
                    });
            }
        },
    }
</script>
