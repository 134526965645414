<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div id="treatment-availability-tabs">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-today-tab" data-toggle="tab" href="#nav-today" role="tab" aria-controls="nav-today" aria-selected="true">Today</a>
                            <a class="nav-item nav-link" id="nav-tomorrow-tab" data-toggle="tab" href="#nav-tomorrow" role="tab" aria-controls="nav-tomorrow" aria-selected="false">Tomorrow</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-today" role="tabpanel" aria-labelledby="nav-today-tab">
                            <div v-if="today">
                                <transition-group tag="ul" name="fade-out-in">
                                    <li v-for="treatment in today"  v-bind:key="treatment.id" class="alt-bg">
                                        <h5 class="treatment-name">{{ treatment.name }} 
                                            <div class="treatment-price">
                                                <span>{{ treatment.service_time }} mins</span> 
                                                <span>&pound;{{ treatment.price }}</span>
                                            </div>
                                        </h5>
                                        <div v-if="treatment.times" class="times-container">
                                            <span v-for="time in treatment.times" class="time-item">{{ time | nicetime }}</span>
                                        </div>
                                    </li>
                                </transition-group>
                                <div class="availability-cta">
                                    To book your treatment, please visit our Spa reception
                                </div>
                            </div>
                            <div v-else class="flex-container align-items-center">
                                <div class="flex-item text-center">
                                    <div v-if="today == null || today.length == 0">
                                        <h3>Sorry, no availability today</h3>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <div class="spinner-grow spinner-grow-md" role="status"></div>
                                            Loading treatments...
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-tomorrow" role="tabpanel" aria-labelledby="nav-tomorrow-tab">
                            <div v-if="tomorrow">
                                <transition-group tag="ul" name="fade-out-in">
                                    <li v-for="treatment in tomorrow"  v-bind:key="treatment.id" class="alt-bg">
                                        <h5 class="treatment-name">{{ treatment.name }} 
                                            <div class="treatment-price">
                                                <span>{{ treatment.service_time }} mins</span> 
                                                <span>&pound;{{ treatment.price }}</span>
                                            </div>
                                        </h5>
                                        <div v-if="treatment.times" class="times-container">
                                            <span v-for="time in treatment.times" class="time-item">{{ time | nicetime }}</span>
                                        </div>
                                    </li>
                                </transition-group>
                                <div class="availability-cta">
                                    To book your treatment, please visit our Spa reception
                                </div>
                            </div>
                            <div v-else class="flex-container align-items-center">
                                <div class="flex-item text-center">
                                    <div v-if="noClassAvailability">
                                        <h3>Sorry, no availability today</h3>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <div class="spinner-grow spinner-grow-md" role="status"></div>
                                            Loading treatments...
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            location: Number,
        },

        components: {
            
        },

        data() {
            return {
                today: null,
                tomorrow: null,
                noAvailability: false,
                loadingAvailability: false,
                noClassAvailability: false,
                loadingClasses: false,
                classes: null,
            }
        },
        
        mounted() {
            this.fetchTreatments()
        },

        watch: {
            
        },

        computed: {
            
        },

        methods: {
            fetchTreatments(loc) {
                var self = this
                self.loadingAvailability = true,
                
                axios.get('/api/treatments/availability')
                    .then(function (response) {
                        if(response.data.length) {
                            
                            var data = _.filter(response.data, { 'venue': self.location.toString() });
                            
                            var allTreatments = []
                            
                            _.each(data, function(o) {
                                o.price = parseInt(o.price.replace('.00', ''));

                                if(o.date == moment().format('YYYY-MM-DD')) {
                                    _.remove(o.times, function(t) { return moment(t) <= moment()})
                                }


                                if(o.times.length) {
                                    allTreatments.push(o)
                                } 
                            })

                            allTreatments = _.filter(allTreatments, service => service.price > 60);

                            if(allTreatments.length) {
                                self.noAvailability = false
                                var days = _.groupBy(allTreatments, 'date')
                                
                                if(days[moment().format('YYYY-MM-DD')]) {
                                    self.today = days[moment().format('YYYY-MM-DD')]
                                }
                                if(days[moment().add(1, 'days').format('YYYY-MM-DD')]) {
                                    self.tomorrow = days[moment().add(1, 'days').format('YYYY-MM-DD')]
                                }
                            } else {
                                self.noAvailability = true
                            }
                            
                        } else {
                            self.noAvailability = true
                        }
                    })
            },
        }
    }
</script>
