<template>
    <div class="container">
        <div class="row">
            <div v-for="t in treatments" class="col-md-6">
                <div class="archive-card">
                    <a :href="'/' + t.slug">
                        <div class="archive-card__image" :style="'background-image:url(/storage/'+t.img+');'">&nbsp;</div>
                        <div class="archive-card__header archive-card-pinched">
                            <h2>{{ t.title }}</h2>
                        </div>
                    </a>
                    <div class="archive-card__body archive-card-pinched">
                        {{ t.intro }}
                    </div>
                    <div class="archive-card-pinched">
                        <a :href="'/' + t.slug" class="btn btn-primary">View treatments</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            location: Number,
        },

        components: {
            
        },

        data() {
            return {
                treatments: null,
                noAvailability: false,
                loadingAvailability: false,
            }
        },
        
        mounted() {
            this.fetchTreatments()
        },

        watch: {
            
        },

        computed: {
            
        },

        methods: {
            fetchTreatments() {
                var self = this
                self.loadingAvailability = true,
                
                axios.get('/api/treatments/feed/' + self.location)
                    .then(function (response) {
                        if(response.data.length) {
                            self.treatments = response.data
                        } else {
                            self.noAvailability = true
                        }
                    })
            },
        }
    }
</script>
