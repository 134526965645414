<template>
    <div>
        <div v-if="success">
            <h4 v-if="success">Thank you for your interest. Download your brochure now.</h4>
            <a class="btn btn-reversed" :href="'/media/download/' + download">Download Brochure</a>
        </div>
        <div v-else>
            <form @submit.prevent="enquire">
                <div class="form-group row">
                    <div class="col-md-3">
                        <input v-model="formData.firstName" ref="firstName" type="text" placeholder="First name" class="form-control" required />
                    </div>
                    <div class="col-md-3">
                        <input v-model="formData.lastName" ref="lastName" type="text" placeholder="Last name" class="form-control" required />
                    </div>
                    <div class="col-md-4">
                        <input v-model="formData.emailAddress" ref="emailAddress" type="email" placeholder="Email" class="form-control" required />
                    </div>
                    <div class="col-md-2">
                        <button type="submit" :disabled="sending" class="btn btn-reversed float-right">Download</button>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="form-check">
                            <label class="form-check-label float-left" for="marketing">
                                <input v-model="formData.marketing" type="checkbox" class="form-check-input" id="marketing">
                                <span class="checkmark"></span>
                                {{ consent_message }}
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div> 
            
</template>

<script>
    var formTemplate = function() {
        return {
            firstName: null,
            lastName: null,
            emailAddress: null,
            marketing: false,
            revinate_id: null
        }
    }
    export default {
        props: {
            email: String,
            download: String,
            ga: String,
            revinate_id: String,
            consent_message: String
        },
        data() {
            return {
                formData: new formTemplate(),
                success: null,
                sending: false,
                downloadUrl: null
            }
        },

        mounted() {
            
        },

        methods: {
            enquire() {
                var self = this
                self.sending = true

                // populate form data, even from autocomplete
                self.formData.firstName = this.$refs.firstName.value
                self.formData.lastName = this.$refs.lastName.value
                self.formData.emailAddress = this.$refs.emailAddress.value
                self.formData.revinate_id = this.revinate_id

                window.getRecaptchaToken()
                    .then(function(r) {
                        var data = {
                            email: self.email,
                            venue: self.venue,
                            file_id: self.download,
                            formData: self.formData,
                            recaptcha: r
                        }
                        axios.post('/system/subscribe/generic-download', data)
                            .then(response=> {
                                self.success = true;
                                self.sending = false
                                self.formData = new formTemplate()
                                self.downloadUrl = response.data

                                dataLayer.push({
                                    event: 'form_submission',
                                    form_type: 'lead_form',
                                    form_subject: self.ga,
                                    venue: self.venue,
                                });

                                this.$emitAnalytics(
                                    'form_submission', 
                                    {
                                        event: 'form_submission',
                                        form_type: 'lead_form',
                                        form_subject: self.ga,
                                        venue: self.venue,
                                    },
                                    self.system.CustomerId,
                                    self.system.WebfolioId
                                );
                            });
                    })
            },
        }
    }
</script>
