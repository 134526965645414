<template>
    <div>
        <input 
            type="text" 
            class="form-control form-control-sm" 
            :value="password" 
            :placeholder="placeholder"
            :id="id"
            :minlength="minlength"
            :required="required"
            @input="$emit('update:password', $event.target.value)"
            :class="{ 'is-invalid': !isValid }"
        >

        <div class="progress">
            <div 
                class="progress-bar progress-bar-animated"
                role="progressbar" 
                :aria-valuenow="percent" 
                aria-valuemin="0" 
                aria-valuemax="100" 
                :style="{ 
                    width: `${percent}%`,
                    backgroundColor: description.color
                }">
            </div>
        </div>

        <div class="form-feedback-text"><p>{{ description.label }}</p></div>
    </div>
</template>

<script>
    import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
    import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
    import zxcvbnEnPackage from '@zxcvbn-ts/language-en';

    export default {
        props: {
            password: String,
            id: String,
            minlength: Number,
            required: Boolean,
            MinPasswordStrength: Number,
            isPasswordValid: Boolean,
            placeholder: String,
        },
        emits: ['update:password', 'update:is-password-valid'],
        mounted() {
            const options = {
                dictionary: {
                    ...zxcvbnCommonPackage.dictionary,
                    ...zxcvbnEnPackage.dictionary,
                },
                graphs: zxcvbnCommonPackage.adjacencyGraphs,
                translations: zxcvbnEnPackage.translations,
            };

            zxcvbnOptions.setOptions(options);
        },
        watch: {
            password() {
                this.$emit('update:is-password-valid', this.isValid);
            }
        },
        computed: {
            score() {
                if(this.password.length === 0) {
                    return 0;
                } else if (this.password.length < this.minlength) {
                    return 1;
                }

                return zxcvbn(this.password).score + 1;
            },
            description() {
                if(this.password.length === 0) {
                    return { color: '#FFF', label: 'Start typing to check your password' };
                } else if(this.password.length < this.minlength) {
                    return { color: '#ce2929', label: `Password must be at least ${this.minlength} characters long` };
                } 

                return this.descriptions[this.score - 1];
            },
            descriptions() {
                return [
                    {
                        color: '#ce2929',
                        label: 'This password is extremely weak and does not meet our complexity requirements',
                    },
                    { 
                        color: '#e16c6c', 
                        label: 'This password is weak and does not meet our complexity requirements' 
                    },
                    { 
                        color: '#e1b66c', 
                        label: 'This is a good password, providing a solid level of security. But can be improved, try adding a few more characters or a symbol' 
                    },
                    { 
                        color: '#a9e16c', 
                        label: 'This is a strong password, providing a solid level of security. But can be improved, try adding a few more characters or a symbol' 
                    },
                    {
                        color: '#3db936',
                        label: 'Congratulations! This password is highly secure and resilient to various types of attacks. ',
                    }
                ];
            },
            percent() {
               return (this.score / this.descriptions.length) * 100;
            },
            isValid() {
                if(this.password.length === 0) {
                    return true;
                }

                return this.score > this.MinPasswordStrength 
                    && this.password.length >= this.minlength;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .progress {
        height: 10px;
        margin: 5px 0;
        border: 1px solid #cdd4da;
    }

    .form-feedback-text {
        p {
            font-size: 12px;
            line-height: 1.4;
        }
    }
</style>