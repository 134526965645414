<template>
    <div>
        <div v-if="!success">
            <form @submit.prevent="subscribe" class="form-inline">
                <input v-model="emailAddress" type="email" placeholder="enter email" class="form-control mr-2" required />
                <input type="image" value="submit" src="/img/arrow-right.svg" alt="submit Button">
                <a @click.prevent="hideForever" class="xs mt-2">Hide forever</a>
            </form>
        </div>
        <div v-else>
            <p>Thanks for signing up!</p>
            <button @click.prevent="close" class="btn btn-reversed">Close</button>
        </div>
        
    </div>
        
</template>

<script>

    export default {
        props: {
            location: String
        },

        data() {
            return {
                emailAddress: null,
                success: null,
                processing: false
            }
        },

        mounted() {
            
        },

        methods: {
            subscribe() {
                var self = this
                self.processing = true
                window.getRecaptchaToken()
                    .then(function(r) {
                        var data = {
                            email: self.emailAddress,
                            recaptcha: r
                        }
                        axios.post('/system/subscribe', data)
                            .then(response=> {
                                self.success = true;
                                self.processing = false
                                self.emailAddress = null
                                self.$cookies.set("signupCookie",1,'90d');

                                dataLayer.push({
                                    event: 'form_submission',
                                    form_type: 'lead_subscription',
                                    form_subject: 'Subscribe Modal',
                                    venue: self.location,
                                });

                                this.$emitAnalytics(
                                    'form_submission', 
                                    {
                                        event: 'form_submission',
                                        form_type: 'lead_subscription',
                                        form_subject: 'Subscribe Modal',
                                        venue: self.location,
                                    },
                                    self.system.CustomerId,
                                    self.system.WebfolioId
                                );
                            });
                    })
            },
            hideForever() {
                this.$cookies.set("signupCookie",1,'90d');
                $('#signup-sideout').addClass('sideout-hide')
            },
            close() {
                $('#signup-sideout').addClass('sideout-hide')
            }
        }
    }
</script>
