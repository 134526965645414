var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.treatments, function (t) {
        return _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "archive-card" }, [
            _c("a", { attrs: { href: "/" + t.slug } }, [
              _c(
                "div",
                {
                  staticClass: "archive-card__image",
                  style: "background-image:url(/storage/" + t.img + ");",
                },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "archive-card__header archive-card-pinched" },
                [_c("h2", [_vm._v(_vm._s(t.title))])]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "archive-card__body archive-card-pinched" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(t.intro) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "archive-card-pinched" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { href: "/" + t.slug },
                },
                [_vm._v("View treatments")]
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }