var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "registerModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "registerModalTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.register.apply(null, arguments)
                  },
                },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("p", { staticClass: "small" }, [
                    _vm._v("Required fields *"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "firstName" } }, [
                        _vm._v("First name*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.Name.FirstName,
                            expression: "registerForm.Name.FirstName",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "firstName",
                          placeholder: "First name",
                          required: "",
                        },
                        domProps: { value: _vm.registerForm.Name.FirstName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.registerForm.Name,
                              "FirstName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "surname" } }, [
                        _vm._v("Surname*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.Name.LastName,
                            expression: "registerForm.Name.LastName",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "surname",
                          placeholder: "Surname",
                          required: "",
                        },
                        domProps: { value: _vm.registerForm.Name.LastName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.registerForm.Name,
                              "LastName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "newEmail" } }, [
                        _vm._v("Your email address*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.EmailAddress,
                            expression: "registerForm.EmailAddress",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "newEmail",
                          placeholder: "Enter email",
                          required: "",
                        },
                        domProps: { value: _vm.registerForm.EmailAddress },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.registerForm,
                              "EmailAddress",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "newPassword" } }, [
                        _vm._v("Your password*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.Password,
                            expression: "registerForm.Password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          minlength: "8",
                          pattern: "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\\S{8,}",
                          id: "newPassword",
                          placeholder: "Min 8, 1 num, 1 capital",
                          required: "",
                        },
                        domProps: { value: _vm.registerForm.Password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.registerForm,
                              "Password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "small" }, [
                        _vm._v(
                          "Minimum 8 characters, 1 number and 1 capital letter"
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "Address1" } }, [
                        _vm._v("Address* (start typing to search)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.Address[0].Address1,
                            expression: "registerForm.Address[0].Address1",
                          },
                        ],
                        ref: "Address1",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "Address1",
                          name: "Address1",
                          placeholder: "Address line 1",
                          required: "",
                        },
                        domProps: {
                          value: _vm.registerForm.Address[0].Address1,
                        },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.registerForm.Address[0],
                                "Address1",
                                $event.target.value
                              )
                            },
                            _vm.debouncedFetchAddressLookup,
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _vm.addressResults.length
                        ? _c(
                            "ul",
                            { staticClass: "autocomplete" },
                            [
                              _c("li", { staticClass: "amount" }, [
                                _vm._v(
                                  "\n                                    Showing " +
                                    _vm._s(_vm.addressResults.length) +
                                    " results\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.addressResults,
                                function (address, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectAddress(address)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(address.address_text) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "Address2" } }, [
                        _vm._v("Address line 2"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "Address2",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "Address2",
                          name: "Address2",
                          placeholder: "Address line 2",
                        },
                        domProps: {
                          value: _vm.registerForm.Address[0].Address2,
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "City" } }, [
                        _vm._v("City*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "City",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "City",
                          name: "City",
                          placeholder: "City",
                          required: "",
                        },
                        domProps: { value: _vm.registerForm.Address[0].City },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-4" }, [
                      _c("label", { attrs: { for: "PostCode" } }, [
                        _vm._v("Post Code*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "PostCode",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "PostCode",
                          name: "PostCode",
                          placeholder: "Post Code",
                          required: "",
                        },
                        domProps: {
                          value: _vm.registerForm.Address[0].PostCode,
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "Country" } }, [
                        _vm._v("Country*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "Country",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "Country",
                          name: "Country",
                          placeholder: "Country",
                          required: "",
                        },
                        domProps: {
                          value: _vm.registerForm.Address[0].Country,
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "Tel" } }, [
                        _vm._v("Telephone*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.Phone[0].PhoneNumber,
                            expression: "registerForm.Phone[0].PhoneNumber",
                          },
                        ],
                        ref: "Tel",
                        staticClass: "form-control",
                        attrs: {
                          type: "tel",
                          pattern: "^[0-9-+\\s()]*$",
                          id: "Tel",
                          name: "Tel",
                          placeholder: "Telephone number",
                          required: "",
                        },
                        domProps: {
                          value: _vm.registerForm.Phone[0].PhoneNumber,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.registerForm.Phone[0],
                              "PhoneNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "DateOfBirth" } }, [
                        _vm._v(
                          "Let us know the month you were born, so we can send you a birthday treat!"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.registerForm.DateOfBirth,
                              expression: "registerForm.DateOfBirth",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { name: "DateOfBirth", id: "DateOfBirth" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.registerForm,
                                "DateOfBirth",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select month of birth"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-01-01" } }, [
                            _vm._v("January"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-02-01" } }, [
                            _vm._v("February"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-03-01" } }, [
                            _vm._v("March"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-04-01" } }, [
                            _vm._v("April"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-05-01" } }, [
                            _vm._v("May"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-06-01" } }, [
                            _vm._v("June"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-07-01" } }, [
                            _vm._v("July"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-08-01" } }, [
                            _vm._v("August"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-09-01" } }, [
                            _vm._v("September"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-10-01" } }, [
                            _vm._v("October"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-11-01" } }, [
                            _vm._v("November"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1970-12-01" } }, [
                            _vm._v("December"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label float-left",
                        attrs: { for: "NoEmail" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.registerForm.NoEmail,
                              expression: "registerForm.NoEmail",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "NoEmail" },
                          domProps: {
                            checked: Array.isArray(_vm.registerForm.NoEmail)
                              ? _vm._i(_vm.registerForm.NoEmail, null) > -1
                              : _vm.registerForm.NoEmail,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.registerForm.NoEmail,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.registerForm,
                                      "NoEmail",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.registerForm,
                                      "NoEmail",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.registerForm, "NoEmail", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          "\n                            Tick to receive the latest news, events and exclusive offers.\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _vm.loading
                        ? _c("span", [
                            _c("div", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status" },
                            }),
                            _vm._v(" Loading..."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "validation" }, [
                        _vm._v(_vm._s(_vm.error)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit", disabled: _vm.loading },
                    },
                    [_vm._v("Register")]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "registerModalTitle" } },
        [_vm._v("Register")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }