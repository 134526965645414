<template>
    <div class="icons cart-header" :class="{'is-changed': hasChanged}" v-if="$route.path != '/book/success'">
        <a href="#" id="cart" @click="toggleMenu()">
            <i class="icon-cart"></i>
            <span 
                class="badge badge-danger" 
                v-if="
                    cart.WebFolioItem !== null && 
                    cart.WebFolioItem !== undefined &&
                    cart.WebFolioItem.length > 0"
                >
                    {{ cart.WebFolioItem.length }}
                </span>
            </a> 
        <div class="shopping-cart" v-if="isOpen" v-on-clickaway="() => isOpen = false">
            <div class="shopping-cart-header">
                <h2>Cart</h2>
                <div class="shopping-cart-total">
                    <span class="main-color-text">&pound;{{ cart.FolioBalance || 0 }}</span>
                </div>
            </div>

            <div class="shopping-cart__body">    
                <div v-if="cart.WebFolioItem === null || cart.WebFolioItem === undefined || cart.WebFolioItem.length === 0">
                    <div class="shopping-cart__empty">
                        <p>Your cart is empty</p>
                    </div>
                </div>
                <div v-for="(folioItem, category) in folioByCategory()" v-if="cart.WebFolioItem !== null && cart.WebFolioItem !== undefined && cart.WebFolioItem.length > 0">
                    <h2 class="shopping-cart__category">{{ category }}</h2>
                    <ul class="shopping-cart-items">
                        <li class="clearfix" v-for="(item, key) in folioItem"  :key="key">

                            <div v-if="category == 'Your Visit'">
                                <div class="item-headers">
                                    <div class="item-location">{{  item.Location }}</div>
                                    <div class="item-date">{{ item.StartDate | shortdate }}</div>
                                </div>

                                <div class="item-details">
                                    <div class="item-name">
                                        {{ item.Details ? item.Details : item.RateDetails }}
                                        <span v-if="item.twinSelected">(Twin room)</span>
                                        <span v-if="item.doubleSelected">(Double room)</span>
                                        <div class="item-guests" v-if="item.Type == 'Stay'">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"/></svg>
                                            {{ item.Adults }} Guests - {{ item.RateDetails }}
                                        </div>
                                        <div class="item-guests" v-if="item.Type == 'Spa Day'">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"/></svg>
                                            {{ item.Adults }} Guests - {{ item.RateDetails }}
                                        </div>
                                    </div>
                                    <div class="item-price">&pound;{{ (+item.Amount).toFixed(2) }}</div>
                                    <button type="button" class="btn btn-danger" @click.prevent="removeItem(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                        <span class="sr-only sr-only-focusable">Remove</span>
                                    </button>
                                </div>

                                <div v-if="!item.HotelOnly && item.InclusiveTreatments && item.InclusiveTreatments.Inclusives[0] !== null" class="inclusive-items">
                                    <h5>Inclusive Treatments <span class="badge">{{ item.InclusiveTreatments.Inclusives.length }}</span></h5> 
                                    <ul> 
                                        <li v-for="(treatment, index) in item.InclusiveTreatments.Inclusives" :key="index" v-if="treatment">
                                            {{ treatment.Details | str_limit(35) }}

                                            <button type="button" class="btn btn-danger" @click.prevent="removeTreatment(treatment)">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                                <span class="sr-only sr-only-focusable">Remove</span>
                                            </button>
                                        </li>
                                    </ul>

                                </div>

                                <div v-if="!item.HotelOnly && item.Treatments.length > 0" class="inclusive-items">
                                    <h5>Additional Treatments <span class="badge">{{ item.Treatments.length }}</span></h5>
                                    <ul>
                                        <li v-for="(treatment, index) in item.Treatments" :key="index">
                                            <div class="treatment-name">
                                                {{ treatment.Details | str_limit(35) }}
                                            </div>
                                            <div class="treatment-price">
                                                &pound;{{ treatment.Amount }}
                                            </div>
                                            <button type="button" class="btn btn-danger" @click.prevent="removeTreatment(treatment)">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                                <span class="sr-only sr-only-focusable">Remove</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="item.extras && item.extras.specialServices && item.extras.specialServices.PMSFolioSpecialService.length > 0" class="inclusive-items">
                                    <h5>Finishing Touches <span class="badge">{{ item.extras.specialServices.PMSFolioSpecialService.length }}</span></h5>
                                    <ul>
                                        <li v-for="(specialService, index) in item.extras.specialServices.PMSFolioSpecialService" :key="index">
                                            <div class="treatment-name">
                                                {{ specialService.PMSSpecialService.PMSSpecialServiceName | str_limit(45) }} <span v-if="specialService.Qty > 1">x {{  specialService.Qty}}</span>
                                            </div>
                                            <div class="treatment-price">
                                                &pound;{{ specialService.TotalWithSurcharges }} 
                                            </div>
                                            <button type="button" class="btn btn-danger" @click.prevent="removeExtras(specialService.PMSFolioSpecialServiceId, item.extras.specialServices.PMSFolioId)">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                                <span class="sr-only sr-only-focusable">Remove</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="category == 'Treatments'">
                                <div class="item-headers">
                                    <div class="item-location">{{  item.Location }}</div>
                                    <div class="item-date">{{ item.StartDate | shortdate }}</div>
                                </div>

                                <div class="item-details">
                                    <div class="item-name">
                                        {{ item.Details | treatment_title }}

                                        <div class="item-guests">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                                            {{ calculateTimeDifference(item.StartDate, item.FinishDate) }} minutes
                                        </div>
                                    </div>
                                    <div class="item-price">&pound;{{ (+item.Amount).toFixed(2) }}</div>
                                    <button type="button" class="btn btn-danger" @click.prevent="removeTreatment(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                        <span class="sr-only sr-only-focusable">Remove</span>
                                    </button>
                                </div>
                            </div>

                            <div v-if="category == 'Retail'" >
                                <div class="item-details">
                                    <div class="item-name">
                                        {{ item.Details | giftcard_title }}
                                    </div>
                                    <div class="item-price">&pound;{{ (+item.FolioTotal).toFixed(2) }}</div>

                                    <button type="button" class="btn btn-danger" @click.prevent="removeGiftCard(item.FolioId, item.FolioItemId)">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                        <span class="sr-only sr-only-focusable">Remove</span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>


            </div>

            <a href="#" @click="continueBooking()" class="btn btn-primary btn-block m-0" :class="{ disabled: cart.WebFolioItem === null || cart.WebFolioItem === undefined || cart.WebFolioItem.length === 0 }">
                Complete Booking
            </a>
        </div>
    </div>
</template>

<script>
    import { directive as onClickaway } from 'vue-clickaway';
    export default {
        directives: {
            onClickaway: onClickaway,
        },
        props: {
            cart: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                bookingExists: false,
                hasChanged: false,
                isOpen: false
            }
        },
        mounted() {
            Bus.$on('showLoading', () => {
                $('#loadingOverlay').removeClass('hide-loading');
            });
            Bus.$on('hideLoading', () => {
                $('#loadingOverlay').addClass('hide-loading');
            });
        },
        watch: {
            'cart.WebFolioItem': {
                handler: function(newVal, oldVal) {
                    if(newVal !== undefined) {
                        if(newVal !== oldVal && newVal.length > 0) {
                            this.hasChanged = true
                            setTimeout(() => this.hasChanged = false, 3000);
                        }
                    }
                },
                deep: true
            },
            cart: function(value) {
                if(value.WebFolioItem) {
                    this.checkExistingBooking();
                } else {
                    this.bookingExists = null;
                }
            },
        },
        methods: {
            toggleMenu() {
                if(this.$screen.width < 375 || this.$screen.height < 620) {
                    if(!this.bookingExists) {
                        this.$router.push('/book/cart');
                    } else {
                        var venue = this.bookingExists.Location == 'Eden Hall - Day Spa' ? 'eden-hall' : 'hoar-cross-hall'

                        if(this.bookingExists.type == 'Stay') {
                            this.$router.push('/book/enhance/' + venue + '/stay/' + this.bookingExists.StartDate.slice(0, -6) + '/' + this.bookingExists.Nights + '/' + this.bookingExists.Adults)
                        } else {
                            this.$router.push('/book/enhance/' + venue + '/spa-day/' + this.bookingExists.StartDate.slice(0, -6) + '/' + this.bookingExists.Adults)
                        }
                    }
                } else {
                    this.isOpen = !this.isOpen;
                }
            },
            folioByCategory() {
                const categories = _.groupBy(this.cart.WebFolioItem, 'Category');
                const updatedCategories = { ...categories };

                if (updatedCategories['Hotel']) {
                    updatedCategories['Your Visit'] = updatedCategories['Hotel'];
                    delete updatedCategories['Hotel'];
                }

                if (updatedCategories['Spa']) {
                    updatedCategories['Treatments'] = updatedCategories['Spa'];
                    delete updatedCategories['Spa'];
                }

                return updatedCategories;
            },
            calculateTimeDifference(dateString1, dateString2) {
                const date1 = moment(dateString1, 'YYYY-MM-DDHHmmss');
                const date2 = moment(dateString2, 'YYYY-MM-DDHHmmss');
                const differenceInMinutes = date2.diff(date1, 'minutes');
                return Math.abs(differenceInMinutes);
            },
            removeItem(item) {
                Bus.$emit('RemoveItem', item)
            },
            removeTreatment(treatment) {
                var request = {
                    method: 'CancelSpaService',
                    data: {
                        SessionId: this.$root.system.SessionId,
                        WebFolioId: this.$root.system.WebFolioId,
                        SpaFolioId: treatment.FolioId,
                        SpaFolioItemId: treatment.FolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {                            
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            removeExtras(id, folio) {
                var request = {
                    method: 'RemovePMSSpecialService',
                    data: {
                        SessionId: this.$root.system.SessionId,
                        WebFolioId: this.$root.system.WebFolioId,
                        PMSFolioId: folio,
                        PMSFolioSpecialServiceId: id
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            removeGiftCard(FolioId, FolioItemId) {
                var request = {
                    method: 'CancelRetailItem',
                    data: {
                        SessionId: this.$root.system.SessionId,
                        WebFolioId: this.$root.system.WebFolioId,
                        RetFolioId: FolioId,
                        RetFolioItemId: FolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response => {
                        if(response.data.Result.value === 'SUCCESS') {
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            checkExistingBooking() {
                var current = _.find(this.cart.WebFolioItem, {'Category': 'Hotel'});
                
                if(current) {
                    current.guests = _.sumBy(this.cart.WebFolioItem, 'Adults');
                    this.bookingExists = current;
                }
            },
            continueBooking() {
                this.checkExistingBooking();

                if(!this.bookingExists) {
                    this.$router.push('/book/cart');
                } else {

                    var venue = this.bookingExists.Location == 'Eden Hall - Day Spa' ? 'eden-hall' : 'hoar-cross-hall'

                    if(this.bookingExists.type == 'Stay') {
                        this.$router.push('/book/enhance/' + venue + '/stay/' + this.bookingExists.StartDate.slice(0, -6) + '/' + this.bookingExists.Nights + '/' + this.bookingExists.Adults)
                    } else {
                        this.$router.push('/book/enhance/' + venue + '/spa-day/' + this.bookingExists.StartDate.slice(0, -6) + '/' + this.bookingExists.Adults)
                    }
                }

                this.toggleMenu();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cart-header {
        position: relative;
        display: inline-block;
        margin: 0 10px;
        font-size: 15px !important;

        @media(max-width: 565px) {
            margin: 0px;
        }

        .badge {
            @media(max-width: 480px) {
                position: relative;
                top: -1px; left: -3px;
                font-size: 60%;
            }
        }

        &.is-changed {
            > a {
                animation: bounce 2.2s ease-out infinite;
            }
        }

        > a {
            display: inline-block;
            text-decoration: none;

            i {
                color: var(--brand);
            }
        }
    }

    .shopping-cart {
        margin: 20px 0;
        background: white;
        width: 420px;
        position: relative;
        padding: 20px;
        text-align: left;
        position: absolute;
        right: -38px;
        top: 24px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

        @media (max-width: 991px) {
            top: 18px
        }

        @media(max-width: 580px) {
            max-height: 620px;
            width: 101vw; height: 90vh;
            padding: 20px;
            text-align: left;
            position: absolute;
            left: -86vw;
        }

        @media(max-height: 580px) {
            display: none;
        }

        &__body {
            overflow-y: scroll;
            max-height: 500px;
            margin-bottom: 15px;

            @media screen and (max-width: 580px) {
                height: 84%;
            }
        }

        &__empty {
            background-color: #EFEFEF;
            padding: 10px;
            text-align: center;
            margin: 10px 0 0;

            p {
                margin-bottom: 0px;
            }
        }
    
        .shopping-cart-header {
            border-bottom: 1px solid #E8E8E8;
            width: 100%;
            overflow: hidden;

            h2 {
                font-size: 28px;
                float: left;
            }
            
            .shopping-cart-total {
                margin-top: 5px;
                float: right;
                font-family: filson-pro,sans-serif;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: #9d7b43;
            }
        }

        &__category {
            font-size: 20px;
            margin: 15px 0;
        }
    
        .shopping-cart-items {
            padding-top: 20px;
            list-style: none;
            margin: 0;
            padding: 0px;

            li {
                padding-bottom: 18px;
                margin-bottom: 18px;
                border-bottom: 1px solid #EFEFEF;
            }

            .item-headers {
                width: 100%;
                overflow: hidden;
                .item-location {
                    float: left;
                    display: block;
                    font-family: "adobe-garamond-pro", serif;
                    font-size: 16px;
                    font-weight: bold;
                    color: var(--brand);
                }
            
                .item-date {
                    float: right;
                    font-family: "adobe-garamond-pro", serif;
                    font-size: 14px;
                    font-weight: bold;
                    color: var(--brand);
                }
            }
                
            .item-details {
                position: relative;
                width: 100%;
                overflow: hidden;
                min-height: 45px;
                .item-name {
                    float: left;
                    display: block;
                    font-size: 14px;
                    max-width: 280px;

                    .item-guests {
                        font-size: 12px;
                        color: #9d7b43;

                        svg {
                            position: relative;
                            top: -1px;
                            fill: #9d7b43;
                        }
                    }
                }
            
                .item-price {
                    float: right;
                    font-family: filson-pro,sans-serif;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: #9d7b43;
                }

                .btn-danger {
                    position: absolute;
                    bottom: 3px; right: 0px;
                    padding: 0px 2px;
                    line-height: 1;
                    border-color: #dc3645;
                    margin: 0 0 0 5px;

                    &:hover,
                    &:focus {
                        background-color: darken(#dc3645, 10%);
                        border-color: darken(#dc3645, 10%);
                    }

                    svg {
                        fill: #FFF;
                    }
                }
                
            }

            .inclusive-items {
                margin: 10px 0;
                padding: 10px;
                background-color: #f5f5f5;

                h5 {
                    font-size: 14px;
                    margin-bottom: 10px;

                    .badge {
                        position: relative;
                        top: -2px;
                        background-color: #9d7b43;
                        color: white;
                    }
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding-left: 0;

                    li {
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        font-size: 12px;
                        padding-bottom: 5px;
                        margin-bottom: 5px;

                        .treatment-name {
                            float: left;
                            max-width: 250px;
                        }

                        .treatment-price {
                            margin-left: 10px;
                            margin-right: 20px;
                            float: right;
                            font-family: filson-pro,sans-serif;
                            font-size: 12px;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            color: #9d7b43;
                        }

                        .btn-danger {
                            position: absolute;
                            top: 3px; right: 0px;
                            padding: 0px 2px;
                            line-height: 1;
                            border-color: #dc3645;
                            margin: 0 0 0 5px;

                            &:hover,
                            &:focus {
                                background-color: darken(#dc3645, 10%);
                                border-color: darken(#dc3645, 10%);
                            }

                            svg {
                                fill: #FFF;
                            }
                        }

                        &:last-child {
                            border: 0px;
                            padding-bottom: 0px;
                        }
                         
                        > span {
                            display: block;
                            font-size: 12px;
                        }
                    }
                }
            
            }
        }
    }

    .shopping-cart:after {
        bottom: 100%;
        left: 89%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: white;
        border-width: 8px;
        margin-left: -8px;
    }

    @keyframes bounce {
        0% {
            -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0)
        }

        10% {
            -webkit-transform: scale(var(--fa-bounce-start-scale-x,1.1),var(--fa-bounce-start-scale-y,.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x,1.1),var(--fa-bounce-start-scale-y,.9)) translateY(0)
        }

        30% {
            -webkit-transform: scale(var(--fa-bounce-jump-scale-x,.9),var(--fa-bounce-jump-scale-y,1.1)) translateY(var(--fa-bounce-height,-.5em));
            transform: scale(var(--fa-bounce-jump-scale-x,.9),var(--fa-bounce-jump-scale-y,1.1)) translateY(var(--fa-bounce-height,-.5em))
        }

        50% {
            -webkit-transform: scale(var(--fa-bounce-land-scale-x,1.05),var(--fa-bounce-land-scale-y,.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x,1.05),var(--fa-bounce-land-scale-y,.95)) translateY(0)
        }

        57% {
            -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound,-.125em));
            transform: scale(1) translateY(var(--fa-bounce-rebound,-.125em))
        }

        64% {
            -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0)
        }

        to {
            -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0)
        }
    }
</style>