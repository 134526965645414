var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pre-arrival-component" },
    [
      !_vm.selected_guest
        ? _c("div", { staticClass: "pre-arrival-list" }, [
            _c("div", { staticClass: "p-5" }, [
              _c("h5", { staticClass: "brandHeading" }, [
                _vm._v("Pre-arrival Consultation"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                We need some details from you to ensure you receive the best\n                possible experience.\n            "
                ),
              ]),
              _vm._v(" "),
              _vm.bookingData
                ? _c("div", [
                    _c("h5", [_vm._v("Booking Reference")]),
                    _vm._v(" "),
                    _c("h3", [_vm._v(_vm._s(_vm.folio_id))]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "small",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.clearFolioId()
                          },
                        },
                      },
                      [_vm._v("Not your booking? Change reference number.")]
                    ),
                  ])
                : _c("div", { staticClass: "text-center" }, [
                    _c("p", [_vm._v("Please enter your booking reference")]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "form-inline justify-content-center",
                        attrs: { autocomplete: "off" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getBooking()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group mb-2" }, [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: "folio_id" },
                            },
                            [_vm._v("Booking reference")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.folio_id,
                                expression: "folio_id",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "tel",
                              id: "folio_id",
                              placeholder: "Booking reference",
                            },
                            domProps: { value: _vm.folio_id },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.folio_id = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary mb-2",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                        Submit\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-1" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getBookingError) +
                          "\n                "
                      ),
                    ]),
                  ]),
            ]),
            _vm._v(" "),
            _vm.bookingData
              ? _c("table", { staticClass: "table" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.bookingData.guests, function (guest) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(guest.name))]),
                        _vm._v(" "),
                        _c("td", [
                          guest.customer_id
                            ? _c("span", [_vm._v("Consultation completed")])
                            : _c("span", [
                                _vm._v("Complete pre-arrival consultation"),
                              ]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          !guest.customer_id
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary mb-0",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.selectGuest(guest)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      loading: "lazy",
                                      src: "/img/arrow-right.svg",
                                      alt: "Start consultation arrow",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "pre-arrival-form" }, [
            _c("div", { staticClass: "pre-arrival-form-user" }, [
              _c("h5", { staticClass: "brandHeading" }, [
                _vm._v("Log in or create an account"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Do you already have an account with us?")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  class: { selected: _vm.showLoginRegister == "login" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectLoginRegister("login")
                    },
                  },
                },
                [_vm._v("\n                Yes\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  class: { selected: _vm.showLoginRegister == "register" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectLoginRegister("register")
                    },
                  },
                },
                [_vm._v("\n                No\n            ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.backToStart()
                      },
                    },
                  },
                  [_vm._v("Back a step")]
                ),
              ]),
              _vm._v(" "),
              _vm.showLoginRegister == "login"
                ? _c("div", { staticClass: "mt-5 mb-3" }, [
                    _vm.customer_id
                      ? _c("div", [
                          _c("h3", [
                            _vm._v(
                              "\n                        Hi " +
                                _vm._s(_vm.customer_first_name) +
                                "\n                        " +
                                _vm._s(_vm.customer_last_name) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c(
                            "form",
                            {
                              staticClass: "row justify-content-center",
                              attrs: { autocomplete: "off" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.login.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "col-md-6 text-left" }, [
                                _c("h5", [_vm._v("Log in")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group mb-2" }, [
                                  _c("label", { attrs: { for: "email" } }, [
                                    _vm._v("Email address"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.EmailAddress,
                                        expression: "loginForm.EmailAddress",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "email",
                                      id: "email",
                                      placeholder: "Email address",
                                    },
                                    domProps: {
                                      value: _vm.loginForm.EmailAddress,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.loginForm,
                                          "EmailAddress",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group mb-3" }, [
                                  _c("label", { attrs: { for: "password" } }, [
                                    _vm._v("Password"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.Password,
                                        expression: "loginForm.Password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      id: "password",
                                      placeholder: "Password",
                                    },
                                    domProps: { value: _vm.loginForm.Password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.loginForm,
                                          "Password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary mb-3",
                                    attrs: { type: "submit" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Log in\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "feedback-container" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.loginError) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "small" }, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.forgotPassword.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Forgot Password?")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendDetails()
                    },
                  },
                },
                [
                  _vm.showLoginRegister == "register"
                    ? _c("div", { staticClass: "mt-5 mb-3 text-left" }, [
                        _c("h5", { staticClass: "mb-1" }, [
                          _vm._v("Enter details"),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "xs" }, [
                          _vm._v("Please fill out all fields."),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6 mb-2" },
                            [
                              _c("label", { attrs: { for: "FirstName" } }, [
                                _vm._v("First name"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.Name.FirstName,
                                    expression: "registerForm.Name.FirstName",
                                  },
                                ],
                                ref: "FirstName",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "FirstName",
                                  placeholder: "First name",
                                  required: "",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.registerForm.Name.FirstName,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm.Name,
                                      "FirstName",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6 mb-2" },
                            [
                              _c("label", { attrs: { for: "LastName" } }, [
                                _vm._v("Surname"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.Name.LastName,
                                    expression: "registerForm.Name.LastName",
                                  },
                                ],
                                ref: "LastName",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "LastName",
                                  placeholder: "Surname",
                                  required: "",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.registerForm.Name.LastName,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm.Name,
                                      "LastName",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6 mb-2" },
                            [
                              _c("label", { attrs: { for: "registerEmail" } }, [
                                _vm._v("Email address"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.EmailAddress,
                                    expression: "registerForm.EmailAddress",
                                  },
                                ],
                                ref: "registerEmail",
                                staticClass: "form-control",
                                attrs: {
                                  type: "email",
                                  id: "registerEmail",
                                  placeholder: "Email address",
                                  required: "",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.registerForm.EmailAddress,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm,
                                      "EmailAddress",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6 mb-2" },
                            [
                              _c("label", { attrs: { for: "PhoneNumber" } }, [
                                _vm._v("Telephone"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.registerForm.Phone[0].PhoneNumber,
                                    expression:
                                      "registerForm.Phone[0].PhoneNumber",
                                  },
                                ],
                                ref: "PhoneNumber",
                                staticClass: "form-control",
                                attrs: {
                                  type: "tel",
                                  id: "PhoneNumber",
                                  placeholder: "Phone number",
                                  required: "",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.registerForm.Phone[0].PhoneNumber,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm.Phone[0],
                                      "PhoneNumber",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6 mb-3" },
                            [
                              _c("label", { attrs: { for: "PostCode" } }, [
                                _vm._v("Post Code"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.Address[0].PostCode,
                                    expression:
                                      "registerForm.Address[0].PostCode",
                                  },
                                ],
                                ref: "PostCode",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "PostCode",
                                  placeholder: "Post code",
                                  required: "",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.registerForm.Address[0].PostCode,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm.Address[0],
                                      "PostCode",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12 mb-2" },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-radio custom-control-inline",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.registerForm.Gender,
                                        expression: "registerForm.Gender",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "radio",
                                      id: "genderFemale",
                                      value: "F",
                                      name: "customRadioInline",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.registerForm.Gender,
                                        "F"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.registerForm,
                                          "Gender",
                                          "F"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "genderFemale" },
                                    },
                                    [_vm._v("Female")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-radio custom-control-inline",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.registerForm.Gender,
                                        expression: "registerForm.Gender",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "radio",
                                      id: "genderMale",
                                      value: "M",
                                      name: "customRadioInline",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.registerForm.Gender,
                                        "M"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.registerForm,
                                          "Gender",
                                          "M"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "genderMale" },
                                    },
                                    [_vm._v("Male")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-radio custom-control-inline",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.registerForm.Gender,
                                        expression: "registerForm.Gender",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "radio",
                                      id: "genderNull",
                                      name: "customRadioInline",
                                      selected: "",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.registerForm.Gender,
                                        null
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.registerForm,
                                          "Gender",
                                          null
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "genderNull" },
                                    },
                                    [_vm._v("Prefer not to say")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customer_id || _vm.showLoginRegister == "register"
                    ? _c("div", [
                        _c("div", { staticClass: "pre-arrival-form-medical" }, [
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-auto my-1" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.medicalForm.Pregnant,
                                        expression: "medicalForm.Pregnant",
                                      },
                                    ],
                                    staticClass: "custom-select mr-sm-2 mb-2",
                                    attrs: { id: "Pregnant", required: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.medicalForm,
                                          "Pregnant",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "No" } }, [
                                      _vm._v("No"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "Yes" } }, [
                                      _vm._v("Yes"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.medicalForm.Pregnant == "Yes"
                                ? _c("div", { staticClass: "col-11" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mr-sm-2",
                                        attrs: { for: "PregnantNotes" },
                                      },
                                      [
                                        _vm._v(
                                          "How many weeks will you be at the time\n                                    of your visit?"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.medicalForm.PregnantNotes,
                                          expression:
                                            "medicalForm.PregnantNotes",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "PregnantNotes", rows: "3" },
                                      domProps: {
                                        value: _vm.medicalForm.PregnantNotes,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.medicalForm,
                                            "PregnantNotes",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-auto my-1" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.medicalForm.Conditions,
                                        expression: "medicalForm.Conditions",
                                      },
                                    ],
                                    staticClass: "custom-select mr-sm-2 mb-2",
                                    attrs: { id: "Conditions", required: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.medicalForm,
                                          "Conditions",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "No" } }, [
                                      _vm._v("No"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "Yes" } }, [
                                      _vm._v("Yes"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.medicalForm.Conditions == "Yes"
                                ? _c("div", { staticClass: "col-11" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mr-sm-2",
                                        attrs: { for: "ConditionsNotes" },
                                      },
                                      [
                                        _vm._v(
                                          "If you answered yes above, please\n                                    provide our team with any further\n                                    information you may deem helpful."
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.medicalForm.ConditionsNotes,
                                          expression:
                                            "medicalForm.ConditionsNotes",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "ConditionsNotes",
                                        rows: "3",
                                      },
                                      domProps: {
                                        value: _vm.medicalForm.ConditionsNotes,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.medicalForm,
                                            "ConditionsNotes",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-auto my-1" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.medicalForm.Dietary,
                                        expression: "medicalForm.Dietary",
                                      },
                                    ],
                                    staticClass: "custom-select mr-sm-2 mb-2",
                                    attrs: { id: "Dietary", required: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.medicalForm,
                                          "Dietary",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "No" } }, [
                                      _vm._v("No"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "Yes" } }, [
                                      _vm._v("Yes"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.medicalForm.Dietary == "Yes"
                                ? _c("div", { staticClass: "col-11" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mr-sm-2",
                                        attrs: { for: "DietaryNotes" },
                                      },
                                      [
                                        _vm._v(
                                          "If you answered yes above, please\n                                    provide our team with any further\n                                    information you may deem helpful."
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.medicalForm.DietaryNotes,
                                          expression:
                                            "medicalForm.DietaryNotes",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "DietaryNotes", rows: "3" },
                                      domProps: {
                                        value: _vm.medicalForm.DietaryNotes,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.medicalForm,
                                            "DietaryNotes",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _vm._m(5),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-auto my-1" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.medicalForm.Allergies,
                                        expression: "medicalForm.Allergies",
                                      },
                                    ],
                                    staticClass: "custom-select mr-sm-2 mb-2",
                                    attrs: { id: "Allergies", required: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.medicalForm,
                                          "Allergies",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "No" } }, [
                                      _vm._v("No"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "Yes" } }, [
                                      _vm._v("Yes"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.medicalForm.Allergies == "Yes"
                                ? _c("div", { staticClass: "col-11" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mr-sm-2",
                                        attrs: { for: "AllergiesNotes" },
                                      },
                                      [
                                        _vm._v(
                                          "If you answered yes above, please\n                                    provide our team with any further\n                                    information you may deem helpful."
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.medicalForm.AllergiesNotes,
                                          expression:
                                            "medicalForm.AllergiesNotes",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "AllergiesNotes",
                                        rows: "3",
                                      },
                                      domProps: {
                                        value: _vm.medicalForm.AllergiesNotes,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.medicalForm,
                                            "AllergiesNotes",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _vm._m(6),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-auto my-1" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.medicalForm.Medication,
                                        expression: "medicalForm.Medication",
                                      },
                                    ],
                                    staticClass: "custom-select mr-sm-2 mb-2",
                                    attrs: { id: "Medication", required: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.medicalForm,
                                          "Medication",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "No" } }, [
                                      _vm._v("No"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "Yes" } }, [
                                      _vm._v("Yes"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.medicalForm.Medication == "Yes"
                                ? _c("div", { staticClass: "col-11" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mr-sm-2",
                                        attrs: { for: "MedicationNotes" },
                                      },
                                      [
                                        _vm._v(
                                          "If you answered yes above, please\n                                    provide our team with any further\n                                    information you may deem helpful."
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.medicalForm.MedicationNotes,
                                          expression:
                                            "medicalForm.MedicationNotes",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "MedicationNotes",
                                        rows: "3",
                                      },
                                      domProps: {
                                        value: _vm.medicalForm.MedicationNotes,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.medicalForm,
                                            "MedicationNotes",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-auto my-1" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.medicalForm.DateOfBirth,
                                      expression: "medicalForm.DateOfBirth",
                                    },
                                  ],
                                  staticClass: "form-control mr-sm-2 mb-2",
                                  attrs: {
                                    type: "date",
                                    id: "DOB",
                                    required: "",
                                  },
                                  domProps: {
                                    value: _vm.medicalForm.DateOfBirth,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.medicalForm,
                                        "DateOfBirth",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pre-arrival-form-marketing text-left",
                          },
                          [
                            _c("h5", [
                              _vm._v(
                                "\n                            Be the first to receive our exclusive offers?\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-row align-items-center" },
                              [
                                _c("div", { staticClass: "col-auto my-1" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.marketingForm.NoEmail,
                                          expression: "marketingForm.NoEmail",
                                        },
                                      ],
                                      staticClass: "custom-select mr-sm-2 mb-2",
                                      attrs: { id: "NoEmail", required: "" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.marketingForm,
                                            "NoEmail",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "Y" } }, [
                                        _vm._v("No"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "N" } }, [
                                        _vm._v("Yes"),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(8),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary mb-2",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  "\n                            Submit Details\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
      _vm._v(" "),
      _c("forgot-password-modal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Guest name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("label", { attrs: { for: "Gender" } }, [_vm._v("Gender")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "mr-sm-2", attrs: { for: "Pregnant" } }, [
        _vm._v("Are you currently pregnant?"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "mr-sm-2", attrs: { for: "Conditions" } }, [
        _vm._v(
          "Are you currently being treated for; or\n                                    have you had any illnesses, injuries or\n                                    conditions including high blood\n                                    pressure, cancer or increased\n                                    sensitivity?"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "mr-sm-2", attrs: { for: "Dietary" } }, [
        _vm._v(
          "Do you have any dietary requirements or\n                                    restrictions?"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "mr-sm-2", attrs: { for: "Allergies" } }, [
        _vm._v(
          "Do you have any allergies that may\n                                    affect your treatment?"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "mr-sm-2", attrs: { for: "Medication" } }, [
        _vm._v(
          "Are you currently taking any\n                                    medication?"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("label", { staticClass: "mr-sm-2", attrs: { for: "DOB" } }, [
        _vm._v(
          "What is your date of birth? (Minimum\n                                    age 16yrs for spa guests)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "marketing-consent-message" }, [
      _c("p", [
        _vm._v(
          "\n                                By clicking submit you are confirming that\n                                you are the person named in this form and\n                                you have answered the questions truthfully\n                                and to the best of your knowledge. Should\n                                your answers change between now and your\n                                visit to the spa, it is your responsibility\n                                to make us aware by calling a member of our\n                                reservations team prior to arrival.\n                            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }