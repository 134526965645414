import { render, staticRenderFns } from "./PortalLogout.vue?vue&type=template&id=fbe2d57e"
import script from "./PortalLogout.vue?vue&type=script&lang=js"
export * from "./PortalLogout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.baronseden.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fbe2d57e')) {
      api.createRecord('fbe2d57e', component.options)
    } else {
      api.reload('fbe2d57e', component.options)
    }
    module.hot.accept("./PortalLogout.vue?vue&type=template&id=fbe2d57e", function () {
      api.rerender('fbe2d57e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/membership/PortalLogout.vue"
export default component.exports