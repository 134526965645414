var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _vm.accounts
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-5" }, [
              _c("div", { staticClass: "mb-4" }, [
                _c("p", { staticClass: "account-header" }, [
                  _vm._v("Member Name:"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "huge" }, [
                  _vm._v(
                    _vm._s(_vm.accounts.Memberships.Membership.FirstName) +
                      " " +
                      _vm._s(_vm.accounts.Memberships.Membership.LastName)
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", { staticClass: "account-header" }, [
                  _vm._v("Membership No:"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "huge" }, [
                  _vm._v(_vm._s(_vm.system.CustomerCode)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-7" }, [
              _c("div", { staticClass: "mb-4" }, [
                _c("p", { staticClass: "account-header mb-1" }, [
                  _vm._v("Membership type:"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "account-detail" }, [
                  _vm._v(_vm._s(_vm.accounts.Memberships.Membership.Name)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _c("ul", { staticClass: "inline-list" }, [
                  _c("li", [
                    _c("p", { staticClass: "account-header mb-1" }, [
                      _vm._v("Start date:"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "account-detail" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("rsdateslash")(
                            _vm.accounts.Memberships.Membership.EffectiveDate
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", { staticClass: "account-header mb-1" }, [
                      _vm._v("End date:"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "account-detail" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("rsdateslash")(
                            _vm.accounts.Memberships.Membership.ExpiryDate
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", { staticClass: "account-header mb-1" }, [
                  _vm._v("Status:"),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "account-detail text-lower-to-capitalise" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.accounts.Memberships.Membership.Status.toLowerCase()
                      )
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12 d-none" }, [
              _c("hr"),
              _vm._v(" "),
              _vm.billing
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-borderless" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.billing, function (bill) {
                          return _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(_vm._s(_vm._f("nicedate")(bill.BillDate))),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bill.Charges))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bill.Payments))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bill.AccountBalance))]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm",
                                  attrs: { disabled: _vm.loadingStatement },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.viewStatement(bill.BillDate)
                                    },
                                  },
                                },
                                [_vm._v("View statement")]
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm.loadingBilling
                ? _c("div", { staticClass: "text-center" }, [
                    _c("div", {
                      staticClass: "spinner-border spinner-border-sm",
                      attrs: { role: "status" },
                    }),
                    _vm._v(" Loading billing...\n                "),
                  ])
                : _c("div", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.billingError) +
                        "\n                "
                    ),
                  ]),
            ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-md-6 text-center" }, [
              _vm.loading
                ? _c("div", [
                    _c("div", {
                      staticClass: "spinner-border",
                      attrs: { role: "status" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "ffs text-uppercase mt-5" }, [
                      _vm._v("Fetching profile"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.error
                ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.error))])])
                : _vm._e(),
            ]),
          ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: "statement-modal", "aria-hidden": "true" },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" },
            },
            [
              _vm.statement
                ? _c("div", { staticClass: "modal-content" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c("h3", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.statement.title)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticClass: "modal-body__intro" }, [
                        _vm._v(_vm._s(_vm.statement.archive_intro)),
                      ]),
                      _vm._v(" "),
                      _c("h4", [_vm._v("What to expect")]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "modal-body__details",
                        domProps: { innerHTML: _vm._s(_vm.statement.details) },
                      }),
                    ]),
                  ])
                : _c("div", { staticClass: "modal-content" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _vm._v(
                        "\n                        No statement available.\n                    "
                      ),
                    ]),
                  ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "ffs text-uppercase" }, [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Billing date")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Charges")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Payments")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Balance")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h3", { staticClass: "modal-title" }, [_vm._v("Sorry...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }