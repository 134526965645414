<template>
    <form @submit.prevent="enquire">
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.firstName"
                    ref="firstName"
                    type="text"
                    placeholder="First Name*"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.lastName"
                    ref="lastName"
                    type="text"
                    placeholder="Last Name"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.tel"
                    ref="tel"
                    type="tel"
                    placeholder="Mobile"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.emailAddress"
                    ref="emailAddress"
                    type="email"
                    placeholder="Email"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.landline"
                    ref="landline"
                    type="tel"
                    placeholder="Landline (optional)"
                    class="form-control"
                />
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-12">
                <label>{{ consent_message }}</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 mb-2">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="email_opt_in">
                        <input
                            v-model="formData.email_opt_in"
                            type="checkbox"
                            class="form-check-input"
                            id="email_opt_in"
                        />
                        <span class="checkmark"></span>
                        Email
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="sms_opt_in">
                        <input
                            v-model="formData.sms_opt_in"
                            type="checkbox"
                            class="form-check-input"
                            id="sms_opt_in"
                        />
                        <span class="checkmark"></span>
                        SMS
                    </label>
                </div>
            </div>
            <div class="col-md-4">
                <div
                    v-if="sending"
                    class="spinner-border text-light"
                    role="status"
                ></div>
                <button
                    type="submit"
                    :disabled="sending"
                    class="btn btn-reversed float-right"
                >
                    Submit
                </button>
            </div>
        </div>

        <p class="signup-message" v-if="success">
            Thank you for your enquiry. We will be in touch with you shortly.
        </p>
    </form>
</template>

<script>
var formTemplate = function () {
    return {
        firstName: null,
        lastName: null,
        tel: null,
        landline: null,
        emailAddress: null,
        email_opt_in: false,
        sms_opt_in: false,
    };
};
export default {
    props: {
        email: String,
        venue: String,
        revinate_id: String,
        consent_message: String,
    },
    data() {
        return {
            formData: new formTemplate(),
            success: null,
            sending: false,
        };
    },

    mounted() {},

    methods: {
        enquire() {
            var self = this;

            self.sending = true;

            // populate form data, even from autocomplete
            self.formData.firstName = this.$refs.firstName.value;
            self.formData.lastName = this.$refs.lastName.value;
            self.formData.emailAddress = this.$refs.emailAddress.value;
            self.formData.tel = this.$refs.tel.value;
            self.formData.landline = this.$refs.landline.value;
            self.formData.revinate_id = this.revinate_id;

            window.getRecaptchaToken().then(function (r) {
                var data = {
                    email: self.email,
                    venue: self.venue,
                    formData: self.formData,
                    recaptcha: r,
                };
                axios
                    .post("/system/subscribe/new-member", data)
                    .then((response) => {
                        self.success = true;
                        self.sending = false;

                        dataLayer.push({
                            event: "form_submission",
                            form_type: "lead_form",
                            form_subject: "Membership Enquiry",
                            venue: self.venue,
                        });

                        this.$emitAnalytics(
                            "form_submission",
                            {
                                event: "form_submission",
                                form_type: "lead_form",
                                form_subject: "Membership Enquiry",
                                venue: self.venue,
                            },
                            self.system.CustomerId,
                            self.system.WebfolioId
                        );

                        self.formData = new formTemplate();
                    });
            });
        },
    },
};
</script>
