<template>
    <div class="calendar">
        <v-calendar
            mode="single"
            v-model="selectedDate"
            :from-date="fromDate"
            :min-date='initMinDate'
            :max-date='null'
            :attributes='atts'
            @dayclick="dayClick($event)"
            @update:to-page="nextMonth($event)"
            is-expanded
            is-inline
            is-linked
            >
            <a slot='header-left-button' class="prevNextArrow arrowLeft">&nbsp;</a>
            <a slot='header-right-button' class="prevNextArrow arrowRight">&nbsp;</a>
            <div
                slot="day-content"
                slot-scope="{ day, attributes, dayEvents }"
                :day='day'
                v-on="dayEvents"
                class="vc-day-content"
                :class="{ daySelected: day.id == arrive, 'vc-text-gray-400': !attributes }"
                >
                <div>
                    <div>{{ day.day }}</div>
                    <div class="vc-day-content__rate" v-for="{key, customData} in attributes"
                        :key="key" :class="{ twilight: customData.twilight, abiDot: customData.discount }">
                        <span v-if="customData.price">
                            <span v-if="customData.discount">&pound;{{ (customData.price - ((customData.price / 100)*10)).toFixed(2) }}</span>
                            <span v-else>&pound;{{ customData.price.toFixed(2) }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </v-calendar>
        <button class="btn btn-secondary btn-block mt-2 main-calendar-action" :disabled="!selectedDate || !isDateAvailable()" @click.prevent="search()">Next Step</button>
        <button class="btn btn-secondary btn-block mt-2 sideout-calendar-action" :disabled="!selectedDate || !isDateAvailable()" @click.prevent="closeDateSideout()">View available experiences</button>
        <div v-if="fetchingRates" class="loading-calendar">
            <span class="pr-1 text-uppercase">Fetching the finer details</span><div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div v-else>
            <div v-if="type != 'treatments'">
                <span class="message-small">Prices shown are lowest rate available, per person<span v-if="type == 'stay'">, per night</span>.</span> 
                <div v-if="0">
                    <span class="abiDot-before message-small">Price shown includes 10% advanced booking discount.</span>
                </div>
                <span v-if="type == 'spa-day'" class="twilight-key">Days with Twilight Spa available from <span v-if="venue.includes('Eden')">&pound;65</span><span v-else>&pound;60</span></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            venue: String,
            type: String,
            mindate: String,
            arrive: String,
            promo: String,
            system: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                atts: null,
                fetchingRates: false,
                calendarRates: [],
                calendarMonths: {},
                selectedDate: null,
                initMinDate: null,
                temp: null,
                fromDate: null
            }
        },

        watch: {
            calendarRates: function() {
                var noDupes = this.calendarRates.map(rate => ({
                        dates: rate.date,
                        customData: rate,
                    }));
                this.atts = _.uniqBy(noDupes, 'dates')
            },
            type() {
                this.calendarRates = []
                var start = moment().format('YYYY-MM-DD')
                var end = moment(start).endOf("month").format('YYYY-MM-DD')
                if(this.type == 'treatments') {
                    this.basicCalendar(start, end)
                } else {
                    this.fetchCalendar(start, end)
                }
            },
            selectedDate() {
                if(this.selectedDate) {
                    this.fromDate = new Date(this.selectedDate)
                }
            },
            promo() {
                var start = moment().format('YYYY-MM-DD')
                var end = moment(start).endOf("month").format('YYYY-MM-DD')
                this.fetchCalendar(start, end)
            }
        },

        mounted() {
            if(this.arrive) {
                var start = moment(this.arrive).format('YYYY-MM-DD')
                this.selectedDate = new Date(this.arrive)
            } else {
                var start = moment().format('YYYY-MM-DD')
            }
            var end = moment(start).endOf("month").format('YYYY-MM-DD')

            if(this.type == 'treatments') {
                this.basicCalendar(start, end)
            } else {
                this.fetchCalendar(start, end)
            }
        },
            

        /**
         * Methods
         */
        methods: {
            logit(msg) {
               console.log(msg) 
            },
            search() {
                Bus.$emit('search')
            },
            closeDateSideout() {
                $('.date-selector').removeClass('is-active');
                $('html').removeClass('no-scroll');
            },
            nextMonth(next) {
                var month = moment(next.month, 'M').format('MM')
                
                if(!this.calendarMonths[next.year + '-' + month]) {
                    var start
                    if(moment().format('YYYY-MM') == next.year + '-' + month) { // current month
                        start = moment().format('YYYY-MM-DD')
                    } else {
                        start = moment([next.year, next.month-1]).startOf('month').format('YYYY-MM-DD')
                    }
                    var end = moment(start).endOf("month").format('YYYY-MM-DD')
                    
                    if(this.type == 'treatments') {
                        this.basicCalendar(start, end)
                    } else {
                        this.fetchCalendar(start, end)
                    }
                    this.calendarMonths[next.year + '-' + month] = true;
                }
            },

            dayClick(e) {
                Bus.$emit('changeDate', e.id)
                this.selectedDate = e.id
                window.scrollTo(0,document.body.scrollHeight)
            },

            basicCalendar(start, end) {
                var self = this;

                var currDate = moment(start).startOf('day');
                var lastDate = moment(end).startOf('day');

                while(currDate.add(1, 'days').diff(lastDate) <= 0) {
                    var calDay = {
                        date: currDate.format('YYYY-MM-DD'),
                        price: null,
                        twilight: null
                    }
                    self.calendarRates.push(calDay)
                }

            },

            fetchCalendar(start, end) {
                if(moment(start) > moment().add(365, 'days')) {
                    this.getRates(start, end)
                } else {
                    this.getCachedRates(start, end)
                }
            },

            getRates(start, end) {
                var self = this;
                this.fetchingRates = true
                //var venue

                // if(this.venue == 'hoar-cross-hall') {
                //     if(this.type == 'stay') {
                //         venue = 'Hoar Cross Hall'
                //     } else {
                //         venue = 'Hoar Cross Hall - Day Spa'
                //     }
                // } else {
                //     venue = 'Eden Hall - Day Spa'
                // }

                var promo

                if(this.promo) {
                    promo = this.promo
                } else {
                    promo = this.type == 'spa-day' ? 'DAYGUEST' : null
                }

                var request = {
                    method: 'FetchPMSVenueCalendar',
                    data: {
                        Venue: this.venue,
                        StartDate: start,
                        EndDate: end,
                        PromoCode: promo,
                        RateType: null,
                        WebFolioId: null,
                        Language: null
                    }
                };
                
                axios.post('/rs/request', request)
                    .then(response=> {
                        if (!$.isArray(response.data.BarRateAvailability)) { // convert single entry to an array
                            response.data.BarRateAvailability = [response.data.BarRateAvailability]
                        }

                        _.each(response.data.BarRateAvailability, function(day) {
                            
                            if(day) {
                                
                                day.date = day.Date.substring(0, day.Date.length - 6)
                                if(day.RateCardDailyAvailability) {
                                    if (!$.isArray(day.RateCardDailyAvailability)){ // convert single entry to an array
                                        day.RateCardDailyAvailability = [day.RateCardDailyAvailability]
                                    }

                                    // remove hotel only rates
                                    if(self.type == 'stay') {
                                        day.RateCardDailyAvailability = _.filter(day.RateCardDailyAvailability, function(rate) {
                                            return !_.includes(self.system.hotelOnlyRates, rate.RateType)
                                        })

                                    } else if(self.type == 'hotel-stay') {
                                        day.RateCardDailyAvailability = _.filter(day.RateCardDailyAvailability, function(rate) {
                                            return _.includes(self.system.hotelOnlyRates, rate.RateType)
                                        })
                                    }

                                    // filter the result based on rooms available and availabile for this day of week
                                    var ratesForDay = _.filter(day.RateCardDailyAvailability, function(r) {
                                        if(self.type == 'spa-day' || self.type == 'hotel-stay') {
                                            return (r.NumRoomsAvail > 0 && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N")
                                        } else {
                                            if(r.RateType == "RPURITYSM") {
                                                return (r.NumRoomsAvail > 0 && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N" && Number(r.MaxLength) == 1 && Number(r.MinOccupancy) == 1)
                                            } else {   
                                                return (r.NumRoomsAvail > 0 && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N" && Number(r.MaxLength) == 1 && Number(r.MinOccupancy) == 2)
                                            }
                                        } 
                                    })
                                    
                                    ratesForDay = _.filter(ratesForDay, function(r) {
                                        if(r.RateType !== "RPURITYSM") {
                                            return (
                                                r.RoomType !== "CSNGL"
                                            );
                                        } else {
                                            return true
                                        }
                                    });
                                    
                                    var twilight = null
                                    var discount = null
                                    var lowestRate = null
                                    
                                    if(self.type == 'stay') {
                                        lowestRate = _.minBy(ratesForDay, function(rate) {
                                            return +rate.DoubleRate
                                        })
                                    } else {
                                        var twi = _.filter(ratesForDay, function(twi) {
                                            return (twi.RoomType == 'HTWI' || twi.RoomType == 'EFEE')
                                        });

                                        var fullPackages = _.filter(ratesForDay, function(full) {
                                            if(full.RoomType != 'HTWI' && full.RoomType != 'EFEE') {
                                                return true
                                            } else {
                                                return false
                                            }
                                        });
                                        
                                        if(fullPackages.length) {
                                            lowestRate = _.minBy(fullPackages, function(rate) {
                                                return +rate.SingleRate
                                            })
                                        } else {
                                            if(twi.length) {
                                                lowestRate = twi[0]
                                            }
                                        }
                                        if(twi.length) {
                                            twilight = twi[0]
                                        }                                        
                                    }

                                    if(lowestRate) {
                                        // Now for the good stuff
                                        /*
                                        if(self.venue == 'Hoar Cross Hall') {
                                            if(moment(day.date) > moment().add(6, 'weeks')) {
                                                discount = true
                                            }
                                        }
                                        
                                        if(moment(day.date).format('M') == 9)  {
                                            if(lowestRate.RateType == 'EHSERENITY' || lowestRate.RateType == 'EHINFINITY') {
                                                discount = true
                                            }
                                        }
                                        */
                                        if(_.includes(['RFESTIVEBALL', 'RFESTIVEESC', 'RFESTIVEGRAND', 'RFESTIVELUXESC', 'RWEBSERENITY', 'RWEBINFINITY', 'RSOOTHINGBF'], lowestRate.RateType)) {
                                            discount = false
                                        }

                                        var price = self.type == 'stay' ? (+lowestRate.DoubleRate / 2) : +lowestRate.SingleRate
                                        var calDay = {
                                            date: day.date,
                                            price: price,
                                            twilight: twilight,
                                            discount: discount
                                        }
                                        self.calendarRates.push(calDay)
                                    }
                                }
                            }
                        })
                        this.fetchingRates = null
                    });
            },
            /*
            * Chached rates
            */
            getCachedRates(start, end) {
                var self = this;
                this.fetchingRates = true
                
                var request = {
                    venue: this.venue,
                    start: start,
                    end: end,
                    promo: this.promo,
                }
                
                axios.post('/api/cached/calendar', request)
                    .then(response=> {
                        if(response.data.calendar.length) {
                            _.each(response.data.calendar, function(day) {

                                // remove hotel only rates
                                if(self.type == 'stay') {
                                    day.rates = _.filter(day.rates, function(rate) {
                                        return !_.includes(self.system.hotelOnlyRates, rate.RateType)
                                    })
                                } else if(self.type == 'hotel-stay') {
                                    day.rates = _.filter(day.rates, function(rate) {
                                        return _.includes(self.system.hotelOnlyRates, rate.RateType)
                                    })
                                }
                                
                                var ratesForDay = _.filter(day.rates, function(r) {
                                    if(self.type == 'spa-day' || self.type == 'hotel-stay') {
                                        return (r.NumRoomsAvail > 0 && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N")
                                    } else {
                                        if(r.RateType == "RPURITYSM") {
                                            return (r.NumRoomsAvail > 0 && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N" && Number(r.MaxLength) == 1 && Number(r.MinOccupancy) == 1)
                                        } else {   
                                            return (r.NumRoomsAvail > 0 && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N" && Number(r.MaxLength) == 1 && Number(r.MinOccupancy) == 2)
                                        }
                                    } 
                                })
                                
                                ratesForDay = _.filter(ratesForDay, function(r) {
                                    if(r.RateType !== "RPURITYSM") {
                                        return (
                                            r.RoomType !== "CSNGL"
                                        );
                                    } else {
                                        return true
                                    }
                                });

                                if(self.venue == 'Eden Hall - Day Spa' && moment(day.date).format('YYYY-MM-DD') == '2024-12-25') {
                                    ratesForDay = _.filter(ratesForDay, function(r) {
                                        return false;
                                    })
                                }

                                var twilight = null
                                var discount = null
                                var lowestRate = null
                                
                                if(self.type != 'spa-day') {
                                    lowestRate = _.minBy(ratesForDay, function(rate) {
                                        return +rate.DoubleRate
                                    })
                                } else {
                                    var twi = _.filter(ratesForDay, function(twi) {
                                        return (twi.RoomType == 'HTWI' || twi.RoomType == 'EFEE')
                                    })
                                    
                                    var fullPackages = _.filter(ratesForDay, function(full) {
                                        if(full.RoomType != 'HTWI' && full.RoomType != 'EFEE') {
                                            return true
                                        } else {
                                            return false
                                        }
                                    })
                                    
                                    if(fullPackages.length) {
                                        lowestRate = _.minBy(fullPackages, function(rate) {
                                            return +rate.SingleRate
                                        })
                                    } else {
                                        if(twi.length) {
                                            lowestRate = twi[0]
                                        }
                                    }
                                    if(twi.length) {
                                        twilight = twi[0]
                                    }                                        
                                }

                                if(lowestRate) {
                                    // Now for the good stuff
                                    /*
                                    if(self.venue == 'Hoar Cross Hall') {
                                        if(moment(day.date) > moment().add(6, 'weeks')) {
                                            discount = true
                                        }
                                    }
                                    
                                    if(moment(day.date).format('M') == 9)  {
                                        if(lowestRate.RateType == 'EHSERENITY' || lowestRate.RateType == 'EHINFINITY') {
                                            discount = true
                                        }
                                    }
                                    */
                                    if(_.includes(['RFESTIVEBALL', 'RFESTIVEESC', 'RFESTIVEGRAND', 'RFESTIVELUXESC', 'RWEBSERENITY', 'RWEBINFINITY', 'RSOOTHINGBF'], lowestRate.RateType)) {
                                        discount = false
                                    }

                                    var price = self.type == 'spa-day' ? +lowestRate.SingleRate : (+lowestRate.DoubleRate / 2)
                                    var calDay = {
                                        date: moment(day.date).format('YYYY-MM-DD'),
                                        price: price,
                                        twilight: twilight,
                                        discount: discount
                                    }
                                    self.calendarRates.push(calDay)
                                }
                                
                                
                            })
                        } else {
                            this.basicCalendar(start, end)
                        }
                        this.fetchingRates = null
                    });
            },

            isDateAvailable() {
                const date = _.find(this.calendarRates, {date: this.selectedDate});
                return (date ? true : false);
            }

        },
    }
</script>