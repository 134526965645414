<template>
    <div>
        <div v-if="system.isLoggedIn">
            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="members-logo">
                                <a
                                    v-if="venue == 'eden-hall'"
                                    href="/eden-hall/members-portal"
                                    ><img
                                        loading="lazy"
                                        src="/img/eh_members.svg"
                                        alt="Eden Hall Members Logo"
                                        class="img-fluid"
                                /></a>
                                <a v-else href="/hoar-cross-hall/members-portal"
                                    ><img
                                        loading="lazy"
                                        src="/img/hch_members.svg"
                                        alt="Hoar Cross Hall Members Logo"
                                        class="img-fluid"
                                /></a>
                            </div>
                        </div>
                        <div class="col-md-10 align-self-end">
                            <nav>
                                <ul>
                                    <li><a href="">Book Classes</a></li>
                                    <li><a href="">My Profile</a></li>
                                    <li><a href="">Account</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <template v-for="section in sections">
                <div v-html="section"></div>
            </template>
        </div>

        <div v-else>
            <portal-login
                :system="system"
                :cart="cart"
                venue="venue"
            ></portal-login>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        system: Object,
        cart: Object,
        venue: String,
        sections: Array,
    },

    components: {},

    data() {
        return {
            isClubAccount: false,
        };
    },

    mounted() {
        console.log("Component mounted");
        //this.accountDetails()
    },

    watch: {},

    methods: {
        accountDetails() {
            var request = {
                method: "FetchClubAccounts",
                data: {
                    CustomerId: null,
                    CustomerGUID: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                console.log(response.data);
            });
        },
    },
};
</script>
