<template>
    <div>
        <div class="row justify-content-md-center">
            <div class="col-md-8 text-center">
                <div class="book-start">
                    <div class="min-300">
                        <form @submit.prevent="availability">
                            <div class="mb-5">
                                <div class="form-group brand-select mb-2">
                                    <img
                                        v-if="venue == 'hoar-cross-hall'"
                                        src="/img/hch_cream_full.svg"
                                        alt="Hoar Cross Hall logo"
                                        loading="lazy"
                                    />
                                    <img
                                        v-if="venue == 'eden-hall'"
                                        src="/img/eh_cream_full.svg"
                                        alt="Eden Hall logo"
                                        loading="lazy"
                                    />
                                </div>
                                <a
                                    @click.prevent="backStep()"
                                    class="small ffss"
                                    >Back a step</a
                                >
                            </div>

                            <div>
                                <h5 class="mb-4">
                                    What type of experience did you have in
                                    mind?
                                </h5>
                                <div
                                    v-if="venue == 'hoar-cross-hall'"
                                    class="form-group"
                                >
                                    <div class="mb-3">
                                        <a
                                            @click.prevent="
                                                selectType('hotel-stay')
                                            "
                                            class="btn btn-primary btn-block type-select"
                                            >Hotel Stay</a
                                        >
                                        <a
                                            @click.prevent="selectType('stay')"
                                            class="btn btn-primary btn-block type-select"
                                            >Spa Stay</a
                                        >
                                        <a
                                            @click.prevent="
                                                selectType('spa-day')
                                            "
                                            class="btn btn-primary btn-block type-select"
                                            >Spa Day</a
                                        >
                                    </div>
                                    <div>
                                        <a
                                            @click.prevent="
                                                selectType('treatments')
                                            "
                                            class="btn btn-primary btn-block type-select"
                                            >Treatments</a
                                        >
                                        <a
                                            @click.prevent="
                                                selectType('afternoon-tea')
                                            "
                                            class="btn btn-primary btn-block type-select"
                                            >Afternoon Tea</a
                                        >
                                    </div>
                                </div>
                                <div v-else class="mb-3">
                                    <div>
                                        <a
                                            @click.prevent="
                                                selectType('spa-day')
                                            "
                                            class="btn btn-primary btn-block type-select"
                                            >Spa Day</a
                                        >
                                        <a
                                            @click.prevent="
                                                selectType('treatments')
                                            "
                                            class="btn btn-primary btn-block type-select"
                                            >Treatments</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="bookingExists" class="modal-message existing-booking-modal">
            <div class="modal-message-container">
                <h2><span>Hey</span> there!</h2>
                <p>It looks like you already have a booking in the cart.</p>
                <h5>
                    {{ bookingExists.RateDetails }} at
                    {{ bookingExists.Location | nicevenue }}
                </h5>
                <h6>{{ bookingExists.StartDate | rsnicedate }}</h6>
                <h6>Guests: {{ bookingExists.guests }}</h6>
                <div class="mt-4">
                    <p>What would you like to do?</p>
                    <button
                        @click.prevent="continueBooking('enhance')"
                        class="btn btn-secondary"
                    >
                        Continue with this booking
                    </button>
                    <button
                        @click.prevent="continueBooking('booking')"
                        class="btn btn-secondary"
                    >
                        Add another booking to this day
                    </button>
                    <button
                        @click.prevent="restartBooking()"
                        class="btn btn-secondary"
                    >
                        Start over
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        system: Object,
        cart: Object,
    },

    components: {},

    data() {
        return {
            venue: this.$route.params.venue,
            bookingExists: null,
        };
    },

    beforeMount() {},

    mounted() {
        var self = this;
        Bus.$emit("RefreshSystem");
        if (self.cart.WebFolioItem) {
            self.checkExistingBooking();
        }

        dataLayer.push({
            event: "journey_begin",
            venue: location,
        });

        this.$emitAnalytics("journey_begin", {
            event: "checkout_success",
            venue: location,
        });
    },

    computed: {
        checkReady() {
            if (this.venue && this.type != "") {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
        cart: function (newVal, oldVal) {
            if (newVal.WebFolioItem) {
                this.checkExistingBooking();
            } else {
                this.bookingExists = null;
            }
        },
    },

    methods: {
        checkExistingBooking() {
            // check if we already have a booking in the cart
            var current = _.find(this.cart.WebFolioItem, { Category: "Hotel" });
            if (current) {
                // We have a stay/spa day
                current.guests = _.sumBy(this.cart.WebFolioItem, "Adults");
                this.bookingExists = current;
            }
        },
        continueBooking(type) {
            var venue =
                this.bookingExists.Location == "Eden Hall - Day Spa"
                    ? "eden-hall"
                    : "hoar-cross-hall";

            if (this.bookingExists.type == "Stay") {
                this.$router.push(
                    "/book/" +
                        type +
                        "/" +
                        venue +
                        "/stay/" +
                        this.bookingExists.StartDate.slice(0, -6) +
                        "/" +
                        this.bookingExists.Nights +
                        "/" +
                        this.bookingExists.Adults
                );
            } else {
                this.$router.push(
                    "/book/" +
                        type +
                        "/" +
                        venue +
                        "/spa-day/" +
                        this.bookingExists.StartDate.slice(0, -6) +
                        "/" +
                        this.bookingExists.Adults
                );
            }
        },

        selectType(type) {
            this.$router.push("/book/search/" + this.venue + "/" + type);
        },

        backStep() {
            this.$router.push("/book");
        },
        restartBooking() {
            Bus.$emit("RestartBooking", true);
        },
    },
};
</script>
