<template>
    <div class="book-component">
        <div class="col-md-12">
            <h3>Check Availability</h3>
        </div>
        <div class="calendar">
            <v-calendar
                mode="range"
                ref="calendar"
                v-model="range"
                :min-date='calStart'
                :from-page.sync="calPage"
                :max-date='null'
                :attributes='atts'
                @dayclick="dayClick($event)"
                @update:to-page="nextMonth($event)"
                is-expanded
                is-inline
                is-linked
                >
                <span
                    slot="day-content"
                    slot-scope="{ day, attributes, dayEvents }"
                    :day='day'
                    v-on="dayEvents"
                    class="vc-day-content"
                    :class="{ daySelected: day.id == range, 'vc-pointer-events-none vc-text-gray-400': !attributes }"
                    >
                    <div>
                        <div class="text-center">{{ day.day }}</div>
                        <div class="vc-day-content__rate" v-for="{key, customData} in attributes"
                            :key="key">
                            &pound;{{ customData.price }}
                        </div>
                    </div>
                </span>
            </v-calendar>
        </div>

        <div class="book-component-selector text-center">
            <ul style="display:none;">
                <li>
                    <p>Nights</p>
                    <button @click="decNights" class="btn btn-plus-minus">-</button>
                    <span class="book-int">{{ nights }}</span>
                    <button @click="incNights" class="btn btn-plus-minus">+</button>
                </li>
                <li>
                    <p>Guests</p>
                    <button @click="decGuests" class="btn btn-plus-minus">-</button>
                    <span class="book-int">{{ guests }}</span>
                    <button @click="incGuests" class="btn btn-plus-minus">+</button>
                </li>
                <li>
                    <p>Rooms</p>
                    <button @click="decRooms" class="btn btn-plus-minus">-</button>
                    <span class="book-int">{{ room }}</span>
                    <button @click="incRooms" class="btn btn-plus-minus">+</button>
                </li>
            </ul>
            <p v-if="fetchingRates" class="text-center"><span class="spinner-border spinner-border-sm" role="status"></span> Fetching rates</p>
            <p v-if="!fetchingRates && !hasAvailability" class="text-center">No availability</p>
            <button class="btn mt-3" type="button" @click="checkAvailability()" :class="{ disabled: isDisabled }" :disabled="isDisabled">
                Book Now
            </button>
        </div>
    </div>
        
</template>

<script>
    import VCalendar from 'v-calendar';
    export default {
        props: {
            rate: String,
            venue: String,
            start: String,
            package: String,
            weekday: Number,
            minnights: Number,
            promo: String
        },
        data() {
            const month = new Date().getMonth();
            const year = new Date().getFullYear();
            return {
                venueName: this.$options.filters.venue(this.venue),
                promoCode: this.promo,
                RateType: this.rate.split(" ")[0],
                urlRate: this.rate ? this.rate.split(" ").join('-') : null,
                range: null,
                nights: 1,
                guests: 2,
                room: 1,
                calStart: this.start && moment(this.start) > moment() ? moment(this.start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                endOfMonth: moment().endOf('month').format('YYYY-MM-DD'),
                calendarRates: [],
                calendarMonths: {},
                allRates: null,
                atts: null,
                tempEnd: null,
                fetchingRates: null,
                price: null,
                calendarAvailability: {},
                calPage: null
            }
        },

        mounted() {
            
            if(this.rate) {
                
                var start = this.start && moment(this.start) > moment() ? moment(this.start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
                var end = moment(start).endOf("month").format('YYYY-MM-DD')

                this.getRates(start, end)
            }
        },

        computed: {
            isDisabled () {
                if (this.range) {
                    return false;
                } else {
                    return true;
                }
            },
            hasAvailability() {
                if(this.calPage && this.calendarAvailability[this.calPage.month]) {
                    return this.calendarAvailability[this.calPage.month]
                }
            }
        },

        watch: {
            calendarRates: function() {
                var noDupes = this.calendarRates.map(rate => ({
                        dates: rate.date,
                        customData: rate,
                    }))
                this.atts = _.uniqBy(noDupes, 'dates')
            }
        },

        methods: {
            nextMonth(next) {
                this.noAvailability = false
                if(this.rate && next.month != moment().format('M')) {
                    if(!this.calendarMonths[next.year + '-' + next.month]) {
                        this.getRates(moment([next.year, next.month - 1]).startOf('month').format('YYYY-MM-DD'), moment([next.year, next.month - 1]).endOf("month").add(1, 'days').format('YYYY-MM-DD'))
                    }
                    this.calendarMonths[next.year + '-' + next.month] = true;
                }
            },
            dayClick(e) {
                this.range = e.id
                this.price = e.attributes[0].customData.price
            },
            // Nights
            decNights() {
                if(this.nights > 1) {
                    this.nights--
                }
            },
            incNights() {
                if(this.nights < 30) {
                    this.nights++
                }
            },
            // Guests
            decGuests() {
                if(this.guests > 1) {
                    this.guests--
                }
            },
            incGuests() {
                if(this.guests < 4) {
                    this.guests++
                }
            },
            // Rooms
            decRooms() {
                if(this.room > 1) {
                    this.room--
                }
            },
            incRooms() {
                if(this.room < 3) {
                    this.room++
                }
            },
            checkAvailability() {
                var venue = this.venue;
                if(this.promo) {
                    var nexturl = '/book/booking/' + venue + '/stay/' + moment(this.range).format('YYYY-MM-DD') + '/' +  this.minnights  + '/' + this.guests + '?promo=' + this.promo;
                } else {
                    var nexturl = '/book/booking/' + venue + '/stay/' + moment(this.range).format('YYYY-MM-DD') + '/' +  this.minnights  + '/' + this.guests;
                }
                
                window.location.href = nexturl;
            },
            getRates(start, end) {
                var self = this;
                this.fetchingRates = true
                this.noAvailability = false

                var request = {
                    method: 'FetchPMSVenueCalendar',
                    data: {
                        Venue: 'Hoar Cross Hall',
                        StartDate: start,
                        EndDate: end,
                        PromoCode: this.promoCode,
                        RateType: this.RateType,
                        WebFolioId: null,
                        Language: null
                    }
                };
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.BarRateAvailability) {
                            if (!$.isArray(response.data.BarRateAvailability)){ // convert single entry to an array
                                response.data.BarRateAvailability = [response.data.BarRateAvailability]
                            }

                            if(self.weekday) {
                                _.remove(response.data.BarRateAvailability, function(wd) {
                                    return [5,6].includes(moment(wd.Date.substring(0, wd.Date.length - 6)).day())
                                })
                            }

                            self.allRates = response.data.BarRateAvailability;
                            _.each(response.data.BarRateAvailability, function(day) {

                                day.date = day.Date.substring(0, day.Date.length - 6)
                                if(day.RateCardDailyAvailability) {
                                    if (!$.isArray(day.RateCardDailyAvailability)){ // convert single entry to an array
                                        day.RateCardDailyAvailability = [day.RateCardDailyAvailability]
                                    }
                                    
                                    // split strings to array
                                    var rateCode = self.RateType;
                                    var validRates = [];
                                    // filter the result based on rooms available and availabile for this day of week
                                    _.each(day.RateCardDailyAvailability, function(rate) {
                                        if(_.includes(rateCode, rate.RateType)) { // check rate first
                                            if(+rate.NumRoomsAvail && rate.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N") {
                                                // Are we stopped?
                                                if(rate.StopArrivals == 'N') {
                                                    rate.date = day.date;
                                                    if(rate.RateType == 'ROYALPKG') {
                                                        rate.price = +rate.DoubleRate
                                                    } else {
                                                        // This is the price per person for the duration
                                                        if(+rate.DoubleRate % 1 !== 0) {
                                                            rate.price = (Math.round(+rate.DoubleRate / 2)).toFixed(2)
                                                        } else {
                                                            rate.price = (+rate.DoubleRate / 2).toFixed(2)
                                                        }
                                                    }
                                                    if(+rate.MinLength >= 2) {
                                                        rate.price = rate.price * +rate.MinLength
                                                    }
                                                    validRates.push(rate);
                                                }
                                            } 
                                        }
                                    });
                                    if(validRates.length) { // we have a rate for the calendar
                                        
                                        var lowest = _.minBy(validRates, 'price');
                                        if(!_.find(self.calendarRates, { date: lowest.date })) {
                                            self.calendarRates.push( lowest );
                                        }
                                    }
                                }
                            })
                            if(!self.calendarRates.length) {
                                Vue.set(this.calendarAvailability, moment(start).format('M'), false)
                            } else {
                                Vue.set(this.calendarAvailability, moment(start).format('M'), true)
                            }
                        } else {
                            Vue.set(this.calendarAvailability, moment(start).format('M'), false)
                        }
                        this.fetchingRates = false
                    });
            }
        }
    }
</script>
