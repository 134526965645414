<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-3">
                <h3 class="brandHeading text-center mb-5">My Bookings</h3>
            </div>
            <div class="col-md-12">
                <h3 class="mobile-text-center mb-3">
                    <span class="mr-3 d-block d-md-inline mb-3">Hungry for more?</span>
                    <a :href="'/' + venue + '/members-portal/book-classes'"  class="btn btn-primary">Browse Classes</a>
                </h3>
            </div>
            <div class="col-md-12">
                <div v-if="bookings.length" class="table-responsive">
                    <table class="table table-members">
                        <thead class="thead-dark">
                            <tr>
                                <th>Class</th>
                                <th class="d-none d-md-table-cell">Date</th>
                                <th class="d-none d-md-table-cell">Time</th>
                                <th class="d-none d-md-table-cell">Location</th>
                                <th>Need to cancel?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(day) in bookings">
                                <td>
                                    {{ day.ItemName }}
                                    <div class="d-md-none members-mobile">
                                        <span>{{ day.BookStartTime | rsnicecompactdate }}</span>
                                        <span>{{ day.BookStartTime | classtime }} - {{ day.BookEndTime | classtime }}</span>
                                        <span>{{ day.Room }}</span>
                                    </div>
                                </td>
                                <td class="d-none d-md-table-cell">{{ day.BookStartTime | rsnicecompactdate }}</td>
                                <td class="d-none d-md-table-cell">{{ day.BookStartTime | classtime }} - {{ day.BookEndTime | classtime }}</td>
                                <td class="d-none d-md-table-cell">{{ day.Room }}</td>
                                <td>
                                    <button @click.prevent="confirmCancel(day)" :disabled="removingBooking" class="btn btn-primary btn-sm mb-0">
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="loadingBookings">
                    Loading bookings...
                </div>
                <div v-else>
                    <p>You do not have any active bookings.</p>
                </div>
                <div v-if="cancelError" class="mt-4">
                    <p>{{ cancelError }}</p>
                </div>
                <div class="mt-4">
                    <p>* Please give a minimum of 4 hours when cancelling a class.</p>
                </div>
                <p v-if="bookingsError" class="paymentError">{{ bookingsError }}</p>
            </div>
        </div>
        <!-- Confirm cancel modal -->
        <div class="modal fade" id="cancelClassModal" tabindex="-1" aria-labelledby="cancelClassModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <p>Are you sure you wish to cancel the following class:</p>
                        <div v-if="classToCancel">
                            <h4>{{ classToCancel.ItemName }}</h4>
                            <p>{{ classToCancel.BookStartTime | rsshortdate }}.  {{ classToCancel.BookStartTime | rstime }} - {{ classToCancel.BookEndTime | rstime }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                        <button type="button" @click.prevent="cancelClass(classToCancel)" class="btn btn-secondary">Cancel class</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object,
            location: Number,
            venue: String
        },

        components: {
            
        },

        data() {
            return {
                bookings: [],
                loadingBookings: null,
                bookingsError: null,
                removingBooking: false,
                classToCancel: null,
                cancelError: null
            }
        },

        beforeMount() {
            Bus.$on('refreshMembersBookings', () => {
                this.getBookings();
            });
        },
        
        mounted() {
            Bus.$emit('RefreshSystem')
            this.getBookings()
        },

        watch: {
            
        },

        computed: {

        },

        methods: {
            getBookings() {
                this.loadingBookings = true
                var request = {
                    method: 'FetchCustomerSpaBookings',
                    data: {
                        CustomerId: this.system.CustomerId,
                        CustomerGUID: this.system.CustomerGUID,
                        BookStatus: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            if(!$.isArray(response.data.SpaFolio)) {
                                response.data.SpaFolio = [response.data.SpaFolio];
                            }
                            var bookingItems = []
                            _.each(response.data.SpaFolio, function(o) {
                                if(!$.isArray(o.SpaFolioItem)) {
                                    o.SpaFolioItem = [o.SpaFolioItem];
                                }
                                _.each(o.SpaFolioItem, function(item) {
                                    if(item.BookStatus != 'CNL') {
                                        var date = item.BookStartTime.substr(0, item.BookStartTime.length-6);
                                        var time = item.BookStartTime.substr(10, 6);
                                        var timeCheck = moment(new Date(date + 'T' + time.match(/.{1,2}/g).join(':')))
                                        if(timeCheck > moment()) {
                                            item.sortTime = timeCheck.format('YYYYMMDDHHmm')
                                            item.SpaFolioId = o.SpaFolioId
                                            if(item.LocationId == 200 || item.LocationId == 400) {
                                                bookingItems.push(item)
                                            }
                                        }
                                    }
                                })
                            })
                                
                            this.bookings = _.orderBy(bookingItems, ['sortTime'], 'asc')
                        } else {
                            this.bookingsError = response.data.Result.Text
                        }
                        this.loadingBookings = false
                    });
            },

            confirmCancel(day) {
                $('#cancelClassModal').modal('show')
                this.classToCancel = day
            },

            cancelClass(day) {                
                $('#cancelClassModal').modal('hide')
                Bus.$emit('showLoading')

                // Update session first
                var request = {
                        method: 'UpdateSession',
                        data: {
                            SessionId: this.system.SessionId,
                            WebFolioId: this.system.WebFolioId,
                            CustomerId: this.system.CustomerId,
                            BookingAgentId: null
                        }
                    }
                    axios.post('/rs/request', request)
                        .then(response=> {
                            if(response.data.Result.value === 'SUCCESS') {
                                console.log('session updated')
                                var request = {
                                    method: 'CancelSpaService',
                                    data: {
                                        SessionId: this.system.SessionId,
                                        WebFolioId: this.system.WebFolioId,
                                        SpaFolioId: day.SpaFolioId,
                                        SpaFolioItemId: day.SpaFolioItemId
                                    }
                                }
                                axios.post('/rs/request', request)
                                    .then(response=> {
                                        
                                        if(response.data.Result.value == 'SUCCESS') {
                                            this.getBookings()
                                        } else {
                                            this.cancelError = response.data.Result.Text
                                        }
                                        Bus.$emit('hideLoading')
                                        console.log(response.data)
                                    });
                            } else {
                                
                            }
                        });
                                
                    
            }
        },

    }
</script>
