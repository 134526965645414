<template>
    <div class="calendar">
        <v-calendar
            mode="single"
            v-model="selectedDate"
            :min-date="minDate"
            :max-date="maxDate"
            :attributes="atts"
            @dayclick="dayClick($event)"
            @update:to-page="nextMonth($event)"
            is-expanded
            is-inline
            is-linked
        >
            <div
                slot="day-content"
                slot-scope="{ day, attributes, dayEvents }"
                :day="day"
                v-on="dayEvents"
                class="vc-day-content"
                :class="{
                    daySelected: day.id == selectedDate,
                    'vc-pointer-events-none vc-text-gray-400': !attributes
                }"
            >
                <div>
                    <div>{{ day.day }}</div>
                    <div
                        class="vc-day-content__rate"
                        v-for="{ key, customData } in attributes"
                        :key="key"
                        :class="{ abiDot: customData.discount }"
                    >
                        <span v-if="customData.discount"
                            >&pound;{{
                                (
                                    customData.price -
                                    (customData.price / 100) *
                                        customData.discount
                                ).toFixed(2)
                            }}</span
                        >
                        <span v-else>
                            <span v-if="customData.price">&pound;{{ customData.price.toFixed(2) }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </v-calendar>
        <div class="row">
            <div class="col-lg-4 cal-loading mb-3">
                <span v-if="fetchingRates"
                    ><span>Loading calendar... </span>
                    <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                    ></div
                ></span>
                <span v-else>
                    <span v-if="discountRate" class="abiDot-before"
                        >price includes {{ discountRate }}% off booking
                        rate</span
                    >
                </span>
            </div>
            <div class="col-lg-8">
                <div class="d-xl-flex package-buttons">
                    <button 
                        class="btn btn-secondary flex-xl-fill mr-md-1"
                        @click.prevent="book"
                        :disabled="!selectedDate"
                    >
                        Book Now
                    </button> 
                    <button class="btn btn-outline-primary flex-xl-fill" 
                        v-if="giftcard != ''"
                        :href="giftcard">Buy as Gift Card
                    </button>
                </div>
            
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props: {
        venue: String,
        type: String,
        mindate: String,
        maxdate: String,
        rate: String,
        promo: String,
        weekday: Number,
        nights: Number,
        giftcard: String,
        package: String,
        exactprice: [String, Number],
        discount: [String, Number]
    },
    /**
     * The components data
     */
    data() {
        return {
            atts: null,
            fetchingRates: false,
            calendarRates: [],
            calendarMonths: {},
            selectedDate: null,
            temp: null,
            minDate: null,
            maxDate: null,
            discountRate: null
        };
    },

    watch: {
        calendarRates: function() {
            var noDupes = this.calendarRates.map(rate => ({
                dates: rate.date,
                customData: rate
            }));
            this.atts = _.uniqBy(noDupes, "dates");
        },
        type() {
            this.calendarRates = [];
            var start = moment().format("YYYY-MM-DD");
            var end = moment(start)
                .endOf("month")
                .format("YYYY-MM-DD");

            this.fetchCalendar(start, end);
        }
    },

    computed: {
        isTwilight() {
            return _.includes(this.package, "Twilight") ? true : false;
        },
        showDot() {
            if (this.type == "Stay") {
                if (this.promo == "") {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    },

    beforeMount() {
        if (this.mindate && moment(this.mindate) > moment()) {
            var start = moment(this.mindate).format("YYYY-MM-DD");
            var end = moment(start)
                .endOf("month")
                .format("YYYY-MM-DD");
            this.minDate = moment(this.mindate).format("YYYY-MM-DD");
        } else {
            var start = moment().format("YYYY-MM-DD");
            var end = moment(start)
                .endOf("month")
                .format("YYYY-MM-DD");
        }

        if (this.maxdate) {
            this.maxDate = moment(this.maxdate).format("YYYY-MM-DD");
        }

        this.fetchCalendar(start, end);
    },

    /**
     * Methods
     */
    methods: {
        nextMonth(next) {
            if (next.year + "-" + next.month != moment().format("YYYY-M")) {
                if (!this.calendarMonths[next.year + "-" + next.month]) {
                    this.fetchCalendar(
                        moment([next.year, next.month - 1])
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                        moment([next.year, next.month - 1])
                            .endOf("month")
                            .add(1, "days")
                            .format("YYYY-MM-DD")
                    );
                }
                this.calendarMonths[next.year + "-" + next.month] = true;
            }
        },

        dayClick(e) {
            this.selectedDate = e.id;
        },

        book() {
            var venue = this.venue;
            var promo = this.promo == "" ? "" : "?promo=" + this.promo;

            if (this.type == "Stay") {
                var nexturl =
                    "/book/booking/" +
                    venue +
                    "/stay/" +
                    moment(this.selectedDate).format("YYYY-MM-DD") +
                    "/" +
                    this.nights +
                    "/2" +
                    promo;
            } else if (this.type == "Hotel") {
                var nexturl =
                    "/book/booking/" +
                    venue +
                    "/hotel-stay/" +
                    moment(this.selectedDate).format("YYYY-MM-DD") +
                    "/" +
                    this.nights +
                    "/2" +
                    promo;
            } else {
                var nexturl =
                    "/book/booking/" +
                    venue +
                    "/spa-day/" +
                    moment(this.selectedDate).format("YYYY-MM-DD") +
                    "/2" +
                    promo;
            }

            window.location.href = nexturl;
        },

        basicCalendar(start, end) {
            var self = this;

            var currDate = moment(start).startOf("day");
            var lastDate = moment(end).startOf("day");

            while (currDate.add(1, "days").diff(lastDate) <= 0) {
                var calDay = {
                    date: currDate.format("YYYY-MM-DD"),
                    price: null,
                    twilight: null
                };
                self.calendarRates.push(calDay);
            }
        },

        fetchCalendar(start, end) {
            if (moment(start) > moment().add(365, 'days')) {
                this.getRates(start, end);
            } else {
                this.getCachedRates(start, end);
            }
        },

        getRates(start, end) {
            var self = this;

            this.fetchingRates = true;
            var venue;

            if (this.venue == "hoar-cross-hall") {
                if (this.type == "Stay" || this.type == "Hotel") {
                    venue = "Hoar Cross Hall";
                } else {
                    venue = "Hoar Cross Hall - Day Spa";
                }
            } else {
                venue = "Eden Hall - Day Spa";
            }

            if (this.promo) {
                var pc = this.promo;
            } else {
                var pc = this.type == "Spa Day" ? "DAYGUEST" : null;
            }

            var request = {
                method: "FetchPMSVenueCalendar",
                data: {
                    Venue: venue,
                    StartDate: start,
                    EndDate: end,
                    PromoCode: pc,
                    RateType: null,
                    WebFolioId: null,
                    Language: null
                }
            };

            axios.post("/rs/request", request).then(response => {
                if (!$.isArray(response.data.BarRateAvailability)) {
                    // convert single entry to an array
                    response.data.BarRateAvailability = [
                        response.data.BarRateAvailability
                    ];
                }

                if (self.weekday) {
                    _.remove(response.data.BarRateAvailability, function(wd) {
                        return [5, 6].includes(
                            moment(
                                wd.Date.substring(0, wd.Date.length - 6)
                            ).day()
                        );
                    });
                }

                this.temp = response.data.BarRateAvailability;

                _.each(response.data.BarRateAvailability, function(day) {
                    if (day) {
                        day.date = day.Date.substring(0, day.Date.length - 6);
                        if (day.RateCardDailyAvailability) {
                            if (!$.isArray(day.RateCardDailyAvailability)) {
                                // convert single entry to an array
                                day.RateCardDailyAvailability = [
                                    day.RateCardDailyAvailability
                                ];
                            }

                            var openRates = _.filter(
                                day.RateCardDailyAvailability,
                                function(r) {
                                    if(r.RateType === "RPURITYSM") {
                                        return (
                                            r.NumRoomsAvail > 0 &&
                                            r.AvailMonSun[
                                                moment(day.date).format("E") - 1
                                            ] != "N" &&
                                            Number(r.MinOccupancy) >= 1 &&
                                            Number(r.MinLength) >= 1
                                        );
                                    } else if(
                                        r.RateType == "DMAGICBF" || 
                                        r.RateType == "EHMAGICBF" || 
                                        r.RateType == "HRMAGIC3" ||
                                        r.RateType == "RSPONTSERENITY" ||
                                        r.RateType == "RFESTIVE"  ||
                                        r.RateType == "RNYECELEBRATE" ||
                                        r.RateType == "RNYESOIREE" ||
                                        r.RateType == "RXMAS3N" ||
                                        r.RateType == "RXMAS2N" ||
                                        r.RateType == "RNYESHEBANG" ||
                                        r.RateType == "RNYEPARTY"  ||
                                        r.RateType == "HCHTWIDULGE"
                                    ) {
                                        return (
                                            r.NumRoomsAvail > 0 &&
                                            r.AvailMonSun[
                                                moment(day.date).format("E") - 1
                                            ] != "N" &&
                                            Number(r.MinOccupancy) >= 1
                                        );
                                    } else {
                                        if(r.RateType == "HRDBB") {
                                            return (
                                                r.NumRoomsAvail > 0 &&
                                                r.AvailMonSun[
                                                    moment(day.date).format("E") - 1
                                                ] != "N" &&
                                                Number(r.MinOccupancy) == 2 &&
                                                Number(r.MinLength) >= 1
                                            );
                                        } else {
                                            return (
                                                r.NumRoomsAvail > 0 &&
                                                r.AvailMonSun[
                                                    moment(day.date).format("E") - 1
                                                ] != "N" &&
                                                Number(r.MinOccupancy) >= 2 &&
                                                Number(r.MinLength) >= 1
                                            );
                                        }
                                    }
                                }
                            );

                            if (self.rate) {
                                openRates = _.filter(openRates, function(r) {
                                    if(r.RateType !== "RPURITYSM") {
                                        return (
                                            r.RoomType !== "CSNGL" ||
                                            r.RoomType !== "GROOM"
                                        );
                                    } else {
                                        return (
                                            r.RoomType !== "GROOM"
                                        );
                                    }
                                   
                                });

                                if (openRates.length) {
                                    // split strings to array
                                    var rateCodes = self.rate.split(" ");
                                    var matchedRate = _.filter(
                                        openRates,
                                        function(r) {
                                            return _.includes(
                                                rateCodes,
                                                r.RateType
                                            );
                                        }
                                    );

                                    if (matchedRate.length) {
                                        var discount = false;

                                        if (self.type == "Spa Day") {
                                            // Spa days

                                            // if(moment(day.date) > moment().add(6, 'weeks')) {
                                            //     if(venue == 'Eden Hall - Day Spa') {
                                            //         if(!_.includes(matchedRate[0].RateTypeDesc, 'Twilight')) {
                                            //             discount = true
                                            //         } else {
                                            //             discount = false
                                            //         }
                                            //     }
                                            // }
                                            /*
                                                    if(moment(day.date).format('M') == 9)  {
                                                        if(matchedRate[0].RateType == 'EHSERENITY' || matchedRate[0].RateType == 'EHINFINITY') {
                                                            discount = true
                                                        } else {
                                                            discount = false
                                                        }
                                                    }
                                                    */

                                            _.each(matchedRate, function(r) {
                                                r.SingleRate = +r.SingleRate;
                                            });

                                            var price = _.minBy(
                                                matchedRate,
                                                "SingleRate"
                                            ).SingleRate;

                                            if (self.discount) {
                                                discount = self.discount;
                                                self.discountRate =
                                                    self.discount;
                                            }

                                            var calDay = {
                                                date: day.date,
                                                price: +price,
                                                discount: discount
                                            };

                                            if (self.exactprice) {
                                                if (
                                                    calDay.price ==
                                                    self.exactprice
                                                ) {
                                                    self.calendarRates.push(
                                                        calDay
                                                    );
                                                }
                                            } else {
                                                self.calendarRates.push(calDay);
                                            }
                                        } else {
                                            _.each(matchedRate, function(r) {
                                                r.DoubleRate = +r.DoubleRate;
                                            });

                                            var price = _.minBy(
                                                matchedRate,
                                                "DoubleRate"
                                            ).DoubleRate;

                                            price = price * self.nights;

                                            if (self.discount) {
                                                discount = self.discount;
                                                self.discountRate =
                                                    self.discount;
                                            }

                                            var calDay = {
                                                date: day.date,
                                                price: price / 2,
                                                discount: discount
                                            };

                                            if (self.exactprice) {
                                                if (
                                                    calDay.price ==
                                                    self.exactprice
                                                ) {
                                                    self.calendarRates.push(
                                                        calDay
                                                    );
                                                }
                                            } else {
                                                self.calendarRates.push(calDay);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
                this.fetchingRates = false;
            });
        },

        getCachedRates(start, end) {
            var self = this;

            this.fetchingRates = true;
            var venue;

            if (this.venue == "hoar-cross-hall") {
                if (this.type == "Stay" || this.type == "Hotel") {
                    venue = "Hoar Cross Hall";
                } else {
                    venue = "Hoar Cross Hall - Day Spa";
                }
            } else {
                venue = "Eden Hall - Day Spa";
            }

            var request = {
                venue: venue,
                start: start,
                end: end,
                promo: this.promo
            };

            axios.post("/api/cached/calendar", request).then(response => {
                if (response.data.calendar.length) {
                    _.each(response.data.calendar, function(day) {
                        // loop through each day

                        // Check if rates closed out
                        var openRates = _.filter(day.rates, function(r) {
                            if (self.type == "Spa Day") {
                                return (
                                    r.NumRoomsAvail > 0 &&
                                    r.AvailMonSun[
                                        moment(day.date).format("E") - 1
                                    ] != "N"
                                );
                            } else {
                                if(r.RateType === "RPURITYSM") {
                                    return (
                                        r.NumRoomsAvail > 0 &&
                                        r.AvailMonSun[
                                            moment(day.date).format("E") - 1
                                        ] != "N" &&
                                        Number(r.MinOccupancy) >= 1 &&
                                        Number(r.MinLength) >= 1
                                    );
                                } else if(
                                    r.RateType == "DMAGICBF" || 
                                    r.RateType == "EHMAGICBF" || 
                                    r.RateType == "HRMAGIC3" ||
                                    r.RateType == "RSPONTSERENITY" ||
                                    r.RateType == "RFESTIVE" ||
                                    r.RateType == "RNYECELEBRATE" ||
                                    r.RateType == "RNYESOIREE" ||
                                    r.RateType == "RXMAS3N" ||
                                    r.RateType == "RXMAS2N" ||
                                    r.RateType == "RNYESHEBANG" ||
                                    r.RateType == "RNYEPARTY" ||
                                    r.RateType == "HCHTWIDULGE"
                                ) {
                                    return (
                                        r.NumRoomsAvail > 0 &&
                                        r.AvailMonSun[
                                            moment(day.date).format("E") - 1
                                        ] != "N" &&
                                        Number(r.MinOccupancy) >= 1
                                    );
                                } else { 
                                    if(r.RateType == "HRDBB" || r.RateType == "RPURITYM" || r.RateType == "RSERENITYM") {
                                        return (
                                            r.NumRoomsAvail > 0 &&
                                            r.AvailMonSun[
                                                moment(day.date).format("E") - 1
                                            ] != "N" &&
                                            Number(r.MinOccupancy) == 2 &&
                                            Number(r.MinLength) >= 1
                                        );
                                    } else {
                                        return (
                                            r.NumRoomsAvail > 0 &&
                                            r.AvailMonSun[
                                                moment(day.date).format("E") - 1
                                            ] != "N" &&
                                            Number(r.MinOccupancy) >= 2 &&
                                            Number(r.MinLength) >= 1
                                        );
                                    }
                                }
                            }
                        });

                        openRates = _.filter(openRates, function(r) {
                            if(r.RateType !== "RPURITYSM") {
                                return (
                                    r.RoomType !== "CSNGL" ||
                                    r.RoomType !== "GROOM"
                                );
                            } else {
                                return (
                                    r.RoomType !== "GROOM"
                                );
                            }
                        });

                        if (openRates.length) {
                            // split strings to array
                            var rateCodes = self.rate.split(" ");
                            var matchedRate = _.filter(openRates, function(r) {
                                return _.includes(rateCodes, r.RateType);
                            });

                            if (matchedRate.length) {
                                var discount = false;

                                if (self.type == "Spa Day") {
                                    // Spa days

                                    _.each(matchedRate, function(r) {
                                        r.SingleRate = +r.SingleRate;
                                    });

                                    var price = _.minBy(
                                        matchedRate,
                                        "SingleRate"
                                    ).SingleRate;

                                    if (self.discount) {
                                        discount = self.discount;
                                        self.discountRate = self.discount;
                                    }

                                    var calDay = {
                                        date: moment(day.date).format(
                                            "YYYY-MM-DD"
                                        ),
                                        price: +price,
                                        discount: discount
                                    };

                                    if (self.exactprice) {
                                        if (calDay.price == self.exactprice) {
                                            self.calendarRates.push(calDay);
                                        }
                                    } else {
                                        self.calendarRates.push(calDay);
                                    }
                                } else {
                                    _.each(matchedRate, function(r) {
                                        r.DoubleRate = +r.DoubleRate;
                                    });

                                    var price = _.minBy(
                                        matchedRate,
                                        "DoubleRate"
                                    ).DoubleRate;

                                    price = price * self.nights;

                                    if (self.discount) {
                                        discount = self.discount;
                                        self.discountRate = self.discount;
                                    }

                                    var calDay = {
                                        date: moment(day.date).format(
                                            "YYYY-MM-DD"
                                        ),
                                        price: price / 2,
                                        discount: discount
                                    };
                                    if (self.exactprice) {
                                        if (calDay.price == self.exactprice) {
                                            self.calendarRates.push(calDay);
                                        }
                                    } else {
                                        self.calendarRates.push(calDay);
                                    }
                                }
                            }
                        }
                    });
                } else {
                    this.basicCalendar(start, end);
                }

                this.fetchingRates = false;
            });
        }
    }
};
</script>