<template>
    <div class="comp-form">
        <div v-if="success">
            <p class="signup-message" v-if="success">{{ success }}</p>
        </div>
        <div v-else>
            <form @submit.prevent="register" class="form-horizontal">
                <div class="form-group row">
                    <div class="col-md-6">
                        <input v-model="formData.firstName" ref="firstName" type="text" placeholder="First name" class="form-control" required />
                    </div>
                    <div class="col-md-6">
                        <input v-model="formData.lastName" ref="lastName" type="text" placeholder="Last name" class="form-control" required />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <input v-model="formData.emailAddress" ref="emailAddress" type="email" placeholder="Email" class="form-control" required />
                    </div>
                    <div v-if="phone" class="col-md-6">
                        <input v-model="formData.phone" ref="phone" type="tel" placeholder="Telephone number" class="form-control" required />
                    </div>
                </div>
                <div v-if="comments" class="form-group row">
                    <div class="col-md-12">
                        <textarea v-model="formData.comments" cols="4" rows="4" placeholder="Enter your comments" class="form-control" required ></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <button type="submit" :disabled="sending" class="btn btn-reversed">Submit</button>
                        <div v-if="sending" class="spinner-border" role="status"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="form-check">
                            <label class="form-check-label float-left" for="marketing">
                                <input v-model="formData.marketing" type="checkbox" class="form-check-input" id="marketing">
                                <span class="checkmark"></span>
                                {{ consent_message }}
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="error" class="form-group row">
                    <div class="col-md-12">
                        <p>{{ error }}</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
        
</template>

<script>
    var formTemplate = function() {
        return {
            firstName: null,
            lastName: null,
            emailAddress: null,
            phone: null,
            comments: null,
            marketing: false,
            competition_id: null,
            competition: null,
        }
    }
    export default {
        props: {
            comp_id: Number,
            competition: String,
            venue: String,
            phone: Number,
            comments: Number,
            revinate_id: String,
            consent_message: String,
        },
        data() {
            return {
                formData: new formTemplate(),
                sending: false,
                success: false,
                error: false,
            }
        },

        mounted() {
            
        },

        methods: {
            register() {
                var self = this
                self.sending = true

                // populate form data, even from autocomplete
                self.formData.firstName = this.$refs.firstName.value
                self.formData.lastName = this.$refs.lastName.value
                self.formData.emailAddress = this.$refs.emailAddress.value
                if(this.$refs.phone) {
                    self.formData.phone = this.$refs.phone.value
                }
                self.formData.revinate_id = this.revinate_id
                self.formData.competition_id = this.comp_id
                self.formData.competition = this.competition

                window.getRecaptchaToken()
                    .then(function(r) {
                        var data = {
                            formData: self.formData,
                            recaptcha: r
                        }
                        axios.post('/system/competitions/entry', data)
                            .then(response=> {
                                self.sending = false
                                if(response.data == 'success') {
                                    self.success = 'Thank you';
                                    
                                    self.formData = new formTemplate()
                                    
                                    dataLayer.push({
                                        event: 'form_submission',
                                        form_type: 'form_competition',
                                        form_subject: self.competition,
                                        venue: self.venue,
                                    });

                                    this.$emitAnalytics(
                                        'form_submission', 
                                        {
                                            event: 'form_submission',
                                            form_type: 'form_competition',
                                            form_subject: self.competition,
                                            venue: self.venue,
                                        },
                                        self.system.CustomerId,
                                        self.system.WebfolioId
                                    );

                                } else {
                                    self.formData = new formTemplate()
                                    self.error = 'Looks like something went wrong. Please try again.'
                                }
                                    


                                    
                            });
                    })
            },
        }
    }
</script>
