<template>
    <div class="booking-enhance">
        <div class="row">
            <div class="col-md-12">
                <progress-indicator :step="step"></progress-indicator>
                <div class="booking-info">
                    <h1 class="brandHeading">Finishing Touches</h1>
                    <h3 class="hide-mobile">{{ pageDate | nicedate }}</h3>
                    <div class="message-small">
                        <a @click.prevent="stepBack" class="mr-2"
                            >Back a step</a
                        >
                        <a @click.prevent="restartBooking(true)"
                            >Restart booking</a
                        >
                    </div>
                </div>
                <div class="booking-list">
                    <div
                        v-for="(folio, k) in filteredCart"
                        :key="k"
                        class="booking-list-item"
                    >
                        <div class="booking-list-item__details">
                            <div class="hide-mobile">
                                <h3>
                                    <span v-if="folio.Type == 'Stay'">Room</span
                                    ><span v-else>Guest</span> {{ k + 1 }}
                                </h3>
                                <p>Location: {{ folio.Location }}</p>
                                <p>Date: {{ folio.StartDate | rsnicedate }}</p>
                                <p>Experience: {{ folio.RateDetails }}</p>
                                <p v-if="folio.Type == 'Stay'">
                                    Guests: {{ folio.Adults }}
                                </p>
                                <p v-if="folio.Type == 'Stay'">
                                    <strong>Room type:</strong>
                                    {{ folio.Details }}
                                    <span v-if="folio.twinSelected"
                                        >(Twin room)</span
                                    >
                                    <span v-if="folio.doubleSelected"
                                        >(Double room)</span
                                    >
                                </p>
                                <p class="details-price">
                                    Cost: &pound;{{
                                        (+folio.Amount).toFixed(2)
                                    }}
                                </p>
                                <p v-if="folio.promotionApplied" class="small">
                                    ({{ folio.promotionApplied }} promotion
                                    applied)
                                </p>
                                <p
                                    v-if="folio.RateType == 'R3FOR2'"
                                    class="small"
                                >
                                    *Add code at checkout
                                </p>
                            </div>
                            <div class="show-mobile">
                                <div class="dropdown">
                                    <div
                                        class="dropdown-heading heading clearfix"
                                        data-toggle="collapse"
                                        data-target="#packageDetails"
                                        aria-expanded="false"
                                        aria-controls="packageDetails"
                                    >
                                        <h3>
                                            <span v-if="folio.Type == 'Stay'"
                                                >Room</span
                                            ><span v-else>Guest</span>
                                            {{ k + 1 }}
                                        </h3>
                                        <i class="arrow"></i>
                                    </div>
                                    <div
                                        class="dropdown-container collapse"
                                        id="packageDetails"
                                    >
                                        <h3>
                                            <span v-if="folio.Type == 'Stay'"
                                                >Room</span
                                            ><span v-else>Guest</span>
                                            {{ k + 1 }}
                                        </h3>
                                        <p>Location: {{ folio.Location }}</p>
                                        <p>
                                            Date:
                                            {{ folio.StartDate | rsnicedate }}
                                        </p>
                                        <p>
                                            Experience: {{ folio.RateDetails }}
                                        </p>
                                        <p v-if="folio.Type == 'Stay'">
                                            Guests: {{ folio.Adults }}
                                        </p>
                                        <p v-if="folio.Type == 'Stay'">
                                            <strong>Room type:</strong>
                                            {{ folio.Details }}
                                            <span v-if="folio.twinSelected"
                                                >(Twin room)</span
                                            >
                                            <span v-if="folio.doubleSelected"
                                                >(Double room)</span
                                            >
                                        </p>
                                        <p class="details-price">
                                            Cost: &pound;{{
                                                (+folio.Amount).toFixed(2)
                                            }}
                                        </p>
                                        <p
                                            v-if="folio.advancedBooking"
                                            class="small"
                                        >
                                            ({{ folio.advancedBooking }}%
                                            discount applied)
                                        </p>
                                        <p v-if="1" class="small">
                                            *Add code at checkout
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="booking-list-item__treatments">
                            <div
                                v-if="folio.InclusiveTreatments"
                                class="inclusive-list"
                            >
                                <div v-if="folio.HasOffers">
                                    <h3>&pound;20 off Selected Treatments</h3>
                                </div>
                                <div v-else>
                                    <h3>Inclusive Treatments</h3>
                                </div>
                                <ul class="enhance-item-list clearfix">
                                    <li
                                        v-for="(treatment, k) in folio
                                            .InclusiveTreatments.Inclusives"
                                        :key="k"
                                        class="clearfix"
                                    >
                                        <span v-if="treatment">
                                            {{ treatment.Details }}.<br />
                                            {{
                                                treatment.StartDate
                                                    | rsdatetimeshort
                                            }}.
                                        </span>
                                        <span v-else>
                                            <span
                                                class="ffs fwb text-uppercase mr-2"
                                                >Treatment {{ k + 1 }}</span
                                            >
                                            <span v-if="folio.HasUpsells"
                                                >Inclusive treatment</span
                                            ><span v-else
                                                >No treatment added</span
                                            >
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <h3>
                                <span v-if="folio.InclusiveTreatments"
                                    >Additional </span
                                >Treatments
                            </h3>
                            <ul
                                v-if="folio.Treatments.length"
                                class="enhance-item-list"
                            >
                                <li
                                    v-for="(treatment, k) in folio.Treatments"
                                    :key="k"
                                >
                                    {{ treatment.Details }}.<br />
                                    {{ treatment.StartDate | rsdatetimeshort }}.
                                    &pound;{{ treatment.Amount }}.
                                </li>
                            </ul>
                            <ul v-else class="enhance-item-list">
                                <li>No treatment added</li>
                            </ul>
                        </div>
                        <div class="booking-list-item__extras">
                            <div class="inclusive-list">
                                <h3>Finishing Touches</h3>
                                <h5 v-if="folio.Type == 'Stay'">
                                    Champagne, Prosecco, Afternoon Tea?
                                </h5>
                                <h5 v-else>
                                    Breakfast, Bubbles, Birthday occasion?
                                </h5>
                                <p>
                                    Why not make your stay truly memorable by
                                    adding some final flourishes...
                                </p>
                                <button
                                    @click.prevent="showExtras(folio)"
                                    class="btn btn-primary btn-block btn-blush"
                                >
                                    Add Finishing Touches
                                </button>
                                <ul v-if="folio.Cabanas" class="enhance-item-list">
                                    <li v-for="(cabana, ck) in folio.Cabanas">
                                        <span>{{ cabana.Details }}</span> <span class="small">&pound;{{ cabana.Amount }}</span><br>
                                        <span class="small">{{ cabana.StartDate | rsnicedate }}, {{ cabana.StartDate | rstime }} - {{ cabana.FinishDate | rstime }} <a @click.prevent="removeTreatment(cabana)" class="small link">Remove</a></span>
                                    </li>
                                </ul>
                                <ul
                                    v-if="folio.extras"
                                    class="enhance-item-list"
                                >
                                    <li
                                        v-for="(extra, ek) in folio.extras
                                            .specialServices
                                            .PMSFolioSpecialService"
                                        :key="ek"
                                    >
                                        <span v-if="extra.Qty != 0"
                                            >{{ extra.Qty }} x
                                        </span>
                                        {{
                                            extra.PMSSpecialService
                                                .PMSSpecialServiceName
                                        }}
                                        <span
                                            v-if="
                                                extra.TotalWithSurcharges !=
                                                '0.00'
                                            "
                                            >&pound;{{
                                                extra.TotalWithSurcharges
                                            }}</span
                                        >
                                        <a
                                            @click.prevent="
                                                removeExtras(
                                                    extra.PMSFolioSpecialServiceId,
                                                    folio.FolioId
                                                )
                                            "
                                        >
                                            <span
                                                v-if="
                                                    removingExtra ==
                                                    extra.PMSFolioSpecialServiceId
                                                "
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                >&nbsp;</span
                                            >
                                            <span v-else class="link small">Remove</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="folio.extras" class="details-price">
                                Cost: &pound;{{
                                    (+folio.extras.total).toFixed(2)
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="cart" class="next-steps">
                    <div class="next-steps__price">
                        &pound;{{ cart.FolioBalance }}
                    </div>
                    <div class="mt-3">
                        <button
                            @click.prevent="checkAdditionalFinishingTouches()"
                            class="btn btn-secondary ersed"
                        >
                            I'm Ready to pay
                        </button>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="gc-box">
                            <h5>Redeeming a Gift Card or E-voucher?</h5>
                            <p>
                                Did you know, all of our gift vouchers hold a
                                monetary value and can be redeemed against any
                                package. Simply select your chosen package, then
                                enter your voucher code at checkout to redeem.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-app-sideout extra-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a
                                @click.prevent="closeSideout()"
                                class="prevNextArrow arrowLeft"
                                >&nbsp;</a
                            >
                        </div>
                        <div v-if="filteredExtras" class="package-content">
                            <div v-if="cabanas && venue == 'hoar-cross-hall'" class="tab-cats">
                                <div v-if="selectedFolio && selectedFolio.RoomType != 'HTWI'">
                                    <h3>Cabanas</h3>
                                    <div v-for="(cabana, k) in cabanas" :key="k" class="tab-cat-items">
                                        <div class="accordion" id="acc-cabana">
                                            <div class="accordion-item">
                                                <div class="accordion-header" :id="'heading' +cabana.SpaItemId +k" 
                                                    data-toggle="collapse" 
                                                    :data-target="'#collapse' + cabana.SpaItemId +k"
                                                    aria-expanded="false"
                                                    :aria-controls="'collapse' +cabana.SpaItemId +k">
                                                    <div class="clearfix">
                                                        <span class="accordion-name">
                                                            {{ cabana.ItemName }}
                                                        </span>
                                                        <span class="float-right">
                                                            <i class="arrow"></i>
                                                            <span v-if="cabana.Price != '0.00'">&pound;{{ cabana.Price | price }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div :id="'collapse'+cabana.SpaItemId+k" class="collapse" :aria-labelledby="'heading'+cabana.SpaItemId +k" data-parent="#acc-cabana">
                                                    <div class="accordion-body">
                                                        <div v-if="cabana.ItemImage && cabana.ItemImage.trim() !==''" 
                                                                class="room-package-image" 
                                                                :style="'background-image:url(https://barons-eden-cdn.fra1.digitaloceanspaces.com/' +cabana.ItemImage.replace('/storage','') // Remove '/storage'
                                                                    .replace(
                                                                        /^\s*-+|-\s*$/g,
                                                                        ''
                                                                    ) // Remove leading/trailing hyphens and spaces
                                                                    .trim() + // Remove leading/trailing whitespace
                                                                ');'
                                                            "
                                                        ></div>
                                                        <div class="mt-3 mb-3" v-html="cabana.ItemDesc"></div>
                                                        <div v-if="selectedFolio">
                                                            <div class="choose-date mb-3">
                                                                <div v-if="selectedFolio.TreatmentDates.length > 1">
                                                                    <label for="date-select">Please select your preferred date</label>
                                                                    <div class="form-inline">
                                                                        <select v-model="treatmentDate" class="custom-select">   
                                                                            <option v-for="(date, index) in selectedFolio.TreatmentDates" :key="index" :value="date">
                                                                                {{ date | nicedate }}
                                                                            </option>
                                                                        </select> 
                                                                        <button @click.prevent="getTreatmentTimesV2(cabana)" :disabled="!treatmentDate" class="btn btn-primary mb-0">Search</button>
                                                                    </div>
                                                                        
                                                                </div>
                                                                <div v-else>
                                                                    <div>{{ treatmentDate | nicedate }}</div>
                                                                    <button @click.prevent="getTreatmentTimesV2(cabana)" class="btn">Check availability</button>
                                                                </div>
                                                                <div v-if="loadingCabanas" class="loading">Loading availability...</div>
                                                            </div>
                                                            <div v-if="cabana.TreatmentTimes" class="treatment-times">
                                                                <div>
                                                                    <strong>Please select your preferred time</strong>
                                                                </div>
                                                                <button
                                                                    @click.prevent="selectTime(time)"
                                                                    v-for="(time, ttk) in cabana.TreatmentTimes"
                                                                    :key="ttk"
                                                                    class="btn btn-tt mr-1"
                                                                    :class="{btnSelected: time == selectedTime}">
                                                                    {{ time.StartTime | rstime }}
                                                                </button>
                                                                <div class="clearfix">
                                                                    <button @click.prevent="addTreatmentToCart()" class="btn btn-secondary mr-1" :disabled="!selectedTime">
                                                                        Add to booking
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <span v-if="cabana.noAvailability">Sorry, there are no available Cabanas for this date</span>
                                                            </div>
                                                        </div>
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-for="(excats, cat) in filteredExtras"
                                :key="cat"
                                class="tab-cats"
                                v-if="dateIsMarch(cat)"
                            >
                                <img
                                    src="/img/MothersDay.webp"
                                    alt="Mothers Day"
                                    loading="lazy"
                                    v-if="cat === 'Seasonal'"
                                    style="width: 195px; padding-bottom: 5px"
                                />
                                <h3 v-if="cat !== 'Seasonal'">
                                    {{ cat.toLowerCase() }}
                                </h3>
                                <div
                                    v-for="(extra, k) in excats"
                                    :key="k"
                                    class="tab-cat-items"
                                >
                                    <div
                                        class="accordion"
                                        :id="
                                            'acc-' +
                                            cat.replace(/[^a-zA-Z0-9-_]+/g, '-')
                                        "
                                    >
                                        <div class="accordion-item">
                                            <div
                                                class="accordion-header"
                                                :id="
                                                    'heading' +
                                                    extra.PMSItemId +
                                                    k
                                                "
                                                data-toggle="collapse"
                                                :data-target="
                                                    '#collapse' +
                                                    extra.PMSItemId +
                                                    k
                                                "
                                                aria-expanded="false"
                                                :aria-controls="
                                                    'collapse' +
                                                    extra.PMSItemId +
                                                    k
                                                "
                                            >
                                                <div class="clearfix">
                                                    <span class="accordion-name"
                                                        ><span
                                                            v-if="
                                                                extra.PMSSpecialServiceCategory !=
                                                                'Notes'
                                                            "
                                                            >{{
                                                                extra.PMSSpecialServiceName
                                                            }}</span
                                                        ></span
                                                    >
                                                    <span class="float-right"
                                                        ><i class="arrow"></i>
                                                        <span
                                                            v-if="
                                                                extra.Price !=
                                                                '0.00'
                                                            "
                                                            >&pound;{{
                                                                extra.Price
                                                                    | price
                                                            }}</span
                                                        ></span
                                                    >
                                                </div>
                                            </div>

                                            <div
                                                :id="
                                                    'collapse' +
                                                    extra.PMSItemId +
                                                    k
                                                "
                                                :class="
                                                    extra.PMSSpecialServiceCategory ==
                                                    'Notes'
                                                        ? ''
                                                        : 'collapse'
                                                "
                                                :aria-labelledby="
                                                    'heading' +
                                                    extra.PMSItemId +
                                                    k
                                                "
                                                :data-parent="
                                                    '#acc-' +
                                                    cat.replace(
                                                        /[^a-zA-Z0-9-_]+/g,
                                                        '-'
                                                    )
                                                "
                                            >
                                                <div class="accordion-body">
                                                    <div
                                                        v-if="
                                                            extra.ImageURL &&
                                                            extra.ImageURL.trim() !==
                                                                ''
                                                        "
                                                        class="room-package-image"
                                                        :style="
                                                            'background-image:url(https://barons-eden-cdn.fra1.digitaloceanspaces.com' +
                                                            extra.ImageURL.replace(
                                                                '/storage',
                                                                ''
                                                            ) // Remove '/storage'
                                                                .replace(
                                                                    /^\s*-+|-\s*$/g,
                                                                    ''
                                                                ) // Remove leading/trailing hyphens and spaces
                                                                .trim() + // Remove leading/trailing whitespace
                                                            ');'
                                                        "
                                                    ></div>
                                                    <div
                                                        v-if="
                                                            extra.PMSSpecialServiceCategory !=
                                                            'Notes'
                                                        "
                                                        class="mb-3 d-inline-block text-center"
                                                    >
                                                        <div
                                                            class="ffs text-uppercase"
                                                        >
                                                            Quantity
                                                        </div>
                                                        <span
                                                            class="plus-minus"
                                                        >
                                                            <button
                                                                @click="
                                                                    subtract(
                                                                        extra
                                                                    )
                                                                "
                                                                class="btn btn-plus-minus"
                                                            >
                                                                -
                                                            </button>
                                                            <span
                                                                class="book-int"
                                                                >{{
                                                                    extra.qty
                                                                }}</span
                                                            >
                                                            <button
                                                                @click="
                                                                    add(extra)
                                                                "
                                                                class="btn btn-plus-minus"
                                                            >
                                                                +
                                                            </button>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="mb-3"
                                                        v-html="
                                                            extra.PMSSpecialServiceDescription
                                                        "
                                                    ></div>
                                                    <div class="extra-note">
                                                        <div class="form-group">
                                                            <textarea
                                                                class="form-control"
                                                                v-model="
                                                                    extra.note
                                                                "
                                                                id="ssNote"
                                                                rows="3"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            :disabled="
                                                                addingExtra
                                                            "
                                                            class="btn btn-secondary btn-block"
                                                            @click.prevent="
                                                                addExtra(extra)
                                                            "
                                                        >
                                                            Add to booking
                                                        </button>
                                                        <span
                                                            v-if="addingExtra"
                                                            class="spinner-border spinner-border-sm"
                                                            role="status"
                                                        >
                                                            <span
                                                                class="sr-only"
                                                                >Loading...</span
                                                            >
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Additional finishing touches modal -->
        <div
            id="additional-finishing-touches-modal"
            class="modal fade"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">&nbsp;</div>
                    <div class="modal-body">
                        <h5 class="brandHeading text-center mb-4">
                            WHY NOT MAKE YOUR EXPERIENCE EVEN MORE MEMORABLE?
                        </h5>
                        <div class="mb-3 text-center">
                            Would you like to take your spa experience to a new
                            level, booking a finishing touch or two? Surprise a
                            loved one, get the party started early or indulge
                            even further...
                        </div>
                        <div class="d-flex">
                            <button
                                data-dismiss="modal"
                                class="btn btn-primary flex-fill mr-2"
                            >
                                Yes please
                            </button>
                            <button
                                @click.prevent="nextStep()"
                                class="btn btn-primary flex-fill ml-2"
                                data-dismiss="modal"
                            >
                                No thanks
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressIndicator from "../book/ProgressIndicator.vue";
export default {
    props: {
        cart: Object,
        system: Object,
        venue: String,
        type: String,
    },
    components: {
        ProgressIndicator,
    },
    /**
     * The components data
     */
    data() {
        return {
            step: 3,
            arrive: this.$route.params.arrive,
            guests: this.$route.params.guests,
            venueName: this.$options.filters.venue(this.venue, this.type),
            selectedFolio: null,
            loading: false,
            treatments: null,
            hchDayTreatments: null,
            hchStayTreatments: null,
            edenTreatments: null,
            inclusives: [],
            addingTreatment: false,
            extras: null,
            loadingExtras: false,
            addingExtra: false,
            extraQty: 1,
            TempSpaNewCustomerForm: {
                NewCustomer: {
                    Name: {
                        FirstName: this.system.WebFolioId,
                        LastName: "TempWebFolio",
                    },
                    EmailAddress: null,
                },
                ContactLogs: {
                    ContactLog: null,
                },
            },
            changingTreatment: false,
            treatmentDate: this.$route.params.arrive,
            treatmentUnavailable: false,
            selectedTreatment: null,
            selectedTime: null,
            selectedInclusive: null,
            selectedInclusiveTime: null,
            selectedTreatmentDate: null,
            removing: null,
            removingExtra: null,
            removingInsurance: false,
            cabanas: null,
            selectedCabana: null,
            loadingCabanas: false,
            addingCabana: false,
            removingCabana: false,
        };
    },

    mounted() {
        if(this.venue == 'hoar-cross-hall') {
            this.cabanas = _.filter(this.system.treatments, function (f) {
                return f.ItemSubcategory == "Cabana";
            });
        }
        if(this.type == 'stay') {
            this.treatmentDate = moment(this.$route.params.arrive).add(1, 'days').format('YYYY-MM-DD')
        }
    },

    watch: {
        cart: function (newVal, oldVal) {
            if (!newVal.WebFolioItem) {
                var firstDate = JSON.parse(
                    window.localStorage.getItem("rsbooking")
                );

                if (firstDate && firstDate[this.system.WebFolioId]) {
                    var start = firstDate[this.system.WebFolioId];
                    if (start.booking.type == "stay") {
                        this.$router.push(
                            "/book/booking/" +
                                start.booking.venue +
                                "/" +
                                start.booking.type +
                                "/" +
                                start.booking.arrive +
                                "/" +
                                start.booking.nights +
                                "/" +
                                start.booking.guests
                        );
                    } else {
                        this.$router.push(
                            "/book/booking/" +
                                start.booking.venue +
                                "/" +
                                start.booking.type +
                                "/" +
                                start.booking.arrive +
                                "/" +
                                start.booking.guests
                        );
                    }
                } else {
                    this.$router.push("/book");
                }
            }
        },
    },

    beforeMount() {
        this.getCachedTreatments();
    },
    computed: {
        treatmentsLoading() {
            if (
                this.edenTreatments &&
                this.hchDayTreatments &&
                this.hchStayTreatments
            ) {
                return false;
            } else {
                return true;
            }
        },
        noBook() {
            if (this.addingTreatment || !this.selectedTime) {
                return true;
            }
            return false;
        },
        filteredCart() {
            if (this.cart.WebFolioItem) {
                return _.filter(this.cart.WebFolioItem, { Category: "Hotel" });
            } else {
                return null;
            }
        },
        filteredExtras() {
            var self = this;
            var extras = _.filter(self.extras, function (ex) {
                return ex.PMSSpecialServiceCategory != "Room Options";
            });

            extras = _.filter(self.extras, function (ex) {
                return ex.PMSSpecialServiceCategory != "*Room Options";
            });

            extras = _.orderBy(extras, "Price");

            return _.groupBy(extras, "PMSSpecialServiceCategory");
        },
        pageDate() {
            if (this.cart.WebFolioItem) {
                return this.cart.WebFolioItem[0].StartDate.slice(0, -6);
            } else {
                this.arrive;
            }
        },
        insuranceTotal() {
            var self = this;

            var filtered = _.filter(self.cart.WebFolioItem, function (f) {
                return f.Category != "Classes";
            });
            if (filtered) {
                var total = _.sumBy(filtered, function (folio) {
                    return (
                        folio.Insurance.Price *
                        folio.Insurance.PMSSpecialServiceQty
                    );
                });
                return total;
            } else {
                return null;
            }
        },
        insuranceAdded() {
            var self = this;

            var folioLength = 0;
            var insuranceCount = 0;
            if (self.cart.WebFolioItem) {
                _.each(self.cart.WebFolioItem, function (folio) {
                    if (folio.Category == "Hotel" && folio.Insurance.Price) {
                        folioLength++;
                        if (folio.extras) {
                            _.each(
                                folio.extras.specialServices
                                    .PMSFolioSpecialService,
                                function (extra) {
                                    if (
                                        extra.PMSSpecialService.PMSSpecialServiceName.includes(
                                            "Insurance"
                                        )
                                    ) {
                                        insuranceCount++;
                                    }
                                }
                            );
                        }
                    }
                });
                if (folioLength > 0) {
                    if (folioLength == insuranceCount) {
                        Bus.$emit("hideLoading");
                        return true;
                    } else {
                        if (self.removingInsurance) {
                            Bus.$emit("hideLoading");
                            self.removingInsurance = false;
                        }
                        return false;
                    }
                }
            } else {
                return false;
            }
        },
        hasFinishingTouches() {
            var self = this;
            var hasFt = false;
            if (self.cart.WebFolioItem) {
                _.each(self.cart.WebFolioItem, function (folio) {
                    if (folio.Category == "Hotel") {
                        if (folio.extras) {
                            _.each(
                                folio.extras.specialServices
                                    .PMSFolioSpecialService,
                                function (ss) {
                                    if (ss.TotalWithSurcharges != "0.00") {
                                        hasFt = true;
                                    }
                                }
                            );
                        }
                    }
                });
            }
            return hasFt;
        },
    },

    /**
     * Methods
     */
    methods: {
        dateIsMarch(cat) {
            if (cat !== "Seasonal") {
                return true;
            }

            if (moment(this.arrive).month() == 2) {
                return true;
            } else {
                return false;
            }
        },
        checkAdditionalFinishingTouches() {
            this.nextStep();
            /*
                if(this.hasFinishingTouches) {
                    this.nextStep()
                } else {
                    $('#additional-finishing-touches-modal').modal('show')
                    $('html').addClass('no-scroll');
                }
                */
        },
        nextStep() {
            this.$router.push("/book/cart");
        },
        stepBack() {
            if (this.type == "spa-day") {
                var url =
                    "/book/enhance/" +
                    this.$route.params.venue +
                    "/" +
                    this.type +
                    "/" +
                    this.$route.params.arrive +
                    "/1";
            } else {
                var url =
                    "/book/enhance/" +
                    this.$route.params.venue +
                    "/" +
                    this.type +
                    "/" +
                    this.$route.params.arrive +
                    "/" +
                    this.$route.params.nights +
                    "/" +
                    this.$route.params.guests;
            }
            this.$router.push({ path: url });
        },
        add(extra) {
            extra.qty++;
        },
        subtract(extra) {
            if (extra.qty > 1) {
                extra.qty--;
            }
        },

        closeSideout() {
            $(".booking-app-sideout").removeClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        restartBooking() {
            Bus.$emit("RestartBooking", true);
        },

        removeItem(item) {
            Bus.$emit("RemoveItem", item);
        },

        showTreatments(folio) {
            this.treatments = null;
            this.selectedFolio = folio;
            this.treatmentDate = folio.StartDate.slice(0, -6);
            if (folio.Location == "Hoar Cross Hall") {
                this.treatments = _.clone(this.hchStayTreatments);
            } else if (folio.Location == "Hoar Cross Hall - Day Spa") {
                this.treatments = _.clone(this.hchDayTreatments);
            } else if (folio.Location == "Eden Hall - Day Spa") {
                this.treatments = _.clone(this.edenTreatments);
            }
            // Temp remove seasonal treatment if not July/Aug
            if (
                moment(this.treatmentDate).month() != 6 &&
                moment(this.treatmentDate).month() != 7
            ) {
                _.remove(this.treatments, function (t) {
                    return t.cat == "SEASONAL TREATMENT OFFER";
                });
            }
            $(".treatment-selector").toggleClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        addInclusive(folio, inclusive) {
            this.selectedInclusive = null;
            this.selectedFolio = null;
            this.treatmentDate = null;

            var self = this;
            var inclusiveList = _.filter(self.inclusives, function (t) {
                return _.includes(inclusive.SpaItemId, t.SpaItemId);
            });

            if (inclusiveList.length == 1) {
                inclusive.SpaItem = inclusiveList[0];
            } else {
                inclusive.OptionList = inclusiveList;
            }

            // Do we have upsells?
            if (inclusive.Upsells) {
                inclusive.UpsellList = _.filter(self.inclusives, function (t) {
                    return _.includes(inclusive.Upsells, t.SpaItemId);
                });
            }

            this.selectedInclusive = inclusive;
            this.selectedFolio = folio;
            this.treatmentDate = inclusive.Date;
            if (!inclusive.Upsells) {
                //this.getTreatmentTimes(this.selectedInclusive.SpaItem)
            }
            $(".inclusive-selector").toggleClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        showExtras(folio) {
            this.selectedFolio = folio;

            this.getExtras();

            $(".extra-selector").toggleClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        getExtras() {
            var self = this;
            this.extras = null;
            var request = {
                method: "FetchPMSSpecialServices",
                data: {
                    PMSFolioId: this.selectedFolio.FolioId,
                    ArrivalDate: this.arrive,
                    DepartureDate: moment(this.arrive)
                        .add(1, "days")
                        .format("YYYY-MM-DD"),
                    Venue: this.selectedFolio.Location,
                    PromoCode: null,
                    WebFolioId: this.system.WebFolioId,
                    Language: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (
                    self.selectedFolio.Location.includes("Hoar Cross Hall") &&
                    moment(self.selectedFolio.StartDate.slice(0, -6)).format(
                        "MM"
                    ) == "06"
                ) {
                    _.remove(response.data.PMSSpecialService, function (ss) {
                        if (
                            _.includes([3001236, 10091, 30031240], ss.PMSItemId)
                        ) {
                            return true;
                        }
                    });
                } else {
                    _.remove(response.data.PMSSpecialService, function (ss) {
                        if (ss.PMSItemId == "999001") {
                            // temp hide Jubilee Afternoon tea
                            return true;
                        }
                    });
                }

                // Be Mine - Feb only
                if (
                    moment(self.selectedFolio.StartDate.slice(0, -6)).format(
                        "MM"
                    ) != "02"
                ) {
                    _.remove(response.data.PMSSpecialService, function (ss) {
                        if (
                            _.includes([999121, 999122, 999123], ss.PMSItemId)
                        ) {
                            return true;
                        }
                    });
                }

                // Prosecco Celebratory - Not Feb
                if (
                    moment(self.selectedFolio.StartDate.slice(0, -6)).format(
                        "MM"
                    ) == "02"
                ) {
                    _.remove(response.data.PMSSpecialService, function (ss) {
                        if (
                            _.includes(
                                [9990126, 9990139, 9990140],
                                ss.PMSItemId
                            )
                        ) {
                            return true;
                        }
                    });
                }

                // Remove insurance
                _.remove(response.data.PMSSpecialService, function (ss) {
                    if (
                        ss.PMSSpecialServiceName.includes("Flex") ||
                        ss.PMSSpecialServiceCategory.includes("ADVANCEDBOOKING")
                    ) {
                        return true;
                    }
                });

                // Remove notes
                _.remove(response.data.PMSSpecialService, function (ss) {
                    if (ss.PMSSpecialServiceName.includes("Notes")) {
                        return true;
                    }
                });
                // Remove promotion ss category
                _.remove(response.data.PMSSpecialService, function (ss) {
                    if (ss.PMSSpecialServiceCategory == "Promotion") {
                        return true;
                    }
                });

                if (
                    _.includes(
                        [6, 7],
                        moment(
                            self.selectedFolio.StartDate.slice(0, -6)
                        ).isoWeekday()
                    )
                ) {
                    _.remove(response.data.PMSSpecialService, function (ss) {
                        return ss.PMSItemId == 2022;
                    });
                }

                // Remove breakfast for Twilights
                if (self.selectedFolio.RateDetails.includes("Twilight")) {
                    _.remove(response.data.PMSSpecialService, function (ss) {
                        if (ss.PMSSpecialServiceName.includes("Breakfast")) {
                            return true;
                        }
                        if (
                            self.selectedFolio.Location ==
                                "Eden Hall - Day Spa" &&
                            ss.PMSSpecialServiceName.includes("Afternoon")
                        ) {
                            return true;
                        }
                    });
                }

                var ssWithQty = [3001236, 10091];
                _.each(response.data.PMSSpecialService, function (ex) {
                    Vue.set(ex, "note", null);
                    Vue.set(ex, "qty", 1);
                    Vue.set(ex, "hasQty", false);
                    if (self.selectedFolio.Location == "Hoar Cross Hall") {
                        if (_.includes(ssWithQty, ex.PMSItemId)) {
                            ex.qty = +self.guests;
                            ex.hasQty = true;
                        }
                        if (
                            ex.PMSSpecialServiceCategory ==
                            "Flexible Booking Insurance"
                        ) {
                            // insurance
                            ex.qty = self.selectedFolio.Nights;
                        }
                        if (ex.PMSItemId == 2022) {
                            ex.qty = +self.guests;
                        }
                    }
                });
                this.extras = response.data.PMSSpecialService;
            });
        },

        addInsurance() {
            Bus.$emit("showLoading");
            var self = this;
            var qty;
            _.each(self.cart.WebFolioItem, function (folio) {
                if (folio.Category == "Hotel" && folio.Insurance.Price) {
                    self.addInsuranceCall(folio);
                }
            });
        },

        removeInsurance() {
            Bus.$emit("showLoading");
            this.removingInsurance = true;
            var self = this;
            _.each(self.cart.WebFolioItem, function (folio) {
                if (folio.Category == "Hotel") {
                    if (folio.extras) {
                        _.each(
                            folio.extras.specialServices.PMSFolioSpecialService,
                            function (extra) {
                                if (
                                    extra.PMSSpecialService.PMSSpecialServiceName.includes(
                                        "Insurance"
                                    )
                                ) {
                                    self.removeExtras(
                                        extra.PMSFolioSpecialServiceId,
                                        folio.FolioId
                                    );
                                }
                            }
                        );
                    }
                }
            });
        },

        addInsuranceCall(folio) {
            var request = {
                method: "AddPMSSpecialService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    PMSFolioId: folio.FolioId,
                    PMSSpecialServiceName:
                        folio.Insurance.PMSSpecialServiceName,
                    PMSSpecialServiceQty: folio.Insurance.PMSSpecialServiceQty,
                    PMSSpecialServiceDate: folio.StartDate.slice(0, -6),
                    PMSSpecialServiceNote: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    dataLayer.push({
                        event: "add_to_cart",
                        venue: folio.Venue,
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                // 'add' actionFieldObject measures.
                                products: [
                                    {
                                        //  adding a product to a shopping cart.
                                        name: folio.Insurance
                                            .PMSSpecialServiceName,
                                        id: folio.Insurance.PMSItemId,
                                        price: +folio.Insurance.Price,
                                        brand: folio.Venue,
                                        category: "Extras",
                                        quantity:
                                            folio.Insurance
                                                .PMSSpecialServiceQty,
                                    },
                                ],
                            },
                        },
                    });

                    this.$emitAnalytics(
                        "add_to_cart",
                        {
                            event: "add_to_cart",
                            venue: folio.Venue,
                            ecommerce: {
                                currency: "GBP",
                                add: {
                                    // 'add' actionFieldObject measures.
                                    products: [
                                        {
                                            //  adding a product to a shopping cart.
                                            name: folio.Insurance
                                                .PMSSpecialServiceName,
                                            id: folio.Insurance.PMSItemId,
                                            price: +folio.Insurance.Price,
                                            brand: folio.Venue,
                                            category: "Extras",
                                            quantity:
                                                folio.Insurance
                                                    .PMSSpecialServiceQty,
                                        },
                                    ],
                                },
                            },
                        },
                        this.system.CustomerId,
                        this.system.WebFolioId
                    );

                    Bus.$emit("UpdateCart");
                } else {
                    console.log(response.data);
                }
            });
        },

        addExtra(extra) {
            Bus.$emit("showLoading");
            this.addingExtra = true;
            var request = {
                method: "AddPMSSpecialService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    PMSFolioId: this.selectedFolio.FolioId,
                    PMSSpecialServiceName: extra.PMSSpecialServiceName,
                    PMSSpecialServiceQty: extra.qty,
                    PMSSpecialServiceDate: this.selectedFolio.StartDate.slice(
                        0,
                        -6
                    ),
                    PMSSpecialServiceNote: extra.note,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    dataLayer.push({
                        event: "addToCart",
                        ecommerce: {
                            currencyCode: "GBP",
                            add: {
                                // 'add' actionFieldObject measures.
                                products: [
                                    {
                                        //  adding a product to a shopping cart.
                                        name: extra.PMSSpecialServiceName,
                                        id: extra.PMSItemId,
                                        price: extra.Price,
                                        brand: extra.Venue,
                                        category: "Extras",
                                        quantity: this.qty,
                                    },
                                ],
                            },
                        },
                    });

                    this.$emitAnalytics(
                        "addToCart",
                        {
                            event: "addToCart",
                            ecommerce: {
                                currencyCode: "GBP",
                                add: {
                                    // 'add' actionFieldObject measures.
                                    products: [
                                        {
                                            //  adding a product to a shopping cart.
                                            name: extra.PMSSpecialServiceName,
                                            id: extra.PMSItemId,
                                            price: extra.Price,
                                            brand: extra.Venue,
                                            category: "Extras",
                                            quantity: this.qty,
                                        },
                                    ],
                                },
                            },
                        },
                        this.system.CustomerId,
                        this.system.WebFolioId
                    );

                    Bus.$emit("hideLoading");
                    this.closeSideout();
                    this.addingExtra = null;
                    this.qty = 1;
                    this.ssNote = null;
                    this.extraDate = null;

                    Bus.$emit("UpdateCart");
                } else {
                    console.log(response.data);
                }
            });
        },

        removeExtras(id, folio) {
            this.removingExtra = id;
            var request = {
                method: "RemovePMSSpecialService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    PMSFolioId: folio,
                    PMSFolioSpecialServiceId: id,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    this.removingExtra = null;
                    Bus.$emit("UpdateCart");
                }
            });
        },

        getTreatments(location) {
            var self = this;
            var request = {
                method: "FetchSpaServices",
                data: {
                    Location: location,
                    WebFolioId: this.system.WebFolioId,
                    Language: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                _.each(response.data.SpaService, function (t) {
                    t.TreatmentTimes = null;
                    t.noAvailability = false;
                });
                /*
                        if(moment(self.arrive) < moment('2022-04-01')) { // remove 2hr eden treatment before apr 1st
                            _.remove(response.data.SpaService, function(t) {
                                return t.SpaItemId == 300811037
                            })
                        }
                        */

                // Day/Stay treatments (HCH only)
                if (location == 100) {
                    var stay = _.filter(response.data.SpaService, {
                        ClientInstructions: "Stay",
                    });
                    stay = _.groupBy(stay, "ItemCategory");
                    var stayArray = [];
                    if (stay["SEASONAL TREATMENT OFFER"]) {
                        stayArray.push({
                            cat: "SEASONAL TREATMENT OFFER",
                            list: stay["SEASONAL TREATMENT OFFER"],
                        });
                    }
                    if (stay["BODY"]) {
                        stayArray.push({ cat: "BODY", list: stay["BODY"] });
                    }
                    if (stay["FACE"]) {
                        stayArray.push({ cat: "FACE", list: stay["FACE"] });
                    }
                    if (stay["HANDS & FEET"]) {
                        stayArray.push({
                            cat: "HANDS & FEET",
                            list: stay["HANDS & FEET"],
                        });
                    }
                    if (stay["HOLISTIC"]) {
                        stayArray.push({
                            cat: "HOLISTIC",
                            list: stay["HOLISTIC"],
                        });
                    }
                    if (stay["MEN"]) {
                        stayArray.push({ cat: "MEN", list: stay["MEN"] });
                    }
                    if (stay["BEAUTY"]) {
                        stayArray.push({ cat: "BEAUTY", list: stay["BEAUTY"] });
                    }
                    if (stay["THE PARLOUR"]) {
                        stayArray.push({
                            cat: "THE PARLOUR",
                            list: stay["THE PARLOUR"],
                        });
                    }
                    if (stay["NEW INCLUSIVE"]) {
                        this.inclusives = this.inclusives.concat(
                            stay["NEW INCLUSIVE"]
                        );
                    }
                    if (stay["UPGRADES"]) {
                        this.inclusives = this.inclusives.concat(
                            stay["UPGRADES"]
                        );
                    }
                    this.hchStayTreatments = stayArray;

                    var day = _.filter(response.data.SpaService, {
                        ClientInstructions: "Day",
                    });
                    day = _.groupBy(day, "ItemCategory");
                    var dayArray = [];
                    if (day["SEASONAL TREATMENT OFFER"]) {
                        dayArray.push({
                            cat: "SEASONAL TREATMENT OFFER",
                            list: day["SEASONAL TREATMENT OFFER"],
                        });
                    }
                    if (day["BODY"]) {
                        dayArray.push({ cat: "BODY", list: day["BODY"] });
                    }
                    if (day["FACE"]) {
                        dayArray.push({ cat: "FACE", list: day["FACE"] });
                    }
                    if (day["HANDS & FEET"]) {
                        dayArray.push({
                            cat: "HANDS & FEET",
                            list: day["HANDS & FEET"],
                        });
                    }
                    if (day["HOLISTIC"]) {
                        dayArray.push({
                            cat: "HOLISTIC",
                            list: day["HOLISTIC"],
                        });
                    }
                    if (day["MEN"]) {
                        dayArray.push({ cat: "MEN", list: day["MEN"] });
                    }
                    if (day["BEAUTY"]) {
                        dayArray.push({ cat: "BEAUTY", list: day["BEAUTY"] });
                    }
                    if (day["THE PARLOUR"]) {
                        dayArray.push({
                            cat: "THE PARLOUR",
                            list: day["THE PARLOUR"],
                        });
                    }
                    if (day["NEW INCLUSIVE"]) {
                        this.inclusives = this.inclusives.concat(
                            day["NEW INCLUSIVE"]
                        );
                    }
                    if (day["UPGRADES"]) {
                        this.inclusives = this.inclusives.concat(
                            day["UPGRADES"]
                        );
                    }
                    this.hchDayTreatments = dayArray;
                } else {
                    var day = _.clone(response.data.SpaService);
                    day = _.groupBy(day, "ItemCategory");
                    var dayArray = [];
                    if (day["SEASONAL TREATMENT OFFER"]) {
                        dayArray.push({
                            cat: "SEASONAL TREATMENT OFFER",
                            list: day["SEASONAL TREATMENT OFFER"],
                        });
                    }
                    if (day["BODY"]) {
                        dayArray.push({ cat: "BODY", list: day["BODY"] });
                    }
                    if (day["FACE"]) {
                        dayArray.push({ cat: "FACE", list: day["FACE"] });
                    }
                    if (day["HANDS & FEET"]) {
                        dayArray.push({
                            cat: "HANDS & FEET",
                            list: day["HANDS & FEET"],
                        });
                    }
                    if (day["HOLISTIC"]) {
                        dayArray.push({
                            cat: "HOLISTIC",
                            list: day["HOLISTIC"],
                        });
                    }
                    if (day["MEN"]) {
                        dayArray.push({ cat: "MEN", list: day["MEN"] });
                    }
                    if (day["BEAUTY"]) {
                        dayArray.push({ cat: "BEAUTY", list: day["BEAUTY"] });
                    }
                    if (day["THE PARLOUR"]) {
                        dayArray.push({
                            cat: "THE PARLOUR",
                            list: day["THE PARLOUR"],
                        });
                    }
                    if (day["NEW INCLUSIVE"]) {
                        this.inclusives = this.inclusives.concat(
                            day["NEW INCLUSIVE"]
                        );
                    }
                    if (day["TWILIGHT INCLUSIVE"]) {
                        this.inclusives = this.inclusives.concat(
                            day["TWILIGHT INCLUSIVE"]
                        );
                    }
                    if (day["UPGRADES"]) {
                        this.inclusives = this.inclusives.concat(
                            day["UPGRADES"]
                        );
                    }
                    this.edenTreatments = dayArray;
                }

                /*
                        var sortedTreatments = _.groupBy(response.data.SpaService, 'ItemCategory')
                        var sortArray = []

                        if(sortedTreatments['BODY']) { sortArray.push({ 'cat': 'BODY', 'list': sortedTreatments['BODY'] }) }
                        if(sortedTreatments['FACE']) { sortArray.push({ 'cat': 'FACE', 'list': sortedTreatments['FACE'] }) }
                        if(sortedTreatments['HANDS & FEET']) { sortArray.push({ 'cat': 'HANDS & FEET', 'list': sortedTreatments['HANDS & FEET'] }) }
                        if(sortedTreatments['HOLISTIC']) { sortArray.push({ 'cat': 'HOLISTIC', 'list': sortedTreatments['HOLISTIC'] }) }
                        if(sortedTreatments['MEN']) { sortArray.push({ 'cat': 'MEN', 'list': sortedTreatments['MEN'] }) }
                        if(sortedTreatments['BEAUTY']) { sortArray.push({ 'cat': 'BEAUTY', 'list': sortedTreatments['BEAUTY'] }) }
                        if(sortedTreatments['THE PARLOUR']) { sortArray.push({ 'cat': 'THE PARLOUR', 'list': sortedTreatments['THE PARLOUR'] }) }

                        if(sortedTreatments['NEW INCLUSIVE']) { this.inclusives = sortedTreatments['NEW INCLUSIVE'] }

                        this.treatments = sortArray
                        */
            });
        },

        getCachedTreatments() {
            if (this.system.treatments.length) {
                var tmts = _.clone(this.system.treatments);

                _.each(tmts, function (t) {
                    t.TreatmentTimes = null;
                    t.noAvailability = false;
                });

                // Day/Stay treatments HCH

                var stay = _.filter(tmts, {
                    ClientInstructions: "Stay",
                    Location: 100,
                });
                stay = _.groupBy(stay, "ItemCategory");
                var stayArray = [];
                if (stay["SEASONAL TREATMENT OFFER"]) {
                    stayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: stay["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (stay["BODY"]) {
                    stayArray.push({ cat: "BODY", list: stay["BODY"] });
                }
                if (stay["FACE"]) {
                    stayArray.push({ cat: "FACE", list: stay["FACE"] });
                }
                if (stay["HANDS & FEET"]) {
                    stayArray.push({
                        cat: "HANDS & FEET",
                        list: stay["HANDS & FEET"],
                    });
                }
                if (stay["HOLISTIC"]) {
                    stayArray.push({ cat: "HOLISTIC", list: stay["HOLISTIC"] });
                }
                if (stay["MEN"]) {
                    stayArray.push({ cat: "MEN", list: stay["MEN"] });
                }
                if (stay["BEAUTY"]) {
                    stayArray.push({ cat: "BEAUTY", list: stay["BEAUTY"] });
                }
                if (stay["THE PARLOUR"]) {
                    stayArray.push({
                        cat: "THE PARLOUR",
                        list: stay["THE PARLOUR"],
                    });
                }
                if (stay["NEW INCLUSIVE"]) {
                    this.inclusives = this.inclusives.concat(
                        stay["NEW INCLUSIVE"]
                    );
                }
                if (stay["UPGRADES"]) {
                    this.inclusives = this.inclusives.concat(stay["UPGRADES"]);
                }
                this.hchStayTreatments = stayArray;

                var day = _.filter(tmts, {
                    ClientInstructions: "Day",
                    Location: 100,
                });
                day = _.groupBy(day, "ItemCategory");
                var dayArray = [];
                if (day["SEASONAL TREATMENT OFFER"]) {
                    dayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: day["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (day["BODY"]) {
                    dayArray.push({ cat: "BODY", list: day["BODY"] });
                }
                if (day["FACE"]) {
                    dayArray.push({ cat: "FACE", list: day["FACE"] });
                }
                if (day["HANDS & FEET"]) {
                    dayArray.push({
                        cat: "HANDS & FEET",
                        list: day["HANDS & FEET"],
                    });
                }
                if (day["HOLISTIC"]) {
                    dayArray.push({ cat: "HOLISTIC", list: day["HOLISTIC"] });
                }
                if (day["MEN"]) {
                    dayArray.push({ cat: "MEN", list: day["MEN"] });
                }
                if (day["BEAUTY"]) {
                    dayArray.push({ cat: "BEAUTY", list: day["BEAUTY"] });
                }
                if (day["THE PARLOUR"]) {
                    dayArray.push({
                        cat: "THE PARLOUR",
                        list: day["THE PARLOUR"],
                    });
                }
                if (day["NEW INCLUSIVE"]) {
                    this.inclusives = this.inclusives.concat(
                        day["NEW INCLUSIVE"]
                    );
                }
                if (day["TWILIGHT INCLUSIVE"]) {
                    this.inclusives = this.inclusives.concat(
                        day["TWILIGHT INCLUSIVE"]
                    );
                }
                if (day["UPGRADES"]) {
                    this.inclusives = this.inclusives.concat(day["UPGRADES"]);
                }
                this.hchDayTreatments = dayArray;

                // Eden
                var day = _.filter(tmts, {
                    ClientInstructions: "Day",
                    Location: 300,
                });
                day = _.groupBy(day, "ItemCategory");
                var dayArray = [];
                if (day["SEASONAL TREATMENT OFFER"]) {
                    dayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: day["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (day["BODY"]) {
                    dayArray.push({ cat: "BODY", list: day["BODY"] });
                }
                if (day["FACE"]) {
                    dayArray.push({ cat: "FACE", list: day["FACE"] });
                }
                if (day["HANDS & FEET"]) {
                    dayArray.push({
                        cat: "HANDS & FEET",
                        list: day["HANDS & FEET"],
                    });
                }
                if (day["HOLISTIC"]) {
                    dayArray.push({ cat: "HOLISTIC", list: day["HOLISTIC"] });
                }
                if (day["MEN"]) {
                    dayArray.push({ cat: "MEN", list: day["MEN"] });
                }
                if (day["BEAUTY"]) {
                    dayArray.push({ cat: "BEAUTY", list: day["BEAUTY"] });
                }
                if (day["THE PARLOUR"]) {
                    dayArray.push({
                        cat: "THE PARLOUR",
                        list: day["THE PARLOUR"],
                    });
                }
                if (day["NEW INCLUSIVE"]) {
                    this.inclusives = this.inclusives.concat(
                        day["NEW INCLUSIVE"]
                    );
                }
                if (day["TWILIGHT INCLUSIVE"]) {
                    this.inclusives = this.inclusives.concat(
                        day["TWILIGHT INCLUSIVE"]
                    );
                }
                if (day["UPGRADES"]) {
                    this.inclusives = this.inclusives.concat(day["UPGRADES"]);
                }
                this.edenTreatments = dayArray;
            } else {
                // Not sure yet
            }
        },

        selectTime(item) {
            this.selectedTime = item;
        },

        getTreatmentTimes(treatment) {
            var self = this;
            treatment.TreatmentTimes = null;
            this.selectedTreatment = treatment;
            this.loading = true;

            // For stays, we need to check if middle days as arrival time for Treatments will be 10:00am.
            var startTime = "093000";

            var request = {
                method: "FetchSpaAvailability",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.WebFolioCustomerId,
                    SpaItemId: treatment.SpaItemId,
                    StartDateTime: this.treatmentDate,
                    SpaLocationId: treatment.Location,
                    SpaStaffId: null,
                    Gender: "X",
                    Version: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    if (!_.isArray(response.data.SpaAvailability)) {
                        response.data.SpaAvailability = [
                            response.data.SpaAvailability,
                        ];
                    }
                    // Clone results
                    var filteredAvailability = _.clone(
                        response.data.SpaAvailability
                    );

                    // Remove all treatments before/after arrival/departure
                    if (self.selectedFolio.Location == "Hoar Cross Hall") {
                        var noOfNights =
                            self.selectedFolio.TreatmentDates.length;

                        _.remove(filteredAvailability, function (avaToRemove) {
                            if (
                                self.treatmentDate ==
                                self.selectedFolio.TreatmentDates[0]
                            ) {
                                // first day
                                if (
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ) <
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.ArrivalTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                ) {
                                    return true;
                                }
                            } else if (
                                self.treatmentDate ==
                                self.selectedFolio.TreatmentDates[
                                    noOfNights - 1
                                ]
                            ) {
                                // last day
                                if (
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ) >
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.DepartureTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                ) {
                                    return true;
                                }
                            }
                        });
                    } else {
                        _.remove(filteredAvailability, function (avaToRemove) {
                            if (
                                moment(
                                    avaToRemove.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ) >
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.ArrivalTime,
                                        "YYYY-MM-DDhhmmss"
                                    ) &&
                                moment(
                                    avaToRemove.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ) <
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.DepartureTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        });
                    }

                    // Clone results
                    var finalAvailability = _.clone(filteredAvailability);

                    // Now remove any second treatment slots if the first slot is available. (Eden + 2hrs, HCH +15mins)
                    _.each(filteredAvailability, function (availability) {
                        if (
                            self.selectedFolio.Location == "Eden Hall - Day Spa"
                        ) {
                            _.remove(finalAvailability, function (avaToRemove) {
                                return (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                        .add(2, "h")
                                        .format("DD-MM-YYYY hh:mm:ss") ==
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("DD-MM-YYYY hh:mm:ss")
                                );
                            });
                        } else {
                            // HCH
                            _.remove(finalAvailability, function (avaToRemove) {
                                return (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                        .add(15, "m")
                                        .format("DD-MM-YYYY hh:mm:ss") ==
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("DD-MM-YYYY hh:mm:ss")
                                );
                            });
                        }
                        // Remove 17:30 slot if 18:45 slot exisits at Eden & HCH
                        if (self.selectedFolio.Type == "Spa Day") {
                            if (
                                moment(
                                    availability.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ).format("HH:mm:ss") == "17:30:00"
                            ) {
                                _.remove(
                                    finalAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("HH:mm:ss") == "18:45:00"
                                        );
                                    }
                                );
                            }
                        }
                        // For Eden, remove that pesky 15:45 time slot
                        if (
                            self.selectedFolio.Location == "Eden Hall - Day Spa"
                        ) {
                            if (
                                moment(
                                    availability.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ).format("HH:mm:ss") == "15:30:00"
                            ) {
                                _.remove(
                                    finalAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("HH:mm:ss") == "15:45:00"
                                        );
                                    }
                                );
                            }
                        }
                    });

                    if (finalAvailability.length) {
                        treatment.TreatmentTimes = finalAvailability;
                    } else {
                        treatment.noAvailability = true;
                    }
                } else {
                    treatment.noAvailability = true;
                }
                this.loading = false;
            });
        },

        addTreatmentToCart() {
            Bus.$emit("showLoading");

            if (this.system.CustomerId) {
                this.bookTreatment();
            } else {
                if (!this.system.tempSpaCustomerId) {
                    this.TempSpaNewCustomerForm.NewCustomer.FirstName =
                        this.system.WebFolioId;
                    this.TempSpaNewCustomerForm.NewCustomer.EmailAddress =
                        this.system.WebFolioId +
                        "@" +
                        this.system.WebFolioId +
                        ".com";
                    var request = {
                        method: "CreateCustomer",
                        data: this.TempSpaNewCustomerForm,
                    };
                    axios
                        .post("/rs/tempSpaAccount", request)
                        .then((response) => {
                            if (response.data.Result.value === "SUCCESS") {
                                this.system.tempSpaCustomerId =
                                    response.data.CustomerId;
                                this.system.tempLoggedIn = true;

                                this.bookTreatment();
                            } else {
                                console.log("temp login failed");
                            }
                        });
                } else {
                    this.bookTreatment();
                }
            }
        },

        bookTreatment() {
            Bus.$emit("showLoading");
            var request = {
                method: "CreateSpaBooking",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.CustomerId
                        ? this.system.CustomerId
                        : this.system.tempSpaCustomerId,
                    StartDateTime: this.selectedTime.StartTime,
                    SpaItemId: this.selectedTreatment.SpaItemId,
                    SpaFolioItemId: null,
                    SpaStaffId:
                        this.selectedTime.SpaStaffs.SpaStaff[0].SpaStaffId,
                    StaffRequested: null,
                    Gender: null,
                    GuestName: null,
                    GuestRequest: this.selectedFolio.FolioId,
                    Language: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    dataLayer.push({
                        event: "addToCart",
                        ecommerce: {
                            currencyCode: "GBP",
                            add: {
                                // 'add' actionFieldObject measures.
                                products: [
                                    {
                                        //  adding a product to a shopping cart.
                                        name: this.selectedTreatment.ItemName,
                                        id: this.selectedTreatment.SpaItemId,
                                        price: this.selectedTreatment.Price,
                                        brand: this.selectedTreatment.Venue,
                                        category: "Treatment",
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    });

                    this.$emitAnalytics(
                        "addToCart",
                        {
                            event: "addToCart",
                            ecommerce: {
                                currencyCode: "GBP",
                                add: {
                                    // 'add' actionFieldObject measures.
                                    products: [
                                        {
                                            //  adding a product to a shopping cart.
                                            name: this.selectedTreatment
                                                .ItemName,
                                            id: this.selectedTreatment
                                                .SpaItemId,
                                            price: this.selectedTreatment.Price,
                                            brand: this.selectedTreatment.Venue,
                                            category: "Treatment",
                                            quantity: 1,
                                        },
                                    ],
                                },
                            },
                        },
                        this.system.CustomerId,
                        this.system.WebFolioId
                    );

                    Bus.$emit("UpdateCart");
                }

                $(".extra-selector").removeClass("is-active");
                $("html").toggleClass("no-scroll");
                Bus.$emit("hideLoading");
                this.selectedTime = null;
                //this.treatmentDate = null;
                this.selectedTreatment.TreatmentTimes = null;

            });
        },

        removeTreatment(treatment) {
            Bus.$emit("showLoading");
            this.removing = treatment.FolioItemId;
            var request = {
                method: "CancelSpaService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    SpaFolioId: treatment.FolioId,
                    SpaFolioItemId: treatment.FolioItemId,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    this.removing = null;
                    Bus.$emit("UpdateCart");
                    Bus.$emit("hideLoading");
                }
            });
        },

        getTreatmentTimesV2(treatment) {
            var self = this;
            treatment.TreatmentTimes = null;
            this.selectedTreatment = treatment;
            this.loadingCabanas = true;

            // For stays, we need to check if middle days as arrival time for Treatments will be 10:00am.
            var startTime = "080000";

            var request = {
                method: "FetchSpaAvailability",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.WebFolioCustomerId,
                    SpaItemId: treatment.SpaItemId,
                    StartDateTime: this.treatmentDate+startTime,
                    SpaLocationId: treatment.Location,
                    SpaStaffId: null,
                    Gender: "X",
                    Version: "2",
                    IsForPackageItem: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    if (response.data.SpaAvailabilities.SpaAvailability) {
                        // we have availabilities
                        if (
                            !_.isArray(
                                response.data.SpaAvailabilities.SpaAvailability
                            )
                        ) {
                            response.data.SpaAvailabilities.SpaAvailability = [
                                response.data.SpaAvailabilities.SpaAvailability,
                            ];
                        }
                        _.each(response.data.SpaAvailabilities.SpaAvailability, function (ava) {
                            if(!_.isArray(ava.SpaStaffs.SpaStaff)) {
                                ava.SpaStaffs.SpaStaff = [ava.SpaStaffs.SpaStaff];
                            }
                        });

                        // Clone results
                        var filteredAvailability = _.filter(
                            response.data.SpaAvailabilities.SpaAvailability,
                            function (ava) {
                                return ava.StartTime.slice(0, -6) == self.treatmentDate;
                            }
                        );

                        if (filteredAvailability.length > 0) {
                            treatment.TreatmentTimes = filteredAvailability;
                        } else {
                            treatment.noAvailability = true;
                        }
                    } else {
                        treatment.noAvailability = true;
                    }
                } else {
                    treatment.noAvailability = true;
                }
                this.loadingCabanas = false;
            });
        },
    },
};
</script>
