<template>
    <span>
        <a v-if="cart.FolioItems" href="/book/cart" class="icons header-cart">
            <i class="icon-cart"></i> <span v-if="updateIcon"><div class="spinner-border spinner-border-sm" role="status"></div></span><span v-else>&pound;{{ cart.FolioBalance }}</span>
        </a>
    </span>
</template>

<script>
    export default {
        props: {
            cart: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                updateIcon: false
            }
        },


        mounted() {
            Bus.$on('showLoading', () => {
                $('#loadingOverlay').removeClass('hide-loading')
            });
            Bus.$on('hideLoading', () => {
                $('#loadingOverlay').addClass('hide-loading')
            });
            Bus.$on('updatingPrice', () => {
                this.updateIcon = true;
            });
            Bus.$on('priceUpdated', () => {
                this.updateIcon = false;
            });
        },

        /**
         * Methods
         */
        methods: {
            
        }
    }
</script>
