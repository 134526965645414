var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row slickInitBenefits",
      attrs: {
        "data-slick":
          '{"infinite": true, "slidesToScroll": 1, "slidesToShow": 2, "arrows": true, "dots": true, "autoplay": true, "speed": 1000, "autoplaySpeed": 3000, "responsive": [{"breakpoint": 991, "settings": {"slidesToShow": 1}} ]}',
      },
    },
    _vm._l(_vm.feed, function (article) {
      return _c("div", { staticClass: "benefit-carousel-item" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass: "card-image",
              style: "background-image:url(/storage/" + article.img + ");",
            },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-body__inner" }, [
              _c("h3", [_vm._v(_vm._s(article.title))]),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(article.copy) } }),
              _vm._v(" "),
              article.btn_1_url
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mr-1",
                      attrs: { href: article.btn_1_url },
                    },
                    [_vm._v(_vm._s(article.btn_1_text))]
                  )
                : _vm._e(),
              _vm._v(" "),
              article.btn_2_url
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mr-1",
                      attrs: { href: article.btn_2_url },
                    },
                    [_vm._v(_vm._s(article.btn_2_text))]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }