<template>
    <div class="booking-stay">
        <div v-for="n in notify">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="booking-date-notice">
                        <span v-html="n.message"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="booking-info">
                    <h2>Your {{ type | slugToTitle}}</h2>
                    <h4>
                        <a @click.prevent="prevDay()" class="prevNextArrow arrowLeft">&nbsp;</a>
                        {{ arrive | nicedate }}
                        <a @click.prevent="nextDay()" class="prevNextArrow arrowRight">&nbsp;</a>
                    </h4>
                    <ul class="guests-nights-selector">
                        <li>
                            <p class="mb-0">Guests</p>
                            <button @click="decGuests" class="btn btn-plus-minus">-</button>
                            <span class="book-int">{{ guests }}</span>
                            <button @click="incGuests" class="btn btn-plus-minus">+</button>
                        </li>
                    </ul>
                    <div class="message-small mt-2">
                        <a @click.prevent="dateSideout">Change date</a>
                    </div>
                    <progress-indicator :step="step"></progress-indicator>
                </div>
                <div class="package-list">
                    <div v-if="rates" class="row">
                        <div v-for="(rate, key) in rates" v-bind:key="key" class="col-12">
                            <div class="package-container">
                                <div class="package-image" :style="'background-image:url('+rate.PMSRateTypeImage+');'">&nbsp;</div>
                                <div class="package-type">
                                    <h3>{{ rate.PMSRateTypeDesc }}</h3>
                                    <div class="fromPrice">
                                        <span v-if="rate.SingleRate"><span :class="[rate.isDiscounted ? 'advancedPrice' : '']" class="mr-1">&pound;{{ rate.SingleRate | currency }}</span><span v-if="rate.isDiscounted">&pound;{{ rate.isDiscounted | currency }}</span></span> (per person)
                                    </div>
                                    <div class="totalPrice">
                                        <span v-if="rate.SingleRate"><span :class="[rate.isDiscounted ? 'advancedPrice' : '']" class="mr-1">&pound;{{ (rate.SingleRate * guests) | currency }}</span><span v-if="rate.isDiscounted">&pound;{{ (rate.isDiscounted * guests) | currency }}</span></span> (total price)
                                    </div>

                                    <button @click.prevent="selectedRate = rate; bookSingles()" class="btn btn-secondary d-lg-none mb-0 align-self-end">Book</button>
                                    <button @click.prevent="viewMoreDetails(rate)" class="btn btn-outline-primary d-lg-none mb-0 align-self-end">View more details</button>
                                </div>
                                <div class="package-description">
                                    {{ rate.PMSRateTypeCustDesc.substring(0,180) }}...

                                    <div class="d-xl-flex package-buttons">
                                        <button @click.prevent="selectedRate = rate; bookSingles()" class="btn btn-secondary flex-xl-fill mr-md-1">Book</button>
                                        <button @click.prevent="viewMoreDetails(rate)" class="btn btn-outline-primary flex-xl-fill">View details</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="no-availability-message">
                            <div v-if="loadingPackages" class="text-center">
                                <h4>Please wait while we fetch all the packages.</h4>
                            </div>
                            <div v-else class="text-center">
                                <h4>Sorry, there is no availability on this date. Please choose another date.</h4>
                                <button class="btn btn-sm" @click.prevent="dateSideout">Select another date</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="upsellPrice" class="row justify-content-center">
                    <div class="col-md-8 mt-2">
                        <div class="p-3 text-center">
                            <h5>Stay overnight from an extra &pound;{{ upsellPrice | currency }} per person. <router-link :to="'/book/booking/hoar-cross-hall/stay/'+arrive+'/1/2'">View Stay Packages</router-link></h5>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 mt-3">
                        <div class="gc-box">
                            <h5>Redeeming a Gift Card or E-voucher?</h5>
                            <p>Did you know, all of our gift vouchers hold a monetary value and can be redeemed against any package. Simply select your chosen package, then enter your voucher code at checkout to redeem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-app-sideout package-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">

                    <div class="sideout-content rate-details" v-if="selectedRate">
                        <div class="package-header">
                            <a @click.prevent="closeSideout()" class="prevNextArrow arrowLeft">&nbsp;</a>
                        </div>
                        <div class="package-content">
                            <div class="room-package-image" :style="'background-image:url('+selectedRate.PMSRateTypeImage+');'">&nbsp;</div>
                            <div class="room-full-description">
                                <h4>{{ selectedRate.PMSRateTypeDesc }}</h4>
                                <div v-html="selectedRate.details.intro"></div>
                                <div v-html="selectedRate.details.details"></div>
                            </div>

                            <div class="package-cta">
                                <button @click.prevent="bookSingles" class="btn btn-secondary btn-block">Book</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-app-sideout date-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a @click.prevent="dateSideout()" class="prevNextArrow arrowLeft">&nbsp;</a>&nbsp;
                        </div>
                        <div class="package-content">
                            <div class="room-full-description text-center">
                                <h4>{{ arrive | nicedate }}</h4>
                                <p class="mb-1">Please note, gift voucher codes are entered at checkout.</p>

                                <rate-calendar :venue="venueName" :type="type" :mindate="mindate" :arrive="arrive" :promo="promoCode"></rate-calendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade blush-modal" :id="'confirmNotification_' + notification.id" tabindex="-1" role="dialog" :aria-labelledby="'confirmNotification_' + notification.id" aria-hidden="true"  v-for="(notification, index) in confirmNotifications" :key="index">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h5 class="modal-title w-100">Important Notice</h5>
                    </div>
                    <div class="modal-body text-center" v-html="notification.message"></div>
                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-primary" @click="chooseAnotherDate(notification.id)">Select Another Date</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Accept</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Group booking modal -->
        <div class="modal fade blush-modal" id="groupsModal" tabindex="-1" role="dialog" aria-labelledby="groupsModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header border-none">
                        <h5 class="modal-title w-100 text-center">Booking on behalf of a group?</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div v-if="venueName === 'Eden Hall - Day Spa'" class="modal-body text-center">
                        <p>For groups over six, please call our Guest Experience Team on <a href="tel:+441608787986">01608 787986</a>, who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests.</p>
                    </div>
                    <div v-else class="modal-body text-center">
                        <p>Then look no further than our collection of group spa experiences, created for milestone celebrations, general get-togethers and corporate team away days.</p>
                        <p>Please call our Guest Experience Team on 01608 787986, who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests.</p>
                        <a href="/hoar-cross-hall/group-experiences" class="btn btn-primary btn-block mt-4 mb-0">View our group Spa collection</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProgressIndicator from '../book/ProgressIndicator.vue';
    export default {
        props: {
            system: Object,
            cart: Object,
            type: String,
            notifications: Array
        },

        components: {
            ProgressIndicator
        },

        data() {
            return {
                step: 1,
                mindate: moment().format('YYYY-MM-DD'),
                arrive: this.$route.params.arrive ? this.$route.params.arrive : moment().add(1, 'days').format('YYYY-MM-DD'),
                guests: this.$route.params.guests ? +this.$route.params.guests : 2,
                rate: this.$route.params.rate ? this.$route.params.rate : null,
                venueName: this.$options.filters.venue(this.$route.params.venue) == 'Hoar Cross Hall' ? 'Hoar Cross Hall - Day Spa' : this.$options.filters.venue(this.$route.params.venue),
                venueSlug: this.$route.params.venue,
                promoCode: this.$route.query.promo ? this.$route.query.promo : null,
                invalidPromo: null,
                next: 'treatments',
                rates: null,
                rooms: null,
                selectedRate: null,
                isDisabled: null,
                bookingsAdded: 0,
                packageFilter: null,
                fetchingRates: null,
                loadingPackages: false,
                selectedRate: null,
                invalidPromo: null,
                upsellPrice: null,
                availability: null
            }
        },

        beforeMount() {
            Bus.$on('changeDate', (date) => {
                this.arrive = date
            })
        },

        mounted() {
            this.fetchPMSRatesRequest()
            //this.fetchAvailability()
            this.packageFilter = this.rate ? this.rate.split('-') : null
            Bus.$emit('showLoading')
        },

        computed: {
            arriveForUrl() {
                return moment(this.arrive).format('YYYY-MM-DD')
            },
            notify() {
                var self = this
                var notices = []
                _.each(self.notifications, function(o) {
                    if(o.location == self.venue) {
                        _.each(o.dates, function(d) {
                            if(_.includes(d, moment(self.arrive).format('YYYY-MM-DD'))) {
                                if(o.type == 'message') {
                                    notices.push(o)
                                }
                            }
                        })
                    }
                })
                return notices;
            },
            confirmNotifications() {
                var self = this
                var notices = []
                _.each(self.notifications, function(o) {
                    if(o.location == self.venue || o.location == self.venueSlug  && o.stay_type == 'spa-day') {
                        _.each(o.dates, function(d) {
                            if(_.includes(d, moment(self.arrive).format('YYYY-MM-DD'))) {
                               if(o.type == 'confirmation-popup') {
                                    notices.push(o);

                                    setTimeout(function() {
                                        $('#confirmNotification_' + o.id).modal('show');
                                    }, 2000);
                                }
                            }
                        })
                    }
                })
                return notices;
            },
            advancedPrice() {
                if(moment(this.arrive) > moment().add(6, 'weeks')) {
                    if(this.selectedRate.Venue == 'Eden Hall - Day Spa') {
                        return false
                    } else {
                        if(this.selectedRate.PMSRate.PMSRoomType.PMSRoomTypeId != 'HTWI' || this.selectedRate.PMSRate.PMSRoomType.PMSRoomTypeId != 'EFEE') {
                            return true
                        } else {
                            return false
                        }
                    }
                }
            }
        },

        watch: {
            guests: function() {
                this.updateRoute(false)
            },
            arrive: function() {
                this.updateRoute(true)
            },
        },

        methods: {
            chooseAnotherDate(id) {
                $('#confirmNotification_' + id).modal('hide');

                $('.date-selector').addClass('is-active');
                $('html').addClass('no-scroll');
            },
            prevDay() {
                this.arrive = moment(this.arrive).subtract(1, 'days').format('YYYY-MM-DD')
            },
            nextDay() {
                this.arrive = moment(this.arrive).add(1, 'days').format('YYYY-MM-DD')
            },
            fetchAvailability() {
                var self = this
                var depart = moment(this.arrive).add(1, 'days').format('YYYY-MM-DD')

                var request = {
                    method: 'FetchPMSVenueAvailability',
                    data: {
                        Venue: this.venueName,
                        StartDate: moment(this.arrive).format('YYYY-MM-DD'),
                        EndDate: depart,
                        WebFolioId: this.system.WebFolioId,
                        Language: null,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(!$.isArray(response.data.VenueDailyAvailability)) {
                            response.data.VenueDailyAvailability = [response.data.VenueDailyAvailability];
                        }
                        this.availability = response.data.VenueDailyAvailability
                        Bus.$emit('hideLoading')
                    })
            },

            upsell() {
                var self = this

                var request = {
                    venue: 'Hoar Cross Hall',
                    start: self.arrive,
                    end: moment(self.arrive).add(1, 'days').format('YYYY-MM-DD'),
                }

                axios.post('/api/cached/calendar', request)
                    .then(response=> {
                        if(response.data.calendar.length) {
                            var day = response.data.calendar[0]
                            if(day) {
                                day.date = moment(day.date).format('YYYY-MM-DD')
                                if(day.rates) {
                                    // filter the result based on rooms available and availabile for this day of week
                                    var ratesForDay = _.filter(day.rates, function(r) {
                                        return (r.NumRoomsAvail && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N") && r.MaxLength == '1'
                                    })

                                    var lowestRate = null
                                    if(ratesForDay.length) {
                                        lowestRate = _.minBy(ratesForDay, function(rate) {
                                            return +rate.DoubleRate
                                        })
                                    }

                                    if(lowestRate) {
                                        var spaDays = null

                                        if(self.rates.length == 1) {
                                            spaDays = _.cloneDeep(self.rates)
                                        } else {
                                            spaDays = _.filter(self.rates, function(sd) {
                                                return sd.PMSRate.PMSRoomType.PMSRoomTypeId != 'HTWI'
                                            })
                                        }

                                        if(spaDays.length) {

                                            var cheapestSpaDay = _.minBy(spaDays, function(rate) {
                                                return +rate.SingleRate
                                            })
                                            var price = (+lowestRate.DoubleRate / 2) - (+cheapestSpaDay.SingleRate)

                                            self.upsellPrice = Math.round(price * 100) / 100
                                        }
                                    }
                                }
                            }
                        }
                    });
            },
            closeSideout() {
                $('.package-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');
            },

            dateSideout() {
                $('.date-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');
            },

            viewMoreDetails(rate) {
                this.selectedRate = rate;

                $('.package-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');

                dataLayer.push({
                    event: "view_item",
                    venue: this.venueName,
                    ecommerce: {
                        currency: "GBP",
                        value: +this.selectedRate.SingleRate,
                        items: [{
                            item_name: this.selectedRate.PMSRateTypeDesc,
                            item_id: this.selectedRate.PMSRateTypeId,
                            price: +this.selectedRate.SingleRate,
                            item_brand: this.venueName,
                            item_category: "Spa Days",
                            quantity: this.guests,
                        }]
                    }
                });

                this.$emitAnalytics(
                    'view_item',
                    {
                        event: "view_item",
                        venue: this.venueName,
                        ecommerce: {
                            currency: "GBP",
                            value: +this.selectedRate.SingleRate,
                            items: [{
                                item_name: this.selectedRate.PMSRateTypeDesc,
                                item_id: this.selectedRate.PMSRateTypeId,
                                price: +this.selectedRate.SingleRate,
                                item_brand: this.venueName,
                                item_category: "Spa Days",
                                quantity: this.guests,
                            }]
                        }
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                )
            },

            applyPromo() {
                this.fetchPMSRatesRequest()
            },
            clearPromo() {
                this.promoCode = null
                this.fetchPMSRatesRequest()
            },
            // Guests
            decGuests() {
                if(this.guests > 1) {
                    this.guests--
                }
            },
            incGuests() {
                if(this.guests < 6) {
                    this.guests++
                } else {
                    $('#groupsModal').modal('show');
                }
            },

            updateRoute(refresh) {
                this.$router.replace({ name: 'book-spa', params: { venue: this.venueSlug, arrive: moment(this.arrive).format('YYYY-MM-DD'), guests: this.guests, rate: this.rate } })

                if(refresh) {
                    this.fetchPMSRatesRequest()
                }
            },

            fetchPMSRatesRequest() {
                this.rates = null
                this.invalidPromo = null
                this.loadingPackages = true
                this.upsellPrice = null

                var promo = this.promoCode ? this.promoCode : 'DAYGUEST'

                var depart = moment(this.arrive).add(1, 'days').format('YYYY-MM-DD')
                var request = {
                    method: 'FetchPMSRates',
                    data: {
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: 1,
                        Children: 0,
                        Youth: 0,
                        Infants: 0,
                        WebFolioId: null,
                        Language: null,
                        Venue: this.venueName,
                        PromoCode: promo,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        var self = this;

                        if(response.data.Result.value == 'FAIL') {
                            this.rates = null
                            if(response.data.Result.ErrorId == 205) {
                                this.invalidPromo = 'Invalid promo code'
                            }

                        } else {
                            if(!$.isArray(response.data.PMSRateType)) {
                                response.data.PMSRateType = [response.data.PMSRateType];
                            }

                            // Filter rates if from a package
                            if(this.packageFilter) {
                                response.data.PMSRateType = response.data.PMSRateType.filter(function (rate) {
                                    return _.includes(self.packageFilter, rate.PMSRateTypeId)
                                })
                            }

                            // Temp remove Serene if date is less than 48hrs away
                            /*
                            if(moment(this.arrive) <= moment().add(2, 'days')) {
                                _.remove(response.data.PMSRateType, function(o) {
                                    if(o.PMSRateTypeId == 'EHSERENEA' || o.PMSRateTypeId == 'DSERENEA') {
                                        return o
                                    }
                                })
                            }
                            */

                            _.each(response.data.PMSRateType, function(o) {
                                Vue.set(o, 'PMSRate', null)
                                Vue.set(o, 'SingleRate', null)
                                self.fetchPMSRoomTypesRequest(o.PMSRateTypeId)
                            })

                            self.rates = response.data.PMSRateType

                            var webfolio = {}
                            webfolio[this.system.WebFolioId] = {
                                'booking': {
                                    'venue': this.$route.params.venue,
                                    'type': 'spa-day',
                                    'arrive': moment(this.arrive).format('YYYY-MM-DD'),
                                    'nights': this.nights,
                                    'guests': 1,
                                    'rooms': this.room
                                }
                            }
                            window.localStorage.setItem('rsbooking', JSON.stringify(webfolio))

                            // Get web details
                            _.each(response.data.PMSRateType, function(o) {
                                self.fetchPackageDetails(o)
                            })

                            if(this.venueName == 'Hoar Cross Hall - Day Spa') {
                                self.upsell()
                            }
                        }
                        this.loadingPackages = false
                        Bus.$emit('hideLoading')
                    });
            },

            fetchPMSRoomTypesRequest (rateType) {
                var self = this
                var depart = moment(this.arrive).add(1, 'days').format('YYYY-MM-DD')

                var request = {
                    method: 'FetchPMSRoomTypes',
                    data: {
                        PMSRateType: rateType,
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: 1,
                        Children: 0,
                        Youth: 0,
                        Infants: 0,
                        WebFolioId: this.system.WebFolioId,
                        Language: null,
                        Venue: this.venueName,
                        PromoCode: this.promoCode
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(!$.isArray(response.data.PMSRate.DailyRate)) {
                            response.data.PMSRate.DailyRate = [response.data.PMSRate.DailyRate];
                        }
                        var rate = _.find(self.rates, {'PMSRateTypeId': rateType})
                        rate.PMSRate = response.data.PMSRate
                        rate.SingleRate = response.data.PMSRate.DailyRate[0].Rate
                        Vue.set(rate, 'isDiscounted', null)
                    })
            },

            bookSingles() {
                Bus.$emit('showLoading')
                var self = this
                this.bookingsAdded = 0

                var i = 1
                while(i <= self.guests) {
                    window.setTimeout(function() {
                        self.book()
                    }, i*2000)
                    i++
                }
            },

            book() {
                var depart = moment(this.arrive).add(1, 'days').format('YYYY-MM-DD')
                var self = this

                var request = {
                    method: 'CreatePMSBooking',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        PMSRoomType: this.selectedRate.PMSRate.PMSRoomType.PMSRoomTypeId,
                        RoomNumber: null,
                        CustomerId: this.system.isLoggedIn ? this.system.CustomerId : this.system.WebFolioCustomerId,
                        PMSRateType: this.selectedRate.PMSRateTypeId,
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: 1,
                        Children: null,
                        Youth: null,
                        Infants:null,
                        GroupCode: null
                    }
                }

                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') { // Added to WebFolio
                            this.bookingsAdded++

                            var ssAdvName
                            if(this.selectedRate.Location == 'Eden Hall - Day Spa') {
                                ssAdvName = 'Advanced Booking Offer -  10%'
                            } else {
                                ssAdvName = 'Advanced Booking Offer  - 10%'
                            }

                            dataLayer.push({
                                'event': 'add_to_cart',
                                'venue': this.venueName,
                                'ecommerce': {
                                    'currency': 'GBP',
                                    'add': {
                                        'products': [{
                                            'name': this.selectedRate.PMSRateTypeDesc,
                                            'id': this.selectedRate.PMSRateTypeId,
                                            'price': +this.selectedRate.SingleRate,
                                            'brand': this.venueName,
                                            'category': 'Spa Days',
                                            'quantity': this.guests,
                                        }]
                                    }
                                }
                            });

                            this.$emitAnalytics(
                                'add_to_cart',
                                {
                                    'event': 'add_to_cart',
                                    'venue': this.venueName,
                                    'ecommerce': {
                                        'currency': 'GBP',
                                        'add': {
                                            'products': [{
                                                'name': this.selectedRate.PMSRateTypeDesc,
                                                'id': this.selectedRate.PMSRateTypeId,
                                                'price': +this.selectedRate.SingleRate,
                                                'brand': this.venueName,
                                                'category': 'Spa Days',
                                                'quantity': this.guests,
                                            }]
                                        }
                                    }
                                },
                                this.system.CustomerId,
                                this.system.WebFolioId
                            );

                            // Lets track this in local storage for later
                            var rsfolio = {
                                PMSFolioId: response.data.PMSFolioId,
                                PMSRoomType: this.selectedRate.PMSRate.PMSRoomType.PMSRoomTypeId,
                                PMSRateType: this.selectedRate.PMSRateTypeId,
                                ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                                DepartureDate: depart,
                                Adults: 1,
                                Nights: 1
                            }

                            var sessionStorage = JSON.parse(window.localStorage.getItem('session_folios'))
                            sessionStorage[this.system.WebFolioId][response.data.PMSFolioId] = rsfolio
                            window.localStorage.setItem('session_folios', JSON.stringify(sessionStorage))

                        } else {
                            // handle error
                        }
                        this.isDisabled = null
                        if(this.bookingsAdded == this.guests) {
                            Bus.$emit('hideLoading')
                            Bus.$emit('UpdateCart')
                            this.$router.push('/book/enhance/' + this.$route.params.venue + '/' + this.type + '/' + moment(this.arrive).format('YYYY-MM-DD') + '/' + this.guests)
                        }
                });
            },

            fetchPackageDetails(rate) {
                axios.get('/rs/rates/' + rate.PMSRateTypeId)
                    .then(response => {
                        rate.details = response.data
                    })
            },
        },
    }
</script>
