<template>
    <div>
        <div class="row justify-content-md-center">
            <div class="col-md-8 text-center">
                <div class="book-start">
                    <div class="min-300">
                        <h1 class="brandHeading">Plan your experience</h1>
                        <p class="mb-4">
                            Which spa retreat would you like to visit?
                        </p>
                        <a
                            @click.prevent="selectVenue('hoar-cross-hall')"
                            class="brand-select hch-bg"
                            ><img
                                src="/img/hch_cream_full.svg"
                                alt="Hoar Cross Hall logo"
                                loading="lazy"
                        /></a>
                        <a
                            @click.prevent="selectVenue('eden-hall')"
                            class="brand-select eh-bg"
                            ><img
                                src="/img/eh_cream_full.svg"
                                alt="Eden Hall logo"
                                loading="lazy"
                        /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        system: Object,
        cart: Object,
        venue: String,
    },

    components: {},

    data() {
        return {
            bookingExists: false,
        };
    },

    beforeMount() {},

    mounted() {
        var self = this;
        Bus.$emit("RefreshSystem");
        if (self.cart.WebFolioItem) {
            self.checkExistingBooking();
        }
    },

    computed: {},

    watch: {
        cart: function (newVal, oldVal) {
            if (newVal.WebFolioItem) {
                this.checkExistingBooking();
            } else {
                this.bookingExists = null;
            }
        },
    },

    methods: {
        checkExistingBooking() {
            var current = _.find(this.cart.WebFolioItem, { Category: "Hotel" });
            if (current) {
                current.guests = _.sumBy(this.cart.WebFolioItem, "Adults");
                this.bookingExists = current;
            }
        },
        continueBooking(type) {
            var venue =
                this.bookingExists.Location == "Eden Hall - Day Spa"
                    ? "eden-hall"
                    : "hoar-cross-hall";

            if (this.bookingExists.type == "Stay") {
                this.$router.push(
                    "/book/" +
                        type +
                        "/" +
                        venue +
                        "/stay/" +
                        this.bookingExists.StartDate.slice(0, -6) +
                        "/" +
                        this.bookingExists.Nights +
                        "/" +
                        this.bookingExists.Adults
                );
            } else {
                this.$router.push(
                    "/book/" +
                        type +
                        "/" +
                        venue +
                        "/spa-day/" +
                        this.bookingExists.StartDate.slice(0, -6) +
                        "/" +
                        this.bookingExists.Adults
                );
            }
        },
        restartBooking() {
            Bus.$emit("RestartBooking", false);
            this.bookingExists = false;
        },
        selectVenue(venue) {
            this.$router.push("/book/start/" + venue);
        },
    },
};
</script>
