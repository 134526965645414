<template>
    
</template>

<script>
    export default {
        props: {
            
        },

        components: {
            
        },

        data() {
            return {
                
            }
        },
        
        mounted() {
            
        },

        watch: {
            
        },

        methods: {
            
        },
    }
</script>
