<template>
    <div class="booking-app">
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                <div class="mt-3">
                    <h1 class="brandHeading mb-4">Password Reset</h1>
                    <div v-if="invalidLink">
                        <p>Oh! looks like you do not have a valid password reset link.</p>
                        <router-link to="/book/account/request-password-reset" class="btn btn-secondary btn-primary mt-3">Request password reset</router-link>
                    </div>
                    <div v-else>
                        <div v-if="checkMessage">
                            <p>{{ checkMessage }}</p>
                            <router-link to="/book/account/request-password-reset" class="btn btn-secondary btn-primary mt-3">Request password reset</router-link>
                        </div>
                        <div v-else>
                            <div v-if="resetSuccess">
                                <h5>Your password was reset successfully.</h5>
                                <router-link to="/book/cart" class="btn btn-secondary btn-primary mt-3">View cart</router-link>
                            </div>
                            <div v-else>
                                <form @submit.prevent="changePassword" class="text-left">
                                    <div v-if="checkReady" class="text-center">
                                        <p>It looks like you have requested a password reset.</p>
                                        <button @click.prevent="checkReset" class="btn btn-primary">Validate reset request</button>
                                    </div>
                                    <div v-else>
                                        <h5>Enter your new password.</h5>
                                        <div class="form-row">
                                            <div class="form-group col">
                                                <password-checker :password.sync="newPassword" id="newPassword" placeholder="Enter new password" :minlength="8" :required="true" :min-password-strength="this.$root.system.MinPasswordStrength" :is-password-valid.sync="passwordValid" />
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col">
                                                <button type="submit" class="btn btn-primary">Reset Password</button>
                                            </div>
                                        </div>
                                        <div v-if="resetError" class="form-row">
                                            <p class="error">{{ resetError }}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                guid: this.$route.query.reset,
                email: this.$route.query.email,
                resetValid: false,
                checkingValid: false,
                checkMessage: null,
                newPassword: '',
                mismatchError: null,
                invalidLink: false,
                resetSuccess: false,
                resetError: null,
                checkReady: false,
                passwordValid: false
            }
        },

        mounted() {
            if(this.$route.query.reset && this.$route.query.email) {
                this.checkReady = true
            } else {
                this.invalidLink = true
            }
        },
        watch: {
            system() {
                if(this.system.CustomerId) {
                    this.$router.push('/book/account')
                }
            }
        },

        computed: {

        },

        /**
         * Methods
         */
        methods: {
            checkReset() {
                Bus.$emit('showLoading')
                this.checkingValid = true
                var request = {
                    method: 'ResetPasswordCustomer',
                    data: {
                        EmailAddress: this.email,
                        PasswordGUID: this.guid,
                        NewPassword: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        this.checkingValid = false
                        if(response.data.Result.value == 'FAIL') {
                            this.checkMessage = response.data.Result.Text;
                        } else {
                            this.checkReady = false
                        }
                        Bus.$emit('hideLoading')
                    });
            },
            changePassword() {
                this.resetError = null
                Bus.$emit('showLoading');
                
                var request = {
                    method: 'ResetPasswordCustomer',
                    data: {
                        EmailAddress: this.email,
                        PasswordGUID: this.guid,
                        NewPassword: this.newPassword
                    }
                }

                axios.post('/rs/request', request)
                    .then(response=> {
                        Bus.$emit('hideLoading')
                        if(response.data.Result.value == 'FAIL') {
                            this.resetError = response.data.Result.Text
                        } else {
                            this.resetSuccess = true
                        }
                    });
            },
        }
    }
</script>
