<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div v-if="system.CustomerId" class="col-md-6">
                    <h3>Confirm details</h3>
                    <p class="small">Required fields *</p>
                    <div v-if="profile" class="mt-5">
                        <form @submit.prevent="update">
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="firstName">First name*</label>
                                    <input
                                        v-model="updateForm.Name.FirstName"
                                        type="text"
                                        class="form-control"
                                        id="firstName"
                                        placeholder="First name"
                                        required
                                    />
                                </div>
                                <div class="form-group col">
                                    <label for="surname">Surname*</label>
                                    <input
                                        v-model="updateForm.Name.LastName"
                                        type="text"
                                        class="form-control"
                                        id="surname"
                                        placeholder="Surname"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="newEmail"
                                        >Your email address*</label
                                    >
                                    <input
                                        v-model="updateForm.EmailAddress"
                                        type="email"
                                        class="form-control"
                                        id="newEmail"
                                        name="update-email"
                                        placeholder="Enter email"
                                        autocomplete="update-email"
                                        required
                                    />
                                </div>
                            </div>
                            <hr />
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="Address1">Address*</label>
                                    <input
                                        v-model="updateForm.Address[0].Address1"
                                        @input="debouncedFetchAddressLookup"
                                        type="text"
                                        class="form-control"
                                        id="Address1"
                                        name="Address1"
                                        ref="Address1"
                                        placeholder="Address line 1"
                                        autocomplete="off"
                                        required
                                    />

                                    <ul
                                        v-if="addressResults.length"
                                        class="autocomplete"
                                    >
                                        <li class="amount">
                                            Showing
                                            {{ addressResults.length }} results
                                        </li>
                                        <li
                                            v-for="(
                                                address, index
                                            ) in addressResults"
                                            :key="index"
                                            @click="selectAddress(address)"
                                            class="list-item"
                                        >
                                            {{ address.address_text }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="Address2">Address line 2</label>
                                    <input
                                        v-model="updateForm.Address[0].Address2"
                                        type="text"
                                        class="form-control"
                                        id="Address2"
                                        name="Address2"
                                        ref="Address2"
                                        placeholder="Address line 2"
                                    />
                                </div>
                                <div class="form-group col">
                                    <label for="City">City*</label>
                                    <input
                                        v-model="updateForm.Address[0].City"
                                        type="text"
                                        class="form-control"
                                        id="City"
                                        name="City"
                                        ref="City"
                                        placeholder="City"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="PostCode">Post Code*</label>
                                    <input
                                        v-model="updateForm.Address[0].PostCode"
                                        type="text"
                                        class="form-control"
                                        id="PostCode"
                                        name="PostCode"
                                        ref="PostCode"
                                        placeholder="Post Code"
                                        required
                                    />
                                </div>
                                <div class="form-group col">
                                    <label for="Country">Country*</label>
                                    <input
                                        :value="updateForm.Address[0].Country"
                                        type="text"
                                        class="form-control"
                                        id="Country"
                                        name="Country"
                                        ref="Country"
                                        placeholder="Country"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="Tel">Telephone*</label>
                                    <input
                                        v-model="
                                            updateForm.Phone[0].PhoneNumber
                                        "
                                        type="tel"
                                        pattern="^0([1-6][0-9]{8,10}|7[0-9]{9})$"
                                        class="form-control"
                                        id="Tel"
                                        name="Tel"
                                        ref="Tel"
                                        placeholder="Telephone number"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="Tel">Mobile*</label>
                                    <input
                                        v-model="
                                            updateForm.Phone[1].PhoneNumber
                                        "
                                        type="tel"
                                        pattern="^0([1-6][0-9]{8,10}|7[0-9]{9})$"
                                        class="form-control"
                                        id="Mob"
                                        name="Mob"
                                        ref="Mob"
                                        placeholder="Mobile number"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12 mb-0">
                                    <label for="DateOfBirth"
                                        >Date of birth, so we can send you a
                                        birthday treat.</label
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <v-date-picker
                                        v-model="updateForm.DateOfBirth"
                                    >
                                        <input
                                            slot-scope="{
                                                inputProps,
                                                inputEvents,
                                            }"
                                            :class="['form-control']"
                                            v-bind="inputProps"
                                            v-on="inputEvents"
                                            placeholder="Select date"
                                        />
                                    </v-date-picker>
                                </div>
                            </div>
                            <div class="form-check">
                                <label
                                    class="form-check-label float-left"
                                    for="NoEmail"
                                >
                                    <input
                                        v-model="updateForm.NoEmail"
                                        type="checkbox"
                                        class="form-check-input"
                                        id="NoEmail"
                                    />
                                    <span class="checkmark"></span>
                                    Tick to receive the latest news, events and
                                    exclusive offers.
                                </label>
                            </div>

                            <div class="form-row">
                                <div class="form-group col">
                                    <span class="validation">{{ error }}</span>
                                </div>
                            </div>
                            <div class="form-row mt-2">
                                <div class="form-group col text-right">
                                    <a @click.prevent="forgotPassword"
                                        >Change password</a
                                    >
                                    <button
                                        type="submit"
                                        class="btn btn-reversed ml-3"
                                        :disabled="updating"
                                    >
                                        Update details
                                        <div
                                            v-if="updating"
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                        ></div>
                                    </button>
                                </div>
                            </div>
                            <div v-if="success">
                                Details updated successfully!
                            </div>
                        </form>
                    </div>
                    <div v-else>
                        <p>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span>
                            Fetching your details...
                        </p>
                    </div>
                </div>
                <div v-else class="col-md-6 text-center">
                    <h3 class="mt-3">Please login to view your profile</h3>
                    <button
                        type="button"
                        class="btn btn-reversed mt-3"
                        data-toggle="modal"
                        data-target="#loginModal"
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="loginModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="loginModalTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form @submit.prevent="login">
                        <div class="modal-header">
                            <h5 class="modal-title" id="loginModalTitle">
                                Login
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="customerEmail"
                                        >Your email address</label
                                    >
                                    <input
                                        @change="clearError"
                                        v-model="email"
                                        type="email"
                                        class="form-control"
                                        id="customerEmail"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter email"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="customerPassword"
                                        >Your password</label
                                    >
                                    <input
                                        @change="clearError"
                                        v-model="password"
                                        type="password"
                                        class="form-control"
                                        id="customerPassword"
                                        aria-describedby="passwordlHelp"
                                        placeholder="Enter password"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <a
                                        @click.prevent="forgotPassword"
                                        class="small-text"
                                        >Forgot password</a
                                    >
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <span v-if="loading"
                                        ><div
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                        ></div>
                                        Loading...</span
                                    >
                                    <div
                                        class="alert alert-danger"
                                        role="alert"
                                        v-if="error"
                                    >
                                        {{ error }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="submit" class="btn btn-primary">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="passwordModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="passwordModalTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updatePassword">
                        <div class="modal-header">
                            <h5 class="modal-title" id="passwordModalTitle">
                                Update password
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="resetPassword"
                                        >New password</label
                                    >
                                    <input
                                        v-model="passwordForm.Password"
                                        type="password"
                                        minlength="8"
                                        pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}"
                                        class="form-control"
                                        id="resetPassword"
                                        placeholder="Min 8, 1 num, 1 capital"
                                        required
                                    />
                                    <div class="small">
                                        Minimum 8 characters, 1 number and 1
                                        capital letter
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="resetPassword"
                                        >Confirm password</label
                                    >
                                    <input
                                        v-model="matchPassword"
                                        type="password"
                                        minlength="8"
                                        pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}"
                                        class="form-control"
                                        id="confirmPassword"
                                        placeholder="Min 8, 1 num, 1 capital"
                                        required
                                    />
                                    <div
                                        v-if="
                                            matchPassword !=
                                            passwordForm.Password
                                        "
                                        class="mt-2"
                                    >
                                        Password does not match
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <div v-if="successPassword" class="mt-2">
                                        Password updated successfully!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary"
                                :disabled="
                                    updatingPassword ||
                                    matchPassword != passwordForm.Password
                                "
                            >
                                Update password
                                <div
                                    v-if="updatingPassword"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                ></div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";

var updateFormTemplate = function () {
    return {
        CustomerId: null,
        CustomerGUID: null,
        Name: {
            Salutation: null,
            FirstName: null,
            LastName: null,
        },
        EmailAddress: null,
        NoEmail: false,
        NoMail: "Y",
        NoCall: "Y",
        NoSMS: "Y",
        Password: null,
        DateOfBirth: null,
        Address: [
            {
                type: "HOME",
                Address1: null,
                Address2: null,
                City: null,
                StateProv: null,
                Country: null,
                PostCode: null,
            },
        ],
        Phone: [
            {
                type: "HOME",
                primary: true,
                PhoneNumber: null,
            },
            {
                type: "MOBILE",
                primary: false,
                PhoneNumber: null,
            },
        ],
    };
};
var updatePasswordForm = function () {
    return {
        Password: null,
    };
};
export default {
    props: {
        system: Object,
    },

    components: {},

    data() {
        return {
            profile: null,
            updateForm: new updateFormTemplate(),
            passwordForm: new updatePasswordForm(),
            updating: null,
            error: null,
            success: null,
            matchPassword: null,
            updatingPassword: null,
            successPassword: null,
            email: null,
            password: null,
            loading: null,
            addressResults: [],
        };
    },

    mounted() {
        this.getProfile();
        Bus.$emit("RefreshSystem");
    },

    watch: {
        system() {
            this.getProfile();
        }
    },

    methods: {
        async fetchAddressLookup() {
            var self = this;

            try {
                await axios.get(
                    "https://api.baronseden.com/sanctum/csrf-cookie"
                );
                const response = await axios.post(
                    "https://api.baronseden.com/api/address/lookup",
                    {
                        address: self.updateForm.Address[0].Address1,
                    },
                    {
                        headers: {
                            "X-CSRF-TOKEN": document
                                .querySelector('meta[name="csrf-token"]')
                                .getAttribute("content"),
                        },
                    }
                );

                this.addressResults = response.data.data;
            } catch (error) {
                console.error("Error:", error);
            }
        },

        debouncedFetchAddressLookup: debounce(function () {
            this.fetchAddressLookup();
        }, 700),

        async selectAddress(address) {
            const response = await axios.post(
                "https://api.baronseden.com/api/address/format",
                {
                    global_address_key: address.global_address_key,
                },
                {
                    headers: {
                        "X-CSRF-TOKEN": document
                            .querySelector('meta[name="csrf-token"]')
                            .getAttribute("content"),
                    },
                }
            );

            this.populateForm(response.data.data);
        },

        populateForm(address) {
            this.updateForm.Address[0].Address1 = address.address_line_1;
            this.updateForm.Address[0].Address2 = address.address_line_2;
            this.updateForm.Address[0].City = address.city;
            this.updateForm.Address[0].PostCode = address.post_code;
            this.updateForm.Address[0].Country = address.country;

            this.addressResults = [];
        },

        getProfile() {
            var request = {
                method: "FetchCustomerProfile",
                data: {
                    CustomerId: this.system.CustomerId,
                    CustomerGUID: this.system.CustomerGUID,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    var customerData = response.data.Customer;
                    this.profile = response.data.Customer;

                    this.updateForm.CustomerId = this.system.CustomerId;
                    this.updateForm.CustomerGUID = this.system.CustomerGUID;

                    this.updateForm.Name.FirstName =
                        customerData.Name.FirstName;
                    this.updateForm.Name.LastName = customerData.Name.LastName;
                    this.updateForm.EmailAddress = customerData.EmailAddress;
                    customerData.DateOfBirth != ""
                        ? (this.updateForm.DateOfBirth = new Date(
                              customerData.DateOfBirth
                          ))
                        : null;

                    this.updateForm.Address[0].Address1 =
                        customerData.Address[0].Address1;
                    this.updateForm.Address[0].Address2 =
                        customerData.Address[0].Address2;
                    this.updateForm.Address[0].City =
                        customerData.Address[0].City;
                    this.updateForm.Address[0].PostCode =
                        customerData.Address[0].PostCode;
                    this.updateForm.Address[0].Country =
                        customerData.Address[0].Country;

                    this.updateForm.Phone[0].PhoneNumber =
                        customerData.Phone[0].PhoneNumber;

                    var mobileIndex = this.findMobilePhoneNumber(
                        customerData.Phone
                    );

                    this.updateForm.Phone[1].PhoneNumber =
                        customerData.Phone[mobileIndex].PhoneNumber;

                    this.updateForm.NoEmail =
                        customerData.NoEmail == "N" ? true : false;
                } else {
                    console.log(response.data);
                }
            });
        },

        findMobilePhoneNumber(phoneNumbers) {
            for (let i = 0; i < phoneNumbers.length; i++) {
                if (phoneNumbers[i].type === "MOBILE") {
                    return i;
                }
            }
            return null;
        },

        login() {
            this.loading = true;
            var request = {
                data: {
                    EmailAddress: this.email,
                    Password: this.password,
                    FolioId: null,
                    LastName: null,
                },
            };
            axios.post("/rs/login", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    Bus.$emit("RefreshSystem");
                    $("#loginModal").modal("hide");
                    this.checkoutStep();
                    this.addContactLog(
                        response.data.CustomerId,
                        response.data.CustomerGUID
                    );
                } else {
                    this.error = response.data.Result.Text;
                }
                this.loading = null;
            });
        },

        update() {
            this.updating = true;
            this.success = null;
            var updateData = Object.assign({}, this.updateForm);

            updateData.CustomerId = this.system.CustomerId;
            updateData.CustomerGUID = this.system.CustomerGUID;
            updateData.NoEmail = this.updateForm.NoEmail ? "N" : "Y";
            if (this.updateForm.DateOfBirth) {
                updateData.DateOfBirth = moment(updateData.DateOfBirth).format(
                    "YYYY-MM-DD"
                );
            }

            updateData.Address[0].Address1 = this.$refs.Address1.value;
            updateData.Address[0].Address2 = this.$refs.Address2.value;
            updateData.Address[0].City = this.$refs.City.value;
            updateData.Address[0].PostCode = this.$refs.PostCode.value;
            updateData.Address[0].Country = this.$refs.Country.value;

            updateData.Phone[0].PhoneNumber = this.$refs.Tel.value;

            var request = {
                method: "UpdateCustomerProfile",
                data: {
                    Customer: updateData,
                    ContactLogs: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    Bus.$emit("RefreshSystem");
                    this.getProfile();
                    this.success = true;
                } else {
                    this.error = response.data.Result.text;
                }
                this.updating = false;
            });
        },

        forgotPassword() {
            $("#loginModal").modal("hide");
            this.$router.push("/book/account/request-password-reset");
        },

        updatePassword() {
            this.updatingPassword = true;
            this.successPassword = null;
            var updateData = Object.assign({}, this.passwordForm);

            updateData.CustomerId = this.system.CustomerId;
            updateData.CustomerGUID = this.system.CustomerGUID;

            var request = {
                method: "UpdateCustomerProfile",
                data: {
                    Customer: updateData,
                    ContactLogs: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    Bus.$emit("RefreshSystem");
                    this.getProfile();
                    this.successPassword = true;
                    this.matchPassword = null;
                    this.passwordForm = new updatePasswordForm();
                } else {
                    this.error = response.data.Result.text;
                }
                this.updatingPassword = false;
            });
        },

        clearError() {
            this.error = null;
        },
    },
};
</script>

<style>
.autocomplete {
    width: 92%;
    padding: 5px;
    border-radius: 0.25rem;
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    --border-opacity: 1;
    border-color: rgb(209 213 219 / var(--border-opacity));
    position: absolute;
    z-index: 10;
    font-size: 14px;
}

.autocomplete > :not([hidden]) ~ :not([hidden]) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse));
}

.autocomplete .amount {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(229 231 235);
    list-style: none;
}

.autocomplete .list-item {
    cursor: pointer;
    padding: 0.25rem;
    list-style: none;
}

.autocomplete .list-item:hover {
    background-color: #f5f5f5;
}
</style>
