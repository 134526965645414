var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-register" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoginForm,
            expression: "showLoginForm",
          },
        ],
        staticClass: "login-section",
      },
      [
        _c("h4", [_vm._v("Login to your account...")]),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { id: "cart-login" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.login.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "cart-login-body" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 col-form-label col-form-label-sm",
                    attrs: { for: "customerEmail" },
                  },
                  [_vm._v("Email")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "email",
                      id: "customerEmail",
                      "aria-describedby": "emailHelp",
                      placeholder: "Enter email",
                      required: "",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      change: _vm.clearError,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 col-form-label col-form-label-sm",
                    attrs: { for: "customerPassword" },
                  },
                  [_vm._v("Password")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "password",
                      id: "customerPassword",
                      "aria-describedby": "passwordlHelp",
                      placeholder: "Enter password",
                      required: "",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      change: _vm.clearError,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group clearfix" }, [
                _c(
                  "a",
                  {
                    staticClass: "step-back float-right",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.forgotPassword.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Forgot password")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group clearfix" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-block",
                    attrs: { type: "submit" },
                  },
                  [
                    _vm._v("Login "),
                    _vm.loading
                      ? _c("span", [
                          _c("div", {
                            staticClass: "spinner-border spinner-border-sm",
                            attrs: { role: "status" },
                          }),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("span", { staticClass: "validation" }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
              ]),
              _vm._v(" "),
              _vm.showPasswordReset
                ? _c("div", { staticClass: "form-group" }, [
                    _vm.resetStatus
                      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.resetStatus))])])
                      : _c("div", [
                          _c("p", [
                            _vm._v(
                              "Would you like to reset your password for the email address " +
                                _vm._s(_vm.email) +
                                "?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm",
                              attrs: { disabled: _vm.resettingPassword },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.resetPassword.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v("Send me a new password "),
                              _vm.resettingPassword
                                ? _c("span", {
                                    staticClass:
                                      "spinner-border spinner-border-sm",
                                    attrs: { role: "status" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelPassword.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRegisterForm,
            expression: "showRegisterForm",
          },
        ],
        staticClass: "register-section",
      },
      [
        _c("h4", [_vm._v("Let's create your account...")]),
        _vm._v(" "),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPersonalForm,
                expression: "showPersonalForm",
              },
            ],
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.personalForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "personal-section" }, [
              _c("h5", [_vm._v("Personal details")]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "firstName" },
                  },
                  [_vm._v("First name*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerForm.Name.FirstName,
                        expression: "registerForm.Name.FirstName",
                      },
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "firstName",
                      placeholder: "First name",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Name.FirstName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.registerForm.Name,
                          "FirstName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "surname" },
                  },
                  [_vm._v("Surname*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerForm.Name.LastName,
                        expression: "registerForm.Name.LastName",
                      },
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "surname",
                      placeholder: "Surname",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Name.LastName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.registerForm.Name,
                          "LastName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "Tel" },
                  },
                  [_vm._v("Telephone*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerForm.Phone[0].PhoneNumber,
                        expression: "registerForm.Phone[0].PhoneNumber",
                      },
                    ],
                    ref: "Tel",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "tel",
                      pattern: "^[0-9-+\\s()]*$",
                      id: "Tel",
                      name: "Tel",
                      placeholder: "Telephone number",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Phone[0].PhoneNumber },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.registerForm.Phone[0],
                          "PhoneNumber",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "Mob" },
                  },
                  [_vm._v("Mobile*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerForm.Phone[1].PhoneNumber,
                        expression: "registerForm.Phone[1].PhoneNumber",
                      },
                    ],
                    ref: "Mob",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "tel",
                      pattern: "^0([1-6][0-9]{8,10}|7[0-9]{9})$",
                      id: "Mob",
                      name: "Mob",
                      placeholder: "Mobile number",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Phone[1].PhoneNumber },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.registerForm.Phone[1],
                          "PhoneNumber",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "newEmail" },
                  },
                  [_vm._v("Email*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerForm.EmailAddress,
                        expression: "registerForm.EmailAddress",
                      },
                    ],
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "email",
                      id: "newEmail",
                      placeholder: "Enter email",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.EmailAddress },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.registerForm,
                          "EmailAddress",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "newPassword" },
                  },
                  [_vm._v("Password*")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-9" },
                  [
                    _c("password-checker", {
                      attrs: {
                        password: _vm.registerForm.Password,
                        id: "newPassword",
                        placeholder: "Enter password",
                        minlength: 8,
                        required: true,
                        "min-password-strength":
                          this.$root.system.MinPasswordStrength,
                        "is-password-valid": _vm.passwordValid,
                      },
                      on: {
                        "update:password": function ($event) {
                          return _vm.$set(_vm.registerForm, "Password", $event)
                        },
                        "update:isPasswordValid": function ($event) {
                          _vm.passwordValid = $event
                        },
                        "update:is-password-valid": function ($event) {
                          _vm.passwordValid = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.emailExists
                ? _c("div", { staticClass: "form-group" }, [
                    _c("p", { staticClass: "validation" }, [
                      _vm._v(_vm._s(_vm.emailExists)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.switchToLogin()
                          },
                        },
                      },
                      [_vm._v("Login")]
                    ),
                  ])
                : _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          disabled: _vm.checkingEmail || !_vm.passwordValid,
                          type: "submit",
                        },
                      },
                      [
                        _vm._v("Next "),
                        _vm.checkingEmail
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status" },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAddressForm,
                expression: "showAddressForm",
              },
            ],
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.addressForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "address-section" }, [
              _c("h5", [
                _vm._v("Your address "),
                _c(
                  "a",
                  {
                    staticClass: "step-back",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.backPersonal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Back a step")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "Address1" },
                  },
                  [_vm._v("Address*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registerForm.Address[0].Address1,
                        expression: "registerForm.Address[0].Address1",
                      },
                    ],
                    ref: "Address1",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "Address1",
                      name: "Address1",
                      placeholder: "(start typing to search)",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Address[0].Address1 },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.registerForm.Address[0],
                            "Address1",
                            $event.target.value
                          )
                        },
                        _vm.debouncedFetchAddressLookup,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _vm.addressResults.length
                    ? _c(
                        "ul",
                        { staticClass: "autocomplete" },
                        [
                          _c("li", { staticClass: "amount" }, [
                            _vm._v(
                              "\n                                Showing " +
                                _vm._s(_vm.addressResults.length) +
                                " results\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.addressResults, function (address, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "list-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectAddress(address)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(address.address_text) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "Address2" },
                  },
                  [_vm._v("Address 2")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    ref: "Address2",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "Address2",
                      name: "Address2",
                      placeholder: "Address line 2",
                    },
                    domProps: { value: _vm.registerForm.Address[0].Address2 },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "City" },
                  },
                  [_vm._v("City*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    ref: "City",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "City",
                      name: "City",
                      placeholder: "City",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Address[0].City },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "PostCode" },
                  },
                  [_vm._v("Post Code*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    ref: "PostCode",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "PostCode",
                      name: "PostCode",
                      placeholder: "Post Code",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Address[0].PostCode },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label col-form-label-sm",
                    attrs: { for: "Country" },
                  },
                  [_vm._v("Country*")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    ref: "Country",
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      type: "text",
                      id: "Country",
                      name: "Country",
                      placeholder: "Country",
                      required: "",
                    },
                    domProps: { value: _vm.registerForm.Address[0].Country },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMarketingForm,
                expression: "showMarketingForm",
              },
            ],
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.register.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "marketing-section" }, [
              _c("h5", [
                _vm._v("Keep in touch "),
                _c(
                  "a",
                  {
                    staticClass: "step-back",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.backAddress.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Back a step")]
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Let us know the month and year you were born, so we can send you a birthday treat!"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.monthOfBirth,
                          expression: "monthOfBirth",
                        },
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { name: "monthOfBirth", id: "monthOfBirth" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.monthOfBirth = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Select month of birth"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "01" } }, [
                        _vm._v("January"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "02" } }, [
                        _vm._v("February"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "03" } }, [
                        _vm._v("March"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "04" } }, [
                        _vm._v("April"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "05" } }, [_vm._v("May")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "06" } }, [
                        _vm._v("June"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "07" } }, [
                        _vm._v("July"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "08" } }, [
                        _vm._v("August"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "09" } }, [
                        _vm._v("September"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "10" } }, [
                        _vm._v("October"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "11" } }, [
                        _vm._v("November"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "12" } }, [
                        _vm._v("December"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.yearOfBirth,
                          expression: "yearOfBirth",
                        },
                      ],
                      staticClass: "form-control form-control-sm",
                      attrs: { name: "yearOfBirth", id: "yearOfBirth" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.yearOfBirth = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Select year of birth"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.years, function (y) {
                        return _c("option", { domProps: { value: y } }, [
                          _vm._v(_vm._s(y)),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-check clearfix" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-check-label float-left",
                    attrs: { for: "NoEmail" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.registerForm.NoEmail,
                          expression: "registerForm.NoEmail",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { type: "checkbox", id: "NoEmail" },
                      domProps: {
                        checked: Array.isArray(_vm.registerForm.NoEmail)
                          ? _vm._i(_vm.registerForm.NoEmail, null) > -1
                          : _vm.registerForm.NoEmail,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.registerForm.NoEmail,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.registerForm,
                                  "NoEmail",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.registerForm,
                                  "NoEmail",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.registerForm, "NoEmail", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(
                      "\n                        Tick to receive the latest news, events and exclusive offers.\n                    "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col" }, [
                  _vm.loading
                    ? _c("span", [
                        _c("div", {
                          staticClass: "spinner-border spinner-border-sm",
                          attrs: { role: "status" },
                        }),
                        _vm._v(" Loading..."),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "validation" }, [
                    _vm._v(_vm._s(_vm.error)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cart-register-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit", disabled: _vm.loading },
                  },
                  [_vm._v("Create my account")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary btn-sm", attrs: { type: "submit" } },
        [_vm._v("Next")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }