<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div id="treatment-class-tabs">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-treatments-tab" data-toggle="tab" href="#nav-treatments" role="tab" aria-controls="nav-treatments" aria-selected="true">Treatments</a>
                            <a class="nav-item nav-link" id="nav-classes-tab" data-toggle="tab" href="#nav-classes" role="tab" aria-controls="nav-classes" aria-selected="false">Classes</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-treatments" role="tabpanel" aria-labelledby="nav-treatments-tab">
                            <div v-if="allTreatments.length">
                                <transition-group tag="ul" name="fade-out-in">
                                    <li v-for="treatment in allTreatments"  v-bind:key="treatment.id" class="alt-bg">
                                        <h5 class="treatment-name">{{ treatment.name }} 
                                            <div class="treatment-price">
                                                <span>{{ treatment.service_time }} mins</span> 
                                                <span>&pound;{{ treatment.price }}</span>
                                            </div>
                                        </h5>
                                        <div v-if="treatment.times" class="times-container">
                                            <span v-for="time in treatment.times" class="time-item">{{ time | nicetime }}</span>
                                        </div>
                                    </li>
                                </transition-group>
                                <div class="availability-cta">
                                    To book your treatment today, please visit our spa reception
                                </div>
                            </div>
                            <div v-else class="flex-container align-items-center">
                                <div class="flex-item text-center">
                                    <div v-if="noAvailability">
                                        <h3>Sorry, no availability today</h3>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <div class="spinner-grow spinner-grow-md" role="status"></div>
                                            Loading treatments...
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-classes" role="tabpanel" aria-labelledby="nav-classes-tab">
                            <div v-if="classes">
                                <transition-group tag="ul" name="fade-out-in">
                                    <li v-for="c in classes"  v-bind:key="c.id" class="alt-bg">
                                        <h5 class="treatment-name">{{ c.name }} 
                                            <div class="treatment-price">
                                                <span>{{ c.room }}</span>
                                                <span>{{ c.time }}</span>
                                                <span>{{ c.duration }} mins</span> 
                                            </div>
                                        </h5>
                                    </li>
                                </transition-group>
                                <div class="availability-cta">
                                    To book your class today, please visit our gym reception
                                </div>
                            </div>
                            <div v-else class="flex-container align-items-center">
                                <div class="flex-item text-center">
                                    <div v-if="noClassAvailability">
                                        <h3>Sorry, no classes remaining today</h3>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <div class="spinner-grow spinner-grow-md" role="status"></div>
                                            Loading classes...
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            location: Number,
            day: Number,
        },

        components: {
            
        },

        data() {
            return {
                allTreatments: [],
                noAvailability: false,
                loadingAvailability: false,
                noClassAvailability: false,
                loadingClasses: false,
                classes: null,
            }
        },
        
        mounted() {
            this.fetchTreatments()
            this.fetchClasses()
        },

        watch: {
            
        },

        computed: {
            
        },

        methods: {
            fetchTreatments(loc) {
                var self = this
                self.loadingAvailability = true,
                
                axios.get('/api/treatments/availability')
                    .then(function (response) {
                        if(response.data.length) {
                            var date = self.day == 0 ? moment().format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD')
                            var data = _.filter(response.data, { 'venue': self.location.toString(), 'date': date })
                            _.each(data, function(o) {
                                if(self.day == 0) {
                                    _.remove(o.times, function(t) { return moment(t) <= moment()})
                                }
                                if(o.times.length) {
                                    self.allTreatments.push(o)
                                }
                            })

                            if(self.allTreatments.length) {
                                self.noAvailability = false
                            } else {
                                self.noAvailability = true
                            }
                            
                        } else {
                            self.noAvailability = true
                        }
                    })
            },
            fetchClasses() {
                var self = this
                this.loadingClasses = true
                
                axios.get('/api/classes/availability')
                    .then(function (response) {
 
                        if(response.data.length) {
                            var date = self.day == 0 ? moment().format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD')
                            
                            var venue = self.location == 100 ? 200 : self.location

                            var allClasses = _.filter(response.data, { 'venue': venue.toString(), 'date': date })

                            // Remove earlier times
                            _.remove(allClasses, function(t) { return moment(t.date + ' ' + t.time + ':00') <= moment()})

                            if(allClasses.length) {
                                self.classes = allClasses
                                self.noClassAvailability = false
                            } else {
                                self.noClassAvailability = true
                            }
                            
                        } else {
                            self.noClassAvailability = true
                        }
                    })
            }
        }
    }
</script>
