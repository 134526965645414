var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.system.isLoggedIn
      ? _c(
          "div",
          [
            _c("header", [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "members-logo" }, [
                      _vm.venue == "eden-hall"
                        ? _c(
                            "a",
                            { attrs: { href: "/eden-hall/members-portal" } },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  loading: "lazy",
                                  src: "/img/eh_members.svg",
                                  alt: "Eden Hall Members Logo",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: "/hoar-cross-hall/members-portal",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  loading: "lazy",
                                  src: "/img/hch_members.svg",
                                  alt: "Hoar Cross Hall Members Logo",
                                },
                              }),
                            ]
                          ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.sections, function (section) {
              return [_c("div", { domProps: { innerHTML: _vm._s(section) } })]
            }),
          ],
          2
        )
      : _c(
          "div",
          [
            _c("portal-login", {
              attrs: { system: _vm.system, cart: _vm.cart, venue: "venue" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-10 align-self-end" }, [
      _c("nav", [
        _c("ul", [
          _c("li", [
            _c("a", { attrs: { href: "" } }, [_vm._v("Book Classes")]),
          ]),
          _vm._v(" "),
          _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("My Profile")])]),
          _vm._v(" "),
          _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Account")])]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }