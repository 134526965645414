<template>
    <form @submit.prevent="enquire">
        <div class="form-group row">
            <div class="col-md-6">
                <input v-model="formData.firstName" ref="firstName" type="text" placeholder="First name" class="form-control" required />
            </div>
            <div class="col-md-6">
                <input v-model="formData.lastName" ref="lastName" type="text" placeholder="Last name" class="form-control" required />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input v-model="formData.tel" ref="tel" type="tel" placeholder="Mobile (optional)" class="form-control" />
            </div>
            <div class="col-md-6">
                <input v-model="formData.emailAddress" ref="emailAddress" type="email" placeholder="Email" class="form-control" required />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input v-model="formData.landline" ref="landline" type="tel" placeholder="Landline (optional)" class="form-control" />
            </div>
        </div>

        <div class="form-group row mt-5">
            <div class="col-md-12">
                <p class="mb-2">Select any apprenticeships you are interested in.</p>
                <div v-for="apprenticeship, key in apprenticeships" class="form-check form-check-inline apprentice-list">
                    <label class="form-check-label" :for="key">
                        <input v-model="formData.apprenticeships[key]" type="checkbox" class="form-check-input" :id="key">
                        <span class="checkmark"></span>
                        {{ apprenticeship }}
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col-md-9">
                <label for="cvUpload">Send us your CV<br><span class="small">Permitted file types: PDF, Word. Max file size 2mb.</span></label>
                <input type="file" @change="onFileChange" class="form-control-file btn btn-secondary" id="cvUpload" ref="cvUpload">
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-12 mt-2">
                <textarea v-model="formData.message" placeholder="Message (optional)" rows="5" class="form-control"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-8 mb-2">
                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="email_opt_in">
                        <input v-model="formData.email_opt_in" type="checkbox" class="form-check-input" id="email_opt_in">
                        <span class="checkmark"></span>
                        {{ consent_message }}
                    </label>
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="sending" class="spinner-border text-light" role="status"></div>
                <button type="submit" :disabled="sending" class="btn btn-reversed float-right">Submit</button>
            </div>
        </div>
        <div class="error-list" v-if="error">
            <h6>Oops, something went wrong...</h6>
            <ul>
                <li v-for="e in error.file">{{ e }}</li>
            </ul>
        </div>
        <p class="signup-message" v-if="success">Thank you for your enquiry. We will be in touch with you shortly.</p>
    </form>
</template>

<script>
    var formTemplate = function() {
        return {
            firstName: null,
            lastName: null,
            tel: null,
            landline: null,
            emailAddress: null,
            message: null,
            email_opt_in: false,
            apprenticeships: {
                "l2cc": false,
                "l2csp": false,
                "l2htmfb": false,
                "l2htmhk": false,
                "l2htmr": false,
                "l3ba": false,
                "l3css": false,
                "l3dm": false,
                "l3hsfb": false,
                "l3hsfo": false,
                "l3hsh": false,
                "l3hrs": false,
                "l3ldm": false,
                "l3spc": false,
                "l3tls": false,
                "l4apm": false,
                "l4hmfb": false,
                "l4hmk": false,
                "l5hrcp": false,
                "l5ldc": false,
                "l5odm": false,
                "l5pc": false
            }
        }
    }
    export default {
        props: {
            email: String,
            ga: String,
            venue: String,
            revinate_id: String,
            consent_message: String
        },
        data() {
            return {
                formData: new formTemplate(),
                success: false,
                error: null,
                sending: false,
                apprenticeships: {
                    "l2cc": "Level 2 - Commis Chef",
                    "l2csp": "Level 2 - Customer Service Practioner",
                    "l2htmfb": "Level 2 - Hospitality Team Member - Food & Beverage",
                    "l2htmhk": "Level 2 - Hospitality Team Member - Housekeeping",
                    "l2htmr": "Level 2 - Hospitality Team Member - Reception",
                    "l3ba": "Level 3 - Business Administration",
                    "l3css": "Level 3 - Customer Service Specialist",
                    "l3dm": "Level 3 - Digital Marketer",
                    "l3hsfb": "Level 3 - Hospitality Supervisor - Food & Beverage",
                    "l3hsfo": "Level 3 - Hospitality Supervisor - Front Office",
                    "l3hsh": "Level 3 - Hospitality Supervisor - Housekeeping",
                    "l3hrs": "Level 3 - HR Support",
                    "l3ldm": "Level 3 - Leisure Duty Manager",
                    "l3spc": "Level 3 - Senior Production Chef",
                    "l3tls": "Level 3 - Team Leader/Supervisor",
                    "l4apm": "Level 4 - Associate Project Manager",
                    "l4hmfb": "Level 4 - Hospitality Manager - Food & Beverage",
                    "l4hmk": "Level 4 - Hospitality Manager - Kitchen",
                    "l5hrcp": "Level 5 - HR Consultant Partner",
                    "l5ldc": "Level 5 - Learning & Development Consultant",
                    "l5odm": "Level 5 - Operations/Departmental Manager",
                    "l5pc": "Level 5 - Professional Coaching"
                },
                cvUpload: null
            }
        },

        mounted() {
            
        },

        methods: {
            onFileChange() {
                this.cvUpload = this.$refs.cvUpload.files[0];
            },
            
            enquire() {
                var self = this

                self.sending = true

                // populate form data, even from autocomplete
                self.formData.firstName = this.$refs.firstName.value
                self.formData.lastName = this.$refs.lastName.value
                self.formData.emailAddress = this.$refs.emailAddress.value
                self.formData.tel = this.$refs.tel.value
                self.formData.landline = this.$refs.landline.value
                self.formData.revinate_id = this.revinate_id

                var formObject = new FormData()
                
                // File stuff
                if(self.cvUpload) {
                    formObject.append('file', self.cvUpload)
                } else {
                    formObject.append('file', null)
                }
                
                var json = JSON.stringify(self.formData)

                formObject.append('data', json)
                formObject.append('email', self.email)

                
                window.getRecaptchaToken()
                    .then(function(r) {
                        formObject.append('recaptcha', r)

                        axios.post('/system/subscribe/apprenticeships', formObject)
                            .then(response=> {
                                
                                if(response.data.status == 'error') {
                                    self.error = response.data.errors
                                    self.sending = false
                                } else {
                                    self.success = true
                                    self.error = null
                                    self.sending = false

                                    dataLayer.push({
                                        event: 'form_submission',
                                        form_type: 'lead_form',
                                        form_subject: 'Apprenticeship Enquiry',
                                        venue: self.venue,
                                    });
                                    
                                    self.formData = new formTemplate()
                                    self.cvUpload = null
                                }
                                    
                            })
                    })
            },
        }
    }
</script>
