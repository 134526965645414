<template>
    <GmapMap
        :center="center"
         :zoom="10"
         style="width: 100%; height: 300px"
        :options="options"
        >
        <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="m.icon"
            :clickable="true"
            :draggable="true"
            @click="center=m.position"
        />
    </GmapMap>
</template>

<script>
    var mapOptions = {
        "clickableIcons": true,
        "disableDoubleClickZoom": false,
        "draggable": true,
        "fullscreenControl": false,
        "keyboardShortcuts": true,
        "mapMaker": false,
        "mapTypeControl": false,
        "mapTypeControlOptions": {
            "text": "Default (depends on viewport size etc.)",
            "style": 0
        },
        "mapTypeId": "roadmap",
        "rotateControl": true,
        "scaleControl": true,
        "scrollwheel": false,
        "streetViewControl": false,
        "styles": [{
            "featureType": "water",
            "stylers": [{
            "visibility": "on"
            }, {
            "color": "#b5cbe4"
            }]
        }, {
            "featureType": "landscape",
            "stylers": [{
            "color": "#efefef"
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
            "color": "#83a5b0"
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{
            "color": "#bdcdd3"
            }]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{
            "color": "#ffffff"
            }]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
            "color": "#e3eed3"
            }]
        }, {
            "featureType": "administrative",
            "stylers": [{
            "visibility": "on"
            }, {
            "lightness": 33
            }]
        }, {
            "featureType": "road"
        }, {
            "featureType": "poi.park",
            "elementType": "labels",
            "stylers": [{
            "visibility": "on"
            }, {
            "lightness": 20
            }]
        }, {}, {
            "featureType": "road",
            "stylers": [{
            "lightness": 20
            }]
        }],
        "zoom": 10,
        "zoomControl": true
    };
    import * as VueGoogleMaps from 'vue2-google-maps'
    
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDbT5LW7F19Ynrs9gGvWk3PoAzsW-xCJcQ',
            libraries: 'marker'
        }
    })
    export default {
        props: {
            location: Object
        },
        data() {
            return {
                center: {
                    lat: parseFloat(this.location.lat),
                    lng: parseFloat(this.location.lng)
                },
                options: mapOptions,
                markers: [
                    {
                        position: {
                            lat: parseFloat(this.location.lat),
                            lng: parseFloat(this.location.lng)
                        },
                        icon: {
                            url: '/img/map-pin.svg',
                            scaledSize: {width: 50, height: 50}
                        }
                    }
                ]
            }
        },
        mounted() {
            
        },

        methods: {
            
        }
    }
</script>
