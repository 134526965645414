<template>
    <div>
        <header>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-2">
                        <div class="members-logo text-center">
                            <a
                                v-if="venue == 'eden-hall'"
                                href="/eden-hall/members-portal"
                                ><img
                                    loading="lazy"
                                    src="/img/eh_members.svg"
                                    alt="Eden Hall Members Logo"
                                    class="img-fluid"
                            /></a>
                            <a v-else href="/hoar-cross-hall/members-portal"
                                ><img
                                    loading="lazy"
                                    src="/img/hch_members.svg"
                                    alt="Hoar Cross Hall Members Logo"
                                    class="img-fluid"
                            /></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="portal-login">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <h3 class="text-center">Please log in</h3>
                        <form @submit.prevent="login">
                            <div class="portal-login-body">
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label for="portalEmail"
                                            >Your email address</label
                                        >
                                        <input
                                            @change="clearError"
                                            v-model="email"
                                            type="email"
                                            class="form-control"
                                            id="portalEmail"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter email"
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label for="portalPassword"
                                            >Your password</label
                                        >
                                        <input
                                            @change="clearError"
                                            v-model="password"
                                            type="password"
                                            class="form-control"
                                            id="portalPassword"
                                            aria-describedby="passwordlHelp"
                                            placeholder="Enter password"
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col">
                                        <a
                                            @click.prevent="forgotPassword"
                                            class="small-text"
                                            >Forgot password</a
                                        >
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col">
                                        <span v-if="loading"
                                            ><div
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                            ></div>
                                            Loading...</span
                                        >
                                        <span class="validation">{{
                                            loginError
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button type="submit" class="btn btn-primary">
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        system: Object,
        cart: Object,
        venue: String,
    },

    components: {},

    data() {
        return {
            email: null,
            password: null,
            loginError: null,
            loading: false,
        };
    },

    mounted() {
        console.log("Portal Login Component mounted");
    },

    watch: {},

    methods: {
        login() {
            this.loading = true;
            var request = {
                method: "AuthCustomer",
                data: {
                    EmailAddress: this.email,
                    Password: this.password,
                    FolioId: null,
                    LastName: null,
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    var request = {
                        method: "UpdateSession",
                        data: {
                            SessionId: this.system.SessionId,
                            WebFolioId: this.system.WebFolioId,
                            CustomerId: response.data.CustomerId,
                            BookingAgentId: null,
                        },
                    };
                    axios.post("/rs/request", request).then((response) => {
                        if (response.data.Result.value === "SUCCESS") {
                            console.log("session updated");
                        } else {
                        }
                    });

                    Bus.$emit("RefreshSystem");
                } else {
                    this.loginError = response.data.Result.Text;
                }
                this.loading = null;
            });
        },
        clearError() {
            this.loginError = null;
        },
        forgotPassword() {
            $("#forgotPasswordModal").modal("show");
        },
    },
};
</script>
