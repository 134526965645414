var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("h3", { staticClass: "mobile-text-center mb-3" }, [
          _c("span", { staticClass: "mr-3 d-block d-md-inline mb-3" }, [
            _vm._v("Hungry for more?"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "/" + _vm.venue + "/members-portal/book-classes" },
            },
            [_vm._v("Browse Classes")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _vm.bookings.length
          ? _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-members" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.bookings, function (day) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(day.ItemName) +
                            "\n                                "
                        ),
                        _c("div", { staticClass: "d-md-none members-mobile" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("rsnicecompactdate")(day.BookStartTime)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("classtime")(day.BookStartTime)) +
                                " - " +
                                _vm._s(_vm._f("classtime")(day.BookEndTime))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(day.Room))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "d-none d-md-table-cell" }, [
                        _vm._v(
                          _vm._s(_vm._f("rsnicecompactdate")(day.BookStartTime))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "d-none d-md-table-cell" }, [
                        _vm._v(
                          _vm._s(_vm._f("classtime")(day.BookStartTime)) +
                            " - " +
                            _vm._s(_vm._f("classtime")(day.BookEndTime))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "d-none d-md-table-cell" }, [
                        _vm._v(_vm._s(day.Room)),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-sm mb-0",
                            attrs: { disabled: _vm.removingBooking },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.confirmCancel(day)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    Cancel\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ])
          : _vm.loadingBookings
          ? _c("div", [
              _vm._v("\n                Loading bookings...\n            "),
            ])
          : _c("div", [
              _c("p", [_vm._v("You do not have any active bookings.")]),
            ]),
        _vm._v(" "),
        _vm.cancelError
          ? _c("div", { staticClass: "mt-4" }, [
              _c("p", [_vm._v(_vm._s(_vm.cancelError))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm.bookingsError
          ? _c("p", { staticClass: "paymentError" }, [
              _vm._v(_vm._s(_vm.bookingsError)),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "cancelClassModal",
          tabindex: "-1",
          "aria-labelledby": "cancelClassModal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c("p", [
                _vm._v("Are you sure you wish to cancel the following class:"),
              ]),
              _vm._v(" "),
              _vm.classToCancel
                ? _c("div", [
                    _c("h4", [_vm._v(_vm._s(_vm.classToCancel.ItemName))]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm._f("rsshortdate")(_vm.classToCancel.BookStartTime)
                        ) +
                          ".  " +
                          _vm._s(
                            _vm._f("rstime")(_vm.classToCancel.BookStartTime)
                          ) +
                          " - " +
                          _vm._s(
                            _vm._f("rstime")(_vm.classToCancel.BookEndTime)
                          )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cancelClass(_vm.classToCancel)
                    },
                  },
                },
                [_vm._v("Cancel class")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 mb-3" }, [
      _c("h3", { staticClass: "brandHeading text-center mb-5" }, [
        _vm._v("My Bookings"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", [_vm._v("Class")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [
          _vm._v("Location"),
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Need to cancel?")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-4" }, [
      _c("p", [
        _vm._v("* Please give a minimum of 4 hours when cancelling a class."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }