<template>
    <form @submit.prevent="enquire">
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.firstName"
                    ref="firstName"
                    type="text"
                    placeholder="First Name*"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.lastName"
                    ref="lastName"
                    type="text"
                    placeholder="Last Name*"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.emailAddress"
                    ref="emailAddress"
                    type="email"
                    placeholder="Email*"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.tel"
                    ref="tel"
                    type="tel"
                    placeholder="Telephone"
                    class="form-control"
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.dob"
                    ref="dob"
                    type="text"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                    placeholder="Date of birth"
                    class="form-control"
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 mb-2">
                <div v-html="consent_message" class="small"></div>
                <div>
                    <button type="submit" class="btn btn-black">Sign up</button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6 mb-2">
                <p class="signup-message" v-if="success">
                    Thank you for subscribing and welcome to the family.
                </p>
            </div>
        </div>
    </form>
</template>

<script>
var formTemplate = function () {
    return {
        firstName: null,
        lastName: null,
        emailAddress: null,
        tel: null,
        dob: null,
    };
};
export default {
    props: {
        venue: String,
        revinate_id: String,
        ga: String,
        consent_message: String,
    },
    data() {
        return {
            formData: new formTemplate(),
            success: null,
        };
    },

    mounted() {},

    methods: {
        enquire() {
            var self = this;

            // populate form data, even from autocomplete
            self.formData.firstName = this.$refs.firstName.value;
            self.formData.lastName = this.$refs.lastName.value;
            self.formData.emailAddress = this.$refs.emailAddress.value;
            self.formData.tel = this.$refs.tel.value;
            self.formData.dob = this.$refs.dob.value;
            self.formData.revinate_id = this.revinate_id;

            window.getRecaptchaToken().then(function (r) {
                var data = {
                    venue: self.venue,
                    formData: self.formData,
                    recaptcha: r,
                };
                axios
                    .post("/system/subscribe/marketing-subscribe", data)
                    .then((response) => {
                        self.success = true;

                        dataLayer.push({
                            event: "form_submission",
                            form_type: "lead-Subscription",
                            form_subject: self.ga,
                            venue: self.venue,
                        });

                        self.formData = new formTemplate();

                        self.fbSubscribe();
                    });
            });
        },
        fbSubscribe() {
            if ("fbq" in window) {
                window.fbq("track", "Lead");

                if (this.venue == "Eden Hall") {
                    window.fbq("trackSingle", "793705824113721", "Subscribe", {
                        currency: "GBP",
                        value: "1.00",
                    });
                } else if (this.location == "Barons Eden") {
                    window.fbq("trackSingle", "310520373072321", "Subscribe", {
                        currency: "GBP",
                        value: "1.00",
                    });
                } else {
                    window.fbq("trackSingle", "1755487601148568", "Subscribe", {
                        currency: "GBP",
                        value: "1.00",
                    });
                    window.fbq("trackSingle", "190765588075297", "Subscribe", {
                        currency: "GBP",
                        value: "1.00",
                    });
                }
            }
        },
    },
};
</script>
