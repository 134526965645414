var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "portal-header-cart" }, [
    _vm.cart.WebFolioItem
      ? _c("a", { attrs: { href: "/" + _vm.venue + "/members-portal/cart" } }, [
          _vm.cart.WebFolioItem
            ? _c("span", { staticClass: "badge badge-light" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.cart.WebFolioItem.length) +
                    " \n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cart.FolioBalance != "0.00"
            ? _c("span", [_vm._v("£" + _vm._s(_vm.cart.FolioBalance))])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hello
      ? _c("span", [
          _vm._v("\n        Hi " + _vm._s(_vm.hello) + ", "),
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.logout.apply(null, arguments)
                },
              },
            },
            [_vm._v("Logout")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }