<template>
    <div>
        <form @submit.prevent="subscribe" class="form-inline">
            <input
                v-model="emailAddress"
                type="email"
                placeholder="enter email*"
                class="form-control"
                required
            />
            <input
                v-if="location == 'Hoar Cross Hall'"
                type="image"
                value="submit"
                src="/img/best-price/hch-arrow.svg"
                alt="submit Button"
                class="brand-overlay"
            />
            <input
                v-else-if="location == 'Eden Hall'"
                type="image"
                value="submit"
                src="/img/best-price/eh-arrow.svg"
                alt="submit Button"
                class="brand-overlay"
            />
            <input
                v-else
                type="image"
                value="submit"
                src="/img/best-price/hch-arrow.svg"
                alt="submit Button"
                class="brand-overlay"
            />
        </form>
        <p class="signup-message" v-if="success">Thanks for signing up!</p>
    </div>
</template>

<script>
export default {
    props: {
        location: String,
    },

    data() {
        return {
            emailAddress: null,
            success: null,
        };
    },

    mounted() {},

    methods: {
        subscribe() {
            var self = this;
            window.getRecaptchaToken().then(function (r) {
                var data = {
                    email: self.emailAddress,
                    recaptcha: r,
                };
                axios.post("/system/subscribe", data).then((response) => {
                    self.success = true;
                    self.emailAddress = null;

                    dataLayer.push({
                        event: "form_submission",
                        form_type: "lead_subscription",
                        form_subject: "Website Subscribe",
                        venue: self.location,
                    });

                    this.$emitAnalytics(
                        "form_submission",
                        {
                            event: "form_submission",
                            form_type: "lead_subscription",
                            form_subject: "Website Subscribe",
                            venue: self.location,
                        },
                        self.system.CustomerId,
                        self.system.WebfolioId
                    );
                });
            });
        },
    },
};
</script>
