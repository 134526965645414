var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.feed, function (article) {
      return _c("div", { staticClass: "col-md-4" }, [
        _c("div", { staticClass: "archive-card" }, [
          _c("a", { attrs: { href: "/" + article.slug } }, [
            _c(
              "div",
              {
                staticClass: "archive-card__image",
                style: "background-image:url(/storage/" + article.img + ");",
              },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "archive-card__header" }, [
              _c("p", [_vm._v(_vm._s(_vm._f("nicedate")(article.date)))]),
              _vm._v(" "),
              _c("h2", [_vm._v(_vm._s(article.title))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "archive-card__body" }, [
            _vm._v(
              "\n                " + _vm._s(article.summary) + "\n            "
            ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }