/**
 * On page ready
 */
$(document).ready(function () {
    /**
     * Primary Nav
     */
    $('.hamburger').on('click', function (e) {
        $('.main-menu').find('.sub-open').removeClass('sub-open');
        $('.main-menu').find('.sub-active').removeClass('sub-active');
        $('.hamburger, .main-menu').toggleClass('is-active');
        $('html').toggleClass('no-scroll');
    })

    if ($(window).width() > 767) {
        $('.main-menu').on('click', function (e) {
            var target = $(e.target);
            if (target.is('a')) {
                return;
            }
            $('.main-menu').find('.sub-open').removeClass('sub-open');
            $('.main-menu').find('.sub-active').removeClass('sub-active');
            $('.hamburger, .main-menu').toggleClass('is-active');
            $('html').toggleClass('no-scroll');
        })

    }

    $('.main-menu .close').on('click', function (e) {
        $('.main-menu').find('.sub-open').removeClass('sub-open');
        $('.main-menu').find('.sub-active').removeClass('sub-active');
        $('.hamburger, .main-menu').toggleClass('is-active');
        $('html').toggleClass('no-scroll');
    })

    $('.arrow').on('click', function (e) {
        $('.main-menu').find('.sub-open').removeClass('sub-open');
        $('.main-menu').find('.sub-active').removeClass('sub-active');
    })

    $('.no-sub').click(function (e) {
        e.preventDefault();
    })

    $('.pri-link').on('mouseenter touchstart', function (e) {
        $('.main-menu').find('.sub-open').removeClass('sub-open');
        $('.main-menu').find('.sub-active').removeClass('sub-active');
        if ($(this).closest('.nav-link').hasClass('sub')) {
            $(this).parent('.sub').addClass('sub-open');
            $('.nav-box').addClass('sub-active');
            $('.nav-wrapper').addClass('sub-active');
        }
    })

    /**
     * Signup modal
     */
    // Check for cookie
    var sup = Cookies.get('signupCookie')
    if (sup) {
        $('#signup-sideout').addClass('sideout-blocked')
    }
    // close
    $('#signup-sideout .close').on('click', function (e) {
        $('#signup-sideout').addClass('sideout-hide')
    })

    $(window).on('scroll', function () {
        var y = $(this).scrollTop();
        if (y > 500) {
            $('#signup-sideout').addClass('sideout-show')
            //$(window).off('scroll');
        }
        if (y > 250) {
            $('body').addClass('window-300')
        } else {
            $('body').removeClass('window-300')
        }
    });

    /**
     * Collection Modal
     */
    $('#collectionContainer a').on('click', function (e) {

        var value = $(this).attr("href")
        if (value == '#collectionModal') {
            e.preventDefault()
            $('#collectionModal').modal('show')
        }

    })

    /**
     * Modify margin for fixed header size
     */
    var contentPlacement = $('.fixed-header').position().top + $('.fixed-header').outerHeight();
    $('.content').css('padding-top', contentPlacement);

    /**
     * UTM Params
     */
    /*
    var telParams = URI.parseQuery(Cookies.get('beCampaign'))
    if(!_.isEmpty(telParams) && (telParams.utm_campaign.indexOf("NCA") !== -1)) {
        if(telParams.utm_medium && telParams.utm_medium != '') {
            if(telParams.utm_medium == 'cpc') {
                $('.globalTel').each(function() {$(this).html('01283 477911')})
                $('.globalTelMobile').attr('href','tel:01283 477911')
            } else if(telParams.utm_medium == 'social') {
                $('.globalTel').each(function() {$(this).html('01283 477913')})
                $('.globalTelMobile').attr('href','tel:01283 477913')
            } else {
                $('.globalTel').each(function() {$(this).html('01283 477911')})
                $('.globalTelMobile').attr('href','tel:01283 477911')
            }
        } else {
            $('.globalTel').each(function() {$(this).html('01283 477911')})
            $('.globalTelMobile').attr('href','tel:01283 477911')
        }
    } else {
        $('.globalTel').each(function() {$(this).html(window.pageLocTel)})
    }
    */

    $('.globalTel, .globalTelMobile').on("click", function () {
        var uri = new URI(window.location.href)

        if (uri.segment(0) == 'eden-hall') {
            dataLayer.push({
                'event': 'generate_lead',
                'lead_type': 'lead_contact',
                'venue': 'Eden Hall'
            });
        } else if (uri.segment(0) == 'hoar-cross-hall') {
            dataLayer.push({
                'event': 'generate_lead',
                'lead_type': 'lead_contact',
                'venue': 'Hoar Cross Hall'
            });
        } else {
            dataLayer.push({
                'event': 'generate_lead',
                'lead_type': 'lead_contact',
                'venue': 'Barons Eden'
            });
        }

    });
})

/**
 * Rellax
 */
import Rellax from 'rellax';
var rellax = new Rellax('.rellax');

/**
 *  slider
 */
require('../../node_modules/slick-carousel/slick/slick.js')

$('.slick').slick({
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8000,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    arrows: false,
    pauseOnHover: false
});

// Initialize Slick
$('.slickInit').slick()

$('.slickInitAwards').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 580,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
});

/**
 *  Masonry
 */
require('../../node_modules/masonry-layout/dist/masonry.pkgd.min');
require('../../node_modules/imagesloaded/imagesloaded.pkgd.min');

var grid = $('.masonry').masonry({
    // options...
    itemSelector: 'li',
    columnWidth: 'li',
    percentPosition: true
});

// layout Masonry after each image loads
grid.imagesLoaded().progress(function () {
    grid.masonry('layout');
});

// image modal
// $('.masonry-item').on('click', function () {
//     var imageSrc = $(this).find('img').attr('src');
//     $('.js-modal-image').attr('src', imageSrc);

//     $('#galleryModal').modal('show')
// });



/**
 * Recaptcha
 */
window.getRecaptchaToken = function () {
    return new Promise(function (resolve, reject) {
        grecaptcha.ready(function () {
            grecaptcha.execute('6Lf__NsUAAAAADnX0QU6l3yK21j-n1thZzHqQXnt', { action: 'subscribe' }).then(function (token) {
                resolve(token)
            });
        });
    });
}
