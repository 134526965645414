var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.status
      ? _c("div", [
          _c("p", [
            _vm._v(
              "Head over to your email inbox where your temporary password will be waiting. (Please allow up to 5 minutes for it to arrive)"
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-hch",
              attrs: { href: "/hoar-cross-hall/members-portal" },
            },
            [_vm._v("Hoar Cross Hall Members")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-eh",
              attrs: { href: "/eden-hall/members-portal" },
            },
            [_vm._v("Eden Hall Members")]
          ),
        ])
      : _c("div", [
          _c("p", [
            _vm._v(
              "To get started, enter the email address associated with your account."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "form",
              {
                staticClass: "form-inline",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.resetPassword.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-row justify-content-center" }, [
                  _c("div", { staticClass: "form-group col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        id: "resetEmail",
                        "aria-describedby": "emailHelp",
                        placeholder: "Enter email",
                        required: "",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary mb-0",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Reset Password")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.loading
            ? _c("span", [
                _c("div", {
                  staticClass: "spinner-grow spinner-grow-sm",
                  attrs: { role: "status" },
                }),
                _vm._v(" Loading..."),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }