<template>
    <div class="container">
        <div class="row justify-content-md-center book-success mb-5">
            <div class="col-md-6 text-center">
                <div class="booking-info mb-5">
                    <h2>Your booking reference number</h2>
                    <span class="book-ref">{{bookingRef}}</span>
                </div>
                <div>
                    <p><strong>Thank you for your booking {{system.FirstName}}, we look forward to welcoming you through our grand Hall doors very soon.</strong></p>
                    <p>A confirmation email will arrive in your inbox very shortly, followed by a pre-arrival consultation form, where we can get fully acquainted with your dietary requirements and health status, ensuring every inch of your experience is tailored to your needs.</p>
                    <p>In the meantime, all that’s left for you to do is peruse your historic property, discovering every treasure the Hall has to offer...</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-4">
                <a href="/hoar-cross-hall" class="btn btn-secondary btn-bg-hch btn-block mb-3">Hoar Cross Hall</a>
                <a href="/eden-hall" class="btn btn-secondary btn-bg-eh btn-block">Eden Hall</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                bookingRef: this.$route.query.ref
            }
        },
        mounted() {
            if(!this.cart.FolioItems) {
                this.$router.push('/book')
            }

            var ecommerce = JSON.parse(window.localStorage.getItem('booking_confirmation'));
            var dining_deposit = JSON.parse(window.localStorage.getItem('dining_deposit'));

            if(dining_deposit !== null && dining_deposit[this.system.WebFolioId] !== undefined) {
                this.depositCheck(dining_deposit[this.system.WebFolioId], ecommerce.purchase)
            }

            dataLayer.push({
                'event': 'checkout_success',
                'ecommerce': ecommerce
            });

            this.$emitAnalytics(
                'checkout_success', 
                {
                    'event': 'checkout_success',
                    'ecommerce': ecommerce
                },
                this.system.CustomerId,
                this.system.WebFolioId
            );

            this.purchase = ecommerce.purchase;

            if(ecommerce.purchase !== undefined) {
                this.awinTracking(ecommerce.purchase);
                this.grinTracking(ecommerce.purchase);
                this.checkoutNotification(ecommerce.purchase);
            }

            window.localStorage.removeItem('booking_confirmation');
            window.localStorage.removeItem('dining_deposit');
            window.localStorage.removeItem('dining_configuration');
            window.localStorage.removeItem('webfolio_rooms');
        },

        watch: {
            cart: {
                handler(newVal, oldVal){
                    if(!newVal.FolioItems) {
                        //this.$router.push('/book')
                    }
                }
            }
        },
        /**
         * Methods
         */
        methods: {
            checkoutNotification(purchase) {
                axios.post('/rs/checkout-notification', {
                    purchase: purchase,
                });
            },
            depositCheck(dining_deposit, purchase) {            
                if(this.hasPaidDeposit(purchase.products, dining_deposit.amount)) {
                    axios.post('/rs/complete-reservation', {
                        resdiary_id: dining_deposit.resdiary_id,
                        customer_id: dining_deposit.customer_id,
                        retail_folio: dining_deposit.retail_folio,
                        giftvoucher_id: dining_deposit.giftvoucher_id,
                    });
                }                
            },
            hasPaidDeposit(items, depositAmount) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].name.toLowerCase().includes("deposit")) {
                        console.log(depositAmount, items[i].price);

                        if(items[i].price === 1) {
                           return true;
                        }

                        return items[i].price === depositAmount;
                    }
                }

                return false;
            },
            awinTracking(purchase) {
                var channel = this.getCookieValue('AwinChannelCookieS2S') || 'other';
                var promoCode = purchase.actionField.promoCode ? purchase.actionField.promoCode : '';

                if (typeof AWIN != "undefined" && typeof AWIN.Tracking != "undefined") {
                    AWIN.Tracking.Sale = {};
                    AWIN.Tracking.Sale.amount = purchase.actionField.revenue;
                    AWIN.Tracking.Sale.channel = channel;
                    AWIN.Tracking.Sale.orderRef = purchase.actionField.id;
                    AWIN.Tracking.Sale.parts = "DEFAULT:" + purchase.actionField.revenue;
                    AWIN.Tracking.Sale.currency = purchase.actionField.currency;
                    AWIN.Tracking.Sale.voucher = promoCode;
                    AWIN.Tracking.Sale.test = "0";
                    AWIN.Tracking.run();
                }

                var img = document.createElement("img");
                img.height = 0;
                img.width = 0;
                img.style = "display:none;";
                img.src = "https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=65940&amount=" + purchase.actionField.revenue + "&ch=" + channel + "&parts=DEFAULT:" + purchase.actionField.revenue + "&ref=" + purchase.actionField.id + "&cr=" + purchase.actionField.currency + "&vc=" + promoCode + "&testmode=0";
                document.getElementsByTagName('body')[0].appendChild(img);

                axios.post('/api/tracking/awin', {
                    merchantId: "65940",
                    totalAmount: purchase.actionField.revenue,
                    orderId: purchase.actionField.id,
                    currency: purchase.actionField.currency,
                    voucherCode: promoCode,
                });
            },
            grinTracking(purchase) {
                var amount = purchase.actionField.revenue;
                var order_number =  purchase.actionField.id;
                Grin = window.Grin || (window.Grin = []);
                Grin.push(['conversion', amount, {order_number: order_number}]);
            },
            getCookieValue(name)  {
                const regex = new RegExp(`(^| )${name}=([^;]+)`);
                const match = document.cookie.match(regex);

                if (match) {
                    return match[2];
                }
            }
        }
    }
</script>