<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div id="class-availability-tabs">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-today-tab" data-toggle="tab" href="#nav-today" role="tab" aria-controls="nav-today" aria-selected="true">Today</a>
                            <a class="nav-item nav-link" id="nav-tomorrow-tab" data-toggle="tab" href="#nav-tomorrow" role="tab" aria-controls="nav-tomorrow" aria-selected="false">Tomorrow</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-today" role="tabpanel" aria-labelledby="nav-today-tab">
                            <div v-if="today">
                                <transition-group tag="ul" name="fade-out-in">
                                    <li v-for="c in today"  v-bind:key="c.id" class="alt-bg">
                                        <h5 class="treatment-name">{{ c.name }} 
                                            <div class="treatment-price">
                                                <span>{{ c.room }}</span>
                                                <span>{{ c.time }}</span>
                                                <span>{{ c.duration }} mins</span> 
                                            </div>
                                        </h5>
                                    </li>
                                </transition-group>
                                <div class="availability-cta">
                                    To book your class, please visit our spa reception
                                </div>
                            </div>
                            <div v-else class="flex-container align-items-center">
                                <div class="flex-item text-center">
                                    <div v-if="today == null || today.length === 0">
                                        <h3>Sorry, no availability today</h3>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <div class="spinner-grow spinner-grow-md" role="status"></div>
                                            Loading treatments...
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-tomorrow" role="tabpanel" aria-labelledby="nav-tomorrow-tab">
                            <div v-if="tomorrow">
                                <transition-group tag="ul" name="fade-out-in">
                                    <li v-for="c in tomorrow"  v-bind:key="c.id" class="alt-bg">
                                        <h5 class="treatment-name">{{ c.name }} 
                                            <div class="treatment-price">
                                                <span>{{ c.room }}</span>
                                                <span>{{ c.time }}</span>
                                                <span>{{ c.duration }} mins</span> 
                                            </div>
                                        </h5>
                                    </li>
                                </transition-group>
                                <div class="availability-cta">
                                    To book your class, please visit our gym reception
                                </div>
                            </div>
                            <div v-else class="flex-container align-items-center">
                                <div class="flex-item text-center">
                                    <div v-if="noClassAvailability">
                                        <h3>Sorry, no classes remaining.</h3>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <div class="spinner-grow spinner-grow-md" role="status"></div>
                                            Loading classes...
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            location: Number,
        },

        components: {
            
        },

        data() {
            return {
                today: null,
                tomorrow: null,
                noAvailability: false,
                loadingAvailability: false,
                noClassAvailability: false,
                loadingClasses: false,
                classes: null,
            }
        },
        
        mounted() {
            this.fetchClasses()
        },

        watch: {
            
        },

        computed: {
            
        },

        methods: {
            fetchClasses() {
                var self = this
                this.loadingClasses = true
                
                axios.get('/api/classes/availability')
                    .then(function (response) {
 
                        if(response.data.length) {
                            var venue = self.location == 100 ? 200 : self.location

                            var allClasses = _.filter(response.data, { 'venue': venue.toString() })

                            var days = _.groupBy(allClasses, 'date')

                            var today = days[moment().format('YYYY-MM-DD')]
                            self.tomorrow = days[moment().add(1, 'days').format('YYYY-MM-DD')]

                            // Remove earlier times for today
                            _.remove(today, function(t) { return moment(t.date + ' ' + t.time + ':00') <= moment()})

                            if(today.length) {
                                self.today = today
                                self.noClassAvailability = false
                            } else {
                                self.noClassAvailability = true
                            }
                            
                        } else {
                            self.noClassAvailability = true
                        }
                    })
            }
        }
    }
</script>
