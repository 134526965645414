<template>
    <div class="container">
        <div class="row">
			<div class="col-md-12">
				<h2 class="mb-3">Get in touch...</h2>
			</div>
		</div>
		<form @submit.prevent="send" class="row">
            <div class="col-md-6">
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="firstName">First name*</label>
						<input type="text" v-model="formData.firstName" class="form-control" id="firstName" required>
					</div>
					<div class="form-group col-md-6">
						<label for="lastName">Surname*</label>
						<input type="text" v-model="formData.lastName" class="form-control" id="lastName" required>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="emailAddress">Email address*</label>
						<input type="email" v-model="formData.emailAddress" class="form-control" id="emailAddress" required>
					</div>
					<div class="form-group col-md-6">
						<label for="tel">Telephone number</label>
						<input type="tel" v-model="formData.tel" class="form-control" id="tel">
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-12">
						<label for="message">Message</label>
						<textarea v-model="formData.message" rows="10" class="form-control" id="message"></textarea>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-row mb-3">
					<div class="form-group col-md-12">
						<h5 class="ffs">Select the venue you wish to enquire about.</h5>
						<select v-model="formData.venue" class="form-control" id="venue" required>
							<option value="">Please select</option>
							<option value="Hoar Cross Hall">Hoar Cross Hall</option>
							<option value="Eden Hall">Eden Hall</option>
						</select>
					</div>
				</div>
				<div class="form-row mb-3">
					<div class="form-group col-md-12">
						<h5 class="ffs">Select the nature of your enquiry.</h5>
						<div v-if="formData.venue != 'Eden Hall'" class="form-check">
							<label class="form-check-label text-uppercase" for="stay">
								<input  v-model="formData.stay" type="checkbox" class="form-check-input" id="stay">
								Stay
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="form-check">
							
							<label class="form-check-label text-uppercase" for="spaDay">
								<input v-model="formData.spaDay" type="checkbox" class="form-check-input" id="spaDay">
								Spa Day
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="form-check">
							<label class="form-check-label text-uppercase" for="giftCards">
								<input v-model="formData.giftCards" type="checkbox" class="form-check-input" id="giftCards">
								Gift Cards
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="form-check">
							<label class="form-check-label text-uppercase" for="membership">
								<input v-model="formData.membership" type="checkbox" class="form-check-input" id="membership">
								Membership
								<span class="checkmark"></span>
							</label>
						</div>
						<div v-if="formData.venue != 'Eden Hall'" class="form-check">
							<label class="form-check-label text-uppercase" for="wedding">
							<input v-model="formData.wedding" type="checkbox" class="form-check-input" id="wedding">								
								Weddings
								<span class="checkmark"></span>
							</label>
						</div>
						<div v-if="formData.venue != 'Eden Hall'" class="form-check">
							<label class="form-check-label text-uppercase" for="dining">
								<input v-model="formData.dining" type="checkbox" class="form-check-input" id="dining">
								Food &amp; Drink
								<span class="checkmark"></span>								
							</label>
						</div>
						<div v-if="formData.venue != 'Eden Hall'" class="form-check">
							<label class="form-check-label text-uppercase" for="privateOccasion">
								<input v-model="formData.privateOccasion" type="checkbox" class="form-check-input" id="privateOccasion">
								Private Occasion
								<span class="checkmark"></span>
							</label>
						</div>
						<div v-if="formData.venue != 'Eden Hall'" class="form-check">
							<label class="form-check-label text-uppercase" for="corporate">
								<input v-model="formData.meeting" type="checkbox" class="form-check-input" id="corporate">
								Corporate
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="form-check">
							<label class="form-check-label text-uppercase" for="press">
								<input v-model="formData.press" type="checkbox" class="form-check-input" id="press">
								Press
								<span class="checkmark"></span>
							</label>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-12">
						<h5 class="ffs">Want to stay updated on the latest news, events and exclusive offers?</h5>
						<div class="form-inline">
							<div class="custom-control custom-radio custom-control-inline">
								<input v-model="formData.subscribe" value="yes" type="radio" id="subscribe" name="subscribe" class="custom-control-input">
								<label class="custom-control-label" for="subscribe">Yes please</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input v-model="formData.subscribe" value="no" type="radio" id="subscribeNo" name="subscribe" class="custom-control-input">
								<label class="custom-control-label" for="subscribeNo">No thanks</label>
							</div>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-12">
						<input type="submit" value="Send" class="btn btn-primary mr-2" :disabled="processing">
						<div v-if="processing" class="spinner-border" role="status"></div>
						<div v-if="success" class="mt-5">
							Thank you. Your message has been sent. We will be in touch shortly.
						</div>
					</div>
				</div>
			</div>
        </form>
    </div>
        
</template>

<script>
    var formTemplate = function() {
      return {
        firstName: null,
        lastName: null,
        emailAddress: null,
        tel: null,
        message: null,
        stay: null,
        spaDay: null,
		dining: null,
		privateOccasion: null,
        giftCards: null,
        membership: null,
        wedding: null,
        meeting: null,
		press: null,
		subscribe: 'no',
		venue: ''
      }
    }
    export default {
        props: {
          location: Object
        },
        data() {
            return {
                formData: new formTemplate(),
                success: null,
				processing: false
            }
        },

        mounted() {

        },

        methods: {
            send() {
                var self = this
				self.processing = true

				if(self.formData.venue == 'Eden Hall') {
					self.formData.stay = false
					self.formData.privateOccasion = false
					self.formData.dining = false
					self.formData.wedding = false
					self.formData.meeting = false
				}
				var selectionArray = [
					self.formData.stay && self.venue != 'Eden Hall' ? 'Stay' : null,
					self.formData.spaDay ? 'Spa Day' : null,
					self.formData.privateOccasion && self.venue != 'Eden Hall' ? 'Private Occasion' : null,
					self.formData.dining && self.venue != 'Eden Hall' ? 'Dining' : null,
					self.formData.giftCards ? 'Gift Cards' : null,
					self.formData.membership ? 'Membership' : null,
					self.formData.press ? 'Press' : null,
					self.formData.wedding && self.venue != 'Eden Hall' ? 'Wedding' : null,
					self.formData.meeting && self.venue != 'Eden Hall' ? 'Meeting' : null
				]
				var eventData = self.formData.venue + ': ' + _.compact(selectionArray).join(", ")

                window.getRecaptchaToken()
                    .then(function(r) {
                        var data = {
                            location: self.formData.venue,
                            formData: self.formData,
                            recaptcha: r
                        }
                        axios.post('/system/contact', data)
                            .then(response=> {
                                self.success = true;
								self.processing = false

								dataLayer.push({
									event: 'form_submission',
									form_type: 'lead_form',
									form_subject: 'Contact Us',
									venue: self.formData.venue,
								});

								this.$emitAnalytics(
									'form_submission', 
									{
										event: 'form_submission',
										form_type: 'lead_form',
										form_subject: 'Contact Us',
										venue: self.formData.venue
									},
									self.system.CustomerId,
                                    self.system.WebfolioId
								);
								
								self.formData = new formTemplate()
                            });
                    })
                
                        
            }
        }
    }
</script>
