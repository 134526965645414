var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      staticClass: "form-control form-control-sm",
      class: { "is-invalid": !_vm.isValid },
      attrs: {
        type: "text",
        placeholder: _vm.placeholder,
        id: _vm.id,
        minlength: _vm.minlength,
        required: _vm.required,
      },
      domProps: { value: _vm.password },
      on: {
        input: function ($event) {
          return _vm.$emit("update:password", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "progress" }, [
      _c("div", {
        staticClass: "progress-bar progress-bar-animated",
        style: {
          width: `${_vm.percent}%`,
          backgroundColor: _vm.description.color,
        },
        attrs: {
          role: "progressbar",
          "aria-valuenow": _vm.percent,
          "aria-valuemin": "0",
          "aria-valuemax": "100",
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-feedback-text" }, [
      _c("p", [_vm._v(_vm._s(_vm.description.label))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }