<template>
    <div>
        <div class="row justify-content-md-center">
            <div class="col-md-5 text-center">
                <div class="book-start">
                    <div>
                        <h3 class="text-center mb-1">Afternoon Tea at the Hall</h3>
                        <a @click.prevent="backStep()" class="small ffss">Back a step</a>

                        <div v-if="configuration.step === 1">
                            <ul class="guests-nights-selector mb-4">
                                <li>
                                    <p>Party Size</p>
                                    <button @click="decParty()" class="btn btn-plus-minus">-</button>
                                    <span class="book-int">{{ configuration.partySize }}</span>
                                    <button @click="incParty()" class="btn btn-plus-minus">+</button>
                                </li>
                            </ul>
                            <div class="calendar">
                                <v-calendar
                                    mode="single"
                                    v-model="configuration.selectedDate"
                                    :from-date="minDate"
                                    @dayclick="dayClick($event)"
                                    :min-date='minDate'
                                    :max-date='null'
                                    is-expanded
                                    is-inline
                                    is-linked
                                >  
                                    <div
                                        slot="day-content"
                                        slot-scope="{ day, attributes, dayEvents }"
                                        :day='day'
                                        v-on="dayEvents"
                                        class="vc-day-content"
                                        :class="{ daySelected: day.id == configuration.selectedDate, 'vc-text-gray-400': !isValid(day.id) }"
                                    >
                                        <div>
                                            <div>{{ day.day }}</div>
                                        </div>
                                    </div>
                                </v-calendar>
                            </div>

                            <div class="row mb-4" v-if="configuration.timeslots.length > 0">
                                <div class="col-12">
                                    <label for="timeslot" class="brandHeading">Time slot</label>
                                    <select v-model="configuration.selectedTimeslot" class="form-control">
                                        <option v-for="(timeslot, index) in configuration.timeslots" :key="index" :value="timeslot.TimeSlot" id="timeslot">
                                            {{ timeslot.TimeSlot | nicetime }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="info-box mb-4" v-else>
                                <p class="mb-0">Sorry, there are no available time slots for the selected date.</p>
                            </div>
                    

                            <div class="mt-2 mb-2 row" v-if="configuration.timeslots.length > 0">
                                <div class="col-12">
                                    <button class="btn btn-secondary" @click="setStep(2)" :disabled="!configuration.selectedTimeslot">Next Step</button>
                                </div>
                            </div>

                        </div>

                        <div v-if="configuration.step === 2"> 

                            <h5 class="brandHeading mt-4">Select your Afternoon Tea</h5>

                            <div class="package-selector list-group mb-4">
                                <a href="#" class="list-group-item list-group-item-action" v-for="(promotion, index) in configuration.promotions" :key="index" @click="selectPromotion(promotion)" :class="{ selected: configuration.selectedPromotion.Id === promotion.Id }">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-1">{{ promotion.Name }}</h5>
                                        <small>&pound;{{ promotion.FullPrice | price}}pp</small>
                                    </div>
                                    <p class="mb-1 text-left">{{ promotion.Description }}</p>
                                </a>
                            </div>

                            <div class="row mb-4">
                                <div class="col-12">
                                    <label for="specialRequests"  class="brandHeading">Any Special Requests?</label>
                                    <textarea class="form-control" v-model="configuration.specialRequests" id="specialRequests"></textarea>
                                </div>
                            </div>

                            <div class="mt-2 mb-2 row">
                                <div class="col-12">
                                    <button class="btn btn-secondary" @click="setStep(3)">Next Step</button>
                                    <button class="btn btn-primary" @click="setStep(1)">Go Back</button>
                                </div>
                            </div>

                        </div>  

                        <div v-if="configuration.step === 3">

                            <h5 class="brandHeading mt-4">Booking Summary</h5>

                            <div class="cart-page-actions__balance mt-3">
                                <span>Amount to pay today: <strong>£{{ paymentAmount }}</strong></span>
                            </div>

                            <div class="alert alert-warning" v-if="mobileError">
                                <p class="mb-0">A mobile number is required to make this booking, please update your account <a href="/book/account" target="_blank">here</a> or call our reservations team on <a href="tel:01283 576522" target="_blank">01283 576522</a>.</p>
                            </div>

                            <div class="alert alert-warning" v-if="error">
                                <p class="mb-0">Unfortunately we're unable to make this reservation online please call our reservations team on <a href="tel:01283 576522" target="_blank">01283 576522</a> to complete your booking.</p>
                            </div>

                            <div class="mt-4 mb-4 row" v-if="this.system.isLoggedIn">
                                <div class="col-12">
                                    <button class="btn btn-secondary" @click="createReservation()" :disabled="loading || mobileError || error">Pay <span v-if="loading"><div class="spinner-border spinner-border-sm" role="status"></div></span></button>
                                    <button class="btn btn-primary" @click="startOver()">Start Over</button>
                                </div> 
                            </div>

                            <div class="row mt-4 mb-4" v-else>
                                <div class="col-12">
                                    <div class="d-md-flex">
                                        <button type="button" class="btn btn-secondary flex-fill mr-2" @click.prevent="showLogin('login')">
                                            Login to account
                                        </button>
                                        <button type="button" class="btn btn-secondary flex-fill" @click.prevent="showLogin('register')">
                                            Checkout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                        <div class="box-blush">
                            <p>Why not enjoy our award-winning spa facilities, visiting us on a spa day or stay, then adding your Afternoon Tea as a finishing touch at checkout?</p>
                            <a @click.prevent="backStep()" class="small ffss">Back a step</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="booking-app-sideout login-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a @click.prevent="closeSideout()" class="prevNextArrow arrowLeft">&nbsp;</a>
                        </div>
                        <div class="package-content">
                            <div class="room-full-description">
                                <login-register :system="system" :cart="cart" :type="type"></login-register>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var emailOrderFormTemplate = function() {
        return {
            SessionId: null,
            WebFolioId: null,
            CustomerId: 24,
            EmailAddress: "",
            IssueToName: "",
            ItemId: "997181",
            Layout: 1,
            MailToAddress: {
                type: 'HOME',
                Address1: null,
                Address2: null,
                City: null,
                StateProv: null,
                Country: 'United Kingdom',
                PostCode: null
            },
            Message: '',
            Method: 'E',
            WrappingId: null,
            ShippingId: 10303,
            PackageCertificate: null,
        }
    };

    export default {
        props: {
            system: Object,
            cart: Object,
            venue: String
        },

        components: {

        },

        data() {
            return { 
                venue: this.$route.params.venue,
                defaultConfiguration: {
                    step: 1,
                    partySize: 1,
                    selectedDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                    selectedTimeslot: null,
                    selectedPromotion: null,
                    specialRequests: "",
                    timeslots: [],
                    promotions: [],
                },
                configuration: {
                    step: this.$route.params.step ? + this.$route.params.step : 1,
                    partySize: 1,
                    selectedDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                    selectedTimeslot: null,
                    selectedPromotion: null,
                    specialRequests: "",
                    timeslots: [],
                    promotions: [],
                },
                loading: false,
                mobileError: false,
                error: false,
                minDate: new Date(moment().add(1, 'days').format('YYYY-MM-DD')),
                type: 'login',
                emailOrderForm: emailOrderFormTemplate(),
                giftCardId: null,
                giftCardIds: {
                    "40.0": {
                        "1": "997181",
                        "2": "997182",
                        "3": "997183",
                        "4": "997184",
                        "5": "997185",
                        "6": "997186",
                    },
                    "45.0": {
                        "1": "997187",
                        "2": "997188",
                        "3": "997189",
                        "4": "997190",
                        "5": "997191",
                        "6": "997192",
                    },
                    "50.0": {
                        "1": "997193",
                        "2": "997194",
                        "3": "997195",
                        "4": "997196",
                        "5": "997197",
                        "6": "997198",
                    }
                }
            }
        },     
        mounted() {
            var existingConfiguration = JSON.parse(window.localStorage.getItem('dining_configuration'));

            if(existingConfiguration !== null && existingConfiguration[this.system.WebFolioId] !== undefined) {
                this.configuration = existingConfiguration[this.system.WebFolioId];
            } else {
                this.getAvailability();
            }

            this.getVenues();
        },

        computed: {
            paymentAmount() {
                if(this.configuration.selectedPromotion !== null && this.configuration.promotions.length > 0) {
                    return (this.configuration.promotions.find(promotion => promotion.Id === this.configuration.selectedPromotion.Id).FullPrice * this.configuration.partySize);
                } else {
                    return 0;
                }
            }
        },

        watch: {
            configuration: {
                deep: true,
                handler (newVal) {
                    if(newVal.selectedPromotion !== null) {
                        this.giftCardId = this.giftCardIds[newVal.selectedPromotion.FullPrice][this.configuration.partySize];
                    }

                    var dining_configuration = {};
                    dining_configuration[this.system.WebFolioId] = newVal;
                    window.localStorage.setItem('dining_configuration', JSON.stringify(dining_configuration));
                }
            },
            '$route.params.step': {
                handler: function(step) {
                    if(step !== this.configuration.step && step !== undefined) {
                        this.configuration.step = Number(step);
                    } else {
                        this.configuration.step = 1;
                    }
                },
                deep: true,
                immediate: true
            }
        },

        methods: {
/*             checkAvailabilty() {
                var request = {
                    method: 'FetchDiningAvailDays',
                    data: {
                        VenueName: "Long Gallery Bar",
                        StartDate: moment(this.configuration.selectedDate).format('YYYY-MM-DD'),
                        EndDate: moment(this.configuration.selectedDate).add(1, 'days').format('YYYY-MM-DD'),
                        PartySize: this.configuration.partySize,
                        VisitTime: moment.utc(this.configuration.selectedTimeslot).local().format('hh:mm:ss'),
                        TimeWindow: 1000
                    }
                };
                
                axios.post('/rs/request', request)
                    .then(response=> {
                        console.log(response);

                        if (response.data.Result.value = "SUCCESS") { 
                            console.log(response);
                        }
                    }
                );  
            }, */
            startOver() {
                this.configuration = this.defaultConfiguration;
                this.loading = false;
                this.error = false;
                this.mobileError = false;
                this.setStep(1);
            },
            setStep(step) {
                this.configuration.step = step;
                this.$router.push('/book/search/hoar-cross-hall/afternoon-tea/' + this.configuration.step + '/');
            },
            selectPromotion(promotion) {
                this.configuration.selectedPromotion = promotion;
            },
            showLogin(type) {
                this.type = null;
                $('.login-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');
                this.type = type
            },
            closeSideout() {
                this.type = null;
                $('.login-selector').removeClass('is-active');
                $('html').toggleClass('no-scroll');
            },
            decParty() {
                if(this.configuration.partySize > 1) {
                    this.configuration.partySize--
                }
            },
            incParty() {
                if(this.configuration.partySize < 6) {
                    this.configuration.partySize++
                }
            },
            dayClick(e) {
                this.configuration.selectedDate = e.id;
                this.getAvailability();
            },
            isValid(date) {
                return moment(date).isAfter(moment().subtract(1, 'days'));
            },
            backStep() {
                this.$router.push('/book/start/' + this.venue)
            },
            getAvailability() {
                var request = {
                    method: 'FetchDiningAvailabilities',
                    data: {
                        WebFolioId: this.system.WebFolioId,
                        VenueName: "Long Gallery Bar",
                        VisitDate: this.configuration.selectedDate,
                        PartySize: this.configuration.partySize,
                        BookingId: null,
                        AreaId: 85609
                    }
                };
                
                axios.post('/rs/request', request)
                    .then(response=> {
                        if (response.data.Result.value = "SUCCESS") { 
                            if(response.data.Availabilities && response.data.Availabilities.TimeSlots.Timeslot.length > 0 
                            && response.data.Availabilities.Promotions.Promotion) {
                                this.configuration.timeslots = response.data.Availabilities.TimeSlots.Timeslot;
                                this.configuration.selectedTimeslot = this.configuration.timeslots[0].TimeSlot; 

                                if (
                                    response.data.Availabilities.Promotions.Promotion &&
                                    !$.isArray(response.data.Availabilities.Promotions.Promotion)
                                ) {
                                    response.data.Availabilities.Promotions.Promotion = [
                                        response.data.Availabilities.Promotions.Promotion
                                    ];
                                }

                                this.configuration.promotions = response.data.Availabilities.Promotions.Promotion;
                                this.configuration.selectedPromotion = this.configuration.promotions[0]; 
                            } else {
                                this.configuration.timeslots = [];
                            }
                        } else {
                            this.configuration.timeslots = [];
                        }
                    });
            },
            getVenues() {
                var request = {
                    method: 'FetchDiningVenues',
                    data: {
                        WebFolioId: this.system.WebFolioId,
                    }
                };
                
                axios.post('/rs/request', request)
                    .then(response=> {
                        console.log(response.data);
                    });
            },
            createReservation() {
                this.loading = true;

                var request = {
                    method: 'CreateDiningReservation',
                    data: {
                        WebFolioId: this.system.WebFolioId,
                        SessionId: this.system.SessionId,
                        VenueName: "Long Gallery Bar",
                        VisitDate: this.configuration.selectedDate,
                        PartySize: this.configuration.partySize,
                        VisitTime: moment.utc(this.configuration.selectedTimeslot).local().format('HH:mm:ss'),
                        SpecialRequests: this.configuration.specialRequests,
                        IsLeaveTimeConfirmed: "Y",
                        PromotionId: this.configuration.selectedPromotion.Id,
                    }
                };
                
                axios.post('/rs/request', request)
                    .then(response => {
                        if (response.data.Result.value == "SUCCESS") { 
                            this.saveReservation(response.data.Reservation);
                            this.addDepositGiftcard(response.data.Reservation);
                        } else if (response.data.Result.value == "FAIL" && response.data.Result.Text == "'Mobile' should not be empty.") {
                            this.loading = false;
                            this.mobileError = true;
                        } else if (response.data.Result.value == "FAIL") {
                            this.loading = false;
                            this.error = true;
                        }
                    });
            },
            addDepositGiftcard(reservation) {
                this.emailOrderForm.SessionId = this.system.SessionId;
                this.emailOrderForm.WebFolioId = this.system.WebFolioId;
                this.emailOrderForm.CustomerId = this.system.CustomerId ?? 24;
                this.emailOrderForm.ItemId = this.giftCardId;
                
                var gc = {
                    method: "PurchaseGiftCertificate",
                    data: this.emailOrderForm
                };

                axios.post("/rs/request", gc).then(response => {
                    if (response.data.Result.value === "SUCCESS") {
                        this.loading = false;

                        var dining_deposit = {};

                        dining_deposit[this.system.WebFolioId] = {
                            amount: this.paymentAmount,
                            resdiary_id: reservation.ResDiaryId,
                            customer_id: this.system.CustomerId,
                            retail_folio: response.data.RetailFolioId,
                            giftvoucher_id: this.giftCardId
                        };

                        window.localStorage.setItem('dining_deposit', JSON.stringify(dining_deposit));

                        Bus.$emit("UpdateCart");
                        this.$router.push('/book/checkout');
                    }
                });
            },
            saveReservation(reservation) {
                axios.post('/rs/create-reservation', {
                    resdiary_id: reservation.ResDiaryId,
                    reference: reservation.Reference,
                    token: reservation.Token,
                    visit_date: reservation.VisitDate,
                    visit_time: reservation.VisitTime,
                    party_size: this.configuration.partySize,
                    customer_name: this.system.FirstName,
                    customer_email: this.system.EmailAddress
                });
            }
        },
    }
</script>

<style lang="scss">
    .package-selector {
        .list-group-item {
            font-family: "filson-pro", sans-serif;
            text-decoration: none;
            background-color: transparent;
            border-color: #9f7e4a;
            color: #9f7e4a;

            h5 {
                font-family: "filson-pro", sans-serif;
                font-weight: bold;
                color: #9f7e4a;
            }

            small {
                font-weight: bold;
            }

            &.selected {
                background-color: #9f7e4a;
                color: #fff;

                h5 {
                    color: #fff;
                }
            }

            &:hover {
                background-color: #9f7e4b8c;
                color: #fff;

                h5 {
                    color: #fff;
                }
            }
        }
    }
</style>