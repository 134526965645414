var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar" },
    [
      _c("v-calendar", {
        attrs: {
          mode: "single",
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate,
          attributes: _vm.atts,
          "is-expanded": "",
          "is-inline": "",
          "is-linked": "",
        },
        on: {
          dayclick: function ($event) {
            return _vm.dayClick($event)
          },
          "update:to-page": function ($event) {
            return _vm.nextMonth($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "day-content",
            fn: function ({ day, attributes, dayEvents }) {
              return _c(
                "div",
                _vm._g(
                  {
                    staticClass: "vc-day-content",
                    class: {
                      daySelected: day.id == _vm.selectedDate,
                      "vc-pointer-events-none vc-text-gray-400": !attributes,
                    },
                    attrs: { day: day },
                  },
                  dayEvents
                ),
                [
                  _c(
                    "div",
                    [
                      _c("div", [_vm._v(_vm._s(day.day))]),
                      _vm._v(" "),
                      _vm._l(attributes, function ({ key, customData }) {
                        return _c(
                          "div",
                          {
                            key: key,
                            staticClass: "vc-day-content__rate",
                            class: { abiDot: customData.discount },
                          },
                          [
                            customData.discount
                              ? _c("span", [
                                  _vm._v(
                                    "£" +
                                      _vm._s(
                                        (
                                          customData.price -
                                          (customData.price / 100) *
                                            customData.discount
                                        ).toFixed(2)
                                      )
                                  ),
                                ])
                              : _c("span", [
                                  customData.price
                                    ? _c("span", [
                                        _vm._v(
                                          "£" +
                                            _vm._s(customData.price.toFixed(2))
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            },
          },
        ]),
        model: {
          value: _vm.selectedDate,
          callback: function ($$v) {
            _vm.selectedDate = $$v
          },
          expression: "selectedDate",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-4 cal-loading mb-3" }, [
          _vm.fetchingRates
            ? _c("span", [
                _c("span", [_vm._v("Loading calendar... ")]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "spinner-border spinner-border-sm",
                  attrs: { role: "status" },
                }),
              ])
            : _c("span", [
                _vm.discountRate
                  ? _c("span", { staticClass: "abiDot-before" }, [
                      _vm._v(
                        "price includes " +
                          _vm._s(_vm.discountRate) +
                          "% off booking\n                    rate"
                      ),
                    ])
                  : _vm._e(),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-8" }, [
          _c("div", { staticClass: "d-xl-flex package-buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary flex-xl-fill mr-md-1",
                attrs: { disabled: !_vm.selectedDate },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.book.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n                    Book Now\n                ")]
            ),
            _vm._v(" "),
            _vm.giftcard != ""
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary flex-xl-fill",
                    attrs: { href: _vm.giftcard },
                  },
                  [_vm._v("Buy as Gift Card\n                ")]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }