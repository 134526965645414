<template>
    <span class="d-none d-md-inline">
        <span class="ffs"><a href="/book/account">Hi {{ hello }}.</a></span> <a @click.prevent="logout" class="small">Logout</a>
    </span>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                hello: this.system.FirstName
            }
        },

        /**
         * Methods
         */
        methods: {
            logout() {
                axios.get('/rs/logout')
                    .then(response=> {
                        Bus.$emit('RefreshSystem');
                        this.$router.push('/book/cart')
                    });
            }
        }
    }
</script>
