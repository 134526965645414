<template>
    <div class="booking-cart checkout">
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                <div class="booking-info" id="termsConditions">    
                    <h2>Payment</h2>
                    <div class="cart-page-actions__balance mt-3">
                        <span v-if="cart.FolioBalance">Ready to pay&pound;{{ cart.FolioBalance }}?</span>
                    </div>
                    <p class="text-muted mb-5">Having some technical issues or in need of assistance? Then please don’t hesitate to call our dedicated Reservations Team on <span class="globalTel">01283 576522.</span></p>
                </div>
            </div>
            <div class="col-md-8 text-center mb-5">
                <div class="checkout-terms">
                    <div class="form-check">
                        <label class="form-check-label" for="termsCheck">
                            <input v-model="termsCheck" type="checkbox" class="form-check-input" id="termsCheck">
                            <span class="checkmark"></span>
                            I agree to the Barons Eden booking <a :href="'/' + (venue ? venue : 'hoar-cross-hall') + '/terms-conditions'" target="_blank">terms and conditions</a>.
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center">
                <div class="checkout-options row justify-content-md-center">
                    <div class="col-md-10">
                        <div class="accordion" id="acc-giftcard">
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading-giftcard" data-toggle="collapse" data-target="#collapse-giftcard" aria-expanded="true" aria-controls="collapse-giftcard">
                                    <div class="clearfix">
                                        <span class="accordion-name">
                                            Redeem a gift card or e-voucher
                                            <span class="float-right"><i class="arrow"></i></span>
                                        </span>
                                    </div>
                                </div>

                                <div id="collapse-giftcard" class="collapse show" aria-labelledby="heading-giftcard" data-parent="#acc-giftcard">
                                    <div class="accordion-body">
                                        <div class="ac-body_pl">
                                            <div v-if="gcInCart">
                                                <span class="ffs">Unfortunately Gift Cards cannot be redeemed against a new Gift Card or Afternoon Tea reservations, to book an Afternoon Tea with a gift card, please call our reservations team on <a href="tel:01283 576522" target="_blank">01283 576522</a>.</span>
                                            </div>
                                            <div v-else class="row">
                                                <div class="col-md-6 voucher-details text-left">
                                                    <p>Did you know, all of our gift cards and e-vouchers hold a monetary value, meaning they can be redeemed against any experience, and used as partial or full payment? Simply enter your gift card or e-voucher number below, followed by the surname of the person who gifted you the blissful experience.</p>
                                                    <ul v-if="appliedVouchers.length > 0">
                                                        <li v-for="voucher in appliedVouchers">&pound;{{ voucher.GCRedeemedAmount }} <span>({{voucher.GiftCertId}})</span> <a @click.prevent="removeGC(voucher.GiftCertId)">Remove</a></li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-6 voucher-form">
                                                    <form @submit.prevent="checkGc">
                                                        <div>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12 mb-3">
                                                                    <input v-model="GCNumber" type="text" class="form-control" id="giftCertNo" placeholder="Gift Card / e-voucher number" required>
                                                                </div>
                                                            </div>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12 mb-4 text-left">
                                                                    <input v-model="FromLastName" type="text" class="form-control" name="FromLastName" id="FromLastName" placeholder="Purchasers surname" required>
                                                                </div>
                                                            </div>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12 mb-4">
                                                                    <button type="submit" :disabled="!termsCheck || checkingGc" class="btn btn-secondary btn-block">Apply <span v-if="checkingGc" class="spinner-border spinner-border-sm" role="status"></span></button>
                                                                </div>
                                                            </div>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12 mb-2 text-left">
                                                                    <div class="alert alert-danger" role="alert" v-if="voucherError">
                                                                       {{ voucherError }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="checkout-options row justify-content-md-center">
                    <div class="col-md-10">
                        <div class="accordion" id="acc-creditcard">
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading-creditcard" data-toggle="collapse" data-target="#collapse-creditcard" aria-expanded="true" aria-controls="collapse-creditcard">
                                    <div class="clearfix">
                                        <span class="accordion-name">
                                            Pay by credit/debit card
                                            <span class="float-right"><i class="arrow"></i></span>
                                        </span>
                                    </div>
                                </div>

                                <div id="collapse-creditcard" class="collapse show" aria-labelledby="heading-creditcard" data-parent="#acc-creditcard">
                                    <div class="accordion-body">
                                        <div class="ac-body_pl">
                                            <div class="alert alert-warning" role="alert" style="line-height: 1.2" v-if="!termsCheck">Please review and accept our <a href="#termsConditions">terms and conditions</a> to continue with your purchase</div>
                                            <button v-if="cart.FolioBalance == '0.00' && cart.Guaranteed == 'Y'" @click.prevent="doCheckoutWithGiftCard" class="btn btn-primary" :disabled="!termsCheck">
                                                <span v-if="doingGcCheckout" class="spinner-border spinner-border-sm" role="status"></span> Complete Booking
                                            </button>
                                            <button v-else @click.prevent="setCheckout(false)" :disabled="settingCheckout || !termsCheck" class="btn btn-secondary">
                                                Pay by credit/debit card
                                            </button>
                                        </div>

                                        <!-- Planet -->
                                        <div v-if="system.PayEngine == '3C'" id="planet">
                                            <form id="3cForm" method="post" target="3cFrame">
                                                <input type='hidden' name='XXX_IPGSESSION_XXX' value=''>
                                                <input type='hidden' name='trx_merchant_reference_2' value=''>
                                                <input type='hidden' name='trx_merchant_reference_3' value=''>
                                                
                                                <input type='hidden' name='merchant_script_data_1' value=''>
                                                <input type='hidden' name='merchant_script_data_2' value=''>
                                                <input type='hidden' name='merchant_script_data_3' value=''>
                                                <input type='hidden' name='content_language' value='EN'>
                                                <input type='hidden' name='redirect_approved' value=''>
                                                <input type='hidden' name='redirect_declined' value=''>
                                            </form>
                                            <iframe id="3cFrame" ref="threeCFrameRef" class="v-none" name="3cFrame" style="width:100%;"></iframe>
                                        </div>
                                        <!-- end Planet-->

                                        <!-- Worldpay-->
                                        <div class="worldpay">
                                            <div id="wpFrame"></div>
                                            <p v-if="paymentStatus && paymentStatus != 'undefined'" class="paymentError">There was an error with your payment. Please try again.</p>
                                        </div>
                                        <!-- end Worldpay-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>

        <div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3><span class="text-italic">Oh no.</span> Something went wrong...</h3>
                        <p>We couldn't process your order. It's probably because some of your details are incorrect. <strong>Please check your address is correct.</strong></p>
                        <button type="button" @click.prevent="gotoDetails" class="btn btn-primary">
                            Update my details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            cart: Object,
            system: Object,
            venue: String
        },

        components: {
            
        },

        /**
         * The components data
         */
        data() {
            return {
                paymentDetails: {
                    FirstName: this.system.FirstName,
                    LastName: this.system.LastName,
                    email: this.system.EmailAddress
                },
                SessionId: this.system.SessionId,
                WebFolioId: this.system.WebFolioId,
                CustomerId: this.system.CustomerId,
                checkoutSetup: null,
                forwardUrl: null,
                paymentStatus: this.$route.query.paymentStatus ? this.$route.query.paymentStatus : null,
                GCNumber: null,
                FromLastName: null,
                appliedVouchers: [],
                paymentError: null,
                voucherError: null,
                ppResponse: null,
                ppInit: null,
                bookingRef: null,
                doingGcCheckout: false,
                gcInCart: false,
                settingCheckout: false,
                checkingGc: false,
                termsCheck: false,
                addressThreeCForm: null
            }
        },

        beforeMount() {
            
        },

        mounted() {
            if(this.system.PayEngine == '3C') {
                this.getAddress()
            }
      
             // Is there a GC in the cart?
             if(_.some(this.cart.WebFolioItem, ['Category', 'Retail'])) {
                this.gcInCart = true
            } else {
                this.gcInCart = false
            }

            if(this.system.CustomerId != this.system.WebFolioCustomerId) {
                this.updateSession()
            }

            this.getAppliedGC();

            if(this.cart.WebFolioItem) {
                this.bookingRef = this.cart.WebFolioItem[0].FolioId
            } 
        }, 
        watch: {
            cart: {
                handler(newVal, oldVal){            
                    if(_.some(newVal.WebFolioItem, ['Category', 'Retail'])) {
                        this.gcInCart = true
                    } else {
                        this.gcInCart = false
                    }
                           
                    if(!newVal.WebFolioItem) {
                        var firstDate = JSON.parse(window.localStorage.getItem('rsbooking'))
                        
                        if(firstDate && firstDate[this.system.WebFolioId]) {
                            var start = firstDate[this.system.WebFolioId]
                            if(start.booking.type == 'stay') {
                                this.$router.push('/book/booking/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.nights + '/' + start.booking.guests + '/1')
                            } else {
                                this.$router.push('/book/booking/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.guests)
                            }
                        } else {
                            this.$router.push('/book')
                        }
                    }
                    this.bookingRef = newVal.WebFolioItem[0].FolioId
                }
            }
        },

        computed: {
            nextDayBooking() {
                var self = this
                if(self.cart.WebFolioItem) {
                    var visit = _.filter(this.cart.WebFolioItem, {'Category': 'Hotel'}) 
                    if(visit.length) {
                        var date = visit[0].StartDate.slice(0, -6)
                        var today = moment().format('YYYY-MM-DD')
                        var tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
                        if(date == today || date == tomorrow) { // same/next day booking
                            if(date == today) {
                                return 'today'
                            } else {
                                return 'tomorrow'
                            }
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },

        /**
         * Methods
         */
        methods: {
            gotoDetails() {
                $('#errorModal').modal('hide')
                this.$router.push('account')
            },

            getAddress() {
                var request = {
                    method: 'FetchCustomerProfile',
                    data: {
                        CustomerGUID: this.system.CustomerGUID,
                        CustomerId: this.system.CustomerId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.addressThreeCForm = response.data.Customer.Address ? response.data.Customer.Address[0] : null
                        } 
                    });
            },

            getAppliedGC() {
                var rsbooking = JSON.parse(localStorage.getItem('rsbooking'))
                
                if(rsbooking && rsbooking[this.system.WebFolioId]) {
                    if('appliedGCs' in rsbooking[this.system.WebFolioId]) {
                        this.appliedVouchers = rsbooking[this.system.WebFolioId].appliedGCs
                    } else {
                        rsbooking[this.system.WebFolioId]['appliedGCs'] = []
                        window.localStorage.setItem('rsbooking', JSON.stringify(rsbooking))
                    }
                }
            },

            updateSession() {
                this.settingCheckout = true
                var request = {
                    method: 'UpdateSession',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        CustomerId: this.system.CustomerId,
                        BookingAgentId: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            console.log('session updated')
                            this.settingCheckout = false
                        } else {
                            
                        }
                    });
            },

            checkGc() {
                this.voucherError = null
                this.checkingGc = true
                var request = {
                    method: 'RedeemGC',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        GCNumber: this.GCNumber,
                        GCRefNumber: null,
                        GCType: 'CERT',
                        FromLastName: this.FromLastName
                    }
                }
                // Check CERT first
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            this.applyGc(response.data)
                            this.checkingGc = false
                        } else {
                            if(response.data.Result.ErrorId == 313) {
                                this.voucherError = 'This Gift Card has zero balance. If you think this is incorrect, please contact the Reservations team on 01283 576522';
                                this.checkingGc = false
                            } else {
                                request.data.GCType = 'CARD'
                                axios.post('/rs/request', request)
                                    .then(response=> {
                                        if(response.data.Result.value == 'SUCCESS') {
                                            this.applyGc(response.data)
                                        } else {
                                            if(response.data.Result.ErrorId == 313) {
                                                this.voucherError = 'This Gift Card has zero balance. If you think this is incorrect, please contact the Reservations team on 01283 576522.'
                                            } else {
                                                this.voucherError = 'Oh dear. Something went wrong. Please check Gift Card number and surname are both correct.'
                                            }
                                            this.checkingGc = false
                                        }
                                    });
                            }
                                
                        }
                    });

            },

            applyGc(giftCard) {
                Bus.$emit('UpdateCart')

                var voucherObject = {
                    GCBalance: giftCard.GCBalance,
                    GCRedeemedAmount: giftCard.GCRedeemedAmount,
                    GiftCertId: giftCard.GiftCardId != '' ? giftCard.GiftCardId : giftCard.GiftCertId
                }

                // Update local storage
                var rsbooking = JSON.parse(localStorage.getItem("rsbooking"))
                rsbooking[this.system.WebFolioId].appliedGCs.push(voucherObject)
                window.localStorage.setItem('rsbooking', JSON.stringify(rsbooking))

                this.appliedVouchers.push(voucherObject)
                this.GCNumber = null
                this.FromLastName = null
                this.checkingGc = false

                this.setCheckout(true);
            },

            removeGC(certId) {
                var request = {
                    method: 'RefundGC',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        GiftCertId: certId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            Bus.$emit('UpdateCart')
                            
                            // Update local storage
                            var rsbooking = JSON.parse(localStorage.getItem("rsbooking"))
                            _.remove(rsbooking[this.system.WebFolioId].appliedGCs, {'GiftCertId': certId}) // Local storage
                            _.remove(this.appliedVouchers, {'GiftCertId': certId}) // voucher array
                            window.localStorage.setItem('rsbooking', JSON.stringify(rsbooking))

                            this.GCNumber = null
                            this.voucherError = null
                        }
                    });
            },

            fetchWebFolioPayments() {
                var request = {
                    method: 'FetchWebFolioPayments',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        console.log(response.data)
                    });
            },
            
            setCheckout(force = false) {
                this.settingCheckout = true;

                var params = {
                    session_id: this.system.SessionId,
                    webfolio_id: this.system.WebFolioId,
                    force: force
                }

                axios.post('/api/book/set-checkout', params)
                    .then(response => {
                        if(response.data.status === 'success') {
                            this.forwardUrl = response.data.forward_url;
                            
                            /* Worldpay */
                            if(this.system.PayEngine == 'WP') {
                                this.initaliseWorldPay()
                            }

                            /* Planet */
                            if(this.system.PayEngine == '3C') {
                                var threeCSettings =  {
                                    url: response.data.forward_url + '/iPage/Service/_2006_05_v1_0_1/service.aspx',
                                    session: response.data.three_c_session
                                }

                                this.initalisePlanetPayments(threeCSettings)
                            }    
                        } else {
                            this.paymentError = response.data.message;
                            this.settingCheckout = false;

                            $('#errorModal').modal('show');
                        }
                    });
            },

            /**
             * Planet functions
             */
             initalisePlanetPayments(threeCSettings) {
                var self = this
                var frame = document.getElementById('3cFrame');
                
                // Setup the Planet form
                $('#3cForm').attr('action', threeCSettings.url);
                $('#3cForm input[name=XXX_IPGSESSION_XXX]').val(threeCSettings.session)
                $('#3cForm input[name=trx_merchant_reference_2]').val(self.system.WebFolioId)
                $('#3cForm input[name=trx_merchant_reference_3]').val(self.system.SessionId)
                
                $('#3cForm input[name=redirect_approved]').val(window.location.origin + '/payments/3Chelper.html' )
                $('#3cForm input[name=redirect_declined]').val(window.location.origin + '/payments/3Chelper.html')
                $('#3cForm').submit();
                $('#3cFrame').removeClass('v-none');

                // Add listner to capture responses
                var listener = function(event) {
                    if (event.data && event.data.type === "3cFrameLoaded") {
                        handle3CFrameLoaded(event);
                    } else if (event.data && event.data.type === "3cReturn") {
                        handle3CReturn(event);
                    }
                };

                function handle3CFrameLoaded(event) {
                    frame.style.height = event.data.height + 'px';

                    var data = {
                        type: "address",
                        address: {
                            trx_email: self.system.EmailAddress,
                            card_holder_first_name: self.system.FirstName,
                            card_holder_last_name: self.system.LastName
                        }
                    };

                    if (self.addressThreeCForm) {
                        data.address = {
                            ...data.address,
                            address1: self.addressThreeCForm.Address1,
                            address2: self.addressThreeCForm.Address2,
                            zip_postcode: self.addressThreeCForm.PostCode,
                            city: self.addressThreeCForm.City
                        }
                    }

                    frame.contentWindow.postMessage(data, event.origin);
                }

                function handle3CReturn(event) {
                    window.removeEventListener('message', listener, true);

                    var getString = event.data.value;
                    var response = parseQueryString(getString);


                    if (!response.TxID || !response.AuthorisationCode) {
                        handleFailure(response);
                    } else {
                        handleSuccess(response);
                    }
                }

                function parseQueryString(queryString) {
                    var response = {};
                    var searchString = queryString.replace(/^\?+/, '');
                    var searchPairs = searchString.split('&');

                    searchPairs.forEach(function(pair) {
                        var keyval = pair.split('=');
                        response[keyval[0]] = keyval[1];
                    });

                    return response;
                }

                function handleFailure(response) {
                    axios.post('/api/log/client', {
                        title: '3C Failure',
                        payload: {
                            webfolio: self.system.WebFolioId,
                            user_name: self.system.FirstName + ' ' + self.system.LastName,
                            user_email: self.system.EmailAddress,
                            data: response
                        }
                    });

                    self.$router.push({ path: '/book/cart', query: { paymentError: true }});
                }

                function handleSuccess(response) {
                    axios.post('/api/log/client', {
                        title: '3C Success',
                        payload: {
                            webfolio: self.system.WebFolioId,
                            user_name: self.system.FirstName + ' ' + self.system.LastName,
                            user_email: self.system.EmailAddress,
                            data: response
                        }
                    });

                    self.doCheckout(response);

                    Bus.$emit('showLoading');
                }

                window.removeEventListener('message', listener, true);
                window.addEventListener("message", listener, true);

                self.$refs.threeCFrameRef.addEventListener('load', function() {
                    self.$refs.threeCFrameRef.contentWindow.postMessage({type: 'RsInit'}, self.forwardUrl);
                }, true);
            },

            /**
             * Worldpay functions
             */
             initaliseWorldPay() {
                // Set the Worldpay options
                var styles = {
                    backgroundColor: '#f1ede6',
                    page: {
                        backgroundColor: '#f1ede6'
                    },
                    buttons: {
                        border: {
                            radius: '0px'
                        }
                    },
                    inputs: {
                        border: {
                            radius: '0px'
                        }
                    }
                }

                // Setup Worldpay checkout
                var customOptions = {
                    type: 'iframe',
                    iframeIntegrationId: 'libraryObject',
                    inject: 'immediate',
                    iframeHelperURL: window.location.protocol + '//' + window.location.host + '/js/helper.html',
                    iframeBaseURL: window.location.protocol + '//' + window.location.host,
                    url: this.forwardUrl,
                    target: 'wpFrame',
                    resultCallback: this.resultCallback,
                    flowCallback: this.flowCallback,
                    accessibility: true,
                    debug: false,
                    language: 'en',
                    country: 'gb',
                    preferredPaymentMethod: '',
                    customisation: styles
                };

                //initialise the library and pass options
                var libraryObject = new WPCL.Library();
                libraryObject.setup(customOptions);
            },
            
            flowCallback(response) {
                this.purchaseMessage({ message: 'WorldpaySelected - ' + 'sessionId: ' + this.system.SessionId })
            },

            resultCallback(response) {
                this.purchaseMessage({ message: 'WorldpayResponded - ' + 'sessionId: ' + this.system.SessionId + ' result: ' + JSON.stringify(response) })

                var status = response.order.status;

                switch (status) {
                    case "success":
                        this.doCheckout(response)
                    break
                    case "failure":
                        this.paymentError = 'failure'
                    break
                    case "error":
                        this.paymentError = 'error'
                    break
                    case "cancelled_by_shopper":
                        this.$router.push('/book/cart')
                    break
                    default:
                        this.paymentError = 'default'
                }

            },

            /**
             * End Worldpay
             */
            checkoutFail() {
                var data = {
                    email: 'kayleigh.ford@baronseden.com',
                    CustomerId: this.system.CustomerId,
                    customer: this.system.FirstName + ' ' + this.system.LastName,
                    balance: this.cart.FolioBalance,
                    bookings: this.cart.WebFolioItem
                }

                axios.post('/system/errors/checkout-fail', data)
                    .then(response=> {
                        
                    })
            },

            doCheckout(response) {
                var params = {
                    session_id: this.system.SessionId,
                    webfolio_id: this.system.WebFolioId,
                    payment_gateway: this.system.PayEngine,
                }

                // Now apply Planet/Worldpay response
                if(this.system.PayEngine == 'WP') {
                    params.WPmac = response.gateway.mac2 ? response.gateway.mac2 : response.gateway.mac;
                    params.WPorderKey = response.gateway.orderKey;
                    params.WPstatus = response.order.status;
                    params.WPpaymentStatus = response.gateway.paymentStatus;
                    params.WPpaymentAmount = response.gateway.paymentAmount;
                    params.WPpaymentCurrency = response.gateway.paymentCurrency;
                }

                if(this.system.PayEngine == '3C') {
                    params.CardHolderName = response.FirstName + ' ' + response.LastName;
                    params.Address = response.Address;
                    params.PostalCode = response.PostCode;
                    params.TxID = response.TxID; 
                    params.AuthorisationCode = response.AuthorisationCode;
                    params.ThreeDSIndicator = response['3DSIndicator'] ? response['3DSIndicator'] : null;
                    params.SCATransRef = response.SCATransRef;
                    params.MerchantRef = response.MerchantRef;
                }

                axios.post('/api/book/do-checkout', params)
                    .then(response => {
                        if(response.data.status === 'success') {
                            this.doAnalytics();                            
                            window.localStorage.setItem('rsbooking', null);
                            this.newWebFolio();
                        } else {
                            this.purchaseMessage(response.data);
                            this.checkoutFail();  
                        }

                        this.$router.push({ path: '/book/success', query: { ref: this.bookingRef }});
                        Bus.$emit('hideLoading');
                    })
                    .catch(error => {
                        this.settingCheckout = false;
                        this.purchaseMessage(response.data);
                        Bus.$emit('hideLoading');
                        this.$router.push({ path: '/book/cart', query: { paymentError: true }});
                    });

            },


            doCheckoutWithGiftCard() {
                this.doingGcCheckout = true
                // Set checkout
                var request = {
                    method: 'SetCheckout',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.fetchBalanceForGiftCard()
                        } else {
                            this.paymentError = response.data.Result.Text
                            this.doingGcCheckout = false
                        }
                    });


            },

            fetchBalanceForGiftCard() {
                // Set checkout
                var request = {
                    method: 'FetchWebFolioBalance',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            var status = response.data
 
                            if( (status.FolioBalance == "0.00" || status.FolioBalance == "0") && status.FolioItems > 0 ) {
                                // Good to go
                                this.doingGcCheckout = false
                                this.doAnalytics()
                                this.checkoutFolio()
                                window.localStorage.setItem('rsbooking', null)
                                this.$router.push({ path: '/book/success', query: { ref: this.bookingRef }})
                            } else {
                                this.doingGcCheckout = false
                            }
                            
                        } else {
                            this.paymentError = response.data.Result.Text
                        }
                    });
            },

            checkoutFolio() {
                // If a next day booking, let Res know
                if(this.nextDayBooking) {
                    this.nextDayNotification(this.nextDayBooking, this.system.WebFolioId)
                }
 
                var request = {
                    method: 'CheckOutWebFolio',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response => {
                        this.emailReciept();
                    })
            },

            emailReciept() {
                var request = {
                    method: 'EmailWebFolioReceipt',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') { // Added to WebFolio
                            this.confirmEmail = true;
                            // Now we need a new Web Folio because this one has been checked out.
                            this.newWebFolio();
                        } else {
                            console.log(response.data)
                        }
                    });
            },

            newWebFolio() {
                axios.get('/rs/newWebFolio')
                    .then(response => {
                        this.$root.system = response.data;
                        Bus.$emit('UpdateCart')
                    });
            },

            doAnalytics() {
                const self = this;
                const cartBalance = +this.cart.FolioBalance;
                let gcTotal = 0;

                this.appliedVouchers.forEach(voucher => {
                    gcTotal += +voucher.GCRedeemedAmount;
                });

                const products = [];
                const location = this.cart.WebFolioItem[0].Location;

                this.cart.WebFolioItem.forEach(item => {
                    let productName, itemCategory, itemId, extraTotal = 0;

                    if (item.Category === "Retail") {
                        productName = item.Details;
                        itemId = item.Details;
                        itemCategory = 'Gift Cards';

                        dataLayer.push({
                            event: 'generate_lead',
                            venue: location,
                            category: itemCategory,
                            value: item.FolioTotal,
                            currency: 'GBP'
                        });
                    } else {
                        productName = item.RateDetails;
                        itemId = item.RateType;
                        itemCategory = item.Location === 'Hoar Cross Hall' ? 'Stays' : 'Spa Days';

                        dataLayer.push({
                            event: 'generate_lead',
                            venue: location,
                            category: itemCategory,
                            value: item.FolioTotal,
                            currency: 'GBP'
                        });
                    }

                    if (item.extras) {
                        extraTotal = item.extras.total;
                    }

                    const folioItemPrice = item.Category === "Retail" ? +item.FolioTotal : +item.Amount;

                    products.push({
                        id: itemId,
                        name: productName,
                        price: folioItemPrice - extraTotal,
                        brand: item.Location,
                        category: itemCategory,
                        variant:  item.Details,
                        start_date: item.StartDate,
                        finish_date: item.FinishDate,
                        quantity: 1 
                    });

                    if (item.extras && item.extras.specialServices.PMSFolioSpecialService) {
                        item.extras.specialServices.PMSFolioSpecialService.forEach(ss => {
                            products.push({
                                id: ss.PMSFolioSpecialServiceId,
                                name: ss.PMSSpecialService.PMSSpecialServiceName,
                                price: +ss.PMSSpecialService.Price,
                                brand: item.Location,
                                category: 'Extras',
                                quantity: ss.Qty,
                            });

                            dataLayer.push({
                                event: 'generate_lead',
                                venue: item.Location,
                                category: 'Extras',
                                value: +ss.TotalWithSurcharges,
                                currency: 'GBP'
                            });
                        });
                    }

                    if (item.Treatments) {
                        item.Treatments.forEach(treatment => {
                            products.push({
                                id: treatment.FolioItemId,
                                name: treatment.Details,
                                price: +treatment.Amount,
                                brand: treatment.Location,
                                category: 'Treatments',
                                quantity: 1,
                            });

                            dataLayer.push({
                                event: 'generate_lead',
                                venue: treatment.Location,
                                category: 'Treatments',
                                value: +item.Amount,
                                currency: 'GBP'
                            });
                        });
                    }
                });


                var promotionApplied = JSON.parse(window.localStorage.getItem('promotion_applied'));

                if(promotionApplied !== null && promotionApplied[this.system.WebFolioId] !== undefined) {
                    var promoCode = promotionApplied[this.system.WebFolioId];
                } else {
                    var promoCode = null;
                }

                // We need to add the GV redemption to the folio total if it exist

                const ecommerce = {
                    purchase: {
                        actionField: {
                            id: self.system.WebFolioId, //transaction ID - mandatory
                            revenue: cartBalance, //total including tax and shipping
                            currency: 'GBP', // GA4 requirement
                            coupon: promoCode,
                            promoCode: this.cart.WebFolioItem[0].promotionApplied ? this.cart.WebFolioItem[0].promotionApplied : null,
                            customerName: this.system.FirstName,
                            customerEmail: this.system.EmailAddress,
                        },
                        products: products
                    }
                };

                window.localStorage.setItem('booking_confirmation', JSON.stringify(ecommerce));

                dataLayer.push({
                    event: 'purchase',
                    venue: location,
                    ecommerce: ecommerce,
                    email: self.system.EmailAddress 
                });
                // sha256.hex(self.system.EmailAddress)

                this.$emitAnalytics(
                    'purchase', 
                    {
                        event: 'purchase',
                        venue: location,
                        ecommerce: ecommerce
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                );
            },

            purchaseMessage(message) {
                axios.post('/system/purchase-message', message)
            },

            nextDayNotification(date, webfolio) {
                var data = {
                    email: 'ota@baronseden.com',
                    subject: 'Same/next day booking notification',
                    message: 'A booking has just been made for ' + date + '. Please see Web Folio ' + webfolio + '.'
                }
                
                axios.post('/system/email-notification', data)
            },
        }
    }
</script>

<!-- Using the `scoped` attribute -->
<style lang="css">
    @import "https://payments.worldpay.com/resources/hpp/integrations/embedded/css/hpp-embedded-integration-library.css";
</style>