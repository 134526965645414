var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "ul",
          { staticClass: "slickInitVue", attrs: { id: "slick-blog-feed" } },
          _vm._l(_vm.articles, function (article) {
            return _c("li", { staticClass: "slick-slide" }, [
              _c("a", { attrs: { href: "/" + article.slug } }, [
                _c("div", { staticClass: "card mb-3" }, [
                  _c("div", {
                    staticClass: "image-header",
                    style:
                      "background-image:url(/storage/" + article.img + ");",
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("h5", { staticClass: "ffs text-uppercase font-gold" }, [
                      _vm._v(_vm._s(article.title)),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(article.summary))]),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }