<template>
    <div>
        <div v-if="success" class="text-center">
            <h3>Good Luck!</h3>
            <p>Your hint is on it’s way, we hope to see you very soon…</p>
        </div>
        <div v-else>
            <h6>If all else fails</h6> 
            <h2>Drop a hint</h2>
            <p class="mb-5">Whether you are looking to give your loved one a subtle nudge or firm push in the right direction, {{location}} has got your dream spa experience all wrapped up. Ensure you receive the gift of all gifts this year and remember to thank yourself later.</p>
            <form @submit.prevent="enquire">
                <div class="form-group row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <div class="mb-2">
                                <label for="name">Your name</label>
                                <input v-model="formData.name" name="name" ref="name" type="text" class="form-control" required />
                            </div>
                            <div class="mb-2">
                                <label for="email">Your email</label>
                                <input v-model="formData.email" name="email" ref="email" type="email" class="form-control" required />
                            </div>
                        </div>
                        <div class="hint-marketing mb-3">
                            <p class="font-italic ffss mb-4">Please note, we will not store any of your recipients data, and will only store your own personal data unless you opt in to receive marketing below.</p>
                            <div class="form-check form-check-inline pl-0">
                                <label class="form-check-label ml-0" for="email_opt_in">
                                    Want to be the first to know about all our exclusive offers and seasonal promotions?
                                    <input v-model="formData.email_opt_in" type="checkbox" class="form-check-input" id="email_opt_in">
                                    <span class="checkmark checkmark-inline ml-2"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-2">
                            <label for="recipientName">Recipient's name</label>
                            <input v-model="formData.recipientName" name="recipientName" ref="recipientName" type="text" class="form-control" required />
                        </div>
                        <div class="mb-2">
                            <label for="recipientEmail">Recipient's email address</label>
                            <input v-model="formData.recipientEmail" name="recipientEmail" ref="recipientEmail" type="email" class="form-control" required />
                        </div>
                        <div class="mb-2">
                            <label for="message">Message: (Max 150 characters)</label>
                            <textarea  v-model="formData.message" name="message" ref="message" cols="30" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-0">
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-outline-primary float-right mb-0">Submit</button>
                    </div>
                </div>
                <p class="signup-referals" v-if="success">Thank you for the referral. We will be in touch with you shortly.</p>
            </form>
        </div>
    </div>
            
</template>

<script>
    var formTemplate = function() {
        return {
            name: null,
            email: null,
            recipientName: null,
            recipientEmail: null,
            message: null,
            email_opt_in: false,
            location: null,
            package: null,
            package_url: null
        }
    }
    export default {
        props: {
            location: String,
            package: String,
            package_url: String,
        },
        data() {
            return {
                formData: new formTemplate(),
                success: null
            }
        },

        mounted() {
            
        },

        methods: {
            enquire() {
                var self = this

                // populate form data, even from autocomplete
                self.formData.name = this.$refs.name.value
                self.formData.email = this.$refs.email.value

                self.formData.location = self.location
                self.formData.package = self.package
                self.formData.package_url = self.package_url

                window.getRecaptchaToken()
                    .then(function(r) {
                        var data = {
                            formData: self.formData,
                            recaptcha: r
                        }
                        axios.post('/system/subscribe/drop-hint', data)
                            .then(response=> {
                                self.success = true;
                                self.formData = new formTemplate()

                                dataLayer.push({
                                    event: 'form_submission',
                                    form_type: 'lead_form',
                                    form_subject: 'Drop Hint',
                                    venue: self.venue,
                                });

                                this.$emitAnalytics(
                                    'form_submission', 
                                    {
                                        event: 'form_submission',
                                        form_type: 'lead_form',
                                        form_subject: 'Drop Hint',
                                        venue: self.venue,
                                    },
                                    self.system.CustomerId,
                                    self.system.WebfolioId
                                );
                            });
                    })
            },
        }
    }
</script>
