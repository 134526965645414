var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.collection
      ? _c(
          "div",
          _vm._l(_vm.collection, function (ci) {
            return _c("div", { staticClass: "collection-item" }, [
              _c("h4", [_vm._v(_vm._s(ci.ItemName))]),
              _vm._v(" "),
              _c("h6", [_vm._v(_vm._s(ci.ServiceTime) + " mins")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(ci.ItemDesc))]),
            ])
          }),
          0
        )
      : _c("div", [_c("p", [_vm._v("No treatments added.")])]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }