<template>
    <div>
        <div v-if="collection">
            <div v-for="ci in collection" class="collection-item">
                <h4>{{ ci.ItemName }}</h4>
                <h6>{{ ci.ServiceTime }} mins</h6>
                <p>{{ ci.ItemDesc }}</p>
            </div>
        </div>
        <div v-else>
            <p>No treatments added.</p>
        </div>
    </div>
            
</template>

<script>
    export default {
        props: {
            inclusives: String
        },
        data() {
            return {
                treatments: window.RS.treatments,
                collection: null,
            }
        },

        mounted() {
            this.getCollection()
        },

        methods: {
            getCollection() {
                var self = this
                if(self.inclusives) {
                    var ids = self.inclusives.split(',')
                    
                    if(ids) {
                        self.collection = _.filter(self.treatments, function(o) {
                            return _.includes(ids, o.SpaItemId.toString())
                        })
                    }
                }
            }
        }
    }
</script>
