<template>
    <div class="booking-treatments">
        <progress-indicator :step="step"></progress-indicator>
        <div class="row">
            <div class="col-md-12">
                <div class="booking-info">
                    <h2>Book a Treatment</h2>
                    <h3>{{ arrive | nicedate }}</h3>
                    <div class="message-small mt-2 mb-2">
                        <a @click.prevent="dateSideout">Change date</a>
                    </div>
                    <div class="booking-reference">
                        <div v-if="existingBookingApplied">
                            Existing booking #{{ existingBooking }}.
                            <a @click.prevent="clearExistingBooking">Change</a>
                        </div>
                        <div v-else>
                            <div class="mb-2">
                                Already have a booking? Please enter your
                                booking reference to add treatment to your
                                itinerary
                            </div>
                            <form
                                class="form-inline justify-content-center mb-3"
                            >
                                <div class="form-group mb-0">
                                    <input
                                        v-model="existingBooking"
                                        :readonly="existingBookingApplied"
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="promoCode"
                                        placeholder="E.g. 102363"
                                    />
                                </div>
                                <button
                                    @click.prevent="applyExistingBooking"
                                    class="btn btn-secondary btn-xs mb-0"
                                >
                                    Apply
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div
                    class="alert alert-warning mt-2 d-block d-md-none d-lg-none d-xl-none"
                >
                    <div class="form-group">
                        <label for="categoryFilters">Filter by category</label>
                        <select
                            v-model="filteredCategory"
                            class="form-control"
                            id="categoryFilters"
                        >
                            <option selected value="All">All</option>
                            <option value="Face">Face</option>
                            <option value="Body / Massage">Body / Massage</option>
                            <option value="Touch">Touch</option>
                            <option value="Hair">Hair</option>
                            <option value="Parlour">Parlour</option>
                            <option value="Serenity">Serenity</option>
                            <option value="Holistic">Holistic</option>
                            <option value="Express">Express</option>
                            <option value="Limited Edition">Limited Edition</option>
                        </select>
                    </div>
                </div>

                <div v-if="treatments">
                    <div
                        v-for="(tc, tkk) in treatments"
                        :key="tkk"
                        class="tab-cats"
                    >
                        <div class="row">
                            <div
                                class="col-md-12"
                                v-if="filteredCategories(tc.list).length > 0"
                            >
                                <h3>{{ tc.cat.toLowerCase() }}</h3>
                            </div>
                            <div
                                v-for="(treatment, tk) in filteredCategories(
                                    tc.list
                                )"
                                :key="tk"
                                class="col-md-12"
                            >
                                <div
                                    class="accordion"
                                    :id="
                                        'acc-' +
                                        tc.cat.replace(/[^a-zA-Z0-9-_]+/g, '-')
                                    "
                                >
                                    <div class="accordion-item">
                                        <div
                                            class="accordion-header"
                                            :id="
                                                'heading' + treatment.SpaItemId
                                            "
                                            data-toggle="collapse"
                                            :data-target="
                                                '#collapse' +
                                                treatment.SpaItemId
                                            "
                                            aria-expanded="false"
                                            :aria-controls="
                                                'collapse' + treatment.SpaItemId
                                            "
                                        >
                                            <div class="clearfix">
                                                <span class="accordion-name">{{
                                                    treatment.ItemName
                                                }}</span>
                                                <div class="float-right">
                                                    <span
                                                        >&pound;{{
                                                            treatment.Price
                                                                | price
                                                        }}</span
                                                    >
                                                    <i class="arrow"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            :id="
                                                'collapse' + treatment.SpaItemId
                                            "
                                            class="collapse"
                                            :aria-labelledby="
                                                'heading' + treatment.SpaItemId
                                            "
                                            :data-parent="
                                                '#acc-' +
                                                tc.cat.replace(
                                                    /[^a-zA-Z0-9-_]+/g,
                                                    '-'
                                                )
                                            "
                                        >
                                            <div class="accordion-body">
                                                <div>
                                                    {{ treatment.ItemDesc }}
                                                </div>
                                                <div
                                                    v-if="
                                                        treatment.TreatmentTimes
                                                    "
                                                    class="treatment-times"
                                                >
                                                    <div>Select time</div>
                                                    <button
                                                        @click.prevent="
                                                            selectTime(time)
                                                        "
                                                        v-for="(
                                                            time, ttk
                                                        ) in treatment.TreatmentTimes"
                                                        :key="ttk"
                                                        class="btn btn-tt mr-1"
                                                        :class="{
                                                            btnSelected:
                                                                time ==
                                                                selectedTime,
                                                        }"
                                                    >
                                                        {{
                                                            time.StartTime
                                                                | rstime
                                                        }}
                                                    </button>
                                                    <div class="clearfix">
                                                        <button
                                                            @click.prevent="
                                                                addTreatmentToCart()
                                                            "
                                                            class="btn btn-secondary btn-xs mr-1"
                                                            :disabled="
                                                                !selectedTime
                                                            "
                                                        >
                                                            Book
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-else class="clearfix">
                                                    <div
                                                        v-if="
                                                            treatment.noAvailability
                                                        "
                                                    >
                                                        <h6
                                                            class="text-right text-uppercase ffs mt-2"
                                                        >
                                                            Sorry, no
                                                            availability
                                                        </h6>
                                                    </div>
                                                    <div v-else>
                                                        <button
                                                            :disabled="loading"
                                                            class="btn ersed btn-xs float-right mt-2 mb-0 mr-2"
                                                            @click.prevent="
                                                                getTreatmentTimesV2(
                                                                    treatment
                                                                )
                                                            "
                                                        >
                                                            Choose a time
                                                            <div
                                                                v-if="loading"
                                                                class="spinner-border spinner-border-sm"
                                                                role="status"
                                                            ></div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="room-full-description">
                    <p>Loading treatments...</p>
                </div>
            </div>
            <div class="treatment-cart-overlay"></div>
            <div class="col-md-4 treatment-cart">
                <div class="treatment-summary">
                    <div
                        class="alert alert-warning mb-5 d-none d-md-block d-lg-block d-xl-block"
                    >
                        <div class="form-group">
                            <label for="categoryFilters"
                                >Filter by category</label
                            >
                            <select
                                v-model="filteredCategory"
                                class="form-control"
                                id="categoryFilters"
                            >
                                <option selected value="All">All</option>
                                <option value="Face">Face</option>
                                <option value="Body / Massage">
                                    Body / Massage
                                </option>
                                <option value="Touch">Touch</option>
                                <option value="Hair">Hair</option>
                                <option value="Parlour">Parlour</option>
                                <option value="Serenity">Serenity</option>
                                <option value="Holistic">Holistic</option>
                                <option value="Express">Express</option>
                                <option value="Limited Edition">
                                    Limited Edition
                                </option>
                            </select>
                        </div>
                    </div>

                    <h5 @click.prevent="toggleCart">
                        My Treatments<span
                            class="badge badge-pill badge-primary"
                            >{{ treatmentCount }}</span
                        >
                        <i v-if="treatmentCount" class="arrow"></i>
                    </h5>
                    <div class="scroll-container">
                        <div
                            v-for="t in treatmentsOnly"
                            class="treatment-summary__item"
                        >
                            <h6>{{ t.Details }}</h6>
                            <div>
                                <span>{{ t.StartDate | rsdatetimeshort }}</span>
                                <span class="float-right"
                                    >&pound;{{ t.AmountWithSurcharges }}</span
                                >
                            </div>
                            <div class="mb-2 text-small">
                                <a @click.prevent="removeTreatment(t)"
                                    >Remove</a
                                >
                            </div>
                        </div>
                    </div>
                    <button
                        v-if="treatmentsOnly.length"
                        @click.prevent="$router.push('/book/cart')"
                        class="btn btn-secondary ersed"
                    >
                        I'm Ready to pay
                    </button>
                </div>
            </div>
        </div>
        <div class="booking-app-sideout date-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a
                                @click.prevent="dateSideout()"
                                class="prevNextArrow arrowLeft"
                                >&nbsp;</a
                            >
                        </div>
                        <div class="package-content">
                            <div class="room-package">
                                <div class="room-full-description text-center">
                                    <h4>{{ arrive | nicedate }}</h4>
                                    <rate-calendar
                                        :venue="venueName"
                                        :type="type"
                                        :arrive="arrive"
                                    ></rate-calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressIndicator from "../book/ProgressIndicator.vue";
export default {
    props: {
        cart: Object,
        system: Object,
        venue: String,
        type: String,
    },
    components: {
        ProgressIndicator,
    },
    /**
     * The components data
     */
    data() {
        return {
            step: 2,
            venueName: this.$options.filters.venue(this.venue),
            arrive: this.$route.params.arrive
                ? this.$route.params.arrive
                : moment().format("YYYY-MM-DD"),
            treatments: null,
            hchDayTreatments: null,
            hchStayTreatments: null,
            edenTreatments: null,
            addingTreatment: null,
            selectedTreatment: null,
            selectedTime: null,
            loadingTimes: true,
            availability: null,
            showAvailability: false,
            treatmentUnavailable: false,
            treatmentbookError: false,
            loading: false,
            TempSpaNewCustomerForm: {
                NewCustomer: {
                    Name: {
                        FirstName: this.system.WebFolioId,
                        LastName: "TempWebFolio",
                    },
                    EmailAddress: null,
                },
                ContactLogs: {
                    ContactLog: null,
                },
            },
            changingTreatment: false,
            existingBooking: null,
            existingBookingApplied: false,
            filteredCategory: "All",
        };
    },

    mounted() {
        this.getCachedTreatments();
    },

    watch: {
        cart: function (newVal, oldVal) {},
    },

    beforeMount() {
        Bus.$on("changeDate", (date) => {
            this.arrive = date;
        });
    },
    computed: {
        noBook() {
            if (this.addingTreatment || !this.selectedTime) {
                return true;
            }
            return false;
        },
        treatmentsOnly() {
            return _.filter(this.cart.WebFolioItem, { Category: "Spa" });
        },
        treatmentCount() {
            if (this.treatmentsOnly.length == 0) {
                $(".treatment-cart-overlay").removeClass("showCart");
                $(".treatment-cart").removeClass("showCart");
                $("html").removeClass("no-scroll");
            }
            return this.treatmentsOnly.length;
        },
    },

    /**
     * Methods
     */
    methods: {
        toggleCart() {
            $(".treatment-cart-overlay").toggleClass("showCart");
            $(".treatment-cart").toggleClass("showCart");
            $("html").toggleClass("no-scroll");
        },
        dateSideout() {
            $(".date-selector").toggleClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        applyExistingBooking() {
            this.existingBookingApplied = true;
        },

        clearExistingBooking() {
            this.existingBooking = null;
            this.existingBookingApplied = false;
        },

        filteredCategories(categories) {
            if (this.filteredCategory === "All") {
                return categories;
            }
            if (this.filteredCategory === "Body / Massage") {
                return categories.filter((category) => {
                    return (
                        category.ItemImage === "Body" ||
                        category.ItemImage === "Massage"
                    );
                });
            }
            return categories.filter((category) => {
                return category.ItemImage === this.filteredCategory;
            });
        },

        isSeasonalId(idToCheck) {
            //hardcoded supplied seasonal treatment ids
            return _.includes(
                [
                    100811661, 100811663, 100811660, 100811662, 300811540,
                    300811542, 300811541,
                ],
                idToCheck
            );
        },

        getCachedTreatments() {
            if (this.system.treatments.length) {
                var tmts = _.clone(this.system.treatments);

                _.each(tmts, function (t) {
                    t.Price = parseInt(t.Price.replace(".00", ""));
                    t.TreatmentTimes = null;
                    t.noAvailability = false;
                });

                tmts = _.filter(tmts, (service) => service.Price >= 60);

                _.remove(
                    tmts,
                    (service) =>
                        this.isSeasonalId(service.SpaItemId) &&
                        ![4, 5, 6, 7, 8].includes(moment(this.arrive).month())
                );

                // Day/Stay treatments HCH

                var stay = _.filter(tmts, {
                    ClientInstructions: "Stay",
                    Location: 100,
                });
                stay = _.reject(stay, { ItemImage: "Upgrade" });

                stay = _.groupBy(stay, "ItemCategory");
                var stayArray = [];

                if (stay["SEASONAL TREATMENT OFFER"]) {
                    stayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: stay["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (stay["Face"]) {
                    stayArray.push({ cat: "Face", list: stay["Face"] });
                }
                if (stay["Body / Massage"]) {
                    stayArray.push({
                        cat: "Body / Massage",
                        list: stay["Body / Massage"],
                    });
                }
                if (stay["Specialist"]) {
                    stayArray.push({
                        cat: "Specialist",
                        list: stay["Specialist"],
                    });
                }
                if (stay["Parlour"]) {
                    stayArray.push({ cat: "Parlour", list: stay["Parlour"] });
                }
                if (stay["Holistic"]) {
                    stayArray.push({ cat: "Holistic", list: stay["Holistic"] });
                }
                if (stay["Hair"]) {
                    stayArray.push({ cat: "Hair", list: stay["Hair"] });
                }

                if (stay["RADIANCE"]) {
                    stayArray.push({ cat: "RADIANCE", list: stay["RADIANCE"] });
                }
                if (stay["REVIVE"]) {
                    stayArray.push({ cat: "REVIVE", list: stay["REVIVE"] });
                }
                if (stay["RENEWAL"]) {
                    stayArray.push({ cat: "RENEWAL", list: stay["RENEWAL"] });
                }
                if (stay["VOYA SEAWEED THERAPY"]) {
                    stayArray.push({
                        cat: "VOYA SEAWEED THERAPY",
                        list: stay["VOYA SEAWEED THERAPY"],
                    });
                }
                if (stay["HOLISTIC"]) {
                    stayArray.push({ cat: "HOLISTIC", list: stay["HOLISTIC"] });
                }
                if (stay["HANDS & FEET"]) {
                    stayArray.push({
                        cat: "HANDS & FEET",
                        list: stay["HANDS & FEET"],
                    });
                }
                if (stay["THE PARLOUR"]) {
                    stayArray.push({
                        cat: "THE PARLOUR",
                        list: stay["THE PARLOUR"],
                    });
                }

                this.hchStayTreatments = stayArray;

                var day = _.filter(tmts, {
                    ClientInstructions: "Day",
                    Location: 100,
                });
                day = _.reject(day, { ItemImage: "Upgrade" });
                day = _.groupBy(day, "ItemCategory");
                var dayArray = [];
                if (day["SEASONAL TREATMENT OFFER"]) {
                    dayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: day["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (day["Face"]) {
                    dayArray.push({ cat: "Face", list: day["Face"] });
                }
                if (day["Body / Massage"]) {
                    dayArray.push({
                        cat: "Body / Massage",
                        list: day["Body / Massage"],
                    });
                }
                if (day["Parlour"]) {
                    dayArray.push({ cat: "Parlour", list: day["Parlour"] });
                }
                if (day["Holistic"]) {
                    dayArray.push({ cat: "Holistic", list: day["Holistic"] });
                }
                if (day["Hair"]) {
                    dayArray.push({ cat: "Hair", list: day["Hair"] });
                }
                if (day["Specialist"]) {
                    dayArray.push({
                        cat: "Specialist",
                        list: day["Specialist"],
                    });
                }
                if (day["RADIANCE"]) {
                    dayArray.push({ cat: "RADIANCE", list: day["RADIANCE"] });
                }
                if (day["REVIVE"]) {
                    dayArray.push({ cat: "REVIVE", list: day["REVIVE"] });
                }
                if (day["RENEWAL"]) {
                    dayArray.push({ cat: "RENEWAL", list: day["RENEWAL"] });
                }
                if (day["VOYA SEAWEED THERAPY"]) {
                    dayArray.push({
                        cat: "VOYA SEAWEED THERAPY",
                        list: day["VOYA SEAWEED THERAPY"],
                    });
                }
                if (day["HOLISTIC"]) {
                    dayArray.push({ cat: "HOLISTIC", list: day["HOLISTIC"] });
                }
                if (day["HANDS & FEET"]) {
                    dayArray.push({
                        cat: "HANDS & FEET",
                        list: day["HANDS & FEET"],
                    });
                }
                if (day["THE PARLOUR"]) {
                    dayArray.push({
                        cat: "THE PARLOUR",
                        list: day["THE PARLOUR"],
                    });
                }

                this.hchDayTreatments = dayArray;

                // Eden
                var day = _.filter(tmts, {
                    ClientInstructions: "Day",
                    Location: 300,
                });
                day = _.reject(day, { ItemImage: "Upgrade" });
                day = _.groupBy(day, "ItemCategory");
                var dayArray = [];
                if (day["SEASONAL TREATMENT OFFER"]) {
                    dayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: day["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (day["Face"]) {
                    dayArray.push({ cat: "Face", list: day["Face"] });
                }
                if (day["Body / Massage"]) {
                    dayArray.push({
                        cat: "Body / Massage",
                        list: day["Body / Massage"],
                    });
                }
                if (day["Specialist"]) {
                    dayArray.push({
                        cat: "Specialist",
                        list: day["Specialist"],
                    });
                }
                if (day["Parlour"]) {
                    dayArray.push({ cat: "Parlour", list: day["Parlour"] });
                }
                if (day["Holistic"]) {
                    dayArray.push({ cat: "Holistic", list: day["Holistic"] });
                }
                if (day["Hair"]) {
                    dayArray.push({ cat: "Hair", list: day["Hair"] });
                }

                if (day["RADIANCE"]) {
                    dayArray.push({ cat: "RADIANCE", list: day["RADIANCE"] });
                }
                if (day["REVIVE"]) {
                    dayArray.push({ cat: "REVIVE", list: day["REVIVE"] });
                }
                if (day["RENEWAL"]) {
                    dayArray.push({ cat: "RENEWAL", list: day["RENEWAL"] });
                }
                if (day["VOYA SEAWEED THERAPY"]) {
                    dayArray.push({
                        cat: "VOYA SEAWEED THERAPY",
                        list: day["VOYA SEAWEED THERAPY"],
                    });
                }
                if (day["HOLISTIC"]) {
                    dayArray.push({ cat: "HOLISTIC", list: day["HOLISTIC"] });
                }
                if (day["HANDS & FEET"]) {
                    dayArray.push({
                        cat: "HANDS & FEET",
                        list: day["HANDS & FEET"],
                    });
                }
                if (day["THE PARLOUR"]) {
                    dayArray.push({
                        cat: "THE PARLOUR",
                        list: day["THE PARLOUR"],
                    });
                }

                this.edenTreatments = dayArray;

                if (this.venue == "hoar-cross-hall") {
                    this.treatments = this.hchDayTreatments;
                } else {
                    this.treatments = this.edenTreatments;
                }
            } else {
                // Not sure yet
            }
        },

        getTreatmentTimesV2(treatment, autoPick = false) {
            var self = this;
            treatment.TreatmentTimes = null;
            this.selectedTreatment = treatment;
            this.loading = true;
            var startTime = "093000";

            var request = {
                method: "FetchSpaAvailability",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.WebFolioCustomerId,
                    SpaItemId: treatment.SpaItemId,
                    StartDateTime: this.arrive,
                    SpaLocationId: treatment.Location,
                    SpaStaffId: null,
                    Gender: "X",
                    Version: "2",
                    IsForPackageItem: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    if (response.data.SpaAvailabilities.SpaAvailability) {
                        // we have availabilities
                        if (
                            !_.isArray(
                                response.data.SpaAvailabilities.SpaAvailability
                            )
                        ) {
                            response.data.SpaAvailabilities.SpaAvailability = [
                                response.data.SpaAvailabilities.SpaAvailability,
                            ];
                        }

                        // Clone results
                        var filteredAvailability = _.clone(
                            response.data.SpaAvailabilities.SpaAvailability
                        );

                        // Now remove any second treatment slots if the first slot is available. (Eden + 2hrs, HCH +15mins)
                        _.each(
                            response.data.SpaAvailability,
                            function (availability) {
                                if (self.venue == "eden-hall") {
                                    _.remove(
                                        filteredAvailability,
                                        function (avaToRemove) {
                                            return (
                                                moment(
                                                    availability.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                )
                                                    .add(2, "h")
                                                    .format(
                                                        "DD-MM-YYYY hh:mm:ss"
                                                    ) ==
                                                moment(
                                                    avaToRemove.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                ).format("DD-MM-YYYY hh:mm:ss")
                                            );
                                        }
                                    );
                                } else {
                                    // HCH
                                    _.remove(
                                        filteredAvailability,
                                        function (avaToRemove) {
                                            return (
                                                moment(
                                                    availability.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                )
                                                    .add(15, "m")
                                                    .format(
                                                        "DD-MM-YYYY hh:mm:ss"
                                                    ) ==
                                                moment(
                                                    avaToRemove.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                ).format("DD-MM-YYYY hh:mm:ss")
                                            );
                                        }
                                    );
                                }
                                // Remove 17:30 slot if 18:45 slot exisits at Eden & HCH
                                if (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("HH:mm:ss") == "17:30:00"
                                ) {
                                    _.remove(
                                        filteredAvailability,
                                        function (avaToRemove) {
                                            return (
                                                moment(
                                                    avaToRemove.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                ).format("HH:mm:ss") ==
                                                "18:45:00"
                                            );
                                        }
                                    );
                                }

                                // For Eden, remove that pesky 15:45 time slot
                                if (self.venue == "eden-hall") {
                                    if (
                                        moment(
                                            availability.StartTime,
                                            "YYYY-MM-DDhhmmss"
                                        ).format("HH:mm:ss") == "15:30:00"
                                    ) {
                                        _.remove(
                                            filteredAvailability,
                                            function (avaToRemove) {
                                                return (
                                                    moment(
                                                        avaToRemove.StartTime,
                                                        "YYYY-MM-DDhhmmss"
                                                    ).format("HH:mm:ss") ==
                                                    "15:45:00"
                                                );
                                            }
                                        );
                                    }
                                }
                            }
                        );

                        if (filteredAvailability.length) {
                            if (autoPick) {
                                const slotsWithMaxSpaStaff =
                                    this.findSlotsWithMaxSpaStaff(
                                        filteredAvailability
                                    );
                                const randomSlot =
                                    this.pickRandomSlot(slotsWithMaxSpaStaff);
                                this.selectTime(randomSlot);
                                this.addTreatmentToCart(true);
                            } else {
                                treatment.TreatmentTimes = filteredAvailability;
                            }
                        } else {
                            treatment.noAvailability = true;
                        }
                    } else {
                        console.log("errr");
                        treatment.noAvailability = true;
                    }
                } else {
                    treatment.noAvailability = true;
                }
                this.loading = false;
            });
        },

        calculateTotalSpaStaff(slot) {
            if (
                typeof slot.SpaStaffs.SpaStaff === "object" &&
                slot.SpaStaffs.SpaStaff !== null
            ) {
                return 1;
            } else {
                return slot.SpaStaffs.SpaStaff.length;
            }
        },

        findSlotsWithMaxSpaStaff(slots) {
            let maxStaffCount = 0;
            let maxStaffSlots = [];

            for (const slot of slots) {
                const staffCount = this.calculateTotalSpaStaff(slot);
                if (staffCount > maxStaffCount) {
                    maxStaffCount = staffCount;
                    maxStaffSlots = [slot];
                } else if (staffCount === maxStaffCount) {
                    maxStaffSlots.push(slot);
                }
            }

            return maxStaffSlots;
        },

        pickRandomSlot(slots) {
            const randomIndex = Math.floor(Math.random() * slots.length);
            return slots[randomIndex];
        },

        getTreatmentTimes(treatment) {
            var self = this;
            treatment.TreatmentTimes = null;
            this.selectedTreatment = treatment;
            this.loading = true;

            dataLayer.push({
                event: "view_item",
                venue: this.venueName,
                ecommerce: {
                    currency: "GBP",
                    value: +this.selectedTreatment.Price,
                    items: [
                        {
                            item_name: this.selectedTreatment.ItemName,
                            item_id: this.selectedTreatment.SpaItemId,
                            price: +this.selectedTreatment.Price,
                            item_brand: this.venueName,
                            item_category: "Treatments",
                            quantity: 1,
                        },
                    ],
                },
            });

            this.$emitAnalytics(
                "view_item",
                {
                    event: "view_item",
                    venue: this.venueName,
                    ecommerce: {
                        currency: "GBP",
                        value: +this.selectedTreatment.Price,
                        items: [
                            {
                                item_name: this.selectedTreatment.ItemName,
                                item_id: this.selectedTreatment.SpaItemId,
                                price: +this.selectedTreatment.Price,
                                item_brand: this.venueName,
                                item_category: "Treatments",
                                quantity: 1,
                            },
                        ],
                    },
                },
                this.system.CustomerId,
                this.system.SessionId
            );

            // For stays, we need to check if middle days as arrival time for Treatments will be 10:00am.
            var startTime = "093000";

            var request = {
                method: "FetchSpaAvailability",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.WebFolioCustomerId,
                    SpaItemId: treatment.SpaItemId,
                    StartDateTime: this.arrive,
                    SpaLocationId: treatment.Location,
                    SpaStaffId: null,
                    Gender: "X",
                    Version: null,
                    IsForPackageItem: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    if (!_.isArray(response.data.SpaAvailability)) {
                        response.data.SpaAvailability = [
                            response.data.SpaAvailability,
                        ];
                    }
                    // Clone results
                    var filteredAvailability = _.clone(
                        response.data.SpaAvailability
                    );

                    // Now remove any second treatment slots if the first slot is available. (Eden + 2hrs, HCH +15mins)
                    _.each(
                        response.data.SpaAvailability,
                        function (availability) {
                            if (self.venue == "eden-hall") {
                                _.remove(
                                    filteredAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                availability.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                                .add(2, "h")
                                                .format(
                                                    "DD-MM-YYYY hh:mm:ss"
                                                ) ==
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("DD-MM-YYYY hh:mm:ss")
                                        );
                                    }
                                );
                            } else {
                                // HCH
                                _.remove(
                                    filteredAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                availability.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                                .add(15, "m")
                                                .format(
                                                    "DD-MM-YYYY hh:mm:ss"
                                                ) ==
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("DD-MM-YYYY hh:mm:ss")
                                        );
                                    }
                                );
                            }
                            // Remove 17:30 slot if 18:45 slot exisits at Eden & HCH
                            if (
                                moment(
                                    availability.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ).format("HH:mm:ss") == "17:30:00"
                            ) {
                                _.remove(
                                    filteredAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("HH:mm:ss") == "18:45:00"
                                        );
                                    }
                                );
                            }

                            // For Eden, remove that pesky 15:45 time slot
                            if (self.venue == "eden-hall") {
                                if (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("HH:mm:ss") == "15:30:00"
                                ) {
                                    _.remove(
                                        filteredAvailability,
                                        function (avaToRemove) {
                                            return (
                                                moment(
                                                    avaToRemove.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                ).format("HH:mm:ss") ==
                                                "15:45:00"
                                            );
                                        }
                                    );
                                }
                            }
                        }
                    );

                    if (filteredAvailability.length) {
                        treatment.TreatmentTimes = filteredAvailability;
                    } else {
                        treatment.noAvailability = true;
                    }
                } else {
                    treatment.noAvailability = true;
                }
                this.loading = false;
            });
        },

        selectTime(time) {
            if (!_.isArray(time.SpaStaffs.SpaStaff)) {
                time.SpaStaffs.SpaStaff = [time.SpaStaffs.SpaStaff];
            }

            this.selectedTime = time;
        },

        checkConflicts(time, staff) {
            var request = {
                method: "FetchSpaCustomerConflictingBookings",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.tempSpaCustomerId,
                    SpaItemId: this.selectedTreatment.SpaItemId,
                    StartDateTime: time,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    this.addTreatmentToCart(time, staff);
                } else {
                    this.$modal.show({
                        message: true,
                        title: "Error",
                        text: "You already have a treatment booked at this time.",
                    });
                }
            });
        },

        addTreatmentToCart(autoPick = false) {
            Bus.$emit("showLoading");

            if (this.system.CustomerId) {
                this.bookTreatment(autoPick);
            } else {
                if (!this.system.tempSpaCustomerId) {
                    this.TempSpaNewCustomerForm.NewCustomer.FirstName =
                        this.system.WebFolioId;
                    this.TempSpaNewCustomerForm.NewCustomer.EmailAddress =
                        this.system.WebFolioId +
                        "@" +
                        this.system.WebFolioId +
                        ".com";
                    var request = {
                        method: "CreateCustomer",
                        data: this.TempSpaNewCustomerForm,
                    };
                    axios
                        .post("/rs/tempSpaAccount", request)
                        .then((response) => {
                            if (response.data.Result.value === "SUCCESS") {
                                this.system.tempSpaCustomerId =
                                    response.data.CustomerId;
                                this.system.tempLoggedIn = true;

                                this.bookTreatment(autoPick);
                            } else {
                                console.log("temp login failed");
                            }
                        });
                } else {
                    this.bookTreatment(autoPick);
                }
            }
        },

        bookTreatment(autoPick = false) {
            var request = {
                method: "CreateSpaBooking",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.CustomerId
                        ? this.system.CustomerId
                        : this.system.tempSpaCustomerId,
                    StartDateTime: this.selectedTime.StartTime,
                    SpaItemId: this.selectedTreatment.SpaItemId,
                    SpaFolioItemId: null,
                    SpaStaffId:
                        this.selectedTime.SpaStaffs.SpaStaff[0].SpaStaffId,
                    StaffRequested: null,
                    Gender: null,
                    GuestName: this.existingBooking,
                    GuestRequest: null,
                    Language: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    dataLayer.push({
                        event: "addToCart",
                        venue: this.venueName,
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                // 'add' actionFieldObject measures.
                                products: [
                                    {
                                        //  adding a product to a shopping cart.
                                        name: this.selectedTreatment.ItemName,
                                        id: this.selectedTreatment.SpaItemId,
                                        price: +this.selectedTreatment.Price,
                                        brand: this.selectedTreatment.Venue,
                                        category: "Treatment",
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    });

                    this.$emitAnalytics(
                        "addToCart",
                        {
                            event: "addToCart",
                            venue: this.venueName,
                            ecommerce: {
                                currency: "GBP",
                                add: {
                                    // 'add' actionFieldObject measures.
                                    products: [
                                        {
                                            //  adding a product to a shopping cart.
                                            name: this.selectedTreatment
                                                .ItemName,
                                            id: this.selectedTreatment
                                                .SpaItemId,
                                            price: +this.selectedTreatment
                                                .Price,
                                            brand: this.selectedTreatment.Venue,
                                            category: "Treatment",
                                            quantity: 1,
                                        },
                                    ],
                                },
                            },
                        },
                        this.system.CustomerId,
                        this.system.WebFolioId
                    );

                    Bus.$emit("UpdateCart");
                }

                Bus.$emit("hideLoading");
                this.selectedTime = null;
                this.selectedTreatment.TreatmentTimes = null;
            });
        },

        removeTreatment(treatment) {
            Bus.$emit("showLoading");
            var request = {
                method: "CancelSpaService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    SpaFolioId: treatment.FolioId,
                    SpaFolioItemId: treatment.FolioItemId,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    this.removing = null;
                    Bus.$emit("UpdateCart");
                }
                Bus.$emit("hideLoading");
            });
        },
    },
};
</script>
