var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("h3", { staticClass: "mobile-text-center mb-3" }, [
          _c("span", { staticClass: "mr-3 d-block d-md-inline mb-3" }, [
            _vm._v("Hungry for more?"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "/" + _vm.venue + "/members-portal/book-classes" },
            },
            [_vm._v("Browse Classes")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _vm.classBookings
          ? _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-members" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.classBookings, function (day) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(day.Details) +
                            "\n                                "
                        ),
                        _c("div", { staticClass: "d-md-none members-mobile" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("rsnicecompactdate")(day.StartDate))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("classtime")(day.StartDate)) +
                                " - " +
                                _vm._s(_vm._f("classtime")(day.FinishDate))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "d-none d-md-table-cell" }, [
                        _vm._v(
                          _vm._s(_vm._f("rsnicecompactdate")(day.StartDate))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "d-none d-md-table-cell" }, [
                        _vm._v(
                          _vm._s(_vm._f("classtime")(day.StartDate)) +
                            " - " +
                            _vm._s(_vm._f("classtime")(day.FinishDate))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "d-none d-md-table-cell" }, [
                        _vm._v(_vm._s(day.GymRoom)),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-sm mb-0",
                            attrs: { disabled: _vm.removingClass },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeClass(day)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    Remove\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              !_vm.andTheRest
                ? _c("div", { staticClass: "mt-4 text-right" }, [
                    _vm.cart.FolioBalance != "0.00"
                      ? _c("span", { staticClass: "mr-3" }, [
                          _vm._v("Total cost: "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-secondary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.checkout()
                          },
                        },
                      },
                      [_vm._v("Complete Booking")]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm.updatingCart
          ? _c("div", [
              _vm._v("\n                Loading bookings...\n            "),
            ])
          : _c("div", [
              _c("p", [_vm._v("Your cart is empty.")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn",
                  attrs: {
                    href: "/" + _vm.venue + "/members-portal/my-bookings",
                  },
                },
                [_vm._v("View My Bookings")]
              ),
            ]),
        _vm._v(" "),
        _vm.paymentError
          ? _c("p", { staticClass: "paymentError" }, [
              _vm._v(_vm._s(_vm.paymentError)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.andTheRest
        ? _c("div", { staticClass: "col-md-12 mt-5" }, [
            _c("h3", [_vm._v("Other bookings")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You have other items in your basket that need payment. We need to move you over to the checkout."
              ),
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "btn", attrs: { href: "/book/cart" } }, [
              _vm._v("Checkout"),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 mb-3" }, [
      _c("h3", { staticClass: "brandHeading" }, [_vm._v("Check out")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", [_vm._v("Class")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [
          _vm._v("Location"),
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Change of heart?")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }