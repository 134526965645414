<template>
    <form @submit.prevent="enquire">
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.firstName"
                    ref="firstName"
                    type="text"
                    placeholder="First Name*"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.lastName"
                    ref="lastName"
                    type="text"
                    placeholder="Last Name"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12">
                <input
                    v-model="formData.emailAddress"
                    ref="emailAddress"
                    type="email"
                    placeholder="Email"
                    class="form-control"
                    required
                />
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-8 mb-2">
                {{ consent_message }}
            </div>
            <div class="col-md-4">
                <button type="submit" class="btn btn-reversed float-right">
                    Submit
                </button>
            </div>
        </div>
        <p class="signup-message" v-if="success">
            Thank you for subscribing and welcome to the family.
        </p>
    </form>
</template>

<script>
var formTemplate = function () {
    return {
        firstName: null,
        lastName: null,
        emailAddress: null,
    };
};
export default {
    props: {
        venue: String,
        revinate_id: String,
        ga: String,
        consent_message: String,
    },
    data() {
        return {
            formData: new formTemplate(),
            success: null,
        };
    },

    mounted() {},

    methods: {
        enquire() {
            var self = this;

            // populate form data, even from autocomplete
            self.formData.firstName = this.$refs.firstName.value;
            self.formData.lastName = this.$refs.lastName.value;
            self.formData.emailAddress = this.$refs.emailAddress.value;
            self.formData.revinate_id = this.revinate_id;

            window.getRecaptchaToken().then(function (r) {
                var data = {
                    venue: self.venue,
                    formData: self.formData,
                    recaptcha: r,
                };
                axios
                    .post("/system/subscribe/subscribe-cta", data)
                    .then((response) => {
                        self.success = true;

                        dataLayer.push({
                            event: "form_submission",
                            form_type: "lead_subscription",
                            form_subject: self.ga,
                            venue: self.venue,
                        });

                        this.$emitAnalytics(
                            "form_submission",
                            {
                                event: "form_submission",
                                form_type: "lead_subscription",
                                form_subject: self.ga,
                                venue: self.venue,
                            },
                            self.system.CustomerId,
                            self.system.WebfolioId
                        );

                        self.formData = new formTemplate();
                    });
            });
        },
    },
};
</script>
