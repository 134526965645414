var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.cart.FolioItems
      ? _c(
          "a",
          { staticClass: "icons header-cart", attrs: { href: "/book/cart" } },
          [
            _c("i", { staticClass: "icon-cart" }),
            _vm._v(" "),
            _vm.updateIcon
              ? _c("span", [
                  _c("div", {
                    staticClass: "spinner-border spinner-border-sm",
                    attrs: { role: "status" },
                  }),
                ])
              : _c("span", [_vm._v("£" + _vm._s(_vm.cart.FolioBalance))]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }