<template>
    <span class="portal-header-cart">
        <a v-if="cart.WebFolioItem" :href="'/' + venue + '/members-portal/cart'">
            <span v-if="cart.WebFolioItem" class="badge badge-light">
                {{ cart.WebFolioItem.length }} 
            </span>
            <span v-if="cart.FolioBalance != '0.00'">&pound;{{ cart.FolioBalance }}</span>
        </a>
        <span v-if="hello">
            Hi {{ hello }}, <a @click.prevent="logout">Logout</a>
        </span>
    </span>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object,
            venue: String
        },
        /**
         * The components data
         */
        data() {
            return {
                hello: this.system.FirstName
            }
        },

        mounted() {
            Bus.$on('showLoading', () => {
                $('#loadingOverlay').removeClass('hide-loading')
            });
            Bus.$on('hideLoading', () => {
                $('#loadingOverlay').addClass('hide-loading')
            });
        },

        /**
         * Methods
         */
        methods: {
            logout() {
                axios.get('/rs/logout')
                    .then(response=> {
                        Bus.$emit('RefreshSystem');
                        window.location.href = '/' + this.venue + '/members-portal'
                    });
            },
        }
    }
</script>
