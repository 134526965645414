<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="slickInitVue" id="slick-blog-feed">
                    <li v-for="article in articles" class="slick-slide">
                        <a :href="'/' + article.slug">
                            <div class="card mb-3">
                                <div class="image-header" :style="'background-image:url(/storage/' + article.img + ');'"></div>
                                <div class="card-body">
                                    <h5 class="ffs text-uppercase font-gold">{{article.title}}</h5>
                                    <p>{{article.summary}}</p>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            
        },

        components: {
            
        },

        data() {
            return {
                articles: null
            }
        },
        
        mounted() {
            this.getFeed()
        },

        computed: {
            
        },

        watch: {
            
        },

        methods: {
            getFeed() {
                axios.get('/system/eden-home-feed')
                    .then(response=> {
                        this.articles = response.data
                        Vue.nextTick(function () {
                            $('.slickInitVue').slick({
                                dots: true,
                                arrows: false,
                                infinite: true,
                                autoplay: true,
                                speed: 300,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                responsive: [
                                    {
                                        breakpoint: 480,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    }
                                    // You can unslick at a given breakpoint now by adding:
                                    // settings: "unslick"
                                    // instead of a settings object
                                ]
                            });
                        })
                    })
            }
        },
    }
</script>
