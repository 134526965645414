var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gift-card-component" }, [
    _c(
      "form",
      {
        staticClass: "gift-card-order",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.addToCart.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "voucher-tabs" }, [
          _vm.giftCard
            ? _c("div", [
                _vm.packages.length > 1 && _vm.giftCardType == "generic"
                  ? _c("div", [
                      _c("h4", { staticClass: "mb-5 ffs fwb" }, [
                        _vm._v("From: £" + _vm._s(_vm.price)),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Select option")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row mb-3" }, [
                        _c("div", { staticClass: "form-group col-md-12" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.giftCard,
                                  expression: "giftCard",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { name: "Country" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.giftCard = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            _vm._l(_vm.packages, function (gc) {
                              return _c("option", { domProps: { value: gc } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(gc.Name) +
                                    " - £" +
                                    _vm._s(gc.Price) +
                                    "\n                                "
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "gift-card-order__price" }, [
                        _vm._v(
                          "\n                        Price: £" +
                            _vm._s(_vm.giftCard.Price) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _vm.giftCardType === "spa-stay"
                  ? _c("div", [
                      _c("hr"),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-4" }, [
                        _vm._v("It's time to tailor your gift..."),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Time of Week")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-sm-6 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.timeOfWeek == "mid",
                                "btn-outline-gold": _vm.timeOfWeek == "weekend",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTimeOfWeek("mid")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Midweek\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.midweekLabel))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.timeOfWeek == "weekend",
                                "btn-outline-gold": _vm.timeOfWeek == "mid",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTimeOfWeek("weekend")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Weekend\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.weekendLabel))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.enableRoomTypes
                        ? _c("div", [
                            _c("h5", [_vm._v("Room Type")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-6 text-center" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-block",
                                      class: {
                                        "btn-gold":
                                          _vm.roomType == "comfy_room",
                                        "btn-outline-gold":
                                          _vm.roomType == "comfy_suite",
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setRoomType("comfy_room")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Comfy Room\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(0),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-sm-6 text-center" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-block",
                                      class: {
                                        "btn-gold":
                                          _vm.roomType == "comfy_suite",
                                        "btn-outline-gold":
                                          _vm.roomType == "comfy_room",
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setRoomType("comfy_suite")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Comfy Suite\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(1),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.treatmentsInclusive
                        ? _c("div", [
                            _c("h5", [_vm._v("Treatment Included?")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block",
                                    class: {
                                      "btn-gold": _vm.treatmentIncluded,
                                      "btn-outline-gold":
                                        !_vm.treatmentIncluded,
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setTreatmentIncluded(true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Yes Please\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block",
                                    class: {
                                      "btn-gold": !_vm.treatmentIncluded,
                                      "btn-outline-gold": _vm.treatmentIncluded,
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setTreatmentIncluded(false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    No Thanks\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-12 text-center" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "\n                                    Recipient will be able to choose from\n                                    our\n                                    "
                                    ),
                                    _vm.collectionUrl
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.collectionUrl,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.collectionLabel))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.collectionUrl
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.collectionLabel) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.giftCardType === "spa-day"
                  ? _c("div", [
                      _c("hr"),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-4" }, [
                        _vm._v("It's time to tailor your gift..."),
                      ]),
                      _vm._v(" "),
                      _vm.enableNumberOfGuests
                        ? _c("div", [
                            _c("h5", [_vm._v("Number of Guests")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-4" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.numOfGuests,
                                        expression: "numOfGuests",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.numOfGuests = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "1" } }, [
                                      _vm._v("1 Guests"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v("2 Guests"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v("3 Guests"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "4" } }, [
                                      _vm._v("4 Guests"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "5" } }, [
                                      _vm._v("5 Guests"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "6" } }, [
                                      _vm._v("6 Guests"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Time of Week")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-sm-6 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.timeOfWeek == "mid",
                                "btn-outline-gold": _vm.timeOfWeek == "weekend",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTimeOfWeek("mid")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Midweek\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.midweekLabel))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.timeOfWeek == "weekend",
                                "btn-outline-gold": _vm.timeOfWeek == "mid",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTimeOfWeek("weekend")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Weekend\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.weekendLabel))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      !_vm.treatmentsInclusive
                        ? _c("div", [
                            _c("h5", [_vm._v("Treatment Included?")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block",
                                    class: {
                                      "btn-gold": _vm.treatmentIncluded,
                                      "btn-outline-gold":
                                        !_vm.treatmentIncluded,
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setTreatmentIncluded(true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Yes Please\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block",
                                    class: {
                                      "btn-gold": !_vm.treatmentIncluded,
                                      "btn-outline-gold": _vm.treatmentIncluded,
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setTreatmentIncluded(false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    No Thanks\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-12 text-center" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "\n                                    Recipient will be able to choose from\n                                    our\n                                    "
                                    ),
                                    _vm.collectionUrl
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.collectionUrl,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.collectionLabel))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.collectionUrl
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.collectionLabel) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.giftCardType === "afternoon-tea"
                  ? _c("div", [
                      _c("hr"),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-4" }, [
                        _vm._v("It's time to tailor your gift..."),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Number of Guests")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.numOfGuests,
                                  expression: "numOfGuests",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.numOfGuests = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v("2 Guests"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "3" } }, [
                                _vm._v("3 Guests"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "4" } }, [
                                _vm._v("4 Guests"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "5" } }, [
                                _vm._v("5 Guests"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "6" } }, [
                                _vm._v("6 Guests"),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Type of Afternoon Tea")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2 text-center" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.teaType == "classic",
                                "btn-outline-gold": _vm.teaType !== "classic",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTeaType("classic")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Classic\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v("With Heritage Teas")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.teaType == "sparkling",
                                "btn-outline-gold": _vm.teaType !== "sparkling",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTeaType("sparkling")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Sparkling\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("With Heritage Teas and Glass of Prosecco"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block",
                              class: {
                                "btn-gold": _vm.teaType == "royal",
                                "btn-outline-gold": _vm.teaType !== "royal",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setTeaType("royal")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Royal\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("With Heritage Teas and Glass of Champagne"),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h5", [_vm._v("Recipient")]),
          _vm._v(" "),
          _vm.category != "Event"
            ? _c("p", [
                _vm._v(
                  "\n                Please enter your personalised message here"
                ),
                _c("br"),
                _vm._v(
                  "\n                (this will be printed on the gift voucher)\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h5", [_vm._v("Name")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-8" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.emailOrderForm.IssueToName,
                    expression: "emailOrderForm.IssueToName",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "IssueToName",
                  placeholder: "Enter recipients name",
                  required: "",
                },
                domProps: { value: _vm.emailOrderForm.IssueToName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.emailOrderForm,
                      "IssueToName",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm.category != "Event"
            ? _c("div", [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-12" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.emailOrderForm.Message,
                          expression: "emailOrderForm.Message",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        cols: "8",
                        rows: "6",
                        id: "Message",
                        placeholder: "Enter your message (max 300 characters)",
                        maxlength: "300",
                      },
                      domProps: { value: _vm.emailOrderForm.Message },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.emailOrderForm,
                            "Message",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "small" }, [
                      _vm._v(
                        "\n                            Characters remaining:\n                            " +
                          _vm._s(300 - _vm.emailOrderForm.Message.length) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.category != "Event"
            ? _c("h5", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "\n                Select your voucher type\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "ul",
                {
                  staticClass: "nav nav-tabs",
                  attrs: { id: "voucherTabs", role: "tablist" },
                },
                [
                  _vm.category != "Event"
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: {
                              active: _vm.voucherType == "CARD-GIFT",
                            },
                            attrs: {
                              id: "card-tab'",
                              "data-toggle": "tab",
                              role: "tab",
                              "aria-controls": "card-gift-tab",
                              "aria-selected": {
                                true: _vm.voucherType == "CARD-GIFT",
                              },
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectVoucherType("CARD-GIFT")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Gift Card + Elemis Giftset\n                            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.category != "Event" && !_vm.disableShipping
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: { active: _vm.voucherType == "CARD" },
                            attrs: {
                              id: "card-tab'",
                              "data-toggle": "tab",
                              role: "tab",
                              "aria-controls": "card-tab",
                              "aria-selected": {
                                true: _vm.voucherType == "CARD",
                              },
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectVoucherType("CARD")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Gift Card\n                            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { active: _vm.voucherType == "CERT" },
                        attrs: {
                          id: "cert-tab'",
                          "data-toggle": "tab",
                          role: "tab",
                          "aria-controls": "cert-tab",
                          "aria-selected": {
                            true: _vm.voucherType == "CERT",
                          },
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectVoucherType("CERT")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                E-Voucher\n                            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-content",
                  attrs: { id: "voucherTabsContent" },
                },
                [
                  _vm.tabsLoading
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-content-center my-4" },
                        [_vm._m(3)]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.tabsLoading &&
                            _vm.voucherType == "CARD-GIFT" &&
                            _vm.category != "Event",
                          expression:
                            "\n                                !tabsLoading &&\n                                voucherType == 'CARD-GIFT' &&\n                                category != 'Event'\n                            ",
                        },
                      ],
                      attrs: {
                        id: "cardGiftPanel",
                        role: "tabpanel",
                        "aria-labelledby": "cardGiftPanel",
                      },
                    },
                    [
                      _vm.giftCard && _vm.giftCard.shippingRates
                        ? _c("div", [
                            _vm._m(4),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.giftCard.shippingRates, function (s) {
                                return s.ShippingId !== 103012
                                  ? _c("div", { staticClass: "col-md-6" }, [
                                      _c("div", [
                                        _c("img", {
                                          staticClass: "img-fluid mb-4",
                                          attrs: {
                                            src: _vm.getGiftImagery(s.Id),
                                            loading: "lazy",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.shippingSelected,
                                              expression: "shippingSelected",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "radio",
                                            name: `gift-${s.Id}`,
                                            id: `gift-${s.Id}`,
                                            checked: "",
                                          },
                                          domProps: {
                                            value: s,
                                            checked: _vm._q(
                                              _vm.shippingSelected,
                                              s
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.shippingSelected = s
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: { for: `gift-${s.Id}` },
                                          },
                                          [
                                            _c("h4", [_vm._v(_vm._s(s.Name))]),
                                            _vm._v(" "),
                                            _c("hr"),
                                            _vm._v(" "),
                                            _c("small", [
                                              _c("div", {
                                                staticClass: "gift-description",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getGiftDescription(s)
                                                  ),
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e()
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.category == "Event"
                        ? _c("p", [
                            _vm._v(
                              "\n                                The recipient is the name of the person\n                                attending the event.\n                            "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              "\n                                The delivery address is the address of the\n                                person you would like us to send the gift\n                                card to. If you would like this delivered to\n                                yourself, please enter your own address.\n                            "
                            ),
                          ]),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.Address1,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .Address1\n                                        ",
                              },
                            ],
                            ref: "Address1",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "Address1",
                              placeholder: "Address line 1",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.Address1,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "Address1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.Address2,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .Address2\n                                        ",
                              },
                            ],
                            ref: "Address2",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "Address2",
                              placeholder: "Address line 2",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.Address2,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "Address2",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailOrderForm.MailToAddress.City,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .City\n                                        ",
                              },
                            ],
                            ref: "City",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "City",
                              placeholder: "City",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.City,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "City",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-4" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.PostCode,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .PostCode\n                                        ",
                              },
                            ],
                            ref: "PostCode",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "PostCode",
                              placeholder: "Post Code",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.PostCode,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "PostCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.emailOrderForm.MailToAddress.Country,
                                  expression:
                                    "\n                                            emailOrderForm.MailToAddress\n                                                .Country\n                                        ",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { name: "Country" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.emailOrderForm.MailToAddress,
                                    "Country",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.countryList, function (country) {
                              return _c(
                                "option",
                                { domProps: { value: country } },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(country) +
                                      "\n                                        "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(7),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _vm.voucherError
                            ? _c("span", { staticClass: "small" }, [
                                _vm._v(_vm._s(_vm.voucherError)),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.tabsLoading && _vm.voucherType == "CERT",
                          expression: "!tabsLoading && voucherType == 'CERT'",
                        },
                      ],
                      attrs: {
                        id: "certPanel",
                        role: "tabpanel",
                        "aria-labelledby": "certPanel-tab",
                      },
                    },
                    [
                      _vm.category == "Event"
                        ? _c("p", [
                            _vm._v(
                              "\n                                The recipient is the name of the person\n                                attending the event.\n                            "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              "\n                                The delivery address is the address of the\n                                person you would like us to send the gift\n                                card to. If you would like this delivered to\n                                yourself, please enter your own address.\n                            "
                            ),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailOrderForm.EmailAddress,
                                expression:
                                  "\n                                            emailOrderForm.EmailAddress\n                                        ",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "email",
                              id: "EmailAddress",
                              placeholder: "Enter recipients email address",
                              required: _vm.voucherType == "CERT",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.EmailAddress,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm,
                                  "EmailAddress",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-10 mb-0" },
                          [
                            _vm.voucherError
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v(_vm._s(_vm.voucherError)),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.tabsLoading &&
                            _vm.voucherType == "CARD" &&
                            _vm.category != "Event",
                          expression:
                            "\n                                !tabsLoading &&\n                                voucherType == 'CARD' &&\n                                category != 'Event'\n                            ",
                        },
                      ],
                      attrs: {
                        id: "cardPanel",
                        role: "tabpanel",
                        "aria-labelledby": "cardPanel-tab",
                      },
                    },
                    [
                      _vm.category == "Event"
                        ? _c("p", [
                            _vm._v(
                              "\n                                The recipient is the name of the person\n                                attending the event.\n                            "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              "\n                                The delivery address is the address of the\n                                person you would like us to send the gift\n                                card to. If you would like this delivered to\n                                yourself, please enter your own address.\n                            "
                            ),
                          ]),
                      _vm._v(" "),
                      _vm.giftCard &&
                      _vm.giftCard.shippingRates &&
                      !_vm.disableShipping
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-12" }, [
                              _c("h5", [_vm._v("Select postage")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btn-group",
                                  attrs: {
                                    role: "group",
                                    "aria-label": "Postage Options",
                                  },
                                },
                                _vm._l(
                                  _vm.giftCard.shippingRates,
                                  function (s) {
                                    return s.Price
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-shipping",
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                s.ShippingId ==
                                                _vm.shippingSelected.ShippingId,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.selectShippingType(s)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(s.Name) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _vm.shippingSelected
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "small d-block text-uppercase fwb",
                                      },
                                      [_vm._v("Shipping selected:")]
                                    ),
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.shippingSelected.Name) +
                                        " -\n                                        £" +
                                        _vm._s(
                                          _vm.shippingSelected
                                            .PriceWithSurcharges
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._m(8),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.Address1,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .Address1\n                                        ",
                              },
                            ],
                            ref: "Address1",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "Address1",
                              placeholder: "Address line 1",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.Address1,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "Address1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.Address2,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .Address2\n                                        ",
                              },
                            ],
                            ref: "Address2",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "Address2",
                              placeholder: "Address line 2",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.Address2,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "Address2",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailOrderForm.MailToAddress.City,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .City\n                                        ",
                              },
                            ],
                            ref: "City",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "City",
                              placeholder: "City",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.City,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "City",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-4" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.emailOrderForm.MailToAddress.PostCode,
                                expression:
                                  "\n                                            emailOrderForm.MailToAddress\n                                                .PostCode\n                                        ",
                              },
                            ],
                            ref: "PostCode",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "PostCode",
                              placeholder: "Post Code",
                              required: _vm.voucherType == "CARD",
                            },
                            domProps: {
                              value: _vm.emailOrderForm.MailToAddress.PostCode,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.emailOrderForm.MailToAddress,
                                  "PostCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.emailOrderForm.MailToAddress.Country,
                                  expression:
                                    "\n                                            emailOrderForm.MailToAddress\n                                                .Country\n                                        ",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { name: "Country" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.emailOrderForm.MailToAddress,
                                    "Country",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.countryList, function (country) {
                              return _c(
                                "option",
                                { domProps: { value: country } },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(country) +
                                      "\n                                        "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(9),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-8" }, [
                          _vm.voucherError
                            ? _c("span", { staticClass: "small" }, [
                                _vm._v(_vm._s(_vm.voucherError)),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-row mt-3" }, [
                _c("div", { staticClass: "form-group col-md-12" }, [
                  _vm.category != "Event"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "/gift-cards" },
                        },
                        [_vm._v("Back to all Gift Cards")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-reversed",
                      attrs: { type: "submit", disabled: _vm.addingGc },
                    },
                    [
                      _vm._v(
                        "\n                                Add to cart\n                            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.addingGc
                    ? _c(
                        "div",
                        {
                          staticClass: "spinner-border spinner-border-md",
                          attrs: { role: "status" },
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Loading..."),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gift-card-cart" },
      [
        _c("gift-card-cart", {
          attrs: { system: _vm.system, cart: _vm.cart, next: "cart" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "/hoar-cross-hall/hotel-rooms/comfy",
            target: "_blank",
          },
        },
        [_vm._v("View Room")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "/hoar-cross-hall/hotel-rooms/comfy-suite",
            target: "_blank",
          },
        },
        [_vm._v("View Room")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", [
      _vm._v("Message "),
      _c("span", { staticClass: "small" }, [_vm._v("(optional)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h5", [_vm._v("Select your Elemis Giftset")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("h5", [_vm._v("Select postage")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group",
            attrs: { role: "group", "aria-label": "Postage Options" },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-shipping",
                attrs: { type: "button", disabled: "disabled" },
              },
              [
                _vm._v(
                  "\n                                            1st Class Post\n                                        "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "small d-block text-uppercase fwb" }, [
            _vm._v("Shipping selected:"),
          ]),
          _vm._v(
            "\n                                        1st Class Post - £5.00\n                                    "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-9" }, [
        _c("h5", [_vm._v("Delivery address")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("p", { staticClass: "small" }, [
          _vm._v(
            "\n                                        Please note, we allow a 48 hour\n                                        processing time for all postal gift\n                                        cards. Delivery by Royal Mail can\n                                        take 5 -7 days, and we are unable to\n                                        take any responsibility for Royal\n                                        Mail delays.\n                                    "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-9" }, [
        _c("h5", [_vm._v("Delivery address")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("p", { staticClass: "small" }, [
          _vm._v(
            "\n                                        Please note, we allow a 48 hour\n                                        processing time for all postal gift\n                                        cards. Delivery by Royal Mail can\n                                        take 5 -7 days, and we are unable to\n                                        take any responsibility for Royal\n                                        Mail delays.\n                                    "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }