import { render, staticRenderFns } from "./AccountComponent.vue?vue&type=template&id=f363f570"
import script from "./AccountComponent.vue?vue&type=script&lang=js"
export * from "./AccountComponent.vue?vue&type=script&lang=js"
import style0 from "./AccountComponent.vue?vue&type=style&index=0&id=f363f570&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.baronseden.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f363f570')) {
      api.createRecord('f363f570', component.options)
    } else {
      api.reload('f363f570', component.options)
    }
    module.hot.accept("./AccountComponent.vue?vue&type=template&id=f363f570", function () {
      api.rerender('f363f570', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/book/AccountComponent.vue"
export default component.exports