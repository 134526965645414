var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking-stay" },
    [
      _vm._l(_vm.notify, function (n) {
        return _c("div", [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "booking-date-notice" }, [
                _c("span", { domProps: { innerHTML: _vm._s(n.message) } }),
              ]),
            ]),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-10" }, [
          _c(
            "div",
            { staticClass: "booking-info" },
            [
              _c("h2", [
                _vm._v("Your " + _vm._s(_vm._f("slugToTitle")(_vm.type))),
              ]),
              _vm._v(" "),
              _c("h4", [
                _c(
                  "a",
                  {
                    staticClass: "prevNextArrow arrowLeft",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.prevDay()
                      },
                    },
                  },
                  [_vm._v(" ")]
                ),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm._f("nicedate")(_vm.arrive)) +
                    "\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass: "prevNextArrow arrowRight",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.nextDay()
                      },
                    },
                  },
                  [_vm._v(" ")]
                ),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "guests-nights-selector" }, [
                _c("li", [
                  _c("p", { staticClass: "mb-0" }, [_vm._v("Guests")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-plus-minus",
                      on: { click: _vm.decGuests },
                    },
                    [_vm._v("-")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "book-int" }, [
                    _vm._v(_vm._s(_vm.guests)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-plus-minus",
                      on: { click: _vm.incGuests },
                    },
                    [_vm._v("+")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message-small mt-2" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.dateSideout.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Change date")]
                ),
              ]),
              _vm._v(" "),
              _c("progress-indicator", { attrs: { step: _vm.step } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "package-list" }, [
            _vm.rates
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.rates, function (rate, key) {
                    return _c("div", { key: key, staticClass: "col-12" }, [
                      _c("div", { staticClass: "package-container" }, [
                        _c(
                          "div",
                          {
                            staticClass: "package-image",
                            style:
                              "background-image:url(" +
                              rate.PMSRateTypeImage +
                              ");",
                          },
                          [_vm._v(" ")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "package-type" }, [
                          _c("h3", [_vm._v(_vm._s(rate.PMSRateTypeDesc))]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fromPrice" }, [
                            rate.SingleRate
                              ? _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-1",
                                      class: [
                                        rate.isDiscounted
                                          ? "advancedPrice"
                                          : "",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "£" +
                                          _vm._s(
                                            _vm._f("currency")(rate.SingleRate)
                                          )
                                      ),
                                    ]
                                  ),
                                  rate.isDiscounted
                                    ? _c("span", [
                                        _vm._v(
                                          "£" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                rate.isDiscounted
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(
                              " (per person)\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "totalPrice" }, [
                            rate.SingleRate
                              ? _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-1",
                                      class: [
                                        rate.isDiscounted
                                          ? "advancedPrice"
                                          : "",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "£" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              rate.SingleRate * _vm.guests
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                  rate.isDiscounted
                                    ? _c("span", [
                                        _vm._v(
                                          "£" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                rate.isDiscounted * _vm.guests
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(
                              " (total price)\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-secondary d-lg-none mb-0 align-self-end",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.selectedRate = rate
                                  _vm.bookSingles()
                                },
                              },
                            },
                            [_vm._v("Book")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline-primary d-lg-none mb-0 align-self-end",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.viewMoreDetails(rate)
                                },
                              },
                            },
                            [_vm._v("View more details")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "package-description" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                rate.PMSRateTypeCustDesc.substring(0, 180)
                              ) +
                              "...\n\n                                "
                          ),
                          _c(
                            "div",
                            { staticClass: "d-xl-flex package-buttons" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-secondary flex-xl-fill mr-md-1",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.selectedRate = rate
                                      _vm.bookSingles()
                                    },
                                  },
                                },
                                [_vm._v("Book")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline-primary flex-xl-fill",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.viewMoreDetails(rate)
                                    },
                                  },
                                },
                                [_vm._v("View details")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _c("div", [
                  _c("div", { staticClass: "no-availability-message" }, [
                    _vm.loadingPackages
                      ? _c("div", { staticClass: "text-center" }, [
                          _c("h4", [
                            _vm._v(
                              "Please wait while we fetch all the packages."
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "text-center" }, [
                          _c("h4", [
                            _vm._v(
                              "Sorry, there is no availability on this date. Please choose another date."
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.dateSideout.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Select another date")]
                          ),
                        ]),
                  ]),
                ]),
          ]),
          _vm._v(" "),
          _vm.upsellPrice
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-md-8 mt-2" }, [
                  _c("div", { staticClass: "p-3 text-center" }, [
                    _c(
                      "h5",
                      [
                        _vm._v(
                          "Stay overnight from an extra £" +
                            _vm._s(_vm._f("currency")(_vm.upsellPrice)) +
                            " per person. "
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/book/booking/hoar-cross-hall/stay/" +
                                _vm.arrive +
                                "/1/2",
                            },
                          },
                          [_vm._v("View Stay Packages")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "booking-app-sideout package-selector" }, [
        _c("div", { staticClass: "sideout-wrapper" }, [
          _c("div", { staticClass: "sideout-container" }, [
            _vm.selectedRate
              ? _c("div", { staticClass: "sideout-content rate-details" }, [
                  _c("div", { staticClass: "package-header" }, [
                    _c(
                      "a",
                      {
                        staticClass: "prevNextArrow arrowLeft",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeSideout()
                          },
                        },
                      },
                      [_vm._v(" ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "package-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "room-package-image",
                        style:
                          "background-image:url(" +
                          _vm.selectedRate.PMSRateTypeImage +
                          ");",
                      },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "room-full-description" }, [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.selectedRate.PMSRateTypeDesc)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedRate.details.intro),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedRate.details.details),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "package-cta" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-block",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.bookSingles.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Book")]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "booking-app-sideout date-selector" }, [
        _c("div", { staticClass: "sideout-wrapper" }, [
          _c("div", { staticClass: "sideout-container" }, [
            _c("div", { staticClass: "sideout-content" }, [
              _c("div", { staticClass: "package-header" }, [
                _c(
                  "a",
                  {
                    staticClass: "prevNextArrow arrowLeft",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.dateSideout()
                      },
                    },
                  },
                  [_vm._v(" ")]
                ),
                _vm._v(" \n                    "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "package-content" }, [
                _c(
                  "div",
                  { staticClass: "room-full-description text-center" },
                  [
                    _c("h4", [_vm._v(_vm._s(_vm._f("nicedate")(_vm.arrive)))]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-1" }, [
                      _vm._v(
                        "Please note, gift voucher codes are entered at checkout."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("rate-calendar", {
                      attrs: {
                        venue: _vm.venueName,
                        type: _vm.type,
                        mindate: _vm.mindate,
                        arrive: _vm.arrive,
                        promo: _vm.promoCode,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.confirmNotifications, function (notification, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "modal fade blush-modal",
            attrs: {
              id: "confirmNotification_" + notification.id,
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "confirmNotification_" + notification.id,
              "aria-hidden": "true",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-dialog modal-dialog-centered",
                attrs: { role: "document" },
              },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "modal-body text-center",
                    domProps: { innerHTML: _vm._s(notification.message) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.chooseAnotherDate(notification.id)
                          },
                        },
                      },
                      [_vm._v("Select Another Date")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", "data-dismiss": "modal" },
                      },
                      [_vm._v("Accept")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade blush-modal",
          attrs: {
            id: "groupsModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "groupsModalTitle",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(2),
                _vm._v(" "),
                _vm.venueName === "Eden Hall - Day Spa"
                  ? _c("div", { staticClass: "modal-body text-center" }, [
                      _vm._m(3),
                    ])
                  : _c("div", { staticClass: "modal-body text-center" }, [
                      _c("p", [
                        _vm._v(
                          "Then look no further than our collection of group spa experiences, created for milestone celebrations, general get-togethers and corporate team away days."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Please call our Guest Experience Team on 01608 787986, who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-block mt-4 mb-0",
                          attrs: { href: "/hoar-cross-hall/group-experiences" },
                        },
                        [_vm._v("View our group Spa collection")]
                      ),
                    ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-6 mt-3" }, [
        _c("div", { staticClass: "gc-box" }, [
          _c("h5", [_vm._v("Redeeming a Gift Card or E-voucher?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Did you know, all of our gift vouchers hold a monetary value and can be redeemed against any package. Simply select your chosen package, then enter your voucher code at checkout to redeem."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header text-center" }, [
      _c("h5", { staticClass: "modal-title w-100" }, [
        _vm._v("Important Notice"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header border-none" }, [
      _c("h5", { staticClass: "modal-title w-100 text-center" }, [
        _vm._v("Booking on behalf of a group?"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("For groups over six, please call our Guest Experience Team on "),
      _c("a", { attrs: { href: "tel:+441608787986" } }, [
        _vm._v("01608 787986"),
      ]),
      _vm._v(
        ", who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }