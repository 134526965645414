<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-3">
                <h3 class="brandHeading">Check out</h3>
            </div>
            <div class="col-md-12">
                <h3 class="mobile-text-center mb-3">
                    <span class="mr-3 d-block d-md-inline mb-3">Hungry for more?</span>
                    <a :href="'/' + venue + '/members-portal/book-classes'"  class="btn btn-primary">Browse Classes</a>
                </h3>
            </div>
            <div class="col-md-12">
                <div v-if="classBookings" class="table-responsive">
                    <table class="table table-members">
                        <thead class="thead-dark">
                            <tr>
                                <th>Class</th>
                                <th class="d-none d-md-table-cell">Date</th>
                                <th class="d-none d-md-table-cell">Time</th>
                                <th class="d-none d-md-table-cell">Location</th>
                                <th>Change of heart?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(day) in classBookings">
                                <td>
                                    {{ day.Details }}
                                    <div class="d-md-none members-mobile">
                                        <span>{{ day.StartDate | rsnicecompactdate }}</span>
                                        <span>{{ day.StartDate | classtime }} - {{ day.FinishDate | classtime }}</span>
                                    </div>
                                </td>
                                <td class="d-none d-md-table-cell">{{ day.StartDate | rsnicecompactdate }}</td>
                                <td class="d-none d-md-table-cell">{{ day.StartDate | classtime }} - {{ day.FinishDate | classtime }}</td>
                                <td class="d-none d-md-table-cell">{{ day.GymRoom }}</td>
                                <td>
                                    <button @click.prevent="removeClass(day)" :disabled="removingClass" class="btn btn-primary btn-sm mb-0">
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="!andTheRest" class="mt-4 text-right">
                        <span v-if="cart.FolioBalance != '0.00'" class="mr-3">Total cost: </span> <a @click.prevent="checkout()" class="btn btn-secondary">Complete Booking</a>
                    </div>
                </div>
                <div v-else-if="updatingCart">
                    Loading bookings...
                </div>
                <div v-else>
                    <p>Your cart is empty.</p>
                    <a :href="'/' + venue + '/members-portal/my-bookings'"  class="btn">View My Bookings</a>
                </div>
                <p v-if="paymentError" class="paymentError">{{ paymentError }}</p>
            </div>
            <div v-if="andTheRest" class="col-md-12 mt-5">
                <h3>Other bookings</h3>
                <p>You have other items in your basket that need payment. We need to move you over to the checkout.</p>
                <a href="/book/cart" class="btn">Checkout</a>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            location: Number,
            system: Object,
            cart: Object,
            venue: String
        },
        /**
         * The components data
         */
        data() {
            return {
                isActive: true,
                removing: false,
                ssTotal: 0,
                updatingCart: null,
                doingCheckout: false,
                paymentError: null,
                removingClass: false,
                loadingClasses: false,
            }
        },
        mounted() {
            Bus.$emit('RefreshSystem')
        },
        beforeMount() {
            Bus.$on('clearCartFromSummary', () => {
                this.confirmClearCart();
            });
            Bus.$on('updatingCartComponent', () => {
                this.updatingCart = true;
            });
            Bus.$on('StopUpdatingCartComponent', () => {
                this.updatingCart = false;
            });
        },
        computed: {
            andTheRest() {
                var cats = _.groupBy(this.cart.WebFolioItem, 'Category');
                if(cats['Hotel']) {
                    cats['Your Visit'] = cats['Hotel'];
                    delete cats['Hotel']
                }
                if(cats['Spa']) {
                    cats['Treatments'] = cats['Spa']
                    delete cats['Spa']
                }
                if(cats['Retail']) {
                    cats['Gift Cards'] = cats['Retail']
                    delete cats['Retail']
                }
                if(cats['Classes']) {
                    delete cats['Classes']
                }
                if(_.isEmpty(cats)) {
                    return null
                } else {
                    return cats;
                }
            },
            classBookings() {
                var cats = _.groupBy(this.cart.WebFolioItem, 'Category');
                if(cats['Classes']) {
                    return cats.Classes;
                }
            },
        },
        watch: {
            cart() {
                this.updatingCart = null
            },
        },
        /**
         * Methods
         */
        methods: {
            checkout() {
                this.doingCheckout = true
                Bus.$emit('showLoading')
                this.setCheckout()
            },

            updateSession() {
                var request = {
                    method: 'UpdateSession',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        CustomerId: this.system.CustomerId,
                        BookingAgentId: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.setCheckout()
                        } else {
                            this.paymentError = response.data.Result.Text
                            this.doingCheckout = false
                        }
                    });
            },
            
            setCheckout() {
                var request = {
                    method: 'SetCheckout',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.checkBalance()
                        } else {
                            this.updateSession()
                        }
                    });
            },

            checkBalance() {
                var request = {
                    method: 'FetchWebFolioBalance',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            var status = response.data

                            if( (status.FolioBalance == "0.00" || status.FolioBalance == "0") && this.cart.WebFolioItem ) {
                                // Good to go
                                this.doingCheckout = false
                                this.checkoutFolio()
                            } else {
                                this.doingCheckout = false
                            }
                            
                        } else {
                            this.paymentError = response.data.Result.Text
                        }
                    });
            },

            checkoutFolio() {
                var request = {
                    method: 'CheckOutWebFolio',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response => {
                        this.emailReciept();
                        Bus.$emit('refreshMembersBookings');
                    })
            },

            emailReciept() {
                var cleanedBookings = []
                _.each(this.cart.WebFolioItem, function(o) {
                    var startDate = o.StartDate.substr(0, o.StartDate.length-6);
                    var startTime = o.StartDate.substr(10, 6);
                    var start = moment(new Date(startDate + 'T' + startTime.match(/.{1,2}/g).join(':')))

                    var endDate = o.FinishDate.substr(0, o.FinishDate.length-6);
                    var endTime = o.FinishDate.substr(10, 6);
                    var end = moment(new Date(endDate + 'T' + endTime.match(/.{1,2}/g).join(':')))

                    o.StartDate = start.format('Do MMM YYYY, h:mma')
                    o.Duration = end.utc().diff(start.utc(), 'minutes')
                    cleanedBookings.push(o)
                })
                
                var request = {
                    name: this.system.FirstName,
                    email: this.system.EmailAddress,
                    location: this.location == 200 ? 'Hoar Cross Hall' : 'Eden Hall',
                    bookings: cleanedBookings
                }
                axios.post('/system/members/confirmation', request)
                    .then(response=> {
                        this.confirmEmail = true;
                        // Now we need a new Web Folio because this one has been checked out.
                        this.newWebFolio();
                    });
            },

            newWebFolio() {
                axios.get('/rs/newWebFolio')
                    .then(response=> {
                        Bus.$emit('updateSession');
                        Bus.$emit('UpdateCart')
                        Bus.$emit('hideLoading')
                        // Redirect to my bookings
                        //window.location.href = '/' + this.venue + '/members-portal/my-bookings'
                    });
            },

            clearCart() {
                var self = this;
                _.each(this.cart.WebFolioItem, function(item) {
                    self.removeBooking(item.FolioId);
                })
                this.$router.push('/book')
            },
        
            removeClass(day) {
                Bus.$emit('showLoading')
                this.removing = true;
                var request = {
                    method: 'CancelSpaService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        SpaFolioId: day.FolioId,
                        SpaFolioItemId: day.FolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.removing = false
                            
                            Bus.$emit('UpdateCart');
                            Bus.$emit('hideLoading')
                        }
                    });
            },

            removeExtras(id, folio) {
                this.removing = id;
                var request = {
                    method: 'RemovePMSSpecialService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        PMSFolioId: folio,
                        PMSFolioSpecialServiceId: id
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.removing = null
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            removeGiftCard(RetFolioId, RetFolioItemId) {
                var request = {
                    method: 'CancelRetailItem',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        RetFolioId: RetFolioId,
                        RetFolioItemId: RetFolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            
                            Bus.$emit('UpdateCart');
                        }
                    });
            },
            viewCart() {
                this.$router.push('/book/cart')
            },
            fss() {

                var request = {
                    method: 'FetchSystemState',
                    data: {
                        WebFolioId: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        
                    });
            }
        }
    }
</script>