var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "forgotPasswordModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "forgotPasswordTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.resetPassword.apply(null, arguments)
                  },
                },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "resetEmail" } }, [
                        _vm._v("Your email address"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "resetEmail",
                          "aria-describedby": "emailHelp",
                          placeholder: "Enter email",
                          required: "",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _vm.loading
                        ? _c("span", [
                            _c("div", {
                              staticClass: "spinner-grow spinner-grow-sm",
                              attrs: { role: "status" },
                            }),
                            _vm._v(" Loading..."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "validation" }, [
                        _vm._v(_vm._s(_vm.status)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "forgotPasswordTitle" } },
        [_vm._v("Reset Password")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [_vm._v("Close")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-secondary", attrs: { type: "submit" } },
        [_vm._v("Reset")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }