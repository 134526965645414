<template>
    <div>
        <div class="container">
            <div v-if="accounts" class="row">
                <div class="col-md-5">
                    <div class="mb-4">
                        <p class="account-header">Member Name:</p>
                        <span class="huge">{{ accounts.Memberships.Membership.FirstName}} {{ accounts.Memberships.Membership.LastName}}</span>
                    </div>
                    <div class="mb-4">
                        <p class="account-header">Membership No:</p>
                        <span class="huge">{{ system.CustomerCode}}</span>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="mb-4">
                        <p class="account-header mb-1">Membership type:</p>
                        <span class="account-detail">{{ accounts.Memberships.Membership.Name}}</span>
                    </div>
                    <div class="mb-4">
                        <ul class="inline-list">
                            <li>
                                <p class="account-header mb-1">Start date:</p>
                                <span class="account-detail">{{ accounts.Memberships.Membership.EffectiveDate | rsdateslash }}</span>
                            </li>
                            <li>
                                <p class="account-header mb-1">End date:</p>
                                <span class="account-detail">{{ accounts.Memberships.Membership.ExpiryDate | rsdateslash }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="mb-4">
                        <p class="account-header mb-1">Status:</p>
                        <span class="account-detail text-lower-to-capitalise">{{ accounts.Memberships.Membership.Status.toLowerCase() }}</span>
                    </div>
                </div>
                <div class="col-md-12 d-none">
                    <hr>
                    <div v-if="billing" class="table-responsive">
                        <table class="table table-borderless">
                            <thead>
                                <tr class="ffs text-uppercase">
                                    <th scope="col">Billing date</th>
                                    <th scope="col">Charges</th>
                                    <th scope="col">Payments</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bill in billing">
                                    <th scope="row">{{ bill.BillDate | nicedate }}</th>
                                    <td>{{ bill.Charges }}</td>
                                    <td>{{ bill.Payments }}</td>
                                    <td>{{ bill.AccountBalance }}</td>
                                    <td class="text-right"><button @click.prevent="viewStatement(bill.BillDate)" class="btn btn-sm" :disabled="loadingStatement">View statement</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else-if="loadingBilling" class="text-center">
                        <div class="spinner-border spinner-border-sm" role="status"></div> Loading billing...
                    </div>
                    <div v-else>
                        {{ billingError }}
                    </div>
                </div>
            </div>
            <div v-else class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <div v-if="loading">
                        <div class="spinner-border" role="status"></div>
                        <h5 class="ffs text-uppercase mt-5">Fetching profile</h5>
                    </div>
                    <div v-if="error">
                        <p>{{ error }}</p>
                    </div>
                </div>
            </div>

            <!-- statement modal -->
            <div id="statement-modal" class="modal fade" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div v-if="statement" class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">{{ statement.title }}</h3>
                        </div>
                        <div class="modal-body">
                            <div class="modal-body__intro">{{ statement.archive_intro }}</div>
                            <h4>What to expect</h4>
                            <div class="modal-body__details" v-html="statement.details"></div>
                        </div>
                    </div>
                    <div v-else class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Sorry...</h3>
                        </div>
                        <div class="modal-body">
                            No statement available.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object,
            venue: String
        },

        components: {
            
        },

        data() {
            return {
                accounts: null,
                error: null,
                loading: false,
                billing: null,
                billingError: null,
                loadingBilling: false,
                statement: null,
                loadingStatement: false,
            }
        },
        
        mounted() {
            console.log('Component mounted')
            this.getAccounts()
        },

        watch: {
            
        },

        methods: {
            getAccounts() {
                this.loading = true
                var request = {
                    method: 'FetchClubAccounts',
                    data: {
                        CustomerId: this.system.CustomerId,
                        CustomerGUID: this.system.CustomerGUID,
                        WebFolioId: null,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            if(!$.isArray(response.data.Account.Members)) {
                                response.data.Account.Members = [response.data.Account.Members];
                            }
                            this.accounts = response.data.Account
                            //this.getBilling()
                        } else {
                            this.error = response.data.Result.Text
                        }
                        this.loading = false
                    });
            },
            getBilling() {
                this.loadingBilling = true
                var request = {
                    method: 'FetchClubBillings',
                    data: {
                        CustomerId: this.system.CustomerId,
                        CustomerGUID: this.system.CustomerGUID,
                        AccountId: this.system.AccountId,
                        WebFolioId: null,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            if(!$.isArray(response.data.BillingPeriod)) {
                                response.data.BillingPeriod = [response.data.BillingPeriod];
                            }
                            this.billing = response.data.BillingPeriod
                        } else {
                            this.billingError = response.data.Result.Text
                        }
                        this.loadingBilling = false
                    });
            },
            viewStatement(date) {
                this.loadingStatement = true
                var request = {
                    method: 'FetchClubStatement',
                    data: {
                        CustomerId: this.system.CustomerId,
                        CustomerGUID: this.system.CustomerGUID,
                        AccountId: this.system.AccountId,
                        BillDate: date,
                        WebFolioId: null,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            // if(!$.isArray(response.data.BillingPeriod)) {
                            //     response.data.BillingPeriod = [response.data.BillingPeriod];
                            // }
                            this.statement = response.data
                        }
                        this.loadingStatement = false
                    });
                $('#statement-modal').modal('show')
            }
        },
    }
</script>
