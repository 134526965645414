var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-app" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-6 text-center" }, [
        _c("div", { staticClass: "mt-3" }, [
          _c("h1", { staticClass: "brandHeading mb-4" }, [
            _vm._v("Password Reset"),
          ]),
          _vm._v(" "),
          _vm.invalidLink
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "Oh! looks like you do not have a valid password reset link."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-secondary btn-primary mt-3",
                      attrs: { to: "/book/account/request-password-reset" },
                    },
                    [_vm._v("Request password reset")]
                  ),
                ],
                1
              )
            : _c("div", [
                _vm.checkMessage
                  ? _c(
                      "div",
                      [
                        _c("p", [_vm._v(_vm._s(_vm.checkMessage))]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-secondary btn-primary mt-3",
                            attrs: {
                              to: "/book/account/request-password-reset",
                            },
                          },
                          [_vm._v("Request password reset")]
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm.resetSuccess
                        ? _c(
                            "div",
                            [
                              _c("h5", [
                                _vm._v("Your password was reset successfully."),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-secondary btn-primary mt-3",
                                  attrs: { to: "/book/cart" },
                                },
                                [_vm._v("View cart")]
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c(
                              "form",
                              {
                                staticClass: "text-left",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.changePassword.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm.checkReady
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _c("p", [
                                        _vm._v(
                                          "It looks like you have requested a password reset."
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.checkReset.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Validate reset request")]
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("h5", [
                                        _vm._v("Enter your new password."),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group col" },
                                          [
                                            _c("password-checker", {
                                              attrs: {
                                                password: _vm.newPassword,
                                                id: "newPassword",
                                                placeholder:
                                                  "Enter new password",
                                                minlength: 8,
                                                required: true,
                                                "min-password-strength":
                                                  this.$root.system
                                                    .MinPasswordStrength,
                                                "is-password-valid":
                                                  _vm.passwordValid,
                                              },
                                              on: {
                                                "update:password": function (
                                                  $event
                                                ) {
                                                  _vm.newPassword = $event
                                                },
                                                "update:isPasswordValid":
                                                  function ($event) {
                                                    _vm.passwordValid = $event
                                                  },
                                                "update:is-password-valid":
                                                  function ($event) {
                                                    _vm.passwordValid = $event
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm._m(0),
                                      _vm._v(" "),
                                      _vm.resetError
                                        ? _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "error" },
                                                [_vm._v(_vm._s(_vm.resetError))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                              ]
                            ),
                          ]),
                    ]),
              ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Reset Password")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }