<template>
    <div class="progress-indicator">
        <div class="progress-container">
            <div class="row justify-content-md-center">
                <div class="col-md-3">
                    <div class="row progress-indicator-steps">
                        <div class="col" :class="step == 1 ? 'stepActive' : ''">
                            <span class="badge badge-pill">1</span>
                            <span class="line"></span>
                        </div>
                        <div class="col" :class="step == 2 ? 'stepActive' : ''">
                            <span class="badge badge-pill">2</span>
                            <span class="line"></span>
                        </div>
                        <div class="col" :class="step == 3 ? 'stepActive' : ''">
                            <span class="badge badge-pill">3</span>
                            <span class="line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            step: Number
        },
        /**
         * The components data
         */
        data() {
            return {
                
            }
        },

        mounted() {

        },

        computed: {
            isActive() {

            }
        },

        watch: {
            
        },

        /**
         * Methods
         */
        methods: {
            
        }
    }
</script>
