var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col-md-5 text-center" }, [
        _c("div", { staticClass: "book-start" }, [
          _c("div", [
            _c("h3", { staticClass: "text-center mb-1" }, [
              _vm._v("Afternoon Tea at the Hall"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "small ffss",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.backStep()
                  },
                },
              },
              [_vm._v("Back a step")]
            ),
            _vm._v(" "),
            _vm.configuration.step === 1
              ? _c("div", [
                  _c("ul", { staticClass: "guests-nights-selector mb-4" }, [
                    _c("li", [
                      _c("p", [_vm._v("Party Size")]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-plus-minus",
                          on: {
                            click: function ($event) {
                              return _vm.decParty()
                            },
                          },
                        },
                        [_vm._v("-")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "book-int" }, [
                        _vm._v(_vm._s(_vm.configuration.partySize)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-plus-minus",
                          on: {
                            click: function ($event) {
                              return _vm.incParty()
                            },
                          },
                        },
                        [_vm._v("+")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "calendar" },
                    [
                      _c("v-calendar", {
                        attrs: {
                          mode: "single",
                          "from-date": _vm.minDate,
                          "min-date": _vm.minDate,
                          "max-date": null,
                          "is-expanded": "",
                          "is-inline": "",
                          "is-linked": "",
                        },
                        on: {
                          dayclick: function ($event) {
                            return _vm.dayClick($event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "day-content",
                              fn: function ({ day, attributes, dayEvents }) {
                                return _c(
                                  "div",
                                  _vm._g(
                                    {
                                      staticClass: "vc-day-content",
                                      class: {
                                        daySelected:
                                          day.id ==
                                          _vm.configuration.selectedDate,
                                        "vc-text-gray-400": !_vm.isValid(
                                          day.id
                                        ),
                                      },
                                      attrs: { day: day },
                                    },
                                    dayEvents
                                  ),
                                  [
                                    _c("div", [
                                      _c("div", [_vm._v(_vm._s(day.day))]),
                                    ]),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          399285560
                        ),
                        model: {
                          value: _vm.configuration.selectedDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.configuration, "selectedDate", $$v)
                          },
                          expression: "configuration.selectedDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.configuration.timeslots.length > 0
                    ? _c("div", { staticClass: "row mb-4" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "label",
                            {
                              staticClass: "brandHeading",
                              attrs: { for: "timeslot" },
                            },
                            [_vm._v("Time slot")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configuration.selectedTimeslot,
                                  expression: "configuration.selectedTimeslot",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.configuration,
                                    "selectedTimeslot",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.configuration.timeslots,
                              function (timeslot, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    attrs: { id: "timeslot" },
                                    domProps: { value: timeslot.TimeSlot },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm._f("nicetime")(timeslot.TimeSlot)
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "info-box mb-4" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "Sorry, there are no available time slots for the selected date."
                          ),
                        ]),
                      ]),
                  _vm._v(" "),
                  _vm.configuration.timeslots.length > 0
                    ? _c("div", { staticClass: "mt-2 mb-2 row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: {
                                disabled: !_vm.configuration.selectedTimeslot,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setStep(2)
                                },
                              },
                            },
                            [_vm._v("Next Step")]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.configuration.step === 2
              ? _c("div", [
                  _c("h5", { staticClass: "brandHeading mt-4" }, [
                    _vm._v("Select your Afternoon Tea"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "package-selector list-group mb-4" },
                    _vm._l(
                      _vm.configuration.promotions,
                      function (promotion, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            staticClass:
                              "list-group-item list-group-item-action",
                            class: {
                              selected:
                                _vm.configuration.selectedPromotion.Id ===
                                promotion.Id,
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.selectPromotion(promotion)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex w-100 justify-content-between",
                              },
                              [
                                _c("h5", { staticClass: "mb-1" }, [
                                  _vm._v(_vm._s(promotion.Name)),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    "£" +
                                      _vm._s(
                                        _vm._f("price")(promotion.FullPrice)
                                      ) +
                                      "pp"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-1 text-left" }, [
                              _vm._v(_vm._s(promotion.Description)),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-4" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "label",
                        {
                          staticClass: "brandHeading",
                          attrs: { for: "specialRequests" },
                        },
                        [_vm._v("Any Special Requests?")]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.configuration.specialRequests,
                            expression: "configuration.specialRequests",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "specialRequests" },
                        domProps: { value: _vm.configuration.specialRequests },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.configuration,
                              "specialRequests",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2 mb-2 row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function ($event) {
                              return _vm.setStep(3)
                            },
                          },
                        },
                        [_vm._v("Next Step")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function ($event) {
                              return _vm.setStep(1)
                            },
                          },
                        },
                        [_vm._v("Go Back")]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.configuration.step === 3
              ? _c("div", [
                  _c("h5", { staticClass: "brandHeading mt-4" }, [
                    _vm._v("Booking Summary"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cart-page-actions__balance mt-3" },
                    [
                      _c("span", [
                        _vm._v("Amount to pay today: "),
                        _c("strong", [_vm._v("£" + _vm._s(_vm.paymentAmount))]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.mobileError
                    ? _c("div", { staticClass: "alert alert-warning" }, [
                        _vm._m(0),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.error
                    ? _c("div", { staticClass: "alert alert-warning" }, [
                        _vm._m(1),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  this.system.isLoggedIn
                    ? _c("div", { staticClass: "mt-4 mb-4 row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: {
                                disabled:
                                  _vm.loading || _vm.mobileError || _vm.error,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.createReservation()
                                },
                              },
                            },
                            [
                              _vm._v("Pay "),
                              _vm.loading
                                ? _c("span", [
                                    _c("div", {
                                      staticClass:
                                        "spinner-border spinner-border-sm",
                                      attrs: { role: "status" },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.startOver()
                                },
                              },
                            },
                            [_vm._v("Start Over")]
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "row mt-4 mb-4" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "d-md-flex" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary flex-fill mr-2",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showLogin("login")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                        Login to account\n                                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary flex-fill",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showLogin("register")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                        Checkout\n                                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "box-blush" }, [
              _c("p", [
                _vm._v(
                  "Why not enjoy our award-winning spa facilities, visiting us on a spa day or stay, then adding your Afternoon Tea as a finishing touch at checkout?"
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "small ffss",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.backStep()
                    },
                  },
                },
                [_vm._v("Back a step")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "booking-app-sideout login-selector" }, [
      _c("div", { staticClass: "sideout-wrapper" }, [
        _c("div", { staticClass: "sideout-container" }, [
          _c("div", { staticClass: "sideout-content" }, [
            _c("div", { staticClass: "package-header" }, [
              _c(
                "a",
                {
                  staticClass: "prevNextArrow arrowLeft",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeSideout()
                    },
                  },
                },
                [_vm._v(" ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "package-content" }, [
              _c(
                "div",
                { staticClass: "room-full-description" },
                [
                  _c("login-register", {
                    attrs: {
                      system: _vm.system,
                      cart: _vm.cart,
                      type: _vm.type,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-0" }, [
      _vm._v(
        "A mobile number is required to make this booking, please update your account "
      ),
      _c("a", { attrs: { href: "/book/account", target: "_blank" } }, [
        _vm._v("here"),
      ]),
      _vm._v(" or call our reservations team on "),
      _c("a", { attrs: { href: "tel:01283 576522", target: "_blank" } }, [
        _vm._v("01283 576522"),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-0" }, [
      _vm._v(
        "Unfortunately we're unable to make this reservation online please call our reservations team on "
      ),
      _c("a", { attrs: { href: "tel:01283 576522", target: "_blank" } }, [
        _vm._v("01283 576522"),
      ]),
      _vm._v(" to complete your booking."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }