<template>
    <div class="modal fade" id="forgotPasswordModal" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <form @submit.prevent="resetPassword">
                    <div class="modal-header">
                        <h5 class="modal-title" id="forgotPasswordTitle">Reset Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="resetEmail">Your email address</label>
                                <input v-model="email" type="email" class="form-control" id="resetEmail" aria-describedby="emailHelp" placeholder="Enter email" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <span v-if="loading"><div class="spinner-grow spinner-grow-sm" role="status"></div> Loading...</span>
                                <span class="validation">{{ status }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-secondary">Reset</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            
        },
        /**
         * The components data
         */
        data() {
            return {
                email: null,
                loading: null,
                status: null,
            }
        },

        mounted() {
            var self = this
            $('#forgotPasswordModal').on('hidden.bs.modal', function (e) {
                self.email = null
                self.status = null
            })
        },

        /**
         * Methods
         */
        methods: {
            resetPassword() {
                this.loading = true
                var request = {
                    method: 'ResetPasswordCustomer',
                    data: { 
                        EmailAddress: this.email,
                        PasswordGUID: null,
                        NewPassword: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        this.loading = false
                        this.status = 'Request sent. If your email address is in the system you will receive an email shortly with your new password. Please allow upto 5 minutes for the email to arrive.'
                    });
            },
        }
    }
</script>
