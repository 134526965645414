<template>
    <div class="gift-card-component">
        <form @submit.prevent="addToCart" class="gift-card-order">
            <div class="voucher-tabs">
                <div class="gift-card-order__price">Price: &pound;{{ giftCard.Price }}</div>

                <hr />

                <h5 class="mb-4">It's time to tailor your gift...</h5>

                <h5>Value</h5>

                <div class="form-row mb-3">
                    <div class="form-group col-md-6">
                        <select v-model="giftCard" name="value" class="form-control">
                            <option v-for="gc in packages" :value="gc">{{ gc.Name }}</option>
                        </select>
                    </div>
                </div>

                <div class="section-notice text-center mb-4">
                    <p>Your preferred value not showing? Fear not, our Reservations
                        Team can create a custom gift card or e-voucher just for you.</p>
                        <a onclick="leadContact('Barons Eden');" data-toggle="modal" data-target="#contact-modal" class="icons">Call Reservations</a>
                </div> 

                <h5>Recipient</h5>

                <p>Please enter your personalised message below<br />(this will be printed on the gift)</p>
                
                <h5>Name</h5>

                <div class="form-row">
                    <div class="form-group col-md-8">
                        <input v-model="emailOrderForm.IssueToName" type="text" class="form-control" id="IssueToName" placeholder="Enter recipients name" required>
                    </div>
                </div>

                <h5>Message <span class="small">(optional)</span></h5>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <textarea v-model="emailOrderForm.Message" cols="8" rows="6" class="form-control" id="Message" placeholder="Enter your message (max 300 characters)" maxlength="300"></textarea>
                        <div class="small">Characters remaining: {{ 300 - emailOrderForm.Message.length }}</div>
                    </div>
                </div>
                
                <h5 class="mt-3">Select your type</h5>

                <div class="row">
                    <div class="col-md-12">
                        <ul class="nav nav-tabs" id="voucherTabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: voucherType == 'CERT' }" id="cert-tab'" data-toggle="tab" @click.prevent="selectVoucherType('CERT')" role="tab" aria-controls="cert-tab" :aria-selected="{ true: voucherType == 'CERT' }">
                                    E-Voucher
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: voucherType == 'CARD' }" id="card-tab'" data-toggle="tab" @click.prevent="selectVoucherType('CARD')" role="tab" aria-controls="card-tab" :aria-selected="{ true: voucherType == 'CARD' }">
                                    Gift Card
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="voucherTabsContent">
                            <p>The delivery address is the address of the person you would like us to send the gift card to. If you would like this delivered to yourself, please enter your own address.</p>
                            <div v-show="voucherType == 'CERT'" id="certPanel" role="tabpanel" aria-labelledby="certPanel-tab">
                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <input v-model="emailOrderForm.EmailAddress" type="email" class="form-control" id="EmailAddress" placeholder="Enter email address" :required="voucherType == 'CERT'">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-10 mb-0">
                                        <span class="small" v-if="voucherError">{{ voucherError }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-show="voucherType == 'CARD'" id="cardPanel" role="tabpanel" aria-labelledby="cardPanel-tab">
                                <div v-if="giftCard && giftCard.shippingRates" class="form-row">
                                    <div class="form-group col-md-12">
                                        <h5>Select postage</h5>
                                        <div class="btn-group" role="group" aria-label="Postage Options">
                                            <button v-for="s in giftCard.shippingRates" v-if="s.Price" type="button" @click.prevent="selectShippingType(s)" :disabled="s.ShippingId == shippingSelected.ShippingId" class="btn btn-secondary btn-shipping">{{ s.Name }}</button>
                                        </div>
                                        <div v-if="shippingSelected">
                                            <span class="small d-block text-uppercase fwb">Shipping selected:</span> {{ shippingSelected.Name }} - &pound;{{ shippingSelected.PriceWithSurcharges }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <h5>Delivery address</h5>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <input v-model="emailOrderForm.MailToAddress.Address1" type="text" class="form-control" id="Address1" ref="Address1" placeholder="Address line 1" :required="voucherType == 'CARD'">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <input v-model="emailOrderForm.MailToAddress.Address2" type="text" class="form-control" id="Address2" ref="Address2" placeholder="Address line 2">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <input v-model="emailOrderForm.MailToAddress.City" type="text" class="form-control" id="City" ref="City" placeholder="City" :required="voucherType == 'CARD'">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <input v-model="emailOrderForm.MailToAddress.PostCode" type="text" class="form-control" id="PostCode" ref="PostCode" placeholder="Post Code" :required="voucherType == 'CARD'">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <select v-model="emailOrderForm.MailToAddress.Country" name="Country" class="form-control">
                                            <option v-for="country in countryList" :value="country">{{ country }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p class="small">Please note, we allow a 48 hour processing time for all postal gift cards. Delivery by Royal Mail can take 5 -7 days, and we are unable to take any responsibility for Royal Mail delays.</p>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <span class="small" v-if="voucherError">{{ voucherError }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <div class="form-group col-md-12">
                                <a href="/gift-cards" class="btn btn-primary">Back to all Gift Cards</a> 
                                <button type="submit" class="btn btn-reversed" :disabled="isDisabled">Add to cart</button>
                                <div v-if="addingGc" class="spinner-border spinner-border-md" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="gift-card-cart">
            <gift-card-cart :system="system" :cart="cart" next='cart'></gift-card-cart>
        </div>
    </div>
</template>

<script>
    var emailOrderFormTemplate = function() {
        return {
            SessionId: null,
            WebFolioId: null,
            CustomerId: 24,
            EmailAddress: null,
            IssueToName: null,
            ItemId: null,
            Layout: 1,
            MailToAddress: {
                type: 'HOME',
                Address1: null,
                Address2: null,
                City: null,
                StateProv: null,
                Country: 'United Kingdom',
                PostCode: null
            },
            Message: '',
            Method: 'E',
            WrappingId: null,
            ShippingId: null,
            PackageCertificate: null,
        }
    };
    var packageTemplate = function() {
        return {
            PackageItemId: null,
            EmailAddress: null,
            IssueToName: null,
            Layout: 1,
            Message: null

        }
    }
    import GiftCardCart from '../components/GiftCardCart.vue';
    import { countries } from '../countries.js';
    export default {
        props: {
            system: Object,
            cart: Object,
            gcid: String,
            giftCardIds: Object
        },

        components: {
            GiftCardCart
        },

        data() {
            return {
                giftCard: null,
                shippingOptions: null,
                packages: null,
                emailOrderForm: emailOrderFormTemplate(),
                deliveryType: 'E',
                shippingSelected: null,
                voucherType: 'CERT',
                voucherError: null,
                countryList: countries,
                addingGc: false
            }
        },
        
        mounted() {
            Bus.$emit('RefreshSystem')
            this.fetchGiftCards()
        },

        computed: {
            isDisabled() {
                if(!this.giftCard) return true
                if(this.addingGc) return true
            }
        },

        watch: {
            giftCard: function() {
                if(this.giftCard.shippingRates) {
                    this.shippingSelected = this.giftCard.shippingRates[0]
                } else {
                    this.shippingSelected = null
                }
            }
        },

        methods: {
            selectVoucherType(type) {
                this.voucherType = type
                if(type == 'CERT') {
                    this.deliveryType = 'E'
                } else {
                    this.deliveryType = 'M'
                }
            },
            selectShippingType(shipping) {
                this.shippingSelected = shipping
            },
            fetchGiftCards() {
                var self = this
                var request = {
                    method: 'FetchGiftCertificates',
                    data: {
                        WebFolioId: null,
                        Language: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if (response.data.Shippings.Shipping && !$.isArray(response.data.Shippings.Shipping)) { // convert single entry to an array
                            response.data.Shippings.Shipping = [response.data.Shippings.Shipping]
                        }
                        this.shippingOptions = response.data.Shippings.Shipping
                        
                        var gc = response.data.GiftCertificate
                        var packages = response.data.PackageCertificates
                        var allPackages = gc.concat(packages)

                        _.each(allPackages, function(o) {
                            o['value'] = +o.Price
                            o['shippingRates'] = null
                            if(!_.isEmpty(o.Shippings)) {
                                o['shippingRates'] = []

                                _.each(o.Shippings.Shipping, function(s) {

                                    if(s.Id != 10303 && s.Id != 103012 && s.Id != 103011) {    
                                        var sr = _.merge({}, s, _.find(self.shippingOptions, {'ShippingId': s.Id}))
                                        if(sr.DefaultMail == 'Y') {
                                            self.shippingSelected = sr
                                        }
                                        o.shippingRates.push(sr)
                                    }

                                })
                            }
                        })

                        var sorted = _.orderBy(allPackages, ['value'], ['asc'])
                        var monetary = _.groupBy(sorted, 'ItemType')

                        var gcIds = this.giftCardIds.item_ids.split(/[ ,]+/);
                        this.packages = _.filter(
                            monetary['Gift Voucher - Monetary'],
                            function(o) {
                                if (_.includes(gcIds, o.ItemId.toString())) {
                                    return o;
                                }
                            }
                        );

                        this.giftCard = this.packages[0]
                    });
            },
            addToCart() {
                
                this.emailOrderForm.SessionId = this.system.SessionId;
                this.emailOrderForm.WebFolioId = this.system.WebFolioId;
                this.emailOrderForm.CustomerId = this.system.WebFolioCustomerId;
                this.emailOrderForm.Method = this.deliveryType;

                this.emailOrderForm.MailToAddress.Address1 = this.$refs.Address1.value;
                this.emailOrderForm.MailToAddress.Address2 = this.$refs.Address2.value;
                this.emailOrderForm.MailToAddress.City = this.$refs.City.value;
                this.emailOrderForm.MailToAddress.PostCode = this.$refs.PostCode.value;

                this.addingGc = true
                
                var self = this;

                this.emailOrderForm.ItemId = this.giftCard.ItemId

                if(this.emailOrderForm.Method == 'M') {
                    if(this.shippingSelected) {
                        this.emailOrderForm.ShippingId = this.shippingSelected.ShippingId
                    }
                } else {
                    if(this.giftCard.shippingRates) {
                        this.emailOrderForm.ShippingId = 10303
                    } else {
                        this.emailOrderForm.ShippingId = null
                    }
                }

                var gc = {
                    method: 'PurchaseGiftCertificate',
                    data: this.emailOrderForm
                };
                axios.post('/rs/request', gc).then(response=> {
                    if(response.data.Result.value === 'SUCCESS') { // Added to WebFolio
                        dataLayer.push({
                            'event': 'add_to_cart',
                            'venue': 'Barons Eden',
                            'ecommerce': {
                                'currency': 'GBP',
                                'add': {                                // 'add' actionFieldObject measures.
                                'products': [{                        //  adding a product to a shopping cart.
                                    'name': this.giftCard.Name,
                                    'id': this.giftCard.ItemId,
                                    'price': +this.giftCard.Price,
                                    'brand': "Barons Eden",
                                    'category': "Gift Cards",
                                    'quantity': 1
                                }]
                                }
                            }
                        });

                        this.$emitAnalytics(
                            'add_to_cart',
                            {
                                'event': 'add_to_cart',
                                'venue': 'Barons Eden',
                                'ecommerce': {
                                    'currency': 'GBP',
                                    'add': {                                // 'add' actionFieldObject measures.
                                    'products': [{                        //  adding a product to a shopping cart.
                                        'name': this.giftCard.Name,
                                        'id': this.giftCard.ItemId,
                                        'price': +this.giftCard.Price,
                                        'brand': "Barons Eden",
                                        'category': "Gift Cards",
                                        'quantity': 1
                                    }]
                                    }
                                }
                            },
                            this.system.CustomerId,
                            this.system.WebfolioId
                        );
                            
                        this.emailOrderForm = emailOrderFormTemplate();
                        Bus.$emit('UpdateCart');

                    } else {
                        this.voucherError = response.data
                    }
                    this.addingGc = false
                });
            }
        },
    }
</script>


<style lang="scss" scoped>
.gift-card-order__price {
    font-family: 'filson-pro', sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    color: #9d7b43;
    text-transform: uppercase;
}
.nav-link.active {
    text-decoration: none;
}

</style>