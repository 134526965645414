<template>
    <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <form @submit.prevent="login">
                    <div class="modal-header">
                        <h5 class="modal-title" id="loginModalTitle">Login</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="customerEmail">Your email address</label>
                                <input @change="clearError" v-model="email" type="email" class="form-control" id="customerEmail" aria-describedby="emailHelp" placeholder="Enter email" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="customerPassword">Your password</label>
                                <input @change="clearError" v-model="password" type="password" class="form-control" id="customerPassword" aria-describedby="passwordlHelp" placeholder="Enter password" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <a @click.prevent="forgotPassword" class="small-text">Forgot password</a>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <span v-if="loading"><div class="spinner-border spinner-border-sm" role="status"></div> Loading...</span>
                                <span class="validation">{{ error }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cart: Object,
            system: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                email: null,
                password: null,
                loading: null,
                error: null,
            }
        },

        mounted() {

        },

        /**
         * Methods
         */
        methods: {
            login() {
                this.loading = true
                var request = {
                    data: {
                        EmailAddress: this.email,
                        Password: this.password,
                        FolioId: null,
                        LastName: null
                    }
                }
                axios.post('/rs/login', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            
                            Bus.$emit('RefreshSystem');
                            $('#loginModal').modal('hide')
                            this.checkoutStep()
                            this.addContactLog(response.data.CustomerId, response.data.CustomerGUID)

                        } else {
                            this.error = response.data.Result.Text;
                        }
                        this.loading = null
                    });
            },

            checkoutStep() {
                dataLayer.push({
                    event: 'login',
                    method: 'ResortSuite',
                    value: this.cart.FolioBalance,
                    currency: 'GBP',
                });

                this.$emitAnalytics(
                    'login', 
                    {
                        event: 'login',
                        method: 'ResortSuite',
                        value: this.cart.FolioBalance,
                        currency: 'GBP',
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                );
            },

            addContactLog(CustomerID, GUID) {
                var customerType = this.$cookies.get("custType");

                if(!customerType) {
                    var logCat = 'Campaign: Existing Customer'
                    var campaign = URI.parseQuery(Cookies.get('beCampaign'))

                    if(!_.isEmpty(campaign) && ( (('utm_campaign' in campaign) && ( campaign.utm_campaign.toLowerCase().indexOf('ltv') !== -1 || campaign.utm_campaign.toLowerCase().indexOf('nca') !== -1 )) || ('gclid' in campaign) )) {
                        // Effect campaign
                        var campaignName = campaign.utm_campaign ? campaign.utm_campaign : null;
                        var source = campaign.utm_source ? campaign.utm_source : null;
                        var medium = campaign.utm_medium ? campaign.utm_medium : null;
                        var content = campaign.utm_content ? campaign.utm_content : null;
                        var term = campaign.utm_term ? campaign.utm_term : null;
                        var gclid = campaign.gclid ? campaign.gclid : null;
                        var landing = campaign.landing ? campaign.landing : null;
                        var referrer = campaign.referrer ? campaign.referrer : null;
                        var clientIp = campaign.clientIp ? campaign.clientIp : null;

                        var request = {
                            method: 'UpdateCustomerProfile',
                            data: { 
                                Customer: {
                                    CustomerId: CustomerID,
                                    CustomerGUID: GUID
                                },
                                ContactLogs: [
                                    {
                                        name: logCat,
                                        _: '\nCustomer type: Returning \n' + 'Campaign: ' + campaignName + '\n' + 'Source: ' + source + '\n' + 'Medium: ' + medium + '\n' + 'Terms: ' + term + '\n' + 'Content: ' + content + '\n' + 'GCLID: ' + gclid + '\n' + 'Landing page: ' + landing + '\n' + 'Referrer: ' + referrer + '\n' + 'Client IP: ' + clientIp
                                    }
                                ]
                            }
                        }
                        axios.post('/rs/request', request)
                            .then(response=> {
                                this.purchaseMessage({message: 'Customer type: Returning' + '\n' + 'Campaign: ' + campaignName + '\n' + 'Source: ' + source + '\n' + 'Medium: ' + medium + '\n' + 'Terms: ' + term + '\n' + 'Content: ' + content + '\n' + 'GCLID: ' + gclid + '\n' + 'Landing page: ' + landing + '\n' + 'Referrer: ' + referrer + '\n' + 'Client IP: ' + clientIp + ' - Customer ID: ' + CustomerID})
                            });
                    }
                }
            },

            purchaseMessage(message) {
                axios.post('/system/purchase-message', message)
            },

            clearError() {
                this.error = null
            },

            forgotPassword() {
                $('#loginModal').modal('hide')
                $('#forgotPasswordModal').modal('show')
            }
        }
    }
</script>
