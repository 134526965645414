<template>
    <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <form @submit.prevent="register">
                    <div class="modal-header">
                        <h5 class="modal-title" id="registerModalTitle">Register</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="small">Required fields *</p>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="firstName">First name*</label>
                                <input v-model="registerForm.Name.FirstName" type="text" class="form-control" id="firstName" placeholder="First name" required>
                            </div>
                            <div class="form-group col">
                                <label for="surname">Surname*</label>
                                <input v-model="registerForm.Name.LastName" type="text" class="form-control" id="surname" placeholder="Surname" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="newEmail">Your email address*</label>
                                <input v-model="registerForm.EmailAddress" type="email" class="form-control" id="newEmail" placeholder="Enter email" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="newPassword">Your password*</label>
                                <input v-model="registerForm.Password" type="password" minlength="8" pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}" class="form-control" id="newPassword" placeholder="Min 8, 1 num, 1 capital" required>
                                <div class="small">Minimum 8 characters, 1 number and 1 capital letter</div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="Address1">Address* (start typing to search)</label>
                                <input 
                                    v-model="registerForm.Address[0].Address1" 
                                    type="text" 
                                    class="form-control" 
                                    id="Address1" 
                                    name="Address1"
                                    ref="Address1" 
                                    placeholder="Address line 1"
                                    @input="debouncedFetchAddressLookup"
                                    required>
                                <ul
                                    v-if="addressResults.length"
                                    class="autocomplete"
                                >
                                    <li class="amount">
                                        Showing {{ addressResults.length }} results
                                    </li>
                                    <li
                                        v-for="(address, index) in addressResults"
                                        :key="index"
                                        @click="selectAddress(address)"
                                        class="list-item"
                                    >
                                        {{ address.address_text }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="Address2">Address line 2</label>
                                <input :value="registerForm.Address[0].Address2" type="text" class="form-control" id="Address2" name="Address2" ref="Address2" placeholder="Address line 2">
                            </div>
                            <div class="form-group col">
                                <label for="City">City*</label>
                                <input :value="registerForm.Address[0].City" type="text" class="form-control" id="City" name="City" ref="City" placeholder="City" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="PostCode">Post Code*</label>
                                <input :value="registerForm.Address[0].PostCode" type="text" class="form-control" id="PostCode" name="PostCode" ref="PostCode" placeholder="Post Code" required>
                            </div>
                            <div class="form-group col">
                                <label for="Country">Country*</label>
                                <input :value="registerForm.Address[0].Country" type="text" class="form-control" id="Country" name="Country" ref="Country" placeholder="Country" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="Tel">Telephone*</label>
                                <input v-model="registerForm.Phone[0].PhoneNumber" type="tel" pattern="^[0-9-+\s()]*$" class="form-control" id="Tel" name="Tel" ref="Tel" placeholder="Telephone number" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label for="DateOfBirth">Let us know the month you were born, so we can send you a birthday treat!</label>
                                <select v-model="registerForm.DateOfBirth" name="DateOfBirth" class="form-control" id="DateOfBirth">
                                    <option value="">Select month of birth</option>
                                    <option value="1970-01-01">January</option>
                                    <option value="1970-02-01">February</option>
                                    <option value="1970-03-01">March</option>
                                    <option value="1970-04-01">April</option>
                                    <option value="1970-05-01">May</option>
                                    <option value="1970-06-01">June</option>
                                    <option value="1970-07-01">July</option>
                                    <option value="1970-08-01">August</option>
                                    <option value="1970-09-01">September</option>
                                    <option value="1970-10-01">October</option>
                                    <option value="1970-11-01">November</option>
                                    <option value="1970-12-01">December</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label float-left" for="NoEmail">
                                <input v-model="registerForm.NoEmail" type="checkbox" class="form-check-input" id="NoEmail">
                                <span class="checkmark"></span>
                                Tick to receive the latest news, events and exclusive offers.
                            </label>
                        </div>
                        
                        <div class="form-row">
                            <div class="form-group col">
                                <span v-if="loading"><div class="spinner-border spinner-border-sm" role="status"></div> Loading...</span>
                                <span class="validation">{{ error }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" :disabled="loading">Register</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { countries } from './../countries.js';

    var registerFormTemplate = function() {
        return {
            Name: {
                Salutation: null,
                FirstName: null,
                LastName: null
            },
            EmailAddress: null,
            NoEmail: false,
            NoMail: 'Y',
            NoCall: 'Y',
            NoSMS: 'Y',
            Password: null,
            DateOfBirth: '',
            Address: [
                {
                    type: 'HOME',
                    Address1: null,
                    Address2: null,
                    City: null,
                    StateProv: null,
                    Country: null,
                    PostCode: null
                }
            ],
            Phone: [
                {
                    type: 'HOME',
                    primary: true,
                    PhoneNumber: null
                }
            ]
        }
    }
    export default {
        props: {
            cart: Object,
            system: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                registerForm: new registerFormTemplate,
                loading: null,
                error: null,
                email: null,
                password: null,
                created: null,
                addressResults: [],
            }
        },


        /**
         * Methods
         */
        methods: {
            async fetchAddressLookup() {
                try {
                    await axios.get('https://api.baronseden.com/sanctum/csrf-cookie');

                    const response = await axios.post('https://api.baronseden.com/api/address/lookup', 
                        {
                            address: this.registerForm.Address[0].Address1
                        }, 
                        {
                            headers: {
                                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                            }
                        }
                    );

                    this.addressResults = response.data.data;
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            
            debouncedFetchAddressLookup: debounce(function() {
                this.fetchAddressLookup();
            }, 700),

            async selectAddress(address) {
                const response = await axios.post('https://api.baronseden.com/api/address/format', 
                    {
                        global_address_key: address.global_address_key
                    }, 
                    {
                        headers: {
                            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        }
                    }
                );

                this.populateForm(response.data.data);
            },

            populateForm(address) {
                this.registerForm.Address[0].Address1 = address.address_line_1;
                this.registerForm.Address[0].Address2 = address.address_line_2;
                this.registerForm.Address[0].City = address.city;
                this.registerForm.Address[0].PostCode = address.post_code;
                this.registerForm.Address[0].Country = address.country;

                this.addressResults = [];
            },
            
            register() {
                var self = this
                this.loading = true
                this.error = null

                this.registerForm.Address[0].Address1 = this.$refs.Address1.value;
                this.registerForm.Address[0].Address2 = this.$refs.Address2.value;
                this.registerForm.Address[0].City = this.$refs.City.value;
                this.registerForm.Address[0].PostCode = this.$refs.PostCode.value;
                this.registerForm.Address[0].Country = this.$refs.Country.value;
                
                var formData = _.cloneDeep(this.registerForm)

                // Marketing
                formData.NoEmail = this.registerForm.NoEmail ? 'N' : 'Y';

                var request = {
                    method: 'CreateCustomer',
                    data: { 
                        NewCustomer: formData,
                        ContactLogs: {
                            ContactLog: null
                        }
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        
                        if(response.data.Result.value === 'SUCCESS') {
                            this.created = response.data;
                            this.email = this.registerForm.EmailAddress;
                            this.password = this.registerForm.Password;
                            this.$cookies.set("custType",'New',0);
                            this.checkoutStep()
                            this.login()
                            this.addContactLog(response.data.CustomerId, response.data.CustomerGUID)
                        } else {
                            this.loading = false
                            if(response.data.Result.ErrorId == 101) {
                                this.error = 'This email address already exists. Please close this window and choose login.'
                            } else {
                                this.error = response.data.Result.Text;
                            }
                        }
                    });
            },

            checkoutStep() {
                dataLayer.push({
                    event: 'sign_up',
                    method: 'ResortSuite',
                    value: this.cart.FolioBalance,
                    currency: 'GBP'
                });

                this.$emitAnalytics(
                    'sign_up', 
                    {
                        event: 'sign_up',
                        method: 'ResortSuite',
                        value: this.cart.FolioBalance,
                        currency: 'GBP'
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                );
            },

            addContactLog(CustomerID, GUID) {

                var customerType = this.$cookies.get("custType");

                var logCat = customerType == 'New' ? 'Campaign: New Customer' : 'Campaign: Returning Customer'
                
                var campaign = URI.parseQuery(Cookies.get('beCampaign'))

                if(!_.isEmpty(campaign) && ( (('utm_campaign' in campaign) && ( campaign.utm_campaign.toLowerCase().indexOf('ltv') !== -1 || campaign.utm_campaign.toLowerCase().indexOf('nca') !== -1 )) || ('gclid' in campaign) )) {
                    // Effect campaign
                    var campaignName = campaign.utm_campaign ? campaign.utm_campaign : null;
                    var source = campaign.utm_source ? campaign.utm_source : null;
                    var medium = campaign.utm_medium ? campaign.utm_medium : null;
                    var content = campaign.utm_content ? campaign.utm_content : null;
                    var term = campaign.utm_term ? campaign.utm_term : null;
                    var gclid = campaign.gclid ? campaign.gclid : null;
                    var landing = campaign.landing ? campaign.landing : null;
                    var referrer = campaign.referrer ? campaign.referrer : null;
                    var clientIp = campaign.clientIp ? campaign.clientIp : null;

                    var request = {
                        method: 'UpdateCustomerProfile',
                        data: { 
                            Customer: {
                                CustomerId: CustomerID,
                                CustomerGUID: GUID
                            },
                            ContactLogs: [
                                {
                                    name: logCat,
                                    _: '\nCustomer type: New \n' + 'Campaign: ' + campaignName + '\n' + 'Source: ' + source + '\n' + 'Medium: ' + medium + '\n' + 'Terms: ' + term + '\n' + 'Content: ' + content + '\n' + 'GCLID: ' + gclid + '\n' + 'Landing page: ' + landing + '\n' + 'Referrer: ' + referrer + '\n' + 'Client IP: ' + clientIp
                                }
                            ]
                        }
                    }
                    axios.post('/rs/request', request)
                        .then(response=> {
                            this.purchaseMessage({message: 'Customer type: ' + customerType + '\n' + 'Campaign: ' + campaignName + '\n' + 'Source: ' + source + '\n' + 'Medium: ' + medium + '\n' + 'Terms: ' + term + '\n' + 'Content: ' + content + '\n' + 'GCLID: ' + gclid + '\n' + 'Landing page: ' + landing + '\n' + 'Referrer: ' + referrer + '\n' + 'Client IP: ' + clientIp + ' - Customer ID: ' + CustomerID})
                        });
                }
            },

            purchaseMessage(message) {
                axios.post('/system/purchase-message', message)
            },

            clearError() {
                this.error = null
            },

            login() {

                var request = {
                    data: {
                        EmailAddress: this.email,
                        Password: this.password,
                        FolioId: null,
                        LastName: null
                    }
                }
                axios.post('/rs/login', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            Bus.$emit('RefreshSystem');
                            this.loading = null
                            this.registerForm = new registerFormTemplate
                            $('#registerModal').modal('hide')
                        }
                    });
            },
        }
    }
</script>

<style>
    .autocomplete {
        width: 92%;
        padding: 5px;
        border-radius: 0.25rem;
        background-color: #ffffff;
        border-width: 1px;
        border-style: solid;
        --border-opacity: 1;
        border-color: rgb(209 213 219 / var(--border-opacity));
        position: absolute;
        z-index: 10;
        font-size: 14px;
    }

    .autocomplete > :not([hidden]) ~ :not([hidden]) {
        --space-y-reverse: 0;
        margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
        margin-bottom: calc(0.25rem * var(--space-y-reverse));
    }
    
    .autocomplete .amount {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0.25rem;
        padding-bottom: 0.5rem; 
        font-weight: 700;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgb(229 231 235);
        list-style: none;
    }

    .autocomplete .list-item {
        cursor: pointer;
        padding: 0.25rem;
        list-style: none;
    }

    .autocomplete .list-item:hover {
        background-color: #f5f5f5; 
    }
</style>