<template>
    <div class="pre-arrival-component">
        <div v-if="!selected_guest" class="pre-arrival-list">
            <div class="p-5">
                <h5 class="brandHeading">Pre-arrival Consultation</h5>
                <p>
                    We need some details from you to ensure you receive the best
                    possible experience.
                </p>
                <div v-if="bookingData">
                    <h5>Booking Reference</h5>
                    <h3>{{ folio_id }}</h3>
                    <a @click.prevent="clearFolioId()" class="small"
                        >Not your booking? Change reference number.</a
                    >
                </div>
                <div v-else class="text-center">
                    <p>Please enter your booking reference</p>
                    <form
                        @submit.prevent="getBooking()"
                        class="form-inline justify-content-center"
                        autocomplete="off"
                    >
                        <div class="form-group mb-2">
                            <label for="folio_id" class="sr-only"
                                >Booking reference</label
                            >
                            <input
                                v-model="folio_id"
                                type="tel"
                                class="form-control"
                                id="folio_id"
                                placeholder="Booking reference"
                            />
                        </div>
                        <button type="submit" class="btn btn-secondary mb-2">
                            Submit
                        </button>
                    </form>
                    <div class="mt-1">
                        {{ getBookingError }}
                    </div>
                </div>
            </div>
            <table v-if="bookingData" class="table">
                <thead>
                    <tr>
                        <th>Guest name</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="guest in bookingData.guests">
                        <td>{{ guest.name }}</td>
                        <td>
                            <span v-if="guest.customer_id"
                                >Consultation completed</span
                            ><span v-else
                                >Complete pre-arrival consultation</span
                            >
                        </td>
                        <td class="text-right">
                            <button
                                v-if="!guest.customer_id"
                                class="btn btn-secondary mb-0"
                                @click.prevent="selectGuest(guest)"
                            >
                                <img
                                    loading="lazy"
                                    src="/img/arrow-right.svg"
                                    alt="Start consultation arrow"
                                />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="pre-arrival-form">
            <div class="pre-arrival-form-user">
                <h5 class="brandHeading">Log in or create an account</h5>
                <p>Do you already have an account with us?</p>
                <button
                    @click.prevent="selectLoginRegister('login')"
                    class="btn btn-primary"
                    :class="{ selected: showLoginRegister == 'login' }"
                >
                    Yes
                </button>
                <button
                    @click.prevent="selectLoginRegister('register')"
                    class="btn btn-primary"
                    :class="{ selected: showLoginRegister == 'register' }"
                >
                    No
                </button>
                <div class="mt-3">
                    <a @click.prevent="backToStart()">Back a step</a>
                </div>

                <div v-if="showLoginRegister == 'login'" class="mt-5 mb-3">
                    <div v-if="customer_id">
                        <h3>
                            Hi {{ customer_first_name }}
                            {{ customer_last_name }}
                        </h3>
                    </div>
                    <div v-else>
                        <form
                            @submit.prevent="login"
                            class="row justify-content-center"
                            autocomplete="off"
                        >
                            <div class="col-md-6 text-left">
                                <h5>Log in</h5>
                                <div class="form-group mb-2">
                                    <label for="email">Email address</label>
                                    <input
                                        type="email"
                                        v-model="loginForm.EmailAddress"
                                        class="form-control"
                                        id="email"
                                        placeholder="Email address"
                                    />
                                </div>
                                <div class="form-group mb-3">
                                    <label for="password">Password</label>
                                    <input
                                        type="password"
                                        v-model="loginForm.Password"
                                        class="form-control"
                                        id="password"
                                        placeholder="Password"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    class="btn btn-secondary mb-3"
                                >
                                    Log in
                                </button>
                                <div class="feedback-container">
                                    {{ loginError }}
                                </div>
                                <div class="small">
                                    <a @click.prevent="forgotPassword"
                                        >Forgot Password?</a
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <form @submit.prevent="sendDetails()" autocomplete="off">
                    <!-- registration form -->
                    <div
                        v-if="showLoginRegister == 'register'"
                        class="mt-5 mb-3 text-left"
                    >
                        <h5 class="mb-1">Enter details</h5>
                        <p class="xs">Please fill out all fields.</p>
                        <div class="row">
                            <div class="form-group col-md-6 mb-2">
                                <label for="FirstName">First name</label>
                                <input
                                    type="text"
                                    v-model="registerForm.Name.FirstName"
                                    class="form-control"
                                    id="FirstName"
                                    ref="FirstName"
                                    placeholder="First name"
                                    required
                                    autocomplete="off"
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2">
                                <label for="LastName">Surname</label>
                                <input
                                    type="text"
                                    v-model="registerForm.Name.LastName"
                                    class="form-control"
                                    id="LastName"
                                    ref="LastName"
                                    placeholder="Surname"
                                    required
                                    autocomplete="off"
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2">
                                <label for="registerEmail">Email address</label>
                                <input
                                    type="email"
                                    v-model="registerForm.EmailAddress"
                                    class="form-control"
                                    id="registerEmail"
                                    ref="registerEmail"
                                    placeholder="Email address"
                                    required
                                    autocomplete="off"
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2">
                                <label for="PhoneNumber">Telephone</label>
                                <input
                                    type="tel"
                                    v-model="registerForm.Phone[0].PhoneNumber"
                                    class="form-control"
                                    id="PhoneNumber"
                                    ref="PhoneNumber"
                                    placeholder="Phone number"
                                    required
                                    autocomplete="off"
                                />
                            </div>
                            <div class="form-group col-md-6 mb-3">
                                <label for="PostCode">Post Code</label>
                                <input
                                    type="text"
                                    v-model="registerForm.Address[0].PostCode"
                                    class="form-control"
                                    id="PostCode"
                                    ref="PostCode"
                                    placeholder="Post code"
                                    required
                                    autocomplete="off"
                                />
                            </div>
                            <div class="form-group col-md-12 mb-2">
                                <div>
                                    <label for="Gender">Gender</label>
                                </div>
                                <div
                                    class="custom-control custom-radio custom-control-inline"
                                >
                                    <input
                                        type="radio"
                                        v-model="registerForm.Gender"
                                        id="genderFemale"
                                        value="F"
                                        name="customRadioInline"
                                        class="custom-control-input"
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="genderFemale"
                                        >Female</label
                                    >
                                </div>
                                <div
                                    class="custom-control custom-radio custom-control-inline"
                                >
                                    <input
                                        type="radio"
                                        v-model="registerForm.Gender"
                                        id="genderMale"
                                        value="M"
                                        name="customRadioInline"
                                        class="custom-control-input"
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="genderMale"
                                        >Male</label
                                    >
                                </div>
                                <div
                                    class="custom-control custom-radio custom-control-inline"
                                >
                                    <input
                                        type="radio"
                                        v-model="registerForm.Gender"
                                        id="genderNull"
                                        name="customRadioInline"
                                        class="custom-control-input"
                                        selected
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="genderNull"
                                        >Prefer not to say</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Medical form -->
                    <div v-if="customer_id || showLoginRegister == 'register'">
                        <div class="pre-arrival-form-medical">
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <label class="mr-sm-2" for="Pregnant"
                                        >Are you currently pregnant?</label
                                    >
                                </div>
                                <div class="col-auto my-1">
                                    <select
                                        v-model="medicalForm.Pregnant"
                                        class="custom-select mr-sm-2 mb-2"
                                        id="Pregnant"
                                        required
                                    >
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                                <div
                                    v-if="medicalForm.Pregnant == 'Yes'"
                                    class="col-11"
                                >
                                    <label class="mr-sm-2" for="PregnantNotes"
                                        >How many weeks will you be at the time
                                        of your visit?</label
                                    >
                                    <textarea
                                        v-model="medicalForm.PregnantNotes"
                                        class="form-control"
                                        id="PregnantNotes"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <label class="mr-sm-2" for="Conditions"
                                        >Are you currently being treated for; or
                                        have you had any illnesses, injuries or
                                        conditions including high blood
                                        pressure, cancer or increased
                                        sensitivity?</label
                                    >
                                </div>
                                <div class="col-auto my-1">
                                    <select
                                        v-model="medicalForm.Conditions"
                                        class="custom-select mr-sm-2 mb-2"
                                        id="Conditions"
                                        required
                                    >
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                                <div
                                    v-if="medicalForm.Conditions == 'Yes'"
                                    class="col-11"
                                >
                                    <label class="mr-sm-2" for="ConditionsNotes"
                                        >If you answered yes above, please
                                        provide our team with any further
                                        information you may deem helpful.</label
                                    >
                                    <textarea
                                        v-model="medicalForm.ConditionsNotes"
                                        class="form-control"
                                        id="ConditionsNotes"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <label class="mr-sm-2" for="Dietary"
                                        >Do you have any dietary requirements or
                                        restrictions?</label
                                    >
                                </div>
                                <div class="col-auto my-1">
                                    <select
                                        v-model="medicalForm.Dietary"
                                        class="custom-select mr-sm-2 mb-2"
                                        id="Dietary"
                                        required
                                    >
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                                <div
                                    v-if="medicalForm.Dietary == 'Yes'"
                                    class="col-11"
                                >
                                    <label class="mr-sm-2" for="DietaryNotes"
                                        >If you answered yes above, please
                                        provide our team with any further
                                        information you may deem helpful.</label
                                    >
                                    <textarea
                                        v-model="medicalForm.DietaryNotes"
                                        class="form-control"
                                        id="DietaryNotes"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <label class="mr-sm-2" for="Allergies"
                                        >Do you have any allergies that may
                                        affect your treatment?</label
                                    >
                                </div>
                                <div class="col-auto my-1">
                                    <select
                                        v-model="medicalForm.Allergies"
                                        class="custom-select mr-sm-2 mb-2"
                                        id="Allergies"
                                        required
                                    >
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                                <div
                                    v-if="medicalForm.Allergies == 'Yes'"
                                    class="col-11"
                                >
                                    <label class="mr-sm-2" for="AllergiesNotes"
                                        >If you answered yes above, please
                                        provide our team with any further
                                        information you may deem helpful.</label
                                    >
                                    <textarea
                                        v-model="medicalForm.AllergiesNotes"
                                        class="form-control"
                                        id="AllergiesNotes"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <label class="mr-sm-2" for="Medication"
                                        >Are you currently taking any
                                        medication?</label
                                    >
                                </div>
                                <div class="col-auto my-1">
                                    <select
                                        v-model="medicalForm.Medication"
                                        class="custom-select mr-sm-2 mb-2"
                                        id="Medication"
                                        required
                                    >
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                                <div
                                    v-if="medicalForm.Medication == 'Yes'"
                                    class="col-11"
                                >
                                    <label class="mr-sm-2" for="MedicationNotes"
                                        >If you answered yes above, please
                                        provide our team with any further
                                        information you may deem helpful.</label
                                    >
                                    <textarea
                                        v-model="medicalForm.MedicationNotes"
                                        class="form-control"
                                        id="MedicationNotes"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <label class="mr-sm-2" for="DOB"
                                        >What is your date of birth? (Minimum
                                        age 16yrs for spa guests)</label
                                    >
                                </div>
                                <div class="col-auto my-1">
                                    <input
                                        type="date"
                                        v-model="medicalForm.DateOfBirth"
                                        class="form-control mr-sm-2 mb-2"
                                        id="DOB"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div class="pre-arrival-form-marketing text-left">
                            <h5>
                                Be the first to receive our exclusive offers?
                            </h5>

                            <div class="form-row align-items-center">
                                <div class="col-auto my-1">
                                    <select
                                        v-model="marketingForm.NoEmail"
                                        class="custom-select mr-sm-2 mb-2"
                                        id="NoEmail"
                                        required
                                    >
                                        <option value="Y">No</option>
                                        <option value="N">Yes</option>
                                    </select>
                                </div>
                            </div>
                            <div class="marketing-consent-message">
                                <p>
                                    By clicking submit you are confirming that
                                    you are the person named in this form and
                                    you have answered the questions truthfully
                                    and to the best of your knowledge. Should
                                    your answers change between now and your
                                    visit to the spa, it is your responsibility
                                    to make us aware by calling a member of our
                                    reservations team prior to arrival.
                                </p>
                            </div>

                            <button
                                type="submit"
                                class="btn btn-secondary mb-2"
                            >
                                Submit Details
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <forgot-password-modal></forgot-password-modal>
    </div>
</template>

<script>
import ForgotPasswordModal from "../book/ForgotPasswordModal.vue";
var registerFormTemplate = function () {
    return {
        Name: {
            FirstName: null,
            LastName: null,
        },
        EmailAddress: null,
        NoEmail: "",
        DateOfBirth: "",
        Gender: null,
        Address: [
            {
                type: "HOME",
                PostCode: null,
            },
        ],
        Phone: [
            {
                type: "HOME",
                primary: true,
                PhoneNumber: null,
            },
        ],
    };
};
var marketingFormTemplate = function () {
    return {
        NoEmail: "",
    };
};
var loginFormTemplate = function () {
    return {
        EmailAddress: null,
        Password: null,
        FolioId: null,
        LastName: null,
    };
};
var medicalFormTemplate = function () {
    return {
        Pregnant: null,
        PregnantNotes: null,
        Conditions: null,
        ConditionsNotes: null,
        Dietary: null,
        DietaryNotes: null,
        Allergies: null,
        AllergiesNotes: null,
        Medication: null,
        MedicationNotes: null,
        DateOfBirth: null,
    };
};
export default {
    props: {},

    components: {
        ForgotPasswordModal,
    },

    data() {
        return {
            bookingData: null,
            selected_guest: null,
            loginForm: new loginFormTemplate(),
            registerForm: new registerFormTemplate(),
            loginError: null,
            submitError: null,
            medicalForm: new medicalFormTemplate(),
            marketingForm: new marketingFormTemplate(),
            showLoginRegister: null,
            folio_id: null,
            customer_id: null,
            customer_guid: null,
            customer_first_name: null,
            customer_last_name: null,
            getBookingError: null,
        };
    },

    mounted() {},

    computed: {},

    methods: {
        selectGuest(guest) {
            this.selected_guest = guest;
        },

        selectLoginRegister(selection) {
            this.clearForms();
            this.showLoginRegister = selection;
        },

        backToStart() {
            this.selected_guest = null;
            this.clearForms();
            this.clearFolioId();
        },

        clearFolioId() {
            this.folio_id = null;
            this.bookingData = null;
        },

        clearForms() {
            this.loginForm = new loginFormTemplate();
            this.registerForm = new registerFormTemplate();
            this.medicalForm = new medicalFormTemplate();
            this.marketingForm = new marketingFormTemplate();

            this.customer_id = null;
            this.customer_guid = null;
            this.customer_first_name = null;
            this.customer_last_name = null;
        },

        getBooking() {
            this.getBookingError = null;
            Bus.$emit("showLoading");
            axios
                .post("/rs/pre-arrival-booking", { folio: this.folio_id })
                .then((response) => {
                    if (response.data) {
                        this.bookingData = response.data;
                    } else {
                        this.getBookingError =
                            "Sorry, we could not find your booking";
                    }
                    Bus.$emit("hideLoading");
                });
        },

        login() {
            this.loginError = null;
            Bus.$emit("showLoading");
            var request = {
                data: this.loginForm,
            };
            axios
                .post("/rs/pre-arrival-get-customer", request)
                .then((response) => {
                    if (response.data.Result.value === "SUCCESS") {
                        this.customer_id = response.data.CustomerId;
                        this.customer_guid = response.data.CustomerGUID;
                        this.customer_first_name = response.data.FirstName;
                        this.customer_last_name = response.data.LastName;
                        this.loginForm = new loginFormTemplate();
                    } else {
                        this.loginError = response.data.Result.Text;
                    }
                    Bus.$emit("hideLoading");
                });
        },

        forgotPassword() {
            $("#forgotPasswordModal").modal("show");
        },

        sendDetails() {
            Bus.$emit("showLoading");

            var medical = [
                {
                    name: "Medical Questionnaire",
                    _:
                        "Medical Questionnaire \n \n" +
                        "Are you pregnant? " +
                        this.medicalForm.Pregnant +
                        "\n" +
                        "Notes: " +
                        this.medicalForm.PregnantNotes +
                        "\n \n" +
                        "Are you currently being treated for; or have you had any illnesses, injuries or conditions including high blood pressure, cancer or increased sensitivity? " +
                        this.medicalForm.Conditions +
                        "\n" +
                        "Notes: " +
                        this.medicalForm.ConditionsNotes +
                        "\n \n" +
                        "Do you have any dietary requirements or restrictions? " +
                        this.medicalForm.Dietary +
                        "\n" +
                        "Notes: " +
                        this.medicalForm.DietaryNotes +
                        "\n \n" +
                        "Do you have any allergies that may affect your treatment? " +
                        this.medicalForm.Allergies +
                        "\n" +
                        "Notes: " +
                        this.medicalForm.AllergiesNotes +
                        "\n \n" +
                        "Are you currently taking any medication? " +
                        this.medicalForm.Medication +
                        "\n" +
                        "Notes: " +
                        this.medicalForm.MedicationNotes,
                },
            ];

            if (this.showLoginRegister == "register") {
                // new Customer
                this.registerForm.Name.FirstName = this.$refs.FirstName.value;
                this.registerForm.Name.LastName = this.$refs.LastName.value;
                this.registerForm.EmailAddress = this.$refs.registerEmail.value;
                this.registerForm.Phone[0].PhoneNumber =
                    this.$refs.PhoneNumber.value;
                this.registerForm.Address[0].PostCode =
                    this.$refs.PostCode.value;
                this.registerForm.NoEmail = this.marketingForm.NoEmail;
                this.registerForm.DateOfBirth = this.medicalForm.DateOfBirth;

                var request = {
                    method: "CreateCustomer",
                    data: {
                        NewCustomer: this.registerForm,
                        ContactLogs: medical,
                    },
                    booking: this.bookingData,
                    guest: this.selected_guest,
                };
            } else {
                var request = {
                    method: "UpdateCustomerProfile",
                    data: {
                        Customer: {
                            CustomerId: this.customer_id,
                            CustomerGUID: this.customer_guid,
                            NoEmail: this.marketingForm.NoEmail,
                            DateOfBirth: this.medicalForm.DateOfBirth,
                        },
                        ContactLogs: medical,
                    },
                    booking: this.bookingData,
                    guest: this.selected_guest,
                };
            }

            axios
                .post("/rs/pre-arrival-folio-customer", request)
                .then((response) => {
                    if (response.data.Result.value === "SUCCESS") {
                        // Send welcome email if registered
                        if (this.showLoginRegister == "register") {
                            var details = {
                                name: this.registerForm.Name.FirstName,
                                email: this.registerForm.EmailAddress,
                            };
                            axios.post("/rs/welcome-email", details);
                        }

                        this.selected_guest = null;
                        this.showLoginRegister = null;
                        this.bookingData = null;

                        this.clearForms();
                        this.clearFolioId();
                    } else {
                        this.submitError = response.data.Result.Text;
                    }
                    Bus.$emit("hideLoading");
                });
        },
    },
};
</script>
