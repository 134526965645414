<template>
    <div class="booking-enhance">
        <div class="row">
            <div class="col-lg-12">
                <progress-indicator :step="step"></progress-indicator>
                <div class="booking-info">
                    <h1 v-if="type == 'hotel-stay'" class="brandHeading">
                        Spa Access
                    </h1>
                    <h1 v-else class="brandHeading">Treatments</h1>
                    <h4 class="hide-mobile">{{ pageDate | nicedate }}</h4>
                    <div class="message-small">
                        <a @click.prevent="addPackage" class="mr-2"
                            >Back a step</a
                        >
                        <a @click.prevent="restartBooking(true)"
                            >Restart booking</a
                        >
                    </div>
                </div>

                <div class="alert alert-warning" v-if="!cart.inclusivesBooked">
                    <p class="mb-0 text-center">
                        Please book all inclusive treatment times before
                        proceeding.
                    </p>
                </div>

                <div class="booking-list">
                    <div v-for="(folio, k) in filteredCart" :key="k">
                        <div class="booking-list-item">
                            <div class="booking-list-item__details">
                                <div class="hide-mobile">
                                    <h3>
                                        <span v-if="folio.Type == 'Stay'"
                                            >Room</span
                                        ><span v-else>Guest</span> {{ k + 1 }}
                                    </h3>
                                    <p>Location: {{ folio.Location }}</p>
                                    <p>
                                        Date: {{ folio.StartDate | rsnicedate }}
                                    </p>
                                    <p>Experience: {{ folio.RateDetails }}</p>
                                    <p v-if="folio.Type == 'Stay'">
                                        Guests: {{ folio.Adults }}
                                    </p>
                                    <p v-if="folio.Type == 'Stay'">
                                        <strong>Room type:</strong>
                                        {{ folio.Details }}
                                        <span v-if="folio.twinSelected"
                                            >(Twin room)</span
                                        >
                                        <span v-if="folio.doubleSelected"
                                            >(Double room)</span
                                        >
                                    </p>
                                    <p class="details-price">
                                        Cost: &pound;{{
                                            (+folio.Amount).toFixed(2)
                                        }}
                                    </p>
                                    <p
                                        v-if="folio.promotionApplied"
                                        class="small"
                                    >
                                        ({{ folio.promotionApplied }} promotion
                                        applied)
                                    </p>
                                    <p
                                        v-if="folio.RateType == 'R3FOR2'"
                                        class="small"
                                    >
                                        *Add code at checkout
                                    </p>
                                </div>
                                <div class="show-mobile">
                                    <div class="dropdown">
                                        <div
                                            class="dropdown-heading heading clearfix"
                                            data-toggle="collapse"
                                            data-target="#packageDetails"
                                            aria-expanded="false"
                                            aria-controls="packageDetails"
                                        >
                                            <h3>
                                                <span
                                                    v-if="folio.Type == 'Stay'"
                                                    >Room</span
                                                ><span v-else>Guest</span>
                                                {{ k + 1 }}
                                            </h3>
                                            <i class="arrow"></i>
                                        </div>
                                        <div
                                            class="dropdown-container collapse"
                                            id="packageDetails"
                                        >
                                            <p>
                                                Location: {{ folio.Location }}
                                            </p>
                                            <p>
                                                Date:
                                                {{
                                                    folio.StartDate | rsnicedate
                                                }}
                                            </p>
                                            <p>
                                                Experience:
                                                {{ folio.RateDetails }}
                                            </p>
                                            <p v-if="folio.Type == 'Stay'">
                                                Guests:
                                                {{
                                                    getRoomGuests(folio.FolioId)
                                                }}
                                            </p>
                                            <p v-if="folio.Type == 'Stay'">
                                                <strong>Room type:</strong>
                                                {{ folio.Details }}
                                                <span v-if="folio.twinSelected"
                                                    >(Twin room)</span
                                                >
                                            </p>
                                            <p class="details-price">
                                                Cost: &pound;{{
                                                    (+folio.Amount).toFixed(2)
                                                }}
                                            </p>
                                            <p
                                                v-if="folio.advancedBooking"
                                                class="small"
                                            >
                                                ({{ folio.advancedBooking }}%
                                                discount applied)
                                            </p>
                                            <p
                                                v-if="
                                                    folio.RateType == 'R3FOR2'
                                                "
                                                class="small"
                                            >
                                                *Add code at checkout
                                            </p>
                                            <div class="message-small">
                                                <a
                                                    @click.prevent="
                                                        removeItem(folio)
                                                    "
                                                    class="mr-2"
                                                    >Remove
                                                    <span
                                                        v-if="
                                                            folio.Type == 'Stay'
                                                        "
                                                        >room</span
                                                    ><span v-else>package</span>
                                                    {{ k + 1 }}</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="booking-list-item__treatments">
                                <div class="inclusive-list">
                                    <div v-if="type != 'hotel-stay'">
                                        <h3>Inclusive Treatments</h3>
                                        <div v-if="folio.InclusiveTreatments">
                                            <div v-if="folio.Type == 'Spa Day'">
                                                <p>
                                                    Please book your inclusive
                                                    treatments and times before
                                                    proceeding to the next step.
                                                </p>
                                                <ul
                                                    class="enhance-item-list clearfix"
                                                >
                                                    <li
                                                        v-for="(
                                                            treatment, tk
                                                        ) in folio
                                                            .InclusiveTreatments
                                                            .Inclusives"
                                                        :key="tk"
                                                        class="clearfix"
                                                    >
                                                        <span v-if="treatment">
                                                            {{
                                                                treatment.Details
                                                            }}.<br />
                                                            {{
                                                                treatment.StartDate
                                                                    | rsdatetimeshort
                                                            }}.
                                                            <span
                                                                v-if="
                                                                    treatment.Amount !=
                                                                    '0.00'
                                                                "
                                                                >(&pound;{{
                                                                    treatment.Amount
                                                                }})</span
                                                            >
                                                            <a
                                                                @click.prevent="
                                                                    removeTreatment(
                                                                        treatment
                                                                    )
                                                                "
                                                                ><span
                                                                    v-if="
                                                                        removing ==
                                                                        treatment.FolioItemId
                                                                    "
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    >&nbsp;</span
                                                                ><span v-else
                                                                    >Remove</span
                                                                ></a
                                                            >
                                                        </span>
                                                        <span v-else>
                                                            <button
                                                                @click.prevent="
                                                                    addInclusive(
                                                                        folio,
                                                                        folio.InclusiveTreatments,
                                                                        k + 1
                                                                    )
                                                                "
                                                                class="btn btn-primary btn-block mb-1"
                                                            >
                                                                Select treatment
                                                            </button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-else>
                                                <p>
                                                    Please book your inclusive
                                                    treatments and times before
                                                    proceeding to the next step.
                                                </p>
                                                <ul
                                                    class="enhance-item-list clearfix"
                                                >
                                                    <li
                                                        v-for="(
                                                            treatment, tk
                                                        ) in folio
                                                            .InclusiveTreatments
                                                            .Inclusives"
                                                        :key="tk"
                                                        class="clearfix"
                                                    >
                                                        <span v-if="treatment">
                                                            {{
                                                                treatment.Details
                                                            }}.<br />
                                                            {{
                                                                treatment.StartDate
                                                                    | rsdatetimeshort
                                                            }}.
                                                            <span
                                                                v-if="
                                                                    treatment.Amount !=
                                                                    '0.00'
                                                                "
                                                                >(&pound;{{
                                                                    treatment.Amount
                                                                }})</span
                                                            >
                                                            <a
                                                                @click.prevent="
                                                                    removeTreatment(
                                                                        treatment
                                                                    )
                                                                "
                                                                ><span
                                                                    v-if="
                                                                        removing ==
                                                                        treatment.FolioItemId
                                                                    "
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    >&nbsp;</span
                                                                ><span v-else
                                                                    >Remove</span
                                                                ></a
                                                            >
                                                        </span>
                                                        <span v-else>
                                                            <button
                                                                @click.prevent="
                                                                    addInclusive(
                                                                        folio,
                                                                        folio.InclusiveTreatments,
                                                                        tk + 1
                                                                    )
                                                                "
                                                                class="btn btn-primary btn-block mb-1"
                                                            >
                                                                Select
                                                                <span
                                                                    v-if="
                                                                        folio.Type ==
                                                                        'Stay'
                                                                    "
                                                                    >guest
                                                                    {{
                                                                        tk + 1
                                                                    }}</span
                                                                >
                                                                treatment
                                                            </button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div
                                                v-if="
                                                    folio.RateType == 'RAUTUMN2'
                                                "
                                            >
                                                <p>
                                                    Your package includes a
                                                    60-minute Full Body Couture
                                                    Touch treatment per person.
                                                    When you receive your
                                                    digital itinerary this will
                                                    show your assigned treatment
                                                    time.
                                                </p>
                                                <p>
                                                    <strong
                                                        >Looking to
                                                        upgrade?</strong
                                                    >
                                                </p>
                                                <p>
                                                    Call our Reservations Team
                                                    on
                                                    <a
                                                        href="tel:01283 576522"
                                                        target="_blank"
                                                        >01283 576522</a
                                                    >
                                                    after completing your
                                                    booking, to hear your
                                                    upgrade options.
                                                </p>
                                            </div>

                                            <div v-else>
                                                <p>
                                                    This package does not
                                                    include inclusive
                                                    treatments.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="booking-list-item__extras">
                                <div v-if="folio.HotelOnly">
                                    <h3>Spa access?</h3>
                                    <div v-if="folio.spaAccess">
                                        <div>
                                            {{ folio.spaAccess.Qty }} x Spa
                                            Access - &pound;{{
                                                folio.spaAccess
                                                    .TotalWithSurcharges
                                            }}
                                        </div>
                                        <a
                                            @click.prevent="
                                                removeSpaAccess(
                                                    folio.spaAccess
                                                        .PMSFolioSpecialServiceId,
                                                    folio.FolioId
                                                )
                                            "
                                            >Remove</a
                                        >
                                    </div>
                                    <div v-else>
                                        <p>
                                            Add evening Spa Access for day of
                                            arrival. &pound;40pp
                                        </p>
                                        <button
                                            @click.prevent="addSpaAccess(folio)"
                                            class="btn btn-primary btn-block btn-blush"
                                            v-if="!isSaturday(folio.StartDate)"
                                        >
                                            Add Spa Access
                                        </button>
                                        <p v-if="isSaturday(folio.StartDate)">
                                            Please call our Reservations Team on
                                            <strong class="globalTel"
                                                >01283 576522</strong
                                            >
                                        </p>
                                    </div>
                                </div>
                                <div v-else>
                                    <h3>Additional Treatments?</h3>
                                    <p>
                                        Why not further enhance your spa
                                        experience with an additional treatment?
                                    </p>
                                    <button
                                        @click.prevent="showTreatments(folio)"
                                        class="btn btn-primary btn-block btn-blush"
                                    >
                                        Add
                                        <span v-if="folio.Treatments.length"
                                            >another treatment</span
                                        ><span v-else>treatments</span>
                                    </button>
                                    <ul
                                        v-if="folio.Treatments.length"
                                        class="enhance-item-list"
                                    >
                                        <li
                                            v-for="(
                                                treatment, k
                                            ) in folio.Treatments"
                                            :key="k"
                                        >
                                            {{ treatment.Details }}.<br />
                                            {{
                                                treatment.StartDate
                                                    | rsdatetimeshort
                                            }}. &pound;{{ treatment.Amount }}.
                                            <a
                                                @click.prevent="
                                                    removeTreatment(treatment)
                                                "
                                                >Remove</a
                                            >
                                        </li>
                                    </ul>
                                    <ul v-else class="enhance-item-list">
                                        <li>
                                            Currently no additional treatments
                                            added
                                        </li>
                                    </ul>
                                    <p
                                        v-if="folio.TreatmentTotal"
                                        class="details-price"
                                    >
                                        Cost: &pound;{{
                                            (+folio.TreatmentTotal).toFixed(2)
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="message-small d-none d-md-block">
                            <a @click.prevent="removeItem(folio)" class="mr-2"
                                >Remove
                                <span v-if="folio.Type == 'Stay'">room</span
                                ><span v-else>package</span> {{ k + 1 }}</a
                            >
                        </div>
                    </div>
                </div>

                <div v-if="cart" class="next-steps">
                    <div class="next-steps__price">
                        Total cost: &pound;{{ cart.FolioBalance }}
                    </div>
                    <div class="mt-3">
                        <button
                            v-if="type == 'hotel-stay'"
                            @click.prevent="payNow"
                            class="btn btn-secondary"
                        >
                            Pay Now
                        </button>
                        <button
                            v-else
                            @click.prevent="checkAdditionalTreatments()"
                            class="btn btn-secondary"
                            :disabled="!cart.inclusivesBooked"
                        >
                            Next Step
                        </button>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="gc-box">
                            <h5>Redeeming a Gift Card or E-voucher?</h5>
                            <p>
                                Did you know, all of our gift vouchers hold a
                                monetary value and can be redeemed against any
                                package. Simply select your chosen package, then
                                enter your voucher code at checkout to redeem.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-app-sideout treatment-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a
                                @click.prevent="closeSideout()"
                                class="prevNextArrow arrowLeft"
                                >&nbsp;</a
                            >
                        </div>

                        <div class="alert alert-warning m-2">
                            <div class="form-group">
                                <label for="categoryFilters"
                                    >Filter by category</label
                                >
                                <select
                                    v-model="filteredCategory"
                                    class="form-control"
                                    id="categoryFilters"
                                >
                                    <option selected value="All">All</option>
                                    <option value="Face">Face</option>
                                    <option value="Body / Massage">
                                        Body / Massage
                                    </option>
                                    <option value="Touch">Touch</option>
                                    <option value="Hair">Hair</option>
                                    <option value="Parlour">Parlour</option>
                                </select>
                            </div>
                        </div>

                        <div v-if="treatments" class="package-content">
                            <div
                                v-for="(tc, tkk) in treatments"
                                :key="tkk"
                                class="tab-cats"
                            >
                                <h3
                                    class="mb-3"
                                    v-if="
                                        filteredCategories(tc.list).length > 0
                                    "
                                >
                                    {{ tc.cat.toLowerCase() }}
                                </h3>
                                <div class="tab-cat-items">
                                    <div
                                        v-for="(
                                            treatment, tk
                                        ) in filteredCategories(tc.list)"
                                        :key="tk"
                                        class="tab-item"
                                    >
                                        <div
                                            class="accordion"
                                            :id="
                                                'acc-' +
                                                tc.cat.replace(
                                                    /[^a-zA-Z0-9-_]+/g,
                                                    '-'
                                                )
                                            "
                                        >
                                            <div class="accordion-item">
                                                <div
                                                    class="accordion-header"
                                                    :id="
                                                        'heading' +
                                                        treatment.SpaItemId
                                                    "
                                                    data-toggle="collapse"
                                                    :data-target="
                                                        '#collapse' +
                                                        treatment.SpaItemId
                                                    "
                                                    aria-expanded="false"
                                                    :aria-controls="
                                                        'collapse' +
                                                        treatment.SpaItemId
                                                    "
                                                >
                                                    <div class="clearfix">
                                                        <span
                                                            class="accordion-name"
                                                            >{{
                                                                treatment.ItemName
                                                            }}</span
                                                        >
                                                        <div
                                                            class="float-right"
                                                        >
                                                            <span
                                                                >&pound;{{
                                                                    treatment.Price
                                                                        | price
                                                                }}</span
                                                            >
                                                            <i
                                                                class="arrow"
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    :id="
                                                        'collapse' +
                                                        treatment.SpaItemId
                                                    "
                                                    class="collapse"
                                                    :aria-labelledby="
                                                        'heading' +
                                                        treatment.SpaItemId
                                                    "
                                                    :data-parent="
                                                        '#acc-' +
                                                        tc.cat.replace(
                                                            /[^a-zA-Z0-9-_]+/g,
                                                            '-'
                                                        )
                                                    "
                                                >
                                                    <div class="accordion-body">
                                                        <div class="mb-3">
                                                            {{
                                                                treatment.ItemDesc
                                                            }}
                                                        </div>
                                                        <div
                                                            v-if="
                                                                selectedFolio
                                                                    .TreatmentDates
                                                                    .length > 1
                                                            "
                                                            class="choose-date mb-3"
                                                        >
                                                            <label
                                                                for="date-select"
                                                                >Please select
                                                                your preferred
                                                                date</label
                                                            >
                                                            <select
                                                                v-model="
                                                                    treatmentDate
                                                                "
                                                                @change="
                                                                    getTreatmentTimesV2(
                                                                        treatment
                                                                    )
                                                                "
                                                                class="custom-select custom-select-lg"
                                                            >
                                                                <option
                                                                    v-for="(
                                                                        date,
                                                                        index
                                                                    ) in selectedFolio.TreatmentDates"
                                                                    :key="index"
                                                                    :value="
                                                                        date
                                                                    "
                                                                >
                                                                    {{
                                                                        date
                                                                            | nicedate
                                                                    }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                treatment.TreatmentTimes
                                                            "
                                                            class="treatment-times"
                                                        >
                                                            <div>
                                                                <strong
                                                                    >Please
                                                                    select your
                                                                    preferred
                                                                    time</strong
                                                                >
                                                            </div>
                                                            <button
                                                                @click.prevent="
                                                                    selectTime(
                                                                        time
                                                                    )
                                                                "
                                                                v-for="(
                                                                    time, ttk
                                                                ) in treatment.TreatmentTimes"
                                                                :key="ttk"
                                                                class="btn btn-primary mr-1"
                                                                :class="{
                                                                    btnSelected:
                                                                        time ==
                                                                        selectedTime,
                                                                }"
                                                            >
                                                                {{
                                                                    time.StartTime
                                                                        | rstime
                                                                }}
                                                            </button>
                                                            <div
                                                                class="clearfix"
                                                            >
                                                                <button
                                                                    @click.prevent="
                                                                        addTreatmentToCart()
                                                                    "
                                                                    class="btn btn-secondary btn-block"
                                                                    :disabled="
                                                                        !selectedTime
                                                                    "
                                                                >
                                                                    Add to
                                                                    booking
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-else
                                                            class="clearfix"
                                                        >
                                                            <div
                                                                v-if="
                                                                    treatment.noAvailability
                                                                "
                                                            >
                                                                <h5
                                                                    class="mt-2"
                                                                >
                                                                    Sorry, no
                                                                    availability
                                                                </h5>
                                                            </div>
                                                            <div v-else>
                                                                <button
                                                                    :disabled="
                                                                        loading
                                                                    "
                                                                    class="btn ersed btn-xs float-right mt-2 mb-0 mr-2"
                                                                    @click.prevent="
                                                                        getTreatmentTimesV2(
                                                                            treatment
                                                                        )
                                                                    "
                                                                >
                                                                    Choose a
                                                                    time
                                                                    <div
                                                                        v-if="
                                                                            loading
                                                                        "
                                                                        class="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                    ></div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-app-sideout inclusive-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a
                                @click.prevent="closeSideout()"
                                class="prevNextArrow arrowLeft"
                                >&nbsp;</a
                            >
                        </div>
                        <div v-if="selectedInclusive" class="package-content">
                            <h3 class="mt-2 mb-3">
                                Guest
                                <span v-if="treatmentGuestNo">{{
                                    treatmentGuestNo
                                }}</span>
                                Inclusive Treatment:
                            </h3>
                            <div
                                v-if="selectedInclusive.OptionList"
                                class="choose-date accordion mb-5"
                                id="acc-inc-treatment-opotion"
                            >
                                <div
                                    v-for="(
                                        treatment, tk
                                    ) in selectedInclusive.OptionList"
                                    :key="tk"
                                    class="accordion-item"
                                >
                                    <div
                                        class="accordion-header"
                                        :id="'heading' + treatment.SpaItemId"
                                        data-toggle="collapse"
                                        :data-target="
                                            '#collapse' + treatment.SpaItemId
                                        "
                                        aria-expanded="false"
                                        :aria-controls="
                                            'collapse' + treatment.SpaItemId
                                        "
                                    >
                                        <div class="clearfix">
                                            <span class="accordion-name">{{
                                                treatment.ItemName
                                            }}</span>
                                            <div class="float-right">
                                                <span
                                                    v-if="
                                                        selectedFolio.HasUpsells
                                                    "
                                                    >&pound;{{
                                                        treatment.Price | price
                                                    }}</span
                                                >
                                                <i class="arrow"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        :id="'collapse' + treatment.SpaItemId"
                                        class="collapse"
                                        :aria-labelledby="
                                            'heading' + treatment.SpaItemId
                                        "
                                        data-parent="#acc-inc-treatment-opotion"
                                    >
                                        <div class="accordion-body">
                                            <div class="mb-3">
                                                {{ treatment.ItemDesc }}
                                            </div>
                                            <div
                                                v-if="
                                                    selectedFolio.TreatmentDates
                                                        .length > 1
                                                "
                                                class="choose-date mb-3"
                                            >
                                                <label for="date-select"
                                                    >Please select your
                                                    preferred date</label
                                                >
                                                <select
                                                    v-model="treatmentDate"
                                                    @change="
                                                        getTreatmentTimesV2(
                                                            treatment
                                                        )
                                                    "
                                                    class="custom-select custom-select-lg"
                                                >
                                                    <option
                                                        v-for="(
                                                            date, index
                                                        ) in selectedFolio.TreatmentDates"
                                                        :key="index"
                                                        :value="date"
                                                    >
                                                        {{ date | nicedate }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                v-if="treatment.TreatmentTimes"
                                                class="treatment-times"
                                            >
                                                <div>
                                                    <strong
                                                        >Please select your
                                                        preferred time</strong
                                                    >
                                                </div>
                                                <button
                                                    @click.prevent="
                                                        selectTime(time)
                                                    "
                                                    v-for="(
                                                        time, ttk
                                                    ) in treatment.TreatmentTimes"
                                                    :key="ttk"
                                                    class="btn btn-tt mr-1"
                                                    :class="{
                                                        btnSelected:
                                                            time ==
                                                            selectedTime,
                                                    }"
                                                >
                                                    {{
                                                        time.StartTime | rstime
                                                    }}
                                                </button>
                                                <div class="clearfix">
                                                    <button
                                                        @click.prevent="
                                                            addTreatmentToCart()
                                                        "
                                                        class="btn btn-secondary btn-block mr-1"
                                                        :disabled="
                                                            !selectedTime
                                                        "
                                                    >
                                                        Add to booking
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-else class="clearfix">
                                                <div
                                                    v-if="
                                                        treatment.noAvailability
                                                    "
                                                >
                                                    <h6 class="mt-2">
                                                        Sorry, no availability.
                                                        Please call Reservations
                                                        to book.
                                                    </h6>
                                                </div>
                                                <div v-else>
                                                    <button
                                                        :disabled="loading"
                                                        class="btn ersed btn-xs float-right mt-2 mb-0 mr-2"
                                                        @click.prevent="
                                                            getTreatmentTimesV2(
                                                                treatment
                                                            )
                                                        "
                                                    >
                                                        Choose a time
                                                        <div
                                                            v-if="loading"
                                                            class="spinner-border spinner-border-sm"
                                                            role="status"
                                                        ></div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mb-5">
                                <div class="accordion-style">
                                    <h3>
                                        {{ selectedInclusive.SpaItem.ItemName }}
                                    </h3>
                                    <p>
                                        {{ selectedInclusive.SpaItem.ItemDesc }}
                                    </p>
                                </div>
                                <div
                                    v-if="
                                        selectedFolio.TreatmentDates.length > 1
                                    "
                                    class="choose-date mb-3"
                                >
                                    <label for="date-select"
                                        >Please select your preferred
                                        date</label
                                    >
                                    <select
                                        v-model="treatmentDate"
                                        @change="
                                            getTreatmentTimesV2(
                                                selectedInclusive.SpaItem
                                            )
                                        "
                                        class="custom-select custom-select-lg"
                                    >
                                        <option
                                            v-for="(
                                                date, index
                                            ) in selectedFolio.TreatmentDates"
                                            :key="index"
                                            :value="date"
                                        >
                                            {{ date | nicedate }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    v-if="
                                        selectedInclusive.SpaItem.TreatmentTimes
                                    "
                                    class="treatment-times"
                                >
                                    <div>
                                        <strong
                                            >Please select your preferred
                                            time</strong
                                        >
                                    </div>
                                    <button
                                        @click.prevent="selectTime(time)"
                                        v-for="(time, ttk) in selectedInclusive
                                            .SpaItem.TreatmentTimes"
                                        :key="ttk"
                                        class="btn btn-tt mr-1"
                                        :class="{
                                            btnSelected: time == selectedTime,
                                        }"
                                    >
                                        {{ time.StartTime | rstime }}
                                    </button>
                                    <div class="clearfix">
                                        <button
                                            @click.prevent="
                                                addTreatmentToCart()
                                            "
                                            class="btn btn-secondary mr-1"
                                            :disabled="!selectedTime"
                                        >
                                            Add to booking
                                        </button>
                                    </div>
                                </div>
                                <div v-else class="clearfix">
                                    <div
                                        v-if="selectedInclusive.noAvailability"
                                    >
                                        <h6 class="text-right mt-2">
                                            Sorry, no availability. Please call
                                            Reservations to book.
                                        </h6>
                                    </div>
                                    <div v-else>
                                        <button
                                            :disabled="loading"
                                            class="btn ersed btn-xs float-right mt-2 mb-0 mr-2"
                                            @click.prevent="
                                                getTreatmentTimesV2(
                                                    selectedInclusive.SpaItem
                                                )
                                            "
                                        >
                                            Choose a time
                                            <div
                                                v-if="loading"
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                            ></div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="selectedInclusive.UpsellList"
                                class="upsell-blush"
                            >
                                <div class="full-width-inner">
                                    <div v-if="!selectedFolio.HasOffers">
                                        <h3>Looking for an upgrade?</h3>
                                        <p>
                                            Why not elevate your inclusive
                                            treatment by upgrading at a
                                            discounted price?
                                        </p>
                                    </div>

                                    <div
                                        class="choose-date mb-3 accordion"
                                        id="acc-upsell-treatment-option"
                                    >
                                        <div
                                            v-for="(
                                                upsell, tk
                                            ) in selectedInclusive.UpsellList"
                                            :key="tk"
                                            class="accordion-item"
                                        >
                                            <div
                                                class="accordion-header"
                                                :id="
                                                    'heading' + upsell.SpaItemId
                                                "
                                                data-toggle="collapse"
                                                :data-target="
                                                    '#collapse' +
                                                    upsell.SpaItemId
                                                "
                                                aria-expanded="false"
                                                :aria-controls="
                                                    'collapse' +
                                                    upsell.SpaItemId
                                                "
                                            >
                                                <div class="clearfix">
                                                    <span
                                                        class="accordion-name"
                                                        >{{
                                                            upsell.ItemName
                                                        }}</span
                                                    >
                                                    <div class="float-right">
                                                        <span
                                                            >&pound;{{
                                                                upsell.Price
                                                                    | price
                                                            }}</span
                                                        >
                                                        <i class="arrow"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                :id="
                                                    'collapse' +
                                                    upsell.SpaItemId
                                                "
                                                class="collapse"
                                                :aria-labelledby="
                                                    'heading' + upsell.SpaItemId
                                                "
                                                data-parent="#acc-upsell-treatment-option"
                                            >
                                                <div class="accordion-body">
                                                    <div>
                                                        {{ upsell.ItemDesc }}
                                                    </div>
                                                    <div
                                                        v-if="
                                                            selectedFolio
                                                                .TreatmentDates
                                                                .length > 1
                                                        "
                                                        class="choose-date mb-3"
                                                    >
                                                        <label for="date-select"
                                                            >Please select your
                                                            preferred
                                                            date</label
                                                        >
                                                        <select
                                                            v-model="
                                                                treatmentDate
                                                            "
                                                            @change="
                                                                getTreatmentTimesV2(
                                                                    upsell
                                                                )
                                                            "
                                                            class="custom-select custom-select-lg"
                                                        >
                                                            <option
                                                                v-for="(
                                                                    date, index
                                                                ) in selectedFolio.TreatmentDates"
                                                                :key="index"
                                                                :value="date"
                                                            >
                                                                {{
                                                                    date
                                                                        | nicedate
                                                                }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            upsell.TreatmentTimes
                                                        "
                                                        class="treatment-times"
                                                    >
                                                        <div>
                                                            <strong
                                                                >Please select
                                                                your preferred
                                                                time</strong
                                                            >
                                                        </div>
                                                        <button
                                                            @click.prevent="
                                                                selectTime(time)
                                                            "
                                                            v-for="(
                                                                time, ttk
                                                            ) in upsell.TreatmentTimes"
                                                            :key="ttk"
                                                            class="btn btn-tt mr-1"
                                                            :class="{
                                                                btnSelected:
                                                                    time ==
                                                                    selectedTime,
                                                            }"
                                                        >
                                                            {{
                                                                time.StartTime
                                                                    | rstime
                                                            }}
                                                        </button>
                                                        <div class="clearfix">
                                                            <button
                                                                @click.prevent="
                                                                    addTreatmentToCart()
                                                                "
                                                                class="btn btn-secondary btn-block mr-1"
                                                                :disabled="
                                                                    !selectedTime
                                                                "
                                                            >
                                                                Add to booking
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="clearfix"
                                                    >
                                                        <div
                                                            v-if="
                                                                upsell.noAvailability
                                                            "
                                                        >
                                                            <h6 class="mt-2">
                                                                Sorry, no
                                                                availability.
                                                                Please call
                                                                Reservations to
                                                                book.
                                                            </h6>
                                                        </div>
                                                        <div v-else>
                                                            <button
                                                                :disabled="
                                                                    loading
                                                                "
                                                                class="btn ersed btn-xs float-right mt-2 mb-0 mr-2"
                                                                @click.prevent="
                                                                    getTreatmentTimesV2(
                                                                        upsell
                                                                    )
                                                                "
                                                            >
                                                                Choose a time
                                                                <div
                                                                    v-if="
                                                                        loading
                                                                    "
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                ></div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Additional treatment modal -->
        <div
            id="additional-treatment-modal"
            class="modal fade"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">&nbsp;</div>
                    <div class="modal-body">
                        <h5 class="brandHeading text-center mb-4">
                            LOOKING TO REACH A NEW LEVEL OF RELAXATION?
                        </h5>
                        <div class="mb-3 text-center">
                            Would you like to take your spa experience to new
                            heights, booking an additional treatment or two? We
                            always recommend booking in advance to avoid
                            disappointment...
                        </div>
                        <div class="d-flex">
                            <button
                                data-dismiss="modal"
                                class="btn btn-primary flex-fill mr-2"
                            >
                                Yes please
                            </button>
                            <button
                                @click.prevent="nextStep()"
                                class="btn btn-primary flex-fill ml-2"
                                data-dismiss="modal"
                            >
                                No thanks
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Seasonal treatment modal -->
        <div
            id="seasonal-treatment-modal"
            class="modal fade"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">&nbsp;</div>
                    <div class="modal-body">
                        <h5 class="brandHeading text-center mb-4">
                            Save £10 on our Seasonal Treatment!
                        </h5>
                        <div class="mb-3 text-center">
                            Why not try our limited-edition Nectar Full Body
                            Ritual? Simply select this treatment in the next
                            window for full description.
                        </div>
                        <div class="d-flex">
                            <button
                                data-dismiss="modal"
                                class="btn btn-primary flex-fill mr-2"
                            >
                                No thank you
                            </button>
                            <button
                                @click.prevent="selectFirstTreatment()"
                                class="btn btn-primary flex-fill ml-2"
                                data-dismiss="modal"
                            >
                                Tell me more
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressIndicator from "../book/ProgressIndicator.vue";
export default {
    props: {
        cart: Object,
        system: Object,
        inclusives: Array,
        venue: String,
        type: String,
    },
    components: {
        ProgressIndicator,
    },
    /**
     * The components data
     */
    data() {
        return {
            step: 2,
            arrive: this.$route.params.arrive,
            guests: this.$route.params.guests,
            venueName: this.$options.filters.venue(this.venue, this.type),
            selectedFolio: null,
            loading: false,
            treatments: null,
            hchDayTreatments: null,
            hchStayTreatments: null,
            edenTreatments: null,
            addingTreatment: false,
            extras: null,
            loadingExtras: false,
            addingExtra: false,
            extraQty: 1,
            TempSpaNewCustomerForm: {
                NewCustomer: {
                    Name: {
                        FirstName: this.system.WebFolioId,
                        LastName: "TempWebFolio",
                    },
                    EmailAddress: null,
                },
                ContactLogs: {
                    ContactLog: null,
                },
            },
            changingTreatment: false,
            treatmentDate: this.$route.params.arrive,
            treatmentUnavailable: false,
            selectedTreatment: null,
            selectedTime: null,
            selectedInclusive: null,
            selectedInclusiveTime: null,
            selectedTreatmentDate: null,
            removing: null,
            removingExtra: null,
            removingInsurance: false,
            treatmentGuestNo: null,
            filteredCategory: "All",
            rooms: [],
        };
    },

    mounted() {
        var existingRoomConfiguration = JSON.parse(
            window.localStorage.getItem("webfolio_rooms")
        );

        if (
            existingRoomConfiguration !== null &&
            existingRoomConfiguration[this.system.WebFolioId] !== undefined
        ) {
            this.rooms = existingRoomConfiguration;
        }

        //Bus.$emit('RefreshSystem')
        // if(this.venue == 'eden-hall') {
        //     $('#seasonal-treatment-modal').modal('show')
        // }
    },

    watch: {
        cart: function (newVal, oldVal) {
            if (!newVal.WebFolioItem) {
                var firstDate = JSON.parse(
                    window.localStorage.getItem("rsbooking")
                );

                if (firstDate && firstDate[this.system.WebFolioId]) {
                    var start = firstDate[this.system.WebFolioId];
                    if (start.booking.type == "stay") {
                        this.$router.push(
                            "/book/booking/" +
                                start.booking.venue +
                                "/" +
                                start.booking.type +
                                "/" +
                                start.booking.arrive +
                                "/" +
                                start.booking.nights +
                                "/" +
                                start.booking.guests
                        );
                    } else {
                        this.$router.push(
                            "/book/booking/" +
                                start.booking.venue +
                                "/" +
                                start.booking.type +
                                "/" +
                                start.booking.arrive +
                                "/" +
                                start.booking.guests
                        );
                    }
                } else {
                    this.$router.push("/book");
                }
            }
        },
    },

    beforeMount() {
        this.getCachedTreatments();
    },
    computed: {
        noBook() {
            if (this.addingTreatment || !this.selectedTime) {
                return true;
            }
            return false;
        },
        filteredCart() {
            if (this.cart.WebFolioItem) {
                return _.filter(this.cart.WebFolioItem, { Category: "Hotel" });
            } else {
                return null;
            }
        },
        pageDate() {
            if (this.cart.WebFolioItem) {
                return this.cart.WebFolioItem[0].StartDate.slice(0, -6);
            } else {
                this.arrive;
            }
        },
        insuranceTotal() {
            var self = this;

            var filtered = _.filter(self.cart.WebFolioItem, function (f) {
                return f.Category != "Classes";
            });
            if (filtered) {
                var total = _.sumBy(filtered, function (folio) {
                    return (
                        folio.Insurance.Price *
                        folio.Insurance.PMSSpecialServiceQty
                    );
                });
                return total;
            } else {
                return null;
            }
        },
        insuranceAdded() {
            var self = this;

            var folioLength = 0;
            var insuranceCount = 0;
            if (self.cart.WebFolioItem) {
                _.each(self.cart.WebFolioItem, function (folio) {
                    if (folio.Category == "Hotel" && folio.Insurance.Price) {
                        folioLength++;
                        if (folio.extras) {
                            _.each(
                                folio.extras.specialServices
                                    .PMSFolioSpecialService,
                                function (extra) {
                                    if (
                                        extra.PMSSpecialService.PMSSpecialServiceName.includes(
                                            "Insurance"
                                        )
                                    ) {
                                        insuranceCount++;
                                    }
                                }
                            );
                        }
                    }
                });
                if (folioLength > 0) {
                    if (folioLength == insuranceCount) {
                        Bus.$emit("hideLoading");
                        return true;
                    } else {
                        if (self.removingInsurance) {
                            Bus.$emit("hideLoading");
                            self.removingInsurance = false;
                        }
                        return false;
                    }
                }
            } else {
                return false;
            }
        },
        hasAdditionalTreatments() {
            var self = this;
            var hasTmt = false;
            if (self.cart.WebFolioItem) {
                _.each(self.cart.WebFolioItem, function (folio) {
                    if (folio.Category == "Hotel") {
                        if (folio.Treatments.length) {
                            hasTmt = true;
                        }
                    }
                });
            }
            return hasTmt;
        },
    },

    /**
     * Methods
     */
    methods: {
        getRoomGuests(folioId) {
            var room = this.rooms[this.system.WebFolioId].find(
                (item) => item.folioId == folioId
            );

            return room.guests;
        },
        filteredCategories(categories) {
            if (this.filteredCategory === "All") {
                return categories;
            }
            if (this.filteredCategory === "Body / Massage") {
                return categories.filter((category) => {
                    return (
                        category.ItemImage === "Body" ||
                        category.ItemImage === "Massage"
                    );
                });
            }
            return categories.filter((category) => {
                return category.ItemImage === this.filteredCategory;
            });
        },
        payNow() {
            this.$router.push("/book/cart");
        },
        selectFirstTreatment() {
            if (this.filteredCart) {
                this.showTreatments(this.filteredCart[0]);
            }
        },
        checkAdditionalTreatments() {
            this.nextStep();
            /*
                if(this.hasAdditionalTreatments) {
                    this.nextStep()
                } else {
                    $('#additional-treatment-modal').modal('show')
                }
                */
        },
        addPackage() {
            if (this.type == "spa-day") {
                var url =
                    "/book/booking/" +
                    this.$route.params.venue +
                    "/" +
                    this.type +
                    "/" +
                    this.$route.params.arrive +
                    "/1";
            } else {
                var url =
                    "/book/booking/" +
                    this.$route.params.venue +
                    "/" +
                    this.type +
                    "/" +
                    this.$route.params.arrive +
                    "/" +
                    this.$route.params.nights +
                    "/" +
                    this.$route.params.guests;
            }
            this.$router.push({ path: url });
        },
        nextStep() {
            if (this.type == "spa-day") {
                var url =
                    "/book/finishing-touches/" +
                    this.$route.params.venue +
                    "/" +
                    this.type +
                    "/" +
                    this.$route.params.arrive +
                    "/1";
            } else {
                var url =
                    "/book/finishing-touches/" +
                    this.$route.params.venue +
                    "/" +
                    this.type +
                    "/" +
                    this.$route.params.arrive +
                    "/" +
                    this.$route.params.nights +
                    "/" +
                    this.$route.params.guests;
            }
            this.$router.push({ path: url });
        },

        closeSideout() {
            $(".booking-app-sideout").removeClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        restartBooking() {
            Bus.$emit("RestartBooking", true);
        },

        removeItem(item) {
            Bus.$emit("RemoveItem", item);
        },

        showTreatments(folio) {
            this.treatments = null;
            this.selectedFolio = folio;
            this.treatmentDate = folio.StartDate.slice(0, -6);
            if (folio.TreatmentDates.length == 1) {
                this.treatmentDate = folio.TreatmentDates[0];
            } else {
                this.treatmentDate = folio.StartDate.slice(0, -6);
            }
            if (folio.Location == "Hoar Cross Hall") {
                this.treatments = _.clone(this.hchStayTreatments);
            } else if (folio.Location == "Hoar Cross Hall - Day Spa") {
                this.treatments = _.clone(this.hchDayTreatments);
            } else if (folio.Location == "Eden Hall - Day Spa") {
                this.treatments = _.clone(this.edenTreatments);
                // If not Purity, remove express treatments
                if (folio.RateType != "EHPURITY") {
                    _.remove(this.treatments, function (t) {
                        return t.cat == "Express Treatments";
                    });
                }
            }
            // Temp remove seasonal treatment if not July/Aug
            /*
                if(moment(this.treatmentDate).month() != 6 && moment(this.treatmentDate).month() != 7) {
                    _.remove(this.treatments, function(t) {
                        return t.cat == 'SEASONAL TREATMENT OFFER'
                    })
                }
                */
            $(".treatment-selector").toggleClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        addInclusive(folio, inclusive, guest) {
            this.selectedInclusive = null;
            this.selectedFolio = null;
            this.treatmentDate = null;
            this.treatmentGuestNo = guest;

            var self = this;

            // Do we have a second option?
            if (inclusive.SpaItemIdTwo && inclusive.SpaItemIdTwo.length) {
                this.treatmentGuestNo = null;
                if (inclusive.Inclusives[0]) {
                    // one in the cart already
                    if (
                        _.includes(
                            inclusive.SpaItemIdTwo,
                            +inclusive.Inclusives[0].ItemId
                        )
                    ) {
                        // its option 2
                        var incOptionList = inclusive.SpaItemId;
                    } else {
                        var incOptionList = inclusive.SpaItemIdTwo;
                    }
                } else {
                    if (guest == 1) {
                        var incOptionList = inclusive.SpaItemId;
                    } else {
                        var incOptionList = inclusive.SpaItemIdTwo;
                    }
                }
            } else {
                var incOptionList = inclusive.SpaItemId;
            }

            var inclusiveList = _.filter(self.inclusives, function (t) {
                return _.includes(incOptionList, t.SpaItemId);
            });

            if (inclusiveList.length == 1) {
                inclusive.SpaItem = inclusiveList[0];
            } else {
                inclusive.OptionList = inclusiveList;
            }

            // Do we have upsells?
            if (inclusive.Upsells) {
                inclusive.UpsellList = _.filter(self.inclusives, function (t) {
                    return _.includes(inclusive.Upsells, t.SpaItemId);
                });
            }

            this.selectedInclusive = inclusive;
            this.selectedFolio = folio;
            this.treatmentDate = inclusive.Date;
            if (!inclusive.Upsells) {
                //this.getTreatmentTimesV2(this.selectedInclusive.SpaItem)
            }
            $(".inclusive-selector").toggleClass("is-active");
            $("html").toggleClass("no-scroll");
        },

        isSeasonalId(idToCheck) {
            return _.includes(
                [
                    100811661, 100811663, 100811660, 100811662, 300811540,
                    300811542, 300811541,
                ],
                idToCheck
            );
        },

        getCachedTreatments() {
            if (this.system.treatments.length) {
                var tmts = _.clone(this.system.treatments);

                _.each(tmts, function (t) {
                    t.Price = parseInt(t.Price.replace(".00", ""));
                    t.TreatmentTimes = null;
                    t.noAvailability = false;
                });

                tmts = _.filter(tmts, (service) => service.Price >= 60);

                _.remove(
                    tmts,
                    (service) =>
                        this.isSeasonalId(service.SpaItemId) &&
                        ![4, 5, 6, 7, 8].includes(moment(this.arrive).month())
                );

                // Day/Stay treatments HCH

                var stay = _.filter(tmts, {
                    ClientInstructions: "Stay",
                    Location: 100,
                });
                stay = _.filter(
                    stay,
                    (t) =>
                        t.ItemImage == "Body / Massage" ||
                        t.ItemImage == "Face" ||
                        t.ItemImage == "Body" ||
                        t.ItemImage == "Massage" ||
                        t.ItemImage == "Parlour" ||
                        t.ItemImage == "Holistic" ||
                        t.ItemImage == "Seasonal Treatment Offer" ||
                        t.ItemImage == "Radiance" ||
                        t.ItemImage == "Revive" ||
                        t.ItemImage == "Renewal" ||
                        t.ItemImage == "Voya Seaweed Therapy" ||
                        t.ItemImage == "The Parlour" ||
                        t.ItemImage == "Hand & Feet"
                );
                stay = _.groupBy(stay, "ItemCategory");
                var stayArray = [];
                /*
                    if(stay['SEASONAL TREATMENT OFFER']) { stayArray.push({ 'cat': 'SEASONAL TREATMENT OFFER', 'list': stay['SEASONAL TREATMENT OFFER'] }) }
                    if(stay['BODY']) { stayArray.push({ 'cat': 'BODY', 'list': stay['BODY'] }) }
                    if(stay['FACE']) { stayArray.push({ 'cat': 'FACE', 'list': stay['FACE'] }) }
                    if(stay['HANDS & FEET']) { stayArray.push({ 'cat': 'HANDS & FEET', 'list': stay['HANDS & FEET'] }) }
                    if(stay['HOLISTIC']) { stayArray.push({ 'cat': 'HOLISTIC', 'list': stay['HOLISTIC'] }) }
                    if(stay['MEN']) { stayArray.push({ 'cat': 'MEN', 'list': stay['MEN'] }) }
                    if(stay['BEAUTY']) { stayArray.push({ 'cat': 'BEAUTY', 'list': stay['BEAUTY'] }) }
                    if(stay['THE PARLOUR']) { stayArray.push({ 'cat': 'THE PARLOUR', 'list': stay['THE PARLOUR'] }) }
                    */
                if (stay["SEASONAL TREATMENT OFFER"]) {
                    stayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: stay["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (stay["RADIANCE"]) {
                    stayArray.push({ cat: "RADIANCE", list: stay["RADIANCE"] });
                }
                if (stay["REVIVE"]) {
                    stayArray.push({ cat: "REVIVE", list: stay["REVIVE"] });
                }
                if (stay["RENEWAL"]) {
                    stayArray.push({ cat: "RENEWAL", list: stay["RENEWAL"] });
                }
                if (stay["VOYA SEAWEED THERAPY"]) {
                    stayArray.push({
                        cat: "VOYA SEAWEED THERAPY",
                        list: stay["VOYA SEAWEED THERAPY"],
                    });
                }
                if (stay["HOLISTIC"]) {
                    stayArray.push({ cat: "HOLISTIC", list: stay["HOLISTIC"] });
                }
                if (stay["HANDS & FEET"]) {
                    stayArray.push({
                        cat: "HANDS & FEET",
                        list: stay["HANDS & FEET"],
                    });
                }
                if (stay["THE PARLOUR"]) {
                    stayArray.push({
                        cat: "THE PARLOUR",
                        list: stay["THE PARLOUR"],
                    });
                }

                if (stay["Face"]) {
                    stayArray.push({ cat: "Face", list: stay["Face"] });
                }
                if (stay["Body / Massage"]) {
                    stayArray.push({
                        cat: "Body / Massage",
                        list: stay["Body / Massage"],
                    });
                }
                if (stay["Parlour"]) {
                    stayArray.push({ cat: "Parlour", list: stay["Parlour"] });
                }
                if (stay["Holistic"]) {
                    stayArray.push({ cat: "Holistic", list: stay["Holistic"] });
                }
                if (stay["Hair"]) {
                    stayArray.push({ cat: "Hair", list: stay["Hair"] });
                }

                this.hchStayTreatments = stayArray;

                var day = _.filter(tmts, {
                    ClientInstructions: "Day",
                    Location: 100,
                });
                day = _.filter(
                    day,
                    (t) =>
                        t.ItemImage == "Body / Massage" ||
                        t.ItemImage == "Specialist" ||
                        t.ItemImage == "Face" ||
                        t.ItemImage == "Body" ||
                        t.ItemImage == "Massage" ||
                        t.ItemImage == "Parlour" ||
                        t.ItemImage == "Holistic" ||
                        t.ItemImage == "Seasonal Treatment Offer" ||
                        t.ItemImage == "Radiance" ||
                        t.ItemImage == "Revive" ||
                        t.ItemImage == "Renewal" ||
                        t.ItemImage == "Voya Seaweed Therapy" ||
                        t.ItemImage == "The Parlour" ||
                        t.ItemImage == "Hand & Feet"
                );
                day = _.groupBy(day, "ItemCategory");
                var dayArray = [];
                /*
                    if(day['SEASONAL TREATMENT OFFER']) { dayArray.push({ 'cat': 'SEASONAL TREATMENT OFFER', 'list': day['SEASONAL TREATMENT OFFER'] }) }
                    if(day['BODY']) { dayArray.push({ 'cat': 'BODY', 'list': day['BODY'] }) }
                    if(day['FACE']) { dayArray.push({ 'cat': 'FACE', 'list': day['FACE'] }) }
                    if(day['HANDS & FEET']) { dayArray.push({ 'cat': 'HANDS & FEET', 'list': day['HANDS & FEET'] }) }
                    if(day['HOLISTIC']) { dayArray.push({ 'cat': 'HOLISTIC', 'list': day['HOLISTIC'] }) }
                    if(day['MEN']) { dayArray.push({ 'cat': 'MEN', 'list': day['MEN'] }) }
                    if(day['BEAUTY']) { dayArray.push({ 'cat': 'BEAUTY', 'list': day['BEAUTY'] }) }
                    if(day['THE PARLOUR']) { dayArray.push({ 'cat': 'THE PARLOUR', 'list': day['THE PARLOUR'] }) }
                    */
                if (day["SEASONAL TREATMENT OFFER"]) {
                    dayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: day["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (day["RADIANCE"]) {
                    dayArray.push({ cat: "RADIANCE", list: day["RADIANCE"] });
                }
                if (day["REVIVE"]) {
                    dayArray.push({ cat: "REVIVE", list: day["REVIVE"] });
                }
                if (day["RENEWAL"]) {
                    dayArray.push({ cat: "RENEWAL", list: day["RENEWAL"] });
                }
                if (day["VOYA SEAWEED THERAPY"]) {
                    dayArray.push({
                        cat: "VOYA SEAWEED THERAPY",
                        list: day["VOYA SEAWEED THERAPY"],
                    });
                }
                if (day["HOLISTIC"]) {
                    dayArray.push({ cat: "HOLISTIC", list: day["HOLISTIC"] });
                }
                if (day["HANDS & FEET"]) {
                    dayArray.push({
                        cat: "HANDS & FEET",
                        list: day["HANDS & FEET"],
                    });
                }
                if (day["THE PARLOUR"]) {
                    dayArray.push({
                        cat: "THE PARLOUR",
                        list: day["THE PARLOUR"],
                    });
                }

                if (day["Face"]) {
                    dayArray.push({ cat: "Face", list: day["Face"] });
                }
                if (day["Body / Massage"]) {
                    dayArray.push({
                        cat: "Body / Massage",
                        list: day["Body / Massage"],
                    });
                }
                if (day["Specialist"]) {
                    dayArray.push({
                        cat: "Specialist",
                        list: day["Specialist"],
                    });
                }
                if (day["Parlour"]) {
                    dayArray.push({ cat: "Parlour", list: day["Parlour"] });
                }
                if (day["Holistic"]) {
                    dayArray.push({ cat: "Holistic", list: day["Holistic"] });
                }
                if (day["Hair"]) {
                    dayArray.push({ cat: "Hair", list: day["Hair"] });
                }

                this.hchDayTreatments = dayArray;

                // Eden
                var day = _.filter(tmts, {
                    ClientInstructions: "Day",
                    Location: 300,
                });
                day = _.filter(
                    day,
                    (t) =>
                        t.ItemImage == "Body / Massage" ||
                        t.ItemImage == "Specialist" ||
                        t.ItemImage == "Face" ||
                        t.ItemImage == "Body" ||
                        t.ItemImage == "Massage" ||
                        t.ItemImage == "Parlour" ||
                        t.ItemImage == "Holistic" ||
                        t.ItemImage == "Seasonal Treatment Offer" ||
                        t.ItemImage == "Radiance" ||
                        t.ItemImage == "Revive" ||
                        t.ItemImage == "Renewal" ||
                        t.ItemImage == "Voya Seaweed Therapy" ||
                        t.ItemImage == "The Parlour" ||
                        t.ItemImage == "Hand & Feet"
                );
                day = _.groupBy(day, "ItemCategory");
                var dayArray = [];
                /*
                    if(day['Express Treatments']) { dayArray.push({ 'cat': 'Express Treatments', 'list': day['Express Treatments'] }) }
                    if(day['SEASONAL TREATMENT OFFER']) { dayArray.push({ 'cat': 'SEASONAL TREATMENT OFFER', 'list': day['SEASONAL TREATMENT OFFER'] }) }
                    if(day['BODY']) { dayArray.push({ 'cat': 'BODY', 'list': day['BODY'] }) }
                    if(day['FACE']) { dayArray.push({ 'cat': 'FACE', 'list': day['FACE'] }) }
                    if(day['HANDS & FEET']) { dayArray.push({ 'cat': 'HANDS & FEET', 'list': day['HANDS & FEET'] }) }
                    if(day['HOLISTIC']) { dayArray.push({ 'cat': 'HOLISTIC', 'list': day['HOLISTIC'] }) }
                    if(day['MEN']) { dayArray.push({ 'cat': 'MEN', 'list': day['MEN'] }) }
                    if(day['BEAUTY']) { dayArray.push({ 'cat': 'BEAUTY', 'list': day['BEAUTY'] }) }
                    if(day['THE PARLOUR']) { dayArray.push({ 'cat': 'THE PARLOUR', 'list': day['THE PARLOUR'] }) }
                    */
                if (day["SEASONAL TREATMENT OFFER"]) {
                    dayArray.push({
                        cat: "SEASONAL TREATMENT OFFER",
                        list: day["SEASONAL TREATMENT OFFER"],
                    });
                }
                if (day["RADIANCE"]) {
                    dayArray.push({ cat: "RADIANCE", list: day["RADIANCE"] });
                }
                if (day["REVIVE"]) {
                    dayArray.push({ cat: "REVIVE", list: day["REVIVE"] });
                }
                if (day["RENEWAL"]) {
                    dayArray.push({ cat: "RENEWAL", list: day["RENEWAL"] });
                }
                if (day["VOYA SEAWEED THERAPY"]) {
                    dayArray.push({
                        cat: "VOYA SEAWEED THERAPY",
                        list: day["VOYA SEAWEED THERAPY"],
                    });
                }
                if (day["HOLISTIC"]) {
                    dayArray.push({ cat: "HOLISTIC", list: day["HOLISTIC"] });
                }
                if (day["HANDS & FEET"]) {
                    dayArray.push({
                        cat: "HANDS & FEET",
                        list: day["HANDS & FEET"],
                    });
                }
                if (day["THE PARLOUR"]) {
                    dayArray.push({
                        cat: "THE PARLOUR",
                        list: day["THE PARLOUR"],
                    });
                }

                if (day["Face"]) {
                    dayArray.push({ cat: "Face", list: day["Face"] });
                }
                if (day["Body / Massage"]) {
                    dayArray.push({
                        cat: "Body / Massage",
                        list: day["Body / Massage"],
                    });
                }
                if (day["Specialist"]) {
                    dayArray.push({
                        cat: "Specialist",
                        list: day["Specialist"],
                    });
                }
                if (day["Parlour"]) {
                    dayArray.push({ cat: "Parlour", list: day["Parlour"] });
                }
                if (day["Holistic"]) {
                    dayArray.push({ cat: "Holistic", list: day["Holistic"] });
                }
                if (day["Hair"]) {
                    dayArray.push({ cat: "Hair", list: day["Hair"] });
                }
                this.edenTreatments = dayArray;
            } else {
                // Not sure yet
            }
        },

        selectTime(item) {
            if (!_.isArray(item.SpaStaffs.SpaStaff)) {
                item.SpaStaffs.SpaStaff = [item.SpaStaffs.SpaStaff];
            }
            this.selectedTime = item;
        },

        getTreatmentTimesV2(treatment, autoPick = false) {
            var self = this;
            treatment.TreatmentTimes = null;
            this.selectedTreatment = treatment;
            this.loading = true;

            // For stays, we need to check if middle days as arrival time for Treatments will be 10:00am.
            var startTime = "093000";

            var request = {
                method: "FetchSpaAvailability",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.WebFolioCustomerId,
                    SpaItemId: treatment.SpaItemId,
                    StartDateTime: this.treatmentDate,
                    SpaLocationId: treatment.Location,
                    SpaStaffId: null,
                    Gender: "X",
                    Version: "2",
                    IsForPackageItem: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    if (response.data.SpaAvailabilities.SpaAvailability) {
                        // we have availabilities
                        if (
                            !_.isArray(
                                response.data.SpaAvailabilities.SpaAvailability
                            )
                        ) {
                            response.data.SpaAvailabilities.SpaAvailability = [
                                response.data.SpaAvailabilities.SpaAvailability,
                            ];
                        }

                        // Clone results
                        var filteredAvailability = _.clone(
                            response.data.SpaAvailabilities.SpaAvailability
                        );

                        // Remove all treatments before/after arrival/departure
                        if (self.selectedFolio.Location == "Hoar Cross Hall") {
                            var noOfNights =
                                self.selectedFolio.TreatmentDates.length;

                            if (
                                _.includes(
                                    self.selectedFolio.RateDetails,
                                    "Purity"
                                )
                            ) {
                                self.selectedFolio.ArrivalTime = "093000";
                            }

                            _.remove(
                                filteredAvailability,
                                function (avaToRemove) {
                                    if (
                                        self.treatmentDate ==
                                        self.selectedFolio.TreatmentDates[0]
                                    ) {
                                        // first day
                                        if (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ) <
                                            moment(
                                                self.treatmentDate +
                                                    self.selectedFolio
                                                        .ArrivalTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                        ) {
                                            return true;
                                        }
                                    } else if (
                                        self.treatmentDate ==
                                        self.selectedFolio.TreatmentDates[
                                            noOfNights - 1
                                        ]
                                    ) {
                                        // last day
                                        if (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ) >
                                            moment(
                                                self.treatmentDate +
                                                    self.selectedFolio
                                                        .DepartureTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                        ) {
                                            return true;
                                        }
                                    }
                                    if (
                                        moment(
                                            avaToRemove.StartTime,
                                            "YYYY-MM-DDhhmmss"
                                        ) >
                                        moment(
                                            self.treatmentDate + "183000",
                                            "YYYY-MM-DDhhmmss"
                                        )
                                    ) {
                                        // remove anything later than 1830 for overnight guests
                                        return true;
                                    }
                                }
                            );
                        } else {
                            _.remove(
                                filteredAvailability,
                                function (avaToRemove) {
                                    if (
                                        moment(
                                            avaToRemove.StartTime,
                                            "YYYY-MM-DDhhmmss"
                                        ) >
                                            moment(
                                                self.treatmentDate +
                                                    self.selectedFolio
                                                        .ArrivalTime,
                                                "YYYY-MM-DDhhmmss"
                                            ) &&
                                        moment(
                                            avaToRemove.StartTime,
                                            "YYYY-MM-DDhhmmss"
                                        ) <
                                            moment(
                                                self.treatmentDate +
                                                    self.selectedFolio
                                                        .DepartureTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            );
                        }

                        // Clone results
                        var finalAvailability = _.clone(filteredAvailability);

                        // Now remove any second treatment slots if the first slot is available. (Eden + 2hrs, HCH +15mins)
                        _.each(filteredAvailability, function (availability) {
                            if (
                                self.selectedFolio.Location ==
                                "Eden Hall - Day Spa"
                            ) {
                                _.remove(
                                    finalAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                availability.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                                .add(2, "h")
                                                .format(
                                                    "DD-MM-YYYY hh:mm:ss"
                                                ) ==
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("DD-MM-YYYY hh:mm:ss")
                                        );
                                    }
                                );
                            } else {
                                // HCH
                                _.remove(
                                    finalAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                availability.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            )
                                                .add(15, "m")
                                                .format(
                                                    "DD-MM-YYYY hh:mm:ss"
                                                ) ==
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("DD-MM-YYYY hh:mm:ss")
                                        );
                                    }
                                );
                            }
                            // Remove 17:30 slot if 18:45 slot exisits at Eden & HCH
                            if (self.selectedFolio.Type == "Spa Day") {
                                if (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("HH:mm:ss") == "17:30:00"
                                ) {
                                    _.remove(
                                        finalAvailability,
                                        function (avaToRemove) {
                                            return (
                                                moment(
                                                    avaToRemove.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                ).format("HH:mm:ss") ==
                                                "18:45:00"
                                            );
                                        }
                                    );
                                }
                            }
                            // For Eden, remove that pesky 15:45 time slot
                            if (
                                self.selectedFolio.Location ==
                                "Eden Hall - Day Spa"
                            ) {
                                if (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("HH:mm:ss") == "15:30:00"
                                ) {
                                    _.remove(
                                        finalAvailability,
                                        function (avaToRemove) {
                                            return (
                                                moment(
                                                    avaToRemove.StartTime,
                                                    "YYYY-MM-DDhhmmss"
                                                ).format("HH:mm:ss") ==
                                                "15:45:00"
                                            );
                                        }
                                    );
                                }
                            }
                        });

                        if (finalAvailability.length) {
                            if (autoPick) {
                                const slotsWithMaxSpaStaff =
                                    this.findSlotsWithMaxSpaStaff(
                                        finalAvailability
                                    );
                                const randomSlot =
                                    this.pickRandomSlot(slotsWithMaxSpaStaff);
                                this.selectTime(randomSlot);
                                this.addTreatmentToCart();
                            } else {
                                treatment.TreatmentTimes = finalAvailability;
                            }
                        } else {
                            treatment.noAvailability = true;
                        }
                    } else {
                        treatment.noAvailability = true;
                    }
                } else {
                    treatment.noAvailability = true;
                }
                this.loading = false;
            });
        },

        calculateTotalSpaStaff(slot) {
            if (
                typeof slot.SpaStaffs.SpaStaff === "object" &&
                slot.SpaStaffs.SpaStaff !== null
            ) {
                return 1;
            } else {
                return slot.SpaStaffs.SpaStaff.length;
            }
        },

        findSlotsWithMaxSpaStaff(slots) {
            let maxStaffCount = 0;
            let maxStaffSlots = [];

            for (const slot of slots) {
                const staffCount = this.calculateTotalSpaStaff(slot);
                if (staffCount > maxStaffCount) {
                    maxStaffCount = staffCount;
                    maxStaffSlots = [slot];
                } else if (staffCount === maxStaffCount) {
                    maxStaffSlots.push(slot);
                }
            }

            return maxStaffSlots;
        },

        pickRandomSlot(slots) {
            const randomIndex = Math.floor(Math.random() * slots.length);
            return slots[randomIndex];
        },

        addTreatmentToCart() {
            Bus.$emit("showLoading");

            if (this.system.CustomerId) {
                this.bookTreatment();
            } else {
                if (!this.system.tempSpaCustomerId) {
                    this.TempSpaNewCustomerForm.NewCustomer.FirstName =
                        this.system.WebFolioId;
                    this.TempSpaNewCustomerForm.NewCustomer.EmailAddress =
                        this.system.WebFolioId +
                        "@" +
                        this.system.WebFolioId +
                        ".com";
                    var request = {
                        method: "CreateCustomer",
                        data: this.TempSpaNewCustomerForm,
                    };
                    axios
                        .post("/rs/tempSpaAccount", request)
                        .then((response) => {
                            if (response.data.Result.value === "SUCCESS") {
                                this.system.tempSpaCustomerId =
                                    response.data.CustomerId;
                                this.system.tempLoggedIn = true;

                                this.bookTreatment();
                            } else {
                                console.log("temp login failed");
                            }
                        });
                } else {
                    this.bookTreatment();
                }
            }
        },

        bookTreatment() {
            var request = {
                method: "CreateSpaBooking",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.CustomerId
                        ? this.system.CustomerId
                        : this.system.tempSpaCustomerId,
                    StartDateTime: this.selectedTime.StartTime,
                    SpaItemId: this.selectedTreatment.SpaItemId,
                    SpaFolioItemId: null,
                    SpaStaffId:
                        this.selectedTime.SpaStaffs.SpaStaff[0].SpaStaffId,
                    StaffRequested: null,
                    Gender: null,
                    GuestName: null,
                    GuestRequest: this.selectedFolio.FolioId,
                    Language: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    dataLayer.push({
                        event: "add_to_cart",
                        venue: this.venueName,
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                // 'add' actionFieldObject measures.
                                products: [
                                    {
                                        //  adding a product to a shopping cart.
                                        name: this.selectedTreatment.ItemName,
                                        id: this.selectedTreatment.SpaItemId,
                                        price: +this.selectedTreatment.Price,
                                        brand: this.venueName,
                                        category: "Treatment",
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    });

                    this.$emitAnalytics(
                        "add_to_cart",
                        {
                            event: "add_to_cart",
                            venue: this.venueName,
                            ecommerce: {
                                currency: "GBP",
                                add: {
                                    products: [
                                        {
                                            name: this.selectedTreatment
                                                .ItemName,
                                            id: this.selectedTreatment
                                                .SpaItemId,
                                            price: +this.selectedTreatment
                                                .Price,
                                            brand: this.venueName,
                                            category: "Treatment",
                                            quantity: 1,
                                        },
                                    ],
                                },
                            },
                        },
                        this.system.CustomerId,
                        this.system.WebFolioId
                    );

                    Bus.$emit("UpdateCart");
                }

                $(".treatment-selector").removeClass("is-active");
                $(".inclusive-selector").removeClass("is-active");
                $("html").toggleClass("no-scroll");
                Bus.$emit("hideLoading");
                this.selectedTime = null;
                this.selectedTreatment.TreatmentTimes = null;
            });
        },

        removeTreatment(treatment) {
            this.removing = treatment.FolioItemId;
            var request = {
                method: "CancelSpaService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    SpaFolioId: treatment.FolioId,
                    SpaFolioItemId: treatment.FolioItemId,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    this.removing = null;
                    Bus.$emit("UpdateCart");
                }
            });
        },

        getTreatmentTimes(treatment) {
            var self = this;
            treatment.TreatmentTimes = null;
            this.selectedTreatment = treatment;
            this.loading = true;

            // For stays, we need to check if middle days as arrival time for Treatments will be 10:00am.
            var startTime = "093000";

            var request = {
                method: "FetchSpaAvailability",
                data: {
                    WebFolioId: this.system.WebFolioId,
                    CustomerId: this.system.isLoggedIn
                        ? this.system.CustomerId
                        : this.system.WebFolioCustomerId,
                    SpaItemId: treatment.SpaItemId,
                    StartDateTime: this.treatmentDate,
                    SpaLocationId: treatment.Location,
                    SpaStaffId: null,
                    Gender: "X",
                    Version: null,
                    IsForPackageItem: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "SUCCESS") {
                    if (!_.isArray(response.data.SpaAvailability)) {
                        response.data.SpaAvailability = [
                            response.data.SpaAvailability,
                        ];
                    }
                    // Clone results
                    var filteredAvailability = _.clone(
                        response.data.SpaAvailability
                    );

                    // Remove all treatments before/after arrival/departure
                    if (self.selectedFolio.Location == "Hoar Cross Hall") {
                        var noOfNights =
                            self.selectedFolio.TreatmentDates.length;

                        if (
                            _.includes(self.selectedFolio.RateDetails, "Purity")
                        ) {
                            self.selectedFolio.ArrivalTime = "093000";
                        }

                        _.remove(filteredAvailability, function (avaToRemove) {
                            if (
                                self.treatmentDate ==
                                self.selectedFolio.TreatmentDates[0]
                            ) {
                                // first day
                                if (
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ) <
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.ArrivalTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                ) {
                                    return true;
                                }
                            } else if (
                                self.treatmentDate ==
                                self.selectedFolio.TreatmentDates[
                                    noOfNights - 1
                                ]
                            ) {
                                // last day
                                if (
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ) >
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.DepartureTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                ) {
                                    return true;
                                }
                            }
                            if (
                                moment(
                                    avaToRemove.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ) >
                                moment(
                                    self.treatmentDate + "183000",
                                    "YYYY-MM-DDhhmmss"
                                )
                            ) {
                                // remove anything later than 1830 for overnight guests
                                return true;
                            }
                        });
                    } else {
                        _.remove(filteredAvailability, function (avaToRemove) {
                            if (
                                moment(
                                    avaToRemove.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ) >
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.ArrivalTime,
                                        "YYYY-MM-DDhhmmss"
                                    ) &&
                                moment(
                                    avaToRemove.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ) <
                                    moment(
                                        self.treatmentDate +
                                            self.selectedFolio.DepartureTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        });
                    }

                    // Clone results
                    var finalAvailability = _.clone(filteredAvailability);

                    // Now remove any second treatment slots if the first slot is available. (Eden + 2hrs, HCH +15mins)
                    _.each(filteredAvailability, function (availability) {
                        if (
                            self.selectedFolio.Location == "Eden Hall - Day Spa"
                        ) {
                            _.remove(finalAvailability, function (avaToRemove) {
                                return (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                        .add(2, "h")
                                        .format("DD-MM-YYYY hh:mm:ss") ==
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("DD-MM-YYYY hh:mm:ss")
                                );
                            });
                        } else {
                            // HCH
                            _.remove(finalAvailability, function (avaToRemove) {
                                return (
                                    moment(
                                        availability.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    )
                                        .add(15, "m")
                                        .format("DD-MM-YYYY hh:mm:ss") ==
                                    moment(
                                        avaToRemove.StartTime,
                                        "YYYY-MM-DDhhmmss"
                                    ).format("DD-MM-YYYY hh:mm:ss")
                                );
                            });
                        }
                        // Remove 17:30 slot if 18:45 slot exisits at Eden & HCH
                        if (self.selectedFolio.Type == "Spa Day") {
                            if (
                                moment(
                                    availability.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ).format("HH:mm:ss") == "17:30:00"
                            ) {
                                _.remove(
                                    finalAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("HH:mm:ss") == "18:45:00"
                                        );
                                    }
                                );
                            }
                        }
                        // For Eden, remove that pesky 15:45 time slot
                        if (
                            self.selectedFolio.Location == "Eden Hall - Day Spa"
                        ) {
                            if (
                                moment(
                                    availability.StartTime,
                                    "YYYY-MM-DDhhmmss"
                                ).format("HH:mm:ss") == "15:30:00"
                            ) {
                                _.remove(
                                    finalAvailability,
                                    function (avaToRemove) {
                                        return (
                                            moment(
                                                avaToRemove.StartTime,
                                                "YYYY-MM-DDhhmmss"
                                            ).format("HH:mm:ss") == "15:45:00"
                                        );
                                    }
                                );
                            }
                        }
                    });

                    if (finalAvailability.length) {
                        treatment.TreatmentTimes = finalAvailability;
                    } else {
                        treatment.noAvailability = true;
                    }
                } else {
                    treatment.noAvailability = true;
                }
                this.loading = false;
            });
        },
        isSaturday(startDate) {
            const date = moment(startDate, "YYYY-MM-DDHHmmss");
            if (date.isoWeekday() === 6) {
                return true;
            }
        },
        addSpaAccess(folio) {
            var self = this;
            Bus.$emit("showLoading");

            var request = {
                method: "AddPMSSpecialService",
                data: {
                    SessionId: self.system.SessionId,
                    WebFolioId: self.system.WebFolioId,
                    PMSFolioId: folio.FolioId,
                    PMSSpecialServiceName: "Spa Access",
                    PMSSpecialServiceQty: folio.Adults,
                    PMSSpecialServiceDate: folio.StartDate.slice(0, -6),
                    PMSSpecialServiceNote: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value == "FAIL") {
                    console.log(response.data.Result);
                } else {
                    Bus.$emit("UpdateCart");
                }
                Bus.$emit("hideLoading");
            });
        },
        removeSpaAccess(id, folio) {
            Bus.$emit("showLoading");
            var request = {
                method: "RemovePMSSpecialService",
                data: {
                    SessionId: this.system.SessionId,
                    WebFolioId: this.system.WebFolioId,
                    PMSFolioId: folio,
                    PMSFolioSpecialServiceId: id,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    Bus.$emit("UpdateCart");
                }
                Bus.$emit("hideLoading");
            });
        },
    },
};
</script>
