<template>
    <div class="booking-cart">
        <div class="row">
            <div v-if="paymentError" class="col-12 mb-5">
                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Payment unsuccessful</strong> There was an issue processing your payment. Please try again or contact our reservations team on 01283 576522.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="col-md-12 text-center">
                <div class="booking-info">
                    <h1 class="brandHeading">Booking Summary</h1>
                    <div class="cart-page-actions__balance mt-3">
                        <span v-if="cart.FolioBalance">Ready to pay &pound;{{ cart.FolioBalance }}?</span>
                    </div>
                    <div class="promo text-center">
                        <form>
                            <div class="form-inline mb-3 justify-content-center">
                                <div class="form-group">
                                    <input v-model="promoCode" type="text" class="form-control form-control" id="promoCode" placeholder="Promo code">
                                </div>
                                <div class="form-group">
                                    <button @click.prevent="applyPromo" class="btn btn-primary mb-0" :disabled="promoProcessing">Apply</button>
                                </div>
                            </div>
                            <p v-if="!promotionApplied && invalidPromo && !promoProcessing" class="message-small booking-notice">{{ invalidPromo }}</p>
                        </form>
                    </div>
                    <p class="small">Redeem a gift card or e-voucher on the next page</p>
                    <div class="mb-3 small">
                        <a @click.prevent="ammendBooking()">Amend booking</a>
                        <a v-if="!guestsAdded" @click.prevent="showGuestNames()" class="ml-1">Add guest names</a>
                        <a v-if="insuranceAdded" @click.prevent="removeInsurance()" class="ml-1">Remove insurance</a>
                    </div>
                </div>
                <!-- cart items -->
                <div v-if="folioByCat" class="card cart-card-items" v-for="(folioItem, cat) in folioByCat">
                    <div class="card-body">
                        <div v-if="cat === 'Your Visit'">
                            <div class="booking-list">
                                <div v-for="folio,k in folioItem"  :key="k" class="booking-list-item">
                                    <div class="booking-list-item__details">
                                        <h3><span v-if="folio.Type == 'Stay'">Room</span><span v-else>Guest</span> {{k+1}}</h3>
                                        <p>Location: {{ folio.Location }}</p>
                                        <p>Date: {{ folio.StartDate | rsnicedate }}</p>
                                        <p>Experience: {{ folio.RateDetails }}</p>
                                        <p v-if="folio.Type == 'Stay'">Guests: {{ folio.Adults }}</p>
                                        <p v-if="folio.Type == 'Stay'">
                                            <strong>Room type:</strong>
                                            {{ folio.Details }}
                                            <span v-if="folio.twinSelected">(Twin room)</span>
                                            <span v-if="folio.doubleSelected">(Double room)</span>
                                        </p>
                                        <p class="details-price">Cost: &pound;{{ (+folio.Amount).toFixed(2) }}</p>
                                        <p v-if="folio.promotionApplied" class="small">({{folio.promotionApplied}} promotion applied)</p>
                                        <p v-if="1" class="small">*Add code at checkout</p>
                                    </div>
                                    <div v-if="folio.hotelOnly" class="booking-list-item__treatments"></div>
                                    <div v-else class="booking-list-item__treatments">
                                        <div v-if="folio.InclusiveTreatments" class="inclusive-list">
                                            <div v-if="folio.HasOffers">
                                                <h3>&pound;20 off Selected Treatments</h3>
                                            </div>
                                            <div v-else>
                                                <h3>Inclusive Treatments</h3>
                                            </div>
                                            <ul class="enhance-item-list clearfix">
                                                <li v-for="treatment, k in folio.InclusiveTreatments.Inclusives" :key="k" class="clearfix">
                                                    <span v-if="treatment">
                                                        {{ treatment.Details }}.<br> {{ treatment.StartDate | rsdatetimeshort }}.
                                                    </span>
                                                    <span v-else>
                                                        <span class="ffs fwb text-uppercase mr-2">Treatment {{k+1}}</span> <span v-if="folio.HasUpsells">Inclusive treatment</span><span v-else>No treatment added</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <h3><span v-if="folio.InclusiveTreatments">Additional </span>Treatments</h3>
                                        <ul v-if="folio.Treatments.length" class="enhance-item-list">
                                            <li v-for="treatment, k in folio.Treatments" :key="k">{{ treatment.Details }}.<br> {{ treatment.StartDate | rsdatetimeshort }}. &pound;{{ treatment.Amount }}.</li>
                                        </ul>
                                        <ul v-else class="enhance-item-list">
                                            <li>No treatment added</li>
                                        </ul>
                                    </div>
                                    <div class="booking-list-item__extras">
                                        <div v-if="folio.hotelOnly">
                                            <h3>Spa access?</h3>
                                            <div v-if="folio.spaAccess">
                                                <div>{{ folio.spaAccess.Qty }} x Spa Access - &pound;{{ folio.spaAccess.TotalWithSurcharges }}</div>
                                                <a @click.prevent="removeSpaAccess(folio.spaAccess.PMSFolioSpecialServiceId, folio.FolioId)">Remove</a>
                                            </div>
                                            <div v-else>
                                                <p>No Spa Access added.</p>
                                                <a @click.prevent="ammendBooking()">Amend booking</a>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <h3>Finishing Touches</h3>
                                            <ul v-if="folio.Cabanas" class="enhance-item-list">
                                                <li v-for="(cabana, ck) in folio.Cabanas">
                                                    <span>{{ cabana.Details }}</span><br>
                                                    <span class="small">{{ cabana.StartDate | rsnicedate }}, {{ cabana.StartDate | rstime }} - {{ cabana.FinishDate | rstime }}. &pound;{{ cabana.Amount }}</span>
                                                </li>
                                            </ul>
                                            <ul v-if="folio.extras" class="enhance-item-list">
                                                <li  v-for="extra, k in folio.extras.specialServices.PMSFolioSpecialService" :key="k">
                                                    <span v-if="extra.Qty != 0">{{ extra.Qty }} x </span>
                                                    {{ extra.PMSSpecialService.PMSSpecialServiceName }} 
                                                    <span v-if="extra.TotalWithSurcharges != '0.00'">&pound;{{extra.TotalWithSurcharges}}</span>
                                                </li>
                                            </ul>
                                            <ul v-else class="enhance-item-list">
                                                <li>No finishing touches added</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="cat === 'Gift Cards x'"> <!-- Hide for now -->
                            <h3>{{ cat }}</h3>
                            <div class="booking-list">
                                <div v-for="item in folioItem" class="booking-list-item">
                                    <div class="col-md-6">
                                        <h5 class="card-title">{{ item.Details }}</h5>
                                        <!-- <h6>{{ item.SubItems.SubItem.Details }} - &pound;{{ item.SubItems.SubItem.Amount }}</h6> -->
                                        <p class="ffs small mb-0">Recipient: {{ item.CustomerName }}</p>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <h5 class="cart-price">&pound;{{item.FolioTotal}}</h5>
                                        <a class="cartSummaryRemove" @click.prevent="removeGiftCard(item.FolioId, item.FolioItemId)">Remove</a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="cat === 'Treatments'"> <!-- Treatments -->
                            <h3>{{ cat }}</h3>
                            <div class="booking-list">
                                <div v-for="item in folioItem" class="booking-list-item">
                                    <div class="col-md-6">
                                        <h5 class="card-title">{{ item.Details }}</h5>
                                        <h6>{{ item.StartDate | rsdatetimeshort }} - &pound;{{ item.Amount }}</h6>
                                        <a class="cartSummaryRemove" @click.prevent="removeClass(item)">Remove</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Classes -->
                <div v-if="classBookings" class="mt-5">
                    <h3 class="mb-3">Class Bookings</h3>
                    <div v-if="classBookings" class="table-responsive">
                        <table class="table table-members">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Item</th>
                                    <th>Time</th>
                                    <th>Additional Cost</th>
                                    <th>Changed your mind?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(day) in classBookings">
                                    <td>{{ day.Details }}</td>
                                    <td>{{ day.StartDate | rsdatetimeshort }}</td>
                                    <td>
                                        <span v-if="day.Amount == '0.00'">--</span>
                                        <span v-else>&pound;{{ day.Amount }}</span>
                                    </td>
                                    <td>
                                        <button @click.prevent="removeClass(day)" class="btn btn-secondary btn-sm mb-0">
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="cart.FolioBalance" class="cart-page-actions mt-5 mb-5">
                    <div v-if="cart.inclusivesBooked">
                        <div v-if="system.isLoggedIn">
                            <div v-if="insuranceTotal != 0">
                                <div v-if="!insuranceAdded" class="mb-3">
                                    <a @click.prevent="insurancePopup()" class="btn btn-secondary btn-wide">Pay now</a>
                                </div>
                                <div v-else class="mb-3">
                                    <router-link to="/book/checkout" class="btn btn-secondary btn-wide">Pay Now</router-link>
                                </div>
                            </div>
                            <div v-else>
                                <router-link to="/book/checkout" class="btn btn-secondary btn-wide">Pay Now</router-link>
                            </div>
                        </div>
                        <div v-else class="d-md-flex">
                            <button type="button" class="btn btn-secondary flex-fill mr-2" @click.prevent="showLogin('login')">
                                Login to account
                            </button>
                            <button type="button" class="btn btn-secondary flex-fill" @click.prevent="showLogin('register')">
                                Checkout
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <p class="text-danger">Your selected package includes treatments, but you haven't booked a time. Please select your time before proceeding.</p>
                        <button @click.prevent="ammendBooking()" class="btn btn-primary">Book my treatments</button>
                    </div>
                </div>

                <div class="row justify-content-center mt-4">
                    <div class="col-lg-6">
                        <div class="gc-box">
                            <h5>Redeeming a Gift Card or E-voucher?</h5>
                            <p>Did you know, all of our gift vouchers hold a monetary value and can be redeemed against any package. Simply select your chosen package, then enter your voucher code at checkout to redeem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="booking-app-sideout login-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content">
                        <div class="package-header">
                            <a @click.prevent="closeSideout()" class="prevNextArrow arrowLeft">&nbsp;</a>
                        </div>
                        <div class="package-content">
                            <div class="room-full-description">
                                <login-register :system="system" :cart="cart" :type="type"></login-register>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Flex modal -->
        <div id="flex-modal" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        &nbsp;
                    </div>
                    <div class="modal-body">
                        <h5 class="brandHeading text-center mb-4">Flex your booking for &pound;{{ insuranceTotal }}</h5>
                        <div class="mb-3 text-center">
                            <strong>Life is full of surprises, so why not protect your reservation by purchasing our recommended ‘Flex’ enhancement?</strong> Offering the peace of mind to change your booking any time, or receive a full refund until 48 hours before arrival, with no questions asked or additional fees required.
                        </div>
                        <div class="mb-3 text-center">
                            Please note, ‘Flex’ can only be purchased at the time of booking and is non-refundable.To view the full terms and conditions surrounding ‘Flex’ and how this compares to our standard terms and conditions, please click <a :href="'/' + locUrl + '/terms-conditions'" target="_blank">here</a>.
                        </div>
                        <div class="d-flex">
                            <button data-dismiss="modal" @click.prevent="addInsurance()" class="btn btn-primary flex-fill mr-2">Yes please</button>
                            <button data-dismiss="modal" @click.prevent="moveToCheckout()" class="btn btn-primary flex-fill ml-2">No thanks</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Guest names modal -->
        <div id="guest-names-modal" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h5 class="brandHeading text-center mb-4">It's all about you</h5>
                        <div class="mb-3 text-center">
                            Looking to speed up the checking-in process, whilst also banking some additional moments in the spa? Then let us know your guest names below, enabling us to create your individual itineraries in advance.
                        </div>
                        <form v-if="cart.WebFolioItem" @submit.prevent="addGuestNames">
                            <div v-for="folio, fk in cart.WebFolioItem" :key="fk" class="mb-3"> <!-- folio -->
                                <div v-if="guestNamesForm[folio.FolioId]">
                                    <h4>{{ folio.GuestNames.type }} {{ fk+1 }}</h4>
                                    <div v-for="guest, gk in folio.GuestNames.guests" :key="gk" class="form-row">
                                        <div v-if="guest" class="form-group col">
                                            {{ guest }}
                                        </div>
                                        <div v-else class="form-group col">
                                            <input v-if="folio.GuestNames.type == 'Room'" v-model="guestNamesForm[folio.FolioId].guests[gk]" type="text" class="form-control" :id="'f' + folio.FolioId + '-' + gk" :placeholder="'Guest ' + (+gk+1) + ' Full name'" required>
                                            <input v-else v-model="guestNamesForm[folio.FolioId].guests[gk]" type="text" class="form-control" :id="'f' + folio.FolioId + '-' + gk" :placeholder="'Guest ' + (+fk+1) + ' Full name'" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-primary btn-block" type="submit">Add to booking</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            system: Object,
            cart: Object,
            venue: String
        },
        components: {

        },
        /**
         * The components data
         */
        data() {
            return {
                step: 4,
                next: 'checkout',
                webFolio: null,
                folioItems: null,
                extras: null,
                balance: 0.00,
                items: null,
                noOfItems: '',
                extras: null,
                processing: null,
                setCheckout: null,
                isLoading: false,
                removing: null,
                forwardUrl: null,
                rateDetails: null,
                showForgot: false,
                incCount: 0,
                incBooked: 0,
                type: 'login',
                removingInsurance: false,
                locUrl: null,
                guestNamesForm: {},
                guestsAdded: false,
                appliedPromo: null,
                promoCode: null,
                invalidPromo: null,
                paymentError: this.$route.query.paymentError,
                promoProcessing: false,
                promotionApplied: false
            }
        },

        mounted() {
            Bus.$emit('RefreshSystem')
            Bus.$on('SwitchLogin', (type) => {
                this.type = null
                this.type = type
            })
            if(window.sessionStorage.getItem('guestsAdded')) {
                this.guestsAdded = true
            }
            if(this.cart.WebFolioItem && this.cart.WebFolioItem[0].Category == 'Hotel') {
                this.locUrl = this.$options.filters.venueSlug(this.cart.WebFolioItem[0].Location)
            }

            if(this.cart.WebFolioItem) {
                this.doAnalyticsEvent('view_cart')
            }
        },

        watch: {
            cart: {
                handler(newVal, oldVal) {
                    if(!newVal.WebFolioItem) {
                        var firstDate = JSON.parse(window.localStorage.getItem('rsbooking'))
                        
                        if(firstDate && firstDate[this.system.WebFolioId]) {
                            var start = firstDate[this.system.WebFolioId]
                            if(start) {
                                this.locUrl = start.booking.venue
                            }
                            if(start.booking.type == 'stay') {
                                this.$router.push('/book/booking/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.nights + '/' + start.booking.guests)
                            } else {
                                this.$router.push('/book/booking/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.guests)
                            }
                        } else {
                            this.$router.push('/book')
                        }
                    } else {
                        if(newVal.WebFolioItem[0].Category == 'Hotel') {
                            this.locUrl = this.$options.filters.venueSlug(newVal.WebFolioItem[0].Location)
                        }
                        this.doAnalyticsEvent('view_cart')
                    }
                }
            },
            system: {
                handler(newVal, oldVal) {
                    if(newVal.isLoggedIn) {
                        if(!this.guestsAdded) {
                            this.showGuestNames()
                        }
                    }
                }
            }
        },

        computed: {
            folioByCat() {
                var cats = _.groupBy(this.cart.WebFolioItem, 'Category');
                if(cats['Hotel']) {
                    cats['Your Visit'] = cats['Hotel'];
                    delete cats['Hotel']
                }
                if(cats['Spa']) {
                    cats['Treatments'] = cats['Spa']
                    delete cats['Spa']
                }
                if(cats['Retail']) {
                    cats['Gift Cards'] = cats['Retail']
                    delete cats['Retail']
                }
                return cats;
            },
            classBookings() {
                var cats = _.groupBy(this.cart.WebFolioItem, 'Category');
                if(cats['Classes']) {
                    return cats.Classes;
                }
            },
            insuranceTotal() {
                var self = this

                var filtered = _.filter(self.cart.WebFolioItem, function(f) { 
                    return (f.Category == 'Hotel' && f.Insurance)
                })
                if(filtered) {
                    var total = _.sumBy(filtered, function(folio) {
                        return folio.Insurance.Price * folio.Insurance.PMSSpecialServiceQty
                    })
                    return total
                } else {
                    return null
                }
            },
            insuranceAdded() {
                var self = this

                var folioLength = 0
                var insuranceCount = 0
                if(self.cart.WebFolioItem) {
                    _.each(self.cart.WebFolioItem, function(folio) {
                        if(folio.Category == 'Hotel' && folio.Insurance) {
                            folioLength++
                            if(folio.extras) {
                                _.each(folio.extras.specialServices.PMSFolioSpecialService, function(extra) {
                                    if(extra.PMSSpecialService.PMSSpecialServiceName.includes('Flex')) {
                                        insuranceCount++
                                    }
                                })
                            }
                        }
                    })
                    
                    if(folioLength > 0) {
                        if(folioLength == insuranceCount) {
                            Bus.$emit('hideLoading')
                            return true
                        } else {
                            if(self.removingInsurance) {
                                Bus.$emit('hideLoading')
                                self.removingInsurance = false
                            }
                            return false
                        }
                    }
                } else {
                    return false
                }
            }
        },

        /**
         * Methods
         */
        methods: {
            applyPromo() {
                var self = this;
                self.promoProcessing = true;
                self.invalidPromo = null
                this.appliedPromo = this.promoCode
                Bus.$emit('showLoading');

                var promoList = Object.values(this.system.discount_settings);

                const matchingPromoCodes = promoList.filter(item => item.discount_code == this.appliedPromo);

                if(matchingPromoCodes) {
                    var promoError = null;

                    _.each(matchingPromoCodes, function(promo) {
                        // Loop through the PMS folios and apply the SS to each.
                        let promises = [];

                        _.each(self.cart.WebFolioItem, function(wf) {
                            if(wf.Category == 'Hotel') {
                                if(wf.promotionApplied) {
                                    promoError = 'Promotion already applied.'
                                } else if(promo.venue != self.venue && promo.venue !== 'all') {
                                    promoError = 'Promotion not valid for this venue.'
                                } else if(
                                    !_.includes(
                                        promo.validMonths, 
                                        moment(wf.StartDate.slice(0, -6)).format('MM')
                                    ) &&
                                    !_.includes(
                                        promo.validDates, 
                                        moment(wf.StartDate.slice(0, -6)).format('DD/MM')
                                    )
                                ) {
                                    promoError = 'Promotion not valid for this date.'
                                } else { // good to go
                                    // includes
                                    if(promo.include) {
                                        if(_.includes(promo.include, wf.RateType)) {
                                            if(wf.RoomType == 'HTWI' || wf.RoomType == 'EFEE') {
                                                var ss_name = promo.twi_name
                                            } else if(wf.Location == 'Hoar Cross Hall') {
                                                var ss_name = promo.stay_name
                                            } else {
                                                var ss_name = promo.name
                                            }

                                            var i = 0;

                                            while(i < wf.Nights) {
                                                var request = {
                                                    method: 'AddPMSSpecialService',
                                                    data: {
                                                        SessionId: self.system.SessionId,
                                                        WebFolioId: self.system.WebFolioId,
                                                        PMSFolioId: wf.FolioId,
                                                        PMSSpecialServiceName: ss_name,
                                                        PMSSpecialServiceQty: 1,
                                                        PMSSpecialServiceDate: moment(wf.StartDate.slice(0, -6)).add(i, 'days').format('YYYY-MM-DD'),
                                                        PMSSpecialServiceNote: self.promoCode
                                                    }
                                                }
                                                promises.push(axios.post('/rs/request', request)
                                                    .then(response=> {
                                                        if(response.data.Result.value === 'FAIL') {
                                                            promoError = 'Error encountered. Try again.'
                                                        } else {
                                                            promoError = null;
                                                            self.promotionApplied = true;
                                                            var promotion_applied = {};
                                                            promotion_applied[self.system.WebFolioId] = self.promoCode;
                                                            window.localStorage.setItem('promotion_applied', JSON.stringify(promotion_applied));
                                                        }
                                                    })
                                                )
                                                i++
                                            }
                                        } else {
                                            promoError = 'Promotion not valid for this package.'
                                        }
                                    } else {
                                        var i = 0
                                        while(i < wf.Nights) {
                                            var request = {
                                                method: 'AddPMSSpecialService',
                                                data: {
                                                    SessionId: self.system.SessionId,
                                                    WebFolioId: self.system.WebFolioId,
                                                    PMSFolioId: wf.FolioId,
                                                    PMSSpecialServiceName: promo.name,
                                                    PMSSpecialServiceQty: 1,
                                                    PMSSpecialServiceDate: moment(wf.StartDate.slice(0, -6)).add(i, 'days').format('YYYY-MM-DD'),
                                                    PMSSpecialServiceNote: self.promoCode
                                                }
                                            }
                                            promises.push(axios.post('/rs/request', request) 
                                                .then(response=> {
                                                    if(response.data.Result.value === 'FAIL') {
                                                        promoError = 'Error encountered. Try again.'
                                                    } else {
                                                        promoError = null;
                                                        self.promotionApplied = true;
                                                        var promotion_applied = {};
                                                        promotion_applied[self.system.WebFolioId] = self.promoCode;
                                                        window.localStorage.setItem('promotion_applied', JSON.stringify(promotion_applied));
                                                    }
                                                })
                                            )
                                            i++
                                        }
                                    }
                                }
                            }
                        })
                        // Process list
                        if(promises.length) {
                            Promise.all(promises).then(response=> {
                                Bus.$emit('UpdateCart');
                                Bus.$emit('hideLoading')
                            })
                        } else {
                            Bus.$emit('hideLoading')
                        }
                    });
                    self.invalidPromo = promoError;
                } else {
                    self.invalidPromo = 'Promo code not recognised.'
                    Bus.$emit('hideLoading')
                }

                setTimeout(function() {
                    self.promoProcessing = false;
                }, 2000)
            },
            clearPromo() {
                this.promoCode = null
                this.appliedPromo = null
            },
            insurancePopup() {
                $('#flex-modal').modal('show')
            },
            moveToCheckout() {
                $('.modal').modal('hide')
                this.$router.push('/book/checkout')
            },
            showGuestNames() {
                this.generateGuestForm()
            },
            generateGuestForm() {
                var self = this
                var showForm = false
                _.each(self.cart.WebFolioItem, function(folio, fk) {
                    
                    if(folio.Category != 'Retail') {
                        self.guestNamesForm[folio.FolioId] = {}
                        self.guestNamesForm[folio.FolioId]['date'] = folio.StartDate.slice(0, -6)
                        self.guestNamesForm[folio.FolioId]['guests'] = {}
                        _.each(folio.GuestNames.guests, function(guest, gk) {
                            if(!guest) {
                                self.guestNamesForm[folio.FolioId]['guests'][gk] = null
                                showForm = true
                            }
                        })
                    }
                })
                
                // Still need some names... Show the form
                if(showForm) {
                    $('#guest-names-modal').modal('show')
                }
            },
            addGuestNames() {
                var self = this
                Bus.$emit('showLoading')
                var guestnames = JSON.parse(window.localStorage.getItem('guestnames'))
                
                let promises = [];
                _.each(self.guestNamesForm, function(folio, fk) {
                    if(!guestnames[self.system.WebFolioId][fk]) {
                        guestnames[self.system.WebFolioId][fk] = {}
                    }
                    _.each(folio.guests, function(guest, gk) {
                        var request = {
                            method: 'AddPMSSpecialService',
                            data: {
                                SessionId: self.system.SessionId,
                                WebFolioId: self.system.WebFolioId,
                                PMSFolioId: fk,
                                PMSSpecialServiceName: 'Guest names',
                                PMSSpecialServiceQty: 1,
                                PMSSpecialServiceDate: folio.date,
                                PMSSpecialServiceNote: guest
                            }
                        }
                        promises.push(axios.post('/rs/request', request)
                            .then(response=> {
                                guestnames[self.system.WebFolioId][fk][response.data.PMSFolioSpecialServiceId] = guest
                            })
                        )
                    })
                });

                Promise.all(promises).then(response=> {
                    window.localStorage.setItem('guestnames', JSON.stringify(guestnames))
                    Bus.$emit('UpdateCart');
                    Bus.$emit('hideLoading')
                    $('#guest-names-modal').modal('hide')
                });

                window.sessionStorage.setItem('guestsAdded', true);
                
                self.guestsAdded = true
            },
            removeClass(day) {
                Bus.$emit('showLoading')
                this.removing = true;
                var request = {
                    method: 'CancelSpaService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        SpaFolioId: day.FolioId,
                        SpaFolioItemId: day.FolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.removing = false
                            
                            Bus.$emit('UpdateCart');
                            Bus.$emit('hideLoading')
                        }
                    });
            },
            
            ammendBooking() {
                var firstDate = JSON.parse(window.localStorage.getItem('rsbooking'))
                        
                if(firstDate && firstDate[this.system.WebFolioId]) {
                    var start = firstDate[this.system.WebFolioId]
                    if(start.booking.type == 'stay') {
                        this.$router.push('/book/enhance/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.nights + '/' + start.booking.guests)
                    } else if(start.booking.type == 'hotel-stay') {
                        this.$router.push('/book/enhance/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.nights + '/' + start.booking.guests)
                    } else {
                        this.$router.push('/book/enhance/' + start.booking.venue + '/' + start.booking.type + '/' + start.booking.arrive + '/' + start.booking.guests)
                    }
                } else {
                    this.$router.push('/book')
                }
            },

            showLogin(type) {
                this.type = null
                this.doAnalyticsEvent('begin_checkout')
                $('.login-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');
                this.type = type
            },
            closeSideout() {
                this.type = null
                $('.login-selector').removeClass('is-active');
                $('html').toggleClass('no-scroll');
            },

            addInsurance() {
                Bus.$emit('showLoading')
                var self = this
                var qty
                _.each(self.cart.WebFolioItem, function(folio) {
                    if(folio.Category == 'Hotel' && folio.Insurance.Price) {
                        self.addInsuranceCall(folio)
                    }
                })
            },

            removeInsurance() {
                Bus.$emit('showLoading')
                this.removingInsurance = true
                var self = this
                _.each(self.cart.WebFolioItem, function(folio) {
                    if(folio.Category == 'Hotel') {
                        if(folio.extras) {
                            _.each(folio.extras.specialServices.PMSFolioSpecialService, function(extra) {
                                if(extra.PMSSpecialService.PMSSpecialServiceName.includes('Flex')) {
                                    self.removeExtras(extra.PMSFolioSpecialServiceId, folio.FolioId)
                                }
                            })
                        }
                    }
                })
            },

            addInsuranceCall(folio) {
                
                var request = {
                    method: 'AddPMSSpecialService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        PMSFolioId: folio.FolioId,
                        PMSSpecialServiceName: folio.Insurance.PMSSpecialServiceName,
                        PMSSpecialServiceQty: folio.Insurance.PMSSpecialServiceQty,
                        PMSSpecialServiceDate: folio.StartDate.slice(0, -6),
                        PMSSpecialServiceNote: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            dataLayer.push({
                                'event': 'add_to_cart',
                                'venue': folio.Venue,
                                'ecommerce': {
                                    'currencyCode': 'GBP',
                                    'add': {                                // 'add' actionFieldObject measures.
                                        'products': [{  
                                            'name': folio.Insurance.PMSSpecialServiceName,
                                            'id': folio.Insurance.PMSItemId,
                                            'price': folio.Insurance.Price,
                                            'brand': folio.Venue,
                                            'category': "Extras",
                                            'quantity': folio.Insurance.PMSSpecialServiceQty
                                        }]
                                    }
                                }
                            });

                            this.$emitAnalytics(
                                'add_to_cart',
                                {
                                    'event': 'add_to_cart',
                                    'venue': folio.Venue,
                                    'ecommerce': {
                                        'currencyCode': 'GBP',
                                        'add': { 
                                            'products': [{ 
                                                'name': folio.Insurance.PMSSpecialServiceName,
                                                'id': folio.Insurance.PMSItemId,
                                                'price': folio.Insurance.Price,
                                                'brand': folio.Venue,
                                                'category': "Extras",
                                                'quantity': folio.Insurance.PMSSpecialServiceQty
                                            }]
                                        }
                                    }
                                },
                                this.system.CustomerId,
                                this.system.WebFolioId
                            );
                                
                            Bus.$emit('UpdateCart');
                        } else {
                            console.log(response.data)
                        }
                    });
            },

            removeExtras(id, folio) {
                this.removingExtra = id;
                var request = {
                    method: 'RemovePMSSpecialService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        PMSFolioId: folio,
                        PMSFolioSpecialServiceId: id
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.removingExtra = null
                            Bus.$emit('UpdateCart');
                        }
                    });
            },

            doAnalyticsEvent(event) {
                var self = this;
                var products = []; // array of products for data layer
                
                // Analytics time
                _.each(self.cart.WebFolioItem, function(item, key) {
                    // Add the main folio item
                    var productName;
                    var itemCategory;
                    var itemId;
                    var extraTotal = 0;

                    if(item.Category == "Retail") {
                        productName = item.Details
                        itemId = item.Details
                        itemCategory = 'Gift Cards'
                    } else {
                        productName = item.RateDetails
                        itemId = item.RateType
                        if(item.Location == 'Hoar Cross Hall') {
                            itemCategory = 'Stays'
                        } else {
                            itemCategory = 'Spa Days'
                        }
                    }
                    if(item.extras) {
                        extraTotal = item.extras.total;
                    }
                    products.push({
                        item_id: itemId,
                        item_name: productName,
                        price: (item.Amount - extraTotal),
                        item_brand: item.Location,
                        item_category: itemCategory,
                        item_category2:  item.Details,
                        quantity: 1 
                    });

                    if(_.has(item, 'extras')) {
                        _.each(item.extras.specialServices.PMSFolioSpecialService, function(ss, key) {
                            products.push({
                                item_id: ss.PMSFolioSpecialServiceId,
                                item_name: ss.PMSSpecialService.PMSSpecialServiceName,
                                price: (ss.TotalWithSurcharges / ss.Qty),
                                item_brand: item.Location,
                                item_category: 'Extras',
                                quantity: ss.Qty,
                            })
                        })
                    }
                    
                    if(_.has(item, 'Treatments')) {
                        _.each(item.Treatments, function(treatment, key) {
                            products.push({
                                item_id: treatment.FolioItemId,
                                item_name: treatment.Details,
                                price: treatment.Amount,
                                item_brand: treatment.Location,
                                item_category: 'Treatments',
                                quantity: 1,
                            })
                        })
                    }
                });

                dataLayer.push({
                    event: event,
                    ecommerce: {
                        currency: "GBP",
                        value: self.cart.FolioBalance,
                        items: products
                    },
                });

                this.$emitAnalytics(
                    event, 
                    {
                        event: event,
                        ecommerce: {
                            currency: "GBP",
                            value: self.cart.FolioBalance,
                            items: products
                        },
                    },
                    self.system.CustomerId,
                    self.system.WebFolioId
                );
            },

            pay() {
                this.$router.push('/book/checkout')
            },
            
        }
    }
</script>

<!-- Using the `scoped` attribute -->
<style lang="scss">
    .cart-page-summary {
        padding: 20px;
        background-color: #f9f9f9;
        &__container {
            background-color: #fff;
            padding: 20px;
        }
        &__items {
            margin-bottom: 10px;
            span {
                margin-right: 10px;
                span {
                    font-size: 18px;
                    margin-left: 5px;
                    font-weight: bold;
                }
            }
        }
        .smallIcons {
            margin-top: 20px;
            .small-icon {
                width: 40px;
                height: auto;
                display: inline-block;
            }
        }
    }
</style>