<template>
    <div class="booking-stay">
        <div v-for="n in notify">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="booking-date-notice">
                        <span v-html="n.message"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <progress-indicator :step="step"></progress-indicator>

                <div class="booking-info">
                    <h1 v-if="type == 'stay'" class="brandHeading">Your Spa Stay</h1>
                    <h1 v-else class="brandHeading">Hotel Only</h1>
                    <div v-if="!roomsSelected()">
                        <div class="date-info">
                            <div class="date-info__box">
                                <div class="row">
                                    <div class="col-md-2 d-none d-md-block">
                                        <div class="date-info__btn" v-if="configurationEditable || !configurationSelected">
                                            <a @click.prevent="prevDay()" class="prevNextArrow arrowLeft"></a>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8"> 
                                        <div class="date-info__label">
                                            Date
                                        </div>
                                        <div class="date-info__date">
                                            {{ arrive | nicedate }} 
                                            
                                            <button @click="dateSideout()" :disabled="configurationSelected" class="date-info__date__btn float-right" v-if="!configurationSelected">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-2 d-none d-md-block">
                                        <div class="date-info__btn" v-if="configurationEditable || !configurationSelected">
                                            <a @click.prevent="nextDay()" class="prevNextArrow arrowRight"></a>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div class="selector-boxes mb-4">
                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <div class="selector-box">
                                        <div class="selector-box__label">
                                            Nights
                                        </div>

                                        <div class="row">
                                            <div class="col-3">
                                                <button @click="decNights" v-if="configurationEditable || !configurationSelected" class="selector-box__btn">-</button>
                                            </div>
                                            <div class="col-6">
                                                <div class="selector-box__amount">{{ nights }}</div>
                                            </div>
                                            <div class="col-3">
                                                <button @click="incNights" v-if="configurationEditable || !configurationSelected" class="selector-box__btn float-right">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="dropdown-box">
                                        <div class="dropdown-box__label">
                                            Rooms / Guests
                                        </div>
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="dropdown-box__amount">
                                                    {{ rooms.length }} rooms, {{ totalGuests }} guests
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <button @click="toggleRoomDropdown()" class="dropdown-box__btn float-right">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="dropdown-box__dropdown" v-if="showRoomDropdown">
                                            <div class="row">
                                                <div class="col-6">
                                                    <strong class="dropdown-box__label">Room 
                                                      
                                                    </strong>
                                                </div>
                                                <div class="col-6">
                                                    <strong class="dropdown-box__label">Guests</strong>
                                                </div>
                                            </div>
                                            <div class="row mb-1" v-for="(room, index) in rooms" :key="index">
                                                <div class="col-5">
                                                    <div class="dropdown-box__room">
                                                        Room {{ index + 1}}
                                                    </div>  
                                                </div>
                                                <div class="col-5">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <button @click="decGuests(index)"  v-if="configurationEditable || !room.packageSelected" class="selector-box__btn">-</button>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="selector-box__amount">{{ room.guests }}</div>
                                                        </div>
                                                        <div class="col-3">
                                                            <button @click="incGuests(index)"  v-if="configurationEditable || !room.packageSelected" class="selector-box__btn float-right">+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <button type="button" class="btn btn-danger" @click.prevent="removeRoom(index)" v-if="index !== 0 && !room.packageSelected">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                                        <span class="sr-only sr-only-focusable">Remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <button class="btn btn-secondary mt-2 mb-0" @click="toggleRoomDropdown()">Complete</button>
                                                    <button class="btn btn-primary mt-2 mb-0" @click="addRoom()" v-if="totalGuests < 6">Add Additional Room</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button @click.prevent="confirmRoomConfiguration()" v-if="!configurationSelected" class="btn btn-secondary mt-4">Next Step</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div v-if="upsellPrice && !showCallRes" class="roomUpsell">
                        <div v-if="!cart.WebFolioItem">
                            <p>Stay a little longer, adding an extra night from only &pound;{{ upsellPrice | currency }}?</p>
                            <a @click.prevent="yesPlease">Yes please!</a>
                        </div>
                    </div>

                    <div v-else-if="type == 'hotel-stay'" class="roomUpsell">
                        <div>
                            <p> Fancy a relaxing Spa Stay? Discover our <a :href="'/book/booking/hoar-cross-hall/stay/'+arrive+'/'+nights+'/'+guests">Spa Stay Experiences</a></p>
                        </div>
                    </div>

                    <div v-if="roomsSelected()" class="row justify-content-center mb-3">
                        <div class="col-md-7">
                            <h5 class="mb-2">Your booking has now been added to the cart.</h5>
                            
                            <div class="d-md-flex">
                                <button @click.prevent="nextStep()" class="btn btn-secondary flex-md-fill ml-md-1">Next step</button>
                                <button @click.prevent="startOver()" class="btn btn-primary flex-md-fill ml-md-1">Start Over</button>
                            </div>
                        </div>
                    </div>

                    <div v-else class="small mb-4">
                        <p class="mb-1">Please note, there is a maximum of 4 guests per room.</p>
                    </div>

                </div>

                <div v-if="configurationSelected && !roomsSelected()">

                    <ul class="room-tabs nav nav-tabs" id="roomsTabs" role="tablist" v-if="!configurationEditable">
                        <li class="nav-item" role="presentation" v-for="(room, index) in rooms">
                            <button class="nav-link" :id="'room-' + index + '-tab'" :class="{ active: activeRoom === index }" data-toggle="tab" :data-target="'room-' + index + '-tab'" type="button" role="tab" :aria-controls="'room-' + index + '-tab'" aria-selected="true" @click="setActiveRoom(index)">
                                Room {{ index + 1 }} 
                                
                                <span class="badge badge-light" v-if="room.packageSelected">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </span>
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content" id="roomTabsContent">
                        <div class="tab-pane fade active show" v-for="(room, index) in rooms" :id="'room-' + index" v-if="activeRoom === index && !loadingPackages" role="tabpanel" :aria-labelledby="'room-' + index">
                            <div class="package-list">
                                <h4 class="brandHeading text-center mb-4" v-if="!configurationEditable && rooms[index].rates !== null && rooms[index].rates.length > 0 && !room.packageSelected">Select your experience for Room {{  index + 1 }}</h4>
                                <h4 class="brandHeading text-center mb-4"  v-if="!configurationEditable && rooms[index].rates !== null && rooms[index].rates.length > 0 && room.packageSelected">Your selected experience</h4>
                                <div v-if="rooms[index].rates !== null && rooms[index].rates.length > 0" class="row">    
                                    <div v-for="(rate, key) in rooms[index].rates" v-bind:key="key" class="col-12" v-if="room.packageSelected === false || getSelectedPackage() === rate.PMSRateTypeId">
                                        <div class="package-container" >
                                            <div class="package-image" :style="'background-image:url('+rate.PMSRateTypeImage+');'">&nbsp;</div>
                                            <div class="package-type">
                                                <h3>{{ rate.PMSRateTypeDesc }}</h3>
                                                <div class="fromPrice">
                                                    <span v-if="rate.fromPrice">from <span :class="{'text-strike mb-0': rate.fromDiscount}">&pound;{{ ((+rate.fromPrice.TotalStayRate / rooms[activeRoom].guests) / nights) | currency }}</span> 
                                                    <span v-if="rate.fromDiscount">&pound;{{ ((+rate.fromDiscount /rooms[activeRoom].guests) / nights) | currency }}</span><span class="d-none d-lg-block">(per person per night)</span><span class="d-lg-none">(pp per night)</span></span>
                                                </div>
                                                <div class="totalPrice">
                                                    <span v-if="rate.fromPrice">from <span :class="{'text-strike mb-0': rate.fromDiscount}">&pound;{{ +rate.fromPrice.TotalStayRate  | currency }}</span> 
                                                    <span v-if="rate.fromDiscount">&pound;{{ +rate.fromDiscount | currency }}</span><span class="d-none d-lg-block">({{ rooms[activeRoom].guests }} guests, {{ nights }} nights)</span><span class="d-lg-none">total cost</span></span>
                                                </div>

                                                <button @click.prevent="removeRoomPackage(rate, key)" v-if="getSelectedPackage() == rate.PMSRateTypeId" class="btn btn-secondary d-lg-none mb-0 align-self-end">
                                                    Remove
                                                </button>
                                                <button @click.prevent="selectRoom(rate)" v-if="getSelectedPackage() != rate.PMSRateTypeId" class="btn btn-secondary d-lg-none mb-0 align-self-end">
                                                    Book
                                                </button>
                                                <button @click.prevent="viewMoreDetails(rate)"   v-if="getSelectedPackage() !== rate.PMSRateTypeId" class="btn btn-outline-primary d-lg-none mb-0 align-self-end">View more details</button>
                                            </div>
                                            <div class="package-description">
                                                {{ rate.PMSRateTypeCustDesc.substring(0,180) }}...

                                                <div class="d-xl-flex package-buttons">
                                                    <button @click.prevent="selectRoom(rate)" v-if="getSelectedPackage() !== rate.PMSRateTypeId" class="btn btn-secondary flex-xl-fill mr-md-1">Book</button>
                                                    <button @click.prevent="removeRoomPackage(rate)" v-if="getSelectedPackage() === rate.PMSRateTypeId" class="btn btn-secondary flex-xl-fill mr-md-1">Remove</button>
                                                    <button @click.prevent="viewMoreDetails(rate)" v-if="getSelectedPackage() !== rate.PMSRateTypeId" class="btn btn-outline-primary flex-xl-fill">View details</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="no-availability-message">
                                        <div v-if="loadingPackages" class="text-center">
                                            <h4>Please wait while we fetch all the experiences.</h4>
                                        </div>
                                        <div v-else class="text-center">
                                            <div class="info-box">
                                                <h4 v-if="rooms[activeRoom].guests == 1">Oops, we don't seem to have any online single room availability on your chosen date. Please call our Reservations Team on <a href="tel:01283 576522" target="_blank">01283 576522</a>, who will be able to help.</h4>
                                                <h4 v-if="rooms[activeRoom].guests > 1">Sorry, there is no availability on this date. Please choose another date.</h4>

                                                <div class="d-xl-flex">
                                                    <button class="btn btn-outline-primary flex-xl-fill" @click.prevent="dateSideout()">Select another date</button>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div v-if="rates" class="row justify-content-center">
                                    <div class="col-md-4 next-steps">
                                        <button v-if="!countRooms && showNextStep" @click.prevent="nextStep()" class="btn btn-secondary flex-md-fill ml-md-1">Next step</button>
                                    </div>
                                    <div class="col-md-9 mt-3">
                                        <div v-if="showCallRes" class="gc-box bg-blush">
                                            <h5>Fancy adding an extra night? No problem!</h5>
                                            <p>Please call reservations on 01283 576522.</p>
                                        </div>
                                        <div v-else class="gc-box">
                                            <h5>Redeeming a Gift Card or E-voucher?</h5>
                                            <p>Did you know, all of our gift vouchers hold a monetary value and can be redeemed against any experience. Simply select your chosen experience, then enter your voucher code at checkout to redeem.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade active show" v-if="loadingPackages">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="booking-app-sideout package-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    <div class="sideout-content rate-details" v-if="showRate && selectedRate">
                        <div class="package-header">
                            <a @click.prevent="togglePopout()" class="prevNextArrow arrowLeft">&nbsp;</a>
                        </div>
                        <div class="package-content">
                            <div v-if="selectedRate.PMSRateTypeImage" class="room-package-image" :style="'background-image:url('+selectedRate.PMSRateTypeImage+');'">&nbsp;</div>
                            <div v-else class="room-package-image">&nbsp;</div>
                            <div class="room-package-description">
                                <h4>{{ selectedRate.PMSRateTypeDesc }}</h4>
                                <div v-html="selectedRate.details.intro"></div>
                                <div v-html="selectedRate.details.details"></div>
                            </div>
                            <div class="package-cta">
                                <button @click.prevent="displayRooms" class="btn btn-secondary btn-block">Select room</button>
                            </div>
                        </div>
                    </div>

                    <div class="sideout-content" v-if="showRooms">
                        <div class="package-header">
                            <a @click.prevent="showRooms = false; togglePopout();" class="prevNextArrow arrowLeft">&nbsp;</a>&nbsp;
                        </div>
                        <div class="package-content">
                            <div class="d-flex justify-content-center" v-if="roomsLoading">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            <div v-for="room in selectedRate.rooms" class="room-feed" v-if="!roomsLoading">
                                <div class="room-image" :style="'background-image:url(/storage/' + room.roomDetails.img + ');'">&nbsp;</div>
                                <div class="room-content">
                                    <h4>{{ room.PMSRoomType.PMSRoomTypeDesc }}</h4>
                                    <div class="room-price">
                                        <span class="mr-1" :class="{advancedPrice: room.AdvancedBookingIncentive}">
                                            &pound;{{ (room.ppRate / nights) | currency }}
                                        </span>
                                        <span v-if="room.AdvancedBookingIncentive">
                                            &pound;{{ ( (room.ppRate - ((room.ppRate / 100)*room.AdvancedBookingIncentive)) / nights ) | currency }}
                                        </span> (per person per night)
                                    </div>
                                    <div class="room-total-price">
                                        <span class="mr-1" :class="{advancedPrice: room.AdvancedBookingIncentive}">
                                            &pound;{{ room.TotalStayRate | currency }}
                                        </span>
                                        <span v-if="room.AdvancedBookingIncentive">
                                            &pound;{{ (room.TotalStayRate - ((room.TotalStayRate / 100)*room.AdvancedBookingIncentive)) | currency }}
                                        </span> ({{ rooms[activeRoom].guests }} guests, {{ nights}} nights)
                                    </div>
                                    <button @click.prevent="getRoomDetails(room)" class="btn btn-primary btn-block mb-md-4 mb-2">View details</button>
                                    
                                    <div class="d-md-flex">
                                        <button @click.prevent="book(room, false)" class="btn btn-secondary flex-md-fill mr-md-1">Book Double</button>
                                        <button v-if="room.PMSRoomTypeId != 'CSNGL'" @click.prevent="book(room, true)" class="btn btn-secondary flex-md-fill ml-md-1">Book Twin</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="sideout-content" v-if="roomFull">
                        <div class="package-header">
                            <a @click.prevent="closeRoom()" class="prevNextArrow arrowLeft">&nbsp;</a>&nbsp;
                        </div>
                        <div class="package-content">
                            <div class="room-full-image" :style="'background-image:url(/storage/'+roomFull.roomDetails.img+');'">&nbsp;</div>
                            <div class="room-full-description">
                                <h4>{{ roomFull.PMSRoomType.PMSRoomTypeDesc }} 
                                    <span class="float-right" :class="{advancedPrice: roomFull.AdvancedBookingIncentive}">
                                        &pound;{{ (roomFull.ppRate / nights) | currency }}pp
                                    </span>
                                    <span v-if="roomFull.AdvancedBookingIncentive">
                                        &pound;{{ ( (roomFull.ppRate - ((roomFull.ppRate / 100)*roomFull.AdvancedBookingIncentive)) / nights ) | currency }}pp
                                    </span>
                                </h4>
                                <div v-html="roomFull.roomDetails.intro"></div>
                                <div v-html="roomFull.roomDetails.details"></div>
                                <div class="d-md-flex">
                                    <button @click.prevent="book(roomFull, false)" class="btn btn-secondary flex-md-fill mr-md-1">Book Double</button>
                                    <button v-if="roomFull.PMSRoomTypeId != 'CSNGL'" @click.prevent="book(roomFull, true)" class="btn btn-secondary flex-md-fill ml-md-1">Book Twin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-app-sideout date-selector">
            <div class="sideout-wrapper">
                <div class="sideout-container">
                    
                    <div class="sideout-content">
                        <div class="package-header">
                            <a @click.prevent="dateSideout()" class="prevNextArrow arrowLeft">&nbsp;</a>&nbsp;
                        </div>
                        <div class="package-content">
                            <div class="room-full-description text-center">
                                <h4 class="mb-4">{{ arrive | nicedate }}</h4>
                                <p class="mb-2">Please note, gift voucher codes are entered at checkout.</p>
                                <rate-calendar :system="system" :venue="venueName" :type="type" :mindate="mindate" :arrive="arrive" :promo="promoCode"></rate-calendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Group booking modal -->
        <div class="modal fade blush-modal" id="groupsModal" tabindex="-1" role="dialog" aria-labelledby="groupsModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header border-none">
                        <h5 class="modal-title w-100 text-center">Booking on behalf of a group?</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <p>Then look no further than our collection of group spa experiences, created for milestone celebrations, general get-togethers and corporate team away days.</p>
                        <p>Please call our Guest Experience Team on 01608 787986, who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests.</p>
                        <a href="/hoar-cross-hall/group-experiences" class="btn btn-primary btn-block mt-4 mb-0">View our group Spa collection</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade blush-modal" id="noRoomAvailability" tabindex="-1" role="dialog" aria-labelledby="noRoomAvailability" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header pb-0 border-none">
                        <h5 class="modal-title w-100 text-center">Sorry</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center pt-0">
                        <p>We have no rooms available for that amount of guests, try adding an additional room or try another selected another date.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- DBB Stay longer modal -->
        <div class="modal fade blush-modal" id="dbbModal" tabindex="-1" role="dialog" aria-labelledby="dbbModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header pb-0 border-none">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center pt-0">
                        <h6 class="mb-3">Fancy staying for longer?</h6>
                        <p>Check out our Multi-night Spa Stay experiences or call our Reservations Team on 01283 576522</p>
                        <a href="/hoar-cross-hall/overnight-experiences" class="btn btn-primary btn-block mt-4 mb-0">View Experiences</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade blush-modal" :id="'confirmNotification_' + notification.id" tabindex="-1" role="dialog" :aria-labelledby="'confirmNotification_' + notification.id" aria-hidden="true"  v-for="(notification, index) in confirmNotifications" :key="index">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h5 class="modal-title w-100">Important Notice</h5>
                    </div>
                    <div class="modal-body text-center" v-html="notification.message"></div>
                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-primary" @click="chooseAnotherDate(notification.id)">Select Another Date</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Accept</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { set } from 'lodash';
import ProgressIndicator from '../book/ProgressIndicator.vue';
    export default {
        props: {
            system: Object,
            cart: Object,
            type: String,
            venue: String,
            notifications: Array
        },

        components: {
            ProgressIndicator
        },

        data() {
            return {
                step: 1,
                mindate: moment().format('YYYY-MM-DD'),
                arrive: this.$route.params.arrive ? this.$route.params.arrive : moment().format('YYYY-MM-DD'),
                nights: this.$route.params.nights ? +this.$route.params.nights : 1,
                guests: this.$route.params.guests ? +this.$route.params.guests : 2,
                room: 1,
                rate: this.$route.params.rate ? this.$route.params.rate : null,
                venueName: this.$options.filters.venue(this.venue, this.type),
                promoCode: this.$route.query.promo ? this.$route.query.promo : null,
                invalidPromo: null,
                next: 'treatments',
                rates: null,
                rooms: [
                    { 
                        guests: 2,
                        rates: null,
                        packageSelected: false,
                        folioId: null
                    }
                ],
                availabilityStatus: null,
                selectedRate: null,
                isDisabled: null,
                bookDisabled: null,
                countRooms: 0,
                roomNumber: 1,
                showPackages: true,
                showRate: false,
                showRooms: false,
                packageFilter: null,
                roomFull: null,
                loadingPackages: false,
                upsellPrice: null,
                fromPriceCount: 0,
                roomsLoading: false,
                roomDetails: null,
                showRoomDropdown: false,
                activeRoom: 0,
                configurationSelected: false,
                configurationEditable: true
            }
        },

        beforeMount() {
            Bus.$on('changeDate', (date) => {
                this.arrive = date;
            })
        },
        
        mounted() {
            // Set nights to 2 if Friday(5) or Saturday(6)
            if(this.friSat) {
                this.nights = 2
            }

            // Set max nights if manually adjusted in URL
            if(this.type == 'stay' && this.nights > 3) {
                this.nights = 3
            }

            this.configurationSelected = false;

            if(this.$root.cart.FolioItems == 0) {
                this.configurationSelected = false;
                window.localStorage.removeItem('webfolio_rooms');
            } else {
                var existingRoomConfiguration = JSON.parse(window.localStorage.getItem('webfolio_rooms'));

                if(existingRoomConfiguration !== null && existingRoomConfiguration[this.system.WebFolioId] !== undefined) {
                    this.rooms = existingRoomConfiguration[this.system.WebFolioId];
                    this.configurationSelected = true;

                    if(this.rooms.length > 1) {
                        this.configurationEditable = false;
                    }
                }
            }


            this.fetchPMSRatesRequest(0);
            this.fetchRoomDetails();
            this.packageFilter = this.rate ? this.rate.split('-') : null
            Bus.$emit('showLoading')
        },

        computed: {
            totalGuests() {
                let totalGuests = 0;
                this.rooms.forEach(item => {
                    totalGuests += item.guests;
                });
                return totalGuests;
            },
            showCallRes() {
                return _.find(this.cart.WebFolioItem, function(r) { return r.RateType == 'RSPRINGSPAA' })
            },
            notify() {
                var self = this
                var notices = []
                _.each(self.notifications, function(o) {
                    if(o.location == self.venue) {
                        _.each(o.dates, function(d) {
                            if(_.includes(d, moment(self.arrive).format('YYYY-MM-DD'))) {
                                if(o.type == 'message') {
                                    notices.push(o)
                                }
                            }
                        })
                    }
                })
                return notices;
            },
            confirmNotifications() {
                var self = this
                var notices = []
                _.each(self.notifications, function(o) {
                    if(o.location == self.venue && o.stay_type == 'overnight') {
                        _.each(o.dates, function(d) {
                            if(_.includes(d, moment(self.arrive).format('YYYY-MM-DD'))) {
                               if(o.type == 'confirmation-popup') {
                                    notices.push(o);

                                    if(!self.countRooms) {
                                        setTimeout(function() {
                                            $('#confirmNotification_' + o.id).modal('show');
                                        }, 1000);
                                    }
                                }
                            }
                        })
                    }
                })
                return notices;
            },
            showNextStep() {
                var status = false
                if(this.cart.FolioItems) { 
                    _.each(this.cart.WebFolioItem, function(o) {
                        if(o.Category == 'Hotel' && o.Location == 'Hoar Cross Hall') {
                            status = true
                        }
                    })
                    return status
                } else {
                    return status
                }
            }
        },

        watch: {
            nights: function() {
                this.updateRoute()
            },
            arrive: function() {
                if(this.friSat) {
                    if(this.nights == 1) {
                        this.nights = 2
                    }
                }
                this.poolPopup(this.arrive);
                this.updateRoute()
            },
            room: function() {
                this.updateRoute()
            },
            rooms: {
                deep: true,
                handler (newVal) {
                    if(newVal !== undefined && this.configurationSelected) 
                    {
                        var webfolio_rooms = {};
                        webfolio_rooms[this.system.WebFolioId] = newVal;
                        window.localStorage.setItem('webfolio_rooms', JSON.stringify(webfolio_rooms));
                    }
                }
            },
            fromPriceCount: function() {
                var self = this
                if(this.fromPriceCount == this.rooms[this.activeRoom].rates.length) {
                    if(this.nights == 1 && this.fromPriceCount > 0) {
                        if(this.rooms[this.activeRoom].guests <= 2) {
                            if(this.type == 'stay') {
                                if(!_.some(self.rates, function(rate) { return rate.PMSRateTypeId == 'RMAGICBF' })) {
                                    this.upsell()
                                }
                            }
                        }
                    }
                }
            }
        },

        methods: {
            startOver() {
                Bus.$emit('RestartBooking', true)
            },
            chooseAnotherDate(id) {
                $('#confirmNotification_' + id).modal('hide');

                $('.date-selector').addClass('is-active');
                $('html').addClass('no-scroll');
            },
            confirmRoomConfiguration() {
                if(this.rooms.length > 1) {
                    this.configurationEditable = false;
                }

                this.configurationSelected = true;
                this.fetchPMSRatesRequest(0);
            },
            removeRoom(index) {
                if(this.activeRoom !== 0) {
                    this.activeRoom--;
                }

                this.rooms.splice(index, 1);
            },
            removeRoomPackage(rate, roomIndex) {
                let self = this;
                
                self.rooms[roomIndex].packageSelected = false;
                self.rooms[roomIndex].folioId = null;

                var webfolioItem = self.cart.WebFolioItem.find(item => item.RateType == rate.PMSRateTypeId);

                if(webfolioItem) {
                    Bus.$emit('RemoveItem', webfolioItem);
                }
            },
            roomsSelected() {
                let self = this;

                var roomsUnselected = self.rooms.findIndex(item => item.packageSelected === false);

                if(roomsUnselected === -1) {
                    return true;
                } else {
                    return false;
                }
            },
            getSelectedPackage() {
                let self = this;

                if(self.$root.cart.WebFolioItem === null) {
                    return false;
                }

                if(!self.rooms[self.activeRoom].packageSelected) {
                    return false;
                }

                var webfolioItem = self.$root.cart.WebFolioItem.find(item => item.FolioId == self.rooms[self.activeRoom].folioId);

                if(webfolioItem) {
                    return webfolioItem.RateType;
                }

                return null;
            },
            setActiveRoom(index) {
                this.loadingPackages = true;
                this.fetchPMSRatesRequest(index);
                this.activeRoom = index;
            },
            toggleRoomDropdown() {
                this.showRoomDropdown = !this.showRoomDropdown;
            },
            addRoom() {
                this.rooms.push({ 
                    guests: 1, 
                    rates: null,
                    packageSelected: false,
                    folioId: null
                });

                if(this.rooms.length > 1) {
                    this.configurationEditable = false;
                }
            },
            prevDay() {
                this.arrive = moment(this.arrive).subtract(1, 'days').format('YYYY-MM-DD');

                this.fetchPMSRatesRequest(0);
            },
            nextDay() {
                this.arrive = moment(this.arrive).add(1, 'days').format('YYYY-MM-DD');

                this.fetchPMSRatesRequest(0);
            },
            upsell() {
                var self = this

                var request = {
                    venue: 'Hoar Cross Hall',
                    start: self.arrive,
                    end: moment(self.arrive).add(2, 'days').format('YYYY-MM-DD'),
                }
                
                axios.post('/api/cached/calendar', request)
                    .then(response=> {
                        if(response.data.calendar.length) {
                            var day = response.data.calendar[0]
                            if(day) {
                                day.date = moment(day.date).format('YYYY-MM-DD')
                                if(day.rates) {
                                    // filter the result based on rooms available and availabile for this day of week
                                    var ratesForDay = _.filter(day.rates, function(r) {
                                        return (r.NumRoomsAvail && r.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N") && (r.MinLength == '2' && r.MaxOccupancy == 2)
                                    })
    
                                    var lowestRate = null
                                    if(ratesForDay.length) {
                                        lowestRate = _.minBy(ratesForDay, function(rate) {
                                            return +rate.DoubleRate
                                        })
                                    }

                                    if(lowestRate) {
                                        var cheapestStay = _.minBy(this.rooms[this.activeRoom].rates, function(rate) {
                                            return +rate.fromPrice.TotalStayRate
                                        })
                                        // hide if more expensive
                                        var oneNightPrice = (+cheapestStay.fromPrice.TotalStayRate / 2)
                                        var twoNightprice = (+lowestRate.DoubleRate)

                                        var difference = twoNightprice - oneNightPrice

                                        if(difference < oneNightPrice) {
                                            self.upsellPrice = Math.round(difference * 100) / 100
                                        }
                                    }
                                }
                            }
                        }
                    });
            },
            yesPlease() {
                this.nights = 2
            },
            bookAgain() {
                this.showPackages = true
            },

            displayRooms() {
                this.showRate = false
                this.showRooms = true
            },

            getRoomDetails(roomType) {
                this.showRooms = false
                this.roomFull = roomType
            },

            closeRoomFeed() {
                this.showRooms = false;
                this.showRate = true;
            },

            closeRoom() {
                this.roomFull = null;
                this.showRooms = true;
            },
            dateSideout() {
                $('.date-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');
            },
            decNights() {
                if(this.friSat) {
                    if(this.nights > 2) {
                        this.nights--
                    }
                } else {
                    if(this.nights > 1) {
                        this.nights--
                    }
                }
                    
            },
            incNights() {
                var maxNights = this.type == 'stay' ? 3 : 30
                if(this.nights < maxNights) {
                    this.nights++
                }
            },
            // Guests
            decGuests(index) {
                if(this.rooms[index].guests > 1) {
                    this.rooms[index].guests--
                }

                this.fetchPMSRatesRequest(index);
            },
            incGuests(index) {
                var totalRoomCount = this.rooms.reduce((total, room) => total + (typeof room.guests === 'number' ? room.guests : 0), 0);
         
                if(totalRoomCount >= 6) {
                    if(this.venue == 'hoar-cross-hall') {
                        $('#groupsModal').modal('show');
                    }

                    return;
                }

                if(this.rooms[index].guests < 4) {
                    this.rooms[index].guests++
                }

                this.fetchPMSRatesRequest(index, true);
            },

            nextStep() {
                this.$router.push('/book/enhance/' + this.venue + '/' + this.type + '/' + moment(this.arrive).format('YYYY-MM-DD') + '/' + this.nights + '/' + this.guests)
            },

            updateRoute() {
                var routeName = this.type == 'stay' ? 'book-stay' : 'book-hotel-stay'
                this.upsellPrice = null
                let from = this.$route.fullPath
                let to = this.$router.resolve({ name: routeName, params: { arrive: moment(this.arrive).format('YYYY-MM-DD'), nights: this.nights, guests: this.guests, rate: this.rate } }).route.fullPath
                
                if(from !== to) {
                    this.$router.replace({ name: routeName, params: { arrive: moment(this.arrive).format('YYYY-MM-DD'), nights: this.nights, guests: this.guests, rate: this.rate } })
                    this.fetchPMSRatesRequest(0)
                }
            },

            fetchPMSRatesRequest(roomIndex = 0, guestCheck = false) {
                this.isDisabled = null
                this.selectedRate = null
                this.availabilityStatus = null
                this.loadingPackages = true
                this.fromPriceCount = 0

                var depart = moment(this.arrive).add(this.nights, 'days').format('YYYY-MM-DD')
                var request = {
                    method: 'FetchPMSRates',
                    data: {
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: this.rooms[roomIndex].guests,
                        Children: 0,
                        Youth: 0, 
                        Infants: 0,
                        WebFolioId: null,
                        Language: null,
                        Venue: 'Hoar Cross Hall',
                        PromoCode: this.promoCode,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        var self = this;

                        if(response.data.Result.value == 'FAIL') {
                            this.rooms[roomIndex].rates = null;

                            if(guestCheck) {
                                this.rooms[roomIndex].guests--;
                                this.fetchPMSRatesRequest(roomIndex);
                                $('#noRoomAvailability').modal('show');
                            }
                        } else {
                            if(!$.isArray(response.data.PMSRateType)) {
                                response.data.PMSRateType = [response.data.PMSRateType];
                            } 

                            // Filter rates if from a package else sort hotel only rates
                            if(this.packageFilter) {
                                response.data.PMSRateType = response.data.PMSRateType.filter(function (rate) {
                                    return _.includes(self.packageFilter, rate.PMSRateTypeId)
                                })
                                
                            } else {
                                if(this.type == 'hotel-stay') {
                                    response.data.PMSRateType = response.data.PMSRateType.filter(function (rate) {
                                        return _.includes(self.system.hotelOnlyRates, rate.PMSRateTypeId)
                                    })
                                } else {
                                    response.data.PMSRateType = response.data.PMSRateType.filter(function (rate) {
                                        return !_.includes(self.system.hotelOnlyRates, rate.PMSRateTypeId)
                                    })
                                }
                                
                            }
                            
                            if(response.data.PMSRateType.length) {
                                // Get web details
                                _.each(response.data.PMSRateType, function(o) {
                                    self.fetchPackageDetails(o)
                                })

                                this.rooms[roomIndex].rates = response.data.PMSRateType

                                var webfolio = {}
                                webfolio[this.system.WebFolioId] = { 
                                    'booking': {
                                        'venue': this.venue,
                                        'type': this.type,
                                        'arrive': moment(this.arrive).format('YYYY-MM-DD'),
                                        'nights': this.nights,
                                        'guests': this.rooms[this.activeRoom].guests,
                                        'rooms': this.room
                                    }
                                }
                                window.localStorage.setItem('rsbooking', JSON.stringify(webfolio))

                                _.each(self.rooms[roomIndex].rates, function(pmsRate) {
                                    Vue.set(pmsRate, 'fromPrice', null)
                                    Vue.set(pmsRate, 'fromDiscount', null)
                                    self.getFromPrice(pmsRate, roomIndex)
                                })
                            } else {
                                this.rooms[roomIndex].rates = null;

                                if(guestCheck) {
                                    this.rooms[roomIndex].guests--;
                                    this.fetchPMSRatesRequest(roomIndex);
                                    $('#noRoomAvailability').modal('show');
                                }
                            }
                        }
                        this.loadingPackages = false
                        Bus.$emit('hideLoading')
                    });
            },

            getFromPrice(rate, roomIndex) {
                var self = this
                var depart = moment(this.arrive).add(this.nights, 'days').format('YYYY-MM-DD')

                var request = {
                    method: 'FetchPMSRoomTypes',
                    data: {
                        PMSRateType: rate.PMSRateTypeId,
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: this.rooms[roomIndex].guests,
                        Children: 0,
                        Youth: 0,
                        Infants: 0,
                        WebFolioId: this.system.WebFolioId,
                        Language: null,
                        Venue: 'Hoar Cross Hall',
                        PromoCode: this.promoCode
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(!$.isArray(response.data.PMSRate)) {
                            response.data.PMSRate = [response.data.PMSRate];
                        }
                        rate.fromPrice = _.minBy(response.data.PMSRate, function(o) { return +o.TotalStayRate })
         
                        if(_.includes(['R3FOR2'], rate.PMSRateTypeId)) {
                            rate.fromDiscount = +rate.fromPrice.TotalStayRate * ((100-33.3333)/100)
                        }
                        
                        self.fromPriceCount++
                        Bus.$emit('hideLoading')
                    })
            },

            selectRoom(rate) {
                this.showRooms = true;
                this.showRate = false;

                this.fetchPMSRoomsRequest(rate);
            },

            viewMoreDetails(rate) {
                this.showRate = true;
                this.showRooms = false;

                this.fetchPMSRoomsRequest(rate);
            },

            togglePopout() {
                $('.package-selector').toggleClass('is-active');
                $('html').toggleClass('no-scroll');
            },

            fetchRoomDetails() {
                var self = this;

                axios.get('/rs/rooms')
                    .then(response => {
                        self.roomDetails = response.data
                    });
            },

            fetchPMSRoomsRequest(rate) {
                var self = this;
                this.roomsLoading = true;
                this.togglePopout();
                
                this.isDisabled = rate;
                this.availabilityStatus = null;
                this.selectedRate = rate;

                dataLayer.push({
                    event: "view_item",
                    venue: this.venueName,
                    ecommerce: {
                        currency: "GBP",
                        value: 0,
                        items: [{
                            item_name: this.selectedRate.PMSRateTypeDesc,
                            item_id: this.selectedRate.PMSRateTypeId,
                            price: 0,
                            item_brand: this.venueName,
                            item_category: "Stays",
                            quantity: this.rooms[this.activeRoom].guests,
                        }]
                    }
                });

                this.$emitAnalytics(
                    'view_item', 
                    {
                        event: "view_item",
                        venue: this.venueName,
                        ecommerce: {
                            currency: "GBP",
                            value: 0,
                            items: [{
                                item_name: this.selectedRate.PMSRateTypeDesc,
                                item_id: this.selectedRate.PMSRateTypeId,
                                price: 0,
                                item_brand: this.venueName,
                                item_category: "Stays",
                                quantity: this.rooms[this.activeRoom].guests,
                            }]
                        }
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                );

                var depart = moment(this.arrive).add(this.nights, 'days').format('YYYY-MM-DD');
                var request = {
                    method: 'FetchPMSRoomTypes',
                    data: {
                        PMSRateType: rate.PMSRateTypeId,
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: this.rooms[this.activeRoom].guests,
                        Children: '0',
                        Youth:'0',
                        Infants:'0',
                        WebFolioId: this.system.WebFolioId,
                        Language: '',
                        Venue: 'Hoar Cross Hall',
                        PromoCode: this.promoCode,
                    }
                }

                axios.post('/rs/request', request)
                    .then(response => {
                        if(response.data.Result.value != 'FAIL') {
                            if(!$.isArray(response.data.PMSRate)) {
                                response.data.PMSRate = [response.data.PMSRate];
                            }

                            // sort by price
                            let pmsRate = _.sortBy(response.data.PMSRate, [function(room) {
                                return +room.TotalStayRate;
                            }])
                            

                            _.each(pmsRate, function(r) {
                                if(_.includes(['CLASS', 'TRIP', 'CSNGL', 'GROOM'], r.PMSRoomType.PMSRoomTypeId)) {
                                    r.ArrivalTime = '3:30pm'
                                } else {
                                    r.ArrivalTime = '3pm'
                                }

                                r.PMSRoomTypeId = r.PMSRoomType.PMSRoomTypeId
                                r.AdvancedBookingIncentive = null
                                r.ppRate = (+r.TotalStayRate / self.rooms[self.activeRoom].guests)

                                if(_.includes(['R3FOR2'], self.selectedRate.PMSRateTypeId)) {
                                    r.AdvancedBookingIncentive = 33.33333
                                }

                                r.roomDetails = self.roomDetails.find(room => room.room_type === r.PMSRoomType.PMSRoomTypeId);
                            })

                            Vue.set(self.selectedRate, 'rooms', pmsRate);
                        }
                    });

                    self.roomsLoading = false;
            },

            book(room, twinRoom) {
                Bus.$emit('showLoading')

                this.bookDisabled = room
                var self = this
                var depart = moment(this.arrive).add(this.nights, 'days').format('YYYY-MM-DD')

                var request = {
                    method: 'CreatePMSBooking',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        PMSRoomType: room.PMSRoomType.PMSRoomTypeId,
                        RoomNumber: null,
                        CustomerId: this.system.isLoggedIn ? this.system.CustomerId : this.system.WebFolioCustomerId,
                        PMSRateType: this.selectedRate.PMSRateTypeId,
                        ArrivalDate: moment(this.arrive).format('YYYY-MM-DD'),
                        DepartureDate: depart,
                        Adults: this.rooms[this.activeRoom].guests,
                        Children: null,
                        Youth: null,
                        Infants:null,
                        GroupCode: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') { // Added to WebFolio
                            
                            this.rooms[this.activeRoom].folioId = response.data.PMSFolioId;
                            this.rooms[this.activeRoom].packageSelected = true;

                            if(twinRoom) {
                                var request = {
                                    method: 'AddPMSSpecialService',
                                    data: {
                                        SessionId: this.system.SessionId,
                                        WebFolioId: this.system.WebFolioId,
                                        PMSFolioId: response.data.PMSFolioId,
                                        PMSSpecialServiceName: 'Twin Bed',
                                        PMSSpecialServiceQty: 1,
                                        PMSSpecialServiceDate: moment(this.arrive).format('YYYY-MM-DD'),
                                        PMSSpecialServiceNote: null
                                    }
                                }
                                axios.post('/rs/request', request)
                                    .then(response=> {
                                        Bus.$emit('UpdateCart')
                                    })
                            } else {
                                var request = {
                                    method: 'AddPMSSpecialService',
                                    data: {
                                        SessionId: this.system.SessionId,
                                        WebFolioId: this.system.WebFolioId,
                                        PMSFolioId: response.data.PMSFolioId,
                                        PMSSpecialServiceName: 'Double Bed',
                                        PMSSpecialServiceQty: 1,
                                        PMSSpecialServiceDate: moment(this.arrive).format('YYYY-MM-DD'),
                                        PMSSpecialServiceNote: null
                                    }
                                }
                                axios.post('/rs/request', request)
                                    .then(response=> {
                                        Bus.$emit('UpdateCart')
                                })
                            }
                            
                            _.each(self.rates, (rate) => {
                                rate.rooms = null
                            })

                            dataLayer.push({
                                'event': 'add_to_cart',
                                'venue': self.venueName,
                                'ecommerce': {
                                    'currency': 'GBP',
                                    'add': {                                // 'add' actionFieldObject measures.
                                        'products': [{                        //  adding a product to a shopping cart.
                                            'name': this.selectedRate.PMSRateTypeDesc,
                                            'id': this.selectedRate.PMSRateTypeId,
                                            'price': +room.TotalStayRate,
                                            'brand': self.venueName,
                                            'category': 'Stays',
                                            'quantity': 1
                                        }]
                                    }
                                }
                            });

                            this.$emitAnalytics(
                                'add_to_cart', 
                                {
                                    'event': 'add_to_cart',
                                    'venue': self.venueName,
                                    'ecommerce': {
                                        'currency': 'GBP',
                                        'add': {                                // 'add' actionFieldObject measures.
                                            'products': [{                        //  adding a product to a shopping cart.
                                                'name': this.selectedRate.PMSRateTypeDesc,
                                                'id': this.selectedRate.PMSRateTypeId,
                                                'price': +room.TotalStayRate,
                                                'brand': self.venueName,
                                                'category': 'Stays',
                                                'quantity': 1
                                            }]
                                        }
                                    }
                                },
                                this.system.CustomerId,
                                this.system.WebFolioId
                            );

                            // Lets track this in local storage for later
                            var rsfolio = {
                                PMSFolioId: response.data.PMSFolioId,
                                PMSRoomType: room.PMSRoomType.PMSRoomTypeId,
                                PMSRateType: this.selectedRate.PMSRateTypeId,
                                ArrivalDate: moment(self.arrive).format('YYYY-MM-DD'),
                                DepartureDate: depart,
                                Adults: self.rooms[self.activeRoom].guests,
                                Nights: self.nights
                            }

                            var sessionStorage = JSON.parse(window.localStorage.getItem('session_folios'))
                            sessionStorage[self.system.WebFolioId][response.data.PMSFolioId] = rsfolio
                            window.localStorage.setItem('session_folios', JSON.stringify(sessionStorage))
                            
                        } 

                        this.isDisabled = null

                        const nextRoom = this.rooms.findIndex(item => item.packageSelected === false);
                        if(nextRoom === -1) {
                            this.showPackages = false;

                        } else {
                            this.setActiveRoom(nextRoom);
                        }

                        this.showRooms = false
                        
                        $('.package-selector').toggleClass('is-active');
                        $('html').toggleClass('no-scroll');
                        Bus.$emit('hideLoading');
                });
            },
            fetchPackageDetails(rate) {
                axios.get('/rs/rates/' + rate.PMSRateTypeId)
                    .then(response => {
                        rate.details = response.data
                    })
            },
        }
    }
</script>