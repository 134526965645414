var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "d-none d-md-inline" }, [
    _c("span", { staticClass: "ffs" }, [
      _c("a", { attrs: { href: "/book/account" } }, [
        _vm._v("Hi " + _vm._s(_vm.hello) + "."),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "small",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.logout.apply(null, arguments)
          },
        },
      },
      [_vm._v("Logout")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }