var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking-stay" },
    [
      _vm._l(_vm.notify, function (n) {
        return _c("div", [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "booking-date-notice" }, [
                _c("span", { domProps: { innerHTML: _vm._s(n.message) } }),
              ]),
            ]),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c(
          "div",
          { staticClass: "col-lg-10" },
          [
            _c("progress-indicator", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("div", { staticClass: "booking-info" }, [
              _vm.type == "stay"
                ? _c("h1", { staticClass: "brandHeading" }, [
                    _vm._v("Your Spa Stay"),
                  ])
                : _c("h1", { staticClass: "brandHeading" }, [
                    _vm._v("Hotel Only"),
                  ]),
              _vm._v(" "),
              !_vm.roomsSelected()
                ? _c("div", [
                    _c("div", { staticClass: "date-info" }, [
                      _c("div", { staticClass: "date-info__box" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-2 d-none d-md-block" },
                            [
                              _vm.configurationEditable ||
                              !_vm.configurationSelected
                                ? _c("div", { staticClass: "date-info__btn" }, [
                                    _c("a", {
                                      staticClass: "prevNextArrow arrowLeft",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.prevDay()
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-md-8" }, [
                            _c("div", { staticClass: "date-info__label" }, [
                              _vm._v(
                                "\n                                        Date\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "date-info__date" }, [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm._f("nicedate")(_vm.arrive)) +
                                  " \n                                        \n                                        "
                              ),
                              !_vm.configurationSelected
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "date-info__date__btn float-right",
                                      attrs: {
                                        disabled: _vm.configurationSelected,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.dateSideout()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 512 512",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-2 d-none d-md-block" },
                            [
                              _vm.configurationEditable ||
                              !_vm.configurationSelected
                                ? _c("div", { staticClass: "date-info__btn" }, [
                                    _c("a", {
                                      staticClass: "prevNextArrow arrowRight",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.nextDay()
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "selector-boxes mb-4" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6 mb-4" }, [
                          _c("div", { staticClass: "selector-box" }, [
                            _c("div", { staticClass: "selector-box__label" }, [
                              _vm._v(
                                "\n                                        Nights\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-3" }, [
                                _vm.configurationEditable ||
                                !_vm.configurationSelected
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "selector-box__btn",
                                        on: { click: _vm.decNights },
                                      },
                                      [_vm._v("-")]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "selector-box__amount" },
                                  [_vm._v(_vm._s(_vm.nights))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3" }, [
                                _vm.configurationEditable ||
                                !_vm.configurationSelected
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "selector-box__btn float-right",
                                        on: { click: _vm.incNights },
                                      },
                                      [_vm._v("+")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "dropdown-box" }, [
                            _c("div", { staticClass: "dropdown-box__label" }, [
                              _vm._v(
                                "\n                                        Rooms / Guests\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-9" }, [
                                _c(
                                  "div",
                                  { staticClass: "dropdown-box__amount" },
                                  [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(_vm.rooms.length) +
                                        " rooms, " +
                                        _vm._s(_vm.totalGuests) +
                                        " guests\n                                            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "dropdown-box__btn float-right",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleRoomDropdown()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 512 512",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.showRoomDropdown
                              ? _c(
                                  "div",
                                  { staticClass: "dropdown-box__dropdown" },
                                  [
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _vm._l(_vm.rooms, function (room, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row mb-1" },
                                        [
                                          _c("div", { staticClass: "col-5" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-box__room",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    Room " +
                                                    _vm._s(index + 1) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-5" }, [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-3" },
                                                [
                                                  _vm.configurationEditable ||
                                                  !room.packageSelected
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "selector-box__btn",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.decGuests(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("-")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "selector-box__amount",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(room.guests)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-3" },
                                                [
                                                  _vm.configurationEditable ||
                                                  !room.packageSelected
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "selector-box__btn float-right",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.incGuests(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-2" }, [
                                            index !== 0 && !room.packageSelected
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-danger",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.removeRoom(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          height: "1em",
                                                          viewBox:
                                                            "0 0 384 512",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "sr-only sr-only-focusable",
                                                      },
                                                      [_vm._v("Remove")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary mt-2 mb-0",
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleRoomDropdown()
                                              },
                                            },
                                          },
                                          [_vm._v("Complete")]
                                        ),
                                        _vm._v(" "),
                                        _vm.totalGuests < 6
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mt-2 mb-0",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addRoom()
                                                  },
                                                },
                                              },
                                              [_vm._v("Add Additional Room")]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          !_vm.configurationSelected
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary mt-4",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.confirmRoomConfiguration()
                                    },
                                  },
                                },
                                [_vm._v("Next Step")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.upsellPrice && !_vm.showCallRes
                ? _c("div", { staticClass: "roomUpsell" }, [
                    !_vm.cart.WebFolioItem
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              "Stay a little longer, adding an extra night from only £" +
                                _vm._s(_vm._f("currency")(_vm.upsellPrice)) +
                                "?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.yesPlease.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Yes please!")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm.type == "hotel-stay"
                ? _c("div", { staticClass: "roomUpsell" }, [
                    _c("div", [
                      _c("p", [
                        _vm._v(" Fancy a relaxing Spa Stay? Discover our "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/book/booking/hoar-cross-hall/stay/" +
                                _vm.arrive +
                                "/" +
                                _vm.nights +
                                "/" +
                                _vm.guests,
                            },
                          },
                          [_vm._v("Spa Stay Experiences")]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.roomsSelected()
                ? _c(
                    "div",
                    { staticClass: "row justify-content-center mb-3" },
                    [
                      _c("div", { staticClass: "col-md-7" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v(
                            "Your booking has now been added to the cart."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-md-flex" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-secondary flex-md-fill ml-md-1",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.nextStep()
                                },
                              },
                            },
                            [_vm._v("Next step")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary flex-md-fill ml-md-1",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.startOver()
                                },
                              },
                            },
                            [_vm._v("Start Over")]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "small mb-4" }, [
                    _c("p", { staticClass: "mb-1" }, [
                      _vm._v(
                        "Please note, there is a maximum of 4 guests per room."
                      ),
                    ]),
                  ]),
            ]),
            _vm._v(" "),
            _vm.configurationSelected && !_vm.roomsSelected()
              ? _c("div", [
                  !_vm.configurationEditable
                    ? _c(
                        "ul",
                        {
                          staticClass: "room-tabs nav nav-tabs",
                          attrs: { id: "roomsTabs", role: "tablist" },
                        },
                        _vm._l(_vm.rooms, function (room, index) {
                          return _c(
                            "li",
                            {
                              staticClass: "nav-item",
                              attrs: { role: "presentation" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link",
                                  class: { active: _vm.activeRoom === index },
                                  attrs: {
                                    id: "room-" + index + "-tab",
                                    "data-toggle": "tab",
                                    "data-target": "room-" + index + "-tab",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "room-" + index + "-tab",
                                    "aria-selected": "true",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveRoom(index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Room " +
                                      _vm._s(index + 1) +
                                      " \n                            \n                            "
                                  ),
                                  room.packageSelected
                                    ? _c(
                                        "span",
                                        { staticClass: "badge badge-light" },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 448 512",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "roomTabsContent" },
                    },
                    [
                      _vm._l(_vm.rooms, function (room, index) {
                        return _vm.activeRoom === index && !_vm.loadingPackages
                          ? _c(
                              "div",
                              {
                                staticClass: "tab-pane fade active show",
                                attrs: {
                                  id: "room-" + index,
                                  role: "tabpanel",
                                  "aria-labelledby": "room-" + index,
                                },
                              },
                              [
                                _c("div", { staticClass: "package-list" }, [
                                  !_vm.configurationEditable &&
                                  _vm.rooms[index].rates !== null &&
                                  _vm.rooms[index].rates.length > 0 &&
                                  !room.packageSelected
                                    ? _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "brandHeading text-center mb-4",
                                        },
                                        [
                                          _vm._v(
                                            "Select your experience for Room " +
                                              _vm._s(index + 1)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.configurationEditable &&
                                  _vm.rooms[index].rates !== null &&
                                  _vm.rooms[index].rates.length > 0 &&
                                  room.packageSelected
                                    ? _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "brandHeading text-center mb-4",
                                        },
                                        [_vm._v("Your selected experience")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.rooms[index].rates !== null &&
                                  _vm.rooms[index].rates.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "row" },
                                        _vm._l(
                                          _vm.rooms[index].rates,
                                          function (rate, key) {
                                            return room.packageSelected ===
                                              false ||
                                              _vm.getSelectedPackage() ===
                                                rate.PMSRateTypeId
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: key,
                                                    staticClass: "col-12",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "package-container",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "package-image",
                                                            style:
                                                              "background-image:url(" +
                                                              rate.PMSRateTypeImage +
                                                              ");",
                                                          },
                                                          [_vm._v(" ")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "package-type",
                                                          },
                                                          [
                                                            _c("h3", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  rate.PMSRateTypeDesc
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fromPrice",
                                                              },
                                                              [
                                                                rate.fromPrice
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "from "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            {
                                                                              "text-strike mb-0":
                                                                                rate.fromDiscount,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "£" +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "currency"
                                                                                )(
                                                                                  +rate
                                                                                    .fromPrice
                                                                                    .TotalStayRate /
                                                                                    _vm
                                                                                      .rooms[
                                                                                      _vm
                                                                                        .activeRoom
                                                                                    ]
                                                                                      .guests /
                                                                                    _vm.nights
                                                                                )
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      rate.fromDiscount
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "£" +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "currency"
                                                                                    )(
                                                                                      +rate.fromDiscount /
                                                                                        _vm
                                                                                          .rooms[
                                                                                          _vm
                                                                                            .activeRoom
                                                                                        ]
                                                                                          .guests /
                                                                                        _vm.nights
                                                                                    )
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "d-none d-lg-block",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "(per person per night)"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "d-lg-none",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "(pp per night)"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "totalPrice",
                                                              },
                                                              [
                                                                rate.fromPrice
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "from "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            {
                                                                              "text-strike mb-0":
                                                                                rate.fromDiscount,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "£" +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "currency"
                                                                                )(
                                                                                  +rate
                                                                                    .fromPrice
                                                                                    .TotalStayRate
                                                                                )
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      rate.fromDiscount
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "£" +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "currency"
                                                                                    )(
                                                                                      +rate.fromDiscount
                                                                                    )
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "d-none d-lg-block",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "(" +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .rooms[
                                                                                  _vm
                                                                                    .activeRoom
                                                                                ]
                                                                                  .guests
                                                                              ) +
                                                                              " guests, " +
                                                                              _vm._s(
                                                                                _vm.nights
                                                                              ) +
                                                                              " nights)"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "d-lg-none",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "total cost"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.getSelectedPackage() ==
                                                            rate.PMSRateTypeId
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary d-lg-none mb-0 align-self-end",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.removeRoomPackage(
                                                                            rate,
                                                                            key
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                Remove\n                                            "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.getSelectedPackage() !=
                                                            rate.PMSRateTypeId
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary d-lg-none mb-0 align-self-end",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.selectRoom(
                                                                            rate
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                Book\n                                            "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.getSelectedPackage() !==
                                                            rate.PMSRateTypeId
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-outline-primary d-lg-none mb-0 align-self-end",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.viewMoreDetails(
                                                                            rate
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "View more details"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "package-description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  rate.PMSRateTypeCustDesc.substring(
                                                                    0,
                                                                    180
                                                                  )
                                                                ) +
                                                                "...\n\n                                            "
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-xl-flex package-buttons",
                                                              },
                                                              [
                                                                _vm.getSelectedPackage() !==
                                                                rate.PMSRateTypeId
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary flex-xl-fill mr-md-1",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.selectRoom(
                                                                                rate
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Book"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.getSelectedPackage() ===
                                                                rate.PMSRateTypeId
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary flex-xl-fill mr-md-1",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.removeRoomPackage(
                                                                                rate
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Remove"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.getSelectedPackage() !==
                                                                rate.PMSRateTypeId
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-outline-primary flex-xl-fill",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.viewMoreDetails(
                                                                                rate
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "View details"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      )
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "no-availability-message",
                                          },
                                          [
                                            _vm.loadingPackages
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        "Please wait while we fetch all the experiences."
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "info-box",
                                                      },
                                                      [
                                                        _vm.rooms[
                                                          _vm.activeRoom
                                                        ].guests == 1
                                                          ? _c("h4", [
                                                              _vm._v(
                                                                "Oops, we don't seem to have any online single room availability on your chosen date. Please call our Reservations Team on "
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "tel:01283 576522",
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "01283 576522"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                ", who will be able to help."
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.rooms[
                                                          _vm.activeRoom
                                                        ].guests > 1
                                                          ? _c("h4", [
                                                              _vm._v(
                                                                "Sorry, there is no availability on this date. Please choose another date."
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-xl-flex",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-outline-primary flex-xl-fill",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.dateSideout()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Select another date"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  _vm.rates
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-4 next-steps",
                                            },
                                            [
                                              !_vm.countRooms &&
                                              _vm.showNextStep
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary flex-md-fill ml-md-1",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.nextStep()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Next step")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-9 mt-3" },
                                            [
                                              _vm.showCallRes
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gc-box bg-blush",
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          "Fancy adding an extra night? No problem!"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("p", [
                                                        _vm._v(
                                                          "Please call reservations on 01283 576522."
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "gc-box" },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          "Redeeming a Gift Card or E-voucher?"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("p", [
                                                        _vm._v(
                                                          "Did you know, all of our gift vouchers hold a monetary value and can be redeemed against any experience. Simply select your chosen experience, then enter your voucher code at checkout to redeem."
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _vm.loadingPackages
                        ? _c(
                            "div",
                            { staticClass: "tab-pane fade active show" },
                            [_vm._m(1)]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "booking-app-sideout package-selector" }, [
        _c("div", { staticClass: "sideout-wrapper" }, [
          _c("div", { staticClass: "sideout-container" }, [
            _vm.showRate && _vm.selectedRate
              ? _c("div", { staticClass: "sideout-content rate-details" }, [
                  _c("div", { staticClass: "package-header" }, [
                    _c(
                      "a",
                      {
                        staticClass: "prevNextArrow arrowLeft",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.togglePopout()
                          },
                        },
                      },
                      [_vm._v(" ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "package-content" }, [
                    _vm.selectedRate.PMSRateTypeImage
                      ? _c(
                          "div",
                          {
                            staticClass: "room-package-image",
                            style:
                              "background-image:url(" +
                              _vm.selectedRate.PMSRateTypeImage +
                              ");",
                          },
                          [_vm._v(" ")]
                        )
                      : _c("div", { staticClass: "room-package-image" }, [
                          _vm._v(" "),
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "room-package-description" }, [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.selectedRate.PMSRateTypeDesc)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedRate.details.intro),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedRate.details.details),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "package-cta" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-block",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.displayRooms.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Select room")]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showRooms
              ? _c("div", { staticClass: "sideout-content" }, [
                  _c("div", { staticClass: "package-header" }, [
                    _c(
                      "a",
                      {
                        staticClass: "prevNextArrow arrowLeft",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.showRooms = false
                            _vm.togglePopout()
                          },
                        },
                      },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" \n                    "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "package-content" },
                    [
                      _vm.roomsLoading
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [_vm._m(2)]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.selectedRate.rooms, function (room) {
                        return !_vm.roomsLoading
                          ? _c("div", { staticClass: "room-feed" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "room-image",
                                  style:
                                    "background-image:url(/storage/" +
                                    room.roomDetails.img +
                                    ");",
                                },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "room-content" }, [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(room.PMSRoomType.PMSRoomTypeDesc)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "room-price" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-1",
                                      class: {
                                        advancedPrice:
                                          room.AdvancedBookingIncentive,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        £" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              room.ppRate / _vm.nights
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  room.AdvancedBookingIncentive
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        £" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                (room.ppRate -
                                                  (room.ppRate / 100) *
                                                    room.AdvancedBookingIncentive) /
                                                  _vm.nights
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " (per person per night)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "room-total-price" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-1",
                                      class: {
                                        advancedPrice:
                                          room.AdvancedBookingIncentive,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        £" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              room.TotalStayRate
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  room.AdvancedBookingIncentive
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        £" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                room.TotalStayRate -
                                                  (room.TotalStayRate / 100) *
                                                    room.AdvancedBookingIncentive
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " (" +
                                      _vm._s(_vm.rooms[_vm.activeRoom].guests) +
                                      " guests, " +
                                      _vm._s(_vm.nights) +
                                      " nights)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-block mb-md-4 mb-2",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.getRoomDetails(room)
                                      },
                                    },
                                  },
                                  [_vm._v("View details")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "d-md-flex" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary flex-md-fill mr-md-1",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.book(room, false)
                                        },
                                      },
                                    },
                                    [_vm._v("Book Double")]
                                  ),
                                  _vm._v(" "),
                                  room.PMSRoomTypeId != "CSNGL"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary flex-md-fill ml-md-1",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.book(room, true)
                                            },
                                          },
                                        },
                                        [_vm._v("Book Twin")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.roomFull
              ? _c("div", { staticClass: "sideout-content" }, [
                  _c("div", { staticClass: "package-header" }, [
                    _c(
                      "a",
                      {
                        staticClass: "prevNextArrow arrowLeft",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeRoom()
                          },
                        },
                      },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" \n                    "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "package-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "room-full-image",
                        style:
                          "background-image:url(/storage/" +
                          _vm.roomFull.roomDetails.img +
                          ");",
                      },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "room-full-description" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.roomFull.PMSRoomType.PMSRoomTypeDesc) +
                            " \n                                "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "float-right",
                            class: {
                              advancedPrice:
                                _vm.roomFull.AdvancedBookingIncentive,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    £" +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.roomFull.ppRate / _vm.nights
                                  )
                                ) +
                                "pp\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.roomFull.AdvancedBookingIncentive
                          ? _c("span", [
                              _vm._v(
                                "\n                                    £" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      (_vm.roomFull.ppRate -
                                        (_vm.roomFull.ppRate / 100) *
                                          _vm.roomFull
                                            .AdvancedBookingIncentive) /
                                        _vm.nights
                                    )
                                  ) +
                                  "pp\n                                "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.roomFull.roomDetails.intro),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.roomFull.roomDetails.details),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-md-flex" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-secondary flex-md-fill mr-md-1",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.book(_vm.roomFull, false)
                              },
                            },
                          },
                          [_vm._v("Book Double")]
                        ),
                        _vm._v(" "),
                        _vm.roomFull.PMSRoomTypeId != "CSNGL"
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary flex-md-fill ml-md-1",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.book(_vm.roomFull, true)
                                  },
                                },
                              },
                              [_vm._v("Book Twin")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "booking-app-sideout date-selector" }, [
        _c("div", { staticClass: "sideout-wrapper" }, [
          _c("div", { staticClass: "sideout-container" }, [
            _c("div", { staticClass: "sideout-content" }, [
              _c("div", { staticClass: "package-header" }, [
                _c(
                  "a",
                  {
                    staticClass: "prevNextArrow arrowLeft",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.dateSideout()
                      },
                    },
                  },
                  [_vm._v(" ")]
                ),
                _vm._v(" \n                    "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "package-content" }, [
                _c(
                  "div",
                  { staticClass: "room-full-description text-center" },
                  [
                    _c("h4", { staticClass: "mb-4" }, [
                      _vm._v(_vm._s(_vm._f("nicedate")(_vm.arrive))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        "Please note, gift voucher codes are entered at checkout."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("rate-calendar", {
                      attrs: {
                        system: _vm.system,
                        venue: _vm.venueName,
                        type: _vm.type,
                        mindate: _vm.mindate,
                        arrive: _vm.arrive,
                        promo: _vm.promoCode,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _vm._l(_vm.confirmNotifications, function (notification, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "modal fade blush-modal",
            attrs: {
              id: "confirmNotification_" + notification.id,
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "confirmNotification_" + notification.id,
              "aria-hidden": "true",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-dialog modal-dialog-centered",
                attrs: { role: "document" },
              },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _vm._m(6, true),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "modal-body text-center",
                    domProps: { innerHTML: _vm._s(notification.message) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.chooseAnotherDate(notification.id)
                          },
                        },
                      },
                      [_vm._v("Select Another Date")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", "data-dismiss": "modal" },
                      },
                      [_vm._v("Accept")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("strong", { staticClass: "dropdown-box__label" }, [
          _vm._v(
            "Room \n                                                  \n                                                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("strong", { staticClass: "dropdown-box__label" }, [
          _vm._v("Guests"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "visually-hidden" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal fade blush-modal",
        attrs: {
          id: "groupsModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "groupsModalTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header border-none" }, [
                _c("h5", { staticClass: "modal-title w-100 text-center" }, [
                  _vm._v("Booking on behalf of a group?"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body text-center" }, [
                _c("p", [
                  _vm._v(
                    "Then look no further than our collection of group spa experiences, created for milestone celebrations, general get-togethers and corporate team away days."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Please call our Guest Experience Team on 01608 787986, who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests."
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary btn-block mt-4 mb-0",
                    attrs: { href: "/hoar-cross-hall/group-experiences" },
                  },
                  [_vm._v("View our group Spa collection")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal fade blush-modal",
        attrs: {
          id: "noRoomAvailability",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "noRoomAvailability",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header pb-0 border-none" }, [
                _c("h5", { staticClass: "modal-title w-100 text-center" }, [
                  _vm._v("Sorry"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body text-center pt-0" }, [
                _c("p", [
                  _vm._v(
                    "We have no rooms available for that amount of guests, try adding an additional room or try another selected another date."
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal fade blush-modal",
        attrs: {
          id: "dbbModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "dbbModalTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header pb-0 border-none" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body text-center pt-0" }, [
                _c("h6", { staticClass: "mb-3" }, [
                  _vm._v("Fancy staying for longer?"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Check out our Multi-night Spa Stay experiences or call our Reservations Team on 01283 576522"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary btn-block mt-4 mb-0",
                    attrs: { href: "/hoar-cross-hall/overnight-experiences" },
                  },
                  [_vm._v("View Experiences")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header text-center" }, [
      _c("h5", { staticClass: "modal-title w-100" }, [
        _vm._v("Important Notice"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }