var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-message" }, [
    _c("div", { staticClass: "modal-message-container" }, [
      _c("h2", [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.showmodal.titleOne) } }),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.showmodal.titleTwo)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.showmodal.message))]),
      _vm._v(" "),
      _vm.showmodal.btnUrl
        ? _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: _vm.showmodal.btnUrl },
            },
            [_vm._v(_vm._s(_vm.showmodal.btn))]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }