var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar" },
    [
      _c(
        "v-calendar",
        {
          attrs: {
            mode: "single",
            "from-date": _vm.fromDate,
            "min-date": _vm.initMinDate,
            "max-date": null,
            attributes: _vm.atts,
            "is-expanded": "",
            "is-inline": "",
            "is-linked": "",
          },
          on: {
            dayclick: function ($event) {
              return _vm.dayClick($event)
            },
            "update:to-page": function ($event) {
              return _vm.nextMonth($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "day-content",
              fn: function ({ day, attributes, dayEvents }) {
                return _c(
                  "div",
                  _vm._g(
                    {
                      staticClass: "vc-day-content",
                      class: {
                        daySelected: day.id == _vm.arrive,
                        "vc-text-gray-400": !attributes,
                      },
                      attrs: { day: day },
                    },
                    dayEvents
                  ),
                  [
                    _c(
                      "div",
                      [
                        _c("div", [_vm._v(_vm._s(day.day))]),
                        _vm._v(" "),
                        _vm._l(attributes, function ({ key, customData }) {
                          return _c(
                            "div",
                            {
                              key: key,
                              staticClass: "vc-day-content__rate",
                              class: {
                                twilight: customData.twilight,
                                abiDot: customData.discount,
                              },
                            },
                            [
                              customData.price
                                ? _c("span", [
                                    customData.discount
                                      ? _c("span", [
                                          _vm._v(
                                            "£" +
                                              _vm._s(
                                                (
                                                  customData.price -
                                                  (customData.price / 100) * 10
                                                ).toFixed(2)
                                              )
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "£" +
                                              _vm._s(
                                                customData.price.toFixed(2)
                                              )
                                          ),
                                        ]),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                )
              },
            },
          ]),
          model: {
            value: _vm.selectedDate,
            callback: function ($$v) {
              _vm.selectedDate = $$v
            },
            expression: "selectedDate",
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "prevNextArrow arrowLeft",
              attrs: { slot: "header-left-button" },
              slot: "header-left-button",
            },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "prevNextArrow arrowRight",
              attrs: { slot: "header-right-button" },
              slot: "header-right-button",
            },
            [_vm._v(" ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary btn-block mt-2 main-calendar-action",
          attrs: { disabled: !_vm.selectedDate || !_vm.isDateAvailable() },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.search()
            },
          },
        },
        [_vm._v("Next Step")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-secondary btn-block mt-2 sideout-calendar-action",
          attrs: { disabled: !_vm.selectedDate || !_vm.isDateAvailable() },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeDateSideout()
            },
          },
        },
        [_vm._v("View available experiences")]
      ),
      _vm._v(" "),
      _vm.fetchingRates
        ? _c("div", { staticClass: "loading-calendar" }, [
            _c("span", { staticClass: "pr-1 text-uppercase" }, [
              _vm._v("Fetching the finer details"),
            ]),
            _c("div", {
              staticClass: "spinner-border spinner-border-sm",
              attrs: { role: "status" },
            }),
          ])
        : _c("div", [
            _vm.type != "treatments"
              ? _c("div", [
                  _c("span", { staticClass: "message-small" }, [
                    _vm._v(
                      "Prices shown are lowest rate available, per person"
                    ),
                    _vm.type == "stay"
                      ? _c("span", [_vm._v(", per night")])
                      : _vm._e(),
                    _vm._v("."),
                  ]),
                  _vm._v(" "),
                  0
                    ? _c("div", [
                        _c(
                          "span",
                          { staticClass: "abiDot-before message-small" },
                          [
                            _vm._v(
                              "Price shown includes 10% advanced booking discount."
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "spa-day"
                    ? _c("span", { staticClass: "twilight-key" }, [
                        _vm._v("Days with Twilight Spa available from "),
                        _vm.venue.includes("Eden")
                          ? _c("span", [_vm._v("£65")])
                          : _c("span", [_vm._v("£60")]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }