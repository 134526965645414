
import Vue from 'vue';

Vue.mixin({
    methods: {
        $emitAnalytics(event, eventData, customer_id = null, webfolio_id = null) {
            axios.post('/api/analytics/track', {
                action: event,
                payload: eventData,
                customer_id: customer_id,
                webfolio_id: webfolio_id,
            });
        }
    }
});