var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-cart" }, [
    _c("div", { staticClass: "row" }, [
      _vm.paymentError
        ? _c("div", { staticClass: "col-12 mb-5" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-12 text-center" },
        [
          _c("div", { staticClass: "booking-info" }, [
            _c("h1", { staticClass: "brandHeading" }, [
              _vm._v("Booking Summary"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cart-page-actions__balance mt-3" }, [
              _vm.cart.FolioBalance
                ? _c("span", [
                    _vm._v(
                      "Ready to pay £" + _vm._s(_vm.cart.FolioBalance) + "?"
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "promo text-center" }, [
              _c("form", [
                _c(
                  "div",
                  { staticClass: "form-inline mb-3 justify-content-center" },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.promoCode,
                            expression: "promoCode",
                          },
                        ],
                        staticClass: "form-control form-control",
                        attrs: {
                          type: "text",
                          id: "promoCode",
                          placeholder: "Promo code",
                        },
                        domProps: { value: _vm.promoCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.promoCode = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mb-0",
                          attrs: { disabled: _vm.promoProcessing },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.applyPromo.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Apply")]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                !_vm.promotionApplied &&
                _vm.invalidPromo &&
                !_vm.promoProcessing
                  ? _c("p", { staticClass: "message-small booking-notice" }, [
                      _vm._v(_vm._s(_vm.invalidPromo)),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "small" }, [
              _vm._v("Redeem a gift card or e-voucher on the next page"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 small" }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.ammendBooking()
                    },
                  },
                },
                [_vm._v("Amend booking")]
              ),
              _vm._v(" "),
              !_vm.guestsAdded
                ? _c(
                    "a",
                    {
                      staticClass: "ml-1",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showGuestNames()
                        },
                      },
                    },
                    [_vm._v("Add guest names")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.insuranceAdded
                ? _c(
                    "a",
                    {
                      staticClass: "ml-1",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeInsurance()
                        },
                      },
                    },
                    [_vm._v("Remove insurance")]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.folioByCat, function (folioItem, cat) {
            return _vm.folioByCat
              ? _c("div", { staticClass: "card cart-card-items" }, [
                  _c("div", { staticClass: "card-body" }, [
                    cat === "Your Visit"
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "booking-list" },
                            _vm._l(folioItem, function (folio, k) {
                              return _c(
                                "div",
                                { key: k, staticClass: "booking-list-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "booking-list-item__details",
                                    },
                                    [
                                      _c("h3", [
                                        folio.Type == "Stay"
                                          ? _c("span", [_vm._v("Room")])
                                          : _c("span", [_vm._v("Guest")]),
                                        _vm._v(" " + _vm._s(k + 1)),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "Location: " + _vm._s(folio.Location)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "Date: " +
                                            _vm._s(
                                              _vm._f("rsnicedate")(
                                                folio.StartDate
                                              )
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "Experience: " +
                                            _vm._s(folio.RateDetails)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      folio.Type == "Stay"
                                        ? _c("p", [
                                            _vm._v(
                                              "Guests: " + _vm._s(folio.Adults)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      folio.Type == "Stay"
                                        ? _c("p", [
                                            _c("strong", [
                                              _vm._v("Room type:"),
                                            ]),
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(folio.Details) +
                                                "\n                                        "
                                            ),
                                            folio.twinSelected
                                              ? _c("span", [
                                                  _vm._v("(Twin room)"),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            folio.doubleSelected
                                              ? _c("span", [
                                                  _vm._v("(Double room)"),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "details-price" },
                                        [
                                          _vm._v(
                                            "Cost: £" +
                                              _vm._s((+folio.Amount).toFixed(2))
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      folio.promotionApplied
                                        ? _c("p", { staticClass: "small" }, [
                                            _vm._v(
                                              "(" +
                                                _vm._s(folio.promotionApplied) +
                                                " promotion applied)"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      1
                                        ? _c("p", { staticClass: "small" }, [
                                            _vm._v("*Add code at checkout"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  folio.hotelOnly
                                    ? _c("div", {
                                        staticClass:
                                          "booking-list-item__treatments",
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "booking-list-item__treatments",
                                        },
                                        [
                                          folio.InclusiveTreatments
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "inclusive-list",
                                                },
                                                [
                                                  folio.HasOffers
                                                    ? _c("div", [
                                                        _c("h3", [
                                                          _vm._v(
                                                            "£20 off Selected Treatments"
                                                          ),
                                                        ]),
                                                      ])
                                                    : _c("div", [
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Inclusive Treatments"
                                                          ),
                                                        ]),
                                                      ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "enhance-item-list clearfix",
                                                    },
                                                    _vm._l(
                                                      folio.InclusiveTreatments
                                                        .Inclusives,
                                                      function (treatment, k) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: k,
                                                            staticClass:
                                                              "clearfix",
                                                          },
                                                          [
                                                            treatment
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        treatment.Details
                                                                      ) +
                                                                      "."
                                                                  ),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "rsdatetimeshort"
                                                                        )(
                                                                          treatment.StartDate
                                                                        )
                                                                      ) +
                                                                      ".\n                                                "
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ffs fwb text-uppercase mr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Treatment " +
                                                                          _vm._s(
                                                                            k +
                                                                              1
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  folio.HasUpsells
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Inclusive treatment"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "No treatment added"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("h3", [
                                            folio.InclusiveTreatments
                                              ? _c("span", [
                                                  _vm._v("Additional "),
                                                ])
                                              : _vm._e(),
                                            _vm._v("Treatments"),
                                          ]),
                                          _vm._v(" "),
                                          folio.Treatments.length
                                            ? _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "enhance-item-list",
                                                },
                                                _vm._l(
                                                  folio.Treatments,
                                                  function (treatment, k) {
                                                    return _c(
                                                      "li",
                                                      { key: k },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            treatment.Details
                                                          ) + "."
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "rsdatetimeshort"
                                                              )(
                                                                treatment.StartDate
                                                              )
                                                            ) +
                                                            ". £" +
                                                            _vm._s(
                                                              treatment.Amount
                                                            ) +
                                                            "."
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "enhance-item-list",
                                                },
                                                [
                                                  _c("li", [
                                                    _vm._v(
                                                      "No treatment added"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "booking-list-item__extras",
                                    },
                                    [
                                      folio.hotelOnly
                                        ? _c("div", [
                                            _c("h3", [_vm._v("Spa access?")]),
                                            _vm._v(" "),
                                            folio.spaAccess
                                              ? _c("div", [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        folio.spaAccess.Qty
                                                      ) +
                                                        " x Spa Access - £" +
                                                        _vm._s(
                                                          folio.spaAccess
                                                            .TotalWithSurcharges
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeSpaAccess(
                                                            folio.spaAccess
                                                              .PMSFolioSpecialServiceId,
                                                            folio.FolioId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Remove")]
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _c("p", [
                                                    _vm._v(
                                                      "No Spa Access added."
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.ammendBooking()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Amend booking")]
                                                  ),
                                                ]),
                                          ])
                                        : _c("div", [
                                            _c("h3", [
                                              _vm._v("Finishing Touches"),
                                            ]),
                                            _vm._v(" "),
                                            folio.Cabanas
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "enhance-item-list",
                                                  },
                                                  _vm._l(
                                                    folio.Cabanas,
                                                    function (cabana, ck) {
                                                      return _c("li", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              cabana.Details
                                                            )
                                                          ),
                                                        ]),
                                                        _c("br"),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "small",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "rsnicedate"
                                                                )(
                                                                  cabana.StartDate
                                                                )
                                                              ) +
                                                                ", " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "rstime"
                                                                  )(
                                                                    cabana.StartDate
                                                                  )
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "rstime"
                                                                  )(
                                                                    cabana.FinishDate
                                                                  )
                                                                ) +
                                                                ". £" +
                                                                _vm._s(
                                                                  cabana.Amount
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            folio.extras
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "enhance-item-list",
                                                  },
                                                  _vm._l(
                                                    folio.extras.specialServices
                                                      .PMSFolioSpecialService,
                                                    function (extra, k) {
                                                      return _c(
                                                        "li",
                                                        { key: k },
                                                        [
                                                          extra.Qty != 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    extra.Qty
                                                                  ) + " x "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                extra
                                                                  .PMSSpecialService
                                                                  .PMSSpecialServiceName
                                                              ) +
                                                              " \n                                                "
                                                          ),
                                                          extra.TotalWithSurcharges !=
                                                          "0.00"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "£" +
                                                                    _vm._s(
                                                                      extra.TotalWithSurcharges
                                                                    )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "enhance-item-list",
                                                  },
                                                  [
                                                    _c("li", [
                                                      _vm._v(
                                                        "No finishing touches added"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                          ]),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : cat === "Gift Cards x"
                      ? _c("div", [
                          _c("h3", [_vm._v(_vm._s(cat))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "booking-list" },
                            _vm._l(folioItem, function (item) {
                              return _c(
                                "div",
                                { staticClass: "booking-list-item" },
                                [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("h5", { staticClass: "card-title" }, [
                                      _vm._v(_vm._s(item.Details)),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "ffs small mb-0" }, [
                                      _vm._v(
                                        "Recipient: " +
                                          _vm._s(item.CustomerName)
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 text-right" },
                                    [
                                      _c("h5", { staticClass: "cart-price" }, [
                                        _vm._v("£" + _vm._s(item.FolioTotal)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "cartSummaryRemove",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeGiftCard(
                                                item.FolioId,
                                                item.FolioItemId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Remove")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : cat === "Treatments"
                      ? _c("div", [
                          _c("h3", [_vm._v(_vm._s(cat))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "booking-list" },
                            _vm._l(folioItem, function (item) {
                              return _c(
                                "div",
                                { staticClass: "booking-list-item" },
                                [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("h5", { staticClass: "card-title" }, [
                                      _vm._v(_vm._s(item.Details)),
                                    ]),
                                    _vm._v(" "),
                                    _c("h6", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("rsdatetimeshort")(
                                            item.StartDate
                                          )
                                        ) +
                                          " - £" +
                                          _vm._s(item.Amount)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "cartSummaryRemove",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.removeClass(item)
                                          },
                                        },
                                      },
                                      [_vm._v("Remove")]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.classBookings
            ? _c("div", { staticClass: "mt-5" }, [
                _c("h3", { staticClass: "mb-3" }, [_vm._v("Class Bookings")]),
                _vm._v(" "),
                _vm.classBookings
                  ? _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table table-members" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.classBookings, function (day) {
                            return _c("tr", [
                              _c("td", [_vm._v(_vm._s(day.Details))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("rsdatetimeshort")(day.StartDate)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                day.Amount == "0.00"
                                  ? _c("span", [_vm._v("--")])
                                  : _c("span", [
                                      _vm._v("£" + _vm._s(day.Amount)),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-secondary btn-sm mb-0",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeClass(day)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Remove\n                                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cart.FolioBalance
            ? _c("div", { staticClass: "cart-page-actions mt-5 mb-5" }, [
                _vm.cart.inclusivesBooked
                  ? _c("div", [
                      _vm.system.isLoggedIn
                        ? _c("div", [
                            _vm.insuranceTotal != 0
                              ? _c("div", [
                                  !_vm.insuranceAdded
                                    ? _c("div", { staticClass: "mb-3" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-wide",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.insurancePopup()
                                              },
                                            },
                                          },
                                          [_vm._v("Pay now")]
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        { staticClass: "mb-3" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "btn btn-secondary btn-wide",
                                              attrs: { to: "/book/checkout" },
                                            },
                                            [_vm._v("Pay Now")]
                                          ),
                                        ],
                                        1
                                      ),
                                ])
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn btn-secondary btn-wide",
                                        attrs: { to: "/book/checkout" },
                                      },
                                      [_vm._v("Pay Now")]
                                    ),
                                  ],
                                  1
                                ),
                          ])
                        : _c("div", { staticClass: "d-md-flex" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary flex-fill mr-2",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showLogin("login")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Login to account\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary flex-fill",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showLogin("register")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Checkout\n                        "
                                ),
                              ]
                            ),
                          ]),
                    ])
                  : _c("div", [
                      _c("p", { staticClass: "text-danger" }, [
                        _vm._v(
                          "Your selected package includes treatments, but you haven't booked a time. Please select your time before proceeding."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.ammendBooking()
                            },
                          },
                        },
                        [_vm._v("Book my treatments")]
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(2),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "booking-app-sideout login-selector" }, [
      _c("div", { staticClass: "sideout-wrapper" }, [
        _c("div", { staticClass: "sideout-container" }, [
          _c("div", { staticClass: "sideout-content" }, [
            _c("div", { staticClass: "package-header" }, [
              _c(
                "a",
                {
                  staticClass: "prevNextArrow arrowLeft",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeSideout()
                    },
                  },
                },
                [_vm._v(" ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "package-content" }, [
              _c(
                "div",
                { staticClass: "room-full-description" },
                [
                  _c("login-register", {
                    attrs: {
                      system: _vm.system,
                      cart: _vm.cart,
                      type: _vm.type,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "flex-modal", "aria-hidden": "true" },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._v("\n                     \n                "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("h5", { staticClass: "brandHeading text-center mb-4" }, [
                  _vm._v(
                    "Flex your booking for £" + _vm._s(_vm.insuranceTotal)
                  ),
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 text-center" }, [
                  _vm._v(
                    "\n                        Please note, ‘Flex’ can only be purchased at the time of booking and is non-refundable.To view the full terms and conditions surrounding ‘Flex’ and how this compares to our standard terms and conditions, please click "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/" + _vm.locUrl + "/terms-conditions",
                        target: "_blank",
                      },
                    },
                    [_vm._v("here")]
                  ),
                  _vm._v(".\n                    "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill mr-2",
                      attrs: { "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addInsurance()
                        },
                      },
                    },
                    [_vm._v("Yes please")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill ml-2",
                      attrs: { "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.moveToCheckout()
                        },
                      },
                    },
                    [_vm._v("No thanks")]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "guest-names-modal", "aria-hidden": "true" },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("h5", { staticClass: "brandHeading text-center mb-4" }, [
                  _vm._v("It's all about you"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 text-center" }, [
                  _vm._v(
                    "\n                        Looking to speed up the checking-in process, whilst also banking some additional moments in the spa? Then let us know your guest names below, enabling us to create your individual itineraries in advance.\n                    "
                  ),
                ]),
                _vm._v(" "),
                _vm.cart.WebFolioItem
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.addGuestNames.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._l(_vm.cart.WebFolioItem, function (folio, fk) {
                          return _c("div", { key: fk, staticClass: "mb-3" }, [
                            _vm.guestNamesForm[folio.FolioId]
                              ? _c(
                                  "div",
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(folio.GuestNames.type) +
                                          " " +
                                          _vm._s(fk + 1)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      folio.GuestNames.guests,
                                      function (guest, gk) {
                                        return _c(
                                          "div",
                                          { key: gk, staticClass: "form-row" },
                                          [
                                            guest
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(guest) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col",
                                                  },
                                                  [
                                                    folio.GuestNames.type ==
                                                    "Room"
                                                      ? _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .guestNamesForm[
                                                                  folio.FolioId
                                                                ].guests[gk],
                                                              expression:
                                                                "guestNamesForm[folio.FolioId].guests[gk]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                            id:
                                                              "f" +
                                                              folio.FolioId +
                                                              "-" +
                                                              gk,
                                                            placeholder:
                                                              "Guest " +
                                                              (+gk + 1) +
                                                              " Full name",
                                                            required: "",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm
                                                                .guestNamesForm[
                                                                folio.FolioId
                                                              ].guests[gk],
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm
                                                                  .guestNamesForm[
                                                                  folio.FolioId
                                                                ].guests,
                                                                gk,
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .guestNamesForm[
                                                                  folio.FolioId
                                                                ].guests[gk],
                                                              expression:
                                                                "guestNamesForm[folio.FolioId].guests[gk]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "text",
                                                            id:
                                                              "f" +
                                                              folio.FolioId +
                                                              "-" +
                                                              gk,
                                                            placeholder:
                                                              "Guest " +
                                                              (+fk + 1) +
                                                              " Full name",
                                                            required: "",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm
                                                                .guestNamesForm[
                                                                folio.FolioId
                                                              ].guests[gk],
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm
                                                                  .guestNamesForm[
                                                                  folio.FolioId
                                                                ].guests,
                                                                gk,
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                  ]
                                                ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ])
                        }),
                        _vm._v(" "),
                        _vm._m(5),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "alert alert-warning alert-dismissible fade show",
        attrs: { role: "alert" },
      },
      [
        _c("strong", [_vm._v("Payment unsuccessful")]),
        _vm._v(
          " There was an issue processing your payment. Please try again or contact our reservations team on 01283 576522.\n                "
        ),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "alert",
              "aria-label": "Close",
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", [_vm._v("Item")]),
        _vm._v(" "),
        _c("th", [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Additional Cost")]),
        _vm._v(" "),
        _c("th", [_vm._v("Changed your mind?")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center mt-4" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "gc-box" }, [
          _c("h5", [_vm._v("Redeeming a Gift Card or E-voucher?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Did you know, all of our gift vouchers hold a monetary value and can be redeemed against any package. Simply select your chosen package, then enter your voucher code at checkout to redeem."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3 text-center" }, [
      _c("strong", [
        _vm._v(
          "Life is full of surprises, so why not protect your reservation by purchasing our recommended ‘Flex’ enhancement?"
        ),
      ]),
      _vm._v(
        " Offering the peace of mind to change your booking any time, or receive a full refund until 48 hours before arrival, with no questions asked or additional fees required.\n                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-primary btn-block", attrs: { type: "submit" } },
        [_vm._v("Add to booking")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }