<template>
    <div>
        <div v-if="status">
            <p>Head over to your email inbox where your temporary password will be waiting. (Please allow up to 5 minutes for it to arrive)</p>
            <a class="btn btn-hch" href="/hoar-cross-hall/members-portal">Hoar Cross Hall Members</a>
            <a class="btn btn-eh" href="/eden-hall/members-portal">Eden Hall Members</a>
        </div>
        <div v-else>
            <p>To get started, enter the email address associated with your account.</p>
            <div class="d-flex justify-content-center">
                <form @submit.prevent="resetPassword" class="form-inline">
                    <div class="form-row justify-content-center">
                        <div class="form-group col">
                            <!-- <label for="resetEmail">Your email address</label> -->
                            <input v-model="email" type="email" class="form-control" id="resetEmail" aria-describedby="emailHelp" placeholder="Enter email" required>
                            <button type="submit" class="btn btn-secondary mb-0">Reset Password</button>
                        </div>
                    </div>
                </form>
            </div>
            <span v-if="loading"><div class="spinner-grow spinner-grow-sm" role="status"></div> Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            
        },
        /**
         * The components data
         */
        data() {
            return {
                email: null,
                loading: null,
                status: false,
            }
        },

        mounted() {
            
        },

        /**
         * Methods
         */
        methods: {
            resetPassword() {
                this.loading = true
                var request = {
                    method: 'ResetPasswordCustomer',
                    data: { 
                        EmailAddress: this.email,
                        PasswordGUID: null,
                        NewPassword: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        this.loading = false
                        this.status = true
                    });
            },
        }
    }
</script>
