var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.success
      ? _c("div", [
          _vm.success
            ? _c("h4", [
                _vm._v(
                  "Thank you for your interest. Download your brochure now."
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-reversed",
              attrs: { href: "/media/download/" + _vm.download },
            },
            [_vm._v("Download Brochure")]
          ),
        ])
      : _c("div", [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.enquire.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-group row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.firstName,
                        expression: "formData.firstName",
                      },
                    ],
                    ref: "firstName",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "First name",
                      required: "",
                    },
                    domProps: { value: _vm.formData.firstName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "firstName", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.lastName,
                        expression: "formData.lastName",
                      },
                    ],
                    ref: "lastName",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Last name",
                      required: "",
                    },
                    domProps: { value: _vm.formData.lastName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "lastName", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.emailAddress,
                        expression: "formData.emailAddress",
                      },
                    ],
                    ref: "emailAddress",
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      placeholder: "Email",
                      required: "",
                    },
                    domProps: { value: _vm.formData.emailAddress },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.formData,
                          "emailAddress",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-reversed float-right",
                      attrs: { type: "submit", disabled: _vm.sending },
                    },
                    [_vm._v("Download")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label float-left",
                        attrs: { for: "marketing" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.marketing,
                              expression: "formData.marketing",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "marketing" },
                          domProps: {
                            checked: Array.isArray(_vm.formData.marketing)
                              ? _vm._i(_vm.formData.marketing, null) > -1
                              : _vm.formData.marketing,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.formData.marketing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "marketing",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "marketing",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formData, "marketing", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.consent_message) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }