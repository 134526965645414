<template>
    <div class="modal-message">
        <div class="modal-message-container">
            <h2><span v-html="showmodal.titleOne"></span><br /> {{ showmodal.titleTwo }}</h2>
            <p>{{ showmodal.message }}</p>
            <a v-if="showmodal.btnUrl" :href="showmodal.btnUrl" class="btn btn-primary">{{ showmodal.btn }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            showmodal: Object
        },

        components: {
            
        },

        data() {
            return {
                
            }
        },
        
        mounted() {
            
            
        },

        watch: {
            
        },

        methods: {

        },
    }
</script>
