var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$route.path != "/book/success"
    ? _c(
        "div",
        {
          staticClass: "icons cart-header",
          class: { "is-changed": _vm.hasChanged },
        },
        [
          _c(
            "a",
            {
              attrs: { href: "#", id: "cart" },
              on: {
                click: function ($event) {
                  return _vm.toggleMenu()
                },
              },
            },
            [
              _c("i", { staticClass: "icon-cart" }),
              _vm._v(" "),
              _vm.cart.WebFolioItem !== null &&
              _vm.cart.WebFolioItem !== undefined &&
              _vm.cart.WebFolioItem.length > 0
                ? _c("span", { staticClass: "badge badge-danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.cart.WebFolioItem.length) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.isOpen
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: () => (_vm.isOpen = false),
                      expression: "() => isOpen = false",
                    },
                  ],
                  staticClass: "shopping-cart",
                },
                [
                  _c("div", { staticClass: "shopping-cart-header" }, [
                    _c("h2", [_vm._v("Cart")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "shopping-cart-total" }, [
                      _c("span", { staticClass: "main-color-text" }, [
                        _vm._v("£" + _vm._s(_vm.cart.FolioBalance || 0)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "shopping-cart__body" },
                    [
                      _vm.cart.WebFolioItem === null ||
                      _vm.cart.WebFolioItem === undefined ||
                      _vm.cart.WebFolioItem.length === 0
                        ? _c("div", [_vm._m(0)])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(
                        _vm.folioByCategory(),
                        function (folioItem, category) {
                          return _vm.cart.WebFolioItem !== null &&
                            _vm.cart.WebFolioItem !== undefined &&
                            _vm.cart.WebFolioItem.length > 0
                            ? _c("div", [
                                _c(
                                  "h2",
                                  { staticClass: "shopping-cart__category" },
                                  [_vm._v(_vm._s(category))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "shopping-cart-items" },
                                  _vm._l(folioItem, function (item, key) {
                                    return _c(
                                      "li",
                                      { key: key, staticClass: "clearfix" },
                                      [
                                        category == "Your Visit"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "item-headers" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-location",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.Location)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("shortdate")(
                                                            item.StartDate
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-details" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            item.Details
                                                              ? item.Details
                                                              : item.RateDetails
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                      item.twinSelected
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "(Twin room)"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.doubleSelected
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "(Double room)"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.Type == "Stay"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-guests",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    height:
                                                                      "1em",
                                                                    viewBox:
                                                                      "0 0 512 512",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                                        " +
                                                                  _vm._s(
                                                                    item.Adults
                                                                  ) +
                                                                  " Guests - " +
                                                                  _vm._s(
                                                                    item.RateDetails
                                                                  ) +
                                                                  "\n                                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.Type == "Spa Day"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-guests",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    height:
                                                                      "1em",
                                                                    viewBox:
                                                                      "0 0 512 512",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                                        " +
                                                                  _vm._s(
                                                                    item.Adults
                                                                  ) +
                                                                  " Guests - " +
                                                                  _vm._s(
                                                                    item.RateDetails
                                                                  ) +
                                                                  "\n                                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£" +
                                                          _vm._s(
                                                            (+item.Amount).toFixed(
                                                              2
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-danger",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeItem(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            height: "1em",
                                                            viewBox:
                                                              "0 0 384 512",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only sr-only-focusable",
                                                        },
                                                        [_vm._v("Remove")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !item.HotelOnly &&
                                              item.InclusiveTreatments &&
                                              item.InclusiveTreatments
                                                .Inclusives[0] !== null
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inclusive-items",
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          "Inclusive Treatments "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item
                                                                  .InclusiveTreatments
                                                                  .Inclusives
                                                                  .length
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          item
                                                            .InclusiveTreatments
                                                            .Inclusives,
                                                          function (
                                                            treatment,
                                                            index
                                                          ) {
                                                            return treatment
                                                              ? _c(
                                                                  "li",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                        " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "str_limit"
                                                                          )(
                                                                            treatment.Details,
                                                                            35
                                                                          )
                                                                        ) +
                                                                        "\n\n                                        "
                                                                    ),
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-danger",
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.removeTreatment(
                                                                                treatment
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "svg",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xmlns:
                                                                                  "http://www.w3.org/2000/svg",
                                                                                height:
                                                                                  "1em",
                                                                                viewBox:
                                                                                  "0 0 384 512",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "path",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "sr-only sr-only-focusable",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Remove"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !item.HotelOnly &&
                                              item.Treatments.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inclusive-items",
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          "Additional Treatments "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.Treatments
                                                                  .length
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          item.Treatments,
                                                          function (
                                                            treatment,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "li",
                                                              { key: index },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "treatment-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "str_limit"
                                                                          )(
                                                                            treatment.Details,
                                                                            35
                                                                          )
                                                                        ) +
                                                                        "\n                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "treatment-price",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            £" +
                                                                        _vm._s(
                                                                          treatment.Amount
                                                                        ) +
                                                                        "\n                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-danger",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.removeTreatment(
                                                                            treatment
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        attrs: {
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg",
                                                                          height:
                                                                            "1em",
                                                                          viewBox:
                                                                            "0 0 384 512",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "sr-only sr-only-focusable",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Remove"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.extras &&
                                              item.extras.specialServices &&
                                              item.extras.specialServices
                                                .PMSFolioSpecialService.length >
                                                0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inclusive-items",
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          "Finishing Touches "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.extras
                                                                  .specialServices
                                                                  .PMSFolioSpecialService
                                                                  .length
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          item.extras
                                                            .specialServices
                                                            .PMSFolioSpecialService,
                                                          function (
                                                            specialService,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "li",
                                                              { key: index },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "treatment-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "str_limit"
                                                                          )(
                                                                            specialService
                                                                              .PMSSpecialService
                                                                              .PMSSpecialServiceName,
                                                                            45
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    specialService.Qty >
                                                                    1
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "x " +
                                                                                _vm._s(
                                                                                  specialService.Qty
                                                                                )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "treatment-price",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            £" +
                                                                        _vm._s(
                                                                          specialService.TotalWithSurcharges
                                                                        ) +
                                                                        " \n                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-danger",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.removeExtras(
                                                                            specialService.PMSFolioSpecialServiceId,
                                                                            item
                                                                              .extras
                                                                              .specialServices
                                                                              .PMSFolioId
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        attrs: {
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg",
                                                                          height:
                                                                            "1em",
                                                                          viewBox:
                                                                            "0 0 384 512",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "sr-only sr-only-focusable",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Remove"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        category == "Treatments"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "item-headers" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-location",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.Location)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("shortdate")(
                                                            item.StartDate
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-details" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "treatment_title"
                                                            )(item.Details)
                                                          ) +
                                                          "\n\n                                    "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-guests",
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                                height: "1em",
                                                                viewBox:
                                                                  "0 0 512 512",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.calculateTimeDifference(
                                                                  item.StartDate,
                                                                  item.FinishDate
                                                                )
                                                              ) +
                                                              " minutes\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£" +
                                                          _vm._s(
                                                            (+item.Amount).toFixed(
                                                              2
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-danger",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeTreatment(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            height: "1em",
                                                            viewBox:
                                                              "0 0 384 512",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only sr-only-focusable",
                                                        },
                                                        [_vm._v("Remove")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        category == "Retail"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "item-details" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "giftcard_title"
                                                            )(item.Details)
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£" +
                                                          _vm._s(
                                                            (+item.FolioTotal).toFixed(
                                                              2
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-danger",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeGiftCard(
                                                            item.FolioId,
                                                            item.FolioItemId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            height: "1em",
                                                            viewBox:
                                                              "0 0 384 512",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only sr-only-focusable",
                                                        },
                                                        [_vm._v("Remove")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ])
                            : _vm._e()
                        }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-block m-0",
                      class: {
                        disabled:
                          _vm.cart.WebFolioItem === null ||
                          _vm.cart.WebFolioItem === undefined ||
                          _vm.cart.WebFolioItem.length === 0,
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.continueBooking()
                        },
                      },
                    },
                    [_vm._v("\n            Complete Booking\n        ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "shopping-cart__empty" }, [
      _c("p", [_vm._v("Your cart is empty")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }