var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { attrs: { id: "treatment-availability-tabs" } }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab-content", attrs: { id: "nav-tabContent" } },
            [
              _c(
                "div",
                {
                  staticClass: "tab-pane fade show active",
                  attrs: {
                    id: "nav-today",
                    role: "tabpanel",
                    "aria-labelledby": "nav-today-tab",
                  },
                },
                [
                  _vm.today
                    ? _c(
                        "div",
                        [
                          _c(
                            "transition-group",
                            { attrs: { tag: "ul", name: "fade-out-in" } },
                            _vm._l(_vm.today, function (treatment) {
                              return _c(
                                "li",
                                { key: treatment.id, staticClass: "alt-bg" },
                                [
                                  _c("h5", { staticClass: "treatment-name" }, [
                                    _vm._v(
                                      _vm._s(treatment.name) +
                                        " \n                                        "
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "treatment-price" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(treatment.service_time) +
                                              " mins"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("£" + _vm._s(treatment.price)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  treatment.times
                                    ? _c(
                                        "div",
                                        { staticClass: "times-container" },
                                        _vm._l(
                                          treatment.times,
                                          function (time) {
                                            return _c(
                                              "span",
                                              { staticClass: "time-item" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("nicetime")(time)
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "availability-cta" }, [
                            _vm._v(
                              "\n                                To book your treatment, please visit our Spa reception\n                            "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "flex-container align-items-center" },
                        [
                          _c("div", { staticClass: "flex-item text-center" }, [
                            _vm.today == null || _vm.today.length == 0
                              ? _c("div", [
                                  _c("h3", [
                                    _vm._v("Sorry, no availability today"),
                                  ]),
                                ])
                              : _c("div", [_vm._m(1)]),
                          ]),
                        ]
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane fade",
                  attrs: {
                    id: "nav-tomorrow",
                    role: "tabpanel",
                    "aria-labelledby": "nav-tomorrow-tab",
                  },
                },
                [
                  _vm.tomorrow
                    ? _c(
                        "div",
                        [
                          _c(
                            "transition-group",
                            { attrs: { tag: "ul", name: "fade-out-in" } },
                            _vm._l(_vm.tomorrow, function (treatment) {
                              return _c(
                                "li",
                                { key: treatment.id, staticClass: "alt-bg" },
                                [
                                  _c("h5", { staticClass: "treatment-name" }, [
                                    _vm._v(
                                      _vm._s(treatment.name) +
                                        " \n                                        "
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "treatment-price" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(treatment.service_time) +
                                              " mins"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("£" + _vm._s(treatment.price)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  treatment.times
                                    ? _c(
                                        "div",
                                        { staticClass: "times-container" },
                                        _vm._l(
                                          treatment.times,
                                          function (time) {
                                            return _c(
                                              "span",
                                              { staticClass: "time-item" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("nicetime")(time)
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "availability-cta" }, [
                            _vm._v(
                              "\n                                To book your treatment, please visit our Spa reception\n                            "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "flex-container align-items-center" },
                        [
                          _c("div", { staticClass: "flex-item text-center" }, [
                            _vm.noClassAvailability
                              ? _c("div", [
                                  _c("h3", [
                                    _vm._v("Sorry, no availability today"),
                                  ]),
                                ])
                              : _c("div", [_vm._m(2)]),
                          ]),
                        ]
                      ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("nav", [
      _c(
        "div",
        {
          staticClass: "nav nav-tabs",
          attrs: { id: "nav-tab", role: "tablist" },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-item nav-link active",
              attrs: {
                id: "nav-today-tab",
                "data-toggle": "tab",
                href: "#nav-today",
                role: "tab",
                "aria-controls": "nav-today",
                "aria-selected": "true",
              },
            },
            [_vm._v("Today")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-item nav-link",
              attrs: {
                id: "nav-tomorrow-tab",
                "data-toggle": "tab",
                href: "#nav-tomorrow",
                role: "tab",
                "aria-controls": "nav-tomorrow",
                "aria-selected": "false",
              },
            },
            [_vm._v("Tomorrow")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", {
        staticClass: "spinner-grow spinner-grow-md",
        attrs: { role: "status" },
      }),
      _vm._v(
        "\n                                        Loading treatments...\n                                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", {
        staticClass: "spinner-grow spinner-grow-md",
        attrs: { role: "status" },
      }),
      _vm._v(
        "\n                                        Loading treatments...\n                                    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }