var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-enhance" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-12" },
        [
          _c("progress-indicator", { attrs: { step: _vm.step } }),
          _vm._v(" "),
          _c("div", { staticClass: "booking-info" }, [
            _vm.type == "hotel-stay"
              ? _c("h1", { staticClass: "brandHeading" }, [
                  _vm._v("\n                    Spa Access\n                "),
                ])
              : _c("h1", { staticClass: "brandHeading" }, [
                  _vm._v("Treatments"),
                ]),
            _vm._v(" "),
            _c("h4", { staticClass: "hide-mobile" }, [
              _vm._v(_vm._s(_vm._f("nicedate")(_vm.pageDate))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "message-small" }, [
              _c(
                "a",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addPackage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Back a step")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.restartBooking(true)
                    },
                  },
                },
                [_vm._v("Restart booking")]
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.cart.inclusivesBooked
            ? _c("div", { staticClass: "alert alert-warning" }, [
                _c("p", { staticClass: "mb-0 text-center" }, [
                  _vm._v(
                    "\n                    Please book all inclusive treatment times before\n                    proceeding.\n                "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "booking-list" },
            _vm._l(_vm.filteredCart, function (folio, k) {
              return _c("div", { key: k }, [
                _c("div", { staticClass: "booking-list-item" }, [
                  _c("div", { staticClass: "booking-list-item__details" }, [
                    _c("div", { staticClass: "hide-mobile" }, [
                      _c("h3", [
                        folio.Type == "Stay"
                          ? _c("span", [_vm._v("Room")])
                          : _c("span", [_vm._v("Guest")]),
                        _vm._v(
                          " " +
                            _vm._s(k + 1) +
                            "\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("Location: " + _vm._s(folio.Location))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                                    Date: " +
                            _vm._s(_vm._f("rsnicedate")(folio.StartDate)) +
                            "\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("Experience: " + _vm._s(folio.RateDetails)),
                      ]),
                      _vm._v(" "),
                      folio.Type == "Stay"
                        ? _c("p", [
                            _vm._v(
                              "\n                                    Guests: " +
                                _vm._s(folio.Adults) +
                                "\n                                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      folio.Type == "Stay"
                        ? _c("p", [
                            _c("strong", [_vm._v("Room type:")]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(folio.Details) +
                                "\n                                    "
                            ),
                            folio.twinSelected
                              ? _c("span", [_vm._v("(Twin room)")])
                              : _vm._e(),
                            _vm._v(" "),
                            folio.doubleSelected
                              ? _c("span", [_vm._v("(Double room)")])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", { staticClass: "details-price" }, [
                        _vm._v(
                          "\n                                    Cost: £" +
                            _vm._s((+folio.Amount).toFixed(2)) +
                            "\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      folio.promotionApplied
                        ? _c("p", { staticClass: "small" }, [
                            _vm._v(
                              "\n                                    (" +
                                _vm._s(folio.promotionApplied) +
                                " promotion\n                                    applied)\n                                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      folio.RateType == "R3FOR2"
                        ? _c("p", { staticClass: "small" }, [
                            _vm._v(
                              "\n                                    *Add code at checkout\n                                "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "show-mobile" }, [
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-heading heading clearfix",
                            attrs: {
                              "data-toggle": "collapse",
                              "data-target": "#packageDetails",
                              "aria-expanded": "false",
                              "aria-controls": "packageDetails",
                            },
                          },
                          [
                            _c("h3", [
                              folio.Type == "Stay"
                                ? _c("span", [_vm._v("Room")])
                                : _c("span", [_vm._v("Guest")]),
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(k + 1) +
                                  "\n                                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("i", { staticClass: "arrow" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-container collapse",
                            attrs: { id: "packageDetails" },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "\n                                            Location: " +
                                  _vm._s(folio.Location) +
                                  "\n                                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                                            Date:\n                                            " +
                                  _vm._s(
                                    _vm._f("rsnicedate")(folio.StartDate)
                                  ) +
                                  "\n                                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                                            Experience:\n                                            " +
                                  _vm._s(folio.RateDetails) +
                                  "\n                                        "
                              ),
                            ]),
                            _vm._v(" "),
                            folio.Type == "Stay"
                              ? _c("p", [
                                  _vm._v(
                                    "\n                                            Guests:\n                                            " +
                                      _vm._s(_vm.getRoomGuests(folio.FolioId)) +
                                      "\n                                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            folio.Type == "Stay"
                              ? _c("p", [
                                  _c("strong", [_vm._v("Room type:")]),
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(folio.Details) +
                                      "\n                                            "
                                  ),
                                  folio.twinSelected
                                    ? _c("span", [_vm._v("(Twin room)")])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "details-price" }, [
                              _vm._v(
                                "\n                                            Cost: £" +
                                  _vm._s((+folio.Amount).toFixed(2)) +
                                  "\n                                        "
                              ),
                            ]),
                            _vm._v(" "),
                            folio.advancedBooking
                              ? _c("p", { staticClass: "small" }, [
                                  _vm._v(
                                    "\n                                            (" +
                                      _vm._s(folio.advancedBooking) +
                                      "%\n                                            discount applied)\n                                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            folio.RateType == "R3FOR2"
                              ? _c("p", { staticClass: "small" }, [
                                  _vm._v(
                                    "\n                                            *Add code at checkout\n                                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "message-small" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "mr-2",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeItem(folio)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "Remove\n                                                "
                                  ),
                                  folio.Type == "Stay"
                                    ? _c("span", [_vm._v("room")])
                                    : _c("span", [_vm._v("package")]),
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(k + 1)
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "booking-list-item__treatments" }, [
                    _c("div", { staticClass: "inclusive-list" }, [
                      _vm.type != "hotel-stay"
                        ? _c("div", [
                            _c("h3", [_vm._v("Inclusive Treatments")]),
                            _vm._v(" "),
                            folio.InclusiveTreatments
                              ? _c("div", [
                                  folio.Type == "Spa Day"
                                    ? _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                                Please book your inclusive\n                                                treatments and times before\n                                                proceeding to the next step.\n                                            "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "enhance-item-list clearfix",
                                          },
                                          _vm._l(
                                            folio.InclusiveTreatments
                                              .Inclusives,
                                            function (treatment, tk) {
                                              return _c(
                                                "li",
                                                {
                                                  key: tk,
                                                  staticClass: "clearfix",
                                                },
                                                [
                                                  treatment
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              treatment.Details
                                                            ) +
                                                            "."
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "rsdatetimeshort"
                                                              )(
                                                                treatment.StartDate
                                                              )
                                                            ) +
                                                            ".\n                                                        "
                                                        ),
                                                        treatment.Amount !=
                                                        "0.00"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "(£" +
                                                                  _vm._s(
                                                                    treatment.Amount
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.removeTreatment(
                                                                  treatment
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.removing ==
                                                            treatment.FolioItemId
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "spinner-border spinner-border-sm",
                                                                    attrs: {
                                                                      role: "status",
                                                                    },
                                                                  },
                                                                  [_vm._v(" ")]
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "Remove"
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary btn-block mb-1",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.addInclusive(
                                                                  folio,
                                                                  folio.InclusiveTreatments,
                                                                  k + 1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Select treatment\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                                Please book your inclusive\n                                                treatments and times before\n                                                proceeding to the next step.\n                                            "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "enhance-item-list clearfix",
                                          },
                                          _vm._l(
                                            folio.InclusiveTreatments
                                              .Inclusives,
                                            function (treatment, tk) {
                                              return _c(
                                                "li",
                                                {
                                                  key: tk,
                                                  staticClass: "clearfix",
                                                },
                                                [
                                                  treatment
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              treatment.Details
                                                            ) +
                                                            "."
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "rsdatetimeshort"
                                                              )(
                                                                treatment.StartDate
                                                              )
                                                            ) +
                                                            ".\n                                                        "
                                                        ),
                                                        treatment.Amount !=
                                                        "0.00"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "(£" +
                                                                  _vm._s(
                                                                    treatment.Amount
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.removeTreatment(
                                                                  treatment
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.removing ==
                                                            treatment.FolioItemId
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "spinner-border spinner-border-sm",
                                                                    attrs: {
                                                                      role: "status",
                                                                    },
                                                                  },
                                                                  [_vm._v(" ")]
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "Remove"
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary btn-block mb-1",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.addInclusive(
                                                                  folio,
                                                                  folio.InclusiveTreatments,
                                                                  tk + 1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Select\n                                                            "
                                                            ),
                                                            folio.Type == "Stay"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "guest\n                                                                " +
                                                                      _vm._s(
                                                                        tk + 1
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                            treatment\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                ])
                              : _c("div", [
                                  folio.RateType == "RAUTUMN2"
                                    ? _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                                Your package includes a\n                                                60-minute Full Body Couture\n                                                Touch treatment per person.\n                                                When you receive your\n                                                digital itinerary this will\n                                                show your assigned treatment\n                                                time.\n                                            "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._m(0, true),
                                        _vm._v(" "),
                                        _vm._m(1, true),
                                      ])
                                    : _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                                This package does not\n                                                include inclusive\n                                                treatments.\n                                            "
                                          ),
                                        ]),
                                      ]),
                                ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "booking-list-item__extras" }, [
                    folio.HotelOnly
                      ? _c("div", [
                          _c("h3", [_vm._v("Spa access?")]),
                          _vm._v(" "),
                          folio.spaAccess
                            ? _c("div", [
                                _c("div", [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(folio.spaAccess.Qty) +
                                      " x Spa\n                                        Access - £" +
                                      _vm._s(
                                        folio.spaAccess.TotalWithSurcharges
                                      ) +
                                      "\n                                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeSpaAccess(
                                          folio.spaAccess
                                            .PMSFolioSpecialServiceId,
                                          folio.FolioId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ])
                            : _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "\n                                        Add evening Spa Access for day of\n                                        arrival. £40pp\n                                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.isSaturday(folio.StartDate)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-block btn-blush",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.addSpaAccess(folio)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Add Spa Access\n                                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isSaturday(folio.StartDate)
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                                        Please call our Reservations Team on\n                                        "
                                      ),
                                      _c(
                                        "strong",
                                        { staticClass: "globalTel" },
                                        [_vm._v("01283 576522")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                        ])
                      : _c("div", [
                          _c("h3", [_vm._v("Additional Treatments?")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                                    Why not further enhance your spa\n                                    experience with an additional treatment?\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary btn-block btn-blush",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showTreatments(folio)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Add\n                                    "
                              ),
                              folio.Treatments.length
                                ? _c("span", [_vm._v("another treatment")])
                                : _c("span", [_vm._v("treatments")]),
                            ]
                          ),
                          _vm._v(" "),
                          folio.Treatments.length
                            ? _c(
                                "ul",
                                { staticClass: "enhance-item-list" },
                                _vm._l(
                                  folio.Treatments,
                                  function (treatment, k) {
                                    return _c("li", { key: k }, [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(treatment.Details) +
                                          "."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("rsdatetimeshort")(
                                              treatment.StartDate
                                            )
                                          ) +
                                          ". £" +
                                          _vm._s(treatment.Amount) +
                                          ".\n                                        "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeTreatment(
                                                treatment
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Remove")]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("ul", { staticClass: "enhance-item-list" }, [
                                _c("li", [
                                  _vm._v(
                                    "\n                                        Currently no additional treatments\n                                        added\n                                    "
                                  ),
                                ]),
                              ]),
                          _vm._v(" "),
                          folio.TreatmentTotal
                            ? _c("p", { staticClass: "details-price" }, [
                                _vm._v(
                                  "\n                                    Cost: £" +
                                    _vm._s((+folio.TreatmentTotal).toFixed(2)) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "message-small d-none d-md-block" }, [
                  _c(
                    "a",
                    {
                      staticClass: "mr-2",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeItem(folio)
                        },
                      },
                    },
                    [
                      _vm._v("Remove\n                            "),
                      folio.Type == "Stay"
                        ? _c("span", [_vm._v("room")])
                        : _c("span", [_vm._v("package")]),
                      _vm._v(" " + _vm._s(k + 1)),
                    ]
                  ),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.cart
            ? _c("div", { staticClass: "next-steps" }, [
                _c("div", { staticClass: "next-steps__price" }, [
                  _vm._v(
                    "\n                    Total cost: £" +
                      _vm._s(_vm.cart.FolioBalance) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _vm.type == "hotel-stay"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.payNow.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Pay Now\n                    "
                          ),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { disabled: !_vm.cart.inclusivesBooked },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.checkAdditionalTreatments()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Next Step\n                    "
                          ),
                        ]
                      ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(2),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "booking-app-sideout treatment-selector" }, [
      _c("div", { staticClass: "sideout-wrapper" }, [
        _c("div", { staticClass: "sideout-container" }, [
          _c("div", { staticClass: "sideout-content" }, [
            _c("div", { staticClass: "package-header" }, [
              _c(
                "a",
                {
                  staticClass: "prevNextArrow arrowLeft",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeSideout()
                    },
                  },
                },
                [_vm._v(" ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "alert alert-warning m-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "categoryFilters" } }, [
                  _vm._v("Filter by category"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filteredCategory,
                        expression: "filteredCategory",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "categoryFilters" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.filteredCategory = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { selected: "", value: "All" } }, [
                      _vm._v("All"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Face" } }, [
                      _vm._v("Face"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Body / Massage" } }, [
                      _vm._v(
                        "\n                                    Body / Massage\n                                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Touch" } }, [
                      _vm._v("Touch"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Hair" } }, [
                      _vm._v("Hair"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Parlour" } }, [
                      _vm._v("Parlour"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.treatments
              ? _c(
                  "div",
                  { staticClass: "package-content" },
                  _vm._l(_vm.treatments, function (tc, tkk) {
                    return _c("div", { key: tkk, staticClass: "tab-cats" }, [
                      _vm.filteredCategories(tc.list).length > 0
                        ? _c("h3", { staticClass: "mb-3" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(tc.cat.toLowerCase()) +
                                "\n                            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tab-cat-items" },
                        _vm._l(
                          _vm.filteredCategories(tc.list),
                          function (treatment, tk) {
                            return _c(
                              "div",
                              { key: tk, staticClass: "tab-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion",
                                    attrs: {
                                      id:
                                        "acc-" +
                                        tc.cat.replace(/[^a-zA-Z0-9-_]+/g, "-"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "accordion-item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "accordion-header",
                                            attrs: {
                                              id:
                                                "heading" + treatment.SpaItemId,
                                              "data-toggle": "collapse",
                                              "data-target":
                                                "#collapse" +
                                                treatment.SpaItemId,
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "collapse" +
                                                treatment.SpaItemId,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "clearfix" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "accordion-name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(treatment.ItemName)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "float-right",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "£" +
                                                          _vm._s(
                                                            _vm._f("price")(
                                                              treatment.Price
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass: "arrow",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "collapse",
                                            attrs: {
                                              id:
                                                "collapse" +
                                                treatment.SpaItemId,
                                              "aria-labelledby":
                                                "heading" + treatment.SpaItemId,
                                              "data-parent":
                                                "#acc-" +
                                                tc.cat.replace(
                                                  /[^a-zA-Z0-9-_]+/g,
                                                  "-"
                                                ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "accordion-body" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mb-3" },
                                                  [
                                                    _vm._v(
                                                      "\n                                                        " +
                                                        _vm._s(
                                                          treatment.ItemDesc
                                                        ) +
                                                        "\n                                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.selectedFolio.TreatmentDates
                                                  .length > 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "choose-date mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "date-select",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Please select\n                                                            your preferred\n                                                            date"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.treatmentDate,
                                                                expression:
                                                                  "\n                                                                treatmentDate\n                                                            ",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "custom-select custom-select-lg",
                                                            on: {
                                                              change: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal =
                                                                    Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function (
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function (
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                  _vm.treatmentDate =
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.getTreatmentTimesV2(
                                                                    treatment
                                                                  )
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.selectedFolio
                                                              .TreatmentDates,
                                                            function (
                                                              date,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: index,
                                                                  domProps: {
                                                                    value: date,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "nicedate"
                                                                        )(date)
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                treatment.TreatmentTimes
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "treatment-times",
                                                      },
                                                      [
                                                        _vm._m(3, true),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          treatment.TreatmentTimes,
                                                          function (time, ttk) {
                                                            return _c(
                                                              "button",
                                                              {
                                                                key: ttk,
                                                                staticClass:
                                                                  "btn btn-primary mr-1",
                                                                class: {
                                                                  btnSelected:
                                                                    time ==
                                                                    _vm.selectedTime,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.selectTime(
                                                                        time
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "rstime"
                                                                      )(
                                                                        time.StartTime
                                                                      )
                                                                    ) +
                                                                    "\n                                                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "clearfix",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-secondary btn-block",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.selectedTime,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.addTreatmentToCart()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                Add to\n                                                                booking\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass: "clearfix",
                                                      },
                                                      [
                                                        treatment.noAvailability
                                                          ? _c("div", [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                Sorry, no\n                                                                availability\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _c("div", [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn ersed btn-xs float-right mt-2 mb-0 mr-2",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.loading,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return _vm.getTreatmentTimesV2(
                                                                          treatment
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                Choose a\n                                                                time\n                                                                "
                                                                  ),
                                                                  _vm.loading
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "spinner-border spinner-border-sm",
                                                                          attrs:
                                                                            {
                                                                              role: "status",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "booking-app-sideout inclusive-selector" }, [
      _c("div", { staticClass: "sideout-wrapper" }, [
        _c("div", { staticClass: "sideout-container" }, [
          _c("div", { staticClass: "sideout-content" }, [
            _c("div", { staticClass: "package-header" }, [
              _c(
                "a",
                {
                  staticClass: "prevNextArrow arrowLeft",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeSideout()
                    },
                  },
                },
                [_vm._v(" ")]
              ),
            ]),
            _vm._v(" "),
            _vm.selectedInclusive
              ? _c("div", { staticClass: "package-content" }, [
                  _c("h3", { staticClass: "mt-2 mb-3" }, [
                    _vm._v(
                      "\n                            Guest\n                            "
                    ),
                    _vm.treatmentGuestNo
                      ? _c("span", [_vm._v(_vm._s(_vm.treatmentGuestNo))])
                      : _vm._e(),
                    _vm._v(
                      "\n                            Inclusive Treatment:\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedInclusive.OptionList
                    ? _c(
                        "div",
                        {
                          staticClass: "choose-date accordion mb-5",
                          attrs: { id: "acc-inc-treatment-opotion" },
                        },
                        _vm._l(
                          _vm.selectedInclusive.OptionList,
                          function (treatment, tk) {
                            return _c(
                              "div",
                              { key: tk, staticClass: "accordion-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion-header",
                                    attrs: {
                                      id: "heading" + treatment.SpaItemId,
                                      "data-toggle": "collapse",
                                      "data-target":
                                        "#collapse" + treatment.SpaItemId,
                                      "aria-expanded": "false",
                                      "aria-controls":
                                        "collapse" + treatment.SpaItemId,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "clearfix" }, [
                                      _c(
                                        "span",
                                        { staticClass: "accordion-name" },
                                        [_vm._v(_vm._s(treatment.ItemName))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _vm.selectedFolio.HasUpsells
                                            ? _c("span", [
                                                _vm._v(
                                                  "£" +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        treatment.Price
                                                      )
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("i", { staticClass: "arrow" }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "collapse",
                                    attrs: {
                                      id: "collapse" + treatment.SpaItemId,
                                      "aria-labelledby":
                                        "heading" + treatment.SpaItemId,
                                      "data-parent":
                                        "#acc-inc-treatment-opotion",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "accordion-body" },
                                      [
                                        _c("div", { staticClass: "mb-3" }, [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(treatment.ItemDesc) +
                                              "\n                                        "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.selectedFolio.TreatmentDates
                                          .length > 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "choose-date mb-3",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "date-select",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Please select your\n                                                preferred date"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.treatmentDate,
                                                        expression:
                                                          "treatmentDate",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "custom-select custom-select-lg",
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.treatmentDate =
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                        },
                                                        function ($event) {
                                                          return _vm.getTreatmentTimesV2(
                                                            treatment
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.selectedFolio
                                                      .TreatmentDates,
                                                    function (date, index) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: index,
                                                          domProps: {
                                                            value: date,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "nicedate"
                                                                )(date)
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        treatment.TreatmentTimes
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "treatment-times",
                                              },
                                              [
                                                _vm._m(4, true),
                                                _vm._v(" "),
                                                _vm._l(
                                                  treatment.TreatmentTimes,
                                                  function (time, ttk) {
                                                    return _c(
                                                      "button",
                                                      {
                                                        key: ttk,
                                                        staticClass:
                                                          "btn btn-tt mr-1",
                                                        class: {
                                                          btnSelected:
                                                            time ==
                                                            _vm.selectedTime,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.selectTime(
                                                              time
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f("rstime")(
                                                                time.StartTime
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "clearfix" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary btn-block mr-1",
                                                        attrs: {
                                                          disabled:
                                                            !_vm.selectedTime,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.addTreatmentToCart()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Add to booking\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "clearfix" },
                                              [
                                                treatment.noAvailability
                                                  ? _c("div", [
                                                      _c(
                                                        "h6",
                                                        { staticClass: "mt-2" },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Sorry, no availability.\n                                                    Please call Reservations\n                                                    to book.\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _c("div", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn ersed btn-xs float-right mt-2 mb-0 mr-2",
                                                          attrs: {
                                                            disabled:
                                                              _vm.loading,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.getTreatmentTimesV2(
                                                                treatment
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Choose a time\n                                                    "
                                                          ),
                                                          _vm.loading
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "spinner-border spinner-border-sm",
                                                                attrs: {
                                                                  role: "status",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", { staticClass: "mb-5" }, [
                        _c("div", { staticClass: "accordion-style" }, [
                          _c("h3", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.selectedInclusive.SpaItem.ItemName) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.selectedInclusive.SpaItem.ItemDesc) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.selectedFolio.TreatmentDates.length > 1
                          ? _c("div", { staticClass: "choose-date mb-3" }, [
                              _c("label", { attrs: { for: "date-select" } }, [
                                _vm._v(
                                  "Please select your preferred\n                                    date"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.treatmentDate,
                                      expression: "treatmentDate",
                                    },
                                  ],
                                  staticClass: "custom-select custom-select-lg",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.treatmentDate = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        return _vm.getTreatmentTimesV2(
                                          _vm.selectedInclusive.SpaItem
                                        )
                                      },
                                    ],
                                  },
                                },
                                _vm._l(
                                  _vm.selectedFolio.TreatmentDates,
                                  function (date, index) {
                                    return _c(
                                      "option",
                                      { key: index, domProps: { value: date } },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(_vm._f("nicedate")(date)) +
                                            "\n                                    "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedInclusive.SpaItem.TreatmentTimes
                          ? _c(
                              "div",
                              { staticClass: "treatment-times" },
                              [
                                _vm._m(5),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectedInclusive.SpaItem.TreatmentTimes,
                                  function (time, ttk) {
                                    return _c(
                                      "button",
                                      {
                                        key: ttk,
                                        staticClass: "btn btn-tt mr-1",
                                        class: {
                                          btnSelected: time == _vm.selectedTime,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.selectTime(time)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("rstime")(time.StartTime)
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary mr-1",
                                      attrs: { disabled: !_vm.selectedTime },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addTreatmentToCart()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Add to booking\n                                    "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _c("div", { staticClass: "clearfix" }, [
                              _vm.selectedInclusive.noAvailability
                                ? _c("div", [
                                    _c(
                                      "h6",
                                      { staticClass: "text-right mt-2" },
                                      [
                                        _vm._v(
                                          "\n                                        Sorry, no availability. Please call\n                                        Reservations to book.\n                                    "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn ersed btn-xs float-right mt-2 mb-0 mr-2",
                                        attrs: { disabled: _vm.loading },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getTreatmentTimesV2(
                                              _vm.selectedInclusive.SpaItem
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Choose a time\n                                        "
                                        ),
                                        _vm.loading
                                          ? _c("div", {
                                              staticClass:
                                                "spinner-border spinner-border-sm",
                                              attrs: { role: "status" },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                            ]),
                      ]),
                  _vm._v(" "),
                  _vm.selectedInclusive.UpsellList
                    ? _c("div", { staticClass: "upsell-blush" }, [
                        _c("div", { staticClass: "full-width-inner" }, [
                          !_vm.selectedFolio.HasOffers
                            ? _c("div", [
                                _c("h3", [_vm._v("Looking for an upgrade?")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                                        Why not elevate your inclusive\n                                        treatment by upgrading at a\n                                        discounted price?\n                                    "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "choose-date mb-3 accordion",
                              attrs: { id: "acc-upsell-treatment-option" },
                            },
                            _vm._l(
                              _vm.selectedInclusive.UpsellList,
                              function (upsell, tk) {
                                return _c(
                                  "div",
                                  { key: tk, staticClass: "accordion-item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "accordion-header",
                                        attrs: {
                                          id: "heading" + upsell.SpaItemId,
                                          "data-toggle": "collapse",
                                          "data-target":
                                            "#collapse" + upsell.SpaItemId,
                                          "aria-expanded": "false",
                                          "aria-controls":
                                            "collapse" + upsell.SpaItemId,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "clearfix" }, [
                                          _c(
                                            "span",
                                            { staticClass: "accordion-name" },
                                            [_vm._v(_vm._s(upsell.ItemName))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "float-right" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "£" +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        upsell.Price
                                                      )
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("i", { staticClass: "arrow" }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "collapse",
                                        attrs: {
                                          id: "collapse" + upsell.SpaItemId,
                                          "aria-labelledby":
                                            "heading" + upsell.SpaItemId,
                                          "data-parent":
                                            "#acc-upsell-treatment-option",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "accordion-body" },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(upsell.ItemDesc) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.selectedFolio.TreatmentDates
                                              .length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "choose-date mb-3",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "date-select",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Please select your\n                                                        preferred\n                                                        date"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.treatmentDate,
                                                            expression:
                                                              "\n                                                            treatmentDate\n                                                        ",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "custom-select custom-select-lg",
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.treatmentDate =
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                            },
                                                            function ($event) {
                                                              return _vm.getTreatmentTimesV2(
                                                                upsell
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectedFolio
                                                          .TreatmentDates,
                                                        function (date, index) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: index,
                                                              domProps: {
                                                                value: date,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "nicedate"
                                                                    )(date)
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            upsell.TreatmentTimes
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "treatment-times",
                                                  },
                                                  [
                                                    _vm._m(6, true),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      upsell.TreatmentTimes,
                                                      function (time, ttk) {
                                                        return _c(
                                                          "button",
                                                          {
                                                            key: ttk,
                                                            staticClass:
                                                              "btn btn-tt mr-1",
                                                            class: {
                                                              btnSelected:
                                                                time ==
                                                                _vm.selectedTime,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.selectTime(
                                                                  time
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "rstime"
                                                                  )(
                                                                    time.StartTime
                                                                  )
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "clearfix",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-secondary btn-block mr-1",
                                                            attrs: {
                                                              disabled:
                                                                !_vm.selectedTime,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.addTreatmentToCart()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Add to booking\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "clearfix" },
                                                  [
                                                    upsell.noAvailability
                                                      ? _c("div", [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Sorry, no\n                                                            availability.\n                                                            Please call\n                                                            Reservations to\n                                                            book.\n                                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _c("div", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn ersed btn-xs float-right mt-2 mb-0 mr-2",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.loading,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.getTreatmentTimesV2(
                                                                      upsell
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Choose a time\n                                                            "
                                                              ),
                                                              _vm.loading
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "spinner-border spinner-border-sm",
                                                                    attrs: {
                                                                      role: "status",
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "additional-treatment-modal", "aria-hidden": "true" },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("h5", { staticClass: "brandHeading text-center mb-4" }, [
                  _vm._v(
                    "\n                        LOOKING TO REACH A NEW LEVEL OF RELAXATION?\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 text-center" }, [
                  _vm._v(
                    "\n                        Would you like to take your spa experience to new\n                        heights, booking an additional treatment or two? We\n                        always recommend booking in advance to avoid\n                        disappointment...\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill mr-2",
                      attrs: { "data-dismiss": "modal" },
                    },
                    [
                      _vm._v(
                        "\n                            Yes please\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill ml-2",
                      attrs: { "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.nextStep()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            No thanks\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "seasonal-treatment-modal", "aria-hidden": "true" },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("h5", { staticClass: "brandHeading text-center mb-4" }, [
                  _vm._v(
                    "\n                        Save £10 on our Seasonal Treatment!\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 text-center" }, [
                  _vm._v(
                    "\n                        Why not try our limited-edition Nectar Full Body\n                        Ritual? Simply select this treatment in the next\n                        window for full description.\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill mr-2",
                      attrs: { "data-dismiss": "modal" },
                    },
                    [
                      _vm._v(
                        "\n                            No thank you\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill ml-2",
                      attrs: { "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.selectFirstTreatment()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Tell me more\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "Looking to\n                                                    upgrade?"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n                                                Call our Reservations Team\n                                                on\n                                                "
      ),
      _c("a", { attrs: { href: "tel:01283 576522", target: "_blank" } }, [
        _vm._v("01283 576522"),
      ]),
      _vm._v(
        "\n                                                after completing your\n                                                booking, to hear your\n                                                upgrade options.\n                                            "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "gc-box" }, [
          _c("h5", [_vm._v("Redeeming a Gift Card or E-voucher?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                            Did you know, all of our gift vouchers hold a\n                            monetary value and can be redeemed against any\n                            package. Simply select your chosen package, then\n                            enter your voucher code at checkout to redeem.\n                        "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("strong", [
        _vm._v(
          "Please\n                                                                select your\n                                                                preferred\n                                                                time"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("strong", [
        _vm._v(
          "Please select your\n                                                    preferred time"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("strong", [
        _vm._v(
          "Please select your preferred\n                                        time"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("strong", [
        _vm._v(
          "Please select\n                                                            your preferred\n                                                            time"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }