var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.currentVenue == "hoar-cross-hall"
      ? _c("a", { attrs: { href: "/hoar-cross-hall" } }, [
          _c("img", {
            attrs: {
              src: "/img/hch_name_only.svg",
              alt: "Hoar Cross Hall logo",
              loading: "lazy",
            },
          }),
        ])
      : _vm.currentVenue == "eden-hall"
      ? _c("a", { attrs: { href: "/eden-hall" } }, [
          _c("img", {
            attrs: {
              src: "/img/eh_name_logo.svg",
              alt: "Eden Hall logo",
              loading: "lazy",
            },
          }),
        ])
      : _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            attrs: {
              src: "/img/be_logo_bare.svg",
              alt: "Barons Eden logo",
              loading: "lazy",
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }