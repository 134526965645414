var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col-md-8 text-center" }, [
        _c("div", { staticClass: "book-start" }, [
          _c("div", { staticClass: "min-300" }, [
            _vm.type
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("h1", { staticClass: "brandHeading" }, [
                        _vm._v(_vm._s(_vm.typeTitle)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "small ffss",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.backStep()
                            },
                          },
                        },
                        [_vm._v("Back a step")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("rate-calendar", {
                      attrs: {
                        system: _vm.system,
                        venue: _vm.calendarVenue,
                        type: _vm.type,
                        mindate: _vm.mindate,
                        arrive: _vm.arrive,
                        promo: _vm.appliedPromo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type == "spa-day" && _vm.venue == "hoar-cross-hall"
              ? _c("div", { staticClass: "box-blush mb-4" }, [
                  _c("p", [
                    _vm._v(
                      "\n                            Why not relax for a little longer, changing your\n                            spa day to an overnight spa stay?\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "small ffss",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.backStep()
                        },
                      },
                    },
                    [_vm._v("Back a step")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.type == "treatments"
              ? _c("div", { staticClass: "box-blush mb-3" }, [
                  _c("p", [
                    _vm._v(
                      "\n                            Why not enjoy our award-winning spa facilities,\n                            choosing a spa day with inclusive treatments?\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "small ffss",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.backStep()
                        },
                      },
                    },
                    [_vm._v("Back a step")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.type !== "hotel-stay"
              ? _c("div", { staticClass: "small lh-2" }, [
                  _vm.type == "treatments"
                    ? _c("p", [
                        _vm._v(
                          "\n                            Minimum age for spa treatments is 18 due to\n                            insurance purposes. Minimum age for manicures\n                            and pedicures in the Parlour is 16. Please note,\n                            standalone treatments do not come with spa\n                            access. Treatments that are not included in a\n                            spa experience are non-refundable, with or\n                            without flexible booking option. Please call our\n                            Reservations Team on 01283 576522 for more\n                            information.\n                        "
                        ),
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n                            Minimum age for spa treatments is 18 due to\n                            insurance purposes. Minimum age for manicures\n                            and pedicures in the Parlour is 16. Please call\n                            our Reservations Team on 01283 576522 for more\n                            information.\n                        "
                        ),
                      ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.bookingExists
      ? _c("div", { staticClass: "modal-message existing-booking-modal" }, [
          _c("div", { staticClass: "modal-message-container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", [
              _vm._v("It looks like you already have a booking in the cart."),
            ]),
            _vm._v(" "),
            _c("h5", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.bookingExists.RateDetails) +
                  " at\n                " +
                  _vm._s(_vm._f("nicevenue")(_vm.bookingExists.Location)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("h6", [
              _vm._v(_vm._s(_vm._f("rsnicedate")(_vm.bookingExists.StartDate))),
            ]),
            _vm._v(" "),
            _c("h6", [_vm._v("Guests: " + _vm._s(_vm.bookingExists.guests))]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("p", [_vm._v("What would you like to do?")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.continueBooking("enhance")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Continue with this booking\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.continueBooking("booking")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Add another booking to this day\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.restartBooking.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n                    Start over\n                ")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [_c("span", [_vm._v("Hey")]), _vm._v(" there!")])
  },
]
render._withStripped = true

export { render, staticRenderFns }