<template>
    <form @submit.prevent="enquire">
        <div class="form-group row">
            <div class="col-md-6">
                <label for="memberName">Current members’ name</label>
                <input v-model="formData.memberName" name="memberName" ref="memberName" type="text" placeholder="Enter your name" class="form-control" required />
            </div>
            <div class="col-md-6">
                <label for="tel">Your telephone number</label>
                <input v-model="formData.tel" name="tel" ref="tel" type="tel" placeholder="Your telephone number" class="form-control" required />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="emailAddress">Your email address</label>
                <input v-model="formData.emailAddress" name="emailAddress" ref="emailAddress" type="email" placeholder="Enter email" class="form-control" required />
            </div>
            <div class="col-md-6">
                <label for="contactTime">What is the best time for us to contact you?</label>
                <select v-model="formData.contactTime" name="contactTime" class="form-control" id="contactTime" required>
                    <option value="">Select best time</option>
                    <option value="Anytime">Anytime</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                    <option value="Evenings">Evenings</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12">
                <button type="submit" class="btn btn-reversed float-right">Submit</button>
            </div>
        </div>
        <p class="signup-referals" v-if="success">Thank you for the referral. We will be in touch with you shortly.</p>
    </form>
</template>

<script>
    var formTemplate = function() {
        return {
            memberName: null,
            tel: null,
            emailAddress: null,
            contactTime: ''
        }
    }
    export default {
        props: {
            email: String,
            venue: String,
        },
        data() {
            return {
                formData: new formTemplate(),
                success: null
            }
        },

        mounted() {
            
        },

        methods: {
            enquire() {
                var self = this

                // populate form data, even from autocomplete
                self.formData.memberName = this.$refs.memberName.value
                self.formData.emailAddress = this.$refs.emailAddress.value
                self.formData.tel = this.$refs.tel.value

                window.getRecaptchaToken()
                    .then(function(r) {
                        var data = {
                            email: self.email,
                            venue: self.venue,
                            formData: self.formData,
                            recaptcha: r
                        }
                        axios.post('/system/subscribe/member-referral', data)
                            .then(response=> {
                                self.success = true;
                                self.formData = new formTemplate()

                                dataLayer.push({
                                    event: 'form_submission',
                                    form_type: 'lead-Form',
                                    form_subject: 'Membership Referral',
                                    venue: self.venue,
                                });

                                this.$emitAnalytics(
                                    'form_submission', 
                                    {
                                        event: 'form_submission',
                                        form_type: 'lead-Form',
                                        form_subject: 'Membership Referral',
                                        venue: self.venue,
                                    },
                                    self.system.CustomerId,
                                    self.system.WebfolioId
                                )
                            });
                    })
            },
        }
    }
</script>
