var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-cart checkout" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-6 text-center" }, [
        _c(
          "div",
          { staticClass: "booking-info", attrs: { id: "termsConditions" } },
          [
            _c("h2", [_vm._v("Payment")]),
            _vm._v(" "),
            _c("div", { staticClass: "cart-page-actions__balance mt-3" }, [
              _vm.cart.FolioBalance
                ? _c("span", [
                    _vm._v(
                      "Ready to pay£" + _vm._s(_vm.cart.FolioBalance) + "?"
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8 text-center mb-5" }, [
        _c("div", { staticClass: "checkout-terms" }, [
          _c("div", { staticClass: "form-check" }, [
            _c(
              "label",
              { staticClass: "form-check-label", attrs: { for: "termsCheck" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.termsCheck,
                      expression: "termsCheck",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "checkbox", id: "termsCheck" },
                  domProps: {
                    checked: Array.isArray(_vm.termsCheck)
                      ? _vm._i(_vm.termsCheck, null) > -1
                      : _vm.termsCheck,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.termsCheck,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.termsCheck = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.termsCheck = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.termsCheck = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(
                  "\n                        I agree to the Barons Eden booking "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" +
                        (_vm.venue ? _vm.venue : "hoar-cross-hall") +
                        "/terms-conditions",
                      target: "_blank",
                    },
                  },
                  [_vm._v("terms and conditions")]
                ),
                _vm._v(".\n                    "),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 text-center" }, [
        _c(
          "div",
          { staticClass: "checkout-options row justify-content-md-center" },
          [
            _c("div", { staticClass: "col-md-10" }, [
              _c(
                "div",
                { staticClass: "accordion", attrs: { id: "acc-giftcard" } },
                [
                  _c("div", { staticClass: "accordion-item" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse show",
                        attrs: {
                          id: "collapse-giftcard",
                          "aria-labelledby": "heading-giftcard",
                          "data-parent": "#acc-giftcard",
                        },
                      },
                      [
                        _c("div", { staticClass: "accordion-body" }, [
                          _c("div", { staticClass: "ac-body_pl" }, [
                            _vm.gcInCart
                              ? _c("div", [_vm._m(2)])
                              : _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-md-6 voucher-details text-left",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "Did you know, all of our gift cards and e-vouchers hold a monetary value, meaning they can be redeemed against any experience, and used as partial or full payment? Simply enter your gift card or e-voucher number below, followed by the surname of the person who gifted you the blissful experience."
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.appliedVouchers.length > 0
                                        ? _c(
                                            "ul",
                                            _vm._l(
                                              _vm.appliedVouchers,
                                              function (voucher) {
                                                return _c("li", [
                                                  _vm._v(
                                                    "£" +
                                                      _vm._s(
                                                        voucher.GCRedeemedAmount
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          voucher.GiftCertId
                                                        ) +
                                                        ")"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeGC(
                                                            voucher.GiftCertId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Remove")]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 voucher-form" },
                                    [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.checkGc.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12 mb-3",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.GCNumber,
                                                          expression:
                                                            "GCNumber",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        id: "giftCertNo",
                                                        placeholder:
                                                          "Gift Card / e-voucher number",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value: _vm.GCNumber,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.GCNumber =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12 mb-4 text-left",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.FromLastName,
                                                          expression:
                                                            "FromLastName",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        name: "FromLastName",
                                                        id: "FromLastName",
                                                        placeholder:
                                                          "Purchasers surname",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value: _vm.FromLastName,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.FromLastName =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12 mb-4",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary btn-block",
                                                        attrs: {
                                                          type: "submit",
                                                          disabled:
                                                            !_vm.termsCheck ||
                                                            _vm.checkingGc,
                                                        },
                                                      },
                                                      [
                                                        _vm._v("Apply "),
                                                        _vm.checkingGc
                                                          ? _c("span", {
                                                              staticClass:
                                                                "spinner-border spinner-border-sm",
                                                              attrs: {
                                                                role: "status",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-12 mb-2 text-left",
                                                  },
                                                  [
                                                    _vm.voucherError
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "alert alert-danger",
                                                            attrs: {
                                                              role: "alert",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                                   " +
                                                                _vm._s(
                                                                  _vm.voucherError
                                                                ) +
                                                                "\n                                                                "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "checkout-options row justify-content-md-center" },
          [
            _c("div", { staticClass: "col-md-10" }, [
              _c(
                "div",
                { staticClass: "accordion", attrs: { id: "acc-creditcard" } },
                [
                  _c("div", { staticClass: "accordion-item" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse show",
                        attrs: {
                          id: "collapse-creditcard",
                          "aria-labelledby": "heading-creditcard",
                          "data-parent": "#acc-creditcard",
                        },
                      },
                      [
                        _c("div", { staticClass: "accordion-body" }, [
                          _c("div", { staticClass: "ac-body_pl" }, [
                            !_vm.termsCheck
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "alert alert-warning",
                                    staticStyle: { "line-height": "1.2" },
                                    attrs: { role: "alert" },
                                  },
                                  [
                                    _vm._v("Please review and accept our "),
                                    _c(
                                      "a",
                                      { attrs: { href: "#termsConditions" } },
                                      [_vm._v("terms and conditions")]
                                    ),
                                    _vm._v(" to continue with your purchase"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cart.FolioBalance == "0.00" &&
                            _vm.cart.Guaranteed == "Y"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { disabled: !_vm.termsCheck },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.doCheckoutWithGiftCard.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.doingGcCheckout
                                      ? _c("span", {
                                          staticClass:
                                            "spinner-border spinner-border-sm",
                                          attrs: { role: "status" },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " Complete Booking\n                                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    attrs: {
                                      disabled:
                                        _vm.settingCheckout || !_vm.termsCheck,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.setCheckout(false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Pay by credit/debit card\n                                        "
                                    ),
                                  ]
                                ),
                          ]),
                          _vm._v(" "),
                          _vm.system.PayEngine == "3C"
                            ? _c("div", { attrs: { id: "planet" } }, [
                                _vm._m(4),
                                _vm._v(" "),
                                _c("iframe", {
                                  ref: "threeCFrameRef",
                                  staticClass: "v-none",
                                  staticStyle: { width: "100%" },
                                  attrs: { id: "3cFrame", name: "3cFrame" },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "worldpay" }, [
                            _c("div", { attrs: { id: "wpFrame" } }),
                            _vm._v(" "),
                            _vm.paymentStatus &&
                            _vm.paymentStatus != "undefined"
                              ? _c("p", { staticClass: "paymentError" }, [
                                  _vm._v(
                                    "There was an error with your payment. Please try again."
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "errorModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "errorModalTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-body" }, [
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.gotoDetails.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Update my details\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-muted mb-5" }, [
      _vm._v(
        "Having some technical issues or in need of assistance? Then please don’t hesitate to call our dedicated Reservations Team on "
      ),
      _c("span", { staticClass: "globalTel" }, [_vm._v("01283 576522.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "accordion-header",
        attrs: {
          id: "heading-giftcard",
          "data-toggle": "collapse",
          "data-target": "#collapse-giftcard",
          "aria-expanded": "true",
          "aria-controls": "collapse-giftcard",
        },
      },
      [
        _c("div", { staticClass: "clearfix" }, [
          _c("span", { staticClass: "accordion-name" }, [
            _vm._v(
              "\n                                        Redeem a gift card or e-voucher\n                                        "
            ),
            _c("span", { staticClass: "float-right" }, [
              _c("i", { staticClass: "arrow" }),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ffs" }, [
      _vm._v(
        "Unfortunately Gift Cards cannot be redeemed against a new Gift Card or Afternoon Tea reservations, to book an Afternoon Tea with a gift card, please call our reservations team on "
      ),
      _c("a", { attrs: { href: "tel:01283 576522", target: "_blank" } }, [
        _vm._v("01283 576522"),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "accordion-header",
        attrs: {
          id: "heading-creditcard",
          "data-toggle": "collapse",
          "data-target": "#collapse-creditcard",
          "aria-expanded": "true",
          "aria-controls": "collapse-creditcard",
        },
      },
      [
        _c("div", { staticClass: "clearfix" }, [
          _c("span", { staticClass: "accordion-name" }, [
            _vm._v(
              "\n                                        Pay by credit/debit card\n                                        "
            ),
            _c("span", { staticClass: "float-right" }, [
              _c("i", { staticClass: "arrow" }),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "form",
      { attrs: { id: "3cForm", method: "post", target: "3cFrame" } },
      [
        _c("input", {
          attrs: { type: "hidden", name: "XXX_IPGSESSION_XXX", value: "" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "hidden",
            name: "trx_merchant_reference_2",
            value: "",
          },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "hidden",
            name: "trx_merchant_reference_3",
            value: "",
          },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "merchant_script_data_1", value: "" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "merchant_script_data_2", value: "" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "merchant_script_data_3", value: "" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "content_language", value: "EN" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "redirect_approved", value: "" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "redirect_declined", value: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [
      _c("span", { staticClass: "text-italic" }, [_vm._v("Oh no.")]),
      _vm._v(" Something went wrong..."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "We couldn't process your order. It's probably because some of your details are incorrect. "
      ),
      _c("strong", [_vm._v("Please check your address is correct.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }