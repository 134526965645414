<template>
    <div>
        <div class="row justify-content-md-center">
            <div class="col-md-8 text-center">
                <div class="book-start">
                    <div class="min-300">
                        <div v-if="type" class="form-group">
                            <div class="mb-2">
                                <h1 class="brandHeading">{{ typeTitle }}</h1>
                                <a
                                    @click.prevent="backStep()"
                                    class="small ffss"
                                    >Back a step</a
                                >
                            </div>
                            <rate-calendar
                                :system="system"
                                :venue="calendarVenue"
                                :type="type"
                                :mindate="mindate"
                                :arrive="arrive"
                                :promo="appliedPromo"
                            ></rate-calendar>
                        </div>
                        <div
                            v-if="
                                type == 'spa-day' && venue == 'hoar-cross-hall'
                            "
                            class="box-blush mb-4"
                        >
                            <p>
                                Why not relax for a little longer, changing your
                                spa day to an overnight spa stay?
                            </p>
                            <a @click.prevent="backStep()" class="small ffss"
                                >Back a step</a
                            >
                        </div>
                        <div v-if="type == 'treatments'" class="box-blush mb-3">
                            <p>
                                Why not enjoy our award-winning spa facilities,
                                choosing a spa day with inclusive treatments?
                            </p>
                            <a @click.prevent="backStep()" class="small ffss"
                                >Back a step</a
                            >
                        </div>

                        <div v-if="type !== 'hotel-stay'" class="small lh-2">
                            <p v-if="type == 'treatments'">
                                Minimum age for spa treatments is 18 due to
                                insurance purposes. Minimum age for manicures
                                and pedicures in the Parlour is 16. Please note,
                                standalone treatments do not come with spa
                                access. Treatments that are not included in a
                                spa experience are non-refundable, with or
                                without flexible booking option. Please call our
                                Reservations Team on 01283 576522 for more
                                information.
                            </p>

                            <p v-else>
                                Minimum age for spa treatments is 18 due to
                                insurance purposes. Minimum age for manicures
                                and pedicures in the Parlour is 16. Please call
                                our Reservations Team on 01283 576522 for more
                                information.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="bookingExists" class="modal-message existing-booking-modal">
            <div class="modal-message-container">
                <h2><span>Hey</span> there!</h2>
                <p>It looks like you already have a booking in the cart.</p>
                <h5>
                    {{ bookingExists.RateDetails }} at
                    {{ bookingExists.Location | nicevenue }}
                </h5>
                <h6>{{ bookingExists.StartDate | rsnicedate }}</h6>
                <h6>Guests: {{ bookingExists.guests }}</h6>
                <div class="mt-4">
                    <p>What would you like to do?</p>
                    <button
                        @click.prevent="continueBooking('enhance')"
                        class="btn btn-secondary"
                    >
                        Continue with this booking
                    </button>
                    <button
                        @click.prevent="continueBooking('booking')"
                        class="btn btn-secondary"
                    >
                        Add another booking to this day
                    </button>
                    <button
                        @click.prevent="restartBooking"
                        class="btn btn-secondary"
                    >
                        Start over
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        system: Object,
        cart: Object,
    },

    data() {
        return {
            mindate: moment().format("YYYY-MM-DD"),
            guests: 2,
            room: 1,
            arrive: null,
            nights: 1,
            venue: this.$route.params.venue,
            type: this.$route.params.type,
            bookingExists: null,
            promoCode: null,
            appliedPromo: null,
            invalidPromo: null,
        };
    },

    beforeMount() {
        Bus.$on("changeDate", (date) => {
            this.arrive = date;
        });
        Bus.$on("search", () => {
            this.availability();
        });
    },

    mounted() {
        Bus.$emit("RefreshSystem");
        if (this.cart.WebFolioItem) {
            this.checkExistingBooking();
        }
        if (this.type == "treatments") {
            this.arrive = moment().format("YYYY-MM-DD");
        }
    },

    computed: {
        calendarVenue() {
            if (this.venue == "eden-hall") {
                return "Eden Hall - Day Spa";
            } else {
                if (this.type == "spa-day") {
                    return "Hoar Cross Hall - Day Spa";
                } else {
                    return "Hoar Cross Hall";
                }
            }
        },
        typeTitle() {
            if (this.type == "spa-day") {
                return "Spa Day";
            }
            if (this.type == "stay") {
                return "Spa Stay";
            }
            if (this.type == "hotel-stay") {
                return "Hotel Only";
            }
            if (this.type == "treatments") {
                return "Treatments";
            }
            return "";
        },
    },

    watch: {
        cart(newVal, oldVal) {
            if (newVal.WebFolioItem) {
                this.checkExistingBooking();
            } else {
                this.bookingExists = null;
            }
        },
    },

    methods: {
        checkExistingBooking() {
            // check if we already have a booking in the cart
            var current = _.find(this.cart.WebFolioItem, { Category: "Hotel" });
            if (current) {
                // We have a stay/spa day
                current.guests = _.sumBy(this.cart.WebFolioItem, "Adults");
                this.bookingExists = current;
            }
        },
        continueBooking(type) {
            var venue =
                this.bookingExists.Location == "Eden Hall - Day Spa"
                    ? "eden-hall"
                    : "hoar-cross-hall";

            if (this.bookingExists.type == "Stay") {
                this.safeNavigate(
                    `/book/${type}/${venue}/stay/${this.bookingExists.StartDate.slice(
                        0,
                        -6
                    )}/${this.bookingExists.Nights}/${
                        this.bookingExists.Adults
                    }`
                );
            } else {
                this.safeNavigate(
                    `/book/${type}/${venue}/spa-day/${this.bookingExists.StartDate.slice(
                        0,
                        -6
                    )}/${this.bookingExists.Adults}`
                );
            }
        },
        restartBooking() {
            Bus.$emit("RestartBooking", true);
            this.bookingExists = null;
        },
        selectType(type) {
            this.type = type;
        },
        backStep() {
            this.safeNavigate(`/book/start/${this.venue}`);
        },
        availability() {
            if (this.venue == "hoar-cross-hall") {
                if (this.type == "stay") {
                    this.safeNavigate(
                        `/book/booking/hoar-cross-hall/stay/${moment(
                            this.arrive
                        ).format("YYYY-MM-DD")}/${this.nights}/${this.guests}`
                    );
                } else if (this.type == "hotel-stay") {
                    this.safeNavigate(
                        `/book/booking/hoar-cross-hall/hotel-stay/${moment(
                            this.arrive
                        ).format("YYYY-MM-DD")}/${this.nights}/${this.guests}`
                    );
                } else if (this.type == "spa-day") {
                    this.safeNavigate(
                        `/book/booking/hoar-cross-hall/spa-day/${moment(
                            this.arrive
                        ).format("YYYY-MM-DD")}/${this.guests}`
                    );
                } else if (this.type == "treatments") {
                    this.safeNavigate(
                        `/book/treatments/hoar-cross-hall/treatments/${moment(
                            this.arrive
                        ).format("YYYY-MM-DD")}/${this.guests}`
                    );
                }
            } else {
                if (this.type == "spa-day") {
                    this.safeNavigate(
                        `/book/booking/eden-hall/spa-day/${moment(
                            this.arrive
                        ).format("YYYY-MM-DD")}/${this.guests}`
                    );
                } else if (this.type == "treatments") {
                    this.safeNavigate(
                        `/book/treatments/eden-hall/treatments/${moment(
                            this.arrive
                        ).format("YYYY-MM-DD")}/${this.guests}`
                    );
                }
            }
        },
        safeNavigate(path) {
            if (this.$route.path !== path) {
                this.$router.push(path).catch((err) => {
                    if (err.name !== "NavigationDuplicated") {
                        console.error(err);
                    }
                });
            }
        },
    },
};
</script>
