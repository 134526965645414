<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <h3 class="brandHeading text-center mb-5">Book classes</h3>
            </div>
            <div class="col-md-9 text-center mb-3">
                <button class="btn prev" @click.prevent="prev" :disabled="prevDisabled">Previous Day</button>
                <div class="d-inline-block ml-3 mr-3 gym-calendar">
                    <v-date-picker v-model="startDate"
                        :min-date="date"
                        :max-date="maxDate"
                        :attributes="attributes">
                        <span 
                            slot-scope="{ inputProps, inputEvents }"
                            :class="['select-date-over']"
                            v-bind="inputProps"
                            v-on="inputEvents"
                        >
                        {{ startDate | nicedate }} <i class="arrow"></i>
                        </span>
                    </v-date-picker>
                </div>
                <button class="btn next" @click.prevent="next" :disabled="nextDisabled">Next Day</button>
            </div>
            <div class="col-md-12">
                <div v-if="classes" class="table-responsive">
                    <table class="table table-members">
                        <thead class="thead-dark">
                            <tr>
                                <th>Class</th>
                                <th class="d-none d-md-table-cell">Time</th>
                                <th class="d-none d-md-table-cell">Location</th>
                                <th class="d-none d-md-table-cell">Places Left</th>
                                <th>Interested?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="day, key in classes">
                                <td>
                                    {{ day.SpaItemName }}
                                    <div class="d-md-none members-mobile">
                                        <span>{{ day.StartTime | classtime }} - {{ day.EndTime | classtime }}</span>
                                        <span>{{ day.SpaRoomId }}</span>
                                        <span class="font-gold">Places left: {{ day.AvailSlots }}</span>
                                    </div>
                                </td>
                                <td class="d-none d-md-table-cell">{{ day.StartTime | classtime }} - {{ day.EndTime | classtime }}</td>
                                <td class="d-none d-md-table-cell">{{ day.SpaRoomId }}</td>
                                <td class="d-none d-md-table-cell">{{ day.AvailSlots }}</td>
                                <td>
                                    <div v-if="day.booking">
                                        Already booked
                                    </div>
                                    <div v-else-if="day.booked">
                                        <a :href="'/' + venue + '/members-portal/cart'" class="btn btn-secondary mb-0">
                                            Check out
                                        </a>
                                    </div>
                                    <div v-else>
                                        <button @click.prevent="bookClass(day)" :disabled="bookingClass" class="btn btn-sm mb-0">
                                            Add to cart
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="loadingClasses">
                    Loading classes...
                </div>
                <div v-else>
                    No classes available...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VCalendar from 'v-calendar';
    export default {
        props: {
            system: Object,
            cart: Object,
            location: Number,
            venue: String
        },

        components: {
            
        },

        data() {
            return {
                loadingClasses: false,
                classesError: null,
                bookingClass: false,
                removingClass: false,
                attributes: [],
                startDate: new Date(),
                maxDate: new Date(moment().add(7, 'days').format('YYYY-MM-DD')),
                today: new Date(),
                date: new Date(),
                classes: null,
                futureBookings: null,
            }
        },

        beforeMount() {
            this.getFutureBookings()
        },
        
        mounted() {
            this.getClasses()
        },

        watch: {
            startDate() {
                this.getClasses()
            },
            cart() {
                this.syncBookingsInCart()
            },
            classes() {
                this.syncConfirmedBookings()
            }
        },

        computed: {
            prevDisabled() {
                if(moment(this.startDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
                    return true
                } else {
                    return false
                }
            },
            nextDisabled() {
                if(moment(this.startDate).format('YYYY-MM-DD') == moment().add(7, 'days').format('YYYY-MM-DD')) {
                    return true
                } else {
                    return false
                }
            }
        },

        methods: {
            syncBookingsInCart() {
                var self = this
                _.each(self.cart.WebFolioItem, function(booking) {
                    var hasBooking = _.find(self.classes, { 'SpaItemId': +booking.ItemId, 'StartTime': booking.StartDate });
                    if(hasBooking) {
                        hasBooking.booked = booking
                    }
                })
            },

            syncConfirmedBookings() {
                var self = this
                if(self.futureBookings) {
                    _.each(self.futureBookings, function(fb) {
                        var hasBooking = _.find(self.classes, { 'SpaItemId': +fb.SpaItemId, 'StartTime': fb.BookStartTime });
                        if(hasBooking) {
                            hasBooking.booking = fb
                        }
                    })
                }
            },
            
            prev() {
                if(moment(this.startDate) > moment()) {
                    this.startDate = moment(this.startDate).subtract(1, 'days')
                }
            },
            next() {
                if(moment(this.startDate) < moment().add(7, 'days')) {
                    this.startDate = moment(this.startDate).add(1, 'days')
                }
            },
            getFutureBookings() {
                var self = this
                self.futureBookings = null

                 var request = {
                    method: 'FetchCustomerSpaBookings',
                    data: {
                        CustomerId: this.system.CustomerId,
                        CustomerGUID: this.system.CustomerGUID,
                        BookStatus: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            if(!$.isArray(response.data.SpaFolio)) {
                                response.data.SpaFolio = [response.data.SpaFolio];
                            }
                            var bookingItems = []
                            _.each(response.data.SpaFolio, function(o) {
                                if(!$.isArray(o.SpaFolioItem)) {
                                    o.SpaFolioItem = [o.SpaFolioItem];
                                }
                                _.each(o.SpaFolioItem, function(item) {
                                    if(item.BookStatus != 'CNL') {
                                        var date = item.BookStartTime.substr(0, item.BookStartTime.length-6);
                                        var time = item.BookStartTime.substr(10, 6);
                                        var timeCheck = moment(new Date(date + 'T' + time.match(/.{1,2}/g).join(':')))
                                        if(timeCheck > moment()) {
                                            item.sortTime = timeCheck.format('YYYYMMDDHHmm')
                                            if(item.LocationId == 200 || item.LocationId == 400) {
                                                bookingItems.push(item)
                                            }
                                        }
                                    }
                                })
                            })
                                
                            self.futureBookings = _.orderBy(bookingItems, ['sortTime'], 'asc')
                        }
                    })
            },
            getClasses() {
                var self = this
                self.classes = null
                self.classesError = null

                var start = moment(this.startDate).format('YYYY-MM-DD')
                var end = moment(this.startDate).add(1, 'days').format('YYYY-MM-DD')
                /*
                if(moment() > moment().set({'hour': '6', 'minute': '00', 'second': '00'})) {
                    var end = moment(this.startDate).add('6', 'days').format('YYYY-MM-DD')
                } else {
                    var end = moment(this.startDate).add('5', 'days').format('YYYY-MM-DD')
                }
                */
                this.loadingClasses = true

                this.dateMap = []

                var request = {
                    method: 'FetchSpaClasses',
                    data: {
                        Location: this.location,
                        StartDate: moment(start).format('YYYY-MM-DD'),
                        EndDate: moment(end).format('YYYY-MM-DD'),
                        WebFolioId: null,
                        Language: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            if(!$.isArray(response.data.DailySpaClasses)) {
                                response.data.DailySpaClasses = [response.data.DailySpaClasses]
                            }

                            _.each(response.data.DailySpaClasses, function(day) {
                                day.date = day.Date.substring(0, day.Date.length - 6)
                                if(day.SpaClass) {
                                    if(!$.isArray(day.SpaClass)) {
                                        day.SpaClass = [day.SpaClass]
                                    }

                                    var classArray = []
                                    _.each(day.SpaClass, function(c, k) {
                                        c['booked'] = false
                                        c['booking'] = false
                                        var date = c.StartTime.substr(0, c.StartTime.length-6);
                                        var time = c.StartTime.substr(10, 6);
                                        var timeCheck = moment(new Date(date + 'T' + time.match(/.{1,2}/g).join(':')))
                                        if(timeCheck.format('YYYYMMDDHHmm') > moment().format('YYYYMMDDHHmm')) {
                                            c.dateTime = day.date
                                            c.sortTime = timeCheck.format('YYYYMMDDHHmm')
                                            classArray.push(c)
                                        }
                                    })

                                    day.classes = _.orderBy(classArray, ['sortTime'], 'asc')
                                }
                            })
                            
                            if(_.find(response.data.DailySpaClasses, { 'date': moment(self.startDate).format('YYYY-MM-DD') })) {
                                self.classes = _.find(response.data.DailySpaClasses, { 'date': moment(self.startDate).format('YYYY-MM-DD') }).classes
                                self.syncBookingsInCart()
                            }
                            
                        } else {
                            this.classesError = response.data.Result.Text
                        }
                        this.loadingClasses = false
                    });
            },

            bookClass(session) {
                Bus.$emit('showLoading')
                this.bookingClass = true
                var request = {
                    method: 'CreateClassBooking',
                    data: {
                        CustomerId: this.system.CustomerId,
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        ProgramId: session.SpaClassId,
                        ItemId: null,
                        GuestName: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            var classes = JSON.parse(window.sessionStorage.getItem('booked_classes'))
                            classes.push(session)
                            window.sessionStorage.setItem('booked_classes', JSON.stringify(classes))
                        }
                        this.bookingClass = false
                        Bus.$emit('UpdateCart')
                        Bus.$emit('hideLoading')
                    });
            },
            removeClass(session) {
                Bus.$emit('showLoading')
                this.removingClass = true
                var request = {
                    method: 'CancelSpaService',
                    data: {
                        SessionId: this.system.SessionId,
                        WebFolioId: this.system.WebFolioId,
                        SpaFolioId: session.booked.FolioId,
                        SpaFolioItemId: session.booked.FolioItemId
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value == 'SUCCESS') {
                            session.booked = false
                        }
                        this.removingClass = false
                        Bus.$emit('UpdateCart')
                        Bus.$emit('hideLoading')
                    });
            },
        },

    }
</script>
