var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row justify-content-md-center book-success mb-5" },
      [
        _c("div", { staticClass: "col-md-6 text-center" }, [
          _c("div", { staticClass: "booking-info mb-5" }, [
            _c("h2", [_vm._v("Your booking reference number")]),
            _vm._v(" "),
            _c("span", { staticClass: "book-ref" }, [
              _vm._v(_vm._s(_vm.bookingRef)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("p", [
              _c("strong", [
                _vm._v(
                  "Thank you for your booking " +
                    _vm._s(_vm.system.FirstName) +
                    ", we look forward to welcoming you through our grand Hall doors very soon."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "A confirmation email will arrive in your inbox very shortly, followed by a pre-arrival consultation form, where we can get fully acquainted with your dietary requirements and health status, ensuring every inch of your experience is tailored to your needs."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "In the meantime, all that’s left for you to do is peruse your historic property, discovering every treasure the Hall has to offer..."
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-secondary btn-bg-hch btn-block mb-3",
            attrs: { href: "/hoar-cross-hall" },
          },
          [_vm._v("Hoar Cross Hall")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-secondary btn-bg-eh btn-block",
            attrs: { href: "/eden-hall" },
          },
          [_vm._v("Eden Hall")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }