var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col-md-8 text-center" }, [
        _c("div", { staticClass: "book-start" }, [
          _c("div", { staticClass: "min-300" }, [
            _c("h1", { staticClass: "brandHeading" }, [
              _vm._v("Plan your experience"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-4" }, [
              _vm._v(
                "\n                        Which spa retreat would you like to visit?\n                    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "brand-select hch-bg",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectVenue("hoar-cross-hall")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/img/hch_cream_full.svg",
                    alt: "Hoar Cross Hall logo",
                    loading: "lazy",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "brand-select eh-bg",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectVenue("eden-hall")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/img/eh_cream_full.svg",
                    alt: "Eden Hall logo",
                    loading: "lazy",
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }