var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-enhance" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("progress-indicator", { attrs: { step: _vm.step } }),
          _vm._v(" "),
          _c("div", { staticClass: "booking-info" }, [
            _c("h1", { staticClass: "brandHeading" }, [
              _vm._v("Finishing Touches"),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "hide-mobile" }, [
              _vm._v(_vm._s(_vm._f("nicedate")(_vm.pageDate))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "message-small" }, [
              _c(
                "a",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.stepBack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Back a step")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.restartBooking(true)
                    },
                  },
                },
                [_vm._v("Restart booking")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "booking-list" },
            _vm._l(_vm.filteredCart, function (folio, k) {
              return _c("div", { key: k, staticClass: "booking-list-item" }, [
                _c("div", { staticClass: "booking-list-item__details" }, [
                  _c("div", { staticClass: "hide-mobile" }, [
                    _c("h3", [
                      folio.Type == "Stay"
                        ? _c("span", [_vm._v("Room")])
                        : _c("span", [_vm._v("Guest")]),
                      _vm._v(
                        " " + _vm._s(k + 1) + "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("Location: " + _vm._s(folio.Location))]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Date: " + _vm._s(_vm._f("rsnicedate")(folio.StartDate))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("Experience: " + _vm._s(folio.RateDetails)),
                    ]),
                    _vm._v(" "),
                    folio.Type == "Stay"
                      ? _c("p", [
                          _vm._v(
                            "\n                                Guests: " +
                              _vm._s(folio.Adults) +
                              "\n                            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    folio.Type == "Stay"
                      ? _c("p", [
                          _c("strong", [_vm._v("Room type:")]),
                          _vm._v(
                            "\n                                " +
                              _vm._s(folio.Details) +
                              "\n                                "
                          ),
                          folio.twinSelected
                            ? _c("span", [_vm._v("(Twin room)")])
                            : _vm._e(),
                          _vm._v(" "),
                          folio.doubleSelected
                            ? _c("span", [_vm._v("(Double room)")])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "details-price" }, [
                      _vm._v(
                        "\n                                Cost: £" +
                          _vm._s((+folio.Amount).toFixed(2)) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    folio.promotionApplied
                      ? _c("p", { staticClass: "small" }, [
                          _vm._v(
                            "\n                                (" +
                              _vm._s(folio.promotionApplied) +
                              " promotion\n                                applied)\n                            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    folio.RateType == "R3FOR2"
                      ? _c("p", { staticClass: "small" }, [
                          _vm._v(
                            "\n                                *Add code at checkout\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "show-mobile" }, [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-heading heading clearfix",
                          attrs: {
                            "data-toggle": "collapse",
                            "data-target": "#packageDetails",
                            "aria-expanded": "false",
                            "aria-controls": "packageDetails",
                          },
                        },
                        [
                          _c("h3", [
                            folio.Type == "Stay"
                              ? _c("span", [_vm._v("Room")])
                              : _c("span", [_vm._v("Guest")]),
                            _vm._v(
                              "\n                                        " +
                                _vm._s(k + 1) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "arrow" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-container collapse",
                          attrs: { id: "packageDetails" },
                        },
                        [
                          _c("h3", [
                            folio.Type == "Stay"
                              ? _c("span", [_vm._v("Room")])
                              : _c("span", [_vm._v("Guest")]),
                            _vm._v(
                              "\n                                        " +
                                _vm._s(k + 1) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Location: " + _vm._s(folio.Location)),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                                        Date:\n                                        " +
                                _vm._s(_vm._f("rsnicedate")(folio.StartDate)) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                                        Experience: " +
                                _vm._s(folio.RateDetails) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          folio.Type == "Stay"
                            ? _c("p", [
                                _vm._v(
                                  "\n                                        Guests: " +
                                    _vm._s(folio.Adults) +
                                    "\n                                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          folio.Type == "Stay"
                            ? _c("p", [
                                _c("strong", [_vm._v("Room type:")]),
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(folio.Details) +
                                    "\n                                        "
                                ),
                                folio.twinSelected
                                  ? _c("span", [_vm._v("(Twin room)")])
                                  : _vm._e(),
                                _vm._v(" "),
                                folio.doubleSelected
                                  ? _c("span", [_vm._v("(Double room)")])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "details-price" }, [
                            _vm._v(
                              "\n                                        Cost: £" +
                                _vm._s((+folio.Amount).toFixed(2)) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          folio.advancedBooking
                            ? _c("p", { staticClass: "small" }, [
                                _vm._v(
                                  "\n                                        (" +
                                    _vm._s(folio.advancedBooking) +
                                    "%\n                                        discount applied)\n                                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          1
                            ? _c("p", { staticClass: "small" }, [
                                _vm._v(
                                  "\n                                        *Add code at checkout\n                                    "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "booking-list-item__treatments" }, [
                  folio.InclusiveTreatments
                    ? _c("div", { staticClass: "inclusive-list" }, [
                        folio.HasOffers
                          ? _c("div", [
                              _c("h3", [_vm._v("£20 off Selected Treatments")]),
                            ])
                          : _c("div", [
                              _c("h3", [_vm._v("Inclusive Treatments")]),
                            ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "enhance-item-list clearfix" },
                          _vm._l(
                            folio.InclusiveTreatments.Inclusives,
                            function (treatment, k) {
                              return _c(
                                "li",
                                { key: k, staticClass: "clearfix" },
                                [
                                  treatment
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(treatment.Details) +
                                            "."
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm._f("rsdatetimeshort")(
                                                treatment.StartDate
                                              )
                                            ) +
                                            ".\n                                    "
                                        ),
                                      ])
                                    : _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ffs fwb text-uppercase mr-2",
                                          },
                                          [_vm._v("Treatment " + _vm._s(k + 1))]
                                        ),
                                        _vm._v(" "),
                                        folio.HasUpsells
                                          ? _c("span", [
                                              _vm._v("Inclusive treatment"),
                                            ])
                                          : _c("span", [
                                              _vm._v("No treatment added"),
                                            ]),
                                      ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [
                    folio.InclusiveTreatments
                      ? _c("span", [_vm._v("Additional ")])
                      : _vm._e(),
                    _vm._v("Treatments\n                        "),
                  ]),
                  _vm._v(" "),
                  folio.Treatments.length
                    ? _c(
                        "ul",
                        { staticClass: "enhance-item-list" },
                        _vm._l(folio.Treatments, function (treatment, k) {
                          return _c("li", { key: k }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(treatment.Details) +
                                "."
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("rsdatetimeshort")(treatment.StartDate)
                                ) +
                                ".\n                                £" +
                                _vm._s(treatment.Amount) +
                                ".\n                            "
                            ),
                          ])
                        }),
                        0
                      )
                    : _c("ul", { staticClass: "enhance-item-list" }, [
                        _c("li", [_vm._v("No treatment added")]),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "booking-list-item__extras" }, [
                  _c("div", { staticClass: "inclusive-list" }, [
                    _c("h3", [_vm._v("Finishing Touches")]),
                    _vm._v(" "),
                    folio.Type == "Stay"
                      ? _c("h5", [
                          _vm._v(
                            "\n                                Champagne, Prosecco, Afternoon Tea?\n                            "
                          ),
                        ])
                      : _c("h5", [
                          _vm._v(
                            "\n                                Breakfast, Bubbles, Birthday occasion?\n                            "
                          ),
                        ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                                Why not make your stay truly memorable by\n                                adding some final flourishes...\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block btn-blush",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showExtras(folio)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                Add Finishing Touches\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    folio.Cabanas
                      ? _c(
                          "ul",
                          { staticClass: "enhance-item-list" },
                          _vm._l(folio.Cabanas, function (cabana, ck) {
                            return _c("li", [
                              _c("span", [_vm._v(_vm._s(cabana.Details))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "small" }, [
                                _vm._v("£" + _vm._s(cabana.Amount)),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "small" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("rsnicedate")(cabana.StartDate)
                                  ) +
                                    ", " +
                                    _vm._s(_vm._f("rstime")(cabana.StartDate)) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("rstime")(cabana.FinishDate)
                                    ) +
                                    " "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "small link",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeTreatment(cabana)
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    folio.extras
                      ? _c(
                          "ul",
                          { staticClass: "enhance-item-list" },
                          _vm._l(
                            folio.extras.specialServices.PMSFolioSpecialService,
                            function (extra, ek) {
                              return _c("li", { key: ek }, [
                                extra.Qty != 0
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(extra.Qty) +
                                          " x\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      extra.PMSSpecialService
                                        .PMSSpecialServiceName
                                    ) +
                                    "\n                                    "
                                ),
                                extra.TotalWithSurcharges != "0.00"
                                  ? _c("span", [
                                      _vm._v(
                                        "£" + _vm._s(extra.TotalWithSurcharges)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeExtras(
                                          extra.PMSFolioSpecialServiceId,
                                          folio.FolioId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.removingExtra ==
                                    extra.PMSFolioSpecialServiceId
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "spinner-border spinner-border-sm",
                                            attrs: { role: "status" },
                                          },
                                          [_vm._v(" ")]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "link small" },
                                          [_vm._v("Remove")]
                                        ),
                                  ]
                                ),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  folio.extras
                    ? _c("div", { staticClass: "details-price" }, [
                        _vm._v(
                          "\n                            Cost: £" +
                            _vm._s((+folio.extras.total).toFixed(2)) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.cart
            ? _c("div", { staticClass: "next-steps" }, [
                _c("div", { staticClass: "next-steps__price" }, [
                  _vm._v(
                    "\n                    £" +
                      _vm._s(_vm.cart.FolioBalance) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary ersed",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.checkAdditionalFinishingTouches()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        I'm Ready to pay\n                    "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "booking-app-sideout extra-selector" }, [
      _c("div", { staticClass: "sideout-wrapper" }, [
        _c("div", { staticClass: "sideout-container" }, [
          _c("div", { staticClass: "sideout-content" }, [
            _c("div", { staticClass: "package-header" }, [
              _c(
                "a",
                {
                  staticClass: "prevNextArrow arrowLeft",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeSideout()
                    },
                  },
                },
                [_vm._v(" ")]
              ),
            ]),
            _vm._v(" "),
            _vm.filteredExtras
              ? _c(
                  "div",
                  { staticClass: "package-content" },
                  [
                    _vm.cabanas && _vm.venue == "hoar-cross-hall"
                      ? _c("div", { staticClass: "tab-cats" }, [
                          _vm.selectedFolio &&
                          _vm.selectedFolio.RoomType != "HTWI"
                            ? _c(
                                "div",
                                [
                                  _c("h3", [_vm._v("Cabanas")]),
                                  _vm._v(" "),
                                  _vm._l(_vm.cabanas, function (cabana, k) {
                                    return _c(
                                      "div",
                                      { key: k, staticClass: "tab-cat-items" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "accordion",
                                            attrs: { id: "acc-cabana" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "accordion-item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accordion-header",
                                                    attrs: {
                                                      id:
                                                        "heading" +
                                                        cabana.SpaItemId +
                                                        k,
                                                      "data-toggle": "collapse",
                                                      "data-target":
                                                        "#collapse" +
                                                        cabana.SpaItemId +
                                                        k,
                                                      "aria-expanded": "false",
                                                      "aria-controls":
                                                        "collapse" +
                                                        cabana.SpaItemId +
                                                        k,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "clearfix",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "accordion-name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  cabana.ItemName
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "float-right",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "arrow",
                                                            }),
                                                            _vm._v(" "),
                                                            cabana.Price !=
                                                            "0.00"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "£" +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "price"
                                                                        )(
                                                                          cabana.Price
                                                                        )
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "collapse",
                                                    attrs: {
                                                      id:
                                                        "collapse" +
                                                        cabana.SpaItemId +
                                                        k,
                                                      "aria-labelledby":
                                                        "heading" +
                                                        cabana.SpaItemId +
                                                        k,
                                                      "data-parent":
                                                        "#acc-cabana",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "accordion-body",
                                                      },
                                                      [
                                                        cabana.ItemImage &&
                                                        cabana.ItemImage.trim() !==
                                                          ""
                                                          ? _c("div", {
                                                              staticClass:
                                                                "room-package-image",
                                                              style:
                                                                "background-image:url(https://barons-eden-cdn.fra1.digitaloceanspaces.com/" +
                                                                cabana.ItemImage.replace(
                                                                  "/storage",
                                                                  ""
                                                                ) // Remove '/storage'
                                                                  .replace(
                                                                    /^\s*-+|-\s*$/g,
                                                                    ""
                                                                  ) // Remove leading/trailing hyphens and spaces
                                                                  .trim() + // Remove leading/trailing whitespace
                                                                ");",
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          staticClass:
                                                            "mt-3 mb-3",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              cabana.ItemDesc
                                                            ),
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _vm.selectedFolio
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "choose-date mb-3",
                                                                },
                                                                [
                                                                  _vm
                                                                    .selectedFolio
                                                                    .TreatmentDates
                                                                    .length > 1
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for: "date-select",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Please select your preferred date"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-inline",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "select",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm.treatmentDate,
                                                                                        expression:
                                                                                          "treatmentDate",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "custom-select",
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        var $$selectedVal =
                                                                                          Array.prototype.filter
                                                                                            .call(
                                                                                              $event
                                                                                                .target
                                                                                                .options,
                                                                                              function (
                                                                                                o
                                                                                              ) {
                                                                                                return o.selected
                                                                                              }
                                                                                            )
                                                                                            .map(
                                                                                              function (
                                                                                                o
                                                                                              ) {
                                                                                                var val =
                                                                                                  "_value" in
                                                                                                  o
                                                                                                    ? o._value
                                                                                                    : o.value
                                                                                                return val
                                                                                              }
                                                                                            )
                                                                                        _vm.treatmentDate =
                                                                                          $event
                                                                                            .target
                                                                                            .multiple
                                                                                            ? $$selectedVal
                                                                                            : $$selectedVal[0]
                                                                                      },
                                                                                  },
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .selectedFolio
                                                                                    .TreatmentDates,
                                                                                  function (
                                                                                    date,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "option",
                                                                                      {
                                                                                        key: index,
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              date,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                            " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "nicedate"
                                                                                              )(
                                                                                                date
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                                                        "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-primary mb-0",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        !_vm.treatmentDate,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        return _vm.getTreatmentTimesV2(
                                                                                          cabana
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Search"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "nicedate"
                                                                                  )(
                                                                                    _vm.treatmentDate
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.getTreatmentTimesV2(
                                                                                      cabana
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Check availability"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                  _vm._v(" "),
                                                                  _vm.loadingCabanas
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "loading",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Loading availability..."
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              cabana.TreatmentTimes
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "treatment-times",
                                                                    },
                                                                    [
                                                                      _vm._m(
                                                                        1,
                                                                        true
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm._l(
                                                                        cabana.TreatmentTimes,
                                                                        function (
                                                                          time,
                                                                          ttk
                                                                        ) {
                                                                          return _c(
                                                                            "button",
                                                                            {
                                                                              key: ttk,
                                                                              staticClass:
                                                                                "btn btn-tt mr-1",
                                                                              class:
                                                                                {
                                                                                  btnSelected:
                                                                                    time ==
                                                                                    _vm.selectedTime,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.selectTime(
                                                                                      time
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "rstime"
                                                                                    )(
                                                                                      time.StartTime
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "clearfix",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary mr-1",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.selectedTime,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.addTreatmentToCart()
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    Add to booking\n                                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    2
                                                                  )
                                                                : _c("div", [
                                                                    cabana.noAvailability
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Sorry, there are no available Cabanas for this date"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.filteredExtras, function (excats, cat) {
                      return _vm.dateIsMarch(cat)
                        ? _c(
                            "div",
                            { key: cat, staticClass: "tab-cats" },
                            [
                              cat === "Seasonal"
                                ? _c("img", {
                                    staticStyle: {
                                      width: "195px",
                                      "padding-bottom": "5px",
                                    },
                                    attrs: {
                                      src: "/img/MothersDay.webp",
                                      alt: "Mothers Day",
                                      loading: "lazy",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              cat !== "Seasonal"
                                ? _c("h3", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(cat.toLowerCase()) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(excats, function (extra, k) {
                                return _c(
                                  "div",
                                  { key: k, staticClass: "tab-cat-items" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "accordion",
                                        attrs: {
                                          id:
                                            "acc-" +
                                            cat.replace(
                                              /[^a-zA-Z0-9-_]+/g,
                                              "-"
                                            ),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "accordion-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "accordion-header",
                                                attrs: {
                                                  id:
                                                    "heading" +
                                                    extra.PMSItemId +
                                                    k,
                                                  "data-toggle": "collapse",
                                                  "data-target":
                                                    "#collapse" +
                                                    extra.PMSItemId +
                                                    k,
                                                  "aria-expanded": "false",
                                                  "aria-controls":
                                                    "collapse" +
                                                    extra.PMSItemId +
                                                    k,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "clearfix" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "accordion-name",
                                                      },
                                                      [
                                                        extra.PMSSpecialServiceCategory !=
                                                        "Notes"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  extra.PMSSpecialServiceName
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "float-right",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass: "arrow",
                                                        }),
                                                        _vm._v(" "),
                                                        extra.Price != "0.00"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "£" +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "price"
                                                                    )(
                                                                      extra.Price
                                                                    )
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class:
                                                  extra.PMSSpecialServiceCategory ==
                                                  "Notes"
                                                    ? ""
                                                    : "collapse",
                                                attrs: {
                                                  id:
                                                    "collapse" +
                                                    extra.PMSItemId +
                                                    k,
                                                  "aria-labelledby":
                                                    "heading" +
                                                    extra.PMSItemId +
                                                    k,
                                                  "data-parent":
                                                    "#acc-" +
                                                    cat.replace(
                                                      /[^a-zA-Z0-9-_]+/g,
                                                      "-"
                                                    ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accordion-body",
                                                  },
                                                  [
                                                    extra.ImageURL &&
                                                    extra.ImageURL.trim() !== ""
                                                      ? _c("div", {
                                                          staticClass:
                                                            "room-package-image",
                                                          style:
                                                            "background-image:url(https://barons-eden-cdn.fra1.digitaloceanspaces.com" +
                                                            extra.ImageURL.replace(
                                                              "/storage",
                                                              ""
                                                            ) // Remove '/storage'
                                                              .replace(
                                                                /^\s*-+|-\s*$/g,
                                                                ""
                                                              ) // Remove leading/trailing hyphens and spaces
                                                              .trim() + // Remove leading/trailing whitespace
                                                            ");",
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    extra.PMSSpecialServiceCategory !=
                                                    "Notes"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mb-3 d-inline-block text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ffs text-uppercase",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        Quantity\n                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "plus-minus",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-plus-minus",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.subtract(
                                                                            extra
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                            -\n                                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "book-int",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        extra.qty
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-plus-minus",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.add(
                                                                            extra
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                            +\n                                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass: "mb-3",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          extra.PMSSpecialServiceDescription
                                                        ),
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "extra-note",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group",
                                                          },
                                                          [
                                                            _c("textarea", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    extra.note,
                                                                  expression:
                                                                    "\n                                                                extra.note\n                                                            ",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id: "ssNote",
                                                                rows: "3",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  extra.note,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      extra,
                                                                      "note",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-secondary btn-block",
                                                          attrs: {
                                                            type: "submit",
                                                            disabled:
                                                              _vm.addingExtra,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.addExtra(
                                                                extra
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        Add to booking\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.addingExtra
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "spinner-border spinner-border-sm",
                                                              attrs: {
                                                                role: "status",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sr-only",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Loading..."
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "additional-finishing-touches-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("h5", { staticClass: "brandHeading text-center mb-4" }, [
                  _vm._v(
                    "\n                        WHY NOT MAKE YOUR EXPERIENCE EVEN MORE MEMORABLE?\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 text-center" }, [
                  _vm._v(
                    "\n                        Would you like to take your spa experience to a new\n                        level, booking a finishing touch or two? Surprise a\n                        loved one, get the party started early or indulge\n                        even further...\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill mr-2",
                      attrs: { "data-dismiss": "modal" },
                    },
                    [
                      _vm._v(
                        "\n                            Yes please\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary flex-fill ml-2",
                      attrs: { "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.nextStep()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            No thanks\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "gc-box" }, [
          _c("h5", [_vm._v("Redeeming a Gift Card or E-voucher?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                            Did you know, all of our gift vouchers hold a\n                            monetary value and can be redeemed against any\n                            package. Simply select your chosen package, then\n                            enter your voucher code at checkout to redeem.\n                        "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("strong", [_vm._v("Please select your preferred time")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }