/* // GA Virtual Pageview
window.vpv = function(url) {
    if("ga" in window && typeof ga.getAll === 'function') {
        var tracker = ga.getAll()[0];

        tracker.send('pageview', url)
    }
} */

// GA Event Tracking
window.handleButtonClick = function (sectionName, buttonText, buttonUrl, venue) {
    dataLayer.push({
        'event': 'select_content',
        'content_name': buttonText,
        'content_type': 'button',
        'section_name': sectionName,
        'button_url': buttonUrl,
        'venue': venue
    });
}

// FB Phone handler
window.leadContact = function (venue) {
    dataLayer.push({
        'event': 'generate_lead',
        'lead_type': 'lead_contact',
        'venue': venue
    });
}

// Grab UTM Params to do stuff with
var uriQuery = new URI(window.location.search)
var campaign = uriQuery.search(true) // params to obj
if (!_.isEmpty(campaign) && ('utm_campaign' in campaign)) {
    campaign['referrer'] = window.RS.referrer
    campaign['landing'] = window.location.href.split('?')[0]
    campaign['clientIp'] = window.RS.client_ip
    Cookies.set('data_first', $.param(campaign), { expires: 30 })
}

// We have UTM Params
if (!_.isEmpty(campaign) && ((('utm_campaign' in campaign) && (campaign.utm_campaign.toLowerCase().indexOf('ltv') !== -1 || campaign.utm_campaign.toLowerCase().indexOf('nca') !== -1)) || ('gclid' in campaign))) {
    Cookies.set('beCampaign', $.param(campaign), { expires: 30 })
}
