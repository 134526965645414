var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("header", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("div", { staticClass: "members-logo text-center" }, [
              _vm.venue == "eden-hall"
                ? _c("a", { attrs: { href: "/eden-hall/members-portal" } }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        loading: "lazy",
                        src: "/img/eh_members.svg",
                        alt: "Eden Hall Members Logo",
                      },
                    }),
                  ])
                : _c(
                    "a",
                    { attrs: { href: "/hoar-cross-hall/members-portal" } },
                    [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          loading: "lazy",
                          src: "/img/hch_members.svg",
                          alt: "Hoar Cross Hall Members Logo",
                        },
                      }),
                    ]
                  ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "portal-login" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("h3", { staticClass: "text-center" }, [_vm._v("Please log in")]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "portal-login-body" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "portalEmail" } }, [
                        _vm._v("Your email address"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "portalEmail",
                          "aria-describedby": "emailHelp",
                          placeholder: "Enter email",
                          required: "",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          change: _vm.clearError,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "portalPassword" } }, [
                        _vm._v("Your password"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          id: "portalPassword",
                          "aria-describedby": "passwordlHelp",
                          placeholder: "Enter password",
                          required: "",
                        },
                        domProps: { value: _vm.password },
                        on: {
                          change: _vm.clearError,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c(
                        "a",
                        {
                          staticClass: "small-text",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.forgotPassword.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Forgot password")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _vm.loading
                        ? _c("span", [
                            _c("div", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status" },
                            }),
                            _vm._v(
                              "\n                                        Loading..."
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "validation" }, [
                        _vm._v(_vm._s(_vm.loginError)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [
          _vm._v(
            "\n                                Close\n                            "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [
          _vm._v(
            "\n                                Login\n                            "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }