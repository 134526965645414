var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "form-inline",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.subscribe.apply(null, arguments)
          },
        },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailAddress,
              expression: "emailAddress",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "email", placeholder: "enter email*", required: "" },
          domProps: { value: _vm.emailAddress },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.emailAddress = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm.location == "Hoar Cross Hall"
          ? _c("input", {
              staticClass: "brand-overlay",
              attrs: {
                type: "image",
                value: "submit",
                src: "/img/best-price/hch-arrow.svg",
                alt: "submit Button",
              },
            })
          : _vm.location == "Eden Hall"
          ? _c("input", {
              staticClass: "brand-overlay",
              attrs: {
                type: "image",
                value: "submit",
                src: "/img/best-price/eh-arrow.svg",
                alt: "submit Button",
              },
            })
          : _c("input", {
              staticClass: "brand-overlay",
              attrs: {
                type: "image",
                value: "submit",
                src: "/img/best-price/hch-arrow.svg",
                alt: "submit Button",
              },
            }),
      ]
    ),
    _vm._v(" "),
    _vm.success
      ? _c("p", { staticClass: "signup-message" }, [
          _vm._v("Thanks for signing up!"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }