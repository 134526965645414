var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col-md-8 text-center" }, [
        _c("div", { staticClass: "book-start" }, [
          _c("div", { staticClass: "min-300" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.availability.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "mb-5" }, [
                  _c("div", { staticClass: "form-group brand-select mb-2" }, [
                    _vm.venue == "hoar-cross-hall"
                      ? _c("img", {
                          attrs: {
                            src: "/img/hch_cream_full.svg",
                            alt: "Hoar Cross Hall logo",
                            loading: "lazy",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.venue == "eden-hall"
                      ? _c("img", {
                          attrs: {
                            src: "/img/eh_cream_full.svg",
                            alt: "Eden Hall logo",
                            loading: "lazy",
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "small ffss",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.backStep()
                        },
                      },
                    },
                    [_vm._v("Back a step")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("h5", { staticClass: "mb-4" }, [
                    _vm._v(
                      "\n                                What type of experience did you have in\n                                mind?\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.venue == "hoar-cross-hall"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("hotel-stay")
                                },
                              },
                            },
                            [_vm._v("Hotel Stay")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("stay")
                                },
                              },
                            },
                            [_vm._v("Spa Stay")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("spa-day")
                                },
                              },
                            },
                            [_vm._v("Spa Day")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("treatments")
                                },
                              },
                            },
                            [_vm._v("Treatments")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("afternoon-tea")
                                },
                              },
                            },
                            [_vm._v("Afternoon Tea")]
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "mb-3" }, [
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("spa-day")
                                },
                              },
                            },
                            [_vm._v("Spa Day")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary btn-block type-select",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectType("treatments")
                                },
                              },
                            },
                            [_vm._v("Treatments")]
                          ),
                        ]),
                      ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.bookingExists
      ? _c("div", { staticClass: "modal-message existing-booking-modal" }, [
          _c("div", { staticClass: "modal-message-container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", [
              _vm._v("It looks like you already have a booking in the cart."),
            ]),
            _vm._v(" "),
            _c("h5", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.bookingExists.RateDetails) +
                  " at\n                " +
                  _vm._s(_vm._f("nicevenue")(_vm.bookingExists.Location)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("h6", [
              _vm._v(_vm._s(_vm._f("rsnicedate")(_vm.bookingExists.StartDate))),
            ]),
            _vm._v(" "),
            _c("h6", [_vm._v("Guests: " + _vm._s(_vm.bookingExists.guests))]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("p", [_vm._v("What would you like to do?")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.continueBooking("enhance")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Continue with this booking\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.continueBooking("booking")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Add another booking to this day\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.restartBooking()
                    },
                  },
                },
                [_vm._v("\n                    Start over\n                ")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [_c("span", [_vm._v("Hey")]), _vm._v(" there!")])
  },
]
render._withStripped = true

export { render, staticRenderFns }