<template>
    <form @submit.prevent="enquire">
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.firstName"
                    type="text"
                    placeholder="First Name*"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.lastName"
                    type="text"
                    placeholder="Last Name*"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <input
                    v-model="formData.tel"
                    type="tel"
                    placeholder="Phone (optional)"
                    class="form-control"
                />
            </div>
            <div class="col-md-6">
                <input
                    v-model="formData.emailAddress"
                    type="email"
                    placeholder="Email*"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div v-if="venue == 'eden-hall'" class="form-group row">
            <div class="col-md-12">
                <select v-model="formData.plan" class="form-control" id="plan">
                    <option value="">Select membership type</option>
                    <option value="House">House</option>
                    <option value="Platinum">Platinum</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12">
                <textarea
                    v-model="formData.message"
                    placeholder="Your enquiry"
                    class="form-control"
                ></textarea>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <div class="form-check">
                    <label class="form-check-label float-left" for="marketing">
                        <input
                            v-model="formData.marketing"
                            type="checkbox"
                            class="form-check-input"
                            id="marketing"
                        />
                        <span class="checkmark"></span>
                        Tick to receive the latest news, events and exclusive
                        offers.
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <button type="submit" class="btn btn-reversed float-right">
                    Submit
                </button>
            </div>
        </div>
        <p class="signup-message" v-if="success">
            Thank you for your enquiry. We will be in touch with you shortly.
        </p>
    </form>
</template>

<script>
var formTemplate = function () {
    return {
        firstName: null,
        lastName: null,
        tel: null,
        emailAddress: null,
        plan: "",
        message: null,
        marketing: false,
    };
};
export default {
    props: {
        email: String,
        venue: String,
    },
    data() {
        return {
            formData: new formTemplate(),
            success: null,
        };
    },

    mounted() {},

    methods: {
        enquire() {
            var self = this;
            window.getRecaptchaToken().then(function (r) {
                var data = {
                    email: self.email,
                    venue: self.venue,
                    formData: self.formData,
                    recaptcha: r,
                };
                axios
                    .post("/system/subscribe/membership", data)
                    .then((response) => {
                        self.success = true;
                        self.formData = new formTemplate();

                        dataLayer.push({
                            event: "form_submission",
                            form_type: "lead_form",
                            form_subject: "Membership Enquiry",
                            venue: self.venue,
                        });

                        this.$emitAnalytics(
                            "form_submission",
                            {
                                event: "form_submission",
                                form_type: "lead_form",
                                form_subject: "Membership Enquiry",
                                venue: self.venue,
                            },
                            self.system.CustomerId,
                            self.system.WebfolioId
                        );
                    });
            });
        },
    },
};
</script>
