<template>
    <div class="gift-card-component">
        <form @submit.prevent="addToCart" class="gift-card-order">
            <div class="voucher-tabs">
                <div v-if="giftCard">
                    <div
                        v-if="packages.length > 1 && giftCardType == 'generic'"
                    >
                        <h4 class="mb-5 ffs fwb">From: &pound;{{ price }}</h4>
                        <h5>Select option</h5>
                        <div class="form-row mb-3">
                            <div class="form-group col-md-12">
                                <select
                                    v-model="giftCard"
                                    name="Country"
                                    class="form-control"
                                >
                                    <option v-for="gc in packages" :value="gc">
                                        {{ gc.Name }} - &pound;{{ gc.Price }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="gift-card-order__price">
                            Price: &pound;{{ giftCard.Price }}
                        </div>
                    </div>

                    <div v-if="giftCardType === 'spa-stay'">
                        <hr />

                        <h5 class="mb-4">It's time to tailor your gift...</h5>

                        <h5>Time of Week</h5>

                        <div class="row mb-2">
                            <div class="col-sm-6 text-center">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': timeOfWeek == 'mid',
                                        'btn-outline-gold':
                                            timeOfWeek == 'weekend',
                                    }"
                                    @click="setTimeOfWeek('mid')"
                                >
                                    Midweek
                                </button>
                                <p>{{ midweekLabel }}</p>
                            </div>
                            <div class="col-sm-6 text-center">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': timeOfWeek == 'weekend',
                                        'btn-outline-gold': timeOfWeek == 'mid',
                                    }"
                                    @click="setTimeOfWeek('weekend')"
                                >
                                    Weekend
                                </button>
                                <p>{{ weekendLabel }}</p>
                            </div>
                        </div>

                        <div v-if="enableRoomTypes">
                            <h5>Room Type</h5>

                            <div class="row mb-2">
                                <div class="col-sm-6 text-center">
                                    <button
                                        type="button"
                                        class="btn btn-block"
                                        :class="{
                                            'btn-gold':
                                                roomType == 'comfy_room',
                                            'btn-outline-gold':
                                                roomType == 'comfy_suite',
                                        }"
                                        @click="setRoomType('comfy_room')"
                                    >
                                        Comfy Room
                                    </button>
                                    <p>
                                        <a
                                            href="/hoar-cross-hall/hotel-rooms/comfy"
                                            target="_blank"
                                            >View Room</a
                                        >
                                    </p>
                                </div>
                                <div class="col-sm-6 text-center">
                                    <button
                                        type="button"
                                        class="btn btn-block"
                                        :class="{
                                            'btn-gold':
                                                roomType == 'comfy_suite',
                                            'btn-outline-gold':
                                                roomType == 'comfy_room',
                                        }"
                                        @click="setRoomType('comfy_suite')"
                                    >
                                        Comfy Suite
                                    </button>
                                    <p>
                                        <a
                                            href="/hoar-cross-hall/hotel-rooms/comfy-suite"
                                            target="_blank"
                                            >View Room</a
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div v-if="!treatmentsInclusive">
                            <h5>Treatment Included?</h5>

                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <button
                                        type="button"
                                        class="btn btn-block"
                                        :class="{
                                            'btn-gold': treatmentIncluded,
                                            'btn-outline-gold':
                                                !treatmentIncluded,
                                        }"
                                        @click="setTreatmentIncluded(true)"
                                    >
                                        Yes Please
                                    </button>
                                </div>
                                <div class="col-sm-6">
                                    <button
                                        type="button"
                                        class="btn btn-block"
                                        :class="{
                                            'btn-gold': !treatmentIncluded,
                                            'btn-outline-gold':
                                                treatmentIncluded,
                                        }"
                                        @click="setTreatmentIncluded(false)"
                                    >
                                        No Thanks
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 text-center">
                                    <p>
                                        Recipient will be able to choose from
                                        our
                                        <a
                                            :href="collectionUrl"
                                            target="_blank"
                                            v-if="collectionUrl"
                                            >{{ collectionLabel }}</a
                                        >
                                        <span v-if="!collectionUrl"
                                            >{{ collectionLabel }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="giftCardType === 'spa-day'">
                        <hr />

                        <h5 class="mb-4">It's time to tailor your gift...</h5>

                        <div v-if="enableNumberOfGuests">
                            <h5>Number of Guests</h5>

                            <div class="row mb-4">
                                <div class="col-sm-6">
                                    <select
                                        class="form-control"
                                        v-model="numOfGuests"
                                    >
                                        <option value="1">1 Guests</option>
                                        <option value="2">2 Guests</option>
                                        <option value="3">3 Guests</option>
                                        <option value="4">4 Guests</option>
                                        <option value="5">5 Guests</option>
                                        <option value="6">6 Guests</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <h5>Time of Week</h5>

                        <div class="row mb-2">
                            <div class="col-sm-6 text-center">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': timeOfWeek == 'mid',
                                        'btn-outline-gold':
                                            timeOfWeek == 'weekend',
                                    }"
                                    @click="setTimeOfWeek('mid')"
                                >
                                    Midweek
                                </button>
                                <p>{{ midweekLabel }}</p>
                            </div>
                            <div class="col-sm-6 text-center">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': timeOfWeek == 'weekend',
                                        'btn-outline-gold': timeOfWeek == 'mid',
                                    }"
                                    @click="setTimeOfWeek('weekend')"
                                >
                                    Weekend
                                </button>
                                <p>{{ weekendLabel }}</p>
                            </div>
                        </div>

                        <div v-if="!treatmentsInclusive">
                            <h5>Treatment Included?</h5>

                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <button
                                        type="button"
                                        class="btn btn-block"
                                        :class="{
                                            'btn-gold': treatmentIncluded,
                                            'btn-outline-gold':
                                                !treatmentIncluded,
                                        }"
                                        @click="setTreatmentIncluded(true)"
                                    >
                                        Yes Please
                                    </button>
                                </div>
                                <div class="col-sm-6">
                                    <button
                                        type="button"
                                        class="btn btn-block"
                                        :class="{
                                            'btn-gold': !treatmentIncluded,
                                            'btn-outline-gold':
                                                treatmentIncluded,
                                        }"
                                        @click="setTreatmentIncluded(false)"
                                    >
                                        No Thanks
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 text-center">
                                    <p>
                                        Recipient will be able to choose from
                                        our
                                        <a
                                            :href="collectionUrl"
                                            target="_blank"
                                            v-if="collectionUrl"
                                            >{{ collectionLabel }}</a
                                        >
                                        <span v-if="!collectionUrl"
                                            >{{ collectionLabel }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="giftCardType === 'afternoon-tea'">
                        <hr />

                        <h5 class="mb-4">It's time to tailor your gift...</h5>

                        <h5>Number of Guests</h5>

                        <div class="row mb-4">
                            <div class="col-sm-6">
                                <select
                                    class="form-control"
                                    v-model="numOfGuests"
                                >
                                    <option value="2">2 Guests</option>
                                    <option value="3">3 Guests</option>
                                    <option value="4">4 Guests</option>
                                    <option value="5">5 Guests</option>
                                    <option value="6">6 Guests</option>
                                </select>
                            </div>
                        </div>

                        <h5>Type of Afternoon Tea</h5>

                        <div class="row mb-2 text-center">
                            <div class="col-sm-4">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': teaType == 'classic',
                                        'btn-outline-gold':
                                            teaType !== 'classic',
                                    }"
                                    @click="setTeaType('classic')"
                                >
                                    Classic
                                </button>
                                <p>With Heritage Teas</p>
                            </div>
                            <div class="col-sm-4">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': teaType == 'sparkling',
                                        'btn-outline-gold':
                                            teaType !== 'sparkling',
                                    }"
                                    @click="setTeaType('sparkling')"
                                >
                                    Sparkling
                                </button>
                                <p>With Heritage Teas and Glass of Prosecco</p>
                            </div>
                            <div class="col-sm-4">
                                <button
                                    type="button"
                                    class="btn btn-block"
                                    :class="{
                                        'btn-gold': teaType == 'royal',
                                        'btn-outline-gold': teaType !== 'royal',
                                    }"
                                    @click="setTeaType('royal')"
                                >
                                    Royal
                                </button>
                                <p>With Heritage Teas and Glass of Champagne</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <h5>Recipient</h5>

                <p v-if="category != 'Event'">
                    Please enter your personalised message here<br />
                    (this will be printed on the gift voucher)
                </p>

                <h5>Name</h5>

                <div class="form-row">
                    <div class="form-group col-md-8">
                        <input
                            v-model="emailOrderForm.IssueToName"
                            type="text"
                            class="form-control"
                            id="IssueToName"
                            placeholder="Enter recipients name"
                            required
                        />
                    </div>
                </div>

                <div v-if="category != 'Event'">
                    <h5>Message <span class="small">(optional)</span></h5>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <textarea
                                v-model="emailOrderForm.Message"
                                cols="8"
                                rows="6"
                                class="form-control"
                                id="Message"
                                placeholder="Enter your message (max 300 characters)"
                                maxlength="300"
                            ></textarea>
                            <div class="small">
                                Characters remaining:
                                {{ 300 - emailOrderForm.Message.length }}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <h5 v-if="category != 'Event'" class="mt-3 mb-4">
                    Select your voucher type
                </h5>

                <div class="row">
                    <div class="col-md-12">
                        <ul
                            class="nav nav-tabs"
                            id="voucherTabs"
                            role="tablist"
                        >
                            <li class="nav-item" v-if="category != 'Event'">
                                <a
                                    class="nav-link"
                                    :class="{
                                        active: voucherType == 'CARD-GIFT',
                                    }"
                                    id="card-tab'"
                                    data-toggle="tab"
                                    @click.prevent="
                                        selectVoucherType('CARD-GIFT')
                                    "
                                    role="tab"
                                    aria-controls="card-gift-tab"
                                    :aria-selected="{
                                        true: voucherType == 'CARD-GIFT',
                                    }"
                                >
                                    Gift Card + Elemis Giftset
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                v-if="category != 'Event' && !disableShipping"
                            >
                                <a
                                    class="nav-link"
                                    :class="{ active: voucherType == 'CARD' }"
                                    id="card-tab'"
                                    data-toggle="tab"
                                    @click.prevent="selectVoucherType('CARD')"
                                    role="tab"
                                    aria-controls="card-tab"
                                    :aria-selected="{
                                        true: voucherType == 'CARD',
                                    }"
                                >
                                    Gift Card
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    :class="{ active: voucherType == 'CERT' }"
                                    id="cert-tab'"
                                    data-toggle="tab"
                                    @click.prevent="selectVoucherType('CERT')"
                                    role="tab"
                                    aria-controls="cert-tab"
                                    :aria-selected="{
                                        true: voucherType == 'CERT',
                                    }"
                                >
                                    E-Voucher
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="voucherTabsContent">
                            <div
                                v-if="tabsLoading"
                                class="d-flex justify-content-center my-4"
                            >
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>

                            <div
                                v-show="
                                    !tabsLoading &&
                                    voucherType == 'CARD-GIFT' &&
                                    category != 'Event'
                                "
                                id="cardGiftPanel"
                                role="tabpanel"
                                aria-labelledby="cardGiftPanel"
                            >
                                <div v-if="giftCard && giftCard.shippingRates">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5>Select your Elemis Giftset</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-6"
                                            v-for="s in giftCard.shippingRates"
                                            v-if="s.ShippingId !== 103012"
                                        >
                                            <div>
                                                <img
                                                    :src="getGiftImagery(s.Id)"
                                                    class="img-fluid mb-4"
                                                    loading="lazy"
                                                />

                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    :name="`gift-${s.Id}`"
                                                    :id="`gift-${s.Id}`"
                                                    :value="s"
                                                    v-model="shippingSelected"
                                                    checked
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="`gift-${s.Id}`"
                                                >
                                                    <h4>{{ s.Name }}</h4>
                                                    <hr />
                                                    <small>
                                                        <div
                                                            class="gift-description"
                                                            v-html="
                                                                getGiftDescription(
                                                                    s
                                                                )
                                                            "
                                                        ></div>
                                                    </small>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p v-if="category == 'Event'">
                                    The recipient is the name of the person
                                    attending the event.
                                </p>
                                <p v-else>
                                    The delivery address is the address of the
                                    person you would like us to send the gift
                                    card to. If you would like this delivered to
                                    yourself, please enter your own address.
                                </p>

                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <h5>Select postage</h5>
                                        <div
                                            role="group"
                                            aria-label="Postage Options"
                                            class="btn-group"
                                        >
                                            <button
                                                type="button"
                                                disabled="disabled"
                                                class="btn btn-secondary btn-shipping"
                                            >
                                                1st Class Post
                                            </button>
                                        </div>
                                        <div>
                                            <span
                                                class="small d-block text-uppercase fwb"
                                                >Shipping selected:</span
                                            >
                                            1st Class Post - £5.00
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <h5>Delivery address</h5>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .Address1
                                            "
                                            type="text"
                                            class="form-control"
                                            id="Address1"
                                            ref="Address1"
                                            placeholder="Address line 1"
                                            :required="voucherType == 'CARD'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .Address2
                                            "
                                            type="text"
                                            class="form-control"
                                            id="Address2"
                                            ref="Address2"
                                            placeholder="Address line 2"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .City
                                            "
                                            type="text"
                                            class="form-control"
                                            id="City"
                                            ref="City"
                                            placeholder="City"
                                            :required="voucherType == 'CARD'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .PostCode
                                            "
                                            type="text"
                                            class="form-control"
                                            id="PostCode"
                                            ref="PostCode"
                                            placeholder="Post Code"
                                            :required="voucherType == 'CARD'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <select
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .Country
                                            "
                                            name="Country"
                                            class="form-control"
                                        >
                                            <option
                                                v-for="country in countryList"
                                                :value="country"
                                            >
                                                {{ country }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p class="small">
                                            Please note, we allow a 48 hour
                                            processing time for all postal gift
                                            cards. Delivery by Royal Mail can
                                            take 5 -7 days, and we are unable to
                                            take any responsibility for Royal
                                            Mail delays.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <span
                                            class="small"
                                            v-if="voucherError"
                                            >{{ voucherError }}</span
                                        >
                                    </div>
                                </div>
                            </div>

                            <div
                                v-show="!tabsLoading && voucherType == 'CERT'"
                                id="certPanel"
                                role="tabpanel"
                                aria-labelledby="certPanel-tab"
                            >
                                <p v-if="category == 'Event'">
                                    The recipient is the name of the person
                                    attending the event.
                                </p>
                                <p v-else>
                                    The delivery address is the address of the
                                    person you would like us to send the gift
                                    card to. If you would like this delivered to
                                    yourself, please enter your own address.
                                </p>

                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <input
                                            v-model="
                                                emailOrderForm.EmailAddress
                                            "
                                            type="email"
                                            class="form-control"
                                            id="EmailAddress"
                                            placeholder="Enter recipients email address"
                                            :required="voucherType == 'CERT'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-10 mb-0">
                                        <span
                                            class="small"
                                            v-if="voucherError"
                                            >{{ voucherError }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                v-show="
                                    !tabsLoading &&
                                    voucherType == 'CARD' &&
                                    category != 'Event'
                                "
                                id="cardPanel"
                                role="tabpanel"
                                aria-labelledby="cardPanel-tab"
                            >
                                <p v-if="category == 'Event'">
                                    The recipient is the name of the person
                                    attending the event.
                                </p>
                                <p v-else>
                                    The delivery address is the address of the
                                    person you would like us to send the gift
                                    card to. If you would like this delivered to
                                    yourself, please enter your own address.
                                </p>

                                <div
                                    v-if="
                                        giftCard &&
                                        giftCard.shippingRates &&
                                        !disableShipping
                                    "
                                    class="form-row"
                                >
                                    <div class="form-group col-md-12">
                                        <h5>Select postage</h5>
                                        <div
                                            class="btn-group"
                                            role="group"
                                            aria-label="Postage Options"
                                        >
                                            <button
                                                v-for="s in giftCard.shippingRates"
                                                v-if="s.Price"
                                                type="button"
                                                @click.prevent="
                                                    selectShippingType(s)
                                                "
                                                :disabled="
                                                    s.ShippingId ==
                                                    shippingSelected.ShippingId
                                                "
                                                class="btn btn-secondary btn-shipping"
                                            >
                                                {{ s.Name }}
                                            </button>
                                        </div>
                                        <div v-if="shippingSelected">
                                            <span
                                                class="small d-block text-uppercase fwb"
                                                >Shipping selected:</span
                                            >
                                            {{ shippingSelected.Name }} -
                                            &pound;{{
                                                shippingSelected.PriceWithSurcharges
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <h5>Delivery address</h5>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-9">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .Address1
                                            "
                                            type="text"
                                            class="form-control"
                                            id="Address1"
                                            ref="Address1"
                                            placeholder="Address line 1"
                                            :required="voucherType == 'CARD'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .Address2
                                            "
                                            type="text"
                                            class="form-control"
                                            id="Address2"
                                            ref="Address2"
                                            placeholder="Address line 2"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .City
                                            "
                                            type="text"
                                            class="form-control"
                                            id="City"
                                            ref="City"
                                            placeholder="City"
                                            :required="voucherType == 'CARD'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <input
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .PostCode
                                            "
                                            type="text"
                                            class="form-control"
                                            id="PostCode"
                                            ref="PostCode"
                                            placeholder="Post Code"
                                            :required="voucherType == 'CARD'"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <select
                                            v-model="
                                                emailOrderForm.MailToAddress
                                                    .Country
                                            "
                                            name="Country"
                                            class="form-control"
                                        >
                                            <option
                                                v-for="country in countryList"
                                                :value="country"
                                            >
                                                {{ country }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p class="small">
                                            Please note, we allow a 48 hour
                                            processing time for all postal gift
                                            cards. Delivery by Royal Mail can
                                            take 5 -7 days, and we are unable to
                                            take any responsibility for Royal
                                            Mail delays.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <span
                                            class="small"
                                            v-if="voucherError"
                                            >{{ voucherError }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <div class="form-group col-md-12">
                                <a
                                    v-if="category != 'Event'"
                                    href="/gift-cards"
                                    class="btn btn-primary"
                                    >Back to all Gift Cards</a
                                >
                                <button
                                    type="submit"
                                    class="btn btn-reversed"
                                    :disabled="addingGc"
                                >
                                    Add to cart
                                </button>
                                <div
                                    v-if="addingGc"
                                    class="spinner-border spinner-border-md"
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="gift-card-cart">
            <gift-card-cart
                :system="system"
                :cart="cart"
                next="cart"
            ></gift-card-cart>
        </div>
    </div>
</template>

<script>
var emailOrderFormTemplate = function () {
    return {
        SessionId: null,
        WebFolioId: null,
        CustomerId: 24,
        EmailAddress: null,
        IssueToName: null,
        ItemId: null,
        Layout: 1,
        MailToAddress: {
            type: "HOME",
            Address1: null,
            Address2: null,
            City: null,
            StateProv: null,
            Country: "United Kingdom",
            PostCode: null,
        },
        Message: "",
        Method: "E",
        WrappingId: null,
        ShippingId: null,
        PackageCertificate: null,
    };
};
var packageTemplate = function () {
    return {
        PackageItemId: null,
        EmailAddress: null,
        IssueToName: null,
        Layout: 1,
        Message: null,
    };
};
import GiftCardCart from "../components/GiftCardCart.vue";
import { countries } from "../countries.js";
export default {
    props: {
        system: Object,
        cart: Object,
        gcid: String,
        category: String,
        price: String,
        giftCardIds: Object,
        giftCardType: String,
        giftDetails: Array,
        treatmentsInclusive: Number,
        enableRoomTypes: Number,
        enableNumberOfGuests: Number,
        disableShipping: Number,
        venue: String,
        weekendLabel: String,
        midweekLabel: String,
        collectionLabel: String,
        collectionUrl: String,
    },

    components: {
        GiftCardCart,
    },

    data() {
        return {
            giftCards: null,
            shippingOptions: null,
            packages: [],
            emailOrderForm: emailOrderFormTemplate(),
            deliveryType: "E",
            shippingSelected: null,
            giftCard: null,
            voucherType: this.giftCardType == "monetary" ? "CARD" : "CARD-GIFT",
            voucherError: null,
            countryList: countries,
            addingGc: false,
            giftCardId: null,
            timeOfWeek: "mid",
            roomType: "comfy_room",
            treatmentIncluded: true,
            numOfGuests: 2,
            teaType: "classic",
            tabsLoading: false,
        };
    },

    mounted() {
        Bus.$emit("RefreshSystem");
        this.setGiftCardId();
        this.fetchGiftCards();
    },

    watch: {
        numOfGuests: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setGiftCardId();
            }
        },
    },
    methods: {
        getGiftImagery(id) {
            var index = this.giftDetails.findIndex(
                (gift) => gift.attributes.gift_shipping_id == id
            );

            if (this.giftDetails[index]) {
                return (
                    "/storage/" + this.giftDetails[index].attributes.gift_img
                );
            } else {
                return "/storage/" + id + ".webp";
            }
        },
        getGiftDescription(gift) {
            var index = this.giftDetails.findIndex(
                (g) => g.attributes.gift_shipping_id == gift.Id
            );

            if (this.giftDetails[index]) {
                return this.giftDetails[index].attributes.gift_description;
            } else {
                return gift.Description;
            }
        },
        setTimeOfWeek(time) {
            this.timeOfWeek = time;
            this.setGiftCardId();
        },
        setRoomType(type) {
            this.roomType = type;
            this.setGiftCardId();
        },
        setTreatmentIncluded(treatment) {
            this.treatmentIncluded = treatment;
            this.setGiftCardId();
        },
        setTeaType(type) {
            this.teaType = type;
            this.setGiftCardId();
        },
        setGiftCardId() {
            this.tabsLoading = true;

            if (this.giftCardType == "spa-stay") {
                const key = `${this.timeOfWeek}_${this.roomType}_${
                    this.treatmentIncluded ? "treatment" : "no_treatment"
                }`;
                this.giftCardId = this.giftCardIds[key] || null;
            } else if (this.giftCardType == "afternoon-tea") {
                const key = `${this.numOfGuests}_guest_${this.teaType}`;
                this.giftCardId = this.giftCardIds[key] || null;
            } else if (this.giftCardType == "spa-day") {
                const key = `${this.numOfGuests}_guest_${this.timeOfWeek}_${
                    this.treatmentIncluded ? "treatment" : "no_treatment"
                }`;
                this.giftCardId = this.giftCardIds[key] || null;
            } else {
                this.giftCardId = null;
            }

            if (this.giftCard !== null && this.giftCards.length > 0) {
                this.giftCard = this.giftCards.find(
                    (gift) => gift.ItemId == this.giftCardId
                );
            }

            this.fetchGiftCards();
        },
        selectVoucherType(type) {
            this.tabsLoading = true;
            this.voucherType = type;

            if (type == "CERT") {
                this.deliveryType = "E";
            } else {
                this.deliveryType = "M";
            }

            this.fetchGiftCards();
        },
        selectShippingType(shipping) {
            this.shippingSelected = shipping;
        },
        fetchGiftCards() {
            var self = this;
            var request = {
                method: "FetchGiftCertificates",
                data: {
                    WebFolioId: null,
                    Language: null,
                },
            };
            axios.post("/rs/request", request).then((response) => {
                if (
                    response.data.Shippings.Shipping &&
                    !$.isArray(response.data.Shippings.Shipping)
                ) {
                    // convert single entry to an array
                    response.data.Shippings.Shipping = [
                        response.data.Shippings.Shipping,
                    ];
                }

                this.shippingOptions = response.data.Shippings.Shipping;
                this.giftCards = response.data.GiftCertificate;

                if (this.giftCardType == "generic") {
                    var gcIds = this.giftCardIds.item_ids.split(/[ ,]+/);
                    var gcArray = _.filter(
                        response.data.GiftCertificate,
                        function (o) {
                            if (_.includes(gcIds, o.ItemId.toString())) {
                                return o;
                            }
                        }
                    );
                } else {
                    var gcArray = _.filter(
                        response.data.GiftCertificate,
                        function (o) {
                            if (self.giftCardId == o.ItemId.toString()) {
                                return o;
                            }
                        }
                    );
                }

                // Now loop through each GC
                _.each(gcArray, function (gc) {
                    gc["shippingRates"] = null;

                    if (!_.isEmpty(gc.Shippings)) {
                        gc["shippingRates"] = [];

                        _.each(gc.Shippings.Shipping, function (s) {
                            if (self.voucherType == "CARD-GIFT") {
                                var shippingToFilter = [10303, 10302, 10301];
                            } else if (self.voucherType == "CARD") {
                                var shippingToFilter = [
                                    10303, 103011, 103012, 103014, 103016,
                                ];
                            } else {
                                var shippingToFilter = [10302, 10301];
                            }

                            if (shippingToFilter.includes(s.Id) == false) {
                                var sr = _.merge(
                                    {},
                                    s,
                                    _.find(self.shippingOptions, {
                                        ShippingId: s.Id,
                                    })
                                );

                                if (self.voucherType == "CARD") {
                                    if (sr.ShippingId == 10301) {
                                        self.shippingSelected = sr;
                                    }
                                }

                                if (self.voucherType == "CERT") {
                                    if (sr.ShippingId == 10303) {
                                        self.shippingSelected = sr;
                                    }
                                }

                                if (self.voucherType == "CARD-GIFT") {
                                    if (sr.ShippingId == 103016) {
                                        self.shippingSelected = sr;
                                    }
                                }

                                gc.shippingRates.push(sr);
                            }
                        });
                    }
                });

                this.giftCard = gcArray[0];

                this.tabsLoading = false;
            });
        },
        addToCart() {
            this.emailOrderForm.SessionId = this.system.SessionId;
            this.emailOrderForm.WebFolioId = this.system.WebFolioId;
            this.emailOrderForm.CustomerId = this.system.WebFolioCustomerId;
            this.emailOrderForm.Method = this.deliveryType;

            this.emailOrderForm.MailToAddress.Address1 =
                this.$refs.Address1.value;
            this.emailOrderForm.MailToAddress.Address2 =
                this.$refs.Address2.value;
            this.emailOrderForm.MailToAddress.City = this.$refs.City.value;
            this.emailOrderForm.MailToAddress.PostCode =
                this.$refs.PostCode.value;

            this.addingGc = true;

            var self = this;

            this.emailOrderForm.ItemId = this.giftCard.ItemId;

            if (this.shippingSelected) {
                this.emailOrderForm.ShippingId = this.shippingSelected.Id;
            }

            var gc = {
                method: "PurchaseGiftCertificate",
                data: this.emailOrderForm,
            };
            axios.post("/rs/request", gc).then((response) => {
                if (response.data.Result.value === "SUCCESS") {
                    // Added to WebFolio
                    dataLayer.push({
                        event: "add_to_cart",
                        venue: "Barons Eden",
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                // 'add' actionFieldObject measures.
                                products: [
                                    {
                                        //  adding a product to a shopping cart.
                                        name: this.giftCard.Name,
                                        id: this.giftCard.ItemId,
                                        price: +this.giftCard.Price,
                                        brand: "Barons Eden",
                                        category: "Gift Cards",
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    });

                    this.$emitAnalytics(
                        "add_to_cart",
                        {
                            event: "add_to_cart",
                            venue: "Barons Eden",
                            ecommerce: {
                                currency: "GBP",
                                add: {
                                    products: [
                                        {
                                            name: this.giftCard.Name,
                                            id: this.giftCard.ItemId,
                                            price: +this.giftCard.Price,
                                            brand: "Barons Eden",
                                            category: "Gift Cards",
                                            quantity: 1,
                                        },
                                    ],
                                },
                            },
                        },
                        this.system.CustomerId,
                        this.system.WebfolioId
                    );

                    this.emailOrderForm = emailOrderFormTemplate();
                    Bus.$emit("UpdateCart");
                } else {
                    this.voucherError = response.data;
                }
                this.addingGc = false;
            });
        },
    },
};
</script>

<style lang="scss">
.gift-card-order__price {
    font-family: "filson-pro", sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    color: #9d7b43;
    text-transform: uppercase;
}

.gift-description {
    ul {
        padding-left: 16px;

        li {
            line-height: 1.3;
            margin-bottom: 5px;

            p {
                font-weight: normal;
                margin-bottom: 0px;
            }
        }
    }
}

.gift-card-component {
    .form-check-input {
        margin-top: 5px;
        margin-left: 0px;
        position: absolute;
        opacity: 1;
        cursor: pointer;
        height: auto;
        width: auto;
        accent-color: black;
    }

    .form-check-label {
        font-family: "filson-pro", sans-serif;
        cursor: pointer;

        hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        h4 {
            font-family: "filson-pro", sans-serif;
            font-size: 15px;
            color: #000;
            margin: 0;
        }

        small {
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.nav-link.active {
    text-decoration: none;
}
</style>
