<template>
    <div class="booking-app">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <h1 class="brandHeading mb-4">Reset your Password</h1>
                    <p class="mb-5">To reset your password, enter the email address for your account. An email will be sent to you with the details.</p>
                    <form @submit.prevent="resetPassword">
                        <div class="form-row">
                            <div class="col-sm-7 mb-3">
                                <input v-model="email" type="email" class="form-control" id="email" placeholder="Enter email address" required>
                            </div>
                            <div class="col">
                                <button type="submit" class="btn btn-primary">Reset password</button>
                            </div>
                        </div>
                    </form>
                    <div v-if="resetStatus" class="message">
                        {{ resetStatus }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cart: Object,
            system: Object
        },
        /**
         * The components data
         */
        data() {
            return {
                email: null,
                resetStatus: null,
            }
        },

        mounted() {

        },

        /**
         * Methods
         */
        methods: {
            resetPassword() {
                Bus.$emit('showLoading')
                this.error = null
                var request = {
                    method: 'ResetPasswordCustomer',
                    data: { 
                        EmailAddress: this.email,
                        PasswordGUID: null,
                        NewPassword: null,
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            this.resetStatus = 'Request received. If your email address is registered with you will receive an email within the next 5 minutes.'
                        } else {
                            this.resetStatus = 'We were unable to find an account with the provided email address, please contact our reservations team on 01283 576522.'
                        }
                        this.email = null
                        Bus.$emit('hideLoading')
                    });

            },

        }
    }
</script>
