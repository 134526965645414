<template>
    <div class="book-component">
        <div class="col-md-12">
            <h3>Check Availability</h3>
        </div>
        <div class="calendar">
            <v-calendar
                mode="range"
                v-model="range"
                :min-date='calStart'
                :max-date='null'
                :attributes='atts'
                @dayclick="dayClick($event)"
                @update:to-page="nextMonth($event)"
                is-expanded
                is-inline
                is-linked
                >
                <div
                    slot="day-content"
                    slot-scope="{ day, attributes, dayEvents }"
                    :day='day'
                    v-on="dayEvents"
                    class="vc-day-content"
                    :class="{ daySelected: day.id == range, 'vc-pointer-events-none vc-text-gray-400': !attributes }"
                    >
                    <div>
                        <div class="text-center">{{ day.day }}</div>
                        <div class="vc-day-content__rate" v-for="{key, customData} in attributes"
                            :key="key">
                            &pound;{{ customData.SingleRate }}
                        </div>
                    </div>
                </div>
            </v-calendar>
        </div>

        <div class="book-component-selector">
            <ul>
                <li style="display:none;">
                    <p>Guests</p>
                    <button @click="decGuests" class="btn btn-plus-minus">-</button>
                    <span class="book-int">{{ guests }}</span>
                    <button @click="incGuests" class="btn btn-plus-minus">+</button>
                </li>
                <li>
                    <p v-if="fetchingRates" class="text-center"><span class="spinner-border spinner-border-sm" role="status"></span> Fetching rates</p>
                    <p v-if="noAvailability" class="text-center">No availability</p>
                    <button class="btn mt-3" type="button" @click="checkAvailability()" :class="{ disabled: isDisabled }" :disabled="isDisabled">
                        Book Now
                    </button>
                </li>
            </ul>
        </div>
    </div>
        
</template>

<script>
    import VCalendar from 'v-calendar';
    export default {
        props: {
            rate: String,
            venue: String,
            start: String,
            package: String,
            weekday: Number
        },
        data() {
            return {
                venueName: this.venue == 'hoar-cross-hall' ? this.$options.filters.venue('hoar-cross-hall-day-spa') : this.$options.filters.venue(this.venue),
                promoCode: null,
                RateType: this.rate,
                urlRate: this.rate ? this.rate.split(" ").join('-') : null,
                range: null,
                guests: 2,
                calStart: this.start && moment(this.start) > moment() ? moment(this.start).format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
                endOfMonth: moment().endOf('month').format('YYYY-MM-DD'),
                calendarRates: [],
                calendarMonths: {},
                allRates: null,
                atts: null,
                tempEnd: null,
                fetchingRates: null,
                noAvailability: false,
                price: null,
                calendarAvailability: {},
                calPage: null
            }
        },

        mounted() {

            if(this.rate) {
                
                var start = this.start && moment(this.start) > moment() ? moment(this.start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
                var end = moment(start).endOf("month").format('YYYY-MM-DD')

                this.getRates(start, end)
            }
        },

        computed: {
            isDisabled () {
                if (this.range) {
                    return false;
                } else {
                    return true;
                }
            },
            hasAvailability() {
                if(this.calPage && this.calendarAvailability[this.calPage.month]) {
                    return this.calendarAvailability[this.calPage.month]
                }
            }
        },

        watch: {
            calendarRates: function() {
                var noDupes = this.calendarRates.map(rate => ({
                        dates: rate.date,
                        customData: rate,
                    }));
                this.atts = _.uniqBy(noDupes, 'dates')
            }
        },

        methods: {
            nextMonth(next) {
                
                if(this.rate && next.month != moment().format('M')) {
                    if(!this.calendarMonths[next.year + '-' + next.month]) {
                        this.getRates(moment([next.year, next.month - 1]).startOf('month').format('YYYY-MM-DD'), moment([next.year, next.month - 1]).endOf("month").add(1, 'days').format('YYYY-MM-DD'))
                    }
                    this.calendarMonths[next.year + '-' + next.month] = true;
                }
            },
            dayClick(e) {
                this.range = e.id
                this.price = e.attributes[0].customData.SingleRate
            },
            // Guests
            decGuests() {
                if(this.guests > 1) {
                    this.guests--
                }
            },
            incGuests() {
                if(this.guests < 4) {
                    this.guests++
                }
            },
            checkAvailability() {
                var venue = this.venue;
                var nexturl = '/book/booking/' + venue + '/spa-day/' + moment(this.range).format('YYYY-MM-DD') + '/' + this.guests;
                var fbVenue = this.venue == 'eden-hall' ? 'Eden Hall' : 'Hoar Cross Hall'
                window.location.href = nexturl;
            },
            getRates(start, end) {
                var self = this;

                this.fetchingRates = true
                this.noAvailability = false

                var request = {
                    method: 'FetchPMSVenueCalendar',
                    data: {
                        Venue: this.venueName,
                        StartDate: start,
                        EndDate: end,
                        PromoCode: 'DAYGUEST',
                        RateType: null,
                        WebFolioId: null,
                        Language: null
                    }
                };
                axios.post('/rs/request', request)
                    .then(response=> {
                        if(response.data.BarRateAvailability) {
                            if (!$.isArray(response.data.BarRateAvailability)){ // convert single entry to an array
                                response.data.BarRateAvailability = [response.data.BarRateAvailability]
                            }
                            if(self.weekday) {
                                _.remove(response.data.BarRateAvailability, function(wd) {
                                    return [5,6].includes(moment(wd.Date.substring(0, wd.Date.length - 6)).day())
                                })
                            }
                            _.each(response.data.BarRateAvailability, function(day) {
                                
                                if(day) {
                                    day.date = day.Date.substring(0, day.Date.length - 6)

                                    if(day.RateCardDailyAvailability) {
                                        if (!$.isArray(day.RateCardDailyAvailability)){ // convert single entry to an array
                                            day.RateCardDailyAvailability = [day.RateCardDailyAvailability]
                                        }

                                        // split strings to array
                                        var rateCodes = self.RateType.split(" ");

                                        // filter the result based on rooms available and availabile for this day of week
                                        _.each(day.RateCardDailyAvailability, function(rate) {

                                            if(_.includes(rateCodes, rate.RateType)) { // check rate first
                                                
                                                if(+rate.NumRoomsAvail && rate.AvailMonSun[ (moment(day.date).format('E')-1) ] != "N") {
                                                    rate.date = day.date
                                                    self.calendarRates.push(rate)
                                                } 
                                            }
                                        });
                                    }
                                }
                            })
                            if(!self.calendarRates.length) {
                                Vue.set(this.calendarAvailability, moment(start).format('M'), false)
                            } else {
                                Vue.set(this.calendarAvailability, moment(start).format('M'), true)
                            }
                        } else {
                            Vue.set(this.calendarAvailability, moment(start).format('M'), false)
                        } 
                        this.fetchingRates = false
                    });
            }
        }
    }
</script>
