<template>
    <div class="login-register">
        <div v-show="showLoginForm" class="login-section">
            <h4>Login to your account...</h4>
            
            <form id="cart-login" @submit.prevent="login">
                <div class="cart-login-body">
                    <div class="form-group row">
                        <label for="customerEmail" class="col-sm-2 col-form-label col-form-label-sm">Email</label>
                        <div class="col-sm-10">
                            <input @change="clearError" v-model="email" type="email" class="form-control form-control-sm" id="customerEmail" aria-describedby="emailHelp" placeholder="Enter email" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="customerPassword" class="col-sm-2 col-form-label col-form-label-sm">Password</label>
                        <div class="col-sm-10">
                            <input @change="clearError" v-model="password" type="password" class="form-control form-control-sm" id="customerPassword" aria-describedby="passwordlHelp" placeholder="Enter password" required>
                        </div>
                    </div>
                    <div class="form-group clearfix">
                        <a @click.prevent="forgotPassword" class="step-back float-right">Forgot password</a>
                    </div>
                    <div class="form-group clearfix">
                        <button type="submit" class="btn btn-secondary btn-block">Login <span v-if="loading"><div class="spinner-border spinner-border-sm" role="status"></div></span></button>
                    </div>
                    <div class="form-group">
                        <span class="validation">{{ error }}</span>
                    </div>
                    <div v-if="showPasswordReset" class="form-group">
                        <div v-if="resetStatus">
                            <p>{{ resetStatus }}</p>
                        </div>
                        <div v-else>
                            <p>Would you like to reset your password for the email address {{ email }}?</p>
                            <button @click.prevent="resetPassword" class="btn btn-sm" :disabled="resettingPassword">Send me a new password <span v-if="resettingPassword" class="spinner-border spinner-border-sm" role="status"></span></button>
                            <button @click.prevent="cancelPassword" class="btn btn-sm">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div v-show="showRegisterForm" class="register-section">
            <h4>Let's create your account...</h4>
            <form v-show="showPersonalForm" @submit.prevent="personalForm">
                <div class="personal-section">
                    <h5>Personal details</h5>
                    <div class="form-group row">
                        <label for="firstName" class="col-sm-3 col-form-label col-form-label-sm">First name*</label>
                        <div class="col-sm-9">
                            <input v-model="registerForm.Name.FirstName" type="text" class="form-control form-control-sm" id="firstName" placeholder="First name" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="surname" class="col-sm-3 col-form-label col-form-label-sm">Surname*</label>
                        <div class="col-sm-9">
                            <input v-model="registerForm.Name.LastName" type="text" class="form-control form-control-sm" id="surname" placeholder="Surname" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="Tel" class="col-sm-3 col-form-label col-form-label-sm">Telephone*</label>
                        <div class="col-sm-9">
                            <input v-model="registerForm.Phone[0].PhoneNumber" type="tel" pattern="^[0-9-+\s()]*$" class="form-control form-control-sm" id="Tel" name="Tel" ref="Tel" placeholder="Telephone number" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="Mob" class="col-sm-3 col-form-label col-form-label-sm">Mobile*</label>
                        <div class="col-sm-9">
                            <input v-model="registerForm.Phone[1].PhoneNumber" type="tel" pattern="^0([1-6][0-9]{8,10}|7[0-9]{9})$" class="form-control form-control-sm" id="Mob" name="Mob" ref="Mob" placeholder="Mobile number" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newEmail" class="col-sm-3 col-form-label col-form-label-sm">Email*</label>
                        <div class="col-sm-9">
                            <input v-model="registerForm.EmailAddress" type="email" class="form-control form-control-sm" id="newEmail" placeholder="Enter email" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newPassword" class="col-sm-3 col-form-label col-form-label-sm">Password*</label>
                        <div class="col-sm-9">
                            <password-checker :password.sync="registerForm.Password" id="newPassword" placeholder="Enter password" :minlength="8" :required="true" :min-password-strength="this.$root.system.MinPasswordStrength" :is-password-valid.sync="passwordValid" />
                        </div>
                    </div>
                    <div v-if="emailExists" class="form-group">
                        <p class="validation">{{ emailExists }}</p>
                        <button @click.prevent="switchToLogin()" class="btn btn-primary btn-sm">Login</button>
                    </div>
                    <div v-else class="form-group">
                        <button :disabled="checkingEmail || !passwordValid" class="btn btn-primary" type="submit">Next <span v-if="checkingEmail" class="spinner-border spinner-border-sm" role="status"></span></button>
                    </div>
                </div>
            </form>
            <form v-show="showAddressForm" @submit.prevent="addressForm">
                <div class="address-section">
                    <h5>Your address <a @click.prevent="backPersonal" class="step-back">Back a step</a></h5>
                    <div class="form-group row">
                        <label for="Address1" class="col-sm-3 col-form-label col-form-label-sm">Address*</label>
                        <div class="col-sm-9">
                            <input 
                                v-model="registerForm.Address[0].Address1"
                                type="text" 
                                class="form-control form-control-sm" 
                                id="Address1" 
                                name="Address1" 
                                ref="Address1" 
                                placeholder="(start typing to search)" 
                                @input="debouncedFetchAddressLookup"
                                required
                            >
                            
                            <ul
                                v-if="addressResults.length"
                                class="autocomplete"
                            >
                                <li class="amount">
                                    Showing {{ addressResults.length }} results
                                </li>
                                <li
                                    v-for="(address, index) in addressResults"
                                    :key="index"
                                    @click="selectAddress(address)"
                                    class="list-item"
                                >
                                    {{ address.address_text }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="Address2" class="col-sm-3 col-form-label col-form-label-sm">Address 2</label>
                        <div class="col-sm-9">
                            <input :value="registerForm.Address[0].Address2" type="text" class="form-control form-control-sm" id="Address2" name="Address2" ref="Address2" placeholder="Address line 2">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="City" class="col-sm-3 col-form-label col-form-label-sm">City*</label>
                        <div class="col-sm-9">
                            <input :value="registerForm.Address[0].City" type="text" class="form-control form-control-sm" id="City" name="City" ref="City" placeholder="City" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="PostCode" class="col-sm-3 col-form-label col-form-label-sm">Post Code*</label>
                        <div class="col-sm-6">
                            <input :value="registerForm.Address[0].PostCode" type="text" class="form-control form-control-sm" id="PostCode" name="PostCode" ref="PostCode" placeholder="Post Code" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="Country" class="col-sm-3 col-form-label col-form-label-sm">Country*</label>
                        <div class="col-sm-9">
                            <input :value="registerForm.Address[0].Country" type="text" class="form-control form-control-sm" id="Country" name="Country" ref="Country" placeholder="Country" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-sm" type="submit">Next</button>
                    </div>
                </div>
            </form>

            <form v-show="showMarketingForm" @submit.prevent="register">
                <div class="marketing-section">
                    <h5>Keep in touch <a @click.prevent="backAddress" class="step-back">Back a step</a></h5>
                    <p>Let us know the month and year you were born, so we can send you a birthday treat!</p>
                    <div class="form-row">
                        <div class="form-group col">
                            <select v-model="monthOfBirth" name="monthOfBirth" class="form-control form-control-sm" id="monthOfBirth">
                                <option value="">Select month of birth</option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="form-group col">
                            <select v-model="yearOfBirth" name="yearOfBirth" class="form-control form-control-sm" id="yearOfBirth">
                                <option value="">Select year of birth</option>
                                <option v-for="y in years" :value="y">{{ y }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-check clearfix">
                        <label class="form-check-label float-left" for="NoEmail">
                            <input v-model="registerForm.NoEmail" type="checkbox" class="form-check-input" id="NoEmail">
                            <span class="checkmark"></span>
                            Tick to receive the latest news, events and exclusive offers.
                        </label>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col">
                            <span v-if="loading"><div class="spinner-border spinner-border-sm" role="status"></div> Loading...</span>
                            <span class="validation">{{ error }}</span>
                        </div>
                    </div>

                    <div class="cart-register-footer">
                        <button type="submit" class="btn btn-primary" :disabled="loading">Create my account</button>
                    </div>
                </div>
            </form>
                    
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';

    var registerFormTemplate = function() {
        return {
            Name: {
                Salutation: null,
                FirstName: null,
                LastName: null
            },
            EmailAddress: null,
            NoEmail: false,
            NoMail: 'Y',
            NoCall: 'Y',
            NoSMS: 'Y',
            Password: '',
            DateOfBirth: '',
            Address: [
                {
                    type: 'HOME',
                    Address1: null,
                    Address2: null,
                    City: null,
                    StateProv: null,
                    Country: null,
                    PostCode: null
                }
            ],
            Phone: [
                {
                    type: 'HOME',
                    primary: true,
                    PhoneNumber: null
                },
                {
                    type: 'MOBILE',
                    primary: false,
                    PhoneNumber: null
                }
            ]
        }
    }
    export default {
        props: {
            cart: Object,
            system: Object,
            type: String
        },
        /**
         * The components data
         */
        data() {
            return {
                email: null,
                password: '',
                loading: null,
                error: null,
                registerForm: new registerFormTemplate,
                showLoginForm: true,
                showRegisterForm: false,
                showPersonalForm: true,
                showAddressForm: false,
                showMarketingForm: false,
                pcaAddress: null,
                showPasswordReset: false,
                resettingPassword: false,
                resetStatus: null,
                checkingEmail: false,
                emailExists: null,
                monthOfBirth: '',
                yearOfBirth: '',
                years: null,
                passwordValid: false,
                addressResults: []
            }
        },

        mounted() {
            var max = new Date().getUTCFullYear();
            var min = max - 120;
            var years = _.range(min, max + 1)
            this.years = years.reverse()
        },

        watch: {
            type() {
                if(this.type == 'login') {
                    this.showLogin()
                } else {
                    this.showRegister()
                }
            }
        },


        /**
         * Methods
         */
        methods: {
            async fetchAddressLookup() {
                try {
                    await axios.get('https://api.baronseden.com/sanctum/csrf-cookie');

                    const response = await axios.post('https://api.baronseden.com/api/address/lookup', 
                        {
                            address: this.registerForm.Address[0].Address1
                        }, 
                        {
                            headers: {
                                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                            }
                        }
                    );

                    this.addressResults = response.data.data;
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            
            debouncedFetchAddressLookup: debounce(function() {
                this.fetchAddressLookup();
            }, 700),

            async selectAddress(address) {
                const response = await axios.post('https://api.baronseden.com/api/address/format', 
                    {
                        global_address_key: address.global_address_key
                    }, 
                    {
                        headers: {
                            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        }
                    }
                );

                this.populateForm(response.data.data);
            },

            populateForm(address) {
                this.registerForm.Address[0].Address1 = address.address_line_1;
                this.registerForm.Address[0].Address2 = address.address_line_2;
                this.registerForm.Address[0].City = address.city;
                this.registerForm.Address[0].PostCode = address.post_code;
                this.registerForm.Address[0].Country = address.country;

                this.addressResults = [];
            },
            
            personalForm() {
                this.checkingEmail = true
                var request = {
                    method: 'CheckCustomerEmail',
                    data: { 
                        EmailAddress: this.registerForm.EmailAddress
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        this.checkingEmail = false
                        if(response.data.Result.value == 'SUCCESS') {
                            this.showPersonalForm = false
                            this.showAddressForm = true
                        } else {
                            // account already exists
                            this.emailExists = 'It looks like you already have an account with us. Please login.'
                        }
                    })
                
            },
            addressForm() {
                this.showAddressForm = false
                this.showMarketingForm = true
            },
            backPersonal() {
                this.showPersonalForm = true
                this.showAddressForm = false
                this.showMarketingForm = false
            },
            backAddress() {
                this.showPersonalForm = false
                this.showAddressForm = true
                this.showMarketingForm = false
            },

            switchToLogin() {
                Bus.$emit('SwitchLogin', 'login')
            },

            showLogin() {
                this.showLoginForm = true
                this.showRegisterForm = false
                this.error = null
                this.emailExists = null
            },
            showRegister() {
                var self = this
                this.error = null
                this.showLoginForm = false
                this.showRegisterForm = true
                this.emailExists = null
            },
            checkEmail() {
                
            },
            login() {
                this.loading = true
                var request = {
                    data: {
                        EmailAddress: this.email,
                        Password: this.password,
                        FolioId: null,
                        LastName: null
                    }
                }
                axios.post('/rs/login', request)
                    .then(response=> {
                        if(response.data.Result.value === 'SUCCESS') {
                            
                            Bus.$emit('RefreshSystem');
                            this.addContactLog(response.data.CustomerId, response.data.CustomerGUID)
                            $('.login-selector').removeClass('is-active');
                            $('html').toggleClass('no-scroll');

                        } else {
                            this.error = response.data.Result.Text;
                        }
                        this.loading = null
                    });

                dataLayer.push({
                    event: 'login',
                    method: 'ResortSuite'
                });

                this.$emitAnalytics(
                    'login', 
                    {
                        event: 'login',
                        method: 'ResortSuite'
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                );
            },
            
            register() {
                var self = this
                this.loading = true
                this.error = null

                this.registerForm.Address[0].Address1 = this.$refs.Address1.value;
                this.registerForm.Address[0].Address2 = this.$refs.Address2.value;
                this.registerForm.Address[0].City = this.$refs.City.value;
                this.registerForm.Address[0].PostCode = this.$refs.PostCode.value;
                this.registerForm.Address[0].Country = this.$refs.Country.value;

                if(this.monthOfBirth != '' && this.yearOfBirth != '') {
                    this.registerForm.DateOfBirth = this.yearOfBirth + '-' + this.monthOfBirth + '-01'
                }
                
                var formData = _.cloneDeep(this.registerForm)

                // Marketing
                formData.NoEmail = this.registerForm.NoEmail ? 'N' : 'Y';

                var request = {
                    method: 'CreateCustomer',
                    data: { 
                        NewCustomer: formData,
                        ContactLogs: {
                            ContactLog: null
                        }
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        
                        if(response.data.Result.value === 'SUCCESS') {
                            this.created = response.data;
                            this.email = this.registerForm.EmailAddress;
                            this.password = this.registerForm.Password;
                            this.$cookies.set("custType",'New',0);
                            this.login()
                            
                            if(this.registerForm.NoEmail) {
                                dataLayer.push({
                                    event: 'generate_lead',
                                    form_type: 'lead_marketing_consent',
                                    consent_granted: true
                                });
                            } else {
                                dataLayer.push({
                                    event: 'generate_lead',
                                    form_type: 'lead_marketing_consent',
                                    consent_granted: false
                                });
                            }
                        } else {
                            this.loading = false
                            if(response.data.Result.ErrorId == 101) {
                                this.error = 'It looks like you already have an account. Please login.'
                                this.email = this.registerForm.EmailAddress
                            } else {
                                this.error = response.data.Result.Text;
                            }
                        }
                    });

                dataLayer.push({
                    event: 'sign_up',
                    method: 'ResortSuite',
                    value: this.cart.FolioBalance,
                    currency: 'GBP'
                });

                this.$emitAnalytics(
                    'sign_up', 
                    {
                        event: 'sign_up',
                        method: 'ResortSuite',
                        value: this.cart.FolioBalance,
                        currency: 'GBP'
                    },
                    this.system.CustomerId,
                    this.system.WebFolioId
                );
            },

            addContactLog(CustomerID, GUID) {

                var customerType = this.$cookies.get("custType");
                if(!customerType) {
            
                    var logCat = 'Campaign: Existing Customer'
                    
                    var campaign = URI.parseQuery(Cookies.get('beCampaign'))

                    if(!_.isEmpty(campaign) && ( (('utm_campaign' in campaign) && ( campaign.utm_campaign.toLowerCase().indexOf('ltv') !== -1 || campaign.utm_campaign.toLowerCase().indexOf('nca') !== -1 )) || ('gclid' in campaign) )) {
                        // Effect campaign
                        var campaignName = campaign.utm_campaign ? campaign.utm_campaign : null;
                        var source = campaign.utm_source ? campaign.utm_source : null;
                        var medium = campaign.utm_medium ? campaign.utm_medium : null;
                        var content = campaign.utm_content ? campaign.utm_content : null;
                        var term = campaign.utm_term ? campaign.utm_term : null;
                        var gclid = campaign.gclid ? campaign.gclid : null;
                        var landing = campaign.landing ? campaign.landing : null;
                        var referrer = campaign.referrer ? campaign.referrer : null;
                        var clientIp = campaign.clientIp ? campaign.clientIp : null;

                        var request = {
                            method: 'UpdateCustomerProfile',
                            data: { 
                                Customer: {
                                    CustomerId: CustomerID,
                                    CustomerGUID: GUID
                                },
                                ContactLogs: [
                                    {
                                        name: logCat,
                                        _: '\nCustomer type: Returning \n' + 'Campaign: ' + campaignName + '\n' + 'Source: ' + source + '\n' + 'Medium: ' + medium + '\n' + 'Terms: ' + term + '\n' + 'Content: ' + content + '\n' + 'GCLID: ' + gclid + '\n' + 'Landing page: ' + landing + '\n' + 'Referrer: ' + referrer + '\n' + 'Client IP: ' + clientIp
                                    }
                                ]
                            }
                        }
                        axios.post('/rs/request', request)
                            .then(response=> {
                                this.purchaseMessage({message: 'Customer type: Returning' + '\n' + 'Campaign: ' + campaignName + '\n' + 'Source: ' + source + '\n' + 'Medium: ' + medium + '\n' + 'Terms: ' + term + '\n' + 'Content: ' + content + '\n' + 'GCLID: ' + gclid + '\n' + 'Landing page: ' + landing + '\n' + 'Referrer: ' + referrer + '\n' + 'Client IP: ' + clientIp + ' - Customer ID: ' + CustomerID})
                            });
                    }
                }
            },

            purchaseMessage(message) {
                axios.post('/system/purchase-message', message)
            },

            clearError() {
                this.error = null
            },

            forgotPassword() {
                this.$router.push('/book/account/request-password-reset')
            },

            cancelPassword() {
                this.error = null
                this.resetStatus = null
                this.showPasswordReset = false
            },

            resetPassword() {
                this.resettingPassword = true
                this.error = null
                var request = {
                    method: 'ResetPasswordCustomer',
                    data: { 
                        EmailAddress: this.email,
                        PasswordGUID: null,
                        NewPassword: null
                    }
                }
                axios.post('/rs/request', request)
                    .then(response=> {
                        this.resettingPassword = false
                        this.resetStatus = 'Request sent. Please allow upto 5 minutes for the email to arrive.'
                    });
            },
        }
    }
</script>

<style>
    .autocomplete {
        width: 92%;
        padding: 5px;
        border-radius: 0.25rem;
        background-color: #ffffff;
        border-width: 1px;
        border-style: solid;
        --border-opacity: 1;
        border-color: rgb(209 213 219 / var(--border-opacity));
        position: absolute;
        z-index: 10;
        font-size: 14px;
    }

    .autocomplete > :not([hidden]) ~ :not([hidden]) {
        --space-y-reverse: 0;
        margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
        margin-bottom: calc(0.25rem * var(--space-y-reverse));
    }
    
    .autocomplete .amount {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0.25rem;
        padding-bottom: 0.5rem; 
        font-weight: 700;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgb(229 231 235);
        list-style: none;
    }

    .autocomplete .list-item {
        cursor: pointer;
        padding: 0.25rem;
        list-style: none;
    }

    .autocomplete .list-item:hover {
        background-color: #f5f5f5; 
    }
</style>
