var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _vm.system.CustomerId
          ? _c("div", { staticClass: "col-md-6" }, [
              _c("h3", [_vm._v("Confirm details")]),
              _vm._v(" "),
              _c("p", { staticClass: "small" }, [_vm._v("Required fields *")]),
              _vm._v(" "),
              _vm.profile
                ? _c("div", { staticClass: "mt-5" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.update.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "firstName" } }, [
                              _vm._v("First name*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Name.FirstName,
                                  expression: "updateForm.Name.FirstName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "firstName",
                                placeholder: "First name",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Name.FirstName,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Name,
                                    "FirstName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "surname" } }, [
                              _vm._v("Surname*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Name.LastName,
                                  expression: "updateForm.Name.LastName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "surname",
                                placeholder: "Surname",
                                required: "",
                              },
                              domProps: { value: _vm.updateForm.Name.LastName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Name,
                                    "LastName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "newEmail" } }, [
                              _vm._v("Your email address*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.EmailAddress,
                                  expression: "updateForm.EmailAddress",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                id: "newEmail",
                                name: "update-email",
                                placeholder: "Enter email",
                                autocomplete: "update-email",
                                required: "",
                              },
                              domProps: { value: _vm.updateForm.EmailAddress },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm,
                                    "EmailAddress",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "Address1" } }, [
                              _vm._v("Address*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Address[0].Address1,
                                  expression: "updateForm.Address[0].Address1",
                                },
                              ],
                              ref: "Address1",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "Address1",
                                name: "Address1",
                                placeholder: "Address line 1",
                                autocomplete: "off",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Address[0].Address1,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.updateForm.Address[0],
                                      "Address1",
                                      $event.target.value
                                    )
                                  },
                                  _vm.debouncedFetchAddressLookup,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _vm.addressResults.length
                              ? _c(
                                  "ul",
                                  { staticClass: "autocomplete" },
                                  [
                                    _c("li", { staticClass: "amount" }, [
                                      _vm._v(
                                        "\n                                        Showing\n                                        " +
                                          _vm._s(_vm.addressResults.length) +
                                          " results\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.addressResults,
                                      function (address, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "list-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectAddress(
                                                  address
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(address.address_text) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "Address2" } }, [
                              _vm._v("Address line 2"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Address[0].Address2,
                                  expression: "updateForm.Address[0].Address2",
                                },
                              ],
                              ref: "Address2",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "Address2",
                                name: "Address2",
                                placeholder: "Address line 2",
                              },
                              domProps: {
                                value: _vm.updateForm.Address[0].Address2,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Address[0],
                                    "Address2",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "City" } }, [
                              _vm._v("City*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Address[0].City,
                                  expression: "updateForm.Address[0].City",
                                },
                              ],
                              ref: "City",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "City",
                                name: "City",
                                placeholder: "City",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Address[0].City,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Address[0],
                                    "City",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-4" }, [
                            _c("label", { attrs: { for: "PostCode" } }, [
                              _vm._v("Post Code*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Address[0].PostCode,
                                  expression: "updateForm.Address[0].PostCode",
                                },
                              ],
                              ref: "PostCode",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "PostCode",
                                name: "PostCode",
                                placeholder: "Post Code",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Address[0].PostCode,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Address[0],
                                    "PostCode",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col" }, [
                            _c("label", { attrs: { for: "Country" } }, [
                              _vm._v("Country*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              ref: "Country",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "Country",
                                name: "Country",
                                placeholder: "Country",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Address[0].Country,
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-6" }, [
                            _c("label", { attrs: { for: "Tel" } }, [
                              _vm._v("Telephone*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Phone[0].PhoneNumber,
                                  expression:
                                    "\n                                        updateForm.Phone[0].PhoneNumber\n                                    ",
                                },
                              ],
                              ref: "Tel",
                              staticClass: "form-control",
                              attrs: {
                                type: "tel",
                                pattern: "^0([1-6][0-9]{8,10}|7[0-9]{9})$",
                                id: "Tel",
                                name: "Tel",
                                placeholder: "Telephone number",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Phone[0].PhoneNumber,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Phone[0],
                                    "PhoneNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-6" }, [
                            _c("label", { attrs: { for: "Tel" } }, [
                              _vm._v("Mobile*"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.updateForm.Phone[1].PhoneNumber,
                                  expression:
                                    "\n                                        updateForm.Phone[1].PhoneNumber\n                                    ",
                                },
                              ],
                              ref: "Mob",
                              staticClass: "form-control",
                              attrs: {
                                type: "tel",
                                pattern: "^0([1-6][0-9]{8,10}|7[0-9]{9})$",
                                id: "Mob",
                                name: "Mob",
                                placeholder: "Mobile number",
                                required: "",
                              },
                              domProps: {
                                value: _vm.updateForm.Phone[1].PhoneNumber,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.updateForm.Phone[1],
                                    "PhoneNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6" },
                            [
                              _c("v-date-picker", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({
                                        inputProps,
                                        inputEvents,
                                      }) {
                                        return _c(
                                          "input",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class: ["form-control"],
                                                attrs: {
                                                  placeholder: "Select date",
                                                },
                                              },
                                              "input",
                                              inputProps,
                                              false
                                            ),
                                            inputEvents
                                          )
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3218920268
                                ),
                                model: {
                                  value: _vm.updateForm.DateOfBirth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.updateForm, "DateOfBirth", $$v)
                                  },
                                  expression: "updateForm.DateOfBirth",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-check" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label float-left",
                              attrs: { for: "NoEmail" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.updateForm.NoEmail,
                                    expression: "updateForm.NoEmail",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "checkbox", id: "NoEmail" },
                                domProps: {
                                  checked: Array.isArray(_vm.updateForm.NoEmail)
                                    ? _vm._i(_vm.updateForm.NoEmail, null) > -1
                                    : _vm.updateForm.NoEmail,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.updateForm.NoEmail,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.updateForm,
                                            "NoEmail",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.updateForm,
                                            "NoEmail",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.updateForm, "NoEmail", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                "\n                                Tick to receive the latest news, events and\n                                exclusive offers.\n                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col" }, [
                            _c("span", { staticClass: "validation" }, [
                              _vm._v(_vm._s(_vm.error)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col text-right" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.forgotPassword.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Change password")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-reversed ml-3",
                                  attrs: {
                                    type: "submit",
                                    disabled: _vm.updating,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Update details\n                                    "
                                  ),
                                  _vm.updating
                                    ? _c("div", {
                                        staticClass:
                                          "spinner-border spinner-border-sm",
                                        attrs: { role: "status" },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.success
                          ? _c("div", [
                              _vm._v(
                                "\n                            Details updated successfully!\n                        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _c("div", [_vm._m(1)]),
            ])
          : _c("div", { staticClass: "col-md-6 text-center" }, [
              _c("h3", { staticClass: "mt-3" }, [
                _vm._v("Please login to view your profile"),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-reversed mt-3",
                  attrs: {
                    type: "button",
                    "data-toggle": "modal",
                    "data-target": "#loginModal",
                  },
                },
                [_vm._v("\n                    Login\n                ")]
              ),
            ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "loginModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "loginModalTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c("label", { attrs: { for: "customerEmail" } }, [
                          _vm._v("Your email address"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "email",
                            id: "customerEmail",
                            "aria-describedby": "emailHelp",
                            placeholder: "Enter email",
                            required: "",
                          },
                          domProps: { value: _vm.email },
                          on: {
                            change: _vm.clearError,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c("label", { attrs: { for: "customerPassword" } }, [
                          _vm._v("Your password"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            id: "customerPassword",
                            "aria-describedby": "passwordlHelp",
                            placeholder: "Enter password",
                            required: "",
                          },
                          domProps: { value: _vm.password },
                          on: {
                            change: _vm.clearError,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.password = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c(
                          "a",
                          {
                            staticClass: "small-text",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.forgotPassword.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Forgot password")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _vm.loading
                          ? _c("span", [
                              _c("div", {
                                staticClass: "spinner-border spinner-border-sm",
                                attrs: { role: "status" },
                              }),
                              _vm._v(
                                "\n                                    Loading..."
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.error
                          ? _c(
                              "div",
                              {
                                staticClass: "alert alert-danger",
                                attrs: { role: "alert" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.error) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "passwordModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "passwordModalTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.updatePassword.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c("label", { attrs: { for: "resetPassword" } }, [
                          _vm._v("New password"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordForm.Password,
                              expression: "passwordForm.Password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            minlength: "8",
                            pattern: "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\\S{8,}",
                            id: "resetPassword",
                            placeholder: "Min 8, 1 num, 1 capital",
                            required: "",
                          },
                          domProps: { value: _vm.passwordForm.Password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.passwordForm,
                                "Password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "small" }, [
                          _vm._v(
                            "\n                                    Minimum 8 characters, 1 number and 1\n                                    capital letter\n                                "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c("label", { attrs: { for: "resetPassword" } }, [
                          _vm._v("Confirm password"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.matchPassword,
                              expression: "matchPassword",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            minlength: "8",
                            pattern: "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\\S{8,}",
                            id: "confirmPassword",
                            placeholder: "Min 8, 1 num, 1 capital",
                            required: "",
                          },
                          domProps: { value: _vm.matchPassword },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.matchPassword = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.matchPassword != _vm.passwordForm.Password
                          ? _c("div", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n                                    Password does not match\n                                "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _vm.successPassword
                          ? _c("div", { staticClass: "mt-2" }, [
                              _vm._v(
                                "\n                                    Password updated successfully!\n                                "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", "data-dismiss": "modal" },
                      },
                      [
                        _vm._v(
                          "\n                            Close\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "submit",
                          disabled:
                            _vm.updatingPassword ||
                            _vm.matchPassword != _vm.passwordForm.Password,
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Update password\n                            "
                        ),
                        _vm.updatingPassword
                          ? _c("div", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status" },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-md-12 mb-0" }, [
      _c("label", { attrs: { for: "DateOfBirth" } }, [
        _vm._v(
          "Date of birth, so we can send you a\n                                    birthday treat."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", {
        staticClass: "spinner-border spinner-border-sm",
        attrs: { role: "status" },
      }),
      _vm._v(
        "\n                        Fetching your details...\n                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "loginModalTitle" } },
        [
          _vm._v(
            "\n                            Login\n                        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [
          _vm._v(
            "\n                            Close\n                        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [
          _vm._v(
            "\n                            Login\n                        "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "passwordModalTitle" } },
        [
          _vm._v(
            "\n                            Update password\n                        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }