var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "book-component" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "calendar" },
      [
        _c("v-calendar", {
          ref: "calendar",
          attrs: {
            mode: "range",
            "min-date": _vm.calStart,
            "from-page": _vm.calPage,
            "max-date": null,
            attributes: _vm.atts,
            "is-expanded": "",
            "is-inline": "",
            "is-linked": "",
          },
          on: {
            "update:fromPage": function ($event) {
              _vm.calPage = $event
            },
            "update:from-page": function ($event) {
              _vm.calPage = $event
            },
            dayclick: function ($event) {
              return _vm.dayClick($event)
            },
            "update:to-page": function ($event) {
              return _vm.nextMonth($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "day-content",
              fn: function ({ day, attributes, dayEvents }) {
                return _c(
                  "span",
                  _vm._g(
                    {
                      staticClass: "vc-day-content",
                      class: {
                        daySelected: day.id == _vm.range,
                        "vc-pointer-events-none vc-text-gray-400": !attributes,
                      },
                      attrs: { day: day },
                    },
                    dayEvents
                  ),
                  [
                    _c(
                      "div",
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(day.day)),
                        ]),
                        _vm._v(" "),
                        _vm._l(attributes, function ({ key, customData }) {
                          return _c(
                            "div",
                            { key: key, staticClass: "vc-day-content__rate" },
                            [
                              _vm._v(
                                "\n                        £" +
                                  _vm._s(customData.price) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                )
              },
            },
          ]),
          model: {
            value: _vm.range,
            callback: function ($$v) {
              _vm.range = $$v
            },
            expression: "range",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "book-component-selector text-center" }, [
      _c("ul", { staticStyle: { display: "none" } }, [
        _c("li", [
          _c("p", [_vm._v("Nights")]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-plus-minus", on: { click: _vm.decNights } },
            [_vm._v("-")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "book-int" }, [_vm._v(_vm._s(_vm.nights))]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-plus-minus", on: { click: _vm.incNights } },
            [_vm._v("+")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", [_vm._v("Guests")]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-plus-minus", on: { click: _vm.decGuests } },
            [_vm._v("-")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "book-int" }, [_vm._v(_vm._s(_vm.guests))]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-plus-minus", on: { click: _vm.incGuests } },
            [_vm._v("+")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", [_vm._v("Rooms")]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-plus-minus", on: { click: _vm.decRooms } },
            [_vm._v("-")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "book-int" }, [_vm._v(_vm._s(_vm.room))]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-plus-minus", on: { click: _vm.incRooms } },
            [_vm._v("+")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.fetchingRates
        ? _c("p", { staticClass: "text-center" }, [
            _c("span", {
              staticClass: "spinner-border spinner-border-sm",
              attrs: { role: "status" },
            }),
            _vm._v(" Fetching rates"),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.fetchingRates && !_vm.hasAvailability
        ? _c("p", { staticClass: "text-center" }, [_vm._v("No availability")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn mt-3",
          class: { disabled: _vm.isDisabled },
          attrs: { type: "button", disabled: _vm.isDisabled },
          on: {
            click: function ($event) {
              return _vm.checkAvailability()
            },
          },
        },
        [_vm._v("\n            Book Now\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12" }, [
      _c("h3", [_vm._v("Check Availability")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }