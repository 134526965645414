var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.success
      ? _c("div", [
          _c(
            "form",
            {
              staticClass: "form-inline",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.subscribe.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.emailAddress,
                    expression: "emailAddress",
                  },
                ],
                staticClass: "form-control mr-2",
                attrs: {
                  type: "email",
                  placeholder: "enter email",
                  required: "",
                },
                domProps: { value: _vm.emailAddress },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.emailAddress = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "image",
                  value: "submit",
                  src: "/img/arrow-right.svg",
                  alt: "submit Button",
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "xs mt-2",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.hideForever.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Hide forever")]
              ),
            ]
          ),
        ])
      : _c("div", [
          _c("p", [_vm._v("Thanks for signing up!")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-reversed",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_vm._v("Close")]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }